import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  getAuthCompaniesThunk,
  getAuthCompanyBrandsThunk,
  getAuthCompanyBrandShopsThunk,
  postAuthThunk,
  getAuthThunk,
  postPasswordResetThunk,
} from 'store/auth/authAsync'
import { AUTH } from 'store/constants'

/**
 * @typedef {Object} AuthResult
 * @property {boolean} isLoggedIn
 * @property {string} loginFormError
 * @property {string} accessToken
 */

/**
 * @typedef {Object} PostAuthResponseData
 * @property {number} status
 * @property {string} statusDescription
 * @property {string} accessToken
 * @property {Object} loginFailed
 * @property {number} loginFailed.count
 * @property {number} loginFailed.nextAvailableTimeToLogin
 */

/**
 * @typedef {Object} PostAuthResponse
 * @property {PostAuthResponseData} data
 * @property {boolean} isFetch
 * @property {number} statusCode
 * @property {string} errorMsg
 */

/**
 * @typedef {Object} GetAuthResponse
 * @property {Object} data
 * @property {boolean} isFetch
 * @property {number} statusCode
 * @property {string} errorMsg
 */

// Init Config
export const authInitState = {
  token: '',
  postAuthRsp: {
    data: {
      status: null,
      accessToken: null,
      statusDescription: null,
      loginFailed: { count: 0, nextAvailableTimeToLogin: null },
    },
    isFetch: false,
    statusCode: 0,
    errorMsg: '',
  },
  getAuthRsp: {
    data: {},
    isFetch: false,
    statusCode: 0,
    errorMsg: '',
  },
  postPasswordResetRsp: {
    data: {
      msg: '',
    },
    code: 200,
    msg: null,
    errors: null,
  },
  getAuthCompaniesRsp: {
    data: {
      companies: [],
    },
    code: 200,
    msg: null,
    errors: null,
  },
  getAuthCompanyBrandsRsp: {
    data: {
      brands: [],
    },
    code: 200,
    msg: null,
    errors: null,
  },
  getAuthCompanyBrandShopsRsp: {
    data: {
      shops: [],
    },
    code: 200,
    msg: null,
    errors: null,
    isFetch: false,
  },
  authAccountData: {
    account: {},
    companyIndex: 0,
    companies: [],
    brandIndex: 0,
    brands: [],
    shopIndex: 0,
    shops: [],
    prevShopId: '',
  },
}

const authSlice = createSlice({
  name: AUTH,
  initialState: authInitState,
  reducers: {
    authInit: () => {
      // 預留一個 auth init 動作，目前沒有需要做的事情
    },
    authLogout: () => ({ ...authInitState }),
    updateIsFetch: (state, action) => {
      state.postAuthRsp.isFetch = action.payload
    },
    resetAuthResult: () => {
      return { ...authInitState }
    },
    updateAuthAccount: (state, action) => {
      state.authAccountData = {
        ...state.authAccountData,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(postAuthThunk.fulfilled, postAuthThunk.rejected),
      (state, action) => {
        state.postAuthRsp.data = action.payload
        state.postAuthRsp.isFetch = true
      }
    )
    builder.addMatcher(
      isAnyOf(getAuthThunk.fulfilled, getAuthThunk.rejected),
      (state, action) => {
        state.getAuthRsp.data = action.payload
        state.authAccountData.account = action.payload?.account
        state.getAuthRsp.isFetch = true
      }
    )
    builder.addMatcher(
      isAnyOf(
        postPasswordResetThunk.fulfilled,
        postPasswordResetThunk.rejected
      ),
      (state, action) => {
        state.postPasswordResetRsp.data = action.payload
      }
    )
    builder.addMatcher(
      isAnyOf(
        getAuthCompaniesThunk.fulfilled || getAuthCompaniesThunk.rejected
      ),
      (state, action) => {
        const {
          data: { companies },
          index,
        } = action.payload
        state.getAuthCompaniesRsp = action.payload
        state.authAccountData.companyId = companies[index]?.id
        state.authAccountData.companyIndex = index
        state.authAccountData.companies = companies
      }
    )
    builder.addMatcher(
      isAnyOf(
        getAuthCompanyBrandsThunk.fulfilled ||
          getAuthCompanyBrandsThunk.rejected
      ),
      (state, action) => {
        const {
          data: { brands },
          index,
        } = action.payload
        state.getAuthCompanyBrandsRsp = action.payload
        state.authAccountData.brandId = brands[index]?.id
        state.authAccountData.brandIndex = index
        state.authAccountData.brands = brands
      }
    )
    builder.addMatcher(
      isAnyOf(
        getAuthCompanyBrandShopsThunk.fulfilled ||
          getAuthCompanyBrandShopsThunk.rejected
      ),
      (state, action) => {
        const {
          data: { shops },
          index,
        } = action.payload
        state.getAuthCompanyBrandShopsRsp = action.payload
        state.getAuthCompanyBrandShopsRsp.isFetch = true
        state.authAccountData.shopId = shops[index]?.id
        state.authAccountData.shopIndex = index
        state.authAccountData.shops = shops
      }
    )
  },
})

export default authSlice.reducer
export const {
  updateIsFetch,
  resetAuthResult,
  updateAuthAccount,
  resetAuthAccount,
  authInit,
  authLogout,
} = authSlice.actions
