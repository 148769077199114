import { useEffect, useContext, useState, useCallback } from 'react'
import { Form } from 'react-bootstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { useDispatch, useSelector } from 'react-redux'
import { getProductListList, batchDeleteProductItem } from 'api/ApiMain'
import {
  searchConfig,
  paginationOptions,
  searchType,
  defaultSort,
  columns,
  selectRow,
} from 'components/pages/ActivitiesCode/ProductActivity/Sub/ProductListConfig'
import {
  handleDefaultSubmit,
  handleUploadCsv,
} from 'components/pages/ActivitiesCode/ProductActivity/Sub/ProductListHelper'
import ProductListWrapper, {
  ImportModalWrapper,
  TextLink,
} from 'components/pages/ActivitiesCode/ProductActivity/Sub/ProductListStyle'
import {
  Caption,
  UploadBtn,
} from 'components/pages/ActivitiesCode/ProductActivity/Sub/ProductListSubComponents'
import {
  Input,
  Button,
  Select,
  Table,
  NoDataIndication,
  Modal,
} from 'components/units'
import { downloadCsv } from 'helpers/common'
import { settingPageType } from 'pages/ActivitiesCode/ProductActivity'
import { SettingContext } from 'pages/ActivitiesCode/ProductActivityManagementEntry'

import { updateListLoading, getGlobalState } from 'store/global/globalSlice'
import { CSV_EXAMPLE } from 'constant/csvExample'

const initSearch = {
  searchType: searchType.code,
  keyword: '',
}

const initPayload = {
  ...defaultSort,
  searchType: '',
  keyword: '',
  p: 1,
  ps: 25,
}
const initSetting = {
  type: 'setting',
  prefix: '',
  length: '',
  total: '',
  prevType: '',
  prevPrefix: '',
  prevLength: '',
  uploadCount: 0,
  isSetting: false,
}

const initListTotal = {
  pageTotal: 0,
  unexchangedCount: 0,
  totalCount: 0,
}

function ProductList() {
  const dispatch = useDispatch()
  const { isDataLoading, isListLoading } = useSelector(getGlobalState)
  const [search, setSearch] = useState(initSearch)
  const [payload, setPayload] = useState(initPayload)
  const [data, setData] = useState([])
  const [setting, setSetting] = useState(initSetting)
  const [selectedList, setSelectedList] = useState([])
  const [listTotal, setListTotal] = useState(initListTotal)
  const [uploadModal, setUploadModal] = useState(false)
  const [inputValues, setInputValues] = useState()
  const [inputValids, setInputValids] = useState()
  const context = useContext(SettingContext)
  const isNew = context?.viewType === settingPageType.new
  const isEdit = context?.viewType === settingPageType.edit
  const setMask = context?.setMask
  const brandId = context?.history?.location?.state?.apiPayload?.brandId
  const pointProductRuleId =
    context?.history?.location?.state?.apiPayload?.pointProductRuleId
  const isSelected = selectedList.some((i) => i?.no)

  const handleSumbitToSearch = (isReset) => {
    dispatch(updateListLoading(true))
    if (!isReset) {
      if (search.keyword === '')
        return setPayload((prev) => ({
          ...prev,
          keyword: '',
          searchType: '',
          p: 1,
        }))
      return setPayload((prev) => ({ ...prev, ...search, p: 1 }))
    }
    setSearch((prev) => ({ ...prev, keyword: '' }))
    return setPayload((prev) => ({
      ...prev,
      p: 1,
      searchType: '',
      keyword: '',
    }))
  }

  const handleSearch = useCallback(async () => {
    if (brandId && pointProductRuleId) {
      dispatch(updateListLoading(true))
      setData([])
      const res = await getProductListList(brandId, pointProductRuleId, payload)
      const rows = res?.data?.data?.rows
      const pageTotal = res?.data?.data?.totalSize
      setData(rows)
      setListTotal((prev) => ({
        ...prev,
        pageTotal,
      }))
      setSetting((prev) => ({
        ...prev,
        prevType: prev.type,
        prevPrefix: prev.prefix,
        prevLength: prev.length,
      }))
      dispatch(updateListLoading(false))
    }
  }, [brandId, pointProductRuleId, payload])

  const handleExampleDownload = () => {
    downloadCsv({
      data: CSV_EXAMPLE.PRODUCT,
      fileName: '商品清單_匯入範本',
      isAppendTime: false,
    })
  }

  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      const selectedItem = {
        no: row.no,
        pointProductId: row.pointProductId,
      }
      return setSelectedList((prev) => [...prev, selectedItem])
    }
    return setSelectedList((prev) => prev.filter((i) => i.no !== row.no))
  }

  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const selectAllList = rows.map((i) => ({
        no: i.no,
        pointProductId: i.pointProductId,
      }))
      return setSelectedList(selectAllList)
    }
    return setSelectedList([])
  }

  const columnsObj = {
    barCode: {
      handleSearch,
      brandId,
      pointProductRuleId,
      disabled: false,
      isEdit,
      placeholder: '商品條碼/貨號',
    },
    name: {
      handleSearch,
      brandId,
      pointProductRuleId,
      disabled: false,
      isEdit,
      placeholder: '商品名稱',
    },
    category: {
      handleSearch,
      brandId,
      pointProductRuleId,
      disabled: false,
      isEdit,
      placeholder: '商品分類',
    },
  }

  const selectObj = {
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    selected: selectedList.map((i) => i.no),
    isEdit,
  }

  const handleTableChange = (type, data) => {
    if (type === 'pagination') return
    const { sortField, sortOrder } = data
    setPayload((prev) => ({ ...prev, so: sortOrder, sf: sortField }))
  }

  const handleBatchRemove = useCallback(
    async (handleButtonLocalLoading) => {
      handleButtonLocalLoading(true)
      const selectedListKeepId = selectedList.map((i) => i.pointProductId)
      let deleteProductListString = ''

      for (let i = 0; i < selectedListKeepId.length; i++) {
        deleteProductListString += `deleteProductList=${selectedListKeepId[i]}${
          i !== selectedListKeepId.length - 1 ? '&' : ''
        }`
      }

      await batchDeleteProductItem(
        brandId,
        pointProductRuleId,
        deleteProductListString
      )
      setSelectedList([])
      handleButtonLocalLoading(false)
      await handleSearch()
    },
    [selectedList, brandId, pointProductRuleId, handleSearch]
  )

  const captionFn = {
    handleUpload: () => setUploadModal(true),
    batchRemove: handleBatchRemove,
  }

  const paginationFn = {
    onPageChange: (page) => setPayload((prev) => ({ ...prev, p: page })),
    onSizePerPageChange: (sizePerPage, page) =>
      setPayload((prev) => ({
        ...prev,
        p: page === 0 ? 1 : page,
        ps: sizePerPage,
      })),
  }

  useEffect(() => {
    if (!isNew) {
      handleSearch()
    }
  }, [isNew, handleSearch])

  useEffect(() => {
    const emptyFeildExist = data.some((i) => i.no === null)
    if (
      payload.p === 1 &&
      isEdit &&
      !emptyFeildExist &&
      search.keyword === ''
    ) {
      // table 內的 loading 須為資料為空才能顯示，故特別監聽 isListLoading false 時才把空資料塞入，
      if (!isListLoading) {
        setData((prev) => [{ no: null, barCode: null }, ...prev])
      }
    }
  }, [isEdit, payload.p, data, search, isListLoading])
  return (
    <ProductListWrapper>
      <Form onSubmit={handleDefaultSubmit}>
        <Form.Group className="search-area">
          <Form.Label>{searchConfig.label}</Form.Label>
          <Select
            selectedValue={0}
            optionItems={searchConfig.options}
            onChange={(e, value) =>
              setSearch((prev) => ({ ...prev, searchType: value }))
            }
          />
          <Input
            formControlOption={{
              value: search.keyword,
              onChange: (e) =>
                setSearch((prev) => ({ ...prev, keyword: e.target.value })),
            }}
          />
          <div className="button-wrap">
            <Button
              size="sm"
              variant="primary"
              disabled={!search.keyword}
              onClick={() => handleSumbitToSearch()}
            >
              {searchConfig.search}
            </Button>
            <Button
              size="sm"
              variant="outline-darkerGray"
              onClick={() => handleSumbitToSearch(true)}
            >
              {searchConfig.clear}
            </Button>
          </div>
        </Form.Group>
      </Form>
      {setting.type !== '' && (
        <PaginationProvider
          pagination={paginationFactory({
            ...paginationOptions,
            ...paginationFn,
            totalSize: listTotal.pageTotal,
            page: payload.p,
            sizePerPage: payload.ps,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <Caption
                paginationProps={paginationProps}
                type={setting.type}
                listTotal={listTotal}
                uploadCount={setting.uploadCount}
                isEdit={isEdit}
                isSelected={isSelected}
                {...captionFn}
              />
              <Table
                headerClasses="table-header"
                bodyClasses="paragraph"
                className="overflow-auto"
                keyField="no"
                data={data}
                columns={columns(columnsObj, {
                  inputValues,
                  inputValids,
                  setInputValues,
                  setInputValids,
                })}
                selectRow={selectRow(selectObj)}
                striped
                remote
                onTableChange={handleTableChange}
                noDataIndication={
                  <NoDataIndication
                    isLoading={isDataLoading ? !isDataLoading : isListLoading}
                    message={
                      isDataLoading
                        ? '載入中'
                        : '查無資料，請調整查詢內容後重新查詢'
                    }
                  />
                }
                {...paginationTableProps}
              />
              <PaginationListStandalone {...paginationProps} />
            </>
          )}
        </PaginationProvider>
      )}
      <Modal
        show={uploadModal}
        onClose={() => setUploadModal(false)}
        titleText="批次上傳"
        closeBtnText="關閉"
        customConfirm={() => (
          <UploadBtn
            onChange={handleUploadCsv({
              brandId,
              pointProductRuleId,
              pointProductRuleName: context?.infoState[0]?.name,
              handleSearch,
              setMask,
              setUploadModal,
            })}
          />
        )}
      >
        <ImportModalWrapper>
          <p>1. 檔案格式限 csv 檔，檔案大小限 ≤ 25MB</p>
          <p>
            2. 欄位設定說明，詳見&nbsp;
            <TextLink onClick={handleExampleDownload}>
              匯入範本說明 (下載)
            </TextLink>
          </p>
        </ImportModalWrapper>
      </Modal>
    </ProductListWrapper>
  )
}

export default ProductList
