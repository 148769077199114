import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'

const CaptionWrapper = styled.div`
  color: ${colorObj.dark};
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 16px;

  > div {
    display: flex;
    align-items: center;
  }

  .caption-label {
    min-width: 60px;
    color: ${colorObj.darkGray};
  }
`

const Wrapper = styled.div`
  tbody {
    font-size: 13px;
    font-weight: normal;
  }
`

const FormatterStatusWrapper = styled.ul`
  list-style-type: disc;
  margin: 0;
  li {
    margin-left: 18px;
    color: ${(props) => props.color || colorObj.dark};
  }
`

export { Wrapper, CaptionWrapper, FormatterStatusWrapper }
