import { useEffect, useContext, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import {
  Col,
  Input,
  Radio,
  Row,
  Button,
  Select,
  Table,
  NoDataIndication,
} from 'components/units'
import { InValidFeedback } from 'components/templates'
import { color, colorObj } from 'assets/styles/Variable/Color'
import {
  batchDeleteVipExchangeCode,
  getVipGiftInfo,
  switchVipExchangeCodeType,
} from 'api/ApiMain'
import {
  ExclusiveSettingContext,
  headerEventType,
  tabType as tabTypeConfig,
} from 'pages/Gift/ExclusiveSetting'
import ExclusiveSerialNumberWrapper from 'components/pages/Gift/ExclusiveSerialNumberStyle'
import {
  Caption,
  SettingChangedModal,
  UploadModal,
  MemberModal,
  ImportModal,
  headerFormatterGiftNumber,
  headerFormatterStatus,
  formatterStatus,
  formatterGiftNumber,
} from 'components/pages/Gift/ExclusiveSerialNumberSubcomponents'
import {
  handleValid,
  parserTableColumn,
  handleUploadCsv,
  handleList,
  handleRuleSetting,
  handleApiError,
} from 'components/pages/Gift/ExclusiveSerialNumberHelper'
import { settingPageType, tabStatus } from 'pages/Gift/config'
import { showToast } from 'api/ApiCommon'
import { startConnection } from 'store/serial-batch-upload/serialBatchUploadSlice'
import { PAGE_PATHS } from 'constant'
import { showTopToast } from 'components/units/Toast'
import { TOAST } from 'constant/common'
import {
  getVipExchangeCodeDetailThunk,
  resetVipGiftExchangeDetailModal,
  vipExchangeCodeDetailSelector,
} from 'store/vip-gift-exchange/vipGiftExchangeSlice'
import {
  getMemberOutlineThunk,
  resetMemberOutline,
} from 'store/member-outline/memberOutlineSlice'
import { number } from 'helpers/format'

const create = {
  byUser: 10,
  bySystem: 20,
}

const receive = {
  same: 20,
  different: 10,
}

const condition = {
  serialNumber: 1,
  phone: 2,
}

const status = {
  notSended: 0, // 未發送
  sended: 1, // 已發送
  received: 2, // 已領取
  expired: 90, // 已過期,
}

const isSystem = (value) => Number(value) === create.bySystem
const isUser = (value) => Number(value) === create.byUser
const isSame = (value) => Number(value) === receive.same

const searchOptions = [
  {
    id: '',
    text: '序號',
    value: condition.serialNumber,
  },
  { text: '手機', value: condition.phone },
]

const createOptions = [
  {
    key: 'byUser',
    text: '自行新增',
    value: create.byUser,
  },
  {
    key: 'bySystem',
    text: '系統生成',
    value: create.bySystem,
  },
]

const receiveOptions = [
  {
    key: 'different',
    text: '不同序號，同一序號限領取一次',
    value: receive.different,
  },
  {
    key: 'same',
    text: '相同序號',
    value: receive.same,
  },
]

const lengthLimitOptions = [
  { text: '10 碼', value: 10 },
  { text: '11 碼', value: 11 },
  { text: '12 碼', value: 12 },
  { text: '13 碼', value: 13 },
  { text: '14 碼', value: 14 },
  { text: '15 碼', value: 15 },
  { text: '16 碼', value: 16 },
  { text: '17 碼', value: 17 },
  { text: '18 碼', value: 18 },
  { text: '19 碼', value: 19 },
  { text: '20 碼', value: 20 },
  { text: '21 碼', value: 21 },
  { text: '22 碼', value: 22 },
  { text: '23 碼', value: 23 },
  { text: '24 碼', value: 24 },
]

function ExclusiveSerialNumber() {
  const dispatch = useDispatch()
  const exclusiveSettingContext = useContext(ExclusiveSettingContext)
  const [infoState] = exclusiveSettingContext?.infoState
  const history = exclusiveSettingContext?.history
  const [, setStateFromEdit] = exclusiveSettingContext?.stateFromEdit
  const [, setMask] = exclusiveSettingContext?.mask
  const [, setTransparentMask] = exclusiveSettingContext?.transparentMask
  const apiPaylaod =
    exclusiveSettingContext?.history?.location?.state?.apiPayload
  const pageType = exclusiveSettingContext?.pageType
  const viewType = exclusiveSettingContext?.viewType
  const handleEvent = exclusiveSettingContext?.handleEvent
  const brandId = apiPaylaod?.brandId
  // apiPaylaod?.giftSettingId為編輯＆檢視進入頁面，exclusiveSettingContext?.settingId為新增進入頁面
  const giftSettingId =
    apiPaylaod?.giftSettingId || exclusiveSettingContext?.settingId
  // 存新增後取得的giftEventId
  const [giftEventId, setGiftEventId] = useState(giftSettingId)
  const isView = viewType === settingPageType.view
  const [setting, setSetting] = useState({
    create: '',
    receive: '',
    prefix: '',
    lengthLimit: '',
    prevCreate: '',
    initCreate: '',
    initReceive: '',
    isChange: false,
    isSetting: false,
  })
  const [search, setSearch] = useState({
    condition: searchOptions[0].value,
    keyword: '',
  })
  const [payload, setPayload] = useState({
    condition: '',
    keyword: '',
    sf: 'serialNumber',
    so: 'desc',
    p: 1,
    ps: 25,
    isSearch: false,
  })
  const [pageData, setPageData] = useState({
    data: [],
    total: {
      conditionReceivedCount: 0,
      conditionTotalCount: 0,
      totalCount: 0,
      sendedCount: 0,
    },
    setting: { date: '', tip: '' },
  })
  const [valid, setValid] = useState({
    prefix: { valid: null, msg: '', required: false },
    lengthLimit: { valid: null, msg: '', required: false },
    receive: { valid: null, msg: '', required: true },
  })
  const [selectedList, setSelectedList] = useState([])
  const [settingModal, setSettingModal] = useState({
    status: false,
    confirm: null,
  })
  const [uploadModal, setUploadModal] = useState(false)
  const [importModal, setImportModal] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const modalContent = useSelector(vipExchangeCodeDetailSelector)
  const [showListField, setShowListField] = useState(false)
  // 變更規則 Modal 中的繼續按鈕
  const [confirmSettingBtnLoading, setConfirmSettingBtnLoading] =
    useState(false)
  // 確認規則按鈕
  const [confirmRuleBtnLoading, setConfirmRuleBtnLoading] = useState(false)

  const isReceiveSame = setting.receive === receive.same
  const isCreateBySystem = setting.create === create.bySystem
  const isUploadDone = infoState.giftAmount === pageData.total.totalCount
  const isUploadDoneBySame = pageData.total.totalCount === 1
  const isUploadBtnDisabled = isView || isReceiveSame || isUploadDone
  const isGiftCodeInputDisabled =
    isView || isReceiveSame ? isUploadDoneBySame : isUploadDone
  const isCaptionValidShow =
    isCreateBySystem ||
    (isSame(setting.receive) ? isUploadDoneBySame : isUploadDone)
  const isSubmitBtnDisabled =
    isView || pageData.total.sendedCount > 0 || !setting.isChange
  const isRemoveBtnDisabled = isView || selectedList.some((i) => i.no) === false

  const fetchVipGiftInfo = async (brandId, giftSettingId) => {
    try {
      const res = await getVipGiftInfo(brandId, giftSettingId)
      const { brandSerialNo } = res.data.data
      setGiftEventId(brandSerialNo)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSettingChange = useCallback(
    (type) =>
      (e, ...props) => {
        const [selectValue] = props
        const radioValue = Number(e.target.value)
        const textValue = e.target.value

        switch (type) {
          case 'create': {
            setValid((prev) => ({
              ...prev,
              receive: {
                ...prev.receive,
                required: true,
              },
              prefix: {
                ...prev.prefix,
                valid: null,
                required: isSystem(e.target.value),
              },
              lengthLimit: {
                ...prev.lengthLimit,
                valid: null,
                required: isSystem(e.target.value),
              },
            }))

            return setSetting((prev) => ({
              ...prev,
              [type]: radioValue,
              receive:
                // 建立方式與現有設定是否相同
                prev.initCreate === radioValue
                  ? prev.initReceive //相同，使用現有領取方式
                  : radioValue === 10
                  ? '' // 不同，選「自行新增」須清空領取方式
                  : 10, // 不同，選「系統生成」須預設為發送不同序號，序號限領取一次
              prefix: prev.initCreate === 20 ? prev.initPrefix : '',
              lengthLimit: prev.initCreate === 20 ? prev.initLengthLimit : '',
              isChange: prev.initCreate !== radioValue,
            }))
          }
          case 'receive':
            return setSetting((prev) => ({
              ...prev,
              [type]: radioValue,
              isChange:
                prev.initCreate !== prev.create ||
                prev.initReceive !== radioValue ||
                prev.initLengthLimit !== prev.lengthLimit ||
                prev.initPrefix !== prev.prefix,
            }))
          case 'prefix':
            return setSetting((prev) => ({
              ...prev,
              [type]: textValue,
              isChange:
                prev.initCreate !== prev.create ||
                prev.initLengthLimit !== prev.lengthLimit ||
                prev.initPrefix !== textValue,
            }))
          case 'lengthLimit':
            return setSetting((prev) => ({
              ...prev,
              [type]: selectValue,
              isChange:
                prev.initCreate !== prev.create ||
                prev.initLengthLimit !== selectValue ||
                prev.initPrefix !== prev.prefix,
            }))
          default:
        }
      },
    []
  )

  /* 變更搜尋的項目 */
  const handleSearchCondition = (e, selectValue) => {
    setSearch({ ...search, condition: selectValue })
  }

  /* 輸入搜尋的關鍵字輸入 */
  const handleSearchKeyword = (e) => {
    setSearch({ ...search, keyword: e.target.value })
  }

  /* 變更頁數 */
  const handlePageChange = (page) => {
    setPayload({ ...payload, p: page, isSearch: false })
  }

  /* 變更顯示數量 */
  const handleSizePerPageChange = (sizePerPage, page) => {
    setPayload({
      ...payload,
      p: page === 0 ? 1 : page,
      ps: sizePerPage,
      isSearch: false,
    })
  }

  /* 單項選取 */
  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      const selectedItem = {
        no: row.no,
        vipGiftExchangeCodeId: row.vipGiftExchangeCodeId,
      }
      return setSelectedList((prev) => [...prev, selectedItem])
    }
    return setSelectedList((prev) => prev.filter((i) => i.no !== row.no))
  }

  /* 全部選取 */
  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const selectAllList = rows.map((i) => ({
        no: i.no,
        vipGiftExchangeCodeId: i.vipGiftExchangeCodeId,
      }))
      return setSelectedList(selectAllList)
    }
    return setSelectedList([])
  }

  /* 主要為排序的變動 */
  const handleTableChange = (type, data) => {
    if (type === 'pagination') return
    const { sortField, sortOrder } = data
    setPayload((prev) => ({
      ...prev,
      so: sortOrder,
      sf: sortField,
      isSearch: false,
    }))
  }

  /* 獲取 Table 資料 */
  const getListData = useCallback(async () => {
    if (!brandId || !giftSettingId) return
    const {
      tableRow,
      conditionTotalCount,
      conditionReceivedCount,
      sendedCount,
      totalCount,
    } = await handleList({
      brandId,
      giftSettingId,
      data: payload,
    })
    if (payload.isSearch && tableRow.length === 0)
      showTopToast(
        { message: '查無資料，請調整搜尋內容後重新查詢' },
        TOAST.ERROR
      )
    setPageData((prev) => ({
      ...prev,
      total: {
        ...prev.total,
        conditionReceivedCount,
        conditionTotalCount,
        sendedCount,
        totalCount,
      },
      data: parserTableColumn(tableRow),
    }))
  }, [brandId, giftSettingId, payload])

  /* 點選查詢按鈕 */
  const handleSearch = () => {
    setPayload({
      ...payload,
      keyword: search.keyword,
      condition: search.condition,
      p: 1,
      isSearch: true,
    })
  }

  const handleExchangeTypeSwitch = useCallback(async () => {
    try {
      const body = {
        giftCodeType: setting.create,
        giftCodeGetType: setting.receive,
        giftCodePrefix: setting.prefix,
        giftCodeLength: setting.lengthLimit || 0,
      }
      setMask(true)
      const rsp = await switchVipExchangeCodeType(brandId, giftSettingId, body)
      setStateFromEdit(tabStatus.unavailable)
      showToast(rsp, '變更序號設定成功')
      await handleSearch()
      setSetting((prev) => ({
        ...prev,
        initCreate: prev.create,
        initReceive: prev.receive,
        initPrefix: prev.prefix,
        initLengthLimit: prev.lengthLimit,
        isSetting: true,
        isChange: false,
      }))
      setShowListField(true)
    } catch (error) {
      console.log(
        '🚀 ~ file: ExclusiveSerialNumber.js ~ line 335 ~ handleExchangeTypeSwitch ~ error',
        error
      )
      setShowListField(false)
      return Promise.reject(error)
    } finally {
      setMask(false)
    }
  }, [brandId, giftSettingId, handleSearch, setMask, setStateFromEdit, setting])

  const updateRuleSetting = useCallback(async () => {
    const isCreateBySystem = setting.create === 20
    const postData = isCreateBySystem
      ? setting
      : { ...setting, prefix: '', lengthLimit: 0 }
    try {
      const { confirmTime, stateFromEdit } = await handleRuleSetting({
        brandId,
        giftSettingId,
        data: postData,
      })
      if (stateFromEdit === tabStatus.available) {
        handleEvent(headerEventType.goList)
      }
      setStateFromEdit(stateFromEdit)
      setPageData((prev) => ({
        ...prev,
        setting: { ...prev.setting, date: confirmTime },
      }))
      setSetting((prev) => ({
        ...prev,
        initCreate: postData.create,
        initReceive: postData.receive,
        initLengthLimit: postData.lengthLimit,
        initPrefix: postData.prefix,
        isSetting: true,
        isChange: false,
      }))
      setShowListField(true)
    } catch (error) {
      handleApiError({ error, updateFn: setValid })
      setShowListField(false)
    }
  }, [brandId, giftSettingId, setStateFromEdit, setting, handleEvent])

  /* 確認變更序號設定 */
  const handleConfirmSetting = async () => {
    setConfirmSettingBtnLoading(true)
    const res = await handleValid({
      data: setting,
      updateValidState: setValid,
    })
    if (!res) {
      setConfirmSettingBtnLoading(false)
      return
    } else {
      try {
        if (pageData.total.totalCount > 0) await handleExchangeTypeSwitch()
        await updateRuleSetting()
      } catch (error) {
        console.log(
          '🚀 ~ file: ExclusiveSerialNumber.js ~ line 410 ~ handleConfirmSetting ~ error',
          error
        )
      } finally {
        setConfirmSettingBtnLoading(false)
      }
    }
  }

  /* 點選確認規則 */
  const handleSubmit = useCallback(async () => {
    const isSettingEmpty =
      setting.initCreate === '' && setting.initReceive === ''
    const isSettingDiff =
      setting.create !== setting.initCreate ||
      setting.receive !== setting.initReceive
    // 設定過規則，要再變更規則，跳出變更確認modal
    const isDiffExistedRule =
      setting.isSetting && isSettingDiff && !isSettingEmpty
    if (isDiffExistedRule)
      return setSettingModal({ status: true, confirm: false })
    const validResult = await handleValid({
      data: setting,
      updateValidState: setValid,
    })
    if (!validResult) return
    setConfirmRuleBtnLoading(true)
    try {
      await updateRuleSetting()
    } catch (error) {
      console.error('專屬好禮確認規則錯誤', error)
    } finally {
      setConfirmRuleBtnLoading(false)
    }
  }, [setting, updateRuleSetting])

  /* 清空關鍵字，不進行其他處理 */
  const handleClearKeyword = () => setSearch({ ...search, keyword: '' })

  /* modal 確認變更序號設定 */
  const handleSettingModalConfirm = async () => {
    setSettingModal({ status: false, confirm: true })
    await handleConfirmSetting()
    setSettingModal((prev) => ({ ...prev, confirm: null }))
  }

  /* 不變更序號設定 */
  const handleSettingModalClose = () => {
    setSettingModal({ status: false, confirm: false })
    setConfirmSettingBtnLoading(false)
  }

  /* 批次刪除 API */
  const handleBatchRemove = useCallback(
    async (e, { handleButtonLocalLoading }) => {
      handleButtonLocalLoading(true)
      const body = { rows: selectedList }
      try {
        const rsp = await batchDeleteVipExchangeCode(
          brandId,
          giftSettingId,
          body
        )
        setStateFromEdit(tabStatus.unavailable)
        showToast(rsp, '批次刪除成功')
        setSelectedList([])
        await handleSearch()
      } catch (e) {
        console.error(e)
      } finally {
        handleButtonLocalLoading(false)
      }
    },
    [selectedList, brandId, giftSettingId, setStateFromEdit, handleSearch]
  )

  /* 關閉 Modal */
  const handleModalClose = () => {
    setModalOpen(false)
    dispatch(resetMemberOutline())
    dispatch(resetVipGiftExchangeDetailModal())
  }

  /* 查看會員詳情，開啟新 Tab */
  const handleMemberModalConfirm = useCallback(() => {
    window.open(`/member/edit/${modalContent.memberId}`)
    handleModalClose()
  }, [modalContent])

  const handleImportModalConfirm = useCallback(() => {
    setImportModal(false)
    setMask(false)
    history.push(PAGE_PATHS.gift.exclusive, {
      apiPayload: history.location.state.apiPayload,
    })
  }, [history])

  /* 取得會員資料 */
  const handleGetModalContent = ({ memberId, vipGiftExchangeCodeId }) => {
    try {
      dispatch(
        getVipExchangeCodeDetailThunk({
          brandId,
          vipGiftSettingId: giftSettingId,
          vipGiftExchangeCodeId: vipGiftExchangeCodeId,
        })
      )
      dispatch(getMemberOutlineThunk({ memberId, brandId }))
      setModalOpen(true)
    } catch (error) {
      console.log(
        '🚀 ~ file: ExclusiveSerialNumberSubcomponents.js ~ line 368 ~ handleClick ~ error',
        error
      )
    }
  }

  const columns = [
    { dataField: 'serialNumber', text: 'No.', sort: true },
    {
      dataField: 'giftNumber',
      text: '好禮序號',
      tip: ['新增後無法編輯', '請先刪除，再次新增'],
      headerFormatter: headerFormatterGiftNumber,
      formatter: formatterGiftNumber,
      formatExtraData: {
        handleSearch: getListData,
        brandId,
        giftSettingId,
        disabled: isGiftCodeInputDisabled,
        updateFn: (res) => setStateFromEdit(res),
        handleEvent,
        setTransparentMask,
      },
    },
    {
      dataField: 'member',
      text: '會員',
      formatter: (cell, row) => {
        const isSerialNumberExist = row.serialNumber
        // 單一序號送多名會員的情況
        if (isReceiveSame && isSerialNumberExist) {
          return `已領 ${number(
            pageData.total.conditionReceivedCount
          )}, 已送 ${number(pageData.total.sendedCount)}`
        }
        return cell
      },
      events: {
        onClick: (e, column, columnIndex, row) => {
          const { memberId, memberName, vipGiftExchangeCodeId } = row
          // 沒有 ID 或格子上沒有名稱，就不進行處理
          if (!memberId || !memberName) return
          handleGetModalContent({ memberId, vipGiftExchangeCodeId })
        },
      },
      style: (cell, row) => {
        const isSerialNumberExist = row.serialNumber
        // 空格子或單一序號送多名會員的情況，不以連結樣式顯示
        if (!cell || (isReceiveSame && isSerialNumberExist)) return {}
        return {
          color: colorObj.primary,
          cursor: 'pointer',
          textDecoration: 'underline',
        }
      },
    },
    { dataField: 'phone', text: '手機' },
    { dataField: 'sendTime', text: '發送時間', sort: true },
    { dataField: 'receiveChannel', text: '領取通路', sort: true },
    {
      dataField: 'status',
      text: '狀態',
      tip: ['依回傳資料', '更新內容與狀態'],
      sort: true,
      headerFormatter: headerFormatterStatus,
      formatter: formatterStatus,
    },
  ]

  useEffect(() => {
    if (pageType !== tabTypeConfig.serialNumber)
      setPayload((prev) => ({ ...prev, isSearch: false }))
  }, [pageType])

  /* 獲取 Table 資料 */
  useEffect(() => {
    if (pageType === tabTypeConfig.serialNumber) getListData()
  }, [pageType, getListData])

  useEffect(() => {
    if (
      infoState.systemNumber !== '-' &&
      pageType === tabTypeConfig.serialNumber
    ) {
      setSetting({
        initCreate: infoState.giftCodeType,
        initReceive: infoState.giftCodeGetType,
        initPrefix: infoState.giftCodePrefix,
        initLengthLimit: infoState.giftCodeLength,
        create: infoState.giftCodeType,
        receive: infoState.giftCodeGetType,
        prefix: infoState.giftCodePrefix,
        lengthLimit: infoState.giftCodeLength,
        isSetting: true,
      })
      setShowListField(
        (prev) => prev || (infoState.giftCodeType && infoState.giftCodeType)
      )
      setPageData((prev) => ({
        ...prev,
        setting: { ...prev.setting, tip: infoState.barcodeTypeNotice },
      }))
    }
  }, [pageType, infoState, setting.isSetting])

  useEffect(() => {
    const isFirstPage = setting.isSetting && payload.p === 1
    const isUserSetting = isUser(setting.create)
    const isSystemSetting = isSystem(setting.create)
    const isUserSettingRowExist = pageData.data.some(
      (i) => i.serialNumber === null
    )

    if (isUserSettingRowExist) {
      if (isSystemSetting) {
        return setPageData((prev) => ({
          ...prev,
          data: prev.data.filter((i) => i.serialNumber !== null),
        }))
      }
      return
    }

    if (isFirstPage && isUserSetting && !isView) {
      setPageData((prev) => ({
        ...prev,
        data: [{ serialNumber: null }, ...prev.data],
      }))
    }
  }, [payload.p, setting, pageData.data, isView])

  // 為了取得新增贈品後序號，這邊須在新增後再次取得序號
  useEffect(() => {
    if (brandId && giftSettingId && !giftEventId)
      fetchVipGiftInfo(brandId, giftSettingId)
  }, [brandId, giftSettingId])

  return (
    <ExclusiveSerialNumberWrapper>
      <Form>
        <Form.Row>
          <Form.Group>
            設定並產生序號，作為會員領取憑證。Lit
            將自動發送序號給符合資格之會員。
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Col lg="6">
            <Form.Group>
              <Form.Label>序號建立：</Form.Label>
              <Col>
                <Row>
                  {createOptions.map((option) => (
                    <Radio
                      key={option.key}
                      id={option.key}
                      value={option.value}
                      label={option.text}
                      checked={option.value === setting.create}
                      onChange={handleSettingChange('create')}
                      disabled={isView}
                    />
                  ))}
                </Row>
                {/* 是否為自行新增 */}
                {isUser(setting.create) && (
                  <Row>
                    <Form.Text>
                      {pageData.setting.tip ||
                        '序號規則需符合 Code-128：半形英數或符號'}
                    </Form.Text>
                  </Row>
                )}
              </Col>
            </Form.Group>
          </Col>
          {setting.create && (
            <Col lg="6">
              <Form.Group>
                <Form.Label>序號領取：</Form.Label>
                <Col className="form-column-list">
                  {receiveOptions.map((option) => (
                    <Radio
                      key={option.key}
                      id={option.key}
                      value={option.value}
                      label={option.text}
                      checked={option.value === setting.receive}
                      onChange={handleSettingChange('receive')}
                      disabled={isView || setting.create === create.bySystem}
                    />
                  ))}
                  <InValidFeedback show={valid.receive.valid}>
                    {valid.receive.msg}
                  </InValidFeedback>
                </Col>
              </Form.Group>
            </Col>
          )}
          {isSystem(setting.create) && (
            <>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>序號前綴：</Form.Label>
                  <Col>
                    <Row>
                      <Input
                        className="form-row-input-fill"
                        onChange={handleSettingChange('prefix')}
                        formControlOption={{
                          value: setting.prefix,
                          placeholder: '4 碼半形大寫英數',
                          maxLength: 4,
                          disabled: isView,
                        }}
                      />
                    </Row>
                    <Row>
                      <InValidFeedback show={valid.prefix.valid}>
                        {valid.prefix.msg}
                      </InValidFeedback>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>序號總長：</Form.Label>
                  <Col>
                    <Row>
                      <Select
                        className="form-row-input-fill"
                        selectedValue={setting.lengthLimit}
                        optionItems={lengthLimitOptions}
                        formControlOption={{
                          placeholder: '請選擇',
                        }}
                        dropdownToggleOption={{ disabled: isView }}
                        onChange={handleSettingChange('lengthLimit')}
                      />
                    </Row>
                    <Row>
                      <InValidFeedback show={valid.lengthLimit.valid}>
                        {valid.lengthLimit.msg}
                      </InValidFeedback>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
            </>
          )}
          <Col lg="12">
            <Form.Group className="col-last">
              <span>
                {pageData.setting.date
                  ? `序號規則已於 ${pageData.setting.date} 更新`
                  : ''}
              </span>
              <Button
                isLoading={confirmRuleBtnLoading}
                variant={isSubmitBtnDisabled ? `${color.gray}` : 'primary'}
                onClick={handleSubmit}
                disabled={isSubmitBtnDisabled}
              >
                確認規則
              </Button>
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
      {showListField && (
        <Form.Group className="search-area">
          <Form.Label>查詢清單：</Form.Label>
          <Select
            selectedValue={searchOptions[0].value}
            optionItems={searchOptions}
            onChange={handleSearchCondition}
          />
          <Input
            onChange={handleSearchKeyword}
            formControlOption={{ value: search.keyword }}
          />
          <Button size="sm" variant="primary" onClick={handleSearch}>
            查詢
          </Button>
          <Button
            size="sm"
            variant="outline-darkerGray"
            onClick={handleClearKeyword}
          >
            清除資料
          </Button>
        </Form.Group>
      )}
      {showListField && (
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            sizePerPageList: [
              { text: '25 筆', value: 25 },
              { text: '50 筆', value: 50 },
              { text: '100 筆', value: 100 },
            ],
            onPageChange: handlePageChange,
            onSizePerPageChange: handleSizePerPageChange,
            totalSize: pageData.total.conditionTotalCount,
            page: payload.p,
            sizePerPage: payload.ps,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <Caption
                paginationProps={paginationProps}
                total={pageData.total}
                uploadCount={infoState.giftAmount}
                uploadDisabled={isUploadBtnDisabled}
                removeDisabled={isRemoveBtnDisabled}
                handleUpload={() => {
                  setMask(true)
                  setUploadModal(true)
                }}
                handleRemove={handleBatchRemove}
                validHidden={isCaptionValidShow}
                isCreateByUser={isUser(setting.create)}
                isReceiveSame={isSame(setting.receive)}
              />
              <Table
                headerClasses="table-header"
                bodyClasses="paragraph"
                className="overflow-auto"
                keyField="serialNumber"
                data={pageData.data}
                columns={columns}
                selectRow={{
                  mode: 'checkbox',
                  nonSelectable: isReceiveSame
                    ? [null, 1]
                    : [
                        null,
                        ...pageData.data
                          .filter(
                            (i) =>
                              i.status === status.sended ||
                              i.status === status.received
                          )
                          .map((i) => i.no),
                      ],
                  selected: selectedList.map((i) => i.no),
                  onSelect: handleSelect,
                  onSelectAll: handleSelectAll,
                  hideSelectColumn: isView,
                }}
                striped
                remote
                onTableChange={handleTableChange}
                noDataIndication={
                  <NoDataIndication message="系統將於判斷會員符合資格時，自動產生並發送對應序號" />
                }
                {...paginationTableProps}
              />
              <PaginationListStandalone {...paginationProps} />
            </>
          )}
        </PaginationProvider>
      )}
      <SettingChangedModal
        show={settingModal.status}
        isLoading={confirmSettingBtnLoading}
        onConfirm={handleSettingModalConfirm}
        onClose={handleSettingModalClose}
      />
      <UploadModal
        show={uploadModal}
        onClose={() => {
          setUploadModal(false)
          setMask(false)
        }}
        onConfirm={handleUploadCsv({
          brandId,
          giftSettingId,
          handleSearch,
          setUploadModal,
          setImportModal,
          setMask,
          setStateFromEdit,
          systemNumber:
            infoState.systemNumber === '-'
              ? giftEventId
              : infoState.systemNumber,
          eventName: infoState.eventName,
          handleSyncUploadProgress: () => {
            dispatch(startConnection({ module: 'vipGift' }))
          },
        })}
      />
      <ImportModal
        show={importModal}
        onClose={handleImportModalConfirm}
        onConfirm={handleImportModalConfirm}
      />
      <MemberModal
        show={isModalOpen}
        memberOutline={modalContent}
        onClose={handleModalClose}
        onConfirm={handleMemberModalConfirm}
      />
    </ExclusiveSerialNumberWrapper>
  )
}

export default ExclusiveSerialNumber
