import styled from 'styled-components'
import { ReactComponent as IconDiamond } from 'assets/images/icon_diamond.svg'
import { ReactComponent as IconHomepage } from 'assets/images/menubar/icon_homepage.svg'
import { ReactComponent as IconMemberList } from 'assets/images/menubar/icon_memberList.svg'
import { ReactComponent as IconMembershipSetting } from 'assets/images/menubar/icon_membershipSetting.svg'
import { ReactComponent as IconActivitySetting } from 'assets/images/menubar/icon_menubar_barcode.svg'
import { ReactComponent as IconThemedDashboard } from 'assets/images/menubar/icon_menubar_dashboard.svg'
import { ReactComponent as IconSystemSetting } from 'assets/images/menubar/icon_menubar_system.svg'
import { ReactComponent as IconPointSetting } from 'assets/images/menubar/icon_pointSetting.svg'
import { PAGE_PATHS, PAGE_PERMISSIONS } from 'constant'

const SubmenuIsOpened = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #b8b8b8 transparent transparent transparent;
`

const SubmenuIsClosed = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 6px 5px 0;
  border-color: transparent #b8b8b8 transparent transparent;
`

const SidebarStruct = [
  {
    title: '首頁',
    path: '/',
    icon: <IconHomepage />,
    id: 'home',
    permissionKeys: PAGE_PERMISSIONS.home,
    subNav: [],
  },
  {
    title: '數據分析',
    path: '/data-analysis',
    icon: <IconThemedDashboard />,
    id: 'dataAnalysis',
    permissionKeys: PAGE_PERMISSIONS.dataAnalysis,
    subNav: [],
  },
  {
    title: '會員名單',
    path: '#',
    icon: <IconMemberList />,
    iconClosed: <SubmenuIsClosed className="icon-menu-switch" />,
    iconOpened: <SubmenuIsOpened className="icon-menu-switch" />,
    permissionKeys: [
      ...PAGE_PERMISSIONS.memberSearch,
      ...PAGE_PERMISSIONS.memberNew,
    ],
    subNav: [
      {
        title: '會員查詢',
        path: '/member',
        cName: 'sub-nav',
        id: 'memberSearch',
        permissionKeys: PAGE_PERMISSIONS.memberSearch,
        linkedPage: ['/member/edit'],
      },
      {
        title: '會員新增',
        path: '/member/new',
        cName: 'sub-nav',
        id: 'memberAdd',
        permissionKeys: PAGE_PERMISSIONS.memberNew,
        linkedPage: [],
      },
    ],
    id: 'memberList',
  },
  {
    title: '會籍設定',
    path: '/membership-setting',
    icon: <IconMembershipSetting />,
    id: 'memberLevel',
    permissionKeys: PAGE_PERMISSIONS.membershipSetting,
    subNav: [],
  },
  {
    title: '點數內容',
    path: '#',
    icon: <IconDiamond />,
    iconClosed: <SubmenuIsClosed className="icon-menu-switch" />,
    iconOpened: <SubmenuIsOpened className="icon-menu-switch" />,
    permissionKeys: [
      ...PAGE_PERMISSIONS.pointSetting,
      ...PAGE_PERMISSIONS.pointHistory,
    ],
    subNav: [
      {
        title: '點數設定',
        path: '/point-setting',
        id: 'pointSetting',
        permissionKeys: PAGE_PERMISSIONS.pointSetting,
        linkedPage: [],
      },
      {
        title: '點數歷程',
        path: '/point-history',
        id: 'pointHistory',
        permissionKeys: PAGE_PERMISSIONS.pointHistory,
        linkedPage: [],
      },
    ],
    id: 'pointContent',
  },
  {
    title: '活動條碼',
    path: '#',
    icon: <IconActivitySetting />,
    iconClosed: <SubmenuIsClosed className="icon-menu-switch" />,
    iconOpened: <SubmenuIsOpened className="icon-menu-switch" />,
    permissionKeys: [
      ...PAGE_PERMISSIONS.pointActivity,
      ...PAGE_PERMISSIONS.productActivity,
      ...PAGE_PERMISSIONS.qrcodeActivity,
    ],
    subNav: [
      {
        title: '點數活動',
        path: '/point-activity',
        id: 'pointActivity',
        permissionKeys: PAGE_PERMISSIONS.pointActivity,
        linkedPage: [],
      },
      {
        title: '商品活動',
        path: '/product-activity',
        id: 'productActivity',
        permissionKeys: PAGE_PERMISSIONS.productActivity,
        linkedPage: ['/product-management', '/product-edit'],
      },
      {
        title: 'QRCode 活動',
        path: '/qrcode-activity',
        id: 'qrcodeActivity',
        permissionKeys: PAGE_PERMISSIONS.qrcodeActivity,
        linkedPage: ['/qrcode-activity-content'],
      },
      {
        title: '活動表單紀錄',
        path: '/activity-form-record',
        id: 'activityFormRecord',
        permissionKeys: PAGE_PERMISSIONS.activityFormRecord,
        linkedPage: [],
      },
    ],
    id: 'activityBarcode',
  },
  {
    title: '好禮贈送',
    path: '#',
    icon: <IconPointSetting />,
    iconClosed: <SubmenuIsClosed className="icon-menu-switch" />,
    iconOpened: <SubmenuIsOpened className="icon-menu-switch" />,
    permissionKeys: PAGE_PERMISSIONS.giftList,
    subNav: [
      {
        title: '專屬好禮',
        path: PAGE_PATHS.gift.exclusive,
        id: 'giftExclusive',
        permissionKeys: PAGE_PERMISSIONS.giftExclusive,
        linkedPage: ['/gift-exclusive-setting'],
      },
      {
        title: '贈品兌換',
        path: PAGE_PATHS.gift.exchange,
        id: 'giftExchange',
        permissionKeys: PAGE_PERMISSIONS.giftExchange,
        linkedPage: ['/gift-setting'],
      },
      {
        title: '好禮名單',
        path: '/gift-list',
        id: 'giftList',
        permissionKeys: PAGE_PERMISSIONS.giftList,
        linkedPage: [],
      },
    ],
    id: 'giftGive',
  },
  {
    title: '系統設定',
    path: '#',
    icon: <IconSystemSetting />,
    iconClosed: <SubmenuIsClosed className="icon-menu-switch" />,
    iconOpened: <SubmenuIsOpened className="icon-menu-switch" />,
    permissionKeys: [
      ...PAGE_PERMISSIONS.companyToken,
      ...PAGE_PERMISSIONS.accountManagement,
    ],
    subNav: [
      {
        title: '帳號管理',
        path: '/account-management',
        id: 'accountManagement',
        permissionKeys: PAGE_PERMISSIONS.accountManagement,
        linkedPage: [
          '/account-management-content/new',
          '/account-management-content/edit',
        ],
      },
      {
        title: '廠商 Token',
        path: '/company-token',
        id: 'companyToken',
        permissionKeys: PAGE_PERMISSIONS.companyToken,
        linkedPage: [],
      },
    ],
    id: 'systemSetting',
  },
]

export default SidebarStruct
