import styled from 'styled-components'
import { colorCommon, colorObj } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

/* 新增 Token */
const AddTokenStyle = styled.div`
  width: 400px;
  text-align: left;

  .form-label {
    min-width: 80px;
    color: ${colorObj.darkGray};
  }
`

/* 取得 Token */
const TokenInfoStyle = styled.div`
  min-width: 318px;

  .info-group {
    border-bottom: 1px solid ${colorCommon.borderGray};
    margin-bottom: ${spacingObj.medium};

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;

      .info-list {
        margin-bottom: 0;
      }
    }
    .info-list {
      margin-bottom: ${spacingObj.medium};

      .list-title {
        display: inline-block;
        min-width: 90px;
        margin-right: 10px;
        color: ${colorObj.darkGray};
      }
      .list-value {
        word-break: break-all;
      }
    }
  }
`
export { AddTokenStyle, TokenInfoStyle }
