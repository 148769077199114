import { Fragment, useEffect, useState } from 'react'
import { FormCheck } from 'react-bootstrap'
import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const monthlyWeekOption = {
  serialList: [
    {
      text: '第一個',
      name: 'first',
      value: 1,
      checked: false,
    },
    {
      text: '第二個',
      name: 'second',
      value: 2,
      checked: false,
    },
    {
      text: '第三個',
      name: 'third',
      value: 3,
      checked: false,
    },
    {
      text: '第四個',
      name: 'fourth',
      value: 4,
      checked: false,
    },
    {
      text: '最後的',
      name: 'last',
      value: 'last',
      checked: false,
    },
  ],
  weekList: [
    {
      text: '一',
      name: 'mon',
      value: 'mon',
      checked: false,
    },
    {
      text: '二',
      name: 'tue',
      value: 'tue',
      checked: false,
    },
    {
      text: '三',
      name: 'wed',
      value: 'wed',
      checked: false,
    },
    {
      text: '四',
      name: 'thu',
      value: 'thu',
      checked: false,
    },
    {
      text: '五',
      name: 'fri',
      value: 'fri',
      checked: false,
    },
    {
      text: '六',
      name: 'sat',
      value: 'sat',
      checked: false,
    },
    {
      text: '日',
      name: 'sun',
      value: 'sun',
      checked: false,
    },
  ],
}

// css樣式
const MonthlyWeekStyle = styled.div`
  width: 337px;

  .serial-list-container {
    .form-check-inline {
      width: calc(100% / 5);
    }
  }
  .week-list-container {
    .form-check-inline {
      width: calc(100% / 7);
    }
  }
  .form-check-inline {
    color: ${colorObj.darkerGray};
    justify-content: center;
    align-items: center;
    margin: 0;
    border-color: ${colorObj.darkerGray};
    border-width: 1px 1px 1px 0;
    border-style: solid;

    :first-of-type {
      border-left-width: 1px;
      border-radius: ${spacingObj.micro} 0 0 ${spacingObj.micro};
    }
    :last-of-type {
      border-radius: 0 ${spacingObj.micro} ${spacingObj.micro} 0;
    }
    > input {
      display: none;

      :checked {
        + label {
          color: ${colorObj.white} !important;
          background-color: ${colorObj.primary};
        }
      }
    }
    > label {
      width: 100%;
      cursor: pointer;
      text-align: center;
      padding: ${spacingObj.mini} 0;
      white-space: nowrap;
      color: ${colorObj.darkerGray} !important;
    }
  }
`

function MonthlyWeek({ onChange, className, fieldName, value, isInit }) {
  const [option, setOption] = useState(monthlyWeekOption)
  const [isFirstLoad, setFirstLoad] = useState(true)

  const handleChange = (e, index, type) => {
    let obj = {}

    const newState = JSON.parse(JSON.stringify(option))
    const checkedItem = JSON.parse(JSON.stringify(option[type][index]))
    checkedItem.checked = e.target.checked
    newState[type][index] = checkedItem

    const serialObj = {}
    for (const item of newState.serialList) {
      const { name, checked } = item
      serialObj[name] = checked
    }

    const weekListObj = {}
    for (const item of newState.weekList) {
      const { name, checked } = item
      weekListObj[name] = checked
    }

    if (fieldName) {
      obj = {
        [fieldName]: {
          ...serialObj,
          dayOfWeek: {
            ...weekListObj,
          },
        },
      }
    }
    setOption(newState)

    setFirstLoad(false)

    if (typeof onChange === 'function') {
      onChange(e, index, obj, type)
    }
  }

  // 處理是否reset
  useEffect(() => {
    if (isInit) {
      setOption(monthlyWeekOption)
    }
  }, [isInit])

  // 處理初次載入時，有值的情況(編輯狀態)
  useEffect(() => {
    if (isFirstLoad && value) {
      const { dayOfWeek, ...serial } = value
      const deepCloneMonthlyWeekOption = JSON.parse(
        JSON.stringify(monthlyWeekOption)
      )
      for (const serialKeyName in serial) {
        for (const [
          index,
          item,
        ] of deepCloneMonthlyWeekOption.serialList.entries()) {
          const { name } = item
          if (name === serialKeyName) {
            deepCloneMonthlyWeekOption.serialList[index].checked =
              serial[serialKeyName]
            break
          }
        }
      }
      for (const weekKeyName in dayOfWeek) {
        for (const [
          index,
          item,
        ] of deepCloneMonthlyWeekOption.weekList.entries()) {
          const { name } = item
          if (name === weekKeyName) {
            deepCloneMonthlyWeekOption.weekList[index].checked =
              dayOfWeek[weekKeyName]
            break
          }
        }
      }
      setOption(deepCloneMonthlyWeekOption)
    }
  }, [isFirstLoad, value])

  return (
    <MonthlyWeekStyle>
      <div className="serial-list-container">
        {option.serialList.map((item, index) => {
          const { name, value, text, checked, ...prop } = item
          return (
            <Fragment key={`monthly-week-serial-list${index}`}>
              <FormCheck
                {...prop}
                type="checkbox"
                className={className}
                name={name}
                value={value}
                inline
                label={text}
                // id取隨機值(主要是讓點選label也能觸發checkbox)
                id={prop?.id || Math.random().toString(36).substring(2)}
                checked={checked}
                onChange={(e) => handleChange(e, index, 'serialList')}
              />
            </Fragment>
          )
        })}
      </div>
      <div className="week-list-container mt-2">
        {option.weekList.map((item, index) => {
          const { name, value, text, checked, ...prop } = item
          return (
            <Fragment key={`monthly-week-week-list${index}`}>
              <FormCheck
                {...prop}
                type="checkbox"
                className={className}
                name={name}
                value={value}
                inline
                label={text}
                // id取隨機值(主要是讓點選label也能觸發checkbox)
                id={prop?.id || Math.random().toString(36).substring(2)}
                checked={checked}
                onChange={(e) => handleChange(e, index, 'weekList')}
              />
            </Fragment>
          )
        })}
      </div>
    </MonthlyWeekStyle>
  )
}

export default MonthlyWeek
