import { useEffect, useRef, useState } from 'react'
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import { Col, Button, Tooltip, Input, Modal, Loader } from 'components/units'
import { InValidFeedback } from 'components/templates'
import { number } from 'helpers/format'
import { downloadCsv } from 'helpers/common'
import { color } from 'assets/styles/Variable/Color'
import { addVipExchangeCode } from 'api/ApiMain'
import {
  ToastLoadingWrapper,
  TextLink,
  ImportModalWrapper,
  MemberModalWrapper,
  AddSerialnumber,
} from 'components/pages/Gift/ExclusiveSerialNumberStyle'
import { ReactComponent as TooltipSvg } from 'assets/images/icon_tooltip.svg'
import { statusFormatter } from 'helpers/tableElFormat'
import { headerEventType } from 'pages/Gift/ExclusiveSetting'
import { tabStatus } from 'pages/Gift/config'
import { showToast } from 'api/ApiCommon'
import { Form } from 'react-bootstrap'
import { CSV_EXAMPLE } from 'constant/csvExample'

const modalConfig = {
  setting: {
    title: '變更設定',
    content: ['變更序號設定，', '將刪除已建立之序號內容。', '是否繼續執行？'],
    close: '返回',
    confirm: '繼續',
  },
  upload: {
    title: '批次上傳',
    content: [
      '1. 序號總數不可高於 100 萬組',
      '2. 檔案格式限 csv 檔，檔案大小限 ≤ 25MB',
      '3. 欄位設定說明，詳見',
      '4. 檔案上傳結果將發送至帳號 E-mail 信箱',
    ],
    link: '匯入範本說明 (下載)',
    close: '關閉',
    confirm: '上傳檔案',
    isShowDefaultMask: false,
  },
  import: {
    title: '返回列表',
    content: [
      '好禮序號已上傳資料庫寫入中，結果將發送至帳號 E-mail 信箱',
      '寫入期間無法對此好禮項目進行編輯與刪除',
    ],
    confirm: '關閉',
    isHideCloseButton: true,
    closeBtnVisible: false,
    isShowDefaultMask: false,
  },
  memberInfo: {
    title: '會員資訊',
    sendRecordLabel: '發送紀錄',
    memberNumber: '會員編號：',
    memberName: '姓名：',
    memberPhone: '手機：',
    memberLevel: '會員等級：',
    memberProfileLabel: '會員輪廓',
    lastMember: '最新會員資料',
    birthday: '生日：',
    gender: '性別：',
    point: '剩餘點數：',
    price: '客單價：',
    lastDealDate: '上次消費日：',
    registeredDate: '入會時間：',
    shopName: '入會門店：',
    close: '關閉',
    confirm: '詳情',
    age: '歲',
  },
}

function Caption({
  paginationProps,
  total,
  uploadCount = 0,
  uploadDisabled,
  removeDisabled,
  handleUpload,
  handleRemove,
  validHidden,
  isCreateByUser,
  isReceiveSame,
}) {
  const { conditionReceivedCount, conditionTotalCount } = total

  const warnCount = isReceiveSame && isCreateByUser ? 1 : number(uploadCount)
  const removeBtnVariant = removeDisabled ? color.gray : `outline-${color.dark}`
  const uploadBtnVariant = uploadDisabled
    ? color.gray
    : `outline-${color.primary}`

  return (
    <div className="caption">
      <Col lg="6" className="label-title">
        <span className="white-space-nowrap">資料總計：</span>
        {conditionTotalCount > 0 ? (
          <div className="caption-text">
            已領取 {number(conditionReceivedCount)} / 共{' '}
            {number(conditionTotalCount)} 筆
          </div>
        ) : (
          <div className="caption-text">共 0 筆</div>
        )}

        <InValidFeedback show={validHidden}>
          序號數不符合發送數量 {warnCount}
        </InValidFeedback>
      </Col>
      <Col lg="6" className="label-title">
        <span className="white-space-nowrap mr-1">每頁</span>
        <SizePerPageDropdownStandalone
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
        {isCreateByUser && (
          <>
            <Button
              className="remove-btn"
              size="sm"
              variant={removeBtnVariant}
              title={`請先選擇\n欲刪除之序號項目`}
              onClick={handleRemove}
              disabled={removeDisabled}
            >
              批次刪除
            </Button>
            <Button
              className="last-btn"
              size="sm"
              variant={uploadBtnVariant}
              onClick={handleUpload}
              disabled={uploadDisabled}
            >
              批次上傳
            </Button>
          </>
        )}
      </Col>
    </div>
  )
}

function SettingChangedModal({ show = true, onClose, onConfirm, isLoading }) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      onConfirm={onConfirm}
      variant={color.danger}
      titleText={modalConfig.setting.title}
      confirmBtnText={modalConfig.setting.confirm}
      closeBtnText={modalConfig.setting.close}
      isLoading={isLoading}
    >
      {modalConfig.setting.content.map((text, key) => (
        <p key={key}>{text}</p>
      ))}
    </Modal>
  )
}

function UploadBtn({ onChange }) {
  const inputRef = useRef(null)
  return (
    <>
      <Button variant={color.primary} onClick={() => inputRef.current.click()}>
        {modalConfig.upload.confirm}
      </Button>
      <Input
        formControlOption={{
          style: { display: 'none' },
          ref: inputRef,
          type: 'file',
          accept: '.csv',
          onChange: (e) => {
            const [file] = e.currentTarget.files
            if (typeof onChange === 'function') onChange(file)
          },
        }}
      />
    </>
  )
}

function UploadModal({ show = true, onClose, onConfirm }) {
  const handleExampleDownload = () =>
    downloadCsv({
      data: CSV_EXAMPLE.EXCLUSIVE,
      fileName: '專屬好禮_匯入範本',
    })
  return (
    <Modal
      show={show}
      onClose={onClose}
      customConfirm={() => <UploadBtn onChange={onConfirm} />}
      titleText={modalConfig.upload.title}
      closeBtnText={modalConfig.upload.close}
      isShowDefaultMask={modalConfig.upload.isShowDefaultMask}
    >
      <ImportModalWrapper>
        {modalConfig.upload.content.map((text, key) => {
          if (key === 2)
            return (
              <p key={key}>
                {text}
                <TextLink onClick={handleExampleDownload}>
                  {modalConfig.upload.link}
                </TextLink>
              </p>
            )
          return <p key={key}>{text}</p>
        })}
      </ImportModalWrapper>
    </Modal>
  )
}

function ImportModal({ show = true, onClose, onConfirm }) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      onConfirm={onConfirm}
      titleText={modalConfig.import.title}
      confirmBtnText={modalConfig.import.confirm}
      isHideCloseButton={modalConfig.import.isHideCloseButton}
      closeBtnVisible={modalConfig.import.closeBtnVisible}
      isShowDefaultMask={modalConfig.import.isShowDefaultMask}
    >
      <ImportModalWrapper>
        <ul style={{ listStyleType: 'decimal', maxWidth: '400px' }}>
          {modalConfig.import.content.map((text, key) => (
            <li
              style={{
                marginBottom: '10px',
                lineHeight: '1.5',
              }}
              key={key}
            >
              {text}
            </li>
          ))}
        </ul>
      </ImportModalWrapper>
    </Modal>
  )
}

function MemberModal({ show = true, onClose, onConfirm, memberOutline }) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      onConfirm={onConfirm}
      titleText={modalConfig.memberInfo.title}
      confirmBtnText={modalConfig.memberInfo.confirm}
      closeBtnText={modalConfig.memberInfo.close}
      isDefaultDataLoading={!memberOutline.isReady}
      confirmBtnVisible={memberOutline.isMemberExist}
    >
      <MemberModalWrapper>
        <h5 className="member-title">
          {modalConfig.memberInfo.sendRecordLabel}
        </h5>
        <div className="member-info">
          <div className="member-label">
            {modalConfig.memberInfo.memberNumber}
          </div>
          <div className="member-content">{memberOutline?.customerId}</div>
        </div>
        <div className="member-info">
          <div className="member-label">
            {modalConfig.memberInfo.memberName}
          </div>
          <div className="member-content">{memberOutline?.name}</div>
        </div>
        <div className="member-info">
          <div className="member-label">
            {modalConfig.memberInfo.memberPhone}
          </div>
          <div className="member-content">{memberOutline?.phone}</div>
        </div>
        <div className="member-info">
          <div className="member-label">
            {modalConfig.memberInfo.memberLevel}
          </div>
          <div className="member-content">{memberOutline?.level}</div>
        </div>
        {memberOutline?.isMemberExist && (
          <>
            <hr />
            <h5 className="member-title">
              {modalConfig.memberInfo.memberProfileLabel}
              <span className="member-note">
                {modalConfig.memberInfo.lastMember}
              </span>
            </h5>
            <div className="member-info">
              <div className="member-label">
                {modalConfig.memberInfo.birthday}
              </div>
              <div className="member-content">
                {`${memberOutline?.age} ${modalConfig.memberInfo.age}`}
                <span className="member-note">{memberOutline?.yearMonth}</span>
              </div>
            </div>
            <div className="member-info">
              <div className="member-label">
                {modalConfig.memberInfo.gender}
              </div>
              <div className="member-content">{memberOutline?.gender}</div>
            </div>
            <div className="member-info">
              <div className="member-label">{modalConfig.memberInfo.point}</div>
              <div className="member-content">
                {memberOutline?.pointBalance}
              </div>
            </div>
            <div className="member-info">
              <div className="member-label">{modalConfig.memberInfo.price}</div>
              <div className="member-content">
                {memberOutline?.customerUnitPrice}
              </div>
            </div>
            <div className="member-info">
              <div className="member-label">
                {modalConfig.memberInfo.lastDealDate}
              </div>
              <div className="member-content">
                {memberOutline?.lastDealDate}
              </div>
            </div>
            <div className="member-info">
              <div className="member-label">
                {modalConfig.memberInfo.registeredDate}
              </div>
              <div className="member-content">
                {memberOutline?.registeredDate}
              </div>
            </div>
            <div className="member-info">
              <div className="member-label">
                {modalConfig.memberInfo.shopName}
              </div>
              <div className="member-content">{memberOutline?.shopName}</div>
            </div>
          </>
        )}
      </MemberModalWrapper>
    </Modal>
  )
}

const headerFormatterGiftNumber = ({ text, tip }) => (
  <>
    {text}
    <Tooltip triggerElement={<TooltipSvg />} globalOption={{ effect: 'solid' }}>
      {tip[0]}
      <br />
      {tip[1]}
    </Tooltip>
  </>
)

const headerFormatterStatus = ({ text, tip }, colIndex, { sortElement }) => {
  return (
    <>
      {text}
      <Tooltip
        triggerElement={<TooltipSvg />}
        globalOption={{ effect: 'solid' }}
      >
        {tip[0]}
        <br />
        {tip[1]}
      </Tooltip>
      {sortElement}
    </>
  )
}

function InputCell({
  data,
  brandId,
  giftSettingId,
  handleSearch,
  disabled,
  updateFn,
  handleEvent,
  setTransparentMask,
}) {
  const [state, setState] = useState('')
  const [valid, setValid] = useState(null)
  const [invalidMsg, setInvalidMsg] = useState({})
  const [inputDisabled, setInputDisabled] = useState(false)
  const inputRef = useRef(null)

  const appendContentConfig = inputDisabled
    ? {
        appendContent: <Loader size="sm" colorType="primary" />,
        appendContentBgColor: '#fff',
        appendContentHaveBorder: false,
        isFocusCocatAppend: true,
      }
    : {}
  const handleChange = (e) => {
    setState(e.target.value)
    setValid(true)
  }

  const handleKeyUp = async (e) => {
    // 按下Enter鍵
    if (e.keyCode === 13) {
      if (e.target.value === '') return
      setInputDisabled(true)
      setTransparentMask(true)
      try {
        const rsp = await addVipExchangeCode(brandId, giftSettingId, {
          giftCode: e.target.value,
        })
        const giftSettingStatus = rsp.data?.data?.vipGiftSettingStatus ?? null
        updateFn(giftSettingStatus)
        handleSearch()
        if (giftSettingStatus === tabStatus.available) {
          handleEvent(headerEventType.goList)
        }
        showToast(rsp, '新增好禮序號成功')
      } catch (error) {
        const errors = error?.errors
        const errorMsgGiftCode = errors?.giftCode ?? []

        if (errorMsgGiftCode?.length > 0) {
          setValid(false)
          setInvalidMsg({
            giftCode: errorMsgGiftCode[0],
          })
        }
      }
      setInputDisabled(false)
      setTransparentMask(false)
    }
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [data])

  return (
    <AddSerialnumber>
      <Input
        formControlOption={{
          type: 'text',
          ref: inputRef,
          value: state,
          onChange: handleChange,
          onKeyUp: handleKeyUp,
          disabled: disabled || inputDisabled,
          placeholder: '請輸入符合序號規則的字元',
          maxLength: 128,
        }}
        {...appendContentConfig}
      />
      <InValidFeedback show={valid}>{invalidMsg?.giftCode}</InValidFeedback>
      <Form.Text>點擊 Enter 確認送出</Form.Text>
    </AddSerialnumber>
  )
}

const formatterGiftNumber = (cell, data, idx, extraData) => {
  if (cell) return cell

  const brandId = extraData?.brandId
  const giftSettingId = extraData?.giftSettingId
  const handleSearch = extraData?.handleSearch
  const disabled = extraData?.disabled
  const updateFn = extraData?.updateFn
  const handleEvent = extraData?.handleEvent
  const setTransparentMask = extraData?.setTransparentMask

  return (
    <InputCell
      data={data}
      brandId={brandId}
      giftSettingId={giftSettingId}
      handleSearch={handleSearch}
      disabled={disabled}
      updateFn={updateFn}
      handleEvent={handleEvent}
      setTransparentMask={setTransparentMask}
    />
  )
}

const formatterStatus = (cell, row) => {
  const { statusDescription } = row
  let statusParameter = [false, false, false]
  // 代碼對照
  // 0: 未發送
  // 1: 已發送
  // 2: 已領取
  // 90: 已過期
  switch (cell) {
    case 0:
      statusParameter = [false, false, true]
      break
    case 1:
      break
    case 2:
      statusParameter = [true, false, false]
      break
    case 90:
      statusParameter = [false, false, true]
      break
    default:
      statusParameter = [false, false, true, false]
      break
  }
  return statusFormatter(statusDescription, ...statusParameter)
}

export {
  Caption,
  SettingChangedModal,
  UploadModal,
  ImportModal,
  MemberModal,
  headerFormatterGiftNumber,
  headerFormatterStatus,
  formatterGiftNumber,
  formatterStatus,
}
