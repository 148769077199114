import { PAGE_PATHS } from 'constant'
import { TOAST } from 'constant/common'

const VERIFY = {
  ENABLE_ACCOUNT: 'enableAccount',
  RESET_PASSWORD: 'resetPassword',
}

const ENABLE_ACCOUNT = {
  ENABLED: 1,
  TOKEN_VALID: 2,
  TOKEN_INVALID: 3,
}

const accountRedirectPaths = {
  [ENABLE_ACCOUNT.ENABLED]: PAGE_PATHS.login,
  [ENABLE_ACCOUNT.TOKEN_VALID]: PAGE_PATHS.verify.settingPassword,
  [ENABLE_ACCOUNT.TOKEN_INVALID]: PAGE_PATHS.verify.verifyFailed,
}

const RESET_PASSWORD = {
  TOKEN_VALID: 1,
  TOKEN_INVALID: 2,
  HAS_RESET: 3,
}

const passwordRedirectPaths = {
  [RESET_PASSWORD.TOKEN_VALID]: PAGE_PATHS.verify.forgetPassword,
  [RESET_PASSWORD.TOKEN_INVALID]: PAGE_PATHS.verify.verifyFailed,
  [RESET_PASSWORD.HAS_RESET]: PAGE_PATHS.login,
}

const PASSWORD_STATUS = {
  ENABLED: 1,
  TOKEN_INVALID: 2,
  ENABLED_PASSWORD_EXISTED: 3,
}

const passwordSettingToast = {
  [PASSWORD_STATUS.ENABLED]: {
    toastType: TOAST.SUCCESS,
    redirectPath: PAGE_PATHS.login,
  },
  [PASSWORD_STATUS.TOKEN_INVALID]: {
    toastType: TOAST.ERROR,
    redirectPath: PAGE_PATHS.verify.verifyFailed,
  },
  [PASSWORD_STATUS.ENABLED_PASSWORD_EXISTED]: {
    toastType: TOAST.ERROR,
    redirectPath: PAGE_PATHS.login,
  },
}

export {
  VERIFY,
  ENABLE_ACCOUNT,
  accountRedirectPaths,
  RESET_PASSWORD,
  passwordRedirectPaths,
  PASSWORD_STATUS,
  passwordSettingToast,
}
