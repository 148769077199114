import { startOfDay } from 'date-fns'

const fieldType = {
  systemNumber: 'systemNumber',
  linkGift: 'linkGift',
  eventName: 'eventName',
  eventType: 'eventType',
  eventStart: 'eventStart',
  eventEnd: 'eventEnd',
  receiveLimit: 'receiveLimit',
  receiveCount: 'receiveCount',
  receiveMethod: 'receiveMethod',
  receiveStart: 'receiveStart',
  receiveEnd: 'receiveEnd',
  target: 'target',
  consumerCondition: 'consumerCondition',
  giftType: 'giftType',
  giftSpecialOffer: 'giftSpecialOffer',
  giftPoint: 'giftPoint',
  giftImage: 'giftImage',
  giftCode: 'giftCode',
  giftName: 'giftName',
  giftColor: 'giftColor',
  giftSize: 'giftSize',
  giftAmount: 'giftAmount',
  giftInfo: 'giftInfo',
  autoUpload: 'autoUpload',
  giftCodeEndTimeType: 'giftCodeEndTimeType',
  giftCodeEndTimeAfterDays: 'giftCodeEndTimeAfterDays',
  giftCodeEndTime: 'giftCodeEndTime',
}

const resAtrr = {
  giftCodeCount: 'giftCodeCount',
  sendCount: 'sendCount',
}

const eventType = {
  admissionCeremony: 1,
  birthdayGift: 2,
  promotionCeremony: 3,
  renewalCeremony: 4,
  complimentaryFullAmount: 5,
  designatedList: 6,
  bindingGift: 7,
}

const receiveLimit = {
  monthlyPickupOnce: 1,
  yearlyPickupOnce: 2,
  onlyPickupOnce: 3,
  validityPeriodPickupOnce: 4,
  eventPeriodPickupSpecificTimes: 5,
  eventPeriodPickupUnlimited: 6,
}

const giftType = {
  offer: 1,
  point: 2,
  gift: 3,
}

const discountType = {
  discount: 10,
  consumerAmount: 20,
}

const receiveMethodType = {
  online: 10,
  shop: 20,
}

const giftCodeEndTimeType = {
  permanent: 1,
  calendar: 2,
  giftCodeEndTimeAfterDays: 3,
}
const giftCodeEndTimeAfterDays = {
  oneWeek: 7,
  twoWeek: 14,
  oneMonth: 30,
  twoMonth: 60,
  threeMonth: 90,
}

const contentType = {
  init: 'init',
  updateFromApi: 'updateFromApi',
  linkGift: 'linkGift',
  eventName: 'eventName',
  eventType: 'eventType',
  eventStart: 'eventStart',
  eventStart_Check: 'eventStart_Check',
  eventEnd: 'eventEnd',
  eventEnd_Check: 'eventEnd_Check',
  receiveLimit: 'receiveLimit',
  receiveCount: 'receiveCount',
  receiveMethod: 'receiveMethod',
  receiveStart: 'receiveStart',
  receiveStart_Check: 'receiveStart_Check',
  newMember: 'newMember',
  basicMember: 'basicMember',
  allMembers: 'allMembers',
  members: 'members',
  designatedList: 'designatedList',
  consumerCondition: 'consumerCondition',
  consumerCondition_Check: 'consumerCondition_Check',
  giftType: 'giftType',
  giftSpecialOfferDiscount: 'giftSpecialOfferDiscount',
  giftSpecialOfferDiscount_Check: 'giftSpecialOfferDiscount_Check',
  giftSpecialOfferConsumerAmount: 'giftSpecialOfferConsumerAmount',
  giftSpecialOfferConsumerAmount_Check: 'giftSpecialOfferConsumerAmount_Check',
  giftPoint: 'giftPoint',
  giftImage: 'giftImage',
  giftCode: 'giftCode',
  giftName: 'giftName',
  giftColor: 'giftColor',
  giftSize: 'giftSize',
  giftAmount: 'giftAmount',
  giftInfo: 'giftInfo',
  autoUpload: 'autoUpload',
  receiveEnd: 'receiveEnd',
  giftCodeEndTimeAfterDays: 'giftCodeEndTimeAfterDays',
  giftCodeEndTime: 'giftCodeEndTime',
}

const imageLimitConfig = {
  size: 2048, // file unit is KB
}

const validMsg = {
  empty: '尚未填寫',
  giftInfo: '上限 2,000 字',
  collectionEndPermanent: '兌換結束日為永久，領取結束日須相同',
}

const formConfig = {
  [fieldType.systemNumber]: {
    label: '系統編號：',
  },
  [fieldType.linkGift]: {
    label: '連結好禮：',
    placeholder: '請選擇',
    linkInfoFn: ({ text, id, isMain }) =>
      isMain ? `${text}(已連結${id})` : text,
    unlink: '解除連結',
  },
  [fieldType.eventName]: {
    label: '名稱：',
    placeholder: '上限 50 字',
    maxlength: 50,
  },
  [fieldType.eventType]: {
    label: '類別：',
    placeholder: '請選擇',
    selectOption: [
      {
        text: '入會禮',
        value: eventType.admissionCeremony,
      },
      {
        text: '綁定禮',
        value: eventType.bindingGift,
      },
      {
        text: '生日禮',
        value: eventType.birthdayGift,
      },
      {
        text: '升等禮',
        value: eventType.promotionCeremony,
      },
      {
        text: '續會禮',
        value: eventType.renewalCeremony,
      },
      {
        text: '滿額贈',
        value: eventType.complimentaryFullAmount,
      },
      {
        text: '指定禮',
        value: eventType.designatedList,
      },
    ],
  },
  [fieldType.eventStart]: {
    label: '發送起始：',
    tip: '於效期內，符合資格後立即發送給會員',
    [`tip${eventType.admissionCeremony}`]: '於效期內，符合資格後立即發送給會員',
    [`tip${eventType.bindingGift}`]: '於效期內，符合資格後立即發送給會員',
    [`tip${eventType.promotionCeremony}`]: '於效期內，符合資格後當日發送給會員',
    [`tip${eventType.renewalCeremony}`]: '於效期內，符合資格後當日發送給會員',
    [`tip${eventType.complimentaryFullAmount}`]:
      '於效期內，符合資格 8 日後發送給會員',
    [`tip${eventType.birthdayGift}`]: '於效期內，每日發送給效期內生日會員',
    radioOption: ['立即開始', '起始日'],
  },
  [fieldType.eventEnd]: {
    label: '發送結束：',
    radioOption: ['永久 (若發送完畢，將自動下架)', '結束日'],
  },
  [fieldType.receiveLimit]: {
    label: '發送限制：',
    placeholder: '請選擇',
    selectOption: [
      {
        text: '會員每月可發送乙次',
        value: receiveLimit.monthlyPickupOnce,
        event: [eventType.complimentaryFullAmount],
      },
      {
        text: '會員每年可發送乙次',
        value: receiveLimit.yearlyPickupOnce,
        event: [eventType.birthdayGift, eventType.complimentaryFullAmount],
      },
      {
        text: '會員終身可發送乙次',
        value: receiveLimit.onlyPickupOnce,
        event: [
          eventType.admissionCeremony,
          eventType.complimentaryFullAmount,
          eventType.bindingGift,
        ],
      },
      {
        text: '該會員等級效期內，可發送乙次',
        value: receiveLimit.validityPeriodPickupOnce,
        event: [
          eventType.renewalCeremony,
          eventType.promotionCeremony,
          eventType.complimentaryFullAmount,
        ],
      },
      {
        text: '好禮效期內，可發送指定次數',
        value: receiveLimit.eventPeriodPickupSpecificTimes,
        event: [eventType.complimentaryFullAmount],
      },
      {
        text: '好禮效期內，不限發送次數',
        value: receiveLimit.eventPeriodPickupUnlimited,
        event: [eventType.complimentaryFullAmount, eventType.designatedList],
      },
    ],
  },
  [fieldType.receiveCount]: {
    label: '領取次數：',
  },
  [fieldType.receiveMethod]: {
    label: '領取方式：',
    tip: '需建立『好禮序號』作為會員領取憑證',
    radioOption: ['線上領取', '門店領取'],
    tooltipFn: (show = false) =>
      show ? '已連結好禮，請先解除連結再修改內容' : null,
  },
  [fieldType.receiveStart]: {
    label: '領取起始：',
  },
  [fieldType.receiveEnd]: {
    label: '領取結束：',
    radioOption: [
      {
        text: '永久',
        value: giftCodeEndTimeType.permanent,
      },
      {
        text: '結束日',
        value: giftCodeEndTimeType.calendar,
      },
      {
        text: '發送後',
        value: giftCodeEndTimeType.giftCodeEndTimeAfterDays,
      },
    ],
  },
  [fieldType.giftCodeEndTimeType]: {
    label: '發送後：',
    placeholder: '請選擇',
    selectOption: [
      {
        text: '7 日內',
        value: giftCodeEndTimeAfterDays.oneWeek,
      },
      {
        text: '14 日內',
        value: giftCodeEndTimeAfterDays.twoWeek,
      },
      {
        text: '30 日內',
        value: giftCodeEndTimeAfterDays.oneMonth,
      },
      {
        text: '60 日內',
        value: giftCodeEndTimeAfterDays.twoMonth,
      },
      {
        text: '90 日內',
        value: giftCodeEndTimeAfterDays.threeMonth,
      },
    ],
  },
  [fieldType.target]: {
    label: '對象：',
    radioOption: ['新會員', '指定等級'],
    checkboxOption: ['全會員', '註冊會員'],
    placeholder: '請指定 Thinker 名單',
    tip: '註冊但未達 Lv1 之會員',
  },
  [fieldType.consumerCondition]: {
    label: '消費條件：',
    content: '會員單筆消費滿 $',
    checkboxOption: ['消費金額不累計贈送'],
  },
  [fieldType.giftType]: {
    label: '內容：',
    placeholder: '請選擇',
    selectOption: [
      {
        text: '優惠',
        value: giftType.offer,
      },
      {
        text: '點數',
        value: giftType.point,
      },
      {
        text: '贈品',
        value: giftType.gift,
      },
    ],
    tooltipFn: (show = false) =>
      show ? '已連結好禮，請先解除連結再修改內容' : '',
  },
  [fieldType.giftSpecialOffer]: {
    label: '優惠：',
    radioOption: ['現金折扣', '消費金額'],
    maxlength: 8,
  },
  [fieldType.giftPoint]: {
    label: '點數：',
    maxlength: 8,
  },
  [fieldType.giftImage]: {
    label: '',
    tip: '圖檔格式限 .png 或 .jpg，檔案大小限 ≤ 2MB，尺寸建議 600*600px',
  },
  [fieldType.giftCode]: {
    label: '贈品識別碼：',
    placeholder: '贈品條碼/貨號',
    tip: '請輸入半形英數、符號，上限 128 字',
    maxlength: 128,
  },
  [fieldType.giftName]: {
    label: '贈品名稱：',
    placeholder: '上限 50 字',
    maxlength: 50,
  },
  [fieldType.giftColor]: {
    label: '顏色：',
    placeholder: '選填，上限 5 字',
    maxlength: 5,
  },
  [fieldType.giftSize]: {
    label: '尺寸：',
    placeholder: '選填，上限 5 字',
    maxlength: 5,
  },
  [fieldType.giftAmount]: {
    label: '發送數量：',
    placeholder: '上限 100 萬組',
  },
  [fieldType.giftInfo]: {
    label: '好禮介紹：',
    placeholder: validMsg.giftInfo,
  },
  [fieldType.autoUpload]: {
    label: '自動上架：',
    radioOption: ['依發送起始日，自動上架好禮', '不自動上架好禮'],
  },
}

const initValid = {
  [fieldType.systemNumber]: {
    valid: null,
    msg: validMsg.empty,
    required: null,
  },
  [fieldType.linkGift]: {
    valid: null,
    msg: validMsg.empty,
    required: null,
  },
  [fieldType.eventName]: {
    valid: null,
    msg: validMsg.empty,
    required: true,
  },
  [fieldType.eventType]: {
    valid: null,
    msg: validMsg.empty,
    required: true,
  },
  [fieldType.eventStart]: {
    valid: null,
    msg: validMsg.empty,
    required: true,
  },
  [fieldType.eventEnd]: {
    valid: null,
    msg: validMsg.empty,
    required: true,
  },
  [fieldType.receiveLimit]: {
    valid: null,
    msg: validMsg.empty,
    required: true,
  },
  [fieldType.receiveCount]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.receiveMethod]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.receiveStart]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.receiveEnd]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.target]: {
    valid: null,
    msg: validMsg.empty,
    required: true,
  },
  [fieldType.consumerCondition]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.giftType]: {
    valid: null,
    msg: validMsg.empty,
    required: true,
  },
  [fieldType.giftSpecialOffer]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.giftPoint]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.giftImage]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.giftCode]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.giftName]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.giftColor]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.giftSize]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.giftAmount]: {
    valid: null,
    msg: validMsg.empty,
    required: true,
  },
  [fieldType.giftInfo]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.autoUpload]: {
    valid: null,
    msg: validMsg.empty,
    required: true,
  },
  [fieldType.giftCodeEndTimeType]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.giftCodeEndTimeAfterDays]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
  [fieldType.giftCodeEndTime]: {
    valid: null,
    msg: validMsg.empty,
    required: false,
  },
}

const initContent = {
  [fieldType.systemNumber]: '-',
  [fieldType.linkGift]: {
    vipGiftSettingId: '',
    serialNo: '',
    content: '',
    isMain: '',
    linkList: [],
  },
  [fieldType.eventName]: '',
  [fieldType.eventType]: '',
  [fieldType.eventStart]: {
    check: '',
    calendar: startOfDay(new Date()),
  },
  [fieldType.eventEnd]: {
    check: '',
    calendar: startOfDay(new Date()),
  },
  [fieldType.receiveLimit]: '',
  [fieldType.receiveCount]: '',
  [fieldType.receiveMethod]: '',
  [fieldType.receiveStart]: startOfDay(new Date()),
  [fieldType.receiveEnd]: {
    check: '',
    calendar: startOfDay(new Date()),
  },
  [fieldType.target]: {
    forNewMember: '',
    forInitialMember: '',
    memberLevels: [],
    designatedList: [],
  },
  [fieldType.consumerCondition]: {
    check: '',
    condition: '',
  },
  [fieldType.giftType]: '',
  [fieldType.giftSpecialOffer]: {
    check: '',
    discount: '',
    consumerAmount: '',
  },
  [fieldType.giftPoint]: '',
  [fieldType.giftImage]: { url: '' },
  deleteExistImage: '',
  [fieldType.giftCode]: '',
  [fieldType.giftName]: '',
  [fieldType.giftColor]: '',
  [fieldType.giftSize]: '',
  [fieldType.giftAmount]: '',
  [fieldType.giftInfo]: '',
  [fieldType.autoUpload]: '',
  [resAtrr.giftCodeCount]: 0,
  [resAtrr.sendCount]: 0,
  vipGiftSettingId: -1,
  barcodeTypeNotice: '',
  giftCodeGetType: '',
  giftCodeLength: '',
  giftCodePrefix: '',
  giftCodeType: '',
  [fieldType.giftCodeEndTimeType]: '',
  [fieldType.giftCodeEndTimeAfterDays]: '',
  [fieldType.giftCodeEndTime]: startOfDay(new Date()),
}

export {
  fieldType,
  eventType,
  receiveLimit,
  giftType,
  discountType,
  receiveMethodType,
  formConfig,
  initValid,
  validMsg,
  contentType,
  initContent,
  imageLimitConfig,
  resAtrr,
}
