import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { color } from 'assets/styles/Variable/Color'
import { Modal } from 'components/units'

function WarnModal({ onClose, isShow }) {
  const history = useHistory()
  const handleClose = () => {
    onClose()
  }

  const handleConfirm = () => {
    onClose()
    history.push('/account-management')
  }

  return (
    <Modal
      show={isShow}
      backdrop="static"
      titleText="即將離開編輯"
      variant={color.danger}
      confirmBtnText="離開"
      closeBtnText="返回"
      onClose={handleClose}
      onConfirm={handleConfirm}
    >
      <div className="modal-confirm-body">
        已編輯的資料不會儲存，
        <br />
        確定離開編輯？
      </div>
    </Modal>
  )
}

export default WarnModal

WarnModal.defaultProps = {
  onClose: () => {},
  isShow: false,
}

WarnModal.propTypes = {
  onClose: PropTypes.func,
  isShow: PropTypes.bool,
}
