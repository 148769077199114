import { fetchData } from 'api/ApiRoute'
import {
  commonFetchProps,
  getSearchParameter,
  handleError,
} from 'api/ApiCommon'

// 取得Qrcode集點失敗列表
const getQrcodeScanFailed = (queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/qrcode/scanfailed${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 取得Qrcode集點失敗單一項目資料
const getQrcodeScanFailedOnce = (scanFailedId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/qrcode/scanfailed/${scanFailedId}${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 修改Qrcode集點失敗的狀態紀錄
const patchQrcodeScanFailedStatus = (scanFailedId, status, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/qrcode/scanfailed/${scanFailedId}/changestatus/${status}${searchParameter}`,
    'PATCH',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 取得Qrcode集點成功列表
const getQrcodePointRecord = (queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/qrcode/pointrecord${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 取得Qrcode集點成功列表
const getQrcodePointRecordOnce = (qrcodeId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/qrcode/pointrecord/${qrcodeId}${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 取得Qrcode集點成功 table 的彈性欄位
const getQrcodePointRecordFieldInfo = (queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/qrcode/pointrecord/fieldinfo${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}

const getQrcodePointRecordDetail = ({ brandId, qrcodeId, recordId }) => {
  return fetchData(
    `member/qrcode/pointrecord/${qrcodeId}/${recordId}?brandId=${brandId}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data
    })
    .catch(handleError)
}

export {
  getQrcodeScanFailed,
  getQrcodeScanFailedOnce,
  patchQrcodeScanFailedStatus,
  getQrcodePointRecord,
  getQrcodePointRecordOnce,
  getQrcodePointRecordFieldInfo,
  getQrcodePointRecordDetail,
}
