import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'

const Wrapper = styled.div`
  svg {
    margin-left: 5px;
    path {
      fill: #fff;
      fill-opacity: 1;
    }
  }
`

const FormatterStatusWrapper = styled.ul`
  list-style-type: disc;
  margin: 0;
  li {
    margin-left: 18px;
    color: ${(props) => props.color || colorObj.dark};
  }
`

const CaptionWrapper = styled.div`
  color: ${colorObj.dark};
  font-size: 15px;
  .caption-search {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    input {
      height: 24px;
    }

    .caption-btn {
      margin: 0 16px 0 20px;
    }
  }

  .caption-total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    > div {
      display: flex;
      align-items: center;
    }
  }

  .caption-label {
    min-width: 60px;
    color: ${colorObj.darkGray};
  }

  .caption-color {
    color: ${colorObj.darkGray};
  }
`

const MemberModalWrapper = styled.div`
  text-align: start;
  min-width: 420px;

  .member-title {
    color: ${colorObj.dark};
    font-size: 14px;
    margin-bottom: 16px;
    &:first-child {
      margin-bottom: 25px;
    }

    .member-note {
      color: ${colorObj.gray};
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .member-info {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .member-label {
    color: ${colorObj.darkGray};
    font-size: 15px;
    min-width: 90px;
  }

  .member-content {
    color: ${colorObj.dark};
    font-size: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    .member-note {
      color: ${colorObj.gray};
      font-size: 13px;
      margin-left: 8px;
    }
  }

  hr {
    margin: 24px 0;
  }
`

const TextLink = styled.span`
  text-decoration: underline;
  color: ${colorObj.primary};
  cursor: pointer;
`

export {
  Wrapper,
  FormatterStatusWrapper,
  CaptionWrapper,
  MemberModalWrapper,
  TextLink,
}
