/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import {
  searchConfig,
  initSearch,
} from 'components/pages/Gift/ExchangeSerialNumberConfig'
import { Input, Button, Select } from 'components/units'

function SearchBar({ isSettingTypeExisted, onListQuery }) {
  const [search, setSearch] = useState(initSearch)

  const handleSearchChange = (updateValue) => {
    setSearch((prev) => ({ ...prev, ...updateValue }))
  }

  const handleSearchClick = () => {
    onListQuery({ ...search, keyword: search.keyword, p: 1 })
  }

  const handleClearClick = () => {
    setSearch(initSearch)
    onListQuery({ keyword: '', searchType: '', p: 1 })
  }
  return (
    <>
      {/* 查詢清單 */}
      {isSettingTypeExisted && (
        <Form.Group className="search-area">
          <Form.Label>{searchConfig.label}</Form.Label>
          <Select
            selectedValue={1}
            optionItems={searchConfig.options}
            onChange={(e, value) => handleSearchChange({ searchType: value })}
          />
          <Input
            formControlOption={{
              value: search.keyword,
              onChange: (e) => handleSearchChange({ keyword: e.target.value }),
            }}
          />
          <Button
            size="sm"
            variant="outline-darkerGray"
            onClick={handleSearchClick}
            disabled={!search.keyword.trim()}
          >
            {searchConfig.search}
          </Button>
          <Button
            size="sm"
            variant="outline-darkerGray"
            onClick={handleClearClick}
          >
            {searchConfig.clear}
          </Button>
        </Form.Group>
      )}
    </>
  )
}

export default SearchBar

SearchBar.propTypes = {
  isSettingTypeExisted: PropTypes.bool.isRequired,
  onListQuery: PropTypes.func.isRequired,
}
