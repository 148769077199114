import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { PaginationListStandalone } from 'react-bootstrap-table2-paginator'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getQrcodeOnce, patchStopQrcode } from 'api/ApiMain'
import { Table, NoDataIndication, Card, Modal } from 'components/units'
import { COMPONENT_PERMISSIONS } from 'constant'
import { QrcodeActivityContext } from 'context/pageContext'
import { RootContext } from 'RootContext'
import { isPermissionAllowed } from 'store/permission/permissionSlice'
import { modalConfig, modalType } from './QrcodeActivityListConfig'
import { tableColumns } from './QrcodeActivityListHelper'
import LocalStyle from './QrcodeActivityListStyle'
import Caption from './QrcodeActivityListSubComponent'

function QrcodeActivityList({ paginationProps, paginationTableProps }) {
  const rootData = useContext(RootContext)
  const brandId = rootData?.brandId
  const {
    settingPageType,
    apiPayload,
    qrcodeListData,
    setApiPayload,
    handleGetQrcode,
    setQrcodeListData,
    tableLoading,
    handleTableLoading,
  } = useContext(QrcodeActivityContext)

  const history = useHistory()
  const { UPDATE, DELETE } = COMPONENT_PERMISSIONS.barcodeRule
  const hasEditPermission = useSelector(
    isPermissionAllowed([...UPDATE, ...DELETE])
  )
  const [modalState, setModalState] = useState({
    type: modalType.stop,
    show: false,
  })
  const stopQrcodeRowIndexRef = useRef()

  const payloadInfo = {
    ...apiPayload,
    brandId,
  }

  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return {
        ...prevState,
        show: true,
        type,
      }
    })
  }

  const handleCloseModal = () => {
    setModalState((prevState) => {
      return {
        ...prevState,
        show: false,
      }
    })
  }

  const stopQrcode = async () => {
    if (stopQrcodeRowIndexRef.current || stopQrcodeRowIndexRef.current === 0) {
      try {
        handleTableLoading(true)
        setQrcodeListData([])
        await patchStopQrcode(
          payloadInfo.brandId,
          qrcodeListData.rows[stopQrcodeRowIndexRef.current]
            .qrCodeActivitySettingId
        )
      } finally {
        stopQrcodeRowIndexRef.current = undefined
        await handleGetQrcode()
        handleCloseModal()
      }
    }
  }
  const handleModalConfirm = async (handleModalLoading, type) => {
    if (type === modalType.stop) {
      handleModalLoading(true)
      await stopQrcode()
      handleModalLoading(false)
    }
  }

  const onTableChange = (type, value) => {
    if (type === 'sort') {
      setApiPayload((prev) => ({
        ...prev,
        sf: value.sortField,
        so: value.sortOrder,
      }))
    }
  }

  const handleGetQrcodeOnce = async (qrCodeActivitySettingId) => {
    if (brandId && qrCodeActivitySettingId) {
      const apiData = await getQrcodeOnce(brandId, qrCodeActivitySettingId)
      return apiData
    }
    return null
  }

  const handleBrandIdChange = useCallback(() => {
    history.push({
      ...history.location,
      state: {
        ...history.location.state,
        brandId,
      },
    })
  }, [brandId, history])

  useEffect(() => {
    handleBrandIdChange()
  }, [handleBrandIdChange])

  return (
    <LocalStyle>
      <Card className="w-100">
        <Caption
          paginationProps={paginationProps}
          totalSize={qrcodeListData?.totalSize}
          setApiPayload={setApiPayload}
          payload={payloadInfo}
          history={history}
        />
        <Table
          headerClasses="table-header"
          bodyClasses="paragraph"
          className="overflow-auto mt-3"
          keyField="no"
          data={qrcodeListData?.rows || []}
          columns={tableColumns(
            history,
            settingPageType,
            rootData?.brandId,
            handleGetQrcode,
            handleTableLoading,
            setQrcodeListData,
            handleOpenModal,
            stopQrcodeRowIndexRef,
            handleGetQrcodeOnce,
            hasEditPermission
          )}
          sort={{ dataField: apiPayload.sf, order: apiPayload.so }}
          striped
          remote
          onTableChange={onTableChange}
          noDataIndication={
            <NoDataIndication
              isLoading={tableLoading}
              message={tableLoading ? '載入中...' : '尚無資料'}
            />
          }
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationTableProps}
        />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PaginationListStandalone {...paginationProps} />
      </Card>
      <Modal
        show={modalState.show}
        onClose={handleCloseModal}
        onConfirm={({ handleModalLoading }) =>
          handleModalConfirm(handleModalLoading, modalState.type)
        }
        variant={modalConfig[modalState.type].variant}
        titleText={modalConfig[modalState.type].title}
        confirmBtnText={modalConfig[modalState.type].confirm}
        closeBtnText={modalConfig[modalState.type].close}
      >
        {modalConfig[modalState.type].content.map((item) => (
          <p key={`modal-content-${item}`}>{item}</p>
        ))}
      </Modal>
    </LocalStyle>
  )
}

export default QrcodeActivityList

QrcodeActivityList.propTypes = {
  paginationProps: PropTypes.shape({
    page: PropTypes.number,
    sizePerPage: PropTypes.number,
    totalSize: PropTypes.number,
    onSizePerPageChange: PropTypes.func,
    onPageChange: PropTypes.func,
  }),
  paginationTableProps: PropTypes.shape({
    page: PropTypes.number,
    sizePerPage: PropTypes.number,
    totalSize: PropTypes.number,
    onSizePerPageChange: PropTypes.func,
    onPageChange: PropTypes.func,
  }),
}

QrcodeActivityList.defaultProps = {
  paginationProps: {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    onSizePerPageChange: () => {},
    onPageChange: () => {},
  },
  paginationTableProps: {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    onSizePerPageChange: () => {},
    onPageChange: () => {},
  },
}
