import styled, { css } from 'styled-components'
import { colorCommon, colorObj, method } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const { colorHandle } = method

const LocalStyle = styled.div`
  margin: 0;
  padding: 0;

  .checkboxs-container {
    .form-check-inline {
      margin-top: 0;
      margin-bottom: 0;
    }
    display: flex;
    flex-wrap: wrap;
    gap: 16px 0;
  }

  .tab-content-base {
    > [class^='tab-content'] {
      padding: ${spacingObj.medium} 0;

      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        padding-bottom: 0;
      }
    }
    .form-group {
      display: inline-flex;
      align-items: center;
      width: calc(100% / 3);

      > .label-title {
        min-width: 125px;
        max-width: 125px;
        padding: 0 16px;
      }
      &:last-of-type {
        width: 100%;
        margin-bottom: 0;
      }
      &:only-child {
        margin-bottom: 0;
      }
    }
    .tab-content-base-fixfields {
      margin-left: -16px;

      .form-group {
        &:nth-of-type(2) {
          width: calc(100% / 3 * 2);
          > label {
            ~ * {
              width: calc(50% - 125px);

              .dropdown-toggle {
                width: 100%;
              }
            }
          }
        }
        &:last-of-type {
          width: 100%;
        }
      }
    }
    .tab-content-line-binding,
    .tab-content-brand-binding {
      border-top: 1px solid ${colorCommon.borderGray};
      margin-left: -16px;

      button {
        margin-left: ${spacingObj.mini};
      }
    }
    .tab-content-base-optionalfields {
      border-top: 1px solid ${colorCommon.borderGray};
      margin-left: -16px;

      .form-group {
        width: 100%;

        .style-input-container {
          &:not([type='textarea']) {
            max-width: 179.5px;
          }
        }
      }
    }
    .tab-content-base-expandfields {
      margin-left: -16px;

      .form-group {
        width: 100%;
      }
    }
  }
  .tab-content-customized {
    .form-group {
      border-bottom: 1px solid ${colorCommon.borderGray};
      padding: ${spacingObj.medium} 0;
      margin-bottom: 0;

      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        border: 0;
        padding-bottom: 0;
      }
      .customizedfield-list {
        &:last-of-type {
          border-bottom: 0;

          .customizedfield-list-item {
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .tab-content-inner-nav {
    .nav-item {
      &:first-of-type {
        .nav-link {
          padding-left: 0;
        }
      }
      &:last-of-type {
        .nav-link {
          border-right: 0;
        }
      }
      .nav-link,
      .nav-link:hover {
        box-shadow: none;
      }
    }
    .nav-link {
      border-right: 2px solid ${colorHandle('dark', colorObj, 0.5)};
      border-radius: 0;
      line-height: 1;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-bottom: ${spacingObj.medium};
      font-weight: 500;

      &.active {
        box-shadow: none;
      }
    }
    .tab-content {
      padding: 0;
      box-shadow: none;
    }
  }

  @media (max-width: 1024px) {
    .tab-content-base {
      .tab-content-base-fixfields {
        .form-group {
          width: 50%;

          &:nth-of-type(2) {
            width: 50%;

            > label {
              ~ * {
                width: calc(100% - 125px);
                .dropdown-toggle {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .tab-content-base-optionalfields {
      }
    }
  }
  @media (max-width: 767px) {
    .tab-content-base {
      .tab-content-base-fixfields {
        .form-group {
          width: 100%;

          &:nth-of-type(2) {
            width: 100%;

            > label {
              ~ * {
                width: 100%;
              }
            }
          }
        }
      }
    }
    //客制欄位
    .tab-content-customized {
      .form-group {
        .customizedfield-list {
          .customizedfield-list-article {
            .customizedfield-list-item {
              width: 100%;
            }
          }
        }
      }
    }
  }
  //LineChannel 子品牌顯示的頓號分隔
  .item-spliter span:nth-last-child(n + 2)::after {
    content: '、';
  }
`
// 客制欄位每一列
const CustomizedFieldListItemStyle = styled.div`
  display: inline-flex;
  align-items: center;
  width: ${(props) => (props?.fieldType === 'text' ? 'calc(100%/4)' : '100%')};
  padding-right: ${spacingObj.small};
  margin-bottom: ${spacingObj.small};

  > .form-label {
    min-width: 90px;
    max-width: 90px;
    margin-bottom: 0;
    color: ${colorHandle('dark', colorObj, 0.66)};
  }
  > .form-control {
    min-width: 130px;
  }

  @media (max-width: 1024px) {
    width: ${(props) =>
      props?.fieldType === 'text' ? 'calc(100%/2)' : '100%'};
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

export { CustomizedFieldListItemStyle }
export default LocalStyle
