import { useContext, useEffect, useRef, useState, forwardRef } from 'react'
import { Input, Modal } from 'components/units'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import useField from 'hooks/useField'
import useClipboard from 'hooks/useClipboard'
import { RootContext } from 'RootContext'
import { postOpenApi } from 'api/ApiMain'
import {
  formControlNames,
  formControlPropsData,
  fieldRules,
} from './TokenModalConfig'
import { modalSetting } from './CompanyTokenConfig'
import { AddTokenStyle, TokenInfoStyle } from './TokenModalStyle'
import { CompanyTokenContext } from 'context/pageContext'

const { modalType } = modalSetting

function TokenModal() {
  const rootData = useContext(RootContext)
  const { modalState, setModalState } = useContext(CompanyTokenContext)
  // 表單form是否按下按鈕來驗證欄位規則
  const [validated, setValidated] = useState(false)
  const formRef = useRef()
  const fieldData = useField()
  const { inputValues, onChangeField } = fieldData
  // 欄位驗證是由後端得知的錯誤訊息，並記錄此錯誤的值與要顯示的錯誤訊息文字
  const [dynamicFieldErors, setDynamicFieldErors] = useState({})
  const clipboardOption = useClipboard('已複製')
  const { inputRef, onClipBoard } = clipboardOption
  const [formControlProps, setFormControlProps] = useState(formControlPropsData)

  const getContent = (type, payload) => {
    switch (type) {
      // 新增Token
      case modalType.addToken: {
        return (
          <AddToken
            validated={validated}
            formControlProps={formControlProps}
            onChangeField={onChangeField}
            ref={formRef}
          />
        )
      }
      // 取得Token
      case modalType.tokenInfo: {
        return <TokenInfo basicInfo={payload.basicInfo} inputRef={inputRef} />
      }
    }
  }

  const handleClose = () => {
    setModalState({
      ...modalState,
      show: false,
    })
  }

  const handleConfirm = (modalState) => {
    switch (modalState.childrenType) {
      // 新增Token
      case modalType.addToken: {
        setValidated(true)
        if (typeof modalState.onConfirm === 'function') {
          modalState.onConfirm(async (handleGetOpenApi) => {
            const form = formRef.current
            const isValidated = form.checkValidity()
            if (isValidated) {
              const brandId = rootData?.brandId
              const postData = {
                ...inputValues,
              }
              try {
                await postOpenApi(brandId, postData)
                setModalState({
                  show: false,
                })
                handleGetOpenApi()
              } catch (err) {
                if (err?.errors) {
                  setDynamicFieldErors((prevState) => {
                    return {
                      ...prevState,
                      [formControlNames.id]: {
                        value: inputValues[formControlNames.id],
                        feedbackText: err?.errors[formControlNames.id],
                      },
                    }
                  })
                  setFormControlProps((prevState) => {
                    return {
                      ...prevState,
                      [formControlNames.id]: {
                        ...prevState[formControlNames.id],
                        formControlOption: {
                          ...prevState[formControlNames.id].formControlOption,
                          isInvalid: true,
                        },
                        feedbackText: err?.errors[formControlNames.id],
                      },
                    }
                  })
                }
              }
            }
          })
        }
        break
      }
      // 複製Token
      case modalType.tokenInfo: {
        if (typeof modalState.onConfirm === 'function') {
          modalState.onConfirm((token) => {
            onClipBoard(token)
          })
        }
        break
      }
    }
  }
  // modal視窗關閉要觸發的行為(發生時間比handleClose還晚)
  const handleExit = () => {
    setValidated(false)
    setDynamicFieldErors({})
    setFormControlProps((prevState) => {
      return {
        ...prevState,
        [formControlNames.id]: {
          ...prevState[formControlNames.id],
          formControlOption: {
            ...prevState[formControlNames.id].formControlOption,
            isInvalid: false,
          },
          feedbackText: fieldRules.common,
        },
      }
    })
  }

  // 處理欄位動態錯誤驗證
  useEffect(() => {
    setFormControlProps((prevState) => {
      return {
        ...prevState,
        [formControlNames.id]: {
          ...prevState[formControlNames.id],
          formControlOption: {
            ...prevState[formControlNames.id].formControlOption,
            isInvalid: inputValues[formControlNames.id]
              ? inputValues[formControlNames.id] ===
                dynamicFieldErors?.[[formControlNames.id]]?.value
              : false,
          },
          feedbackText: inputValues[formControlNames.id]
            ? inputValues[formControlNames.id] ===
              dynamicFieldErors?.[formControlNames.id]?.value
              ? dynamicFieldErors?.[[formControlNames.id]]?.feedbackText
              : inputValues[formControlNames.id]
              ? fieldRules[formControlNames.id].pattern
              : fieldRules.common
            : fieldRules.common,
        },
      }
    })
  }, [inputValues, dynamicFieldErors])

  return (
    <Modal
      show={modalState.show}
      titleText={modalState.titleText}
      closeBtnText={modalState.closeBtnText}
      closeBtnVisible={modalState.closeBtnVisible}
      confirmBtnText={modalState.confirmBtnText}
      confirmBtnVisible={modalState.confirmBtnVisible}
      variant={modalState.variant}
      onClose={handleClose}
      onConfirm={() => handleConfirm(modalState)}
      modalOption={{
        onExit: handleExit,
      }}
    >
      {getContent(modalState.childrenType, modalState.childrenPayload)}
    </Modal>
  )
}

// 新增 Token Modal 內容
const AddToken = forwardRef(
  ({ validated, formControlProps, onChangeField }, ref) => {
    return (
      <AddTokenStyle className="fz15">
        <Form noValidate validated={validated} ref={ref}>
          <Form.Group>
            <Form.Row className="mb-3">
              <Form.Label className="mb-0 mt-1">
                <span className="form-label">廠商編號：</span>
              </Form.Label>
              <Col>
                <Input
                  type="text"
                  formControlOption={{
                    ...formControlProps[formControlNames.id].formControlOption,
                  }}
                  feedbackText={
                    formControlProps[formControlNames.id].feedbackText
                  }
                  onChange={onChangeField}
                />
              </Col>
            </Form.Row>
            <Form.Row className="mb-3">
              <Form.Label className="mb-0 mt-1">
                <span className="form-label">廠商名稱：</span>
              </Form.Label>
              <Col>
                <Input
                  type="text"
                  formControlOption={{
                    ...formControlProps[formControlNames.name]
                      .formControlOption,
                  }}
                  feedbackText={
                    formControlProps[formControlNames.name].feedbackText
                  }
                  onChange={onChangeField}
                />
              </Col>
            </Form.Row>
          </Form.Group>
        </Form>
      </AddTokenStyle>
    )
  }
)

// 取得 Token Modal 內容
function TokenInfo({ basicInfo }) {
  return (
    <div className="fz15">
      <TokenInfoStyle className="fz15 text-left">
        <div className="info-group">
          <ul className="info-list">
            {basicInfo.list.map((item, index) => {
              return (
                <li
                  key={`info-list-item-${item.id}`}
                  className={`d-flex ${
                    index !== basicInfo.list.length - 1 ? 'mb-3' : ''
                  }`}
                >
                  <span className="list-title">{item.label}：</span>
                  <span className="list-value">{item.value}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </TokenInfoStyle>
    </div>
  )
}

export { TokenModal }
