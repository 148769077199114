const paginationOptions = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

const settingPageType = {
  new: 'new',
  edit: 'edit',
  view: 'view',
}

const sortOrder = {
  desc: 'desc',
  asc: 'asc',
}

const tabStatus = {
  available: 'available',
  scheduled: 'scheduled',
  unavailable: 'unavailable',
  sealed: 'sealed',
}

const tabConfig = [
  {
    eventKey: tabStatus.available,
    title: '上架中',
  },
  {
    eventKey: tabStatus.scheduled,
    title: '已排程',
  },
  {
    eventKey: tabStatus.unavailable,
    title: '下架',
  },
  {
    eventKey: tabStatus.sealed,
    title: '封存',
  },
]

export { paginationOptions, settingPageType, tabStatus, tabConfig, sortOrder }
