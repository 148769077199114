import { IconButtonGroup } from 'components/widgets'

// 列表的column設定
const columns = ({ onHandleIconButton }) => {
  const columnsConfig = [
    {
      dataField: 'no',
      text: 'No.',
      classes: 'cursor-pointer',
      sort: false,
    },
    {
      dataField: 'email',
      text: '帳號',
      classes: 'cursor-pointer',
      sort: true,
    },
    {
      dataField: 'name',
      text: '名稱',
      classes: 'cursor-pointer',
      sort: false,
    },
    {
      dataField: 'roleDescription',
      text: '角色',
      classes: 'cursor-pointer',
      sort: true,
    },
    {
      dataField: 'shopDescription',
      text: '所屬門店',
      classes: 'cursor-pointer',
      sort: false,
    },
    {
      dataField: 'statusDescription',
      text: '狀態',
      classes: 'cursor-pointer',
      sort: true,
    },
    {
      dataField: 'funcList',
      text: '',
      formatter: (cell) => {
        if (!cell) return null
        return (
          <IconButtonGroup
            iconList={cell || []}
            onHandleIconButton={onHandleIconButton}
          />
        )
      },
      style: () => {
        return {
          minWidth: '75px',
        }
      },
    },
  ]
  return columnsConfig
}

export default columns
