import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import withPermission from 'components/hocs/withPermission'

import { Mask } from 'components/units'
import { StyleLoader } from 'components/units/Loader'
import { PAGE_PATHS, PAGE_PERMISSIONS, PAGE_BREADCRUMB } from 'constant'
import {
  ProductActivity,
  ProductActivityManagementEntry,
  PointActivity,
  QrcodeActivity,
  QrcodeActivityContent,
  ActivityFormRecord,
} from 'pages/ActivitiesCode'
import DataAnalysis from 'pages/data-analysis/DataAnalysis'
import {
  GiftExchange,
  GiftSetting,
  GiftList,
  GiftExclusive,
  GiftExclusiveSetting,
} from 'pages/Gift'
import Home from 'pages/home/Home'
import Login from 'pages/login/Login'
import MemberEdit from 'pages/Member/Edit'
import MemberNew from 'pages/Member/New'
import MemberSearch from 'pages/Member/Search'
import MembershipSetting from 'pages/MembershipSetting'
import { PointHistory } from 'pages/PointContent'
import PointSetting from 'pages/PointSetting/PointSetting'
import CompanyToken from 'pages/SystemConfiguration'
import AccountManagement from 'pages/SystemConfiguration/AccountManagement'
import AccountManagementEdit from 'pages/SystemConfiguration/AccountManagementContent/AccountManagementEdit'
import AccountManagementNew from 'pages/SystemConfiguration/AccountManagementContent/AccountManagementNew'
import { getGlobalState, updatePageLoading } from 'store/global/globalSlice'

const PageContent = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #fafafa;
`

const RouteConfig = [
  {
    path: PAGE_PATHS.login,
    component: Login,
  },
  {
    path: PAGE_PATHS.home,
    component: withPermission(
      Home,
      PAGE_PERMISSIONS.home,
      PAGE_BREADCRUMB.home
    ),
  },
  {
    path: PAGE_PATHS.dataAnalysis,
    component: withPermission(
      DataAnalysis,
      PAGE_PERMISSIONS.dataAnalysis,
      PAGE_BREADCRUMB.dataAnalysis
    ),
  },
  {
    path: PAGE_PATHS.member.new,
    component: withPermission(
      MemberNew,
      PAGE_PERMISSIONS.memberNew,
      PAGE_BREADCRUMB.member.new
    ),
  },
  {
    path: PAGE_PATHS.member.edit,
    component: withPermission(
      MemberEdit,
      PAGE_PERMISSIONS.memberEdit,
      PAGE_BREADCRUMB.member.edit
    ),
  },
  {
    path: PAGE_PATHS.member.search,
    component: withPermission(
      MemberSearch,
      PAGE_PERMISSIONS.memberSearch,
      PAGE_BREADCRUMB.member.search
    ),
  },
  {
    path: PAGE_PATHS.membershipSetting,
    component: withPermission(
      MembershipSetting,
      PAGE_PERMISSIONS.membershipSetting,
      PAGE_BREADCRUMB.membershipSetting
    ),
  },
  {
    path: PAGE_PATHS.pointContent.setting,
    component: withPermission(
      PointSetting,
      PAGE_PERMISSIONS.pointSetting,
      PAGE_BREADCRUMB.pointContent.setting
    ),
  },
  {
    path: PAGE_PATHS.pointContent.history,
    component: withPermission(
      PointHistory,
      PAGE_PERMISSIONS.pointHistory,
      PAGE_BREADCRUMB.pointContent.history
    ),
  },
  {
    path: PAGE_PATHS.activitiesCode.pointActivity,
    component: withPermission(
      PointActivity,
      PAGE_PERMISSIONS.pointActivity,
      PAGE_BREADCRUMB.activitiesCode.pointActivity
    ),
  },
  {
    path: PAGE_PATHS.activitiesCode.productActivity,
    component: withPermission(
      ProductActivity,
      PAGE_PERMISSIONS.productActivity,
      PAGE_BREADCRUMB.activitiesCode.productActivity
    ),
  },
  {
    path: PAGE_PATHS.activitiesCode.productActivityNew,
    component: withPermission(
      ProductActivityManagementEntry,
      PAGE_PERMISSIONS.productActivityManagementEntry,
      PAGE_BREADCRUMB.activitiesCode.productActivityNew
    ),
  },
  {
    path: PAGE_PATHS.activitiesCode.productActivityEdit,
    component: withPermission(
      ProductActivityManagementEntry,
      PAGE_PERMISSIONS.productActivityManagementEntry,
      PAGE_BREADCRUMB.activitiesCode.productActivityEdit
    ),
  },
  {
    path: PAGE_PATHS.activitiesCode.qrcodeActivity,
    component: withPermission(
      QrcodeActivity,
      PAGE_PERMISSIONS.qrcodeActivity,
      PAGE_BREADCRUMB.activitiesCode.qrcodeActivity
    ),
  },
  {
    path: PAGE_PATHS.activitiesCode.qrcodeActivityContent,
    component: withPermission(
      QrcodeActivityContent,
      PAGE_PERMISSIONS.qrcodeActivityContent,
      PAGE_BREADCRUMB.activitiesCode.qrcodeActivityContent
    ),
  },
  {
    path: PAGE_PATHS.activitiesCode.activityFormRecord,
    component: withPermission(
      ActivityFormRecord,
      PAGE_PERMISSIONS.activityFormRecord,
      PAGE_BREADCRUMB.activitiesCode.activityFormRecord
    ),
  },
  {
    path: PAGE_PATHS.gift.exclusive,
    component: withPermission(
      GiftExclusive,
      PAGE_PERMISSIONS.giftExclusive,
      PAGE_BREADCRUMB.gift.exclusive
    ),
  },
  {
    path: PAGE_PATHS.gift.exclusiveSetting,
    component: withPermission(
      GiftExclusiveSetting,
      PAGE_PERMISSIONS.giftExclusiveSetting,
      PAGE_BREADCRUMB.gift.exclusiveSetting
    ),
  },
  {
    path: PAGE_PATHS.gift.exchange,
    component: withPermission(
      GiftExchange,
      PAGE_PERMISSIONS.giftExchange,
      PAGE_BREADCRUMB.gift.exchange
    ),
  },
  {
    path: PAGE_PATHS.gift.exchangeSetting,
    component: withPermission(
      GiftSetting,
      PAGE_PERMISSIONS.giftSetting,
      PAGE_BREADCRUMB.gift.setting
    ),
  },
  {
    path: PAGE_PATHS.gift.list,
    component: withPermission(
      GiftList,
      PAGE_PERMISSIONS.giftList,
      PAGE_BREADCRUMB.gift.list
    ),
  },
  {
    path: PAGE_PATHS.systemConfiguration.accountManagement,
    component: withPermission(
      AccountManagement,
      PAGE_PERMISSIONS.accountManagement,
      PAGE_BREADCRUMB.systemConfiguration.accountManagement
    ),
  },
  {
    path: PAGE_PATHS.systemConfiguration.companyToken,
    component: withPermission(
      CompanyToken,
      PAGE_PERMISSIONS.companyToken,
      PAGE_BREADCRUMB.systemConfiguration.companyToken
    ),
  },
  {
    path: PAGE_PATHS.systemConfiguration.accountManagementEdit,
    component: withPermission(
      AccountManagementEdit,
      PAGE_PERMISSIONS.accountManagement,
      PAGE_BREADCRUMB.systemConfiguration.accountManagementEdit
    ),
  },
  {
    path: PAGE_PATHS.systemConfiguration.accountManagementNew,
    component: withPermission(
      AccountManagementNew,
      PAGE_PERMISSIONS.accountManagement,
      PAGE_BREADCRUMB.systemConfiguration.accountManagementNew
    ),
  },
]

function PageRoutes() {
  const { isPageLoading, isMaskOpen } = useSelector(getGlobalState)
  const dispatch = useDispatch()
  return (
    <Switch>
      {RouteConfig.map(({ path, component: Component }) => (
        <Route key={path} path={path} exact>
          <PageContent>
            <StyleLoader isHide={!isPageLoading} bgColorAlpha={1} />
            <Mask show={isMaskOpen} />
            <Component
              setPageLoading={(bool) => dispatch(updatePageLoading(bool))}
            />
          </PageContent>
        </Route>
      ))}
    </Switch>
  )
}

export default PageRoutes
