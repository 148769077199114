import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import withPermission from 'components/hocs/withPermission'
import { ExclusivePage } from 'components/pages/Gift'
import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb, Button } from 'components/units'
import { PAGE_PATHS, COMPONENT_PERMISSIONS } from 'constant'
import { settingPageType } from 'pages/Gift/config'
import { StyledSideSection } from 'pages/Gift/ExclusiveStyle'
import { authAccountSelector } from 'store/auth/authSelector'
import {
  updateVipGiftConfig,
  vipGiftListSelector,
} from 'store/vip-gift-exchange/vipGiftExchangeListSlice'

function NavSection() {
  return (
    <Breadcrumb
      now="專屬好禮"
      pathList={[{ title: '好禮贈送', slash: true }]}
    />
  )
}

function SideSection() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { brandId } = useSelector(authAccountSelector)
  const { addBtnDisabled } = useSelector(vipGiftListSelector)
  const { CREATE } = COMPONENT_PERMISSIONS.vipGift
  const PermissionAddButton = withPermission(Button, CREATE)

  const handleAddVipGift = () => {
    history.push(PAGE_PATHS.gift.exclusiveSetting, {
      type: settingPageType.new,
      apiPayload: {
        brandId,
      },
    })
    dispatch(
      updateVipGiftConfig({
        pageType: settingPageType.new,
      })
    )
  }

  return (
    <StyledSideSection>
      <PermissionAddButton
        variant="outline-primary"
        onClick={handleAddVipGift}
        disabled={addBtnDisabled}
      >
        新增好禮
      </PermissionAddButton>
    </StyledSideSection>
  )
}

function Exclusive() {
  return (
    <Basic navSection={NavSection} sideSection={SideSection}>
      <ContentSection>
        <ExclusivePage.ExclusiveMain />
      </ContentSection>
    </Basic>
  )
}

export default Exclusive
