import { useState } from 'react'
import { searchSectionSetting } from './ActivityFormRecordListConfig'
import { SearchSection, TabSection } from './ActivityFormRecordListSubComponent'
import { ActivityFormRecordListContext } from 'context/pageContext'

function ActivityFormRecordList() {
  // Qrcode集點失敗或成功的api表格資料
  const [tableRow, setTableRow] = useState([])
  // Qrcode集點成功的表格彈性欄位
  const [optionalTableColumns, setOptionalTableColumns] = useState()
  const { defaultSort } = searchSectionSetting
  const [totalSize, setTotalSize] = useState(0)
  const [apiPayload, setApiPayload] = useState({
    ps: 25,
    p: 1,
    ...defaultSort,
  })

  return (
    <ActivityFormRecordListContext.Provider
      value={{
        totalSize,
        setTotalSize,
        apiPayload,
        setApiPayload,
        tableRow,
        setTableRow,
        optionalTableColumns,
        setOptionalTableColumns,
      }}
    >
      <SearchSection />
      <TabSection />
    </ActivityFormRecordListContext.Provider>
  )
}

export default ActivityFormRecordList
export { ActivityFormRecordListContext }
