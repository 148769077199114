import { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colorObj, method } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'
import ReactTooltip from 'react-tooltip'
import { Input, Button } from 'components/units'
import { Calendar } from 'react-date-range'
import iconCalendar from 'assets/images/icon_calendar.svg'
import { getYear, getMonth, getDate } from 'date-fns'
import dateFnsLocale from 'date-fns/locale/zh-TW'

const yearlyDayOption = {
  dates: [],
  globalOption: {
    isCapture: true,
  },
  specificOption: {
    'data-background-color': '#fff',
    'data-event': 'click',
    'data-scroll-hide': false,
    'data-effect': 'solid',
  },
}
// css樣式
const YearlyDayStyle = styled.div`
  width: 337px;

  cursor: pointer;
  display: block;

  .input-group-prepend {
    + .form-control {
      cursor: pointer;
      border-color: #ced4da !important;
      background-color: ${colorObj.white} !important;
    }

    .input-group-text {
      > span {
        margin-left: ${spacingObj.small};
        color: ${method.colorHandle('dark', colorObj, 0.5)};
        line-height: 1;
      }
    }
  }
  .add-date-container {
    width: 50%;
  }
  .dates-list {
    display: flex;
    flex-wrap: wrap;
    .date-item-container {
      padding-left: 0;
      width: 50%;

      :nth-of-type(even) {
        padding-left: ${spacingObj.mini};
      }
      [data-id='tooltip'] {
        pointer-events: initial;
        opacity: 1;
        box-shadow: 0 0 10px #888888;
        margin-top: -10px;
        background-color: ${colorObj.white};
      }
    }
    .rdrCalendarWrapper {
      ::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .rdrSelected {
        color: ${colorObj.primary} !important;
        filter: none;
      }
    }
  }
`

// 各個日期欄位元件
function DateItem(props) {
  const {
    index,
    className,
    id,
    globalOption,
    specificOption,
    dates,
    settingState,
  } = props

  // 事件
  const { setSettingState, handleDatesChange, handleCalendarDisabled } = props

  const reactTooltipRef = useRef()

  // tooltip的日期calendar關閉處理
  const handleCloseCalendar = () => {
    const TooltipRef = reactTooltipRef.current
    TooltipRef.tooltipRef = null
    ReactTooltip.hide()
  }

  // tooltip的日期calendar清空欄位處理
  const handleClearCalendar = (index, dates, setSettingState) => {
    if (dates[0]) {
      setSettingState((prevState) => {
        const newDates = [...prevState.dates]
        for (const keyName in newDates[index]) {
          newDates[index][keyName] = ''
        }
        return {
          ...prevState,
          dates: newDates,
        }
      })
      handleDatesChange(null, index)
    }
  }
  return (
    <div
      className={`date-item-container mb-2 ${className} ${
        index < 2 ? 'mt-2' : ''
      }`}
      data-testid="date-item-container"
    >
      <span data-tip data-for={id} {...specificOption}>
        <Input
          prependContent={<img src={iconCalendar} alt="icon-calendar" />}
          prependContentHaveBorder={false}
          prependContentBgColor="transparent"
          value={dates.value}
          formControlOption={{
            value:
              dates.month && dates.day
                ? `${
                    dates.month < 10 && parseInt(dates.month >= 0)
                      ? `0${dates.month}`
                      : dates.month
                  }/${
                    dates.day < 10 && parseInt(dates.day >= 0)
                      ? `0${dates.day}`
                      : dates.day
                  }`
                : '',
            onKeyDown(e) {
              e.preventDefault()
            },
          }}
          onChange={handleDatesChange}
        />
      </span>
      <ReactTooltip id={id} {...globalOption} ref={reactTooltipRef}>
        <Calendar
          locale={dateFnsLocale}
          dragSelectionEnabled={false}
          showMonthAndYearPickers={false}
          disabledDates={handleCalendarDisabled(settingState?.dates)}
          onChange={(value) => {
            handleCloseCalendar()
            handleDatesChange(value, index)
          }}
          date={handleCalendarDisabled(settingState?.dates, index)}
        />
        <div className="react-date-range-calendar-feature-container text-right mb-2">
          <Button
            className="mx-1"
            variant="outline-gray"
            colorType="gray"
            colorAlpha="1"
            size="md"
            onClick={() =>
              handleClearCalendar(index, settingState?.dates, setSettingState)
            }
          >
            清除
          </Button>
          <Button
            className="mx-1"
            variant="outline-gray"
            colorType="gray"
            colorAlpha="1"
            size="md"
            onClick={() => handleCloseCalendar()}
          >
            關閉
          </Button>
        </div>
      </ReactTooltip>
    </div>
  )
}
// 每年指定日期主元件
function YearlyDay({ onChange, className, fieldName, value }) {
  const [option, setOption] = useState(yearlyDayOption)
  const [isFirstLoad, setFirstLoad] = useState(true)

  const addDate = () => {
    setOption((prevState) => {
      return {
        ...prevState,
        dates: [
          ...prevState.dates,
          {
            year: '',
            month: '',
            day: '',
          },
        ],
      }
    })
  }
  // 日期欄位onChange事件
  const handleDatesChange = (value, index) => {
    const newDates = [...option.dates]
    newDates[index] = {
      year: value ? getYear(value) : value,
      month: value ? getMonth(value) + 1 : value,
      day: value ? getDate(value) : value,
    }
    const obj = {
      [fieldName]: {
        dates: [],
      },
    }
    if (fieldName) {
      obj[fieldName].dates = newDates
    }
    if (typeof onChange === 'function') {
      onChange(value, index, {
        ...newDates,
        ...obj,
      })
    }

    setOption((prevState) => {
      return { ...prevState, dates: newDates }
    })

    setFirstLoad(false)
  }
  // 日期欄位disabled處理
  const handleCalendarDisabled = (arrData, index) => {
    if (Array.isArray(arrData)) {
      if (index || index === 0) {
        const { year, month, day } = arrData[index]
        return year && month && day ? new Date(`${year}/${month}/${day}`) : null
      }
      const arr = []
      for (const item of arrData) {
        const { year, month, day } = item
        arr.push(new Date(`${year}/${month}/${day}`))
      }
      return arr
    }
  }

  // 處理初次載入時，有值的情況(編輯狀態)
  useEffect(() => {
    if (isFirstLoad && value) {
      const deepCloneYearlyDayOption = JSON.parse(
        JSON.stringify(yearlyDayOption)
      )
      deepCloneYearlyDayOption.dates = value.dates
      deepCloneYearlyDayOption.dates.map((item) => {
        item.year = getYear(new Date())
        return item
      })
      setOption(deepCloneYearlyDayOption)
    }
  }, [isFirstLoad, value])

  return (
    <YearlyDayStyle>
      <div className="dates-list">
        {option.dates.map((dates, index) => {
          const id = Math.random().toString(36).substr(2)
          const globalOption = {
            isCapture: false,
          }
          const specificOption = {
            'data-background-color': '#fff',
            'data-event': 'click',
            'data-scroll-hide': false,
            'data-effect': 'solid',
          }
          return (
            <Fragment key={`yearly-day-style-${index}`}>
              <DateItem
                className={className}
                index={index}
                id={id}
                globalOption={globalOption}
                specificOption={specificOption}
                dates={dates}
                settingState={option}
                setSettingState={setOption}
                handleDatesChange={handleDatesChange}
                handleCalendarDisabled={handleCalendarDisabled}
              />
            </Fragment>
          )
        })}
      </div>
      <div
        className="add-date-container ml-0"
        onClick={addDate}
        data-testid="add-date-container"
      >
        <Input
          prependContent={
            <>
              <img src={iconCalendar} alt="icon-calendar" />
              <span>新增日期</span>
            </>
          }
          prependContentHaveBorder={false}
          prependContentBgColor="transparent"
          formControlOption={{
            readOnly: true,
          }}
        />
      </div>
    </YearlyDayStyle>
  )
}

export default YearlyDay
