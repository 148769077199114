/* eslint-disable import/prefer-default-export */
import { createExampleNumber, createExampleUrl } from 'helpers/common'

const CSV_EXAMPLE = {
  // 贈品兌換
  EXCHANGE: `贈品序號\r\n${createExampleNumber(5)}${createExampleUrl(5)}`,

  // 專屬好禮
  EXCLUSIVE: `好禮序號\r\n${createExampleNumber(20)}`,

  // 商品活動
  PRODUCT:
    '商品識別碼(商品條碼/貨號), 商品名稱, 商品分類\r\n' +
    '1234567890, 【KINYO】A 迷你除濕機, 家電\r\n' +
    '1234567891, 【KINYO】B 迷你除濕機, 家電\r\n',

  // 好禮名單 - 出貨名單 - 批次上傳
  SHIPMENT:
    '訂單編號,兌換時間,會員編號,會員,手機,贈品識別碼,贈品名稱,顏色,尺寸,數量,兌換點數,兌換金額,"收件姓名\n(可批次更新)","收件手機\n(可批次更新)","收件地址_縣市\n(可批次更新)","收件地址_區域\n(可批次更新)","收件地址\n(可批次更新)","已出貨\n(可批次更新)","物流單號\n(可批次更新)",出貨變更時間\r\n' +
    'VK2021050799123,2021/5/7 13:49,AAA010000009,王小福,999123910,12333592,【KYO】 除濕機,冷光白,S,1,3000,0,王小花,999123912,台北市,大同區,大同路 10 巷 1 號, y,BC12345,2021/5/1 20:21\r\n' +
    'VK2021050699395,2021/5/6 18:23,AAA010000010,王小維,999294940,12333593,【KYO】 除濕機,冷光白,L,1,23000,300,王小花,999294940,台北市,中正區,中正路 11 巷 9 號\r\n',
}

export { CSV_EXAMPLE }
