import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'

const LocalStyle = styled.div`
  .table {
    font-size: 15px;
    color: rgba(51, 51, 51, 1);
  }

  .table th {
    color: rgba(51, 51, 51, 0.66);
    font-weight: normal;
  }

  .table td {
    padding-right: 50px;
  }

  .table td,
  .table th {
    border-top: none;
  }

  .hr,
  hr {
    padding-top: 0px;
    padding-bottom: 5px;
    margin: 0px;
  }

  .name {
    color: #4b65a6;
    font-size: 24px;
    margin-right: 9px;
    max-width: 70%;
  }

  .membership {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    min-height: 32px;
    border-radius: 100px;
    border: 1px solid #d4d4d4;
    background-color: #ffffff;
    box-sizing: border-box;
    color: #333333;
    text-align: center;
    line-height: normal;
  }

  .tip {
    color: ${colorObj.gray};
    font-size: 13px;
  }

  .activity-article {
    color: red;
    h3 {
      margin-bottom: 10px !important;
    }

    .activity-remind {
      color: ${colorObj.gray};
    }
  }
  .membership-and-level {
    display: flex;
    align-items: center;
    min-width: 100px;
    width: 30%;
  }
`

export default LocalStyle
