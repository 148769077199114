import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getApiReturnStatus } from 'api/ApiCommon'
import { getMemberDashboard } from 'api/ApiMain'
import { addId } from 'helpers/common'
import { MEMBER_DASHBOARD } from 'store/constants'

// Init data
const init = {
  memberDashboardRsp: {
    data: {
      total: [],
      thisMonth: [],
    },
    statusCode: 0,
    errorMsg: '',
  },
}

// Thunk
export const getMemberDashboardThunk = createAsyncThunk(
  `${MEMBER_DASHBOARD}/getMemberDashboard`,
  async ({ shopId }) => {
    try {
      const response = await getMemberDashboard({ shopId })
      const { status, total, thisMonth } = response
      return { statusCode: status, total, thisMonth }
    } catch (error) {
      const {
        status,
        data: { msg },
      } = error
      return { statusCode: status, errorMsg: msg }
    }
  }
)

// Slice
const memberDashboardSlice = createSlice({
  name: `${MEMBER_DASHBOARD}`,
  initialState: init,
  extraReducers: (builder) => {
    builder.addCase(getMemberDashboardThunk.fulfilled, (state, action) => {
      const {
        total = [],
        thisMonth = [],
        statusCode = 0,
        errorMsg = '',
      } = action.payload
      state.memberDashboardRsp.data.total = total
      state.memberDashboardRsp.data.thisMonth = thisMonth
      state.memberDashboardRsp.statusCode = statusCode
      state.memberDashboardRsp.errorMsg = errorMsg
    })
  },
})

// Selector
export const getMemberDashboardData = (state) => {
  const { total, thisMonth } = state[MEMBER_DASHBOARD].memberDashboardRsp.data
  return {
    total: addId(total),
    thisMonth: addId(thisMonth),
  }
}

export const getMemberDashboardRspError = (state) => {
  const { statusCode, errorMsg } = state[MEMBER_DASHBOARD].memberDashboardRsp
  let tempErrorMsg = errorMsg

  if (statusCode !== 0) {
    const errorMessage = getApiReturnStatus(statusCode)?.message
    if (errorMessage && errorMessage !== 'Unknown Error') {
      tempErrorMsg = errorMessage
    }
  }
  return {
    statusCode: state[MEMBER_DASHBOARD].memberDashboardRsp.statusCode,
    errorMsg: tempErrorMsg,
  }
}

export default memberDashboardSlice.reducer
