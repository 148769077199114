const validMsg = {
  empty: '尚未填寫',
}

const formConfig = {
  name: { label: '名稱：', placeholder: '上限 50 字', maxLength: 50 },
  target: { label: '對象：', placeholder: '' },
  targetAll: { label: '全會員' },
  targetZero: { label: '註冊會員', tip: '註冊但未達 Lv1 之會員' },
  startDate: { label: '效期起始：' },
  endDate: { label: '效期結束：' },
  content: { label: '內容：' },
  usingAdditionalPoint: {
    maxLength: 6,
  },
  notSplit: { label: '購買多件商品不累贈' },
}

const optionConfig = {
  target: ['新會員', '指定等級'],
  startDate: ['立即開始', '起始日'],
  endDate: ['永久', '結束日'],
  content: ['商品點數', '點數加贈', '不贈點'],
  // ! 永久代表null,
} // ! 思考api相關type value是否放在這邊

const pointProductMultipleList = (step) => {
  const newArrData = []
  const pointProductMultipleCodeList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
  let sum = 1

  for (let i = 1; i < step; i++) {
    newArrData.push({
      id: i,
      code: pointProductMultipleCodeList[i - 1],
      name: `${(sum += 0.5)} 倍`,
    })
  }

  return newArrData
}

const initState = {
  name: '',
  target: {
    forNewMember: '',
    forInitialMember: false,
    memberLevels: [],
  },
  memberLevelItemList: [],
  startDate: { check: '', calendar: new Date() },
  endDate: { check: '', calendar: new Date() },
  content: {
    usingPointMultiple: { check: '', value: 1 },
    usingAdditionalPoint: { check: '', value: '' },
    usingNotSplit: false,
    isNoGivingPoint: false,
    pointProductMultipleList: pointProductMultipleList(9),
  },
}

const genModalNewText = () => {
  return (
    <>
      <p>已編輯的資料將不會儲存，</p>
      <p>確定離開編輯？</p>
    </>
  )
}

const genModalModifyText = () => {
  return <p>是否儲存目前進度？</p>
}

const modalState = {
  new: {
    titleText: '即將離開',
    confirmBtnText: '離開',
    closeBtnText: '返回',
    btnColor: 'danger',
    text: genModalNewText(),
  },
  edit: {
    titleText: '儲存進度',
    confirmBtnText: '儲存',
    closeBtnText: '返回',
    btnColor: 'primary',
    text: genModalModifyText(),
  },
}

const stateReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        ...action.payload,
      }
    case 'lv0': {
      const clearupSetting = action.payload

      return {
        ...state,
        target: {
          forNewMember: clearupSetting ? false : state.target.forNewMember,
          memberLevels: state.target.memberLevels,
          forInitialMember: action.payload,
        },
      }
    }
    case 'forNewMember':
      return {
        ...state,
        target: {
          ...state.target,
          forInitialMember: false,
          memberLevels: [],
          forNewMember: action.payload,
        },
      }
    case 'memberLevelsAll':
      return {
        ...state,
        target: {
          ...state.target,
          memberLevels: state.memberLevelItemList.map((i) => ({
            ...i,
            selected: action.payload,
          })),
          forNewMember: false,
          forInitialMember: action.payload,
        },
      }
    case 'memberLevels': {
      const stateMemberLevels = state.target.memberLevels
      const selectedIndex = stateMemberLevels.findIndex(
        (level) => level.id === action.payload.id
      )
      const isNewLevel = selectedIndex === -1
      const newMemberLevels = isNewLevel
        ? [
            ...stateMemberLevels,
            { id: action.payload.id, selected: action.payload.selected },
          ]
        : stateMemberLevels.map((level, id) => {
            if (id === selectedIndex)
              return { ...level, selected: !level.selected }
            return level
          })
      return {
        ...state,
        target: {
          ...state.target,
          memberLevels: newMemberLevels,
        },
      }
    }
    case 'startDate_Checkbox':
    case 'endDate_Checkbox': {
      const type = action.type.split('_Checkbox').at(0)
      return {
        ...state,
        [type]: {
          ...state[type],
          check: action.payload,
        },
      }
    }
    case 'content_Checkbox':
    case 'usingAdditionalPoint':
      return {
        ...state,
        content: {
          ...state.content,
          ...action.payload,
        },
      }
    case 'startDate':
    case 'endDate':
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          calendar: action.payload,
        },
      }
    case 'name':
      return {
        ...state,
        [action.type]: action.payload,
      }
    case 'pointProductMultiple':
      return {
        ...state,
        content: {
          ...state.content,
          usingNotSplit: false,
          usingAdditionalPoint: {
            check: false,
            value: '',
          },
          usingPointMultiple: {
            check: true,
            value: action.payload,
          },
          isNoGivingPoint: false,
        },
      }
    case 'notSplit':
      return {
        ...state,
        content: {
          ...state.content,
          usingNotSplit: action.payload,
        },
      }
    default:
      return state
  }
}

const stateUpdate = (dispatch) => (type) => (e) => {
  const checkboxConditionType = {
    forNewMember: 'newMember',
    startDate_Checkbox: 'immediately',
    endDate_Checkbox: 'endDatePermanent',
    content: 'productPoint',
  }

  switch (type) {
    case 'memberLevels':
      return dispatch({
        type,
        payload: { id: Number(e.target.id), selected: e.target.checked },
      })
    case 'forNewMember':
    case 'startDate_Checkbox':
    case 'endDate_Checkbox':
      return dispatch({
        type,
        payload: e.target.id === checkboxConditionType[type],
      })
    case 'memberLevelsAll':
    case 'lv0':
    case 'notSplit':
      return dispatch({ type, payload: e.target.checked })
    case 'startDate':
    case 'endDate':
      return dispatch({ type, payload: e })
    case 'name':
      return dispatch({ type, payload: e.target.value })
    case 'content_Checkbox': {
      let contentStatus = ''

      if (e.target.id === 'productPoint') {
        contentStatus = {
          usingPointMultiple: { check: true, value: 1 },
          usingAdditionalPoint: { check: '', value: '' },
          usingNotSplit: false,
          isNoGivingPoint: false,
        }
      }

      if (e.target.id === 'additionalPoint') {
        contentStatus = {
          usingPointMultiple: { check: '', value: 1 },
          usingAdditionalPoint: { check: true, value: '' },
          isNoGivingPoint: false,
        }
      }
      if (e.target.id === 'noPoint') {
        contentStatus = {
          usingPointMultiple: { check: '', value: 1 },
          usingAdditionalPoint: { check: '', value: '' },
          usingNotSplit: false,
          isNoGivingPoint: true,
        }
      }

      return dispatch({
        type,
        payload: contentStatus,
      })
    }
    case 'usingAdditionalPoint':
      return dispatch({
        type,
        payload: {
          usingPointMultiple: { check: '', value: 1 },
          usingAdditionalPoint: {
            check: true,
            value: e.target.value.replace(/\D/g, ''),
          },
          isNoGivingPoint: false,
        },
      })
    default:
  }
}

const parserPayload = (res) => {
  const usingPointMultipleValue = res.pointProductMultiple
    .filter((i) => i.selected)
    .map((i) => {
      return {
        value: i.id,
      }
    })

  return {
    name: res.name,
    target: {
      forInitialMember: res.forInitialMember,
      forNewMember: res.forNewMember,
      memberLevels: res.memberLevelList,
    },
    memberLevelItemList: res.memberLevelItemList,
    startDate: {
      check: res.startDate === null,
      calendar: res.startDate ? new Date(res.startDate) : new Date(),
    },
    endDate: {
      check: res.endDate === null,
      calendar: res.endDate ? new Date(res.endDate) : new Date(),
    },
    content: {
      usingPointMultiple: {
        check: res.usingPointMultiple,
        value:
          usingPointMultipleValue.length === 0
            ? 1
            : usingPointMultipleValue[0].value,
      },
      usingAdditionalPoint: {
        check: res.usingAdditionalPoint,
        value: res.additionalPoint,
      },
      usingNotSplit: res.usingNotSplit,
      isNoGivingPoint: res.isNoGivingPoint,
      pointProductMultipleList: pointProductMultipleList(9),
    },
  }
}

const initValid = {
  name: { isValid: null, msg: validMsg.empty, required: true },
  target: { isValid: null, msg: validMsg.empty, required: true },
  startDate: { isValid: null, msg: validMsg.empty, required: true },
  endDate: { isValid: null, msg: validMsg.empty, required: true },
  content: { isValid: null, msg: validMsg.empty, required: true },
}

export {
  validMsg,
  formConfig,
  optionConfig,
  initState,
  modalState,
  stateReducer,
  stateUpdate,
  parserPayload,
  initValid,
}
