import styled from 'styled-components'
import { Modal } from 'components/units'
import { colorObj, method } from 'assets/styles/Variable/Color'

const PointRecordModalStyle = styled(Modal)`
  &.point-record-modal {
    .modal-dialog {
      width: 510px;
    }

    .point-record-form {
      font-size: 15px;
    }

    .form-caption {
      width: 86px;
      color: ${colorObj.darkGray};
    }

    .form-control {
      height: 32px;

      &::placeholder {
        color: ${colorObj.gray};
      }

      &:read-only {
        border-color: transparent;
        background-color: transparent;
      }
    }

    .member-number-fields {
      width: 276px;
    }

    .divider {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .input-field,
    .input-field-name,
    .input-field-textarea,
    .input-field-point {
      width: calc(100% - 86px);
    }

    .input-field-name {
      .form-control {
        width: 100%;
      }
    }

    .input-field-point {
      .input-group {
        width: 342px;
      }
    }

    .input-field-textarea {
      height: 80px;
    }

    .btn-outline-dark {
      height: 32px;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 14px;
      color: ${colorObj.darkerGray};

      &:hover {
        background-color: ${colorObj.white};
        box-shadow: 0 1px 2px 0 rgba(51, 51, 51, 0.25);
      }

      &:disabled {
        color: ${method.colorHandle('dark', colorObj, 0.5)};
        background-color: ${method.colorHandle('dark', colorObj, 0.25)};
        border-color: ${method.colorHandle('dark', colorObj, 0.5)};
        opacity: 1;
        border: none;
      }
    }

    .member-name-field,
    .point-type-field {
      .custom-invalid-feedback {
        padding-left: 86px;
      }
    }

    .point-type-field {
      .custom-invalid-feedback {
        padding-left: 96px;
        font-size: 80%;
      }
    }

    .custom-invalid-feedback,
    .invalid-feedback {
      margin-top: 8px;
      padding-left: 8px;
    }
  }
`

export default PointRecordModalStyle
