import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { colorObj, method } from 'assets/styles/Variable/Color'

const { colorHandle } = method

const LocalStyle = styled(Modal)`
  .modal-subtitle {
    background-color: ${colorObj.white};
    box-sizing: border-box;
    font-family: 'Noto Sans TC', sans-serif;
    color: ${colorObj.dark};
    text-align: center;
    line-height: 30px;
    margin-top: 24px;
  }
  form {
    .form-label {
      color: ${colorHandle('dark', colorObj, 0.66) || 'inherit'};
      font-family: 'Noto Sans TC', sans-serif;
      font-size: 15px;
      text-align: left;
      line-height: 24px;
      min-width: 80px;
    }
  }
`

export default LocalStyle
