import { color } from 'assets/styles/Variable/Color'
/* -------------------------------------- 
  可共用的參數
--------------------------------------*/
const settingPageType = {
  new: 'new',
  edit: 'edit',
  view: 'view',
}
// qrcode活動的單個活動的狀態
const qrcodeActivityListStatus = {
  pending: 0,
  ongoing: 10,
  expired: 20,
  stopped: 30,
}

// table中的排序方式
const defaultSort = {
  so: 'asc',
  sf: 'no',
}

// table中頁碼的下拉選單設定
const paginationOptions = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

// modal 類型
const modalType = {
  exit: 'exit',
  updateSetting: 'updateSetting',
  save: 'save',
  unlink: 'unlink',
}

// modal設定
const modalConfig = {
  [modalType.exit]: {
    title: '即將離開',
    confirm: '離開',
    close: '返回',
    content: ['已編輯的資料將不會儲存，', '確定離開編輯？'],
    variant: color.danger,
  },
  [modalType.updateSetting]: {
    title: '變更設定',
    confirm: '繼續',
    close: '返回',
    content: ['變更內容選項，', '將刪除已建立之序號內容。', '是否繼續執行？'],
    variant: color.danger,
  },
  [modalType.save]: {
    title: '儲存進度',
    confirm: '儲存',
    close: '返回',
    content: ['是否儲存目前編輯進度？'],
    variant: color.primary,
  },
  [modalType.unlink]: {
    title: '即將解除',
    confirm: '解除',
    close: '返回',
    content: ['解除連結後將無法復原，', '確定解除？'],
    variant: color.danger,
  },
}
// toast 成功訊息
const toastSuccessMsg = {
  exportAllList: '匯出全部清單成功',
}

const commonSetting = {
  settingPageType,
  defaultSort,
  paginationOptions,
  modalConfig,
  toastSuccessMsg,
}
/* -------------------------------------- 
  FormSection 區塊
--------------------------------------*/

// 表單欄位中要送出的欄位名稱
const formControlOutputNames = {
  prefix: 'codePrefix',
  length: 'codeLength',
  exchangeNumber: 'codeType',
  sameNumberCount: 'exchangeTimes',
  couple: 'quantityToGenerate',
}

// 表單中的各form-group名稱
const formGroups = {
  prefix: 'prefix',
  length: 'length',
  exchange: 'exchange',
  couple: 'couple',
}

// 欄位對應的所有驗證規則(以form-group為一單位)
const fieldRules = {
  common: '尚未填寫',
  prefix: {
    pattern: '請輸入 4 碼半型大寫英文與數字',
    repeat: '序號前綴不可重複',
  },
  couple: {
    onceExceed: '單次不可高於 1 萬組',
    totalExceed: '總數不可高於 10 萬組，剩餘 ${remainCount} 組',
  },
}

// 欄位對應的驗證規則不符合時正在顯示的文字(以form-group為一單位)
const formGroupErrors = {
  [formGroups.prefix]: null,
  [formGroups.length]: null,
  [formGroups.exchange]: null,
  [formGroups.couple]: null,
}

// 所有欄位的FieldTypeSwitch元件的屬性設定值
const allFieldTypeSwitchProp = {
  prefix: {
    type: 'text',
    formControlOption: {
      name: formControlOutputNames.prefix,
      required: true,
      placeholder: '4 碼半形大寫英文與數字',
      maxLength: 4,
      autoComplete: 'off',
    },
  },
  length: {
    type: 'select',
    formControlOption: {
      className: 'mb-3',
      name: formControlOutputNames.length,
      required: true,
      placeholder: '請選擇',
    },
    selectOption: {
      dropdownToggleOption: {
        disabled: false,
      },
    },
    options: [...Array(15)].map((item, index) => ({
      name: `${index + 10} 碼`,
      code: index + 10,
      id: index + 10,
    })),
  },
  differentNumber: {
    type: 'radio',
    containerClass: 'w-100',
    formControlOption: {
      name: formControlOutputNames.exchangeNumber,
      required: true,
    },
    options: [
      {
        name: '發送不同序號，同一序號限兌換一次',
        code: 10,
      },
    ],
  },
  sameNumberInfiniteCount: {
    type: 'radio',
    containerClass: 'w-100',
    formControlOption: {
      name: formControlOutputNames.exchangeNumber,
      required: true,
      inline: true,
    },
    options: [
      {
        name: '發送相同序號，同一序號會員不限兌換次數',
        code: 20,
      },
    ],
  },
  sameNumberLimitCount: {
    type: 'radio',
    containerClass: 'w-100',
    formControlOption: {
      name: formControlOutputNames.exchangeNumber,
      required: true,
      inline: true,
    },
    options: [
      {
        name: '發送相同序號，同一序號會員可兌換指定次數',
        code: 30,
      },
    ],
  },
  sameNumberCount: {
    type: 'select',
    containerClass: 'mt-2',
    formControlOption: {
      name: formControlOutputNames.sameNumberCount,
      required: true,
      placeholder: '請選擇',
    },
    selectOption: {
      dropdownToggleOption: {
        className: 'ml-4',
        disabled: true,
      },
    },
    options: [],
  },
  couple: {
    type: 'text',
    formControlOption: {
      name: formControlOutputNames.couple,
      required: true,
      placeholder: '單次不可高於 1 萬組',
      autoComplete: 'off',
    },
  },
}

// 表單設定（版面配置）
const formConfig = {
  [formGroups.prefix]: {
    title: '序號前綴',
    groupClass:
      'group-prefix d-flex col-12 col-lg-6 pl-0 pr-0 pr-lg-3 mb-3 mb-lg-0',
    labelClass: 'mt-1',
  },
  [formGroups.length]: {
    title: '序號總長',
    groupClass:
      'group-length d-flex col-12 col-lg-6 pr-0 pl-0 pl-lg-3 mb-3 mb-lg-0',
    labelClass: 'mt-1',
  },
  [formGroups.exchange]: {
    title: '序號兌換',
    groupClass:
      'group-exchange d-flex col-12 col-lg-6 pl-0 pr-0 pr-lg-3 mb-3 mb-lg-0',
    labelClass: '',
  },
  [formGroups.couple]: {
    title: '生成組數',
    groupClass:
      'group-couple d-flex col-12 col-lg-6 pr-0 pl-0 pl-lg-3 mb-3 mb-lg-0',
    labelClass: 'mt-1',
  },
}

const formSectionSetting = {
  formControlOutputNames,
  formGroups,
  fieldRules,
  formGroupErrors,
  allFieldTypeSwitchProp,
  formConfig,
}

/* -------------------------------------- 
  Caption 區塊
--------------------------------------*/

// 查詢條件設定
const queryCondition = {
  type: {
    name: 'searchType',
  },
  text: {
    name: 'keyword',
  },
}

// 查詢類型的下拉選單所需options參數
const queryListOptions = [
  {
    text: '序號',
    value: 1,
  },
  {
    text: '手機',
    value: 2,
  },
]

// 初始值的查詢類型的下拉選單
const initQueryType = {
  code: queryListOptions[0].value,
  name: queryListOptions[0].text,
  selected: true,
}

// 匯出檔案的選擇類型
const exportType = {
  allList: 'allList',
  allImg: 'allImg',
  unexchangedImg: 'unexchangedImg',
}
// 匯出資料的下拉選單所需options參數
const exportDataConfig = {
  options: [
    {
      text: '全部清單',
      value: exportType.allList,
      divider: true,
    },
    {
      text: '全部圖檔',
      value: exportType.allImg,
    },
    {
      text: '未兌換圖檔',
      value: exportType.unexchangedImg,
    },
  ],
  formControlOption: {
    name: 'abc',
    placeholder: '匯出資料',
  },
}

const captionSetting = {
  queryCondition,
  queryListOptions,
  initQueryType,
  exportDataConfig,
  exportType,
}

/* -------------------------------------- 
  ModalSection 區塊
--------------------------------------*/
const modalSectionType = {
  qrcode: 'qrcode',
  link: 'link',
  readyExport: 'readyExport',
  memberInfo: 'memberInfo',
  serinalNumberUpdateSetting: 'serinalNumberUpdateSetting',
}
const modalSectionConfig = {
  [modalSectionType.qrcode]: {
    title: 'QRCode 活動',
    close: '關閉',
  },
  [modalSectionType.link]: {
    title: 'QRCode 連結',
    confirm: '複製連結',
  },
  [modalSectionType.readyExport]: {
    title: '即將匯出',
    confirm: '繼續',
    close: '返回',
  },
  [modalSectionType.memberInfo]: {
    title: '會員資訊',
    confirm: '詳情',
    close: '關閉',
  },
  [modalSectionType.serinalNumberUpdateSetting]: {
    title: '變更設定',
    confirm: '繼續',
    close: '返回',
  },
}
const modalSectionSetting = {
  modalSectionType,
  modalSectionConfig,
}
export {
  commonSetting,
  formSectionSetting,
  captionSetting,
  modalSectionSetting,
  qrcodeActivityListStatus,
}
