import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import SubNavItem from 'components/layouts/SubNavItem'
import { dynamicPathRegex, setHomeDataStorage } from 'helpers/common'

const SidebarLink = styled(NavLink)`
  display: flex;
  color: rgba(51, 51, 51, 0.75);
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 40px;
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  font-family: 'Noto Sans TC';
  border-right: 2px solid transparent;

  svg {
    width: 16px;

    path {
      fill: #333333;
      fill-opacity: 0.3;
    }
  }
  &.subnav-icon-hide {
    svg:last-child {
      display: none;
    }
  }
  &.parent-menu {
    &.on {
      background-color: #f2f2f2;

      &:hover {
        background-color: rgba(75, 101, 166, 0.1);

        > .icon-menu-switch {
          border-color: transparent;
          border-top-color: ${colorObj.primary};
        }
      }

      > svg {
        transform: rotate(90deg);
      }
    }
  }
  &.selected {
    color: #4b65a6;
    border-right: 2px solid #4b65a6;
    background-color: rgba(75, 101, 166, 0.1);

    svg {
      path {
        fill: #4b65a6;
        fill-opacity: 1;
      }
    }
  }
  &:hover {
    background-color: rgba(75, 101, 166, 0.1);
    border-right: 2px solid #4b65a6;
    cursor: pointer;
    color: #4b65a6;

    > .icon-menu-switch {
      border-right-color: ${colorObj.primary};
    }
    svg path {
      fill: #4b65a6;
      fill-opacity: 1;
    }
  }
`

/**
 * @param {object} sub
 * @param {string} currentPath
 * @returns {boolean}
 * @description 判斷當前路徑是否符合子選單的路徑，已打開子選單
 * @example sub = { path: '/member', subNav: [{ path: '/member/edit' }] } currentPath = '/member/edit' return true
 */
const handleSubNavOpen = (sub, currentPath) => {
  return sub.subNav.some(({ path, linkedPage }) => {
    if (path === currentPath) {
      return true
    }

    return linkedPage.some((link) => {
      return dynamicPathRegex(link, currentPath)
    })
  })
}

/**
 * @param {object} sub
 * @param {string} currentPath
 * @returns {object}
 * @description 針對 sub 數據增加 isActive 屬性，並針對subNav 增加 isActive 屬性
 */
const handleSubActive = (sub, currentPath) => {
  return {
    ...sub,
    isActive: sub.path === currentPath,
    subNav: sub.subNav.map((subNavItem) => {
      return {
        ...subNavItem,
        isActive:
          subNavItem.path === currentPath ||
          subNavItem.linkedPage.some((linkedPage) => {
            return dynamicPathRegex(linkedPage, currentPath)
          }),
      }
    }),
  }
}

function SidebarDropdown({ navItem }) {
  const history = useHistory()
  const { pathname } = history.location

  const [activeNavItem, setActiveNavItem] = useState(
    handleSubActive(navItem, pathname)
  )

  const [isSubNavOpen, setSubNavOpen] = useState(
    handleSubNavOpen(activeNavItem, pathname)
  )

  const handleClick = (e, itemProps) => {
    if (itemProps?.subNav) {
      setSubNavOpen((prevState) => !prevState)
    }
    if (itemProps?.path === '#') {
      e?.preventDefault()
    }
  }

  useEffect(() => {
    const unListen = history.listen((location) => {
      setActiveNavItem(handleSubActive(navItem, location.pathname))
      setSubNavOpen(handleSubNavOpen(navItem, location.pathname))
    })
    return () => {
      unListen()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SidebarLink
        exact
        to={activeNavItem.path}
        className={`parent-menu ${isSubNavOpen ? 'on' : ''}`}
        activeClassName="selected"
        isActive={() => activeNavItem.isActive}
        onClick={(e) => handleClick(e, activeNavItem)}
        onMouseDown={setHomeDataStorage}
      >
        <div className="d-flex align-items-center">
          {activeNavItem.icon}
          <span className="ml-3">{activeNavItem.title}</span>
        </div>
        {isSubNavOpen ? activeNavItem.iconOpened : activeNavItem.iconClosed}
      </SidebarLink>
      {isSubNavOpen &&
        activeNavItem.subNav.map((item) => (
          <SubNavItem
            key={`subNavItem-${item.id}`}
            subNavItem={item}
            onHomeData={setHomeDataStorage}
          />
        ))}
    </>
  )
}

export default SidebarDropdown

SidebarDropdown.propTypes = {
  navItem: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.element,
    iconOpened: PropTypes.element,
    iconClosed: PropTypes.element,
    permissionKeys: PropTypes.arrayOf(PropTypes.string),
    subNav: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        path: PropTypes.string,
        permissionKeys: PropTypes.arrayOf(PropTypes.string),
        isOnSubcomponent: PropTypes.string,
      })
    ),
  }),
}

SidebarDropdown.defaultProps = {
  navItem: {},
}
