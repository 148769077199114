/* -------------------------------------- 
  SearchSection 的設定
--------------------------------------*/
// table中的排序方式
const defaultSort = {
  so: 'desc',
  sf: 'createdTime',
}
// 欄位name名稱命名
const fromControlName = {
  cellPhone: 'cellPhone',
  customId: 'customId',
  date: 'date',
  name: 'name',
}
// 欄位元件參數
const fieldProps = {
  [fromControlName.cellPhone]: {
    title: '手機',
    name: 'cellPhone',
  },
  [fromControlName.customId]: {
    title: '會員編號',
    name: 'customId',
  },
  [fromControlName.date]: {
    title: '時間',
    name: 'date',
    placeholder: '時間',
  },
  [fromControlName.name]: {
    title: '名稱',
    name: 'name',
    placeholder: '活動名稱',
  },
}

const searchSectionSetting = {
  defaultSort,
  fromControlName,
  fieldProps,
}

/* -------------------------------------- 
  TabSection 的設定
--------------------------------------*/
// tab頁籤設定
const tabOption = {
  getPointFail: {
    eventKey: 'get-point-fail',
    title: 'QRCode 掃描失敗',
  },
  getPointSuccess: {
    eventKey: 'get-point-success',
    title: 'QRCode 掃描成功',
  },
}
const modalType = {
  getPointFailInfo: 'getPointFailInfo',
}

const modalConfig = {
  [modalType.getPointFailInfo]: {
    title: '掃描失敗詳情',
    close: '關閉',
  },
}

const modalSetting = {
  modalType,
  modalConfig,
}

const tabSectionSetting = {
  tabOption,
  modalSetting,
}

export { searchSectionSetting, tabSectionSetting }
