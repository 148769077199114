import { getEmailValidationThunk } from 'store/account-setting/accountSettingAsync'
import { getMemberDashboardThunk } from 'store/dashboard/membersDashboardSlice'
import {
  getExchangeRecordDetailThunk,
  getExchangeCodeDetailThunk,
} from 'store/gift-exchange/giftExchangeSlice'
import { getPermissionsThunk } from 'store/permission/permissionSlice'
import { getQrcodeGeneratedCodeDetailThunk } from 'store/qrcode-serail-number-detail/qrcodeSerialNumberDetailSlice'
import {
  getVipExchangeCodeDetailThunk,
  getVipExchangeRecordDetailThunk,
} from 'store/vip-gift-exchange/vipGiftExchangeSlice'
import { batchDeleteExchangeCodeThunk } from './gift-exchange/giftExchangeSerialSlice'
import {
  getVipGiftExchangeRecordDetailThunk,
  getGiftExchangeRecordDetailThunk,
  getVipGiftVerificationDetailThunk,
  getExchangeGiftVerificationDetailThunk,
} from './gift-list/giftListAsync'
import { getMemberOutlineThunk } from './member-outline/memberOutlineSlice'
import { getQrcodePointRecordDetailThunk } from './qrcode-activity-form/qrcodeActivityFormSlice'

export const appLoadingList = [getPermissionsThunk.typePrefix]

export const pageLoadingList = [getMemberDashboardThunk.typePrefix]

export const loadingSkipList = [
  getEmailValidationThunk.typePrefix,
  getMemberOutlineThunk.typePrefix,
  getQrcodePointRecordDetailThunk.typePrefix,
  getQrcodeGeneratedCodeDetailThunk.typePrefix,
  getExchangeCodeDetailThunk.typePrefix,
  getExchangeRecordDetailThunk.typePrefix,
  getVipExchangeCodeDetailThunk.typePrefix,
  getVipExchangeRecordDetailThunk.typePrefix,
  batchDeleteExchangeCodeThunk.typePrefix,
  getVipGiftExchangeRecordDetailThunk.typePrefix,
  getGiftExchangeRecordDetailThunk.typePrefix,
  getVipGiftVerificationDetailThunk.typePrefix,
  getExchangeGiftVerificationDetailThunk.typePrefix,
]
