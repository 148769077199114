import styled from 'styled-components'
import Tabs from 'react-bootstrap/Tabs'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { colorCommon, colorObj } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

/* SearchSection 區塊 */
const FormItemContainerStyle = styled(Row)`
  gap: 20px;
`
const FormItemStyle = styled(Col)`
  .label-title {
    margin-right: 5px;
  }
`

/* TabSection 區塊 */
const TabsWrapper = styled(Tabs)`
  + .tab-content {
    padding: 24px;
    .paragraph {
      font-size: 13px;
      color: ${colorObj.dark};
    }
  }
`
const TableWrapperStyle = styled.div`
  .td-status {
    .style-tooltip-container {
      display: flex;
      justify-content: center;
    }
  }
`
const TableImageStyle = styled.div`
  img {
    cursor: pointer;
    max-width: 48px;
  }
`
const GetPointFailDetailStyle = styled.div`
  min-width: 318px;

  .info-group {
    border-bottom: 1px solid ${colorCommon.borderGray};
    margin-bottom: ${spacingObj.medium};

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;

      .basicinfo-list {
        margin-bottom: 0;
      }
    }
    .basicinfo-list,
    .extrainfo-list {
      margin-bottom: ${spacingObj.medium};

      .list-title {
        display: inline-block;
        margin-right: 10px;
        color: ${colorObj.darkerGray};
      }
      .list-value {
        max-width: 260px;
        display: inline-block;
      }
    }
    img {
      max-width: 320px;
    }
  }
`

export {
  FormItemContainerStyle,
  FormItemStyle,
  TabsWrapper,
  TableWrapperStyle,
  TableImageStyle,
  GetPointFailDetailStyle,
}
