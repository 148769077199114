import { createSlice, createSelector, isAnyOf } from '@reduxjs/toolkit'
import {
  getRolesThunk,
  getShopsThunk,
  getEmailValidationThunk,
  postAccountThunk,
  getAccountThunk,
  patchAccountThunk,
} from 'store/account-setting/accountSettingAsync'
import { ACCOUNT_SETTING } from 'store/constants'

export const accountSettingInitState = {
  getShopsRsp: {
    data: {},
    code: 200,
    errors: '',
    msg: '',
  },
  getRolesRsp: {
    data: {},
    code: 200,
    errors: '',
    msg: '',
  },
  getEmailValidationRsp: {
    data: {
      accountName: '',
      status: null, // AccountNew: 1 BrandRelNew: 2 Exist: 3
      statusDescription: '',
    },
    code: 200,
    errors: '',
    msg: '',
    isFetch: false,
  },
  getAccountDataRsp: {
    data: {},
    code: null,
    msg: null,
    errors: null,
    isFetch: false,
  },
  accountCheck: {
    isSuccess: null,
    validationMsg: '',
    name: '',
    isShow: false, // 若有 isShow : true ，表示 api 為最新狀態，才使用這些值
  },
  selectedShops: [],
  isWarnModalOpen: false,
  isPermissionModalOpen: false,
}

const accountSettingSlice = createSlice({
  name: ACCOUNT_SETTING,
  initialState: accountSettingInitState,
  reducers: {
    updateSelectedShops: (state, action) => {
      const { shopCode } = action.payload
      const shopList = state.selectedShops
      const isAll =
        state.selectedShops?.length ===
        state.getShopsRsp?.data?.shopList?.length
      if (shopCode === 'all') {
        if (isAll) {
          state.selectedShops = []
        } else {
          state.selectedShops = state.getShopsRsp?.data?.shopList.map(
            (shop) => shop
          )
        }
      } else {
        const hasSelectedShop = shopList.some((shop) => shop.code === shopCode)
        if (hasSelectedShop) {
          state.selectedShops = shopList.filter(
            (shop) => shop.code !== shopCode
          )
        } else {
          state.selectedShops = [
            ...shopList,
            ...state.getShopsRsp.data.shopList.filter(
              (shop) => shop.code === shopCode
            ),
          ]
        }
      }
    },
    openWarnModal: (state) => {
      state.isWarnModalOpen = true
    },
    closeWarnModal: (state) => {
      state.isWarnModalOpen = false
    },
    openPermissionModal: (state) => {
      state.isPermissionModalOpen = true
    },
    closePermissionModal: (state) => {
      state.isPermissionModalOpen = false
    },
    resetEamilValidation: (state) => {
      state.getEmailValidationRsp = {
        ...accountSettingInitState.getEmailValidationRsp,
      }
    },
    resetSelectedShops: (state) => {
      state.selectedShops = []
    },
    resetAccountData: (state) => {
      state.getAccountDataRsp = {
        ...accountSettingInitState.getAccountDataRsp,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => action.type === postAccountThunk.rejected.type,
        () => {
          throw new Error('新增帳號失敗')
        }
      )
      .addMatcher(
        (action) =>
          action.type === getShopsThunk.fulfilled.type ||
          action.type === getShopsThunk.rejected.type,
        (state, action) => {
          state.getShopsRsp = action.payload
          state.shopsCheckedStatus = action.payload.data.shopList
        }
      )
      .addMatcher(
        (action) =>
          action.type === getRolesThunk.fulfilled.type ||
          action.type === getRolesThunk.rejected.type,
        (state, action) => {
          state.getRolesRsp = action.payload
        }
      )
      .addMatcher(
        (action) =>
          action.type === getEmailValidationThunk.fulfilled.type ||
          action.type === getEmailValidationThunk.rejected.type,
        (state, action) => {
          if (action.payload) {
            state.getEmailValidationRsp.data = action.payload
            state.getEmailValidationRsp.isFetch = true
          }
        }
      )
      .addMatcher(
        isAnyOf(getAccountThunk.fulfilled, getAccountThunk.rejected),
        (state, action) => {
          state.getAccountDataRsp = {
            ...action.payload,
            isFetch: true,
          }
          // 更新 selectedShops
          state.selectedShops = action.payload.data.shopList
        }
      )
      .addMatcher(
        (action) => action.type === patchAccountThunk.rejected.type,
        () => {
          throw new Error('編輯帳號失敗')
        }
      )
  },
})

const shopsSelector = createSelector(
  (state) => state[ACCOUNT_SETTING].getShopsRsp,
  (getShopsRsp) => {
    const shopList = getShopsRsp?.data?.shopList || []
    const shopListWithAllShopOption = [
      {
        code: 'all',
        name: '全部門店',
      },
      ...shopList,
    ]
    return shopListWithAllShopOption || []
  }
)

const rolesSelector = createSelector(
  (state) => state[ACCOUNT_SETTING].getRolesRsp,
  (getRolesRsp) => {
    const rolesForDropdown = getRolesRsp?.data?.roleList?.map((role) => {
      return {
        dropdownItemOption: {
          eventKey: role.code,
        },
        text: role.name,
        value: role.code,
      }
    })
    return rolesForDropdown
  }
)

const selectedShopsSelector = createSelector(
  (state) => state[ACCOUNT_SETTING]?.getShopsRsp?.data?.shopList || [],
  (state) => state[ACCOUNT_SETTING]?.selectedShops || [],
  (shopList, selectedShops) => {
    if (selectedShops.length === shopList.length) {
      return [...selectedShops, { code: 'all', name: '全部門店' }]
    }
    return selectedShops
  }
)

const accountCheckSelector = createSelector(
  (state) => state[ACCOUNT_SETTING].getEmailValidationRsp,
  (state) => state[ACCOUNT_SETTING].accountCheck,
  (getEmailValidationRsp, accountCheck) => {
    const { accountName, status, statusDescription } =
      getEmailValidationRsp.data
    return {
      ...accountCheck,
      isSuccess: (status && status !== 3) ?? null, // 狀態icon
      validationMsg: statusDescription ?? '', // 信箱欄位的驗證提示
      name: accountName ?? '', // 名稱欄位自動帶入
      isShow: getEmailValidationRsp.isFetch,
    }
  }
)

const accountDataSelector = createSelector(
  (state) => state[ACCOUNT_SETTING].getAccountDataRsp,
  (getAccountDataRsp) => {
    return { data: getAccountDataRsp.data, isFetch: getAccountDataRsp.isFetch }
  }
)

const modalStatusSelector = createSelector(
  (state) => state[ACCOUNT_SETTING]?.isWarnModalOpen,
  (state) => state[ACCOUNT_SETTING]?.isPermissionModalOpen,
  (isWarnModalOpen, isPermissionModalOpen) => ({
    isWarnModalOpen,
    isPermissionModalOpen,
  })
)

export {
  shopsSelector,
  rolesSelector,
  selectedShopsSelector,
  modalStatusSelector,
  accountCheckSelector,
  accountDataSelector,
}
export const {
  updateSelectedShops,
  openWarnModal,
  closeWarnModal,
  openPermissionModal,
  closePermissionModal,
  resetEamilValidation,
  resetSelectedShops,
  resetAccountData,
} = accountSettingSlice.actions
export const accountSettingReducer = accountSettingSlice.reducer
