// import { useSelector } from 'react-redux'
import { useSelector } from 'react-redux'
import PasswordSettingContainer from 'components/pages/passwordSetting/containers/PasswordSetting'
import LocalStyle from 'pages/passwordSetting/PasswordSettingStyle'
import { tokenSelector } from 'store/account-verify/accountVerifySlice'

export default function PasswordSetting() {
  const currentYear = new Date().getFullYear()
  const pageContent = {
    enableAccount: {
      title: '設定密碼',
      subTitle: '首次啟用帳號，請進行設定密碼',
    },
    resetPassword: {
      title: '重設密碼',
      subTitle: '完成重設密碼後，即可重新登入',
    },
  }
  const { type } = useSelector(tokenSelector)

  return (
    <LocalStyle>
      <div className="main">
        <div className="welcome-section">Welcome to</div>
        <div className="litloyal-section">LitLoyal</div>
        <div className="subtitle-section">創造高忠誠的會員經濟</div>
        <div className="margin-t-40 margin-b-40">
          <hr className="line-section" />
        </div>
        <div className="form-title">{pageContent[type].title}</div>
        <div className="form-subTitle">{pageContent[type].subTitle}</div>
        <PasswordSettingContainer />
      </div>
      <footer>Copyright © {currentYear} MIGO Corp. All rights reserved</footer>
    </LocalStyle>
  )
}
