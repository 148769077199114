import { toast } from 'react-hot-toast'
import { toastErrorContent } from 'helpers/common'
import { fetchData } from 'api/ApiRoute'
import { commonFetchProps, getSearchParameter } from 'api/ApiCommon'

const handleError = async (res) => {
  if (res.isCancel) {
    return Promise.reject(res.data ?? res)
  }
  switch (res.status) {
    case 400:
      if (res?.data?.msg) {
        toast.error((t) => toastErrorContent(res.status, res.data.msg, t.id))
        return Promise.reject(res.data ?? res)
      }
  }
}

// 獲得QRcode活動列表
const getQrcode = (brandId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `setting/brand/${brandId}/qrcode${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 獲得Qrcode活動單一項目
const getQrcodeOnce = (brandId, qrCodeActivitySettingId, cancelToken) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrCodeActivitySettingId}`,
    'GET',
    null,
    cancelToken,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 新增QRcode活動
const postQrcode = (brandId, data) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode`,
    'POST',
    data,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 編輯QRcode活動
const patchQrcode = (brandId, qrCodeActivitySettingId, data) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrCodeActivitySettingId}`,
    'PATCH',
    data,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 刪除QRcode活動
const deleteQrcode = (brandId, qrCodeActivitySettingId) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrCodeActivitySettingId}`,
    'DELETE',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 停用QRcode活動
const patchStopQrcode = (brandId, qrCodeActivitySettingId) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrCodeActivitySettingId}/stop`,
    'PATCH',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 獲得額外贈點列表
const getGainPointRule = (brandId, qrcodeId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeId}/gainpointrule${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}

// 獲得額外贈點單一項目資料
const getGainPointRuleOnce = (brandId, qrcodeId, gainPointRuleId) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeId}/gainpointrule/${gainPointRuleId}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 新增額外贈點
const postQrcodeGainPointRule = (brandId, qrcodeId, data) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeId}/gainpointrule`,
    'POST',
    data,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 編輯額外贈點
const patchQrcodeGainPointRule = (brandId, qrcodeId, data, gainPointRuleId) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeId}/gainpointrule/${gainPointRuleId}`,
    'PATCH',
    data,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 刪除額外贈點
const deleteQrcodeGainPointRule = (brandId, qrcodeId, gainPointRuleId) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeId}/gainpointrule/${gainPointRuleId}`,
    'DELETE',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 停用額外贈點
const patchQrcodeGainPointRuleStop = (brandId, qrcodeId, gainPointRuleId) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeId}/gainpointrule/${gainPointRuleId}/stop`,
    'PATCH',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 獲得QRcode序號兌換指定次數
const getQrcodeExchangeTimesItems = (brandId) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/exchangetimesitems`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 獲得QRcode序號列表
const getQrcodeGeneratedCode = (
  brandId,
  qrcodeActivitySettingId,
  queryStringObj
) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeActivitySettingId}/generatedcode${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 獲得QRcode序號列表 - 詳細頁內容
const getQrcodeGeneratedCodeDetail = ({
  brandId,
  qrcodeActivitySettingId,
  qrCodeGeneratedCodeId,
}) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeActivitySettingId}/generatedcode/${qrCodeGeneratedCodeId}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data
    })
    .catch(handleError)
}
// 新增QRcode序號
const postQrcodeGeneratedCode = (
  brandId,
  qrcodeActivitySettingId,
  data,
  cancelToken
) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeActivitySettingId}/generatedcode`,
    'POST',
    data,
    cancelToken || commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 刪除所有QRcode序號
const deleteQrcodeGeneratedCode = (brandId, qrcodeActivitySettingId, data) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeActivitySettingId}/generatedcode`,
    'DELETE',
    data,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 刪除指定QRcode序號
const deleteQrcodeGeneratedCodeBatch = (
  brandId,
  qrcodeActivitySettingId,
  data
) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeActivitySettingId}/generatedcode/batch`,
    'DELETE',
    data,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 停用指定QRcode序號
const patchQrcodeGeneratedCodeStop = (
  brandId,
  qrcodeActivitySettingId,
  data
) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeActivitySettingId}/generatedcode/stop`,
    'PATCH',
    data,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 獲得QRcode全部序號匯出清單
const getQrcodeExportFile = (brandId, qrcodeActivitySettingId) => {
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeActivitySettingId}/generatedcode/exportfile`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      // 這邊需注意data的階層，與其他後端api格式不同
      return rsp?.data
    })
    .catch(handleError)
}
// 獲得QRcode全部序號匯出清單
const getQrcodeExportImage = (
  brandId,
  qrcodeActivitySettingId,
  queryStringObj
) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `setting/brand/${brandId}/qrcode/${qrcodeActivitySettingId}/generatedcode/exportimage${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}

export {
  getQrcode,
  getQrcodeOnce,
  postQrcode,
  patchQrcode,
  deleteQrcode,
  patchStopQrcode,
  getGainPointRule,
  getGainPointRuleOnce,
  postQrcodeGainPointRule,
  patchQrcodeGainPointRule,
  deleteQrcodeGainPointRule,
  patchQrcodeGainPointRuleStop,
  getQrcodeExchangeTimesItems,
  getQrcodeGeneratedCode,
  getQrcodeGeneratedCodeDetail,
  postQrcodeGeneratedCode,
  deleteQrcodeGeneratedCode,
  deleteQrcodeGeneratedCodeBatch,
  patchQrcodeGeneratedCodeStop,
  getQrcodeExportFile,
  getQrcodeExportImage,
}
