import styled from 'styled-components'
import { Col, Row, Container } from 'components/units'

const Wrapper = styled.div`
  margin: 40px;
  width: 100%;
`

const StyledHeaderRow = styled(Row)`
  height: 60px;

  .width-max-content {
    width: max-content;
  }
`

const StyledRow = styled(Row)`
  margin-top: 24px !important;
`

const StyledCol = styled(Col)`
  &:nth-child(2) {
    margin-left: 20px;
    align-self: end;
  }
`

function ContentSection(props) {
  return (
    <StyledRow {...props}>
      <StyledCol>{props.children}</StyledCol>
    </StyledRow>
  )
}

function Basic({
  navSection: NavSection = null,
  sideSection: SideSection = null,
  ...props
}) {
  return (
    <Wrapper {...props}>
      <Container>
        <StyledHeaderRow>
          <StyledCol>{NavSection && <NavSection {...props} />}</StyledCol>
          <StyledCol>{SideSection && <SideSection {...props} />}</StyledCol>
        </StyledHeaderRow>
        {props.children}
      </Container>
    </Wrapper>
  )
}

export { ContentSection }
export default Basic
