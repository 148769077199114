import { useSelector, useDispatch } from 'react-redux'
import { Col } from 'react-bootstrap'
import { Breadcrumb, Button } from 'components/units'
import {
  BaseSetting,
  GivePointSetting,
} from 'components/pages/PointSetting/Container'
import withPermission from 'components/hocs/withPermission'
import { patchPointStartCompute, deleteGainPointRules } from 'api/ApiMain'
import { handleCatch } from 'components/pages/PointSetting/PointSettingHelper'
import LocalStyle from './PointSettingStyle'
import { COMPONENT_PERMISSIONS, PAGE_BREADCRUMB } from 'constant'
import { Basic, ContentSection } from 'components/templates'
import { colorObj } from 'assets/styles/Variable/Color'
import { authAccountSelector } from 'store/auth/authSelector'
import {
  pointSettingRspSelector,
  statusSelector,
  isCalculateBtnDisabledSelector,
  changeIsCalculateLoading,
  changeIsCalculateDisabled,
} from 'store/point-setting/pointSettingSlice'

function SideSection({
  isCalculateBtnShow,
  isCalculateBtnDisabled,
  startComputeDate,
  isCalculateLoading,
  handleCalculateClick,
}) {
  const { CREATE } = COMPONENT_PERMISSIONS.point
  const PermissionCalculateButton = withPermission(Button, CREATE)

  return (
    <Col className="no-padding">
      <div className="position-relative text-right">
        {isCalculateBtnShow && (
          <PermissionCalculateButton
            variant="outline-gray"
            isLoading={isCalculateLoading}
            onClick={handleCalculateClick}
            disabled={isCalculateBtnDisabled}
            loaderOption={{
              colorType: 'dark',
            }}
          >
            開始計算
          </PermissionCalculateButton>
        )}
      </div>
      {!isCalculateBtnShow && (
        <Button
          disabled
          variant="outline-darkerGray"
          colorType="darkerGray"
          size="lg"
          style={{
            backgroundColor: 'transparent',
            padding: '22px 20px',
            color: `${colorObj.dark}`,
            fontSize: '15px',
            float: 'right',
          }}
        >
          此設定於 {startComputeDate} 生效，修改請聯繫 Migo 客服
        </Button>
      )}
    </Col>
  )
}

function PointSetting() {
  const dispatch = useDispatch()
  const { brandId } = useSelector(authAccountSelector)
  const { startComputeDate, isStartComputeDisabled, baseId, isFetch } =
    useSelector(pointSettingRspSelector)
  const { isCalculateLoading } = useSelector(statusSelector)

  /* 開始計算鈕 disabled */
  const isCalculateBtnDisabled = useSelector(isCalculateBtnDisabledSelector)

  // "開始計算"按鈕按下後的處理
  const handleCalculateClick = async () => {
    try {
      dispatch(changeIsCalculateDisabled(true))
      dispatch(changeIsCalculateLoading(true))
      await patchPointStartCompute(brandId, baseId)
    } catch (error) {
      handleCatch(error)
    } finally {
      dispatch(changeIsCalculateLoading(false))
    }
  }

  return (
    <>
      <Basic
        navSection={() => Breadcrumb(PAGE_BREADCRUMB.pointContent.setting)}
        sideSection={() =>
          SideSection({
            isCalculateBtnShow: !isStartComputeDisabled,
            isCalculateBtnDisabled,
            startComputeDate,
            isCalculateLoading,
            handleCalculateClick,
          })
        }
      >
        <ContentSection>
          <LocalStyle>
            <div className="point-setting-container mt-0">
              {/* 基本設定 */}
              <BaseSetting />

              {/* 贈點設定 */}
              {isFetch && <GivePointSetting />}
            </div>
          </LocalStyle>
        </ContentSection>
      </Basic>
    </>
  )
}

export default PointSetting
