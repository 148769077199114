import React from 'react'
import ProductActivityList from './ProductActivity/Main/ProductActivityList'
import ProductActivityManagement from './ProductActivity/Sub/ProductActivityManagement'
import ProductList from './ProductActivity/Sub/ProductList'
import QrcodeActivityList from './QrcodeActivity/Index/QrcodeActivityList'
import ActivityFormRecordList from './ActivityFormRecord/Index/ActivityFormRecordList'
import PointActivityList from './PointActivity/PointActivityList'

const SettingPage = { ProductActivityManagement, ProductList }
const ListPage = {}

/* 商品活動頁面 */
const ProductActivityPage = { ProductActivityList }

/* Qrcode活動頁面 */
const ActivityContent = React.lazy(() =>
  import('./QrcodeActivity/ActivityContent/ActivityContent')
)
const SerialNumber = React.lazy(() =>
  import('./QrcodeActivity/SerialNumber/SerialNumber')
)
const ExtraPoint = React.lazy(() =>
  import('./QrcodeActivity/ExtraPoint/ExtraPoint')
)
const FormRecord = React.lazy(() =>
  import('./QrcodeActivity/FormRecord/FormRecord')
)

/* 點數活動頁面 */
const PointActivityPage = { PointActivityList }

/* QRcode活動頁面 */
const QrcodeActivityPage = {
  QrcodeActivityList, // 總表
  ActivityContent, // 活動內容頁籤
  SerialNumber, // Qrcode序號頁籤
  ExtraPoint, // 額外贈點頁籤
  FormRecord, // 表單紀錄頁籤
}

/* 活動表單紀錄頁面 */
const ActivityFormRecordPage = {
  ActivityFormRecordList, // 總表
}

export {
  ProductActivityPage,
  QrcodeActivityPage,
  ActivityFormRecordPage,
  PointActivityPage,
  SettingPage,
  ListPage,
}
