import PropTypes from 'prop-types'
import styled from 'styled-components'

const NoStyleBtn = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: ${({ style }) => (style.underline ? 'underline' : 'none')};
  color: ${({ style }) => style.color || 'inherit'};
  font-weight: ${({ style }) => style.fontWeight || 'inherit'};
  cursor: pointer;
`

function PlainButton({ children, onClick, style, className }) {
  return (
    <NoStyleBtn
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      style={style}
      className={className}
    >
      {children}
    </NoStyleBtn>
  )
}

export default PlainButton

PlainButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.shape({
    underline: PropTypes.bool,
    color: PropTypes.string,
    fontWeight: PropTypes.string,
  }),
  className: PropTypes.string,
}

PlainButton.defaultProps = {
  children: null,
  style: {},
  className: '',
}
