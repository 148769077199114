/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  getExchangeCodeList,
  switchExchangeCodeType,
  batchDeleteExchangeCode,
  exportExchangeCode,
  patchExchangeCodeRules,
} from 'api/ApiMain'
// eslint-disable-next-line import/no-cycle
import {
  codeGetType,
  buildType,
} from 'components/pages/Gift/ExchangeSerialNumberConfig'
import { GIFT_EXCHANGE_SERIAL } from 'store/constants'
import { giftExchangeStateSelector } from './giftExchangeSlice'

export const giftExchangeSerialInitState = {
  exchangeCodeListRsp: {
    data: {
      sort: {
        field: '',
        order: '',
        availableFields: [],
      },
      conditionalReceivedCount: 0,
      conditionalUnexchangedCodeCount: 0,
      totalSize: 0,
      rows: [],
      exchangeCode: {
        unexchangedCount: 0,
        exchangedCount: 0,
        totalCount: 0,
      },
    },
    code: 0,
    msg: null,
    errors: null,
    isFetch: false,
  },
  exchangeCodeExportRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      downloadUrl: '',
    },
    isFetch: false,
  },
  switchExchangeCodeTypeRsp: {
    code: null,
    msg: '',
    errors: {},
    data: {
      deleteCount: null,
    },
    isFetch: false,
  },
  batchDeleteExchangeCodeRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {},
    isFetch: false,
  },
  patchExchangeCodeRulesRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      ruleConfirmedTimeString: '',
      giftSettingStatus: '',
    },
    isFetch: false,
  },
}

const getExchangeCodeListThunk = createAsyncThunk(
  `${GIFT_EXCHANGE_SERIAL}/getExchangeCodeList`,
  async ({ brandId, giftSettingId, payload }, { rejectWithValue }) => {
    try {
      const rsp = await getExchangeCodeList(brandId, giftSettingId, payload)
      return rsp?.data // 先保持原本api的格式，日後可統一rsp
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)
const switchExchangeCodeTypeThunk = createAsyncThunk(
  `${GIFT_EXCHANGE_SERIAL}/switchExchangeCodeType`,
  async ({ brandId, giftSettingId, body }, { rejectWithValue }) => {
    try {
      const rsp = await switchExchangeCodeType(brandId, giftSettingId, body)
      return rsp?.data // 先保持原本api的格式，日後可統一rsp
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)
const batchDeleteExchangeCodeThunk = createAsyncThunk(
  `${GIFT_EXCHANGE_SERIAL}/batchDeleteExchangeCode`,
  async ({ brandId, giftSettingId, body }, { rejectWithValue }) => {
    try {
      const rsp = await batchDeleteExchangeCode(brandId, giftSettingId, body)
      return rsp?.data // 先保持原本api的格式，日後可統一rsp
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const exportExchangeCodeThunk = createAsyncThunk(
  `${GIFT_EXCHANGE_SERIAL}/exportExchangeCode`,
  async ({ brandId, giftSettingId, payload }, { rejectWithValue }) => {
    try {
      const rsp = await exportExchangeCode({
        brandId,
        giftSettingId,
        payload,
      })
      return rsp
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const patchExchangeCodeRulesThunk = createAsyncThunk(
  `${GIFT_EXCHANGE_SERIAL}/patchExchangeCodeRules`,
  async ({ brandId, giftSettingId, payload }, { rejectWithValue }) => {
    try {
      const rsp = await patchExchangeCodeRules(brandId, giftSettingId, payload)
      return rsp
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const giftExchangeSerialSlice = createSlice({
  name: GIFT_EXCHANGE_SERIAL,
  initialState: giftExchangeSerialInitState,
  reducers: {
    resetToastState: (state) => {
      state.switchExchangeCodeTypeRsp =
        giftExchangeSerialInitState.switchExchangeCodeTypeRsp
      state.batchDeleteExchangeCodeRsp =
        giftExchangeSerialInitState.batchDeleteExchangeCodeRsp
    },
    resetPatchExchangeCodeRulesRsp: (state) => {
      state.patchExchangeCodeRulesRsp =
        giftExchangeSerialInitState.patchExchangeCodeRulesRsp
    },
  },
  extraReducers: (builder) => {
    builder.addCase(batchDeleteExchangeCodeThunk.fulfilled, (state, action) => {
      state.batchDeleteExchangeCodeRsp = action.payload
      state.batchDeleteExchangeCodeRsp.isFetch = true
    })
    builder.addCase(batchDeleteExchangeCodeThunk.rejected, (state, action) => {
      state.batchDeleteExchangeCodeRsp = action.payload
    })
    builder.addCase(switchExchangeCodeTypeThunk.rejected, (state, action) => {
      state.switchExchangeCodeTypeRsp = action.payload
    })
    builder.addCase(switchExchangeCodeTypeThunk.fulfilled, (state, action) => {
      state.switchExchangeCodeTypeRsp = action.payload
      state.switchExchangeCodeTypeRsp.isFetch = true
      state.exchangeCodeListRsp =
        giftExchangeSerialInitState.exchangeCodeListRsp
    })
    builder.addCase(patchExchangeCodeRulesThunk.rejected, (state, action) => {
      state.patchExchangeCodeRulesRsp = action.payload
    })
    builder.addCase(patchExchangeCodeRulesThunk.fulfilled, (state, action) => {
      state.patchExchangeCodeRulesRsp = action.payload
      state.patchExchangeCodeRulesRsp.isFetch = true
    })
    builder.addMatcher(
      (action) =>
        action.type === getExchangeCodeListThunk.fulfilled.type ||
        action.type === getExchangeCodeListThunk.rejected.type,
      (state, action) => {
        state.exchangeCodeListRsp = action.payload
        state.exchangeCodeListRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === exportExchangeCodeThunk.fulfilled.type ||
        action.type === exportExchangeCodeThunk.rejected.type,
      (state, action) => {
        state.exchangeCodeExportRsp = action.payload
        state.exchangeCodeExportRsp.isFetch = true
      }
    )
  },
})

const csvDownloadSelector = createSelector(
  (state) => state[GIFT_EXCHANGE_SERIAL].exchangeCodeExportRsp,
  (exchangeCodeExportRsp) => exchangeCodeExportRsp
)

const exchangeCodeListSelector = createSelector(
  (state) => state[GIFT_EXCHANGE_SERIAL].exchangeCodeListRsp,
  (exchangeCodeListRsp) => {
    const { data, isFetch } =
      exchangeCodeListRsp ?? giftExchangeSerialInitState.exchangeCodeListRsp

    const {
      rows = [],
      totalSize: conditionalCount,
      conditionalUnexchangedCodeCount,
      conditionalReceivedCount,
      exchangeCode,
    } = data
    const {
      unexchangedCount: totalUnexchangedCount = 0,
      totalCount = 0,
      exchangedCount, // 已兌換數量
    } = exchangeCode

    return {
      conditionalCount,
      conditionalUnexchangedCodeCount,
      conditionalReceivedCount,
      totalUnexchangedCount,
      totalCount,
      rows,
      exchangedCount,
      isFetch,
    }
  }
)

const exchangeRulesStateSelector = createSelector(
  giftExchangeStateSelector,
  exchangeCodeListSelector,
  (giftExchangeState, codeListState) => {
    const {
      amount, // 上架數量
      giftCodeType,
      giftCodePrefix,
      giftCodeGetType,
      giftCodeLength,
    } = giftExchangeState
    const {
      totalCount, // 生成序號數量
      totalUnexchangedCount, // 未兌換數量
    } = codeListState

    const isDifferentCode = giftCodeGetType === codeGetType.differentCode
    const isSameCode = giftCodeGetType === codeGetType.sameCode
    const isSystemType = giftCodeType === buildType.system
    const isUserType = giftCodeType === buildType.user

    const isAmountEnough =
      (isDifferentCode && amount === totalCount) || (isSameCode && totalCount)

    const isCodeExchanged =
      (isDifferentCode && totalCount !== totalUnexchangedCount) ||
      (isSameCode && amount !== totalUnexchangedCount)

    return {
      isDefaultRulesExisted: !!giftCodeType,
      defaultRules: {
        type: giftCodeType ?? null,
        prefix: giftCodePrefix ?? '',
        codeGetType: giftCodeGetType ?? null,
        length: giftCodeLength ?? 0,
      },
      uploadCount: amount,
      isAmountEnough,
      isCodeExchanged,
      isSystemType,
      isUserType,
      isSameCode, // 同序號
    }
  }
)

const messageStateSelector = createSelector(
  (state) => state[GIFT_EXCHANGE_SERIAL].switchExchangeCodeTypeRsp,
  (state) => state[GIFT_EXCHANGE_SERIAL].batchDeleteExchangeCodeRsp,
  (state) => state[GIFT_EXCHANGE_SERIAL].patchExchangeCodeRulesRsp,
  (
    switchExchangeCodeTypeRsp,
    batchDeleteExchangeCodeRsp,
    patchExchangeCodeRulesRsp
  ) => {
    const actions = [
      {
        isTrigger: switchExchangeCodeTypeRsp.code === 200,
        message: '變更序號設定成功',
      },
      {
        isTrigger: batchDeleteExchangeCodeRsp.code === 200,
        message: '批次刪除成功',
      },
    ]

    const activeAction = actions.find((action) => action.isTrigger)
    return {
      toastMsg: activeAction ? activeAction.message : '',
      rulesUpdatedTime: patchExchangeCodeRulesRsp.isFetch
        ? `序號規則已於 ${patchExchangeCodeRulesRsp.data.ruleConfirmedTimeString} 更新`
        : '',
    }
  }
)

export {
  csvDownloadSelector,
  exchangeCodeListSelector,
  messageStateSelector,
  exchangeRulesStateSelector,
}
export {
  getExchangeCodeListThunk,
  switchExchangeCodeTypeThunk,
  batchDeleteExchangeCodeThunk,
  exportExchangeCodeThunk,
  patchExchangeCodeRulesThunk,
}
export const { resetToastState, resetPatchExchangeCodeRulesRsp } =
  giftExchangeSerialSlice.actions
export default giftExchangeSerialSlice.reducer
