import {
  importVipExchangeCode,
  checkVipExchangeRule,
  getVipExchangeCodeList,
} from 'api/ApiMain'
import { getRandomId, removeToast } from 'helpers/common'
import { showUploadToast } from 'components/units/Toast'

const validate = ({ type, data, validData }) => {
  const validResult = { ...validData, valid: null }
  const rule = {
    prefix: /^[0-9A-Z]{4}$/,
    lengthLimit: /^.+$/,
    receive: /^.+$/,
  }

  if (!validData.required) return validResult

  switch (type) {
    case 'prefix':
      return {
        ...validResult,
        valid: new RegExp(rule[type]).test(data),
        msg: '請輸入 4 碼半形大寫英數',
      }

    case 'lengthLimit':
    case 'receive':
      return {
        ...validResult,
        valid: new RegExp(rule[type]).test(data),
        msg: '尚未填寫',
      }

    default:
      return validResult
  }
}

const settingValidResult =
  ({ data, resolve }) =>
  (state) => {
    const validState = JSON.parse(JSON.stringify(state))
    let isValid = true

    for (const [type] of Object.entries(validState)) {
      validState[type] = validate({
        type,
        data: data[type],
        validData: validState[type],
      })
      if (isValid && validState[type].valid === false) isValid = false
    }

    resolve(isValid)
    return validState
  }

const handleValid = ({ data, updateValidState }) =>
  new Promise((resolve) =>
    updateValidState(settingValidResult({ data, resolve }))
  )

const handleUploadCsv =
  ({
    brandId,
    giftSettingId,
    setUploadModal,
    setImportModal,
    setMask,
    setStateFromEdit,
    systemNumber,
    eventName,
    handleSyncUploadProgress,
  }) =>
  async (file) => {
    const bodyFormData = new FormData()
    bodyFormData.append('ImportFile', file)

    const toastMsg = {
      module: 'vipGift',
      title: `No.${systemNumber} ${eventName}`,
      content: file.name,
    }

    const uploadTimeoutHandler = setTimeout(async () => {
      try {
        showUploadToast(uploadingToastConfig)
        const res = await importVipExchangeCode(
          brandId,
          giftSettingId,
          bodyFormData
        )

        const giftSettingStatus = res?.data?.data?.vipGiftSettingStatus ?? null
        setStateFromEdit(giftSettingStatus)
        removeToast(uploadingToastConfig.toastId)
        setImportModal(true)
        showUploadToast(importingToastConfig)
        handleSyncUploadProgress()
      } catch (error) {
        setMask(false)
        removeToast(uploadingToastConfig.toastId)
      }
    }, 6000)

    const handleUploadCancel = () => {
      clearTimeout(uploadTimeoutHandler)
      setMask(false)
      showUploadToast(cancelToastConfig)
    }
    const preUploadToastConfig = {
      duration: 5,
      type: 'preUpload',
      message: toastMsg,
      onClose: handleUploadCancel,
      toastId: getRandomId(),
    }
    const uploadingToastConfig = {
      type: 'uploading',
      message: toastMsg,
      toastId: getRandomId(),
    }
    const cancelToastConfig = {
      type: 'cancel',
      message: toastMsg,
      toastId: getRandomId(),
    }
    const importingToastConfig = {
      type: 'importing',
      message: { ...toastMsg, progress: '0' },
      toastId: `vipGift-${systemNumber}`,
    }
    setUploadModal(false)
    showUploadToast(preUploadToastConfig)
  }

const composeVipExchangeRulePayload = (data) => ({
  giftCodeType: data.create,
  giftCodeGetType: data.receive,
  giftCodePrefix: data.prefix,
  giftCodeLength: data.lengthLimit || 0,
})

const composeVipExchangeListPayload = (data) => {
  const sortFieldConfig = {
    serialNumber: 'no',
    sendTime: 'exchangedDateTime',
    receiveChannel: 'receivePath',
    status: 'status',
  }
  return {
    searchType: data.condition,
    keyword: data.keyword,
    p: data.p,
    ps: data.ps,
    sf: sortFieldConfig[data.sf],
    so: data.so,
  }
}

const parserTableColumn = (data) => {
  return data.map((i) => ({
    ...i,
    serialNumber: i.no,
    giftNumber: i.giftCode,
    member: i.memberName,
    phone: i.memberCellPhone,
    sendTime: i.exchangedDateTime,
    receiveChannel: i.receivePath,
  }))
}

const handleList = async ({ brandId, giftSettingId, data }) => {
  try {
    const payload = composeVipExchangeListPayload(data)
    const res = await getVipExchangeCodeList(brandId, giftSettingId, payload)

    const tableRow = res.data?.data?.rows ?? []
    const conditionReceivedCount = res.data?.data?.conditionalReceivedCount ?? 0
    const conditionTotalCount = res.data?.data?.totalSize ?? 0
    const sendedCount = res.data?.data?.vipGift?.sendCount ?? 0
    const totalCount = res.data?.data?.vipGift?.giftCodeCount ?? 0

    return Promise.resolve({
      tableRow,
      conditionTotalCount,
      conditionReceivedCount,
      sendedCount,
      totalCount,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const handleRuleSetting = async ({ brandId, giftSettingId, data }) => {
  try {
    const paylaod = composeVipExchangeRulePayload(data)
    const res = await checkVipExchangeRule(brandId, giftSettingId, paylaod)
    return Promise.resolve({
      confirmTime: res.data?.data?.ruleConfirmedTimeString,
      stateFromEdit: res.data?.data?.vipGiftSettingStatus,
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const handleApiError = ({ error, updateFn }) => {
  const { errors } = error
  const config = {
    giftCodePrefix: [
      {
        type: '在同一品牌裡，序號不可重複',
        attr: 'prefix',
        msg: '在同一品牌裡，序號前綴不可重複',
      },
    ],
  }

  for (const type in errors) {
    for (const errorMsg of errors[type]) {
      if (
        config[type] === undefined ||
        config[type].findIndex((i) => i.type === errorMsg) === -1
      )
        break
      const { msg, attr } = config[type].find((i) => i.type === errorMsg)
      updateFn((prevState) => ({
        ...prevState,
        [attr]: {
          ...prevState[attr],
          valid: false,
          msg,
        },
      }))
    }
  }
}

export {
  handleValid,
  parserTableColumn,
  handleUploadCsv,
  handleList,
  handleRuleSetting,
  handleApiError,
}
