import styled, { css } from 'styled-components'
import FormCheck from 'react-bootstrap/FormCheck'
import { colorObj, method } from 'assets/styles/Variable/Color'

const { colorHandle } = method

// checkbox共幾種尺寸
const checkboxSize = {
  md: {
    fontSize: '15px',
    height: '24px',
  },
  sm: {
    fontSize: '13px',
    height: '20px',
  },
}
// checkbox的狀態
const checkboxState = {
  checked: {
    colorType: 'primary',
    color: colorObj.primary,
  },
  disabled: {
    colorType: 'dark',
    color: colorObj.dark,
  },
}

// Input size樣式處理
const handleSizeStyles = (size) => {
  size = size || ''
  let cssStyle = ''
  for (const type in checkboxSize) {
    const { height } = checkboxSize[type]
    if (size !== type) {
      cssStyle += `
          &.form-control-${size}{
              height:${height};
          }
      `
    }
  }
  return css`
    ${cssStyle}
  `
}

// Checkbox 基礎樣式
const checkboxBaseStyles = (props) => {
  let { size, colorType, colorAlpha } = props
  size = size || 'md'
  const svgStrokeColor = encodeURIComponent(checkboxState.checked.color)
  const svgStrokeDisabledColor = encodeURIComponent(
    checkboxState.disabled.color
  )
  return css`
    &.form-check {
      position: relative;
      line-height: ${checkboxSize[size].height};
      font-size: ${checkboxSize[size].fontSize};
      display: flex;
      align-items: center;

      &.form-check-inline {
        min-width: 100px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
      input[type='checkbox'] {
        margin-top: 0;
        width: 16px;
        height: 16px;
        opacity: 0;

        &:disabled {
          ~ .form-check-label {
            &::before {
              border-color: ${colorHandle(
                checkboxState.disabled.colorType,
                colorObj,
                0.5
              )};
            }
          }
        }
        ~ .form-check-label {
          color: ${colorType
            ? colorHandle(colorType, colorObj, colorAlpha) || colorObj.dark
            : 'inherit'};
          text-indent: 4px;
          white-space: nowrap;

          &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-color: #fff;
            position: absolute;
            border-width: 1px;
            border-style: solid;
            border-radius: 2px;
            background-repeat: no-repeat;
            background-size: 130%;
            background-position: center;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            border-color: ${colorObj.dark};
          }
        }

        &:checked {
          &:disabled {
            ~ .form-check-label {
              text-indent: 4px;

              &::before {
                background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='${svgStrokeDisabledColor}' stroke-opacity='0.5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
                border-color: ${colorHandle(
                  checkboxState.disabled.colorType,
                  colorObj,
                  0.5
                )};
              }
            }
          }
          ~ .form-check-label {
            text-indent: 4px;

            &::before {
              background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='${svgStrokeColor}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
              border-color: ${checkboxState.checked.color};
            }
          }
        }
      }
      .valid-feedback {
        text-indent: 4px;
      }
    }
    &.form-check-inline {
      min-width: 100px;
      display: inline-flex;
    }
    &.custom-switch {
      .custom-control-label {
        &::before {
          border-color: #adb5bd;
        }
      }
    }
    ${handleSizeStyles()};
  `
}

const StyleCheckbox = styled((props) => {
  let {
    colorType, // eslint-disable-line no-unused-vars
    colorAlpha, // eslint-disable-line no-unused-vars
    label,
    type,
    isInvalid,
    isValid,
    ...prop
  } = props
  type = ['checkbox', 'switch'].includes(type) ? type : 'checkbox'

  const labelElmenet = () => {
    return <>{label || ''}</>
  }

  return (
    <>
      <FormCheck
        {...prop}
        type={type}
        label={labelElmenet()}
        data-value-type={typeof prop.value}
      />
      {props?.feedback && isInvalid && (
        <div
          className="custom-invalid-feedback"
          data-testid="custom-invalid-feedback"
        >
          {props.feedback}
        </div>
      )}
      {props?.feedback && isValid && (
        <div
          className="custom-isvalid-feedback"
          data-testid="custom-isvalid-feedback"
        >
          {props.feedback}
        </div>
      )}
    </>
  )
})`
  ${(props) => checkboxBaseStyles(props)};
`

export { StyleCheckbox }
