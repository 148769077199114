import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import { Modal } from 'components/units'
import { MemberModalWrapper } from 'components/pages/Gift/ExchangeSendListStyle'
import { CaptionWrapper } from 'components/pages/Gift/ListExchangeGiftStyle'
import { number } from 'helpers/format'

const eventType = {
  line: 'LIFF',
  store: '線下門店',
  maac: '漸強活動',
  sms: '簡訊發送',
  delivery: '宅配出貨',
}

function Caption({ paginationProps, total = 0 }) {
  return (
    <CaptionWrapper>
      <div>
        <div className="caption-label">總項目：</div>
        <div>{`共 ${number(total)} 筆`}</div>
      </div>
      <div>
        <div className="caption-color mr-1">每頁</div>
        <SizePerPageDropdownStandalone
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
      </div>
    </CaptionWrapper>
  )
}

function DetailModal({
  show = false,
  data = {},
  onClose = () => {},
  onConfirm = () => {},
  isDefaultDataLoading = false,
}) {
  const isHasValidPeriod =
    data.type !== eventType.maac && data.type !== eventType.delivery
  const isHasGotTime =
    data.type === eventType.sms || data.type === eventType.line
  const isHasGotGiftWay = data.type === eventType.line
  const isHasGotGiftPlace =
    data.type === eventType.line || data.type === eventType.store
  const isHasGiftCode = data.type === eventType.line
  return (
    <Modal
      show={show}
      onClose={onClose}
      onConfirm={onConfirm}
      titleText="兌換詳情"
      customConfirm={<></>}
      closeBtnText="關閉"
      isDefaultDataLoading={isDefaultDataLoading}
    >
      <MemberModalWrapper>
        {/* TODO 根據兌換通路 顯示/隱藏 選項 */}
        <div className="member-info">
          <div className="member-label">訂單編號：</div>
          <div className="member-content">{data?.orderNumber}</div>
        </div>
        <div className="member-info">
          <div className="member-label">狀態：</div>
          <div className="member-content">{data?.statusDescription}</div>
        </div>
        <div className="member-info">
          <div className="member-label">兌換時間：</div>
          <div className="member-content">{data?.time}</div>
        </div>
        <div className="member-info">
          <div className="member-label">兌換通路：</div>
          <div className="member-content">{data?.type}</div>
        </div>
        <div className="member-info">
          <div className="member-label">兌換方式：</div>
          <div className="member-content">{data?.gotType}</div>
        </div>
        {isHasGotGiftWay && (
          <div className="member-info">
            <div className="member-label">領取方式：</div>
            <div className="member-content">{data?.giftType}</div>
          </div>
        )}
        {isHasValidPeriod && (
          <div className="member-info">
            <div className="member-label">領取效期：</div>
            <div className="member-content">{data?.gotGiftValidPeriod}</div>
          </div>
        )}
        {isHasGotTime && (
          <div className="member-info">
            <div className="member-label">領取時間：</div>
            <div className="member-content">{data?.gotTime}</div>
          </div>
        )}
        {isHasGotGiftPlace && (
          <div className="member-info">
            <div className="member-label">領取通路：</div>
            <div className="member-content">{data?.gotPlace}</div>
          </div>
        )}
        {isHasGiftCode && (
          <div className="member-info">
            <div className="member-label">贈品序號：</div>
            <div className="member-content">{data?.giftCode}</div>
          </div>
        )}
        <hr />
        <div className="member-info">
          <div className="member-label">會員編號：</div>
          <div className="member-content">{data?.customClientId}</div>
        </div>
        <div className="member-info">
          <div className="member-label">會員：</div>
          <div className="member-content">{data?.member}</div>
        </div>
        <div className="member-info">
          <div className="member-label">手機：</div>
          <div className="member-content">{data?.phone}</div>
        </div>
        <div className="member-info">
          <div className="member-label">會員等級：</div>
          <div className="member-content">{data?.level}</div>
        </div>
        <hr />
        <div className="member-info">
          <div className="member-label">贈品識別碼：</div>
          <div className="member-content">{data?.customId}</div>
        </div>
        <div className="member-info">
          <div className="member-label">贈品名稱：</div>
          <div className="member-content">{data?.giftName}</div>
        </div>
        <div className="member-info">
          <div className="member-label">顏色：</div>
          <div className="member-content">{data?.giftColor}</div>
        </div>
        <div className="member-info">
          <div className="member-label">尺寸：</div>
          <div className="member-content">{data?.giftSize}</div>
        </div>
        <div className="member-info">
          <div className="member-label">數量：</div>
          <div className="member-content">{data?.giftCount}</div>
        </div>
      </MemberModalWrapper>
    </Modal>
  )
}

export { Caption, DetailModal }
