import styled from 'styled-components'
import { ContentSection } from 'components/templates'

const StyledContentSection = styled(ContentSection)`
  .tab-content {
    padding: 24px;
  }
`

const StyledSideSection = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  > button {
    min-width: auto !important;
    &:first-child {
      margin-right: 1rem;
    }
  }
`
const TransparentMask = styled.div`
  > div {
    background-color: transparent;
  }
`

export { StyledContentSection, StyledSideSection, TransparentMask }
