const MEMBER_DASHBOARD = 'memberDashboard'
const AUTH = 'auth'
const ACCOUNT_SETTING = 'accountSetting'
const ACCOUNT_VERIFY = 'accountVerify'
const PASSWORD_SETTING = 'passwordSetting'
const ACCOUNT_MANAGEMENT = 'accountManagement'
const GLOBAL = 'global'
const SERIAL_BATCH_UPLOAD = 'serialBatchUpload'
const POINT_RECORD = 'pointRecord'
const DEAL_INFO = 'dealInfo'
const GIFT_EXCHANGE = 'giftExchange'
const GIFT_EXCHANGE_SERIAL = 'giftExchangeSerial'
const VIP_GIFT_EXCHANGE = 'vipGiftExchange'
const VIP_GIFT_EXCHANGE_SETTING = 'vipGiftExchangeSetting'
const VIP_GIFT_EXCHANGE_LIST = 'vipGiftExchangeList'
const GIFT_LIST = 'giftList'
const MEMBER_OUTLINE = 'memberOutline'
const QRCODE_SERIAL_NUMBER_DETAIL = 'qrcodeSerialNumberDetail'
const POINT_HISTORY = 'pointHistory'
const QRCODE_ACTIVITY_FORM_RECORD = 'qrcodeActivityFormRecord'
const DATA_ANALYSIS = 'dataAnalysis'
const POINT_SETTING = 'pointSetting'

export {
  MEMBER_DASHBOARD,
  AUTH,
  ACCOUNT_SETTING,
  PASSWORD_SETTING,
  ACCOUNT_VERIFY,
  ACCOUNT_MANAGEMENT,
  GLOBAL,
  SERIAL_BATCH_UPLOAD,
  POINT_RECORD,
  DEAL_INFO,
  GIFT_EXCHANGE,
  GIFT_EXCHANGE_SERIAL,
  VIP_GIFT_EXCHANGE,
  VIP_GIFT_EXCHANGE_SETTING,
  VIP_GIFT_EXCHANGE_LIST,
  GIFT_LIST,
  MEMBER_OUTLINE,
  QRCODE_SERIAL_NUMBER_DETAIL,
  POINT_HISTORY,
  QRCODE_ACTIVITY_FORM_RECORD,
  DATA_ANALYSIS,
  POINT_SETTING,
}
