import { useRef } from 'react'
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import { Col, Button, Tooltip, Input } from 'components/units'
import { InValidFeedback } from 'components/templates'
import { number } from 'helpers/format'
import { addSingleProductListItem } from 'api/ApiMain'
import { toast } from 'react-hot-toast'
import { captionLabel } from 'components/pages/ActivitiesCode/ProductActivity/Sub/ProductListConfig'
import {
  ToastWrapper,
  HeaderFormatterInfoWrapper,
  HeaderFormatterInfoHasContentWrapper,
  OrderWrapper,
  ColumnInputWrapper,
} from 'components/pages/ActivitiesCode/ProductActivity/Sub/ProductListStyle'
import { ReactComponent as TooltipSvg } from 'assets/images/icon_tooltip.svg'
import { ReactComponent as Delete } from 'assets/images/modal/icon_modalClose.svg'
import { color } from 'assets/styles/Variable/Color'

function Caption({ paginationProps, listTotal, isEdit, isSelected, ...props }) {
  const { batchRemove, handleUpload } = props

  return (
    <div className="caption">
      <Col lg="6" className="label-title">
        <span className="white-space-nowrap">{captionLabel.total}</span>
        {listTotal.pageTotal > 0 ? (
          <div className="caption-text">{number(listTotal.pageTotal)} 筆</div>
        ) : (
          <div className="caption-text">0 筆</div>
        )}
      </Col>
      <Col lg="6" className="label-title">
        <span className="white-space-nowrap mr-1">
          {captionLabel.sizeParPage}
        </span>
        <SizePerPageDropdownStandalone
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
        {isEdit && (
          <div className="button-wrap">
            <Button
              className="remove-btn"
              size="sm"
              disabled={!isSelected}
              variant={!isSelected ? 'primary' : 'outline-darkerGray'}
              onClick={(e, { handleButtonLocalLoading }) =>
                batchRemove(handleButtonLocalLoading)
              }
            >
              {captionLabel.delete}
            </Button>
            <Button
              className="remove-btn"
              size="sm"
              variant="outline-primary"
              onClick={handleUpload}
            >
              {captionLabel.multileUpload}
            </Button>
          </div>
        )}
      </Col>
    </div>
  )
}

function InfoCell({ cell, extraProps, ...props }) {
  const { disabled, placeholder } = props
  const { inputValids, setInputValues } = extraProps
  if (cell !== null) return cell

  const handleChange = (e) => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }
  return (
    <ColumnInputWrapper>
      <Input
        className="column-input"
        id="product-barcode"
        formControlOption={{
          type: 'text',
          onChange: handleChange,
          disabled,
          maxLength: 128,
          name: 'BarCode',
          placeholder,
        }}
      />
      <InValidFeedback show={!inputValids?.BarCode}>
        {inputValids?.BarCode}
      </InValidFeedback>
    </ColumnInputWrapper>
  )
}

function UploadBtn({ onChange }) {
  const inputRef = useRef(null)
  return (
    <>
      <Button variant={color.primary} onClick={() => inputRef.current.click()}>
        上傳檔案
      </Button>
      <Input
        onClick={(e) => {
          e.target.value = ''
        }} // 情境：按取消上傳，再次重複上傳相同檔案，重置動作，才能再次上傳該檔案
        formControlOption={{
          style: { display: 'none' },
          ref: inputRef,
          type: 'file',
          accept: '.csv',
          onChange: (e) => {
            const [file] = e.currentTarget.files
            if (typeof onChange === 'function') onChange(file)
          },
        }}
      />
    </>
  )
}

function ToastError({ handleClose }) {
  return (
    <ToastWrapper>
      <div className="toast-head">
        <h3 className="toast-title">贈品序號</h3>
        <Delete onClick={handleClose} />
      </div>
      <p className="toast-content">無法生成足量序號</p>
      <p className="toast-content">請調整前綴、增加總長後，再次生成</p>
    </ToastWrapper>
  )
}

const formatterBarCode = (cell, row, rowIndex, { columnsObj, extraProps }) => {
  return (
    <InfoCell cell={cell} extraProps={extraProps} {...columnsObj.barCode} />
  )
}

const headerFormatterInfo = function renderInfo(cell, no, info) {
  const ascSTy =
    info.sortElement.props.order === 'asc' ? 'caret order-highlight' : 'caret'
  const descSTy =
    info.sortElement.props.order === 'desc' ? 'caret order-highlight' : 'caret'

  return (
    <HeaderFormatterInfoHasContentWrapper>
      商品識別碼
      <Tooltip
        triggerElement={<TooltipSvg />}
        globalOption={{ effect: 'solid' }}
      >
        線上網站，可用商品貨號識別
        <br />
        線下門店，可用商品條碼識別
      </Tooltip>
      <OrderWrapper>
        <span className="order">
          {!info.sortElement.props.order ||
          info.sortElement.props.order === 'asc' ? (
            <span className="dropup">
              <span className={ascSTy} />
            </span>
          ) : null}
          {!info.sortElement.props.order ||
          info.sortElement.props.order === 'desc' ? (
            <span className="dropdown">
              <span className={descSTy} />
            </span>
          ) : null}
        </span>
      </OrderWrapper>
    </HeaderFormatterInfoHasContentWrapper>
  )
}

const headerFormatterStatus = () => <HeaderFormatterInfoWrapper />
const genFormData = (data) => {
  const bodyFormData = new FormData()
  bodyFormData.append('BarCode', data.BarCode ?? '')
  bodyFormData.append('Name', data.Name ?? '')
  bodyFormData.append('Category', data.Category ?? '')
  return bodyFormData
}

const resetInputValues = (setInputValues) => {
  setInputValues({
    Barcode: '',
    Name: '',
  })
}
const resetInputValids = (setInputValids) => {
  setInputValids()
}

const handleSubmitNewProductItem = async (
  info,
  handleButtonLocalLoading,
  extraProps
) => {
  const { inputValues, setInputValues, setInputValids } = extraProps
  if (inputValues?.BarCode || inputValues?.Name || inputValues?.Category) {
    const bodyFromData = genFormData({
      BarCode: inputValues?.BarCode,
      Name: inputValues?.Name,
      Category: inputValues?.Category,
    })

    try {
      handleButtonLocalLoading(true)
      const rsp = await addSingleProductListItem(
        info.barCode.brandId,
        info.barCode.pointProductRuleId,
        bodyFromData
      )
      if (rsp?.data?.data?.success) {
        info.barCode.handleSearch()
        resetInputValues(setInputValues)
        resetInputValids(setInputValids)
      } else {
        const { field: errorField, message: errorMsg } =
          rsp?.data?.data?.failureMessage
        if (errorField) {
          setInputValids((prevState) => {
            return {
              ...prevState,
              [errorField]: errorMsg,
            }
          })
        }
      }
    } catch ({ errors }) {
      Object.entries(errors).forEach(([field, message]) =>
        toast.error(`${field.toUpperCase()}${message}`, { duration: 4000 })
      )
    } finally {
      handleButtonLocalLoading(false)
    }
  }
}

const formatterStatus = (
  cell,
  item,
  rowIndex,
  { columnsObj: info, extraProps }
) => {
  const { inputValues } = extraProps
  const isBtnDisabled =
    !inputValues?.BarCode && !inputValues?.Name && !inputValues?.Category
  if (item.no !== null || !info?.barCode?.isEdit) return ''

  return (
    <Button
      className="confirm-btn"
      size="sm"
      id="submit-new-product-item"
      variant={isBtnDisabled ? 'primary' : 'outline-primary'}
      disabled={isBtnDisabled}
      onClick={(e, { handleButtonLocalLoading }) =>
        handleSubmitNewProductItem(info, handleButtonLocalLoading, extraProps)
      }
    >
      {captionLabel.submitNewProductItem}
    </Button>
  )
}

function NameCell({ cell, extraProps, ...props }) {
  const { disabled, placeholder } = props
  const { setInputValues } = extraProps

  if (cell || cell === '') return cell

  const handleChange = (e) => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  return (
    <ColumnInputWrapper>
      <Input
        className="column-input"
        id="product-name"
        formControlOption={{
          type: 'text',
          onChange: handleChange,
          disabled,
          maxLength: 128,
          name: 'Name',
          placeholder,
        }}
      />
    </ColumnInputWrapper>
  )
}
function CategoryCell({ cell, extraProps, ...props }) {
  const { disabled, placeholder } = props
  const { setInputValues } = extraProps

  if (cell || cell === '') return cell

  const handleChange = (e) => {
    setInputValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  return (
    <ColumnInputWrapper>
      <Input
        className="column-input"
        id="product-category"
        formControlOption={{
          type: 'text',
          onChange: handleChange,
          disabled,
          maxLength: 128,
          name: 'Category',
          placeholder,
        }}
      />
    </ColumnInputWrapper>
  )
}
const formatterName = (cell, row, rowIndex, { columnsObj, extraProps }) => {
  return <NameCell cell={cell} extraProps={extraProps} {...columnsObj.name} />
}
const formatterCategory = (cell, row, rowIndex, { columnsObj, extraProps }) => {
  return (
    <CategoryCell
      cell={cell}
      extraProps={extraProps}
      {...columnsObj.category}
    />
  )
}

export {
  Caption,
  UploadBtn,
  ToastError,
  formatterBarCode,
  formatterName,
  formatterCategory,
  headerFormatterInfo,
  headerFormatterStatus,
  formatterStatus,
}
