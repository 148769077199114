import { fetchData } from 'api/ApiRoute'
import { commonFetchProps, parserUrl, showToast } from 'api/ApiCommon'

const pointActivityUrl = {
  list: 'setting/brand/{brandId}/point/barcoderule',
  setting: 'setting/brand/{brandId}/point/barcoderule/setting',
  submit: 'setting/brand/{brandId}/point/barcoderule',
  stop: 'setting/brand/{brandId}/point/barcoderule/{pointBarcodeRuleId}/stop',
  delete: 'setting/brand/{brandId}/point/barcoderule/{pointBarcodeRuleId}',
  editInfo: 'setting/brand/{brandId}/point/barcoderule/{pointBarcodeRuleId}',
  edit: 'setting/brand/{brandId}/point/barcoderule/{pointBarcodeRuleId}',
}

const handleError400 = (rsp) => {
  if (rsp.status === 400 && rsp?.data?.msg) {
    showToast(rsp)
  }
  return Promise.reject(rsp.data ?? rsp)
}

/**
 * @description 獲取對應品牌的 「點數活動」 列表
 * @param brandId
 * @param payload
 * @returns {PromipaginationPropsse<any>}
 */
function getPointActivityListApi(brandId, payload) {
  return fetchData(
    parserUrl(pointActivityUrl.list, { brandId }, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

/**
 * @description 獲取點數贈送 select options
 * @param brandId
 * @returns {Promise<any>}
 */
function getPointActivitySettingApi(brandId) {
  return fetchData(
    parserUrl(pointActivityUrl.setting, { brandId }),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

/**
 * @description 新增點數活動
 * @param brandId
 * @param body
 * @returns {Promise<any>}
 */
function addBarcodeRuleApi(brandId, body) {
  return fetchData(
    parserUrl(pointActivityUrl.submit, { brandId }),
    'POST',
    body,
    commonFetchProps
  ).catch(handleError400)
}

/**
 * @description 停用點數活動
 * @param brandId
 * @param pointBarcodeRuleId
 * @returns {Promise<any>}
 */
function stopPointActivityApi(brandId, pointBarcodeRuleId) {
  return fetchData(
    parserUrl(pointActivityUrl.stop, { brandId, pointBarcodeRuleId }),
    'PATCH',
    null,
    commonFetchProps
  ).catch(handleError400)
}

/**
 * @description 刪除點數活動
 * @param brandId
 * @param pointBarcodeRuleId
 * @returns {Promise<any>}
 */
function deletePointActivityApi(brandId, pointBarcodeRuleId) {
  return fetchData(
    parserUrl(pointActivityUrl.delete, { brandId, pointBarcodeRuleId }),
    'DELETE',
    null,
    commonFetchProps
  ).catch(handleError400)
}

/**
 * @description 取得編輯活動資訊
 * @param brandId
 * @param pointBarcodeRuleId
 * @returns {Promise<any>}
 */
function getEditPointActivityApi(brandId, pointBarcodeRuleId) {
  return fetchData(
    parserUrl(pointActivityUrl.editInfo, { brandId, pointBarcodeRuleId }),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

/**
 * @description 編輯點數活動
 * @param brandId
 * @param bodyData
 * @returns {Promise<any>}
 */
function editPointActivityApi(brandId, pointProductRuleId, bodyData) {
  return fetchData(
    `setting/brand/${brandId}/point/barcoderule/${pointProductRuleId}`,
    'PATCH',
    bodyData
  ).catch(handleError400)
}

export {
  getPointActivityListApi,
  getPointActivitySettingApi,
  addBarcodeRuleApi,
  stopPointActivityApi,
  deletePointActivityApi,
  getEditPointActivityApi,
  editPointActivityApi,
}
