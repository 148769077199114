import PropTypes from 'prop-types'
import { Col, Form, Row } from 'react-bootstrap'
import { color } from 'assets/styles/Variable/Color'
import { InValidFeedback } from 'components/templates'
import { Input, Button } from 'components/units'

// TODO: [重構] TMP 因應 cycle import 先將 config 獨立出來
const formConfig = {
  total: {
    label: '生成組數：',
    placeholder: '單次不可高於 1 萬組',
    button: '生成序號',
  },
}

function SystemGenerateCount({
  valid,
  setting,
  isFieldsDisabled,
  isGenerateDisabled,
  onSettingChange,
  onGenerate,
}) {
  return (
    <Form.Group className="form-group-row-nowarp mb-0">
      <Form.Label>{formConfig.total.label}</Form.Label>
      <Col lg="6">
        <fieldset disabled={isFieldsDisabled}>
          <Row>
            <Input
              className="form-row-input-fill"
              onChange={onSettingChange('total')}
              formControlOption={{
                defaultValue: setting.total,
                placeholder: formConfig.total.placeholder,
              }}
            />
            <Button
              className="buttom-custom"
              size="md"
              variant={
                isFieldsDisabled || isGenerateDisabled ? color.gray : 'primary'
              }
              disabled={isGenerateDisabled}
              onClick={onGenerate}
            >
              {formConfig.total.button}
            </Button>
          </Row>
          <Row>
            <InValidFeedback show={valid.total.valid}>
              {valid.total.msg}
            </InValidFeedback>
          </Row>
        </fieldset>
      </Col>
    </Form.Group>
  )
}

export default SystemGenerateCount

SystemGenerateCount.propTypes = {
  valid: PropTypes.shape({
    total: PropTypes.shape({
      valid: PropTypes.bool,
      msg: PropTypes.string,
    }),
  }).isRequired,
  setting: PropTypes.shape({
    total: PropTypes.number,
    uploadCount: PropTypes.number,
  }).isRequired,
  isFieldsDisabled: PropTypes.bool.isRequired,
  isGenerateDisabled: PropTypes.bool.isRequired,
  onSettingChange: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
}
