import {
  formatterMember,
  headerFormatterStatus,
  formatterStatus,
} from 'components/pages/Gift/ExclusiveSendListSubComponents'

const statusType = {
  sended: '已發送',
  notExchanged: '未兌換',
  received: '已領取',
}

const captionText = {
  phone: '手機：',
  search: '查詢',
  clear: '清除條件',
  totalLabel: '總項目：',
  total: (number) => `共 ${number} 筆`,
  page: '每頁',
}

const tableText = {
  no: 'No.',
  member: '會員',
  phone: '手機',
  senddatetime: '發送時間',
  status: '狀態',
  statusTip1: '依回傳資料',
  statusTip2: '更新內容與狀態',
  empty: '系統將於判斷會員符合資格時，自動發送好禮',
  loading: '載入中...',
}

const toastText = {
  searchEmpty: '查無資料，請調整搜尋內容後重新查詢',
}

const defaultSort = {
  sf: 'no',
  so: 'desc',
}

const initPaylaod = {
  cellPhone: '',
  p: 1,
  ps: 25,
  sf: defaultSort.sf,
  so: defaultSort.so,
}

const columns = (columnsObj = {}) => [
  { dataField: 'no', text: tableText.no, sort: true },
  {
    dataField: 'memberName',
    text: tableText.member,
    formatter: formatterMember(columnsObj),
  },
  { dataField: 'memberCellPhone', text: tableText.phone },
  { dataField: 'sendDateTime', text: tableText.senddatetime, sort: true },
  {
    dataField: 'status',
    text: '',
    headerFormatter: headerFormatterStatus,
    formatter: formatterStatus,
    sort: true,
    headerClasses: 'setting-no-status',
  },
]

export { statusType, captionText, tableText, toastText, initPaylaod, columns }
