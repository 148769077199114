import styled from 'styled-components'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const LocalStyle = styled.div`
  .base-setting-collapse-container {
    flex: 1;
    margin-left: 24px;
    color: rgba(51, 51, 51, 0.66);
  }
  .base-setting-collapse-item {
    margin-bottom: ${spacingObj.mini};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .setting-img-container {
    > span {
      position: relative;
      top: -5px;

      &:before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`

export default LocalStyle
