import { createContext, useEffect, useRef, useState } from 'react'

const RootContext = createContext()

function RootContextProvider({ value: initValue, children }) {
  const [value, updateRootValue] = useState(initValue)
  const prevValueRef = useRef()
  const isResetRef = useRef(false)

  const resetRootValue = () => {
    prevValueRef.current = undefined
    isResetRef.current = true
    updateRootValue({
      prevRootData: prevValueRef.current,
    })
  }

  useEffect(() => {
    if (isResetRef.current) {
      isResetRef.current = false
    }
    if (!isResetRef.current) {
      prevValueRef.current = value
    }
  }, [value])

  return (
    <RootContext.Provider
      value={{
        ...value,
        prevRootData: prevValueRef.current,
        updateRootValue,
        resetRootValue,
      }}
    >
      {children}
    </RootContext.Provider>
  )
}

export { RootContext }
export default RootContextProvider
