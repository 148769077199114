import styled from 'styled-components'

const LocalStyle = styled.div`
  & {
    position: fixed;
    z-index: 250;
    top: 0;
    width: 100%;
  }
  .app-header-navbar {
    width: 100%;
    height: 48px;
    background-color: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px 0px rgba(51, 51, 51, 0.25);
    top: 0;
    left: 0;
  }
  .navbar-brand {
    width: 200px;
    text-align: center;

    .navbar-logo {
      width: 95px;
    }
  }
  .navbar-nav {
    .dropdown {
      top: 50%;
      transform: translateY(-50%);
      .dropdown-toggle {
        font-size: 14px;

        &,
        &:focus,
        &::after {
          color: #646464;
        }
      }
    }
  }
`

export default LocalStyle
