import styled from 'styled-components'
import { ReactComponent as IconCancel } from 'assets/images/button/icon_cancel.svg'
import { ReactComponent as IconDelete } from 'assets/images/button/icon_delete.svg'
import { ReactComponent as IconEdit } from 'assets/images/button/icon_edit.svg'
import { ReactComponent as IconLoad } from 'assets/images/button/icon_load.svg'
import { ReactComponent as IconSeal } from 'assets/images/button/icon_seal.svg'
import { ReactComponent as IconSave } from 'assets/images/button/icon_save.svg'
import { ReactComponent as IconView } from 'assets/images/button/icon_view.svg'
import { ReactComponent as IconSetting } from 'assets/images/button/icon_gear.svg'
import { ReactComponent as IconCopy } from 'assets/images/button/icon_copy.svg'
import { ReactComponent as IconStop } from 'assets/images/page/icon_suspend.svg'
import { ReactComponent as IconLink } from 'assets/images/button/icon_link.svg'
import { ReactComponent as IconTableLink } from 'assets/images/table/icon_table_link.svg'
import { ReactComponent as IconTableDelete } from 'assets/images/table/icon_table_delete.svg'
import { ReactComponent as IconTableQrcode } from 'assets/images/table/icon_table_qrcode.svg'
import { ReactComponent as IconTableActive } from 'assets/images/table/icon_table_active.svg'
import { ReactComponent as IconTableExtend } from 'assets/images/table/icon_table_extend.svg'
import { getRandomId } from 'helpers/common'
import { Tooltip } from 'components/units'
import PlainButton from 'components/units/PlainButton'
import { memo } from 'react'

const IconDownload = styled(IconLoad)`
  transform: rotate(180deg);
`

const list = {
  cancel: <IconCancel />,
  copy: <IconCopy />,
  delete: <IconDelete />,
  edit: <IconEdit />,
  upload: <IconLoad />,
  download: <IconDownload />,
  save: <IconSave />,
  seal: <IconSeal />,
  setting: <IconSetting />,
  view: <IconView />,
  stop: <IconStop />,
  link: <IconLink />,
  tableLink: <IconTableLink />,
  tableDelete: <IconTableDelete />,
  tableQrcode: <IconTableQrcode />,
  tableActive: <IconTableActive />,
  tableExtend: <IconTableExtend />,
  activate: <IconTableActive />,
}

const tooltipList = {
  cancel: '取消',
  copy: '複製',
  delete: '刪除',
  edit: '編輯',
  upload: '上傳',
  download: '下載',
  save: '儲存',
  seal: '封存',
  setting: '設定',
  view: '檢視',
  stop: '停用',
  link: '連結',
  tableLink: '連結',
  tableDelete: '刪除',
  tableQrcode: 'QR Code',
  tableActive: '啟用',
  tableExtend: '延長',
  activate: '啟用',
}

const IconButton = memo(function ({
  id = getRandomId(),
  tooltip = '',
  type = '',
  className = '',
  ...props
}) {
  const icon = list[type]
  const tooltipText = tooltip || tooltipList[type] || ''

  return (
    <PlainButton className={className} {...props}>
      <Tooltip id={id} triggerElement={icon}>
        {tooltipText}
      </Tooltip>
    </PlainButton>
  )
})

export default IconButton
