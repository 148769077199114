import { useState, useContext, useMemo, useEffect } from 'react'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { useHistory, useLocation } from 'react-router-dom'
import withPermission from 'components/hocs/withPermission'
import { ExchangePage } from 'components/pages/Gift'
import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb, Button } from 'components/units'
import { COMPONENT_PERMISSIONS, PAGE_PATHS } from 'constant'
import { ExchangeContext } from 'context/pageContext'
import {
  paginationOptions,
  settingPageType,
  tabStatus,
} from 'pages/Gift/config'
import { StyledSideSetion } from 'pages/Gift/ExchangeStyle'
import { RootContext } from 'RootContext'

const defaultSort = {
  so: 'desc',
  sf: 'updatedTime',
}

function NavSection() {
  const BreadcrumbConfig = {
    now: '贈品兌換',
    pathList: [{ title: '好禮贈送', slash: true }],
  }
  return (
    <Breadcrumb
      now={BreadcrumbConfig.now}
      pathList={BreadcrumbConfig.pathList}
    />
  )
}

function SideSection() {
  const history = useHistory()
  const { apiPayload, event, addBtdDisabled } = useContext(ExchangeContext)
  const rootData = useContext(RootContext)
  const { brandId } = rootData
  const payload = {
    ...apiPayload,
    event,
    brandId,
  }
  const PermissionAddGiftButton = withPermission(
    Button,
    COMPONENT_PERMISSIONS.gift.CREATE
  )
  return (
    <StyledSideSetion>
      <PermissionAddGiftButton
        variant="outline-primary"
        disabled={addBtdDisabled}
        onClick={() =>
          history.push(PAGE_PATHS.gift.exchangeSetting, {
            type: settingPageType.new,
            apiPayload: payload,
          })
        }
      >
        新增贈品
      </PermissionAddGiftButton>
    </StyledSideSetion>
  )
}

function Exchange() {
  const { state } = useLocation()
  const [addBtdDisabled, setAddBtdDisabled] = useState(false)
  const [event, setEvent] = useState({
    usingMaacActivity: false,
    usingOfflineShop: false,
    usingLineLiff: false,
  })
  const [totalSize, setTotalSize] = useState(0)
  const [apiPayload, setApiPayload] = useState({
    state: state?.apiPayload?.state ?? tabStatus.available,
    ps: 25,
    p: 1,
    ...defaultSort,
  })

  const paginationFn = {
    onPageChange: (page) => setApiPayload((prev) => ({ ...prev, p: page })),
    onSizePerPageChange: (sizePerPage, page) =>
      setApiPayload((prev) => ({ ...prev, ps: sizePerPage, p: page || 1 })),
  }
  const exchangeContextValue = useMemo(() => {
    return {
      apiPayload,
      setApiPayload,
      setTotalSize,
      defaultSort,
      event,
      setEvent,
      addBtdDisabled,
      setAddBtdDisabled,
    }
  }, [
    apiPayload,
    setTotalSize,
    event,
    setEvent,
    addBtdDisabled,
    setAddBtdDisabled,
  ])

  useEffect(() => {
    if (state?.apiPayload?.state !== undefined) {
      setApiPayload((prev) => ({ ...prev, state: state.apiPayload.state }))
    }
  }, [state])

  return (
    <ExchangeContext.Provider value={exchangeContextValue}>
      <Basic navSection={NavSection} sideSection={SideSection}>
        <ContentSection>
          <PaginationProvider
            pagination={paginationFactory({
              ...paginationOptions,
              ...paginationFn,
              totalSize,
              page: apiPayload.p,
              sizePerPage: apiPayload.ps,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ExchangePage.ExchangeMain
                paginationProps={paginationProps}
                paginationTableProps={paginationTableProps}
              />
            )}
          </PaginationProvider>
        </ContentSection>
      </Basic>
    </ExchangeContext.Provider>
  )
}

export default Exchange
