import { useEffect, useContext, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom'
import AccountForm from 'components/pages/SystemConfiguration/account-management-content/widgets/AccountForm'
import WarnModal from 'components/pages/SystemConfiguration/account-management-content/widgets/WarnModal'
import RolePermissionModal from 'components/pages/SystemConfiguration/AccountManagement/widgets/RolePermissionModal'
import { PAGE_PATHS } from 'constant'
import { PAGE_TYPE } from 'constant/common'
import { RootContext } from 'RootContext'
import {
  getAccountThunk,
  getShopsThunk,
  getRolesThunk,
  postAccountThunk,
  getEmailValidationThunk,
  patchAccountThunk,
} from 'store/account-setting/accountSettingAsync'
import {
  resetAccountData,
  resetSelectedShops,
  updateSelectedShops,
  openWarnModal,
  closeWarnModal,
  openPermissionModal,
  closePermissionModal,
  resetEamilValidation,
  shopsSelector,
  rolesSelector,
  selectedShopsSelector,
  modalStatusSelector,
  accountCheckSelector,
  accountDataSelector,
} from 'store/account-setting/accountSettingSlice'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'

function AccountSetting() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const currentPageType = params.pageType || 'new'
  const accountId = params.accountId || null
  const { brandId } = useContext(RootContext)
  const brandIdRef = useRef()
  const shops = useSelector(shopsSelector)
  const roles = useSelector(rolesSelector)
  const selectedShops = useSelector(selectedShopsSelector)
  const optionData = { shops, roles, selectedShops }
  const { isWarnModalOpen, isPermissionModalOpen } =
    useSelector(modalStatusSelector)
  const { isDataLoading } = useSelector(getGlobalState)
  const accountCheck = useSelector(accountCheckSelector)
  const accountData = useSelector(accountDataSelector)
  const [requestController, setRequestController] = useState({
    controller: null,
  })

  const handleLoading = useCallback(
    (isOpen) => {
      dispatch(updateDataLoading(isOpen))
    },
    [dispatch]
  )

  const handleSubmit = async (formData) => {
    const submitAction = {
      [PAGE_TYPE.new]: postAccountThunk,
      [PAGE_TYPE.edit]: patchAccountThunk,
    }
    handleLoading(true)
    try {
      await dispatch(
        submitAction[currentPageType]({
          brandId,
          accountId,
          payload: formData,
        })
      )
      history.push(PAGE_PATHS.systemConfiguration.accountManagement)
    } finally {
      handleLoading(false)
    }
  }

  const handleSelectShop = ({ shopCode }) => {
    dispatch(updateSelectedShops({ shopCode }))
  }

  const handleResetEmailValidation = () => {
    dispatch(resetEamilValidation())
  }

  const handleOpenWarnModal = () => {
    dispatch(openWarnModal())
  }

  const handleCloseWarnModal = () => {
    dispatch(closeWarnModal())
    handleResetEmailValidation()
  }

  const handlOpenPermissionModal = () => {
    dispatch(openPermissionModal())
  }

  const handlClosePermissionModal = () => {
    dispatch(closePermissionModal())
  }

  const handleEmailValidation = (email) => {
    const controller = new AbortController()
    setRequestController({ controller })
    const { signal } = controller
    dispatch(getEmailValidationThunk({ brandId, email, signal }))
  }

  const handleStopRequest = useCallback(() => {
    requestController.controller?.abort()
  }, [requestController])

  useEffect(() => {
    const getOptionData = async () => {
      await dispatch(getRolesThunk(brandId))
      await dispatch(getShopsThunk(brandId))
      handleLoading(false)
    }

    const getAccountData = async () => {
      await dispatch(
        getAccountThunk({
          brandId,
          accountId,
        })
      )
      handleLoading(false)
    }

    if (brandId && brandIdRef.current !== brandId) {
      handleLoading(true)
      brandIdRef.current = brandId
      dispatch(resetSelectedShops())
      dispatch(resetEamilValidation())
      dispatch(resetAccountData())
      getOptionData()
      if (currentPageType === PAGE_TYPE.edit && accountId) {
        getAccountData()
      }
    }

    return () => handleStopRequest()
  }, [
    accountId,
    brandId,
    currentPageType,
    dispatch,
    handleLoading,
    handleStopRequest,
  ])

  return (
    <>
      <AccountForm
        onSubmit={handleSubmit}
        onSelectShop={handleSelectShop}
        onOpenWarnModal={handleOpenWarnModal}
        onOpenPermissionModal={handlOpenPermissionModal}
        onEmailValidation={handleEmailValidation}
        onResetEmailValidation={handleResetEmailValidation}
        optionData={optionData}
        accountData={accountData.data}
        isEditDisabled={accountData.isFetch}
        pageType={currentPageType}
        isLoading={isDataLoading}
        emailValidation={accountCheck}
      />
      <WarnModal onClose={handleCloseWarnModal} isShow={isWarnModalOpen} />
      <RolePermissionModal
        show={isPermissionModalOpen}
        onClose={handlClosePermissionModal}
      />
    </>
  )
}

export default AccountSetting
