import { createSlice } from '@reduxjs/toolkit'

// Init Config
export const globalInitState = {
  isAppLoading: false, // 登入時的全畫面 loading
  isPageLoading: false, // sidemenu 切換頁面時的 loading
  isDataLoading: false, // 取資料時的 loading
  isListLoading: false, // 取列表資料時的 loading
  isMaskOpen: false, // toast 使用的灰色遮罩
  toastMsg: null,
}

// Slice
const globalSlice = createSlice({
  name: 'global',
  initialState: globalInitState,
  reducers: {
    updateAppLoading: (state, action) => {
      state.isAppLoading = action.payload
    },
    updatePageLoading: (state, action) => {
      state.isPageLoading = action.payload
    },
    updateDataLoading: (state, action) => {
      state.isDataLoading = action.payload
    },
    updateListLoading: (state, action) => {
      state.isListLoading = action.payload
    },
    updateToast: (state, action) => {
      state.toastMsg = action.payload
    },
    clearToast: (state) => {
      state.toastMsg = null
    },
    updateMaskOpen: (state, action) => {
      state.isMaskOpen = action.payload
    },
  },
})

// Selector
export const getGlobalState = (state) => state.global

export const {
  updateAppLoading,
  updatePageLoading,
  updateDataLoading,
  updateListLoading,
  updateToast,
  clearToast,
  updateMaskOpen,
} = globalSlice.actions
export default globalSlice.reducer
