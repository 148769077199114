import { formatISO } from 'date-fns'
import { postImportProductItems } from 'api/ApiMain'
import { downloadCsv, getRandomId } from 'helpers/common'
import { handleCloseToast, showUploadToast } from 'components/units/Toast'
import ToastContent from 'components/units/ToastContent'
import ToastFooter from 'components/units/ToastFooter'
import { UPLOAD_STAGE } from 'constant/toast'

const handleUploadCsv =
  ({ brandId, setUploadModal, setMask, search }) =>
  async (file) => {
    const bodyFormData = new FormData()
    bodyFormData.append('BrandId', brandId)
    bodyFormData.append('ImportFile', file)
    const postponeTime = 5
    const toastId = getRandomId()

    const toastMsg = {
      module: 'giftList',
      content: file.name,
    }

    const preUploadToastConfig = {
      duration: postponeTime,
      type: UPLOAD_STAGE.PREPARE,
      message: toastMsg,
      toastId,
    }

    const uploadingToastConfig = {
      type: UPLOAD_STAGE.PROCESS,
      message: toastMsg,
      toastId,
    }

    const cancelToastConfig = {
      type: UPLOAD_STAGE.CANCEL,
      message: toastMsg,
      toastId: getRandomId(), // 因目前流程設計，cancel toastId 要特別獨立，才能正確開啟
    }

    const uploadTimeoutHandler = setTimeout(async () => {
      try {
        showUploadToast(uploadingToastConfig)
        const rsp = await postImportProductItems(bodyFormData)
        const { successAmount, failureAmount, failureFileUrl } = rsp?.data?.data
        const completedToastConfig = {
          type: UPLOAD_STAGE.COMPLETED,
          message: {
            ...toastMsg,
            title: '',
            content: (
              <ToastContent
                successCount={successAmount}
                failCount={failureAmount}
              />
            ),
          },
          footer: <ToastFooter reportLink={failureFileUrl} />,
          toastId,
        }
        showUploadToast(completedToastConfig)
      } catch (e) {
        console.error('batchUplaod error:', e)
        handleCloseToast(toastId)
      } finally {
        setMask(false)
        search()
      }
    }, postponeTime * 1000 + 100)
    setMask(true)
    setUploadModal(false)
    showUploadToast({
      ...preUploadToastConfig,
      onClose: () => {
        clearTimeout(uploadTimeoutHandler)
        setMask(false)
        showUploadToast(cancelToastConfig)
      },
    })
  }
const parserTableData = (data) => {
  return data.map((i, key) => {
    return {
      key,
      ...i,
    }
  })
}
const formatTimeISO = (time) => {
  if (time === '') return time
  return formatISO(time, { representation: 'date' })
}

export { handleUploadCsv, downloadCsv, parserTableData, formatTimeISO }
