import PropTypes from 'prop-types'
import withPermission from 'components/hocs/withPermission'
import SidebarDropdown from './SidebarDropdown'

function SidebarDropdownItem({ navItem }) {
  const SidebarDropdownPermission = withPermission(
    SidebarDropdown,
    navItem.permissionKeys
  )

  return (
    <SidebarDropdownPermission
      navItem={navItem}
      key={`submenu-${navItem.id}`}
    />
  )
}

SidebarDropdownItem.propTypes = {
  navItem: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.element,
    iconOpened: PropTypes.element,
    iconClosed: PropTypes.element,
    permissionKeys: PropTypes.arrayOf(PropTypes.string),
    subNav: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        path: PropTypes.string,
        permissionKeys: PropTypes.arrayOf(PropTypes.string),
        isOnSubcomponent: PropTypes.string,
      })
    ),
  }),
}

SidebarDropdownItem.defaultProps = {
  navItem: {
    id: '',
    title: '',
    path: '',
    icon: null,
    iconOpened: null,
    iconClosed: null,
    permissionKeys: [],
    subNav: [],
  },
}

export default SidebarDropdownItem
