// 好禮贈送 -> 贈品兌換 -> 編輯贈品 -> 贈品內容
import { useContext, useEffect, useRef } from 'react'
import { toast } from 'react-hot-toast'
import { Form, InputGroup } from 'react-bootstrap'
import startOfToday from 'date-fns/startOfToday'
import {
  Col,
  Input,
  Editor,
  Calendar,
  ImageUploader,
  Row,
  Tooltip,
  Radio,
  Checkbox,
} from 'components/units'
import {
  FormWrapper,
  CalendarWrapper,
  ColumnListWrapper,
  CheckListMainWrapper,
  CheckListSubWrapper,
  ComplexListWrapper,
  ComplexItem,
  InValidFeedback,
  LvZeroEl,
} from 'components/templates'
import { toastErrorText, debounce } from 'helpers/common'
import {
  modalType,
  SettingContext,
  tabStatus as tabType,
} from 'pages/Gift/ExchangeSetting'
import {
  formConfig,
  stateUpdate,
  parserPayload,
  exchangePathway,
  collectionMethod,
  imageLimitConfig,
} from 'components/pages/Gift/ExchangeContentConfig'
import { settingPageType, tabStatus } from 'pages/Gift/config'
import iconTooltip from 'assets/images/icon_tooltip.svg'

const handleSizeError = () => {
  toast.error((t) =>
    toastErrorText(
      `圖檔大小超過 ${imageLimitConfig.size / 1024}MB，請重新選擇`,
      t.id
    )
  )
}

const handleImageError = (e) => {
  switch (e) {
    case 'size':
      handleSizeError()
      break
    default:
      return console.warn('onError fail')
  }
}

const handleSubmit = (event) => {
  event.preventDefault()
  event.stopPropagation()
}

function SettingContent() {
  const {
    defaultData,
    viewType,
    infoState: [state, dispatch],
    infoValid: [validStatus],
    history,
    settingId,
    stateFromEdit: contextStateFromEdit,
    pageType: contextPageType,
    handleUniteModalSwitch,
  } = useContext(SettingContext)

  const [stateFromEdit] = contextStateFromEdit
  const pageType = contextPageType
  const {
    usingMaacActivity,
    usingOfflineShop,
    usingLineLiff,
    usingSendBySms,
    usingDelivery,
  } = history.location.state?.apiPayload?.event
  const isNew = viewType === settingPageType.new
  const isView = viewType === settingPageType.view
  const isEdit = viewType === settingPageType.edit
  const hasExchanged = defaultData.giftRecordCount > 0
  const isFieldDisabled = isNew ? false : isView || hasExchanged
  const isFieldDisabledByView = isView
  const isFieldDisabledByExchanged = isFieldDisabledByView || hasExchanged

  const isNeedReceiveTime =
    state.exchangePathway === exchangePathway.liff ||
    state.exchangePathway === exchangePathway.sms

  const handleChange = stateUpdate(dispatch)

  const debounceGiftInfoChange = useRef(debounce(handleChange('giftInfo'), 200))

  // 初始化（新增、編輯）資料
  useEffect(() => {
    if (!defaultData) return
    const isNewSetting = isNew && !settingId
    const newSettingData = {
      target: {
        forNewMember: '',
        forInitialMember: false,
        memberLevels: defaultData.memberLevelItemList.map((i) => ({
          ...i,
          selected: false,
        })),
      },
      memberLevelItemList: defaultData.memberLevelItemList,
    }
    const payload = isNewSetting ? newSettingData : parserPayload(defaultData)
    dispatch({ type: 'init', payload })
  }, [defaultData, isNew, settingId, dispatch])

  // 自動上架 Modal
  useEffect(() => {
    return () => {
      if (
        stateFromEdit &&
        stateFromEdit !== tabStatus.unavailable &&
        pageType === tabType.content
      ) {
        handleUniteModalSwitch(true, { type: modalType.goList })
      }
    }
  }, [stateFromEdit, pageType, handleUniteModalSwitch])

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit}>
        {/* 基本資料 - 名稱、顏色、尺寸等 */}
        <Form.Row>
          <Col lg="6">
            <Form.Group>
              <Form.Label>{formConfig.systemNo.label}</Form.Label>
              <Form.Control plaintext readOnly value={state.systemNo} />
            </Form.Group>
            <Form.Group>
              <Form.Label>{formConfig.name.label}</Form.Label>
              <InputGroup>
                <Input
                  className="form-row-input-fill"
                  formControlOption={{
                    placeholder: formConfig.name.placeholder,
                    value: state.name,
                    disabled: isNew ? false : !isEdit,
                    onChange: handleChange('name'),
                    maxLength: formConfig.name.maxLength,
                    required: validStatus.name.isValid === false,
                  }}
                />
                <InValidFeedback show={validStatus.name.isValid}>
                  {validStatus.name.msg}
                </InValidFeedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>{formConfig.color.label}</Form.Label>
              <InputGroup>
                <Input
                  className="form-row-input-fill"
                  formControlOption={{
                    placeholder: formConfig.color.placeholder,
                    value: state.color,
                    disabled: isFieldDisabled,
                    onChange: handleChange('color'),
                    maxLength: formConfig.color.maxLength,
                  }}
                />
                <InValidFeedback show={validStatus.color.isValid}>
                  {validStatus.color.msg}
                </InValidFeedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>{formConfig.size.label}</Form.Label>
              <InputGroup>
                <Input
                  className="form-row-input-fill"
                  formControlOption={{
                    placeholder: formConfig.size.placeholder,
                    value: state.size,
                    disabled: isFieldDisabled,
                    onChange: handleChange('size'),
                    maxLength: formConfig.size.maxLength,
                  }}
                />
                <InValidFeedback show={validStatus.size.isValid}>
                  {validStatus.size.msg}
                </InValidFeedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col lg="6">
            <ImageUploader
              value={state.images[0]?.url}
              disabled={isFieldDisabled}
              onChange={handleChange('images')}
              onError={handleImageError}
              limit={imageLimitConfig}
            />
            <Form.Text className="no-padding">
              {formConfig.images.placeholder}
            </Form.Text>
            <InValidFeedback
              show={validStatus.images.isValid}
              className="no-padding"
            >
              {validStatus.images.msg}
            </InValidFeedback>
          </Col>
        </Form.Row>

        {/* 對象 */}
        <Form.Row>
          <Form.Group className="form-group-top" as={Col}>
            <Form.Label>{formConfig.target.label}</Form.Label>
            <Col>
              <Row>
                <CheckListMainWrapper>
                  <Radio
                    label={'新會員'}
                    id="newMember"
                    checked={state.target.forNewMember}
                    disabled={isFieldDisabled}
                    onChange={handleChange('forNewMember')}
                  />
                  <Radio
                    label={'指定等級'}
                    id="specified"
                    checked={
                      state.target.forNewMember !== '' &&
                      !state.target.forNewMember
                    }
                    disabled={isFieldDisabled}
                    onChange={handleChange('forNewMember')}
                  />
                </CheckListMainWrapper>
                {state.target.forNewMember === false && (
                  <CheckListSubWrapper>
                    <Checkbox
                      label={formConfig.targetAll.label}
                      id="all"
                      checked={
                        state.target.memberLevels.every((i) => i.selected) &&
                        state.target.forInitialMember
                      }
                      disabled={isFieldDisabled}
                      onChange={handleChange('memberLevelsAll')}
                    />
                    <LvZeroEl>
                      <Checkbox
                        label={formConfig.targetZero.label}
                        id="lv0"
                        checked={state.target.forInitialMember}
                        disabled={isFieldDisabled}
                        onChange={handleChange('lv0')}
                      />
                      <Tooltip
                        triggerElement={
                          <img src={iconTooltip} alt="icon-tooltip" />
                        }
                        globalOption={{
                          effect: 'solid',
                        }}
                      >
                        {formConfig.targetZero.tip}
                      </Tooltip>
                    </LvZeroEl>
                    {state.memberLevelItemList.map((item) => (
                      <Checkbox
                        key={item.id}
                        label={item.name}
                        id={item.id}
                        checked={state.target.memberLevels.some(
                          (level) => level.id === item.id && level.selected
                        )}
                        disabled={isFieldDisabled}
                        onChange={handleChange('memberLevels')}
                      />
                    ))}
                  </CheckListSubWrapper>
                )}
              </Row>
              <InValidFeedback show={validStatus.target.isValid}>
                {validStatus.target.msg}
              </InValidFeedback>
            </Col>
          </Form.Group>
        </Form.Row>

        {/* 通路及兌換相關 */}
        <Form.Row>
          {/* 兌換通路 & 識別碼 */}
          <Col lg="6">
            <Form.Group className="form-group-top">
              <Form.Label>{formConfig.exchangePathway.label}</Form.Label>
              <Col>
                <Row className="flex-wrap">
                  {usingMaacActivity && (
                    <Radio
                      label={'漸強活動'}
                      id="event"
                      checked={state.exchangePathway === exchangePathway.event}
                      disabled={isFieldDisabled}
                      onChange={handleChange('exchangePathway')}
                    />
                  )}
                  {usingLineLiff && (
                    <Radio
                      label={'Line Liff'}
                      id="liff"
                      checked={state.exchangePathway === exchangePathway.liff}
                      disabled={isFieldDisabled}
                      onChange={handleChange('exchangePathway')}
                    />
                  )}
                  {/* 線下門店 phase1.5 先不開發 */}
                  {/* 此處功能，與後端確認似乎無後續，待後續刪除 */}
                  {usingOfflineShop && (
                    <Radio
                      label={'線下門店'}
                      id="store"
                      checked={state.exchangePathway === exchangePathway.store}
                      disabled={isFieldDisabled}
                      onChange={handleChange('exchangePathway')}
                    />
                  )}
                  {usingSendBySms && (
                    <Radio
                      label={'簡訊發送'}
                      id="sms"
                      checked={state.exchangePathway === exchangePathway.sms}
                      disabled={isFieldDisabled}
                      onChange={handleChange('exchangePathway')}
                    />
                  )}
                  {usingDelivery && (
                    <Radio
                      label={'宅配出貨'}
                      id="delivery"
                      checked={
                        state.exchangePathway === exchangePathway.delivery
                      }
                      disabled={isFieldDisabled}
                      onChange={handleChange('exchangePathway')}
                    />
                  )}
                </Row>
                <InValidFeedback show={validStatus.exchangePathway.isValid}>
                  {validStatus.exchangePathway.msg}
                </InValidFeedback>
              </Col>
            </Form.Group>
            {state.exchangePathway === exchangePathway.liff && (
              <Form.Group className="form-group-top">
                <Form.Label>{formConfig.collectionMethod.label}</Form.Label>
                <Col>
                  <Row>
                    <Radio
                      label={'線上連結'}
                      id="link"
                      checked={state.collectionMethod === collectionMethod.link}
                      disabled={isFieldDisabled}
                      onChange={handleChange('collectionMethod')}
                    />
                    <Radio
                      label={'線上序號'}
                      id="online"
                      checked={
                        state.collectionMethod === collectionMethod.online
                      }
                      disabled={isFieldDisabled}
                      onChange={handleChange('collectionMethod')}
                    />
                    <Radio
                      label={'門店領取'}
                      id="collectionStore"
                      checked={
                        state.collectionMethod === collectionMethod.store
                      }
                      disabled={isFieldDisabled}
                      onChange={handleChange('collectionMethod')}
                    />
                  </Row>
                  <Form.Text>{formConfig.collectionMethod.tip}</Form.Text>
                  <InValidFeedback show={validStatus.collectionMethod.isValid}>
                    {validStatus.collectionMethod.msg}
                  </InValidFeedback>
                </Col>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>{formConfig.giftCode.label}</Form.Label>
              <InputGroup>
                <Input
                  className="form-row-input-fill"
                  formControlOption={{
                    placeholder:
                      formConfig.giftCode[
                        `placeholder${state.exchangePathway}`
                      ],
                    value: state.giftCode,
                    disabled: isFieldDisabled,
                    onChange: handleChange('giftCode'),
                    maxLength: formConfig.giftCode.maxLength,
                    required: validStatus.giftCode.isValid === false,
                  }}
                />
                <Form.Text>{formConfig.giftCode.tip}</Form.Text>
                <InValidFeedback show={validStatus.giftCode.isValid}>
                  {validStatus.giftCode.msg}
                </InValidFeedback>
              </InputGroup>
            </Form.Group>
          </Col>

          {/* 兌換方式 */}
          <Col lg="6">
            {state.exchangePathway === exchangePathway.store ||
            state.exchangePathway === exchangePathway.sms ||
            state.exchangePathway === exchangePathway.delivery ? (
              <Form.Group>
                <Form.Label>{formConfig.exchangeMethod.label}</Form.Label>
                <ComplexListWrapper>
                  <ComplexItem>
                    <Col lg="4">
                      <Checkbox
                        label={formConfig.exchangePathwayPoint.label}
                        id="onlyPoint"
                        checked={
                          state.exchangeMethod?.onlyPoint?.check ||
                          state.exchangeMethod?.onlyPoint?.point
                        }
                        disabled={isFieldDisabled}
                        onChange={handleChange('onlyPoint_Checkbox')}
                      />
                    </Col>
                    <Col lg="8">
                      <Input
                        appendContent="點"
                        appendContentBgColor="#fff"
                        appendContentPadding="0 4px 0 0"
                        appendContentHaveBorder={false}
                        isFocusCocatAppend
                        formControlOption={{
                          value: state.exchangeMethod?.onlyPoint?.point ?? '',
                          disabled:
                            (isFieldDisabledByExchanged ?? '') ||
                            (!(
                              state.exchangeMethod?.onlyPoint?.check ||
                              state.exchangeMethod?.onlyPoint?.point
                            ) ??
                              ''),
                          onChange: handleChange('onlyPoint'),
                          maxLength: formConfig.exchangePathwayPoint.maxLength,
                        }}
                      />
                    </Col>
                  </ComplexItem>

                  {/* 線下門店配合的條碼，已停止開發 */}
                  {state.exchangePathway === exchangePathway.store && (
                    <>
                      <ComplexItem>
                        <Col lg="4">
                          <Form.Label>
                            {formConfig.exchangePathwayPoint.subLabel}
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <InputGroup>
                            <Input
                              className="form-row-input-fill"
                              formControlOption={{
                                placeholder:
                                  formConfig.exchangePathwayPoint.placeholder,
                                value:
                                  state.exchangeMethod?.onlyPoint?.barcode ??
                                  '',
                                disabled: isFieldDisabled,
                                onChange: handleChange('onlyPoint_Barcode'),
                                maxLength:
                                  formConfig.exchangePathwayPoint.subMaxLength,
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </ComplexItem>
                      <ComplexItem>
                        <Col lg="4">
                          <Checkbox
                            label={formConfig.exchangePathwayPointMoney.label}
                            id="pointMoney"
                            checked={
                              state?.exchangeMethod?.pointMoney?.check ||
                              (state.exchangeMethod?.pointMoney?.point &&
                                state.exchangeMethod?.pointMoney?.money)
                            }
                            disabled={isFieldDisabled}
                            onChange={handleChange('pointMoney_Checkbox')}
                          />
                        </Col>
                        <Col lg="8">
                          <Input
                            appendContent="點"
                            appendContentBgColor="#fff"
                            appendContentPadding="0 4px 0 0"
                            appendContentHaveBorder={false}
                            isFocusCocatAppend
                            formControlOption={{
                              value:
                                state.exchangeMethod?.pointMoney?.point ?? '',
                              disabled:
                                (isFieldDisabledByExchanged ?? '') ||
                                (!(
                                  state?.exchangeMethod?.pointMoney?.check ||
                                  (state.exchangeMethod?.pointMoney?.point &&
                                    state.exchangeMethod?.pointMoney?.money)
                                ) ??
                                  ''),
                              onChange: handleChange('pointMoney_Point'),
                              maxLength:
                                formConfig.exchangePathwayPointMoney.maxLength,
                            }}
                          />
                          <span>+</span>
                          <Input
                            appendContent="元"
                            appendContentBgColor="#fff"
                            appendContentPadding="0 4px 0 0"
                            appendContentHaveBorder={false}
                            isFocusCocatAppend
                            formControlOption={{
                              value:
                                state.exchangeMethod?.pointMoney?.money ?? '',
                              disabled:
                                (isFieldDisabledByExchanged ?? '') ||
                                (!(
                                  state?.exchangeMethod?.pointMoney?.check ||
                                  (state.exchangeMethod?.pointMoney?.point &&
                                    state.exchangeMethod?.pointMoney?.money)
                                ) ??
                                  ''),
                              onChange: handleChange('pointMoney_Money'),
                              maxLength:
                                formConfig.exchangePathwayPointMoney.maxLength,
                            }}
                          />
                        </Col>
                      </ComplexItem>
                      <ComplexItem>
                        <Col lg="4">
                          <Form.Label>
                            {formConfig.exchangePathwayPointMoney.subLabel}
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <InputGroup>
                            <Input
                              className="form-row-input-fill"
                              formControlOption={{
                                placeholder:
                                  formConfig.exchangePathwayPointMoney
                                    .placeholder,
                                value:
                                  state.exchangeMethod?.pointMoney?.barcode ??
                                  '',
                                disabled: isFieldDisabled,
                                onChange: handleChange('pointMoney_Barcode'),
                                maxLength:
                                  formConfig.exchangePathwayPointMoney
                                    .subMaxLength,
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </ComplexItem>
                      <ComplexItem>
                        <Col lg="4">
                          <Checkbox
                            label={formConfig.exchangePathwayMoney.label}
                            id="onlyMoney"
                            checked={
                              state.exchangeMethod?.onlyMoney?.check ||
                              state.exchangeMethod?.onlyMoney?.money
                            }
                            disabled={isFieldDisabled}
                            onChange={handleChange('onlyMoney_Checkbox')}
                          />
                        </Col>
                        <Col lg="8">
                          <Input
                            appendContent="元"
                            appendContentBgColor="#fff"
                            appendContentPadding="0 4px 0 0"
                            appendContentHaveBorder={false}
                            isFocusCocatAppend
                            formControlOption={{
                              value:
                                state.exchangeMethod?.onlyMoney?.money ?? '',
                              disabled:
                                (isFieldDisabledByExchanged ?? '') ||
                                (!(
                                  state.exchangeMethod?.onlyMoney?.check ||
                                  state.exchangeMethod?.onlyMoney?.money
                                ) ??
                                  ''),
                              onChange: handleChange('onlyMoney'),
                              maxLength:
                                formConfig.exchangePathwayMoney.maxLength,
                            }}
                          />
                        </Col>
                      </ComplexItem>
                      <ComplexItem className="form-complex-item-last">
                        <Col lg="4">
                          <Form.Label>
                            {formConfig.exchangePathwayMoney.subLabel}
                          </Form.Label>
                        </Col>
                        <Col lg="8">
                          <InputGroup>
                            <Input
                              className="form-row-input-fill"
                              formControlOption={{
                                placeholder:
                                  formConfig.exchangePathwayMoney.placeholder,
                                value:
                                  state.exchangeMethod?.onlyMoney?.barcode ??
                                  '',
                                disabled: isFieldDisabled,
                                onChange: handleChange('onlyMoney_Barcode'),
                                maxLength:
                                  formConfig.exchangePathwayMoney.subMaxLength,
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </ComplexItem>
                      <Form.Text>{formConfig.exchangeMethod.tip}</Form.Text>
                    </>
                  )}

                  {state.exchangePathway === exchangePathway.delivery && (
                    <>
                      <ComplexItem>
                        <Col lg="4">
                          <Checkbox
                            label={formConfig.exchangePathwayPointMoney.label}
                            id="pointMoney"
                            checked={
                              state.exchangeMethod?.pointMoney?.check ||
                              (state.exchangeMethod?.pointMoney?.point &&
                                state.exchangeMethod?.pointMoney?.money)
                            }
                            disabled={isFieldDisabled}
                            onChange={handleChange('pointMoney_Checkbox')}
                          />
                        </Col>
                        <Col lg="8">
                          <Input
                            appendContent="點"
                            appendContentBgColor="#fff"
                            appendContentPadding="0 4px 0 0"
                            appendContentHaveBorder={false}
                            isFocusCocatAppend
                            formControlOption={{
                              value:
                                state.exchangeMethod?.pointMoney?.point ?? '',
                              disabled:
                                (isFieldDisabledByExchanged ?? '') ||
                                (!(
                                  state.exchangeMethod?.pointMoney?.check ||
                                  (state.exchangeMethod?.pointMoney?.point &&
                                    state.exchangeMethod?.pointMoney?.money)
                                ) ??
                                  ''),
                              onChange: handleChange('pointMoney_Point'),
                              maxLength:
                                formConfig.exchangePathwayPointMoney.maxLength,
                            }}
                          />
                          <span>+</span>
                          <Input
                            appendContent="元"
                            appendContentBgColor="#fff"
                            appendContentPadding="0 4px 0 0"
                            appendContentHaveBorder={false}
                            isFocusCocatAppend
                            formControlOption={{
                              value:
                                state.exchangeMethod?.pointMoney?.money ?? '',
                              disabled:
                                (isFieldDisabledByExchanged ?? '') ||
                                (!(
                                  state.exchangeMethod?.pointMoney?.check ||
                                  (state.exchangeMethod?.pointMoney?.point &&
                                    state.exchangeMethod?.pointMoney?.money)
                                ) ??
                                  ''),
                              onChange: handleChange('pointMoney_Money'),
                              maxLength:
                                formConfig.exchangePathwayPointMoney.maxLength,
                            }}
                          />
                        </Col>
                      </ComplexItem>
                      <ComplexItem className="form-complex-item-last">
                        <Col lg="4">
                          <Checkbox
                            label={formConfig.exchangePathwayMoney.label}
                            id="onlyMoney"
                            checked={
                              state.exchangeMethod?.onlyMoney?.check ||
                              state.exchangeMethod?.onlyMoney?.money
                            }
                            disabled={isFieldDisabled}
                            onChange={handleChange('onlyMoney_Checkbox')}
                          />
                        </Col>
                        <Col lg="8">
                          <Input
                            appendContent="元"
                            appendContentBgColor="#fff"
                            appendContentPadding="0 4px 0 0"
                            appendContentHaveBorder={false}
                            isFocusCocatAppend
                            formControlOption={{
                              value:
                                state.exchangeMethod?.onlyMoney?.money ?? '',
                              disabled:
                                (isFieldDisabledByExchanged ?? '') ||
                                (!(
                                  state.exchangeMethod?.onlyMoney?.check ||
                                  state.exchangeMethod?.onlyMoney?.money
                                ) ??
                                  ''),
                              onChange: handleChange('onlyMoney'),
                              maxLength:
                                formConfig.exchangePathwayMoney.maxLength,
                            }}
                          />
                        </Col>
                      </ComplexItem>
                      <Form.Text>{formConfig.exchangeMethod.tip}</Form.Text>
                    </>
                  )}
                  <InValidFeedback show={validStatus.exchangeMethod.isValid}>
                    {validStatus.exchangeMethod.msg}
                  </InValidFeedback>
                </ComplexListWrapper>
              </Form.Group>
            ) : (
              <Form.Group>
                <Form.Label>{formConfig.exchangeMethod.label}</Form.Label>
                <Col>
                  <Input
                    appendContent="點"
                    appendContentBgColor="#fff"
                    appendContentPadding="0 4px 0 0"
                    appendContentHaveBorder={false}
                    isFocusCocatAppend
                    formControlOption={{
                      value: state.exchangeMethod?.onlyPoint?.point,
                      disabled: isFieldDisabled,
                      onChange: handleChange('onlyPoint'),
                      maxLength: formConfig.exchangePathwayPoint.maxLength,
                      required: validStatus.exchangeMethod.isValid === false,
                    }}
                  />
                  <InValidFeedback show={validStatus.exchangeMethod.isValid}>
                    {validStatus.exchangeMethod.msg}
                  </InValidFeedback>
                </Col>
              </Form.Group>
            )}
          </Col>
        </Form.Row>

        {/* 交換數量 */}
        <Form.Row>
          <Col lg="6">
            <Form.Group>
              <Form.Label>{formConfig.uploadCount.label}</Form.Label>
              <InputGroup>
                <Input
                  className="form-row-input-fill"
                  formControlOption={{
                    placeholder:
                      formConfig.uploadCount[
                        `placeholder${state.exchangePathway}`
                      ],
                    value: state.uploadCount,
                    disabled: isFieldDisabledByView,
                    onChange: handleChange('uploadCount'),
                    maxLength:
                      formConfig.uploadCount[
                        `maxLength${state.exchangePathway}`
                      ],
                    required: validStatus.uploadCount.isValid === false,
                  }}
                />
                <Form.Text>{formConfig.uploadCount.tip}</Form.Text>
                <InValidFeedback show={validStatus.uploadCount.isValid}>
                  {validStatus.uploadCount.msg}
                </InValidFeedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="form-group-top">
              <Form.Label>{formConfig.exchangeLimit.label}</Form.Label>
              <Col>
                <Row>
                  <Checkbox
                    label={'每人限兌換 1 次'}
                    id="exchangeLimit"
                    isValid
                    checked={state.exchangeLimit}
                    disabled={isFieldDisabled}
                    onChange={handleChange('exchangeLimit')}
                  />
                </Row>
                <InValidFeedback show={validStatus.exchangeLimit.isValid}>
                  {validStatus.exchangeLimit.msg}
                </InValidFeedback>
              </Col>
            </Form.Group>
          </Col>
        </Form.Row>

        {/* 兌換時間 */}
        <Form.Row>
          <Col lg="6">
            <Form.Group className="form-group-top" as={Col}>
              <Form.Label>{formConfig.exchangeStart.label}</Form.Label>
              <ColumnListWrapper>
                <Radio
                  label={'立即開始'}
                  id="immediately"
                  checked={state.exchangeStart.check}
                  disabled={isFieldDisabled}
                  onChange={handleChange('exchangeStart_Checkbox')}
                />
                <CalendarWrapper>
                  <Radio
                    label={'起始日'}
                    id="exchangeStart"
                    checked={state.exchangeStart.check === false}
                    disabled={isFieldDisabled}
                    onChange={handleChange('exchangeStart_Checkbox')}
                  />
                  <Calendar
                    value={state.exchangeStart.calendar}
                    disabled={isFieldDisabled}
                    onChange={handleChange('exchangeStart')}
                    minDate={startOfToday()}
                    isValid={validStatus.exchangeStart.isValid}
                  />
                  <InValidFeedback show={validStatus.exchangeStart.isValid}>
                    {validStatus.exchangeStart.msg}
                  </InValidFeedback>
                </CalendarWrapper>
              </ColumnListWrapper>
            </Form.Group>
            <Form.Group className="form-group-top" as={Col}>
              <Form.Label>{formConfig.autoUpload.label}</Form.Label>
              <ColumnListWrapper>
                <Radio
                  label={'依兌換起始日，自動上架贈品'}
                  id="auto"
                  checked={state.autoUpload}
                  disabled={isFieldDisabledByView}
                  onChange={handleChange('autoUpload')}
                />
                <Radio
                  label={'不自動上架贈品'}
                  id="nonAuto"
                  checked={state.autoUpload === false}
                  disabled={isFieldDisabledByView}
                  onChange={handleChange('autoUpload')}
                />
                <InValidFeedback show={validStatus.autoUpload.isValid}>
                  {validStatus.autoUpload.msg}
                </InValidFeedback>
              </ColumnListWrapper>
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="form-group-top" as={Col}>
              <Form.Label>{formConfig.exchangeEnd.label}</Form.Label>
              <ColumnListWrapper>
                <Radio
                  label={'永久（若兌換完畢，將自動下架）'}
                  id="exchangeEndPermanent"
                  checked={state.exchangeEnd.check}
                  disabled={isFieldDisabledByView}
                  onChange={handleChange('exchangeEnd_Checkbox')}
                />
                <CalendarWrapper>
                  <Radio
                    label={'結束日'}
                    id="exchangeEnd"
                    checked={state.exchangeEnd.check === false}
                    disabled={isFieldDisabledByView}
                    onChange={handleChange('exchangeEnd_Checkbox')}
                  />
                  <Calendar
                    value={state.exchangeEnd.calendar}
                    disabled={isFieldDisabledByView}
                    onChange={handleChange('exchangeEnd')}
                    minDate={startOfToday()}
                    isValid={validStatus.exchangeEnd.isValid}
                  />
                </CalendarWrapper>
                <InValidFeedback show={validStatus.exchangeEnd.isValid}>
                  {validStatus.exchangeEnd.msg}
                </InValidFeedback>
              </ColumnListWrapper>
            </Form.Group>
          </Col>
        </Form.Row>

        {/* 領取時間 */}
        {isNeedReceiveTime && (
          <Form.Row>
            <Col lg="6">
              <Form.Group className="form-group-top" as={Col}>
                <Form.Label>{formConfig.collectionStart.label}</Form.Label>
                <ColumnListWrapper>
                  <Calendar
                    value={state.collectionStart}
                    disabled={isFieldDisabled}
                    onChange={handleChange('collectionStart')}
                    minDate={startOfToday()}
                    isValid={validStatus.collectionStart.isValid}
                  />
                  <InValidFeedback show={validStatus.collectionStart.isValid}>
                    {validStatus.collectionStart.msg}
                  </InValidFeedback>
                </ColumnListWrapper>
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group className="form-group-top" as={Col}>
                <Form.Label>{formConfig.collectionEnd.label}</Form.Label>
                <ColumnListWrapper>
                  <Radio
                    label={'永久'}
                    id="collectionEndPremanent"
                    checked={state.collectionEnd.check}
                    disabled={isFieldDisabledByView}
                    onChange={handleChange('collectionEnd_Checkbox')}
                  />
                  <CalendarWrapper>
                    <Radio
                      label={'結束日'}
                      id="collectionEnd"
                      checked={state.collectionEnd.check === false}
                      disabled={isFieldDisabledByView}
                      onChange={handleChange('collectionEnd_Checkbox')}
                    />
                    <Calendar
                      value={state.collectionEnd.calendar}
                      disabled={isFieldDisabledByView}
                      onChange={handleChange('collectionEnd')}
                      minDate={startOfToday()}
                      isValid={validStatus.collectionEnd.isValid}
                    />
                  </CalendarWrapper>
                  <InValidFeedback show={validStatus.collectionEnd.isValid}>
                    {validStatus.collectionEnd.msg}
                  </InValidFeedback>
                </ColumnListWrapper>
              </Form.Group>
            </Col>
          </Form.Row>
        )}

        {/* 贈品介紹 */}
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>{formConfig.giftInfo.label}</Form.Label>
            <Col>
              <Editor
                height="146"
                width="100%"
                defaultValue={isNew ? '' : state.giftInfo}
                disabled={isFieldDisabledByView}
                onChange={(e) => debounceGiftInfoChange.current(e)}
                placeholder={formConfig.giftInfo.placeholder}
              />
              <InValidFeedback show={validStatus.giftInfo.isValid}>
                {validStatus.giftInfo.msg}
              </InValidFeedback>
            </Col>
          </Form.Group>
        </Form.Row>
      </Form>
    </FormWrapper>
  )
}

export default SettingContent
