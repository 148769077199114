import PropTypes from 'prop-types'
import { useContext, useState, useEffect, useCallback, useRef } from 'react'
import {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
  getProductActivityList,
  stopProductActivity,
  deleteProductActivityEvent,
} from 'api/ApiMain' // changeStatus
import { color, colorObj } from 'assets/styles/Variable/Color'
import withPermission from 'components/hocs/withPermission'
import {
  Table,
  NoDataIndication,
  Row,
  Col,
  Modal,
  Button,
  IconButton,
  Card,
} from 'components/units'
import { COMPONENT_PERMISSIONS, PAGE_PATHS } from 'constant'
import { handleXSS } from 'helpers/common'
import { RootContext } from 'RootContext'
import { getGlobalState, updatePageLoading } from 'store/global/globalSlice'

const settingPageType = {
  new: 'new',
  edit: 'edit',
  view: 'view',
}
const infoMixin = css`
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    background-color: ${colorObj.white};
    border-radius: 5px;
  }
  .info-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  .info-content {
    display: flex;
    flex-direction: column;
  }
  .info-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .info-default {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    color: ${colorObj.darkGray};
  }
`

const settingMixin = css`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0 8px;
`

const StyledFormatter = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: ${(props) => props.color || colorObj.dark};
  font-weight: 400;
  width: ${(props) => props.width || 'auto'};
  ${(props) => props.info && infoMixin}
  ${(props) => props.setting && settingMixin};
  p.note {
    color: ${colorObj.danger};
    margin-bottom: 0;
  }
`

const ColWrap = styled.div`
  display: flex;
  align-items: center;
  .spot {
    width: 6px;
    height: 6px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: ${(props) => props.color || colorObj.dark};
  }
`

const formatterName = (cell, row) => {
  return (
    <StyledFormatter>
      {cell}
      <p className="note">{row.pointProductCount === 0 ? '無商品資料' : ''}</p>
    </StyledFormatter>
  )
}

const formatterValidPeriod = (cell) => {
  return <StyledFormatter>{cell}</StyledFormatter>
}

const formatterObject = (cell, row) => {
  if (!Array.isArray(cell)) return null
  return (
    <StyledFormatter>
      {cell.map((item) => (
        <div key={row.pointProductRuleId}>{item}</div>
      ))}
    </StyledFormatter>
  )
}

const formatterContent = (cell) => {
  return (
    <StyledFormatter>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: handleXSS(cell) }} />
    </StyledFormatter>
  )
}

const formatterStatus = (cell) => {
  let onColor = '#333333'
  switch (cell) {
    case '進行中':
      onColor = '#3ca078'
      break
    case '準備中':
      onColor = '#333333'
      break
    case '已停用':
    case '已過期':
    default:
      onColor = 'rgba(51, 51, 51, 0.25)'
      break
  }
  return (
    <StyledFormatter width="110px" color={onColor}>
      <ColWrap color={onColor}>
        <i className="spot" />
        {cell}
      </ColWrap>
    </StyledFormatter>
  )
}

const parserBtnStatus = (info) => ({
  remove: info?.deleteBtn?.visible,
  edit: info?.editBtn?.visible,
  stop: info?.stopBtn?.visible,
  view: info?.viewBtn?.visible,
})

const handleView = (apiPayload, history) => () =>
  history.push(PAGE_PATHS.activitiesCode.productActivityNew, {
    type: settingPageType.view,
    apiPayload,
  })

function NormalSection({
  cell,
  params,
  onResetList,
  onLoading,
  onGetList,
  onOpenModal,
}) {
  const history = useHistory()
  const { brandId, pointProductRuleId, apiPayload } = params
  const { stop, remove, edit, view } = parserBtnStatus(cell)

  const handleEdit = () => {
    history.push(PAGE_PATHS.activitiesCode.productActivityEdit, {
      type: settingPageType.edit,
      apiPayload: { brandId, pointProductRuleId, ...apiPayload },
    })
  }
  const handleDelete = async () => {
    onLoading(true)
    onResetList()
    await deleteProductActivityEvent(brandId, pointProductRuleId)
    await onGetList()
    onLoading(false)
  }

  const handleStop = () =>
    onOpenModal({ brandId, pointProductRuleId, type: 'stop' })
  const { READ, UPDATE, DELETE } = COMPONENT_PERMISSIONS.productRule
  const PermissionViewIcon = withPermission(IconButton, READ)
  const PermissionEditIcon = withPermission(IconButton, UPDATE)
  const PermissionDeleteIcon = withPermission(IconButton, DELETE)
  const PermissionStopIcon = withPermission(IconButton, DELETE)
  return (
    <StyledFormatter setting>
      {view && (
        <PermissionViewIcon
          onClick={handleView(
            { brandId, pointProductRuleId, ...apiPayload },
            history
          )}
          tooltip="檢視"
          type="view"
        />
      )}
      {edit && (
        <PermissionEditIcon onClick={handleEdit} tooltip="編輯" type="edit" />
      )}
      {remove && (
        <PermissionDeleteIcon
          onClick={handleDelete}
          color={colorObj.lightGray}
          tooltip="刪除"
          type="delete"
        />
      )}
      {stop && (
        <PermissionStopIcon
          onClick={handleStop}
          color={colorObj.lightGray}
          tooltip="停用"
          type="stop"
        />
      )}
    </StyledFormatter>
  )
}
NormalSection.propTypes = {
  cell: PropTypes.shape({
    deleteBtn: PropTypes.shape({
      visible: PropTypes.bool,
    }),
    editBtn: PropTypes.shape({
      visible: PropTypes.bool,
    }),
    stopBtn: PropTypes.shape({
      visible: PropTypes.bool,
    }),
    viewBtn: PropTypes.shape({
      visible: PropTypes.bool,
    }),
  }).isRequired,
  params: PropTypes.shape({
    brandId: PropTypes.number,
    pointProductRuleId: PropTypes.number,
    apiPayload: PropTypes.shape({
      brandId: PropTypes.number,
      state: PropTypes.string,
      event: PropTypes.shape({
        usingMaccActivity: PropTypes.bool,
        usingOfflineShop: PropTypes.bool,
      }),
      sf: PropTypes.string,
      so: PropTypes.string,
      p: PropTypes.number,
    }),
  }).isRequired,
  onResetList: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired,
  onGetList: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
}

const Wrapper = styled.div`
  .tab-content {
    padding: 24px;
  }

  .caption {
    caption-side: top;
    padding: 0px 0px 16px 0px;
  }

  .label-title {
    display: flex;
    align-items: center;
  }

  .label-title + .text-right {
    justify-content: flex-end;
  }
  .white-space-nowrap-with-btn {
    margin-left: 25px;
  }
`

const formatterSetting = ({
  brandId,
  apiPayload,
  openModal,
  handleResetList,
  handleLoading,
  handleGetList,
}) =>
  function setting(cell, row) {
    const params = {
      brandId,
      pointProductRuleId: row?.pointProductRuleId,
      apiPayload,
    }

    return (
      <NormalSection
        cell={cell}
        params={params}
        onResetList={handleResetList}
        onLoading={handleLoading}
        onGetList={handleGetList}
        onOpenModal={openModal}
      />
    )
  }

const columns = ({
  brandId,
  apiPayload,
  openModal,
  handleResetList,
  handleLoading,
  handleGetList,
}) => [
  {
    dataField: 'no',
    text: 'No.',
    sort: true,
    style: (_cell, row) => {
      return {
        backgroundColor:
          row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
      }
    },
  },
  {
    dataField: 'name',
    text: '名稱',
    sort: false,
    formatter: formatterName,
    headerStyle: {
      width: '35%',
    },
    style: (_cell, row) => {
      return {
        backgroundColor:
          row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
      }
    },
  },
  {
    dataField: 'validPeriod',
    text: '效期',
    sort: true,
    formatter: formatterValidPeriod,
    headerStyle: {
      width: '13%',
    },
    style: (_cell, row) => {
      return {
        backgroundColor:
          row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
      }
    },
  },
  {
    dataField: 'object',
    text: '對象',
    sort: false,
    formatter: formatterObject,
    headerStyle: {
      width: '15%',
    },
    style: (_cell, row) => {
      return {
        backgroundColor:
          row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
      }
    },
  },
  {
    dataField: 'content',
    text: '內容',
    sort: false,
    formatter: formatterContent,
    headerStyle: {
      width: '25%',
    },
    style: (_cell, row) => {
      return {
        backgroundColor:
          row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
      }
    },
  },
  {
    dataField: 'state',
    text: '狀態',
    sort: true,
    formatter: formatterStatus,
    headerStyle: {
      width: '5%',
    },
    style: (_cell, row) => {
      switch (row.state) {
        case '進行中':
          return {
            color: '#3ca078',
            backgroundColor:
              row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
          }
        case '準備中':
          return {
            color: '#333333',
            backgroundColor:
              row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
          }
        case '已停用':
        case '已過期':
          return {
            backgroundColor:
              row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
          }
        default:
          return {
            color: 'rgba(51, 51, 51, 0.25)',
          }
      }
    },
  },
  {
    dataField: 'funcButton',
    text: '',
    style: (_cell, row) => {
      return {
        backgroundColor:
          row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
      }
    },
    formatter: formatterSetting({
      brandId,
      apiPayload,
      openModal,
      handleResetList,
      handleLoading,
      handleGetList,
    }), // TODO 傳遞props資料流程過於冗長以及深度過深，思考如何優化
  },
]

const initModalData = {
  status: false,
  payload: { brandId: null, pointProductRuleId: null, type: '' },
  type: '',
  info: '',
}

const onTableChange = (setApiPayload) => (type, value) => {
  if (type === 'sort') {
    setApiPayload((prev) => ({
      ...prev,
      sf: value.sortField,
      so: value.sortOrder,
    }))
  }
}

function Caption({
  paginationProps,
  payload,
  history,
  enableNewPointProductRule,
}) {
  const { CREATE } = COMPONENT_PERMISSIONS.productRule
  const PermissionAddActivityButton = withPermission(Button, CREATE)
  return (
    <Row className="caption">
      <Col className="label-title">
        <div>
          總項目：
          <span className="paragraph-info">
            {paginationProps.totalSize || 0} 筆
          </span>
        </div>
      </Col>
      <Col className="label-title text-right">
        <span className="white-space-nowrap mr-1">每頁</span>
        <SizePerPageDropdownStandalone
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
        <PermissionAddActivityButton
          className="white-space-nowrap-with-btn"
          variant="outline-primary"
          disabled={!enableNewPointProductRule}
          onClick={() => {
            history.push(PAGE_PATHS.activitiesCode.productActivityNew, {
              type: 'new',
              apiPayload: payload,
            })
          }}
          size="sm"
        >
          新增活動
        </PermissionAddActivityButton>
      </Col>
    </Row>
  )
}

function ProductActivityList({
  paginationProps,
  paginationTableProps,
  apiPayload,
  setApiPayload,
  setTotalSize,
  event,
  setEvent,
}) {
  const dispatch = useDispatch()
  const { isPageLoading } = useSelector(getGlobalState)
  const rootData = useContext(RootContext)
  const { brandId } = rootData
  const history = useHistory()
  const [modal, setModal] = useState(initModalData)
  const [rows, setRows] = useState([])
  const [research, setResearch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [enableNewPointProductRule, setEnableNewPointProductRule] =
    useState(true)
  const payloadInfo = {
    ...apiPayload,
    event,
    brandId,
  }

  const openModal = (payload) => {
    let modalInfo = {
      status: true,
      payload: {
        brandId: payload.brandId,
        pointProductRuleId: payload.pointProductRuleId,
      },
      type: payload.type,
      info: '',
    }

    switch (payload.type) {
      case 'stop':
        modalInfo = {
          ...modalInfo,
          info: {
            content1: '停用後將無法復原',
            content2: '確定停用？',
            titleText: '即將停用',
            confirmBtnText: '停用',
            closeBtnText: '返回',
          },
        }
        break
      default:
        break
    }
    setModal(modalInfo)
  }
  const handleClose = () => setModal(initModalData)

  const handleGetProductActivityList = useCallback(async () => {
    setIsLoading(true)
    setRows([])
    setTotalSize(0)
    const apiPayloadParam =
      apiPayload.p === 0 ? { ...apiPayload, p: 1 } : apiPayload
    try {
      const rsp = await getProductActivityList(brandId, apiPayloadParam)
      setEnableNewPointProductRule(rsp?.data?.data?.enableNewPointProductRule)
      setRows(rsp?.data?.data?.rows)
      setTotalSize(rsp?.data?.data?.totalSize)
      setEvent(() => ({
        usingMaccActivity: rsp?.data?.data?.feature?.usingMaccActivity || false,
        usingOfflineShop: rsp?.data?.data?.feature?.usingOfflineShop || false,
      }))
    } catch (error) {
      console.error(error)
      setRows([])
    } finally {
      setIsLoading(false)
      dispatch(updatePageLoading(false))
    }
  }, [setTotalSize, brandId, apiPayload, setEvent, dispatch])

  const onConfirm = async (payload, handleModalLoading) => {
    const { brandId: brandIdParam, pointProductRuleId } = payload
    handleModalLoading(true)
    try {
      await stopProductActivity(brandIdParam, pointProductRuleId)
    } catch {
      // 已在上層處理
    } finally {
      await handleGetProductActivityList()
      handleModalLoading(false)
      handleClose()
    }
  }

  const apiPayloadRef = useRef(apiPayload)

  useEffect(() => {
    if (brandId && apiPayload !== apiPayloadRef.current) {
      handleGetProductActivityList()
    }
  }, [brandId, handleGetProductActivityList, apiPayload])

  useEffect(() => {
    if (research) {
      handleGetProductActivityList()
      setResearch(false)
    }
  }, [research, handleGetProductActivityList])

  return (
    <Wrapper>
      <Card className="w-100">
        <Caption
          paginationProps={paginationProps}
          totalSize={rows.length}
          setApiPayload={setApiPayload}
          payload={payloadInfo}
          history={history}
          enableNewPointProductRule={enableNewPointProductRule}
        />
        <Table
          headerClasses="table-header"
          bodyClasses="paragraph"
          className="overflow-auto"
          keyField="no"
          data={rows}
          columns={columns({
            type: apiPayload.state,
            brandId,
            apiPayload: { ...apiPayload, event },
            openModal,
            handleResetList: () => setRows([]),
            handleLoading: (bool) => setIsLoading(bool),
            handleGetList: handleGetProductActivityList,
          })}
          sort={{ dataField: apiPayload.sf, order: apiPayload.so }}
          striped
          remote
          onTableChange={onTableChange(setApiPayload)}
          noDataIndication={
            <NoDataIndication
              isLoading={isPageLoading ? false : isLoading}
              message={isLoading ? '載入中...' : '尚無資料'}
            />
          }
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationTableProps}
        />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PaginationListStandalone {...paginationProps} />
      </Card>
      <Modal
        show={modal.status}
        onClose={handleClose}
        onConfirm={({ handleModalLoading }) =>
          onConfirm(modal.payload, handleModalLoading)
        }
        variant={color.danger}
        titleText={modal.info?.titleText}
        confirmBtnText={modal.info?.confirmBtnText}
        closeBtnText={modal.info?.closeBtnText}
      >
        <p>{modal.info?.content1}</p>
        <p>{modal.info?.content2}</p>
      </Modal>
    </Wrapper>
  )
}

export default ProductActivityList

Caption.propTypes = {
  paginationProps: PropTypes.shape({
    totalSize: PropTypes.number,
    sizePerPage: PropTypes.number,
  }).isRequired,
  payload: PropTypes.shape({
    brandId: PropTypes.number,
    state: PropTypes.string,
    event: PropTypes.shape({
      usingMaccActivity: PropTypes.bool,
      usingOfflineShop: PropTypes.bool,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  enableNewPointProductRule: PropTypes.bool.isRequired,
}
ProductActivityList.propTypes = {
  paginationProps: PropTypes.shape({
    totalSize: PropTypes.number,
    sizePerPage: PropTypes.number,
  }).isRequired,
  paginationTableProps: PropTypes.shape({
    onTableChange: PropTypes.func,
  }).isRequired,
  apiPayload: PropTypes.shape({
    brandId: PropTypes.string,
    state: PropTypes.string,
    event: PropTypes.shape({
      usingMaccActivity: PropTypes.bool,
      usingOfflineShop: PropTypes.bool,
    }),
    sf: PropTypes.string,
    so: PropTypes.string,
    p: PropTypes.number,
  }).isRequired,
  setApiPayload: PropTypes.func.isRequired,
  setTotalSize: PropTypes.func.isRequired,
  event: PropTypes.shape({
    usingMaccActivity: PropTypes.bool,
    usingOfflineShop: PropTypes.bool,
  }).isRequired,
  setEvent: PropTypes.func.isRequired,
}
