import styled from 'styled-components'
import { Tabs } from 'react-bootstrap'
import { Checkbox, Row } from 'components/units'
import { colorObj } from 'assets/styles/Variable/Color'

const CaptionCheckbox = styled(Checkbox)`
  margin-left: 16px;
  color: ${colorObj.dark};
`

const CaptionWrapper = styled(Row)`
  padding: 0px 0px 16px 0px;

  .label-title {
    display: flex;
    align-items: center;
  }

  .label-title + .text-right {
    justify-content: flex-end;
  }
`

const TabsWrapper = styled(Tabs)`
  + .tab-content {
    padding: 24px;
    .paragraph {
      font-size: 13px;
      color: ${colorObj.dark};
    }
  }
`

const FormatterNameWrapper = styled.div`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const FormatterDateRangeWrapper = styled.div`
  .dateRange-expired {
    color: ${colorObj.danger};
  }
`

const FormatterAmountWrapper = styled.div`
  text-align: end;
  .amount-invalid {
    color: ${colorObj.danger};
  }
`

const FormatterFnWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.flexEnd ? 'flex-end' : 'flex-start')};
  align-items: center;
  gap: 10px;
  .formatterFnBtn .dropdown-toggle {
    padding: 0 8px;
  }

  .unavailableIcon {
    font-size: 14px;
    margin-right: 5px;
    path {
      fill-opacity: 1;
      fill: ${colorObj.darkerGray};
    }
    + span {
      font-size: 14px;
    }
  }
`

const TextLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export {
  CaptionCheckbox,
  CaptionWrapper,
  TabsWrapper,
  FormatterNameWrapper,
  FormatterDateRangeWrapper,
  FormatterAmountWrapper,
  FormatterFnWrapper,
  TextLink,
}
