import styled from 'styled-components'
import { colorObj, colorCommon } from 'assets/styles/Variable/Color'

const PermissionTableWrapper = styled.div`
  .table {
    width: 550px;
    td {
      padding: 0.4rem 0.75rem;
    }
  }

  .table-slash-box {
    position: relative;
    height: 50px;
    .table-slash {
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: ${colorCommon.borderGray};
        position: absolute;
        left: 0;
        transform: rotate(22.5deg) scaleX(1.4) translateY(2275%);
      }
      .title {
        position: absolute;
        width: 50%;
        text-align: center;
      }
      .left-title {
        left: 0;
        bottom: 0;
      }
      .right-title {
        top: 0;
        right: 0;
      }
    }
  }
`
const ModuleCell = styled.div`
  display: 'flex';
  justify-content: 'center';
  width: 148px;
  color: ${colorObj.primary};
`
const PermissionCell = styled.div`
  display: flex;
  justify-content: center;
  width: 60px;
  color: ${colorObj.darkerGray};
`
const IconInfo = styled.div`
  margin-top: 16px;
  display: flex;
  .icon-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
    p {
      color: ${colorObj.darkerGray}!important;
      font-size: 13px !important;
      padding-left: 5px;
    }
  }
`
const IconWrapper = styled.div`
  height: auto;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: ${({ color }) => color || colorObj.white};
      fill-opacity: 1;
    }
  }
`
export {
  PermissionTableWrapper,
  ModuleCell,
  PermissionCell,
  IconWrapper,
  IconInfo,
}
