import { Modal } from 'components/units'
import { color } from 'assets/styles/Variable/Color'

export default function UploadLeaveHintsModal({
  isShow = false,
  onContinueUploadFile = () => {},
  onConfirmLeave = () => {},
}) {
  return (
    <Modal
      show={isShow}
      backdrop="static"
      titleText="即將離開"
      variant={color.danger}
      confirmBtnText="離開"
      closeBtnText="返回"
      onClose={onContinueUploadFile}
      onConfirm={() => onConfirmLeave()}
    >
      <p>已編輯的資料將不會儲存，</p>
      <p>確定離開編輯？</p>
    </Modal>
  )
}
