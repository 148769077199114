import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'

const StyledSideSetion = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
const TableLinkStyle = styled.div`
  color: ${colorObj.primary};
  text-decoration: underline;
  cursor: pointer;
`

export { StyledSideSetion, TableLinkStyle }
