import PropTypes from 'prop-types'
import { Breadcrumb } from 'components/units'

function NavSection({ handleEvent, pageTitle }) {
  const pathList = [
    { title: '系統設定', slash: true },
    { onClick: handleEvent, title: '帳號管理' },
  ]
  const now = (
    <span className="width-max-content d-inline-block">{pageTitle}</span>
  )
  return <Breadcrumb now={now} pathList={pathList} />
}

export default NavSection

NavSection.defaultProps = {
  handleEvent: () => {},
  pageTitle: '',
}
NavSection.propTypes = {
  handleEvent: PropTypes.func,
  pageTitle: PropTypes.string,
}
