import { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import styled from 'styled-components'
import Navbar from 'components/layouts/Navbar'
import SerialBatchUpload from 'components/layouts/SerialBatchUpload'
import Sidebar from 'components/layouts/Sidebar'
import { StyleLoader } from 'components/units/Loader'
// import Zendesk from 'components/widgets/zendesk/Zendesk'
import { PAGE_PATHS } from 'constant'
import AppRouter from 'routers'
import { authInit } from 'store/auth/authSlice'
import { getGlobalState } from 'store/global/globalSlice'

const SideBarAndContent = styled.div`
  display: flex;
  flex-grow: 1;
  padding-top: ${(props) => (props.isFullLayout ? '0' : '48px')};
`
const Content = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: #fafafa;
`

const checkFullLayoutRoutes = (pathname) => {
  const fullLayoutPaths = [
    PAGE_PATHS.login,
    PAGE_PATHS.privacyPolicy,
    '/verify',
  ]
  const parts = pathname.substring(1).split('/')
  const firstRoutePart = `/${parts[0]}`

  return fullLayoutPaths.includes(firstRoutePart)
}
// const ZENDESK_KEY = 'd88a8836-256e-45c5-85d4-29a013acfe20'

function App() {
  const dispatch = useDispatch()
  const { isAppLoading } = useSelector(getGlobalState)
  const [isFullLayout, setIsFullLayout] = useState(true)

  const handleChangeHistory = (pathname) => {
    setIsFullLayout(checkFullLayoutRoutes(pathname))
  }
  useEffect(() => {
    dispatch(authInit())
    checkFullLayoutRoutes(window.location.pathname)
  }, [dispatch])
  const handleZendeskLoaded = () => {
    // ZendeskAPI('messenger', 'open')
  }
  return (
    <>
      <Toaster
        containerStyle={{
          top: 40,
        }}
        toastOptions={{
          className: 'react-hot-toast',
          style: {
            border: '1px solid #d8d8d8',
            maxWidth: 'none',
            // 統一左右 padding 為 16px，但 hot-toast 套件在內文會自動有 10px 左右 padding，所以要扣掉
            padding: '3px 6px 3px 16px',
          },
          success: {
            duration: 3000,
            iconTheme: {
              primary: '#3ca078',
            },
          },
          error: {
            duration: Infinity,
            iconTheme: {
              primary: '#dc3c50',
            },
          },
        }}
      />
      {!isFullLayout ? <Navbar /> : null}
      <StyleLoader
        isHide={!isAppLoading}
        bgColorAlpha={1}
        className="appLoading"
      />
      {/* <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleZendeskLoaded} /> */}
      <div className={`app ${isAppLoading ? 'overflow-hidden' : ''}`}>
        <SideBarAndContent isFullLayout={isFullLayout}>
          <Content>
            <Router>
              <SerialBatchUpload />
              {!isFullLayout ? <Sidebar /> : null}
              <AppRouter onChange={handleChangeHistory} />
            </Router>
          </Content>
        </SideBarAndContent>
      </div>
    </>
  )
}

export default App
