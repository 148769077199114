import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'

const CaptionWrapper = styled.div`
  color: ${colorObj.dark};
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 16px;

  > div {
    display: flex;
    align-items: center;
  }

  .caption-label {
    min-width: 60px;
    color: ${colorObj.darkGray};
  }

  .caption-color {
    color: ${colorObj.darkGray};
  }
`

const Wrapper = styled.div`
  tbody {
    font-size: 13px;
    font-weight: normal;
  }
`

export { Wrapper, CaptionWrapper }
