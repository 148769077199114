import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getValidateCodeSetting,
  getVipGiftExchangeRecord,
  getGiftExchangeRecord,
  getVipGiftReimburse,
  getGiftDeliveryDetail,
  getGiftExchangeRecordDetail,
  getVipGiftExchangeRecordDetail,
  getVipGiftReimburseDetail,
  patchVipGiftReimburse,
  getGiftReimburse,
  getGiftReimburseDetail,
  patchGiftReimburse,
} from 'api/ApiMain'
import { GIFT_LIST } from 'store/constants'

export const giftListInitState = {
  validateCodeSettingRsp: {
    data: {
      liffEnabled: false,
      source: [],
      shop: [],
      consumeChannel: [],
    },
  },
  vipGiftExchangeRecordListRsp: {
    data: {
      sort: {},
      totalSize: 0,
      rows: [],
    },
  },
  giftExchangeRecordListRsp: {
    data: {
      sort: {},
      totalSize: 0,
      rows: [],
    },
  },
  vipGiftVerificationListRsp: {
    data: {
      sort: {},
      totalSize: 0,
      rows: [],
    },
  },
  vipGiftVerificationResultRsp: {
    data: { totalCount: 0, successCount: 0, failedCount: 0 },
    isFetch: false,
  },
  exchangeGiftVerificationRsp: {
    data: { totalCount: 0, successCount: 0, failedCount: 0 },
    isFetch: false,
  },
  giftExchangeRecordDetailRsp: {
    data: {
      giftRecordObjId: '',
      status: 0,
      statusDescription: '',
      exchangedDate: '',
      exchangedTime: '',
      exchangedWay: '',
      gotGiftValidPeriod: '',
      gotGiftTime: '',
      gotGiftWay: '',
      gotGiftPlace: '',
      customClientId: '',
      memberName: '',
      memberCellPhone: '',
      memberLevel: '',
      giftCode: '',
      giftCustomId: '',
      giftName: '',
      giftColor: '',
      giftSize: '',
      giftQuantity: 0,
      exchangedExpense: '',
    },
    code: null,
    msg: '',
    errors: {},
    isFetch: false,
  },
  vipGiftExchangeRecordDetailRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      giftRecordObjId: '',
      status: 0,
      statusDescription: '',
      sendTime: '',
      gotGiftValidPeriod: '',
      gotGiftDate: '',
      gotGiftTime: '',
      gotGiftWay: '',
      gotGiftPlace: '',
      customClientId: '',
      memberName: '',
      memberCellPhone: '',
      memberLevel: '',
      giftCode: '',
      type: '',
      contentType: 1,
      contentTypeText: '',
      name: '',
      content: '',
      productCustomId: '',
      productName: '',
      productColor: '',
      productSize: '',
    },
    isFetch: false,
  },
  giftDeliveryDetailRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      giftRecordObjId: '',
      status: 0,
      statusDescription: '',
      exchangedDate: '',
      exchangedTime: '',
      exchangedWay: '',
      exchangedExpense: '',
      customClientId: '',
      memberName: '',
      memberCellPhone: '',
      memberLevel: '',
      giftCode: '',
      giftCustomId: '',
      giftName: '',
      giftColor: '',
      giftSize: '',
      giftQuantity: 0,
      recipientName: '',
      recipientCellPhone: '',
      recipientCity: {
        optionId: '',
        code: '',
        name: '',
      },
      recipientDist: {
        optionId: '',
        code: '',
        name: '',
      },
      recipientAddress: '',
      deliveryNote: '',
      whetherToShip: true,
      shippingUpdateTime: '',
      shippingNumber: '',
    },
    isFetch: false,
  },
  vipGiftVerificationDetailRsp: {
    data: {
      giftRecordObjId: '',
      status: 0,
      statusDescription: '',
      exchangedDate: '',
      exchangedTime: '',
      exchangedWay: '',
      gotGiftValidPeriod: '',
      gotGiftTime: '',
      gotGiftWay: '',
      gotGiftPlace: '',
      customClientId: '',
      memberName: '',
      memberCellPhone: '',
      memberLevel: '',
      giftCode: '',
      giftCustomId: '',
      giftName: '',
      giftColor: '',
      giftSize: '',
      giftQuantity: 0,
      exchangedExpense: '',
    },
    code: null,
    msg: '',
    errors: {},
    isFetch: false,
  },
  exchangeGiftVerificationListRsp: {
    data: {},
  },
  exchangeGiftVerificationDetailRsp: {
    data: {
      giftRecordObjId: '',
      status: 0,
      statusDescription: '',
      exchangedDate: '',
      exchangedTime: '',
      exchangedWay: '',
      gotGiftValidPeriod: '',
      gotGiftTime: '',
      gotGiftWay: '',
      gotGiftPlace: '',
      customClientId: '',
      memberName: '',
      memberCellPhone: '',
      memberLevel: '',
      giftCode: '',
      giftCustomId: '',
      giftName: '',
      giftColor: '',
      giftSize: '',
      giftQuantity: 0,
      exchangedExpense: '',
    },
    isFetch: false,
  },
}

// 好禮核銷 - 核銷設定資訊
const getValidateCodeSettingThunk = createAsyncThunk(
  `${GIFT_LIST}/getValidateCodeSetting`,
  async (payload, { rejectWithValue }) => {
    try {
      const rsp = await getValidateCodeSetting(payload)
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 專屬好禮 - 完整名單
const getVipGiftExchangeRecordListThunk = createAsyncThunk(
  `${GIFT_LIST}/getVipGiftExchangeRecordList`,
  async (payload, { rejectWithValue }) => {
    try {
      const rsp = await getVipGiftExchangeRecord(payload)
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 贈品兌換 - 完整名單
const getGiftExchangeRecordListThunk = createAsyncThunk(
  `${GIFT_LIST}/getGiftExchangeRecordList`,
  async (payload, { rejectWithValue }) => {
    try {
      const rsp = await getGiftExchangeRecord(payload)
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 好禮核銷 - 完整名單
const getVipGiftVerificationListThunk = createAsyncThunk(
  `${GIFT_LIST}/getVipGiftVerificationList`,
  async (payload, { rejectWithValue }) => {
    try {
      const rsp = await getVipGiftReimburse(payload)
      return rsp.data
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 贈品核銷 - 完整名單
const getExchangeGiftVerificationListThunk = createAsyncThunk(
  `${GIFT_LIST}/getExchangeGiftVerificationList`,
  async (body, { rejectWithValue }) => {
    try {
      const rsp = await getGiftReimburse(body)
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 贈品兌換 - 詳情
const getGiftExchangeRecordDetailThunk = createAsyncThunk(
  `${GIFT_LIST}/getGiftExchangeRecordDetail`,
  async ({ giftRecordObjId, brandId }, { rejectWithValue }) => {
    try {
      const rsp = await getGiftExchangeRecordDetail({
        giftRecordObjId,
        brandId,
      })
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 專屬好禮 - 詳情
const getVipGiftExchangeRecordDetailThunk = createAsyncThunk(
  `${GIFT_LIST}/getVipGiftExchangeRecordDetail`,
  async ({ giftRecordObjId, brandId }, { rejectWithValue }) => {
    try {
      const rsp = await getVipGiftExchangeRecordDetail({
        giftRecordObjId,
        brandId,
      })
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 出貨名單 - 詳情
const getGiftDeliveryDetailThunk = createAsyncThunk(
  `${GIFT_LIST}/getGiftDeliveryDetail`,
  async ({ giftRecordObjId, brandId }, { rejectWithValue }) => {
    try {
      const rsp = await getGiftDeliveryDetail({
        giftRecordObjId,
        brandId,
      })
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 好禮核銷 - 詳情
const getVipGiftVerificationDetailThunk = createAsyncThunk(
  `${GIFT_LIST}/getVipGiftVerificationDetail`,
  async ({ giftRecordObjId, brandId }, { rejectWithValue }) => {
    try {
      const rsp = await getVipGiftReimburseDetail({
        giftRecordObjId,
        brandId,
      })
      return rsp.data
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 贈品核銷 - 詳情
const getExchangeGiftVerificationDetailThunk = createAsyncThunk(
  `${GIFT_LIST}/getExchangeGiftVerificationDetail`,
  async ({ giftRecordObjId, brandId }, { rejectWithValue }) => {
    try {
      const rsp = await getGiftReimburseDetail({
        giftRecordObjId,
        brandId,
      })
      return rsp.data
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 好禮核銷 - 核銷 API
const patchVipGiftVerificationThunk = createAsyncThunk(
  `${GIFT_LIST}/patchVipGiftVerification`,
  async (body, { rejectWithValue }) => {
    try {
      const rsp = await patchVipGiftReimburse(body)
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

// 贈品核銷 - 核銷 API
const patchGiftVerificationThunk = createAsyncThunk(
  `${GIFT_LIST}/patchGiftVerification`,
  async (body, { rejectWithValue }) => {
    try {
      const rsp = await patchGiftReimburse(body)
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

export {
  getValidateCodeSettingThunk,
  getVipGiftExchangeRecordListThunk,
  getGiftExchangeRecordListThunk,
  getVipGiftVerificationListThunk,
  getGiftExchangeRecordDetailThunk,
  getVipGiftExchangeRecordDetailThunk,
  getGiftDeliveryDetailThunk,
  getVipGiftVerificationDetailThunk,
  patchVipGiftVerificationThunk,
  getExchangeGiftVerificationListThunk,
  getExchangeGiftVerificationDetailThunk,
  patchGiftVerificationThunk,
}
