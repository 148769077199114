import PropTypes from 'prop-types'
import { useState, useEffect, useCallback, useMemo } from 'react'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import { useDispatch, useSelector } from 'react-redux'
import { getGiftDelivery } from 'api/ApiMain'
import { parserTableData } from 'components/pages/Gift/ListGoodsListHelper'
import { Wrapper } from 'components/pages/Gift/ListGoodsListStyle'
import { COMPONENT_PERMISSIONS } from 'constant'
import { authAccountSelector } from 'store/auth/authSelector'
import { getGiftDeliveryDetailThunk } from 'store/gift-list/giftListAsync'
import {
  resetDetailModal,
  giftDeliveryDetailSelector,
} from 'store/gift-list/giftListSlice'
import { updateDataLoading } from 'store/global/globalSlice'
import { isPermissionAllowed } from 'store/permission/permissionSlice'
import {
  GoodsListReady,
  GoodsListAll,
  FormEmailModal,
  DetailModal,
} from './ListGoodsListSubComponents'

const initTablePayload = {
  p: 1,
  ps: 25,
  sf: 'giftRecordObjId',
  so: 'desc',
}
const initTableData = {
  list: [],
  total: 0,
  unshippedCount: 0,
}

function ListGoodsList({ searchPayload, tabValue }) {
  const dispatch = useDispatch()
  const { brandId } = useSelector(authAccountSelector)
  const isShowEmailNoticeButton = useSelector(
    isPermissionAllowed(COMPONENT_PERMISSIONS.giftShippingList.UPDATE)
  )
  const deliveryDetail = useSelector(giftDeliveryDetailSelector)

  const [tablePayload, setTablePayload] = useState(initTablePayload)
  const [tableData, setTableData] = useState(initTableData)
  const [isShowFormEmail, setShowFormEmail] = useState(false)
  const [isShowDetailModal, setIsShowDetailModal] = useState(false)

  // 結合tablePayload ＆ searchPayload
  const apiPayload = useMemo(
    () => ({
      brandId,
      state: 'unshipped',
      ...tablePayload,
      ...searchPayload,
    }),
    [brandId, searchPayload, tablePayload]
  )

  const handleTabChange = (eventKey) => {
    setTablePayload({
      ...tablePayload,
      state: eventKey === 'ready' ? 'unshipped' : undefined,
    })
  }

  const handleDetailModalClose = () => {
    setIsShowDetailModal(false)
    dispatch(resetDetailModal())
  }

  /* 呼叫詳細資料 API 並打開 Modal */
  const handleClick = (row) => {
    const { giftRecordObjId } = row
    setIsShowDetailModal(true)
    dispatch(getGiftDeliveryDetailThunk({ giftRecordObjId, brandId }))
  }

  const search = useCallback(async () => {
    if (tabValue !== 'goodsList' || !brandId) return

    try {
      dispatch(updateDataLoading(true))
      setTableData(initTableData)
      const res = await getGiftDelivery(apiPayload)
      const data = parserTableData(res?.data?.data?.rows ?? [])
      const total = res?.data?.data?.totalSize || 0
      const unshippedCount = res?.data?.data?.unshippedCount || 0
      setTableData({ list: data, total, unshippedCount })
    } catch (error) {
      setTableData({ list: [], total: 0, unshippedCount: 0 })
    } finally {
      dispatch(updateDataLoading(false))
    }
  }, [tabValue, brandId, dispatch, apiPayload])

  const handleModalOpen = () => setShowFormEmail(true)
  const handleModalClose = () => setShowFormEmail(false)

  useEffect(() => {
    search()
  }, [search])

  return (
    <Wrapper>
      <div className="tab-content-inner-nav">
        <Tab.Container defaultActiveKey="ready" onSelect={handleTabChange}>
          <Nav>
            <Nav.Item>
              <Nav.Link
                // 發生若modal開啟時，tab沒有disdabled將會導致modal內的表單欄位，無法使用方向鍵，因此才做此處理
                disabled={isShowFormEmail}
                eventKey="ready"
                className="fz14"
              >
                未出貨 ({tableData?.unshippedCount})
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                disabled={isShowFormEmail}
                eventKey="all"
                className="fz14"
              >
                全部
              </Nav.Link>
            </Nav.Item>
            <EmailNoticeButton
              isShow={isShowEmailNoticeButton}
              onClick={handleModalOpen}
            />
            <FormEmailModal
              show={isShowFormEmail}
              brandId={brandId}
              onClose={handleModalClose}
            />
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="ready">
              <GoodsListReady
                apiPayload={apiPayload}
                tablePayload={tablePayload}
                setTablePayload={setTablePayload}
                tableData={tableData}
                search={search}
                onClick={handleClick}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="all">
              <GoodsListAll
                apiPayload={apiPayload}
                tablePayload={tablePayload}
                setTablePayload={setTablePayload}
                tableData={tableData}
                search={search}
                onClick={handleClick}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <DetailModal
        show={isShowDetailModal}
        data={deliveryDetail}
        brandId={brandId}
        onClose={handleDetailModalClose}
        search={search}
        isDefaultDataLoading={!deliveryDetail.isFetch}
      />
    </Wrapper>
  )
}

function EmailNoticeButton({ isShow, onClick }) {
  const handleClick = () => onClick()
  return (
    isShow && (
      <span className="send-email fz14" onClick={handleClick}>
        寄送電子郵件通知
      </span>
    )
  )
}

export default ListGoodsList

ListGoodsList.propTypes = {
  searchPayload: PropTypes.shape({}).isRequired,
  tabValue: PropTypes.string.isRequired,
}

EmailNoticeButton.propTypes = {
  isShow: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
