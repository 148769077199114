import { useRef } from 'react'
import { toast } from 'react-hot-toast'

/* 
------------------------------------
功能說明
------------------------------------
1. 處理想要執行字串複製功能


------------------------------------
hook 初值說明
------------------------------------
參數1:String
複製成功要呈現的toast文字

------------------------------------
參數說明
------------------------------------
inputRef:useRef
要綁定在原生input的ref
※ 此為支援舊版剪貼簿複製功能，使用時必須先創立一個原生input
例如 ：<input ref={inputRef} />

onClipBoard:Function
要觸發的剪貼簿複製功能

  - function 參數1：
    targetText:String  //要複製的目標文字

*/

const useClipboard = (successText = '已複製') => {
  const inputRef = useRef()

  const onClipBoard = (targetText) => {
    // 支援navigator.clipboard 且 不是android 的裝置
    if (navigator?.clipboard && !/Android/i.test(navigator.userAgent)) {
      navigator.clipboard.writeText(targetText).then(() => {
        toast.success(successText)
      })
    }
    // 不支援navigator.clipboard的另一個複製方法
    else {
      inputRef.current.type = 'text'
      inputRef.current.focus()
      inputRef.current.select()
      document.execCommand('copy')
      inputRef.current.type = 'hidden'
      toast.success(successText)
    }
  }
  return {
    inputRef,
    onClipBoard,
  }
}
export default useClipboard
