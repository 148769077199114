import PropTypes from 'prop-types'
import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Input } from 'components/units'
import { regexRuleNormal } from 'helpers/validation'

// 欄位對應的驗證規則要顯示的文字
const fieldRules = {
  password: {
    required: '尚未填寫',
    pattern: '請輸入符合密碼格式的字元',
  },
  passwordCheck: {
    required: '尚未填寫',
    pattern: '兩次密碼不相符',
  },
}
function PasswordSettingForm({ onSubmit }) {
  // 表單資料
  const [formData, setFormData] = useState({
    password: '',
    passwordCheck: '',
  })
  // 表單form 是否開啟欄位驗證
  const [validated, setValidated] = useState(false)
  // 欄位errors
  const [fieldsError, setFieldsError] = useState({
    password: fieldRules?.password?.required,
    passwordCheck: fieldRules?.passwordCheck?.required,
  })

  const handleInputChange = (e) => {
    // 根據輸入更新錯誤訊息
    const { name, value } = e.target
    const isValueEmpty = value === ''
    let feedbackText = ''

    // 是否為空值
    if (isValueEmpty) {
      feedbackText = fieldRules?.[name]?.required
    } else {
      feedbackText = fieldRules?.[name]?.pattern
    }

    // 確認兩次密碼是否相同
    const isSamePassword =
      (name === 'passwordCheck' && value === formData.password) ||
      (name === 'password' && value === formData.passwordCheck)
    const passwordCheckFeedbackText = isSamePassword
      ? ''
      : fieldRules?.passwordCheck?.pattern

    // 更新錯誤訊息
    setFieldsError({
      ...fieldsError,
      [name]: feedbackText,
      passwordCheck: passwordCheckFeedbackText,
    })

    // 更新表單資料
    setFormData({
      ...formData,
      [name]: `${value}`,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // 表單開啟欄位驗證
    setValidated(true)
    const isValidated = e.currentTarget.checkValidity()
    if (isValidated && formData?.password === formData?.passwordCheck) {
      onSubmit(formData)
    }
  }

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      data-testid="login-form"
    >
      <Form.Group>
        <Input
          formControlOption={{
            required: true,
            pattern: `${regexRuleNormal.password}`,
            className: 'input-account',
            type: 'password',
            name: 'password',
            placeholder: '設定密碼',
            autoComplete: 'current-password',
            onChange: handleInputChange,
          }}
          feedbackText={fieldsError?.password}
          helpertext="請混合使用 10 碼以上的大小寫英文、數字或符號"
        />
      </Form.Group>
      <Form.Group>
        <Input
          formControlOption={{
            required: true,
            isInvalid: validated && !!fieldsError?.passwordCheck,
            className: 'input-password',
            type: 'password',
            name: 'passwordCheck',
            placeholder: '確認密碼',
            autoComplete: 'current-password',
            onChange: handleInputChange,
          }}
          feedbackText={fieldsError?.passwordCheck}
        />
      </Form.Group>
      <Button className="login-btn w-100" type="submit">
        儲存
      </Button>
    </Form>
  )
}

export default PasswordSettingForm

PasswordSettingForm.propTypes = {
  onSubmit: PropTypes.func,
}

PasswordSettingForm.defaultProps = {
  onSubmit: () => {},
}
