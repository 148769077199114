import { useState } from 'react'
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import { Tooltip, Input, Button, Modal } from 'components/units'
import { number } from 'helpers/format'
import { ReactComponent as TooltipSvg } from 'assets/images/icon_tooltip.svg'
import {
  CaptionWrapper,
  MemberModalWrapper,
  TextLink,
} from 'components/pages/Gift/ExchangeSendListStyle'
import {
  captionText,
  tableText,
} from 'components/pages/Gift/ExchangeSendListConfig'
import { statusFormatter } from 'helpers/tableElFormat'

const formatterMember = ({ onClick }) =>
  function renderMember(cell, data) {
    const { memberId, giftRecordObjId } = data
    const payload = {
      memberId,
      giftRecordObjId,
    }
    const handleClick = () => typeof onClick === 'function' && onClick(payload)
    return <TextLink onClick={handleClick}>{cell}</TextLink>
  }

const headerFormatterStatus = (column, colIndex, { sortElement }) => (
  <>
    {tableText.status}
    <Tooltip triggerElement={<TooltipSvg />} globalOption={{ effect: 'solid' }}>
      {tableText.statusTip1}
      <br />
      {tableText.statusTip2}
    </Tooltip>
    {sortElement}
  </>
)

const formatterStatus = (cell, row) => {
  const { statusDescription } = row
  let statusParameter = [false, false, false]
  // 代碼對應
  // 0: 未兌換
  // 1: 已兌換
  // 2: 已領取 ("已使用"，搭配 “簡訊發送” 的開始使用)
  // 10: 未出貨
  // 11: 已出貨
  // 90: 已過期
  switch (cell) {
    case 0:
      statusParameter = [false, false, true]
      break
    case 1:
      break
    case 2:
      statusParameter = [true, false, false]
      break
    case 10:
      break
    case 11:
      statusParameter = [true, false, false]
      break
    case 90:
      statusParameter = [false, false, true]
      break
  }
  return statusFormatter(statusDescription, ...statusParameter)
}

function Caption({ paginationProps, onSubmit, total = 0 }) {
  const [text, setText] = useState('')
  const handleSubmit = () => {
    if (typeof onSubmit === 'function') onSubmit(text)
  }

  return (
    <CaptionWrapper>
      <div className="caption-search">
        <div className="caption-label">{captionText.phone}</div>
        <Input
          formControlOption={{
            value: text,
            onChange: (e) => setText(e.target.value),
          }}
        />
        <Button
          className="caption-btn"
          size="sm"
          variant="primary"
          onClick={handleSubmit}
        >
          {captionText.search}
        </Button>
        <Button
          size="sm"
          variant="outline-darkerGray"
          onClick={() => setText('')}
        >
          {captionText.clear}
        </Button>
      </div>
      <div className="caption-total">
        <div>
          <div className="caption-label">{captionText.totalLabel}</div>
          <div>{captionText.total(number(total))}</div>
        </div>
        <div>
          <div className="caption-color mr-1">{captionText.page}</div>
          <SizePerPageDropdownStandalone
            {...paginationProps}
            sizePerPage={`${paginationProps.sizePerPage} 筆`}
          />
        </div>
      </div>
    </CaptionWrapper>
  )
}

function MemberInfoModal({
  info = {},
  show = false,
  onClose = () => {},
  onConfirm = () => {},
}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      onConfirm={() => onConfirm(info.customerId)}
      titleText="會員資訊"
      confirmBtnText="詳情"
      closeBtnText="關閉"
      confirmBtnVisible={info.isMemberExist}
      isDefaultDataLoading={!info.isReady}
    >
      <MemberModalWrapper>
        <h5 className="member-title">兌換紀錄</h5>
        <div className="member-info">
          <div className="member-label">會員編號：</div>
          <div className="member-content">{info.customerId}</div>
        </div>
        <div className="member-info">
          <div className="member-label">姓名：</div>
          <div className="member-content">{info.name}</div>
        </div>
        <div className="member-info">
          <div className="member-label">手機：</div>
          <div className="member-content">{info.phone}</div>
        </div>
        <div className="member-info">
          <div className="member-label">會員等級：</div>
          <div className="member-content">{info.level}</div>
        </div>
        {info.isMemberExist && (
          <>
            <hr />
            <h5 className="member-title">
              會員輪廓<span className="member-note">最新會員資料</span>
            </h5>
            <div className="member-info">
              <div className="member-label">生日：</div>
              <div className="member-content">
                {info.age} 歲
                <span className="member-note">{info.yearMonth}</span>
              </div>
            </div>
            <div className="member-info">
              <div className="member-label">性別：</div>
              <div className="member-content">{info.gender}</div>
            </div>
            <div className="member-info">
              <div className="member-label">剩餘點數：</div>
              <div className="member-content">{info.pointBalance}</div>
            </div>
            <div className="member-info">
              <div className="member-label">客單價：</div>
              <div className="member-content">{info.customerUnitPrice}</div>
            </div>
            <div className="member-info">
              <div className="member-label">上次消費日：</div>
              <div className="member-content">{info.lastDealDate}</div>
            </div>
            <div className="member-info">
              <div className="member-label">入會時間：</div>
              <div className="member-content">{info.registeredDate}</div>
            </div>
            <div className="member-info">
              <div className="member-label">入會門店：</div>
              <div className="member-content">{info.shopName}</div>
            </div>
          </>
        )}
      </MemberModalWrapper>
    </Modal>
  )
}

export {
  Caption,
  MemberInfoModal,
  formatterMember,
  headerFormatterStatus,
  formatterStatus,
}
