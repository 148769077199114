import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Form, Col, Row, Container, Tabs, Tab } from 'react-bootstrap'
import { ReactComponent as IconCancel } from 'assets/images/button/icon_cancel.svg'
import { ReactComponent as IconSave } from 'assets/images/button/icon_save.svg'
import { colorObj } from 'assets/styles/Variable/Color'
import NavSection from 'components/pages/SystemConfiguration/account-management-content/widgets/NavSection'
import SideSection from 'components/pages/SystemConfiguration/account-management-content/widgets/SideSection'
import { Basic } from 'components/templates'
import { Input, Checkbox, Select } from 'components/units'
import { StyleLoader } from 'components/units/Loader'
import PlainButton from 'components/units/PlainButton'
import { PAGE_TYPE } from 'constant/common'
import { regexRuleNormal } from 'helpers/validation'
import LocalStyle, {
  CustomFieldWrapper,
  CheckboxsWrapper,
  CustomFeedback,
  IconInputWrapper,
} from './AccountFormStyle'

const pageTitles = {
  [PAGE_TYPE.new]: '新增帳號',
  [PAGE_TYPE.edit]: '編輯帳號',
}

function FormLabelTitle({ title }) {
  return (
    <Form.Label className="label-title">
      <span>
        {title}
        <span className="warn">*</span>：
      </span>
    </Form.Label>
  )
}

function EmailValitdationIcon({ isShow, isSuccess }) {
  if (!isShow) return null
  return isSuccess ? (
    <IconSave className="email-enabled" />
  ) : (
    <IconCancel className="email-disabled" />
  )
}

function AccountForm({
  onSubmit,
  onSelectShop,
  onOpenWarnModal,
  onOpenPermissionModal,
  onEmailValidation,
  onResetEmailValidation,
  optionData,
  accountData,
  isEditDisabled,
  pageType,
  isLoading,
  emailValidation,
}) {
  const { shops, roles, selectedShops } = optionData
  const emailValidatedRef = useRef(false)
  const [validated, setValidated] = useState(false)
  const [emailValidated, setEmailValidated] = useState(false)
  const [validationStatus, setValidationStatus] = useState({
    email: null, // 兩種情形："尚未填寫"、"請輸入正確的 Email 格式"
    name: '尚未填寫',
    role: '尚未填寫',
    shopList: '尚未填寫',
  })
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    role: { code: '', name: '' },
    shopList: [],
  })
  const isNewPageWithEmailInvalid =
    pageType === 'new' && !emailValidation.isSuccess
  const formConfig = {
    email: {
      label: '帳號',
      placeholder: 'Email 信箱',
      maxLength: 128,
    },
    name: {
      label: '名稱',
      placeholder: '使用者名稱',
      maxLength: 128,
      hint: '上限 128 字',
    },
    role: {
      label: '角色',
      placeholder: '請選擇',
      dropdownDisplay: formData.role?.code,
    },
    shop: { label: '所屬門店' },
  }

  const feedbackTextTrigger = {
    emailInvalid:
      (validated && validationStatus.email) ||
      (emailValidated && validationStatus.email) ||
      emailValidation.isSuccess === false,
    emailDuplicate:
      emailValidation.isSuccess === false ||
      (emailValidation.isSuccess && emailValidation.name),
    nameInvalid: validated && !formData.name,
    roleInvalid: validated && !formData.role.code,
    shopListInvalid: validated && !selectedShops.length,
  }

  const emailValidationRules = {
    required: '尚未填寫',
    format: '請輸入正確的 Email 格式',
  }

  const isValueEmpty = (field, value) => {
    if (field === 'email' || field === 'name') {
      return !value
    }
    if (field === 'shopList') {
      return value.length === 0
    }
    if (field === 'role') return !value.code
    return false
  }

  const isInvalidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+$/
    return !emailRegex.test(email)
  }

  const checkValidation = ({
    submitFormData = {},
    fieldValue = null,
    fieldType = null,
  }) => {
    const validationResult = { ...validationStatus }
    let isFormValid = true

    const validateSingleField = (field, value) => {
      // email 欄位有多種錯誤訊息，所以依照錯誤狀況分別處理
      if (field === 'email') {
        if (isValueEmpty(field, value)) {
          isFormValid = false
          validationResult.email = emailValidationRules.required
        } else if (isInvalidEmail(value)) {
          isFormValid = false
          validationResult.email = emailValidationRules.format
        } else {
          validationResult.email = null
        }
      }
      // 其他欄位僅需判斷是否為空值
      else if (isValueEmpty(field, value)) {
        isFormValid = false
      }
      return validationResult
    }
    // 單一欄位驗證
    if (fieldType) {
      validateSingleField(fieldType, fieldValue)
    }
    // 全表單驗證
    else {
      Object.entries(submitFormData).forEach(([field, value]) => {
        validateSingleField(field, value)
      })
    }

    setValidationStatus(validationResult)
    return isFormValid
  }

  const handleSubmit = (e) => {
    // 由於全門市不是真正的門市選項，所以送出表單前先移除
    const cleanSelectedShops = selectedShops.filter(
      (shop) => shop.code !== 'all'
    )
    const submitFormData = {
      ...formData,
      shopList: cleanSelectedShops,
      email: formData.email.trim(),
      name: formData.name.trim(),
    }
    setFormData(submitFormData)
    e.preventDefault()
    e.stopPropagation()
    const isFormValidated = checkValidation({ submitFormData })
    setValidated(true) // 啟動 bootstrap 表單驗證:輸入框驗證樣式＆驗證文字
    // 檢查 form data 皆符合規範且不重複才送出
    if (isNewPageWithEmailInvalid) return
    if (isFormValidated) {
      onSubmit(submitFormData)
      onResetEmailValidation()
    }
  }

  const handleDropdownSelect = (e, value) => {
    setFormData({
      ...formData,
      role: { code: value, name: e.target.text },
    })
  }

  const handleSelectShop = (e) => {
    onSelectShop({ shopCode: e.target.id })
  }

  const handleInputChange = (e) => {
    const fieldType = e.target.id
    const fieldValue = e.target.value
    if (fieldType === 'email' && emailValidation.isSuccess !== null) {
      onResetEmailValidation()
    }
    setFormData({ ...formData, [fieldType]: fieldValue })
    checkValidation({ fieldType, fieldValue })
  }

  const handleEmailValidation = () => {
    const isFormatCorrect = checkValidation({
      fieldType: 'email',
      fieldValue: formData.email,
    })
    setEmailValidated(true)
    if (isFormatCorrect) {
      onEmailValidation(formData.email)
      emailValidatedRef.current = true
    }
  }

  useEffect(() => {
    if (pageType === PAGE_TYPE.edit && Object.keys(accountData).length) {
      setFormData({
        ...accountData,
      })
    }
  }, [accountData, pageType])

  useEffect(() => {
    if (pageType === PAGE_TYPE.new && emailValidatedRef.current) {
      setFormData({
        ...formData,
        name: emailValidation.isSuccess ? emailValidation.name : '',
      })
      emailValidatedRef.current = false
    }
  }, [emailValidation, formData, pageType])

  return (
    <Basic
      navSection={() =>
        NavSection({
          handleEvent: onOpenWarnModal,
          pageTitle: pageTitles[pageType],
        })
      }
      sideSection={() =>
        SideSection({ onSubmit: handleSubmit, onCancel: onOpenWarnModal })
      }
    >
      <LocalStyle>
        <Container>
          <Row className="align-items-end" />
          <Row>
            <Col>
              <Form noValidate validated={validated}>
                <Tabs defaultActiveKey="basicInfo" id="noanim-tab-example">
                  <Tab eventKey="basicInfo" title="基本資料">
                    <StyleLoader isHide={!isLoading} />
                    <div className="tab-content-inner-nav">
                      <Tab.Container>
                        <Tab.Content>
                          <Form.Group controlId="email">
                            <FormLabelTitle title={formConfig.email.label} />
                            <CustomFieldWrapper>
                              <IconInputWrapper
                                isShow={emailValidation.isSuccess !== null}
                              >
                                <Input
                                  formControlOption={{
                                    type: 'email',
                                    maxLength: formConfig.email.maxLength,
                                    pattern: regexRuleNormal.email,
                                    name: 'account-email',
                                    autoComplete: 'account-email',
                                    placeholder: formConfig.email.placeholder,
                                    onChange: handleInputChange,
                                    isInvalid: feedbackTextTrigger.emailInvalid,
                                    onBlur: handleEmailValidation,
                                    value: formData.email,
                                    disabled: isEditDisabled,
                                  }}
                                  feedbackText={validationStatus.email}
                                  label="Email"
                                  type="email"
                                  id="email"
                                />
                                <EmailValitdationIcon
                                  isShow={emailValidation.isShow}
                                  isSuccess={emailValidation.isSuccess}
                                />
                              </IconInputWrapper>
                              {feedbackTextTrigger.emailDuplicate && (
                                <CustomFeedback
                                  isWarn={emailValidation.isSuccess === false}
                                >
                                  {emailValidation.validationMsg}
                                </CustomFeedback>
                              )}
                            </CustomFieldWrapper>
                          </Form.Group>
                          <Form.Group controlId="name">
                            <FormLabelTitle title={formConfig.name.label} />
                            <CustomFieldWrapper>
                              <Input
                                formControlOption={{
                                  type: 'text',
                                  maxLength: formConfig.name.maxLength,
                                  name: 'account-name',
                                  autoComplete: 'account-name',
                                  placeholder: formConfig.name.placeholder,
                                  onChange: handleInputChange,
                                  isInvalid: feedbackTextTrigger.nameInvalid,
                                  value: formData.name,
                                  disabled:
                                    emailValidation.isSuccess &&
                                    emailValidation.name,
                                }}
                                feedbackText={validationStatus.name}
                                label="name"
                                type="text"
                                id="name"
                              />
                              <CustomFeedback>上限 128 字</CustomFeedback>
                            </CustomFieldWrapper>
                          </Form.Group>
                          <div className="divider" />
                          <Form.Group controlId="role" required>
                            <FormLabelTitle title={formConfig.role.label} />
                            {roles && (
                              <Select
                                className="select-wrapper"
                                optionItems={roles}
                                selectedValue={formConfig.role.dropdownDisplay}
                                onChange={handleDropdownSelect}
                                formControlOption={{
                                  isInvalid: feedbackTextTrigger.roleInvalid,
                                  placeholder: formConfig.role.placeholder,
                                }}
                                feedbackText={validationStatus.role}
                              />
                            )}
                            <PlainButton
                              onClick={onOpenPermissionModal}
                              style={{
                                underline: true,
                                color: colorObj.primary,
                                fontWeight: '500',
                              }}
                            >
                              檢視角色權限表
                            </PlainButton>
                          </Form.Group>
                          <Form.Group controlId="shop">
                            <FormLabelTitle title={formConfig.shop.label} />
                            <CustomFieldWrapper>
                              <CheckboxsWrapper>
                                {shops.map((shop) => (
                                  <Checkbox
                                    key={`create-account-shop-${shop.code}`}
                                    label={shop.name}
                                    id={shop.code}
                                    checked={selectedShops.some(
                                      (selectedShop) =>
                                        selectedShop.code === shop.code
                                    )}
                                    onChange={handleSelectShop}
                                  />
                                ))}
                              </CheckboxsWrapper>
                              {feedbackTextTrigger.shopListInvalid && (
                                <CustomFeedback isWarn>
                                  {validationStatus.shopList}
                                </CustomFeedback>
                              )}
                            </CustomFieldWrapper>
                          </Form.Group>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Tab>
                </Tabs>
              </Form>
            </Col>
          </Row>
        </Container>
      </LocalStyle>
    </Basic>
  )
}

export default AccountForm
AccountForm.defaultProps = {
  onSubmit: () => {},
  onSelectShop: () => {},
  onEmailValidation: () => {},
  onResetEmailValidation: () => {},
  onOpenWarnModal: () => {},
  onOpenPermissionModal: () => {},
  optionData: {
    roles: [],
    shops: [],
    selectedShops: [],
  },
  accountData: {
    email: '',
    name: '',
    role: {
      code: '',
      name: '',
    },
    shopList: [],
  },
  isEditDisabled: false,
  pageType: '',
  isLoading: false,
  emailValidation: {
    isSuccess: null,
    name: '',
    validationMsg: '',
    isShow: false,
  },
}

AccountForm.propTypes = {
  onSubmit: PropTypes.func,
  onSelectShop: PropTypes.func,
  onEmailValidation: PropTypes.func,
  onOpenWarnModal: PropTypes.func,
  onOpenPermissionModal: PropTypes.func,
  onResetEmailValidation: PropTypes.func,
  optionData: PropTypes.shape({
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    shops: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    selectedShops: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  accountData: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
    shopList: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  isEditDisabled: PropTypes.bool,
  pageType: PropTypes.string,
  isLoading: PropTypes.bool,
  emailValidation: PropTypes.shape({
    isSuccess: PropTypes.bool,
    name: PropTypes.string,
    validationMsg: PropTypes.string,
    isShow: PropTypes.bool,
  }),
}
FormLabelTitle.defaultProps = {
  title: '',
}
FormLabelTitle.propTypes = {
  title: PropTypes.string,
}
EmailValitdationIcon.defaultProps = {
  isShow: false,
  isSuccess: false,
}
EmailValitdationIcon.propTypes = {
  isShow: PropTypes.bool,
  isSuccess: PropTypes.bool,
}
FormLabelTitle.defaultProps = {
  title: '',
}
FormLabelTitle.propTypes = {
  title: PropTypes.string,
}
EmailValitdationIcon.defaultProps = {
  isShow: false,
  isSuccess: false,
}
EmailValitdationIcon.propTypes = {
  isShow: PropTypes.bool,
  isSuccess: PropTypes.bool,
}
