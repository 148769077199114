import {
  formatterMember,
  headerFormatterStatus,
  formatterStatus,
} from 'components/pages/Gift/ExchangeSendListSubComponents'

const paginationOptions = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

const statusType = {
  exchanged: '已兌換',
  notExchanged: '未兌換',
  received: '已領取',
  sent: '已出貨',
  notSent: '未出貨',
}

const captionText = {
  phone: '手機：',
  search: '查詢',
  clear: '清除條件',
  totalLabel: '總項目：',
  total: (number) => `共 ${number} 筆`,
  page: '每頁',
}

const tableText = {
  no: 'No.',
  member: '會員',
  phone: '手機',
  exchangedDate: '兌換時間',
  status: '狀態',
  statusTip1: '依回傳資料',
  statusTip2: '更新內容與狀態',
  empty: '尚無資料',
  loading: '載入中...',
}

const defaultSort = {
  sf: 'no',
  so: 'desc',
}

const columns = (columnsObj = {}) => [
  { dataField: 'no', text: tableText.no, sort: true },
  {
    dataField: 'memberName',
    text: tableText.member,
    formatter: formatterMember(columnsObj),
  },
  { dataField: 'memberCellPhone', text: tableText.phone },
  { dataField: 'exchangedDateTime', text: tableText.exchangedDate, sort: true },
  {
    dataField: 'status',
    text: '',
    headerFormatter: headerFormatterStatus,
    formatter: formatterStatus,
    sort: true,
    headerClasses: 'setting-no-status',
  },
]

export {
  paginationOptions,
  statusType,
  captionText,
  tableText,
  defaultSort,
  columns,
}
