import styled, { css } from 'styled-components'
import { Fragment } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { colorObj, colorCommon, method } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const { colorHandle } = method

// dropdown共幾種尺寸
const dropdownSize = {
  lg: {
    fontSize: 15,
    height: 32,
  },
  sm: {
    fontSize: 14,
    height: 24,
  },
}

// dropdown size樣式處理
const handleSizeStyles = ({ size, framed }) => {
  size = size || 'lg'
  let cssStyle = ''
  for (const type in dropdownSize) {
    const { height, fontSize } = dropdownSize[type]
    if (size === type) {
      cssStyle += `
                button {
                  height:${framed ? height - 2 : height}px;
                  font-size:${fontSize}px;
                  line-height: 1;
                  ${framed ? 'padding: 0px 16px' : ''}
                  
                }
            `
    }
  }
  return css`
    ${cssStyle}
  `
}

// Dropdown 共用樣式
const dropdownCommonStyles = ({ colorType, colorAlpha, framed = false }) => {
  let cssStyle = ''
  cssStyle += `
        .dropdown{
            position:relative;
            display: inline-flex;
            min-width: auto;
            justify-content:flex-end;
            border: ${framed ? '1px' : '0px'} solid 
            ${
              colorType
                ? colorHandle(colorType, colorObj, colorAlpha)
                : colorObj.dark
            };
            border-radius: 4px;
            
            .dropdown-toggle{
                color:${
                  colorType
                    ? colorHandle(colorType, colorObj, colorAlpha)
                    : colorObj.dark
                };
                background-color:transparent;
                border-radius:2px;
                border-color:${colorCommon.borderGray};
                box-shadow:none !important;
                border:0;
                
                &:focus,
                &:active{
                    color:${
                      colorType
                        ? colorHandle(colorType, colorObj, colorAlpha)
                        : colorObj.dark
                    };
                    background-color:transparent;
                    border-radius:2px;
                    border-color:${colorCommon.borderGray};
                }
            }
            .dropdown-menu{
                width:auto;
                min-width:min-content;
                border-color:${colorCommon.borderGray};
                border-radius: 8px;
                box-shadow: 1px 2px 8px 0px rgba(51, 51, 51, 0.25);
                padding:${spacingObj.mini} 0;
                max-height:285px;
                overflow:auto;

                .dropdown-header{
                    padding:0 ${spacingObj.small};
                }
                .dropdown-item{
                    color:${colorObj.darkerGray};
                    padding:0 ${spacingObj.small};
                    min-height:36px;
                    display:flex;
                    align-items:center;

                    &:hover{
                      background-color:rgba(99,153,209,0.2);
                    }
                    &.active{
                      background-color:transparent;
                      
                      &:hover{
                        background-color:rgba(99,153,209,0.2);
                      }
                    }

                    &.disabled {
                      background-color: rgba(51, 51, 51, 0.25);
                      color: ${colorObj.gray}
                    }
                }
                .dropdown-divider{
                    width:calc(100% - (${spacingObj.small}*2));
                    margin-left:auto;
                    margin-right:auto;
                }
            }
        }
    `
  return css`
    ${cssStyle}
  `
}

// Dropdown 基礎樣式
const dropdownBaseStyles = (props) => {
  return css`
    ${dropdownCommonStyles(props)};
    ${handleSizeStyles(props)};
  `
}

function DropdownContainer({ className, children }) {
  return <div className={className}>{children}</div>
}
const StyleDropdownContainer = styled(DropdownContainer)`
  ${(props) => dropdownBaseStyles(props)};
`

function CustomDropdown(props) {
  const {
    children,
    html,
    menuItems,
    dropdownOption,
    dropdownToggleOption,
    dropdownMenuOption,
  } = props
  return (
    <Dropdown {...dropdownOption}>
      {children || (
        <>
          <Dropdown.Toggle {...dropdownToggleOption}>{html}</Dropdown.Toggle>
          <Dropdown.Menu {...dropdownMenuOption} align="right">
            {Array.isArray(menuItems) &&
              menuItems.map((item) => {
                const { text, title, divider, dropdownItemOption } = item
                return (
                  <Fragment key={`${dropdownItemOption.eventKey}`}>
                    {title && <Dropdown.Header>{title}</Dropdown.Header>}
                    <Dropdown.Item {...dropdownItemOption}>
                      {text}
                    </Dropdown.Item>
                    {divider && <Dropdown.Divider />}
                  </Fragment>
                )
              })}
          </Dropdown.Menu>
        </>
      )}
    </Dropdown>
  )
}

function StyleDropdown(props) {
  return (
    <StyleDropdownContainer {...props}>
      <CustomDropdown {...props} />
    </StyleDropdownContainer>
  )
}

export { StyleDropdown }
