import {
  commonFetchProps,
  handleError,
  getSearchParameter,
  showToast,
} from 'api/ApiCommon'
import { fetchData } from 'api/ApiRoute'

const getRoles = (brandId) => {
  return fetchData(
    `account/auth/brand/${brandId}/roles`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data
    })
    .catch(handleError)
}

const getShops = (brandId) => {
  return fetchData(
    `account/auth/brand/${brandId}/account/shops`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data
    })
    .catch(handleError)
}

const getEmailValidation = (brandId, queryStringObj, signal) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `account/auth/brand/${brandId}/account/check${searchParameter}`,
    'GET',
    null,
    { ...commonFetchProps, signal }
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}

const postAccount = (brandId, body) => {
  return fetchData(`account/auth/brand/${brandId}/account`, 'POST', body)
    .then((rsp) => {
      showToast(rsp, '新增成功')
      return rsp?.data
    })
    .catch(handleError)
}

const getAccount = (brandId, accountId) => {
  return fetchData(
    `account/auth/brand/${brandId}/account/${accountId}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data
    })
    .catch(handleError)
}

const patchAccount = (brandId, accountId, body) => {
  return fetchData(
    `account/auth/brand/${brandId}/account/${accountId}`,
    'PATCH',
    body,
    commonFetchProps
  )
    .then((rsp) => {
      showToast(rsp, '編輯成功')
      return rsp?.data
    })
    .catch(handleError)
}

export {
  getRoles,
  getShops,
  getEmailValidation,
  postAccount,
  getAccount,
  patchAccount,
}
