import PropTypes from 'prop-types'
import { useContext, useState, useEffect, useReducer, useRef } from 'react'
import { Row } from 'react-bootstrap'
import {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import { useSelector } from 'react-redux'
import { showToast } from 'api/ApiCommon'
import {
  getPointActivityListApi,
  getPointActivitySettingApi,
  stopPointActivityApi,
  deletePointActivityApi,
  getEditPointActivityApi,
} from 'api/ApiMain'
import withPermission from 'components/hocs/withPermission'
import Formatter from 'components/pages/ActivitiesCode/PointActivity/PointActivityTableFormatters'
import { Table, NoDataIndication, Col, Button } from 'components/units'
import { StyleCard } from 'components/units/Card'
import { COMPONENT_PERMISSIONS } from 'constant'
import { RootContext } from 'RootContext'
import { isPermissionAllowed } from 'store/permission/permissionSlice'
import PointActivityHintsModal from './PointActivityHintsModal'
import PointActivityListStyle from './PointActivityListStyle'
import PointActivityModal from './PointActivityModal'
import { initState, PointActivityReducer } from './PointActivityModalReducer'

/**
 * @description 點數活動頁面列表
 * @returns {string}
 * @constructor
 */
function PointActivityList({
  paginationProps,
  paginationTableProps,
  apiPayload,
  setApiPayload,
  setTotalSize,
}) {
  const [state, dispatch] = useReducer(PointActivityReducer, initState)
  const rootData = useContext(RootContext)
  const { brandId } = rootData
  const [isLoadingTableData, setIsLoadingTableData] = useState(false)
  const [tableData, setTableData] = useState([])
  const [visibleDoublePoint, setVisibleDoublePoint] = useState(false)
  const { UPDATE, DELETE } = COMPONENT_PERMISSIONS.barcodeRule
  const isShowFunctBtnCol = useSelector(
    isPermissionAllowed([...UPDATE, ...DELETE])
  )
  const displayTableData = isLoadingTableData ? [] : tableData
  const fetchPointActivityList = async () => {
    setIsLoadingTableData(true)
    const apiPayloadParam =
      apiPayload.p === 0 ? { ...apiPayload, p: 1 } : apiPayload
    const respond = await getPointActivityListApi(brandId, apiPayloadParam)
    if (respond) {
      const {
        data: {
          data: { rows, totalSize, feature },
        },
      } = respond
      setTableData(rows)
      setTotalSize(totalSize)
      setVisibleDoublePoint(feature.visibleDoublePoint)
      setIsLoadingTableData(false)
    }
  }

  const apiPayloadRef = useRef(apiPayload)

  useEffect(() => {
    if (brandId && apiPayload !== apiPayloadRef.current) {
      fetchPointActivityList()
    }
  }, [rootData?.brandId, apiPayload]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isEmptyList = 0
    if (brandId && state.barcodeMultipleList?.length === isEmptyList) {
      const fetchData = async () => {
        try {
          const respond = await getPointActivitySettingApi(brandId)
          // if api success
          if (respond?.data.code === 200) {
            dispatch({
              type: 'SET_BARCODE_MULTIPLE_LIST',
              payload: respond.data.data.pointBarcodeMultipleList,
            })
          }
        } catch (error) {
          console.log(error) //eslint-disable-line
        }
      }
      fetchData()
    }
  }, [rootData?.brandId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [isShowAddModal, setIsShowAddModal] = useState(false)

  /**
   * @description 新增活動 event
   */
  const onAddNewPointActivity = () => {
    dispatch({
      type: 'CLEAR_ALL_FILEDS',
    })
    setIsShowAddModal(true)
  }

  const [isShowEditModal, setIsShowEditModal] = useState(false)
  const [currentEditId, setCurrentEditId] = useState(null)
  /**
   * @description 編輯活動點數 event
   * @param pointBarcodeRuleId
   */
  async function onEditPointActivity(pointBarcodeRuleId) {
    try {
      const respond = await getEditPointActivityApi(brandId, pointBarcodeRuleId)

      // if api success
      if (respond?.data.code === 200) {
        setCurrentEditId(pointBarcodeRuleId)
        dispatch({
          type: 'UPDATE_EDIT_FIELDS',
          payload: respond?.data.data,
        })
        setIsShowEditModal(true)
      }
    } catch (error) {
      console.log(error) //eslint-disable-line
      setCurrentEditId(null)
    }
  }

  const [stopPointActivity, setStopPointActivity] = useState({
    isShowModal: false,
    pointBarcodeRuleId: null,
  })

  async function onStopPointActivity(brandIdParam, pointBarcodeRuleId) {
    const rsp = await stopPointActivityApi(brandIdParam, pointBarcodeRuleId)
    // if api success
    if (rsp?.data?.code === 200) {
      setStopPointActivity((prevState) => ({
        ...prevState,
        pointBarcodeRuleId: null,
      }))
      fetchPointActivityList()
    }
  }
  /**
   * @description 刪除活動事件
   * @param pointBarcodeRuleId
   * @returns {Promise<void>}
   */
  async function onDeletePointActivity(pointBarcodeRuleId) {
    try {
      setIsLoadingTableData(true)
      const rsp = await deletePointActivityApi(brandId, pointBarcodeRuleId)
      showToast(rsp, '點數活動刪除成功')
      fetchPointActivityList()
    } catch (e) {
      console.error('delete point activity error:', e)
    } finally {
      setIsLoadingTableData(false)
    }
  }
  /**
   * @description 確認關閉停用 Modal 並發送 API
   */
  const onConfirmCloseHintsModal = async (handleModalLoading) => {
    handleModalLoading(true)
    await onStopPointActivity(brandId, stopPointActivity.pointBarcodeRuleId)
    setStopPointActivity((prevState) => ({
      ...prevState,
      isShowModal: false,
    }))
    handleModalLoading(false)
  }
  /**
   * @description 關閉停用點數活動的 Modal
   */
  const onCloseHintsModal = () => {
    setStopPointActivity({
      isShowModal: false,
      pointBarcodeRuleId: null,
    })
  }
  const onTableChange = (setApiPayloadParam, type, value) => {
    if (type === 'sort') {
      setApiPayloadParam((prev) => ({
        ...prev,
        sf: value.sortField,
        so: value.sortOrder,
      }))
    }
  }
  /**
   * @description 產生 columns 配置
   * @param setStopPointActivity
   */
  const generateColumns = (isShowFuncBtnCol) => {
    return [
      {
        dataField: 'no',
        text: 'No.',
        sort: true,
        headerStyle: {
          width: '3%',
        },
        style: (_cell, row) => {
          return {
            backgroundColor:
              row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
          }
        },
      },
      {
        dataField: 'barcode',
        text: '活動條碼',
        sort: false,
        formatter: Formatter.barcodeColumn,
        headerStyle: {
          width: '10%',
        },
        style: (_cell, row) => {
          return {
            backgroundColor:
              row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
          }
        },
      },
      {
        dataField: 'name',
        text: '名稱',
        sort: false,
        formatter: Formatter.nameColumn,
        headerStyle: {
          width: '33%',
        },
        style: (_cell, row) => {
          return {
            backgroundColor:
              row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
          }
        },
      },
      {
        dataField: 'validPeriod',
        text: '效期',
        sort: true,
        formatter: Formatter.nameColumn,
        headerStyle: {
          width: '14%',
        },
        style: (_cell, row) => {
          return {
            backgroundColor:
              row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
          }
        },
      },
      {
        dataField: 'content',
        text: '內容',
        sort: false,
        formatter: Formatter.contentColumn,
        headerStyle: {
          width: '20%',
        },
        style: (_cell, row) => {
          return {
            backgroundColor:
              row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
          }
        },
      },
      {
        dataField: 'state',
        text: '狀態',
        sort: true,
        formatter: Formatter.statusColumn,
        headerStyle: {
          width: '10%',
        },
        style: (_cell, row) => {
          switch (row.state) {
            case '進行中':
              return {
                color: '#3ca078',
                backgroundColor:
                  row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
              }
            case '準備中':
              return {
                color: '#333333',
                backgroundColor:
                  row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
              }
            case '已停用':
            case '已過期':
              return {
                backgroundColor:
                  row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
              }
            default:
              return {
                color: 'rgba(51, 51, 51, 0.25)',
              }
          }
        },
      },
      {
        dataField: 'funcButton',
        text: '',
        hidden: !isShowFuncBtnCol,
        headerStyle: {
          width: '10%',
        },
        style: (_cell, row) => {
          return {
            backgroundColor:
              row.pointProductCount === 0 ? 'rgba(220, 60, 80, 0.2)' : '',
          }
        },
        formatter: (cell, row) =>
          Formatter.editColumn({
            cell,
            row,
            onEditPointActivity,
            onDeletePointActivity,
            setStopPointActivity,
          }),
      },
    ]
  }

  return (
    <PointActivityListStyle>
      <StyleCard className="w-100">
        <TableToolBar
          totalSize={paginationProps.totalSize}
          paginationProps={paginationProps}
          sizePerPage={paginationProps.sizePerPage}
          onClick={onAddNewPointActivity}
        />
        <Table
          striped
          remote
          headerClasses="table-header"
          bodyClasses="paragraph"
          className="overflow-auto"
          keyField="no"
          data={displayTableData}
          columns={generateColumns(isShowFunctBtnCol)}
          sort={{ dataField: apiPayload.sf, order: apiPayload.so }}
          onTableChange={(type, value) =>
            onTableChange(setApiPayload, type, value)
          }
          noDataIndication={
            <NoDataIndication
              isLoading={isLoadingTableData}
              message={isLoadingTableData ? '載入中...' : '尚無資料'}
            />
          }
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationTableProps}
        />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PaginationListStandalone {...paginationProps} />
      </StyleCard>
      <PointActivityModal
        state={state}
        dispatch={dispatch}
        brandId={brandId}
        isShowAddModal={isShowAddModal}
        isShowEditModal={isShowEditModal}
        onCloseModal={(isBool) => {
          setIsShowAddModal(isBool)
          setIsShowEditModal(isBool)
        }}
        visibleDoublePoint={visibleDoublePoint}
        pointBarcodeMultipleList={state.barcodeMultipleList}
        fetchPointActivityList={fetchPointActivityList}
        currentEditId={currentEditId}
        setCurrentEditId={setCurrentEditId}
      />
      <PointActivityHintsModal
        isShow={stopPointActivity.isShowModal}
        onClose={onCloseHintsModal}
        onConfirm={({ handleModalLoading }) => {
          onConfirmCloseHintsModal(handleModalLoading)
        }}
      />
    </PointActivityListStyle>
  )
}

/**
 * @description table 上方工具列 UI
 * @param totalSize
 * @param paginationProps
 * @param sizePerPage
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
function TableToolBar({
  totalSize = 0,
  paginationProps = {},
  sizePerPage = 0,
  onClick = () => {},
}) {
  const { CREATE } = COMPONENT_PERMISSIONS.barcodeRule
  const PermissionAddActivityButton = withPermission(Button, CREATE)
  return (
    <Row className="table-toolbar d-flex">
      <Col>
        <span>總項目：</span>
        <span className="total-size">{totalSize} 筆</span>
      </Col>
      <Col className="d-flex">
        <div className="ml-auto">
          <span className="mr-1">每頁</span>
          <SizePerPageDropdownStandalone
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...paginationProps}
            sizePerPage={`${sizePerPage} 筆`}
          />
        </div>
        <PermissionAddActivityButton
          className="ml-3"
          variant="outline-primary"
          onClick={onClick}
          size="sm"
        >
          新增活動
        </PermissionAddActivityButton>
      </Col>
    </Row>
  )
}

export default PointActivityList

PointActivityList.propTypes = {
  paginationProps: PropTypes.shape({
    totalSize: PropTypes.number,
    sizePerPage: PropTypes.number,
  }),
  paginationTableProps: PropTypes.shape({}),
  apiPayload: PropTypes.shape({
    ps: PropTypes.number,
    p: PropTypes.number,
    sf: PropTypes.string,
    so: PropTypes.string,
  }),
  setApiPayload: PropTypes.func,
  setTotalSize: PropTypes.func,
}

PointActivityList.defaultProps = {
  paginationProps: {
    totalSize: 0,
    sizePerPage: 0,
  },
  paginationTableProps: {},
  apiPayload: {
    ps: 0,
    p: 1,
    sf: '',
    so: '',
  },
  setApiPayload: () => {},
  setTotalSize: () => {},
}

TableToolBar.propTypes = {
  totalSize: PropTypes.number,
  sizePerPage: PropTypes.number,
  paginationProps: PropTypes.shape({
    totalSize: PropTypes.number,
    sizePerPage: PropTypes.number,
  }),
  onClick: PropTypes.func,
}

TableToolBar.defaultProps = {
  totalSize: 0,
  paginationProps: {
    totalSize: 0,
    sizePerPage: 0,
  },
  sizePerPage: 0,
  onClick: () => {},
}
