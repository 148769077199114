import styled from 'styled-components'
import { Row } from 'components/units'
import { colorObj } from 'assets/styles/Variable/Color'

const ConsumerConditionWrapper = styled(Row)`
  display: flex;
  gap: 15px;
  align-items: center;
`

const GiftSpecialWrapper = styled(Row)`
  gap: 5px;
  align-items: center;
  div:nth-last-child(1) {
    flex-grow: 1;
  }
`

const LinkContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TextLink = styled.span`
  color: ${colorObj.primary};
  text-decoration: underline;
  cursor: pointer;
`

export {
  ConsumerConditionWrapper,
  GiftSpecialWrapper,
  LinkContentWrapper,
  TextLink,
}
