import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'

const Wrapper = styled.div`
  z-index: 300;
  width: 100%;
  height: calc(100% + 48px);
  top: -48px;
  left: 0;
  background-color: rgba(0, 0, 0);
  position: fixed;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  &.show {
    opacity: 0.5;
    visibility: visible;
  }
`

function Mask({ show = false, children }) {
  return show && <Wrapper className={show && 'show'}>{children}</Wrapper>
}

export default Mask
