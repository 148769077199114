import { createContext } from 'react'

const PointHistoryContext = createContext()
PointHistoryContext.displayName = 'PointHistoryContext'

// ActivityFormRecord page
const ActivityFormRecordListContext = createContext()
ActivityFormRecordListContext.displayName = 'ActivityFormRecordListContext'

const TabSectionContext = createContext()
TabSectionContext.displayName = 'TabSectionContext'

// QrcodeActivity page
const QrcodeActivityContext = createContext()
QrcodeActivityContext.displayName = 'QrcodeActivityContext'
const QrcodeActivityContentContext = createContext()
QrcodeActivityContentContext.displayName = 'QrcodeActivityContentContext'
const ExtraPointContext = createContext()
ExtraPointContext.displayName = 'ExtraPointContext'
const SerialNumberContext = createContext()
SerialNumberContext.displayName = 'SerialNumberContext'
const FormSectionContext = createContext()
FormSectionContext.displayName = 'FormSectionContext'
const SearchSectionContext = createContext()
SearchSectionContext.displayName = 'SearchSectionContext'

// GiftExchange page
const ExchangeContext = createContext()
ExchangeContext.displayName = 'ExchangeContext'

// Gift-List
const GiftListContext = createContext()
GiftListContext.displayName = 'GiftListContext'

// companyToken page
const CompanyTokenContext = createContext()
CompanyTokenContext.displayName = 'CompanyTokenContext'

export default PointHistoryContext
export {
  PointHistoryContext,
  ActivityFormRecordListContext,
  TabSectionContext,
  QrcodeActivityContext,
  QrcodeActivityContentContext,
  ExtraPointContext,
  SerialNumberContext,
  FormSectionContext,
  SearchSectionContext,
  ExchangeContext,
  GiftListContext,
  CompanyTokenContext,
}
