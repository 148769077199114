import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb } from 'components/units'
import { updatePageLoading } from 'store/global/globalSlice'
import noPermissionBg from '../../assets/images/page/img_bg_permission.png'

const Notice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;
  img {
    width: 201px;
    height: 200px;
  }
  p {
    margin-top: 16px;
  }
`

function NavSection(props) {
  const {
    pageInfo: { pathList, title, description },
  } = props
  const now = <span className="width-max-content d-inline-block">{title}</span>
  return <Breadcrumb now={now} pathList={pathList} description={description} />
}

export default function NoPermissionNotice(props) {
  const { pageInfo } = props
  const dispatch = useDispatch()
  useEffect(() => {
    if (pageInfo) dispatch(updatePageLoading(false))
  }, [pageInfo, dispatch])

  return (
    <Basic navSection={() => NavSection({ pageInfo })}>
      <ContentSection>
        <Notice>
          <img src={noPermissionBg} alt="no access" />
          <p>尚無權限</p>
        </Notice>
      </ContentSection>
    </Basic>
  )
}

NoPermissionNotice.propTypes = {
  pageInfo: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        group: PropTypes.string,
        list: PropTypes.string,
      }),
    ]),
    description: PropTypes.string,
  }).isRequired,
}

NavSection.propTypes = {
  pageInfo: PropTypes.shape({
    title: PropTypes.string,
    pathList: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        slash: PropTypes.bool,
      })
    ),
    description: PropTypes.string,
  }).isRequired,
}
