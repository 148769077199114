import styled from 'styled-components'
import { Tabs } from 'react-bootstrap'
import { colorObj } from 'assets/styles/Variable/Color'

const ButtonGroupWrapper = styled.div`
  .btn {
    min-width: auto !important;
  }
`
const StyledSideSection = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
const TabsWarpper = styled(Tabs)`
  + .tab-content {
    padding: 24px;
    .paragraph {
      font-size: 13px;
      color: ${colorObj.dark};
    }
  }
`

const TransparentMask = styled.div`
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  display: ${(props) => (props.isShow ? 'block' : 'none')};
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  cursor: pointer;
`

export {
  ButtonGroupWrapper,
  TabsWarpper,
  StyledSideSection,
  TransparentMask,
  IconWrapper,
}
