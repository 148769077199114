import { toast } from 'react-hot-toast'
import { commonFetchProps, getSearchParameter } from 'api/ApiCommon'
import { fetchData } from 'api/ApiRoute'
import { toastErrorContent } from 'helpers/common'

const handleError = async (res) => {
  switch (res.status) {
    case 400:
      if (res?.data?.msg) {
        const { msg, errors } = res.data
        // 有後端自定義 errors，錯誤 toast 優先使用 errors
        if (errors) {
          const errorsKeys = Object.keys(errors)
          if (errorsKeys.length > 0) {
            const firstErrorKey = errorsKeys[0]
            const firstErrorMessage = errors[firstErrorKey]
            toast.error((t) =>
              toastErrorContent(res.status, firstErrorMessage, t.id)
            )
            return Promise.reject(res.data ?? res)
          }
        }
        // 無後端自定義 errors，錯誤 toast 使用 msg
        toast.error((t) => toastErrorContent(res.status, msg, t.id))
        return Promise.reject(res.data ?? res)
      }
      break
    default:
      break
  }

  return Promise.resolve() // 在 switch 語句的預設情況下，返回一個已解決的 Promise
}

// 獲得廠商Token列表
const getOpenApi = (brandId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `account/openapi/brand/${brandId}${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 獲得廠商單一項目詳細資料
const getOpenApiOnce = (brandId, tokenId) => {
  return fetchData(
    `account/openapi/brand/${brandId}/${tokenId}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 新增廠商Token
const postOpenApi = (brandId, data) => {
  return fetchData(
    `account/openapi/brand/${brandId}`,
    'POST',
    data,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 刪除廠商Token
const deleteOpenApi = (brandId, tokenId) => {
  return fetchData(
    `account/openapi/brand/${brandId}/${tokenId}`,
    'DELETE',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 停用廠商Token
const patchOpenApiStop = (brandId, tokenId) => {
  return fetchData(
    `account/openapi/brand/${brandId}/${tokenId}/stop`,
    'PATCH',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 啟用廠商Token
const patchOpenApiActive = (brandId, tokenId) => {
  return fetchData(
    `account/openapi/brand/${brandId}/${tokenId}/active`,
    'PATCH',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 延展廠商Token
const patchOpenApiExtend = (brandId, tokenId) => {
  return fetchData(
    `account/openapi/brand/${brandId}/${tokenId}/extend`,
    'PATCH',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}

export {
  getOpenApi,
  getOpenApiOnce,
  postOpenApi,
  deleteOpenApi,
  patchOpenApiStop,
  patchOpenApiActive,
  patchOpenApiExtend,
}
