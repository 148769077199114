import PropTypes from 'prop-types'
import { Modal } from 'components/units'

/* 核銷用 modal，用於「好禮核銷」和「贈品核銷」 */
function BatchModal({ show, onCancel, onClose, onConfirm }) {
  return (
    <Modal
      show={show}
      variant="danger"
      titleText="批次核銷"
      confirmBtnText="繼續"
      closeBtnText="返回"
      onConfirm={onConfirm}
      onClose={onClose}
      onCancel={onCancel}
    >
      <div>將變更狀態為已領取。</div>
      <div>是否繼續執行？</div>
    </Modal>
  )
}

BatchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default BatchModal
