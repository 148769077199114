import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import NoPermissionNotice from 'components/layouts/NoPermissionNotice'
import {
  getPermissionList,
  isPermissionAllowed,
} from 'store/permission/permissionSlice'

const withPermission = (
  WrappedComponent,
  permissionKeys,
  pageInfo = null,
  rule = 'or'
) => {
  function Permission(props) {
    const { children } = props
    const permissionList = useSelector(getPermissionList)
    const isAllowed = useSelector(isPermissionAllowed(permissionKeys, rule))

    if (!permissionList.length) return null

    if (isAllowed)
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...props}>{children}</WrappedComponent>

    if (pageInfo)
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <NoPermissionNotice pageInfo={pageInfo} {...props} />
  }

  Permission.propTypes = {
    children: PropTypes.node,
  }
  Permission.defaultProps = {
    children: null,
  }
  return Permission
}

export default withPermission
