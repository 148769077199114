import PropTypes from 'prop-types'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { Button, Input } from 'components/units'
import { regexRuleNormal } from 'helpers/validation'

// 欄位對應的驗證規則要顯示的文字
const fieldRules = {
  email: {
    required: '尚未填寫',
    pattern: 'email 格式不正確',
  },
  password: {
    required: '尚未填寫',
  },
}
function LoginForm(props) {
  const { onChange, onSubmit, formError, isFetchingForgetPassword } = props
  // 表單資料
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  // 初始設定為登入操作
  const [actionType, setActionType] = useState('login')
  // 表單form 是否開啟欄位驗證
  const [validated, setValidated] = useState(false)
  // 欄位errors
  const [fieldsError, setFieldsError] = useState({
    email: fieldRules?.email?.required,
    password: fieldRules?.password?.required,
  })
  const handleInputChange = (e) => {
    // 輸入值後，將 isFetch 改回false
    onChange()
    // 依照輸入結果更新錯誤訊息
    const { name, value, pattern } = e.target
    if (pattern) {
      let feedbackText = fieldRules?.[name]?.required || ''
      if (value !== '') {
        feedbackText = fieldRules?.[name]?.pattern || ''
      }
      setFieldsError({
        ...fieldsError,
        [name]: feedbackText,
      })
    }
    // 將輸入值更新到表單狀態
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // 表單開啟欄位驗證
    setValidated(true)
    const isValidated = e.currentTarget.checkValidity()
    if (isValidated) {
      onSubmit({ formData, actionType })
    }
  }
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      data-testid="login-form"
    >
      <Form.Group>
        <Input
          formControlOption={{
            required: true,
            pattern: regexRuleNormal.email,
            className: 'input-account',
            type: 'email',
            name: 'email',
            placeholder: '帳號',
            onChange: handleInputChange,
            autoComplete: 'username',
          }}
          feedbackText={fieldsError?.email}
        />
      </Form.Group>
      <Form.Group>
        <Input
          formControlOption={{
            required: actionType === 'login',
            className: 'input-password',
            type: 'password',
            name: 'password',
            placeholder: '密碼',
            autoComplete: 'current-password',
            onChange: handleInputChange,
          }}
          feedbackText={fieldsError?.password}
        />
        {formError && (
          <div
            className="custom-invalid-feedback px-2 pt-1"
            data-testid="login-form-error"
          >
            {formError}
          </div>
        )}
      </Form.Group>
      <Button
        className="login-btn w-100"
        type="submit"
        name="login-btn"
        value="login"
        onClick={() => setActionType('login')}
      >
        登入
      </Button>
      <Button
        className="password-forget-btn"
        type="submit"
        name="password-forget-btn"
        value="password-forget"
        isLoading={isFetchingForgetPassword}
        onClick={() => setActionType('password-forget')}
      >
        忘記密碼?
      </Button>
    </Form>
  )
}

export default LoginForm

LoginForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  formError: PropTypes.string,
  isFetchingForgetPassword: PropTypes.bool,
}

LoginForm.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
  formError: '',
  isFetchingForgetPassword: false,
}
