import Container from 'react-bootstrap/Container'
import { Row, Col, InputGroup } from 'react-bootstrap'
import { Checkbox, Input, Button } from 'components/units'
import {
  Container as ReactSmoothDndContainer,
  Draggable,
} from 'react-smooth-dnd'
import * as format from 'helpers/format'
import iconDelete from 'assets/images/page/icon_delete_14_16.svg'
import {
  isNonEmptyArray,
  toPascalCase,
  sanitizeNonNumberStr,
} from 'helpers/common'
import PropTypes from 'prop-types'

function ConditionForm({
  formName,
  memberLevelForm,
  validated,
  accumulativeWay,
  condition,
  onConditionFieldRemind,
  fieldsError,
  setCondition,
  setMemberLevelForm,
  isShowSyncButton,
  conditionInfo,
}) {
  const listPropertyMapping = {
    upgrade: 'upgradeList',
    renew: 'renewList',
  }
  const listProperty = listPropertyMapping[formName]

  // checkbox onChange 處理
  const handleCheck = ({ target }) => {
    const { name, checked } = target
    setCondition((prevState) => {
      return {
        ...prevState,
        [name]: checked,
      }
    })

    if (checked) {
      // 勾選單筆消費，累積消費及消費次數要取消勾選，並且裡面的值要清空
      if (name === 'checkboxSingleGe') {
        setCondition((prevState) => {
          return {
            ...prevState,
            checkboxAccumulationGe: false,
            checkboxConsumptionNumGe: false,
            accumulation_ge: '',
            consumption_num_ge: '',
          }
        })
      }

      // 如果勾選累積消費或消費次數，單筆消費要取消勾選，並且裡面的值要清空
      if (
        name === 'checkboxAccumulationGe' ||
        name === 'checkboxConsumptionNumGe'
      ) {
        setCondition((prevState) => {
          return {
            ...prevState,
            checkboxSingleGe: false,
            single_ge: '',
          }
        })
      }
    }

    // 單筆消費、累計消費
    const nameMapping = {
      checkboxSingleGe: 'single_ge',
      checkboxAccumulationGe: 'accumulation_ge',
      checkboxConsumptionNumGe: 'consumption_num_ge',
    }
    const actionName = nameMapping[name]
    if (actionName) {
      setCondition((prevState) => {
        return {
          ...prevState,
          [actionName]: '',
        }
      })
    }
  }

  // input onChange 處理
  const handleChangeCondition = ({ target }) => {
    const name = target?.name
    const value = target?.value

    const numericValue = sanitizeNonNumberStr(value)

    setCondition((prevState) => {
      return {
        ...prevState,
        [name]: numericValue,
      }
    })
  }

  // 驗證欄位是否錯誤
  const validateFieldError = (fieldName) => {
    let isError = false
    // 已按下按鈕 且 (未勾選 fieldName 欄位 或 勾選了 fieldName 欄位但尚未填寫)，要顯示錯誤
    const fieldErrorMapping = {
      single_ge:
        !condition?.checkboxSingleGe ||
        (condition?.checkboxSingleGe && !condition?.single_ge),
      accumulation_ge:
        !condition?.checkboxAccumulationGe ||
        (condition?.checkboxAccumulationGe && !condition?.accumulation_ge),
      consumption_num_ge:
        !condition?.checkboxConsumptionNumGe ||
        (condition?.checkboxConsumptionNumGe && !condition?.consumption_num_ge),
    }
    const isFieldError = fieldErrorMapping[fieldName]
    if (isFieldError) isError = true

    // 未勾選 fieldName 的欄位 且 勾選了其他欄位，則不需要顯示錯誤
    const ErrorCancelMapping = {
      single_ge:
        !condition?.checkboxSingleGe &&
        (condition?.checkboxAccumulationGe ||
          condition?.checkboxConsumptionNumGe),
      accumulation_ge:
        !condition?.checkboxAccumulationGe &&
        (condition?.checkboxSingleGe || condition?.checkboxConsumptionNumGe),
      consumption_num_ge:
        !condition?.checkboxConsumptionNumGe &&
        (condition?.checkboxSingleGe || condition?.checkboxAccumulationGe),
    }
    const isErrorCancelMapping = ErrorCancelMapping[fieldName]
    if (isErrorCancelMapping) isError = false

    return isError
  }

  // 確認是否所有欄位都已驗證通過
  const isAllConditionValidated =
    !validateFieldError('single_ge') &&
    !validateFieldError('accumulation_ge') &&
    !validateFieldError('consumption_num_ge')

  // 處理升等條件：新增條件的事件處理
  const handleCreateCondition = () => {
    const isPointSetting = accumulativeWay?.baseSettingUse === 'point'
    const isValidated = isPointSetting
      ? !(
          validateFieldError('accumulation_ge') ||
          validateFieldError('consumption_num_ge')
        )
      : !(
          validateFieldError('single_ge') ||
          validateFieldError('accumulation_ge') ||
          validateFieldError('consumption_num_ge')
        )

    // 通過驗證
    if (isValidated) {
      setMemberLevelForm((prevState) => {
        const ListMapping = {
          upgrade: [...memberLevelForm?.upgradeList] || [],
          renew: [...memberLevelForm?.renewList] || [],
        }
        const newConditionList = ListMapping[formName]

        const cloneProps = {}
        for (const ruleSettingStateProp in condition) {
          // 過濾掉checkbox的值，這邊僅需要input值
          if (!ruleSettingStateProp.includes('checkbox')) {
            cloneProps[ruleSettingStateProp] =
              parseInt(condition[ruleSettingStateProp]) ||
              parseInt(condition[ruleSettingStateProp]) === 0
                ? parseInt(condition[ruleSettingStateProp])
                : null
          }
        }
        newConditionList.push({
          ...cloneProps,
        })

        return {
          ...prevState,
          [listProperty]: newConditionList,
        }
      })

      // reset
      setCondition((prevState) => {
        const newConditionState = { ...prevState }
        for (const propName in newConditionState) {
          newConditionState[propName] = null
        }
        return newConditionState
      })
    }
  }

  // react smooth dnd處理拖曳後的最後排列結果
  const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult
    if (removedIndex === null && addedIndex === null) return arr

    const result = [...arr]
    let itemToAdd = payload

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0]
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd)
    }

    return result
  }

  // 拖曳事件處理
  const onDropMemberShipCondition = (props) => {
    const newMemberShipCondition = applyDrag(
      memberLevelForm?.[listProperty],
      props
    )
    setMemberLevelForm((prevState) => {
      return {
        ...prevState,
        [listProperty]: newMemberShipCondition,
      }
    })
  }

  // 刪除條件
  const handleDeleteCondition = (deleteIndex) => {
    setMemberLevelForm((prevState) => {
      const newConditionList = [...prevState[listProperty]]
      newConditionList.splice(deleteIndex, 1)
      return {
        ...prevState,
        [listProperty]: newConditionList,
      }
    })
  }

  // 已按下表單的送出按鈕 && 未新增任一項條件
  const isConditionError =
    validated && !isNonEmptyArray(memberLevelForm?.[listProperty])

  // 表單內所有欄位
  const allConditions = accumulativeWay[accumulativeWay?.baseSettingUse]

  // 同步升等條件
  const handleSync = () => {
    setMemberLevelForm((prevState) => {
      return {
        ...prevState,
        renewList: [...prevState.upgradeList],
      }
    })
  }

  return (
    <>
      <div className="member-condition-container">
        <Container>
          {accumulativeWay?.baseSettingUse &&
            Object.keys(allConditions).map((conditionName, conditionIndex) => {
              const conditionItem = allConditions[conditionName]
              return (
                <Row key={`member-condition-accumulativeway-${conditionIndex}`}>
                  <Col className="no-padding">
                    <InputGroup className="flex-nowrap align-items-center">
                      <Checkbox
                        name={`checkbox${toPascalCase(conditionName)}`}
                        label={conditionItem?.text}
                        id={`${formName}_checkbox_${conditionName}`}
                        checked={
                          !!condition?.[
                            `checkbox${toPascalCase(conditionName)}`
                          ]
                        }
                        onChange={handleCheck}
                      />
                      <Input
                        className="memshipset-modal-conditional-input ml-2"
                        appendContent={conditionItem?.unit}
                        appendContentBgColor="#fff"
                        appendContentPadding="0 4px 0 0"
                        appendContentHaveBorder={false}
                        isFocusCocatAppend
                        formControlOption={{
                          name: conditionName,
                          disabled:
                            !condition?.[
                              `checkbox${toPascalCase(conditionName)}`
                            ],
                          value: condition?.[conditionName] ?? '',
                          onChange: handleChangeCondition,
                          maxlength: 6,
                        }}
                      />
                    </InputGroup>
                    {/* 提示 */}
                    <div className="custom-invalid-feedback">
                      {condition?.[conditionName] && (
                        <>
                          {/* 數值設定提示 */}
                          {onConditionFieldRemind(
                            conditionName,
                            condition?.[conditionName]
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              )
            })}
          <Row>
            <Col className="d-flex justify-content-end p-0">
              {isShowSyncButton && (
                <Button
                  className="btn-new-level-condition"
                  variant="outline-darkerGray"
                  onClick={handleSync}
                >
                  同升等條件
                </Button>
              )}
              <Button
                className="btn-new-level-condition ml-3"
                variant={isAllConditionValidated ? 'outline-blue' : 'gray'}
                disabled={!isAllConditionValidated}
                onClick={handleCreateCondition}
              >
                新增條件
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="member-condition-container-remind fz13 mt-1">
        {conditionInfo}
      </div>
      <Container className="member-condition-list-checked mt-2">
        {Array.isArray(memberLevelForm?.[listProperty]) && (
          <ReactSmoothDndContainer
            lockAxis="y"
            dragClass="smooth-dnd-active-item"
            dragHandleSelector=".column-drag-handle"
            onDrop={onDropMemberShipCondition}
          >
            {memberLevelForm?.[listProperty]?.map((item, index) => {
              return (
                <Draggable key={`draggable-item-member-condition-${index}`}>
                  <Row className="align-items-baseline mx-0">
                    <span
                      className="column-drag-handle cursor-pointer"
                      style={{ float: 'left', padding: '0 10px' }}
                    >
                      ＝
                    </span>
                    <div className="list-title mr-1">{index + 1}.</div>
                    <Col className="no-padding">
                      <ConditionList
                        item={item}
                        allConditions={allConditions}
                        baseSetting={accumulativeWay?.baseSettingUse}
                      />
                    </Col>
                    <Col xs={1} className="text-right">
                      <img
                        src={iconDelete}
                        className="setting-img"
                        alt=""
                        title="刪除"
                        onClick={() => handleDeleteCondition(index)}
                      />
                    </Col>
                  </Row>
                </Draggable>
              )
            })}
          </ReactSmoothDndContainer>
        )}
      </Container>
      {isConditionError && (
        <div className="custom-invalid-feedback pl-2">
          {fieldsError?.memberShipCondition}
        </div>
      )}
    </>
  )
}

const ConditionList = ({ item, allConditions, baseSetting }) => {
  const isConSumption = baseSetting === 'consumption'

  const isNonNegativeNumber = (value) => {
    return typeof value === 'number' && value >= 0
  }

  // 「單筆消費」訊息，例：單筆消費 ≥ $1 元
  const singleGeInfo = isNonNegativeNumber(item.single_ge)
    ? `${allConditions.single_ge.text}
        ${format.currency(item.single_ge)}
        ${allConditions.single_ge.unit}`
    : ''

  // 「累積消費」訊息，例：累積消費 ≥ $1 元 或 累積消費 ≥ 1 點
  const accumulationGeInfo = isNonNegativeNumber(item.accumulation_ge)
    ? `${allConditions.accumulation_ge.text}
        ${format[isConSumption ? 'currency' : 'number'](item.accumulation_ge)}
        ${allConditions.accumulation_ge.unit}`
    : ''

  // 「消費次數」訊息，例：消費次數 ≥ 1 次
  const consumptionNumGeInfo = isNonNegativeNumber(item.consumption_num_ge)
    ? `${allConditions.consumption_num_ge.text}
        ${format.number(item.consumption_num_ge)}
        ${allConditions.consumption_num_ge.unit}`
    : ''

  // 「累積消費」及「消費次數」之間的 & 及換行
  const connectSymbol = isNonNegativeNumber(item.accumulation_ge) &&
    isNonNegativeNumber(item.consumption_num_ge) && (
      <>
        {' '}
        &amp; <br />
      </>
    )

  return (
    <div className="member-condition-list-checked-content">
      {singleGeInfo}
      {accumulationGeInfo}
      {connectSymbol}
      {consumptionNumGeInfo}
    </div>
  )
}

export default ConditionForm

ConditionForm.propTypes = {
  formName: PropTypes.string.isRequired,
  memberLevelForm: PropTypes.object.isRequired,
  validated: PropTypes.bool.isRequired,
  accumulativeWay: PropTypes.object.isRequired,
  condition: PropTypes.object.isRequired,
  onConditionFieldRemind: PropTypes.func,
  fieldsError: PropTypes.object.isRequired,
  setCondition: PropTypes.func.isRequired,
  setMemberLevelForm: PropTypes.func.isRequired,
  isShowSyncButton: PropTypes.bool,
  conditionInfo: PropTypes.string.isRequired,
}

ConditionForm.defaultProps = {
  onConditionFieldRemind: () => {},
  isShowSyncButton: false,
}
