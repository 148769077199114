import { ReactComponent as IconCalendar } from 'assets/images/icon_calendar.svg'
import { colorObj } from 'assets/styles/Variable/Color'

const settingPageType = {
  new: 'new',
  edit: 'edit',
  view: 'view',
}

// 表單欄位控制的欄位名稱
const formControlOutputNames = {
  name: 'name',
  validateStart: 'validateStart',
  validateStartDate: 'startDate',
  validateFinal: 'validateFinal',
  validateFinalDate: 'endDate',
  point: 'point',
  liffTitle: 'liffTitle',
  liffCaption: 'liffDescription',
}

// 表單中的各form-group名稱
const formGroups = {
  name: 'name',
  validateFrom: 'validateFrom',
  validateEnd: 'validateEnd',
  point: 'point',
  liffTitle: 'liffTitle',
  liffCaption: 'liffCaption',
}

// 欄位對應的所有驗證規則(以form-group為一單位)
const fieldRules = {
  common: '尚未填寫',
  validateEnd: {
    earlyFrom: '不可早於效期起始日',
  },
  point: {
    number: '請輸入正負整數或 0',
  },
}

// 欄位對應的驗證規則不符合時正在顯示的文字(以form-group為一單位)
const formGroupErrors = {
  [formGroups.name]: null,
  [formGroups.validateFrom]: null,
  [formGroups.validateEnd]: null,
  [formGroups.point]: null,
  [formGroups.liffCaption]: null,
}

// 日期欄位的prependContenet設定
const datePrependContentSetting = () => {
  return {
    prependContent: <IconCalendar />,
    prependContentBgColor: 'transparent',
    prependContentHaveBorder: false,
    prependContentPadding: '10px 0 10px 10px',
    prependContentColor: colorObj.gray,
  }
}

// 所有欄位的FieldTypeSwitch元件的屬性設定值
const allFieldTypeSwitchProp = {
  name: {
    type: 'text',
    formControlOption: {
      name: formControlOutputNames.name,
      required: true,
      placeholder: '上限 50 字',
      maxLength: 50,
      autoComplete: 'off',
    },
  },
  validateFromNow: {
    type: 'radio',
    formControlOption: {
      className: 'mb-3',
      name: formControlOutputNames.validateStart,
    },
    options: [
      {
        name: '立即開始',
        code: 'now',
      },
    ],
  },
  validateFromStart: {
    type: 'radio',
    containerClass: 'w-auto d-flex',
    formControlOption: {
      name: formControlOutputNames.validateStart,
    },
    inline: true,
    options: [
      {
        name: '起始日',
        code: 'startDate',
      },
    ],
  },
  validateFromStartDate: {
    type: 'date',
    formControlOption: {
      name: formControlOutputNames.validateStartDate,
      readOnly: true,
      disabled: true,
      placeholder: '起始日期',
    },
    min: new Date(),
    inputOption: datePrependContentSetting(),
  },
  validateEndForever: {
    type: 'radio',
    className: 'mb-3',
    formControlOption: {
      name: formControlOutputNames.validateFinal,
    },
    options: [
      {
        name: '永久',
        code: 'forever',
      },
    ],
  },
  validateEndFinal: {
    type: 'radio',
    containerClass: 'w-auto d-flex',
    formControlOption: {
      name: formControlOutputNames.validateFinal,
      inline: true,
    },
    inline: true,
    options: [
      {
        name: '結束日',
        code: 'endDate',
      },
    ],
  },
  validateEndFinalDate: {
    type: 'date',
    formControlOption: {
      name: formControlOutputNames.validateFinalDate,
      disabled: true,
      placeholder: '結束日期',
    },
    min: new Date(),
    inputOption: datePrependContentSetting(),
  },
  point: {
    type: 'text',
    formControlOption: {
      name: formControlOutputNames.point,
      autoComplete: 'off',
    },
    inputOption: {
      appendContent: '點',
      appendContentColor: '#000',
      appendContentBgColor: '#fff',
      appendContentPadding: '0 4px 0 0',
      appendContentHaveBorder: false,
      isFocusCocatAppend: true,
    },
  },
  liffTitle: {
    formControlOption: {
      type: 'text',
      name: formControlOutputNames.liffTitle,
      maxLength: 30,
      placeholder: '選填，上限 30 字',
    },
  },
  liffCaption: {
    formControlOption: {
      type: 'text',
      name: formControlOutputNames.liffCaption,
      maxLength: 50,
      placeholder: '選填，上限 50 字',
    },
  },
}

// 表單設定（版面配置）
const formConfig = {
  [formGroups.name]: {
    title: '名稱',
    groupClass: 'group-name d-flex col-12 col-lg-6 pl-0 pr-0 pr-lg-3 mb-0',
    labelClass: 'list-title mt-1',
  },
  [formGroups.validateFrom]: {
    title: '效期起始',
    groupClass:
      'group-validate-from d-flex col-12 col-lg-6 pl-0 pr-0 pr-lg-3 mb-3 mb-lg-0',
    labelClass: 'list-title ',
  },
  [formGroups.validateEnd]: {
    title: '效期結束',
    groupClass:
      'group-validate-end d-flex col-12 col-lg-6 pr-0 pl-0 pl-lg-3 mb-0',
    labelClass: 'list-title ',
  },
  [formGroups.point]: {
    title: '點數',
    groupClass:
      'group-point d-flex col-12 col-lg-6 pl-0 pr-0 pr-lg-3 mb-3 mb-lg-0',
    labelClass: 'list-title mt-1',
  },
  [formGroups.liffTitle]: {
    title: '頁面標題',
    groupClass:
      'group-liff-caption d-flex col-12 col-lg-6 pl-0 pr-0 pr-lg-3 mb-3 mb-lg-0',
    labelClass: 'list-title mt-1',
  },
  [formGroups.liffCaption]: {
    title: '頁面說明',
    groupClass:
      'group-liff-caption d-flex col-12 col-lg-6 pr-0 pl-0 pl-lg-3 mb-0',
    labelClass: 'list-title mt-1',
  },
}

export {
  settingPageType,
  formControlOutputNames,
  formGroups,
  fieldRules,
  formGroupErrors,
  allFieldTypeSwitchProp,
  formConfig,
  datePrependContentSetting,
}
