import queryString from 'query-string'
import urlparse from 'url-parse'
import { showTopToast } from 'components/units/Toast'
import { TOAST } from 'constant/common'
import { getLocalStorage } from 'helpers/common'

// 共用的fetch參數設定
const commonFetchProps = {
  headers: {
    'Content-Type': 'application/json',
  },
}

/* qeury string parameter */
const getSearchParameter = (extraParameterObj) => {
  extraParameterObj =
    extraParameterObj && extraParameterObj.constructor === Object
      ? extraParameterObj
      : {}
  const commonQueryString = {}
  const mixQueryString = queryString.stringify({
    ...extraParameterObj,
    ...commonQueryString,
  })
  const searchParameter = `?${mixQueryString}`
  return searchParameter
}

function parserUrl(url, param, payload = {}) {
  let result = url
  for (const key in param) {
    result = result.replace(`{${key}}`, param[key])
  }
  if (JSON.stringify(payload) !== '{}') result += getSearchParameter(payload)
  return result
}

// API 回傳的對應狀態
const getApiReturnStatus = (status) => {
  let message = ''
  switch (status) {
    case 99:
      message = '例外錯誤'
      break
    case 100:
      message = 'FAILED'
      break
    default:
      message = 'Unknown Error'
  }
  return {
    message,
  }
}
// api錯誤處理
const handleError = async (res) => {
  switch (res.status) {
    case 400:
      if (res?.data?.msg) {
        showToast(res, res.data.msg)
        return Promise.reject(res.data ?? res)
      }
  }
}

/**
 * 顯示 Toast 訊息，根據 API 回傳的資料顯示不同類型的 Toast。
 * @async
 * @param {Object} res - API 回傳的物件，包含 { code, msg } 的屬性。
 * @param {string} message - 要顯示的訊息內容。
 * @returns {Promise} 如果 API 回傳的狀態碼不是 200，則回傳被拒絕的 Promise，內含 API 回傳的資料。
 * @throws {Object} 如果 API 回傳的狀態碼不是 200，則拋出包含 API 回傳的資料的錯誤物件。
 */
const showToast = async (rsp, message) => {
  const { code, msg, errors } = rsp?.data || {}
  const errorsMsg = errors ? Object.values(errors)[0][0] : null // 後端檢查發現欄位錯誤時，會回傳 errors 物件
  const errorMsg = message || errorsMsg || msg || ''
  if (code === 200) {
    showTopToast({ message }, TOAST.SUCCESS, null)
  } else {
    showTopToast(
      {
        message: `錯誤代碼：${code}，${errorMsg}`,
      },
      TOAST.ERROR,
      null
    )
  }
}

// 轉換為不同環境的網址
const getUrl = (url) => {
  const { protocol, hostname } = urlparse(window.location.href)
  // 線上
  let apiDomain = `${protocol}//${hostname}/api/v1`
  // 本機測試
  if (!hostname.includes('litloyal.com')) {
    apiDomain = `https://dev.litloyal.com/api/v1`
  }
  const route = url.substring(0, 4) === 'http' ? url : `${apiDomain}/${url}`
  return route
}

// 取得 token
const getAuthorization = () => {
  const authorization = getLocalStorage('token')
    ? `Bearer ${JSON.parse(getLocalStorage('token') || {})?.accessToken}`
    : ''
  return authorization
}

export {
  commonFetchProps,
  getSearchParameter,
  getApiReturnStatus,
  handleError,
  showToast,
  parserUrl,
  getUrl,
  getAuthorization,
}
