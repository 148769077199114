import urlparse from 'url-parse'
import { fetchData } from 'api/ApiRoute'
import { getSearchParameter, commonFetchProps, showToast } from 'api/ApiCommon'
import { getRandomId } from 'helpers/common'
import {
  getGiftList,
  copyEvent,
  deleteEvent,
  getEvent,
  updateEvent,
  addEvent,
  changeStatus,
  getMemberList,
  getDesignatedList,
  patchExchangeCodeRules,
  generateSystemExchangeCode,
  importExchangeCode,
  exportExchangeCode,
  getExchangeCodeList,
  getExchangeCodeDetail,
  addExchangeCode,
  switchExchangeCodeType,
  batchDeleteExchangeCode,
  getMemberOutline,
  getVipGiftList,
  addVipGift,
  getVipGiftInfo,
  updateVipGiftInfo,
  deleteVipGiftInfo,
  changeVipGiftStatus,
  copyVipGiftInfo,
  getVipExchangeCodeList,
  getVipExchangeCodeDetail,
  importVipExchangeCode,
  addVipExchangeCode,
  switchVipExchangeCodeType,
  batchDeleteVipExchangeCode,
  checkVipExchangeRule,
  getGiftExchangeRecord,
  getGiftExchangeRecordDetail,
  getExchangeRecordDetail,
  getVipGiftExchangeRecord,
  getVipGiftExchangeRecordDetail,
  getVipExchangedRecord,
  getVipExchangeRecordDetail,
  getVipExchangeLink,
  vipExchangeUnlink,
  getGiftDelivery,
  getGiftDeliveryDetail,
  patchGiftDelivery,
  postNotificationGift,
  getGiftDeliveryExport,
  postImportProductItems,
  getValidateCodeSetting,
  getVipGiftReimburse,
  getVipGiftReimburseDetail,
  patchVipGiftReimburse,
  getGiftReimburse,
  getGiftReimburseDetail,
  patchGiftReimburse,
} from 'api/pages/Gift'
import {
  getProductActivityList,
  getProductActivityEvent,
  deleteProductActivityEvent,
  stopProductActivity,
  getPointproductrulemultiple,
  addProductActivityEvent,
  updateProductActivityEvent,
  getProductListList,
  addSingleProductListItem,
  batchDeleteProductItem,
  importProductItems,
} from 'api/pages/ProductActivity'

import {
  getPointActivityListApi,
  getPointActivitySettingApi,
  addBarcodeRuleApi,
  stopPointActivityApi,
  deletePointActivityApi,
  getEditPointActivityApi,
  editPointActivityApi,
} from 'api/pages/PointActivityApi'

import {
  getPointHistoryListApi,
  getCustomIdApi,
  additionalPointApi,
  uploadFileApi,
  getPointHistoryDetail,
} from 'api/pages/PointHistoryApi.js'

/* Qrcode 活動 */
import {
  getQrcode,
  getQrcodeOnce,
  postQrcode,
  patchQrcode,
  deleteQrcode,
  patchStopQrcode,
  getGainPointRule,
  getGainPointRuleOnce,
  postQrcodeGainPointRule,
  patchQrcodeGainPointRule,
  deleteQrcodeGainPointRule,
  patchQrcodeGainPointRuleStop,
  getQrcodeExchangeTimesItems,
  getQrcodeGeneratedCode,
  getQrcodeGeneratedCodeDetail,
  postQrcodeGeneratedCode,
  deleteQrcodeGeneratedCode,
  deleteQrcodeGeneratedCodeBatch,
  patchQrcodeGeneratedCodeStop,
  getQrcodeExportFile,
  getQrcodeExportImage,
} from 'api/pages/QrcodeActivity'
/* Qrcode 活動表單紀錄 */
import {
  getQrcodeScanFailed,
  getQrcodeScanFailedOnce,
  patchQrcodeScanFailedStatus,
  getQrcodePointRecord,
  getQrcodePointRecordOnce,
  getQrcodePointRecordFieldInfo,
  getQrcodePointRecordDetail,
} from 'api/pages/ActivityFormRecord'
/* 廠商Token */
import {
  getOpenApi,
  getOpenApiOnce,
  postOpenApi,
  deleteOpenApi,
  patchOpenApiStop,
  patchOpenApiActive,
  patchOpenApiExtend,
} from 'api/pages/CompanyToken'
import { handleError } from 'api/ApiCommon'
import { showTopToast } from 'components/units/Toast'

const { origin } = urlparse(window.location.href)

/*--------------------------------------
    檢測是否系統維護
----------------------------------------*/
const getIsMaintenance = () => {
  return fetchData(origin, 'GET', null).then((rsp) => {
    const keyword = `<meta name="keywords"`
    const strFromIndex = rsp?.data?.indexOf(keyword)
    const strEndIndex = rsp?.data?.indexOf('>', strFromIndex)
    const keywordsMeta = rsp?.data?.substring(strFromIndex, strEndIndex + 1)
    if (keywordsMeta?.includes('maintenance')) {
      window.location.reload()
    }
  })
}

/*--------------------------------------
    檢測是否有模組在批次上傳序號，以便建立 signalR 連線
----------------------------------------*/
const getSerialBatchModule = async (brandId) => {
  try {
    const rsp = await fetchData(`setting/brand/${brandId}/signalr`, 'GET')
    return rsp?.data?.data
  } catch (error) {
    handleError(error)
  }
}

/*--------------------------------------
    登入頁 or 使用者相關資訊
----------------------------------------*/

// 登入
const postAuth = (data) => {
  return fetchData(`account/auth`, 'POST', data).then((rsp) => {
    return rsp?.data?.data
  })
}

// 獲得使用者資訊
const getAuth = async () => {
  try {
    const rsp = await fetchData(`account/auth`, 'GET', null)
    const { status } = rsp?.data?.data
    if (status !== 0) {
      // failed
      const AUTH_STATUS = {
        2: '帳號未啟用',
        3: '帳號已被停用',
        99: '例外錯誤',
      }
      const errorMessage = AUTH_STATUS[status] || 'Failed'
      showTopToast(
        { message: `錯誤代碼： ${status}，${errorMessage}` },
        'error',
        getRandomId()
      )
    }
    return rsp?.data?.data
  } catch (err) {
    handleError(err)
  }
}
// 重設密碼 / 忘記密碼
const postPasswordReset = (data) => {
  return fetchData(`account/auth/password/email`, 'POST', data)
    .then((rsp) => {
      showToast(rsp, rsp.data?.data?.msg)
      return rsp?.data?.data
    })
    .catch(handleError)
}

// 獲得使用者 CRUD 權限
const getPermissions = (brandId) => {
  return fetchData(`account/auth/brand/${brandId}/permission`, 'GET', null)
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}

// 獲得對應使用者的公司列表
const getAuthCompanies = async () => {
  try {
    const rsp = await fetchData(
      `account/auth/companies`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data
  } catch (err) {
    handleError(err)
  }
}
// 獲得對應使用者公司的品牌列表
const getAuthCompanyBrands = async (companyId) => {
  try {
    const rsp = await fetchData(
      `account/auth/company/${companyId}/brands`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data
  } catch (err) {
    handleError(err)
  }
}
// 獲得對應使用者公司品牌的商店列表
const getAuthCompanyBrandShops = async (companyId, brandId) => {
  try {
    const rsp = await fetchData(
      `account/auth/company/${companyId}/brand/${brandId}/shops`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data
  } catch (err) {
    handleError(err)
  }
}
// 修改登入密碼
const patchAuthChpwd = (data) => {
  return fetchData(`account/auth/chpwd`, 'PATCH', data, commonFetchProps).then(
    (rsp) => {
      return rsp?.data?.data
    }
  )
}

/*--------------------------------------
    首頁
----------------------------------------*/
// 獲得總會員數、月招募會員數等資訊
const getMemberDashboard = (queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/dashboard${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}

/*--------------------------------------
    數據分析頁
----------------------------------------*/
const getThemedDashboard = async (queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  try {
    const rsp = await fetchData(
      `account/themeddashboard${searchParameter}`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data
  } catch (error) {
    handleError(error)
  }
}

/*--------------------------------------
    會員查詢頁
----------------------------------------*/
// 取得所有會員資料
const getMember = (queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(`member${searchParameter}`, 'GET', null, commonFetchProps)
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
const postMember = (data) => {
  return fetchData(`member`, 'POST', data, commonFetchProps).then((rsp) => {
    return rsp?.data?.data
  })
}
// 取得指定會員資料、新增會員的欄位資料
const getMemberOnce = (memberId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/${memberId}${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
// 取得品牌的會員等級資料
const getMemberLevel = async (brandId) => {
  try {
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership/levelitems`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    handleError(error)
    throw error
  }
}
/*--------------------------------------
    會員資料頁
----------------------------------------*/
// 修改會員資料

const patchMember = async (memberId, data) => {
  try {
    const searchParameter = getSearchParameter()
    const rsp = await fetchData(
      `member/${memberId}${searchParameter}`,
      'PATCH',
      data,
      commonFetchProps
    )
    showToast(rsp, '會員編輯成功')
    return rsp?.data // 結構與其他API不同，少了一層data
  } catch (error) {
    handleError(error)
  }
}

// 取得交易資料
const getMemberDealinfo = async (memberId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  try {
    const rsp = await fetchData(
      `member/${memberId}/dealinfo${searchParameter}`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    handleError(error)
  }
}
// 取得點數紀錄
const getMemberPointRecord = (memberId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/${memberId}/pointrecord${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}
// 取得會籍紀錄
const getMembershipRecord = (memberId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/${memberId}/membershiprecord${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
// 刪除會員資料
const deleteMember = (memberId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `member/${memberId}${searchParameter}`,
    'DELETE',
    null,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
// 解除手機綁定
const patchUnbind = (memberId, data) => {
  return fetchData(
    `member/${memberId}/unbind`,
    'PATCH',
    data,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
/*--------------------------------------
    點數設定頁
----------------------------------------*/
// 點數設定頁全部資料
const getPoint = (brandId) => {
  return fetchData(
    `setting/brand/${brandId}/point`,
    'GET',
    null,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
// 點數設定頁-贈點設定的表格資料
const getGainPointRules = (brandId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `setting/brand/${brandId}/point/gainpointrules${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data
  })
}
// 點數設定頁-編輯基本設定
const patchPointBase = (brandId, baseId, data) => {
  return fetchData(
    `setting/brand/${brandId}/point/base/${baseId}`,
    'PATCH',
    data,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
// 點數設定頁-新增基本設定
const postPointBase = (brandId, data) => {
  return fetchData(
    `setting/brand/${brandId}/point/base`,
    'POST',
    data,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
// 點數設定頁-獲得編輯贈點設定單一row的資料
const getPointGainPointRuleOnce = (brandId, gainPointRuleId) => {
  return fetchData(
    `setting/brand/${brandId}/point/gainpointrule/${gainPointRuleId}`,
    'GET'
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
// 點數設定頁-編輯贈點設定
const patchGainPointRule = (brandId, gainPointRuleId, data) => {
  return fetchData(
    `setting/brand/${brandId}/point/gainpointrule/${gainPointRuleId}`,
    'PATCH',
    data,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
// 點數設定頁-新增贈點
const postGainPointRule = (brandId, data) => {
  return fetchData(
    `setting/brand/${brandId}/point/gainpointrule`,
    'POST',
    data,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
// 點數設定頁-刪除贈點
const deleteGainPointRule = (brandId, gainPointRuleId) => {
  return fetchData(
    `setting/brand/${brandId}/point/gainpointrule/${gainPointRuleId}`,
    'DELETE',
    null,
    commonFetchProps
  ).then((rsp) => {
    return rsp
  })
}
// 點數設定頁-刪除所有贈點
const deleteGainPointRules = (brandId) => {
  return fetchData(
    `setting/brand/${brandId}/point/gainpointrules`,
    'DELETE',
    null,
    commonFetchProps
  ).then((rsp) => {
    return rsp?.data?.data
  })
}
// 點數設定頁-停用贈點
const patchGainPointRuleStop = (brandId, gainPointRuleId) => {
  return fetchData(
    `setting/brand/${brandId}/point/gainpointrule/${gainPointRuleId}/stop`,
    'PATCH',
    null,
    commonFetchProps
  ).then((rsp) => {
    return rsp
  })
}
// 點數設定頁-開始計算
const patchPointStartCompute = (brandId, baseId) => {
  return fetchData(
    `setting/brand/${brandId}/point/base/${baseId}/startcompute`,
    'PATCH',
    null
  ).then((rsp) => {
    return rsp?.data?.data
  })
}

/*--------------------------------------
    會籍設定頁
----------------------------------------*/
// 會籍設定頁全部資料(不包含會員等級的表格資料)
const getMemberShip = async (brandId) => {
  try {
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    throw error
  }
}
// 會籍設定頁-會員等級資料(規則設定表格)
const getMemberShipLevels = async (brandId, queryStringObj) => {
  try {
    const searchParameter = getSearchParameter(queryStringObj)
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership/levels${searchParameter}`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    throw error
  }
}
// 會籍設定頁-獲得編輯會級單一row的資料
const getMemberShipLevelOnce = async (brandId, levelId) => {
  try {
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership/level/${levelId}`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    throw error
  }
}
// 會籍設定頁-編輯會級
const patchMemberShipLevel = async (brandId, levelId, data) => {
  try {
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership/level/${levelId}`,
      'PATCH',
      data,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    throw error
  }
}
// 會籍設定頁-新增會級
const postMemberShipLevel = async (brandId, data) => {
  try {
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership/level`,
      'POST',
      data,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    throw error
  }
}
// 會籍設定頁-編輯基本設定
const patchMemberShipBase = async (brandId, baseId, data) => {
  try {
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership/base/${baseId}`,
      'PATCH',
      data,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    throw error
  }
}
// 會籍設定頁-新增基本設定
const postMemberShipBase = async (brandId, data) => {
  try {
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership/base`,
      'POST',
      data,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    throw error
  }
}
// 會籍設定頁-開始計算
const patchMemberShipStartCompute = async (brandId, baseId) => {
  try {
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership/base/${baseId}/startcompute`,
      'PATCH',
      null
    )
    return rsp?.data?.data
  } catch (error) {
    handleError(error)
    throw error
  }
}
// 會籍設定頁-刪除會級
const deleteMemberShipLevel = async (brandId, levelId) => {
  try {
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership/level/${levelId}`,
      'DELETE',
      null,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    throw error
  }
}
// 會籍設定頁-刪除全部會級
const deleteMemberShipLevels = async (brandId) => {
  try {
    const rsp = await fetchData(
      `setting/brand/${brandId}/membership/levels`,
      'DELETE',
      null,
      commonFetchProps
    )
    return rsp?.data?.data
  } catch (error) {
    handleError(error)
    throw error
  }
}

// 帳號管理 - 取得帳號管理列表
const getAccountManagement = (brandId, queryStringObj) => {
  const searchParameter = getSearchParameter(queryStringObj)
  return fetchData(
    `account/auth/brand/${brandId}/accounts${searchParameter}`,
    'GET',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      return rsp?.data?.data
    })
    .catch(handleError)
}

// 帳號管理 - 刪除帳號
const deleteAccount = (brandId, itemId) => {
  return fetchData(
    `account/auth/brand/${brandId}/account/${itemId}`,
    'DELETE',
    null,
    commonFetchProps
  )
    .then((rsp) => {
      showToast(rsp, '刪除成功')
      return rsp?.data
    })
    .catch(handleError)
}

// 帳號管理 - 帳號啟用/停用
const patchAccountStatus = (brandId, itemId, data) => {
  return fetchData(
    `account/auth/brand/${brandId}/account/${itemId}/status`,
    'PATCH',
    data,
    commonFetchProps
  )
    .then((rsp) => {
      const message = data?.newStatus === 'activate' ? '啟用成功' : '停用成功'
      showToast(rsp, message)
      return rsp?.data
    })
    .catch(handleError)
}

// 帳號管理 - 驗證信 token 有效確認
const patchTokenValidity = async (token) => {
  try {
    const rsp = await fetchData(
      `account/auth/account/email`,
      'PATCH',
      token,
      commonFetchProps
    )
    return rsp?.data
  } catch (error) {
    handleError(error)
  }
}

// 帳號管理 - 重設密碼驗證信 token 有效確認
const getResetPasswordTokenValidity = async (token) => {
  try {
    const rsp = await fetchData(
      `account/auth/password/email?token=${token}`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data
  } catch (error) {
    handleError(error)
  }
}

// 帳號管理 - 設定密碼
const patchPasswordNew = async (data) => {
  try {
    const rsp = await fetchData(
      `account/auth/password/new`,
      'PATCH',
      data,
      commonFetchProps
    )
    return rsp?.data
  } catch (error) {
    handleError(error)
  }
}

// 帳號管理 - 重設密碼
const patchPasswordReset = async (data) => {
  try {
    const rsp = await fetchData(
      `account/auth/password/reset`,
      'PATCH',
      data,
      commonFetchProps
    )
    return rsp?.data
  } catch (err) {
    handleError(err)
  }
}

export {
  /* 檢測是否系統維護 */
  getIsMaintenance,
  /* 檢測批次上傳模組 */
  getSerialBatchModule,
  /* 登入頁 or 使用者相關資訊 */
  postAuth,
  getAuth,
  postPasswordReset,
  getAuthCompanies,
  getAuthCompanyBrands,
  getAuthCompanyBrandShops,
  getPermissions,
  /* 首頁 */
  getMemberDashboard,
  patchAuthChpwd,
  /* 數據分析頁 */
  getThemedDashboard,
  /* 會員查詢頁 */
  getMember,
  postMember,
  getMemberOnce,
  getMemberLevel,
  /* 會員資料頁 */
  patchMember,
  getMemberDealinfo,
  getMemberPointRecord,
  getMembershipRecord,
  deleteMember,
  patchUnbind,
  /* 點數設定頁 */
  getPoint,
  getGainPointRules,
  patchPointBase,
  postPointBase,
  getPointGainPointRuleOnce,
  patchGainPointRule,
  postGainPointRule,
  deleteGainPointRule,
  deleteGainPointRules,
  patchGainPointRuleStop,
  patchPointStartCompute,
  /* 會籍設定頁 */
  getMemberShip,
  getMemberShipLevels,
  getMemberShipLevelOnce,
  patchMemberShipLevel,
  postMemberShipLevel,
  patchMemberShipBase,
  postMemberShipBase,
  patchMemberShipStartCompute,
  deleteMemberShipLevel,
  deleteMemberShipLevels,
  /* 活動條碼 - 商品活動 */
  getProductActivityList,
  getProductActivityEvent,
  deleteProductActivityEvent,
  stopProductActivity,
  getPointproductrulemultiple,
  addProductActivityEvent,
  updateProductActivityEvent,
  getProductListList,
  addSingleProductListItem,
  batchDeleteProductItem,
  importProductItems,
  /* 活動條碼 - Qrcode 活動 */
  getQrcode,
  getQrcodeOnce,
  postQrcode,
  patchQrcode,
  deleteQrcode,
  patchStopQrcode,
  getGainPointRule,
  getGainPointRuleOnce,
  postQrcodeGainPointRule,
  patchQrcodeGainPointRule,
  deleteQrcodeGainPointRule,
  patchQrcodeGainPointRuleStop,
  getQrcodeExchangeTimesItems,
  getQrcodeGeneratedCode,
  getQrcodeGeneratedCodeDetail,
  postQrcodeGeneratedCode,
  deleteQrcodeGeneratedCode,
  deleteQrcodeGeneratedCodeBatch,
  patchQrcodeGeneratedCodeStop,
  getQrcodeExportFile,
  getQrcodeExportImage,
  /* 活動條碼 - 活動表單紀錄 */
  getQrcodeScanFailed,
  getQrcodeScanFailedOnce,
  patchQrcodeScanFailedStatus,
  getQrcodePointRecord,
  getQrcodePointRecordOnce,
  getQrcodePointRecordFieldInfo,
  getQrcodePointRecordDetail,
  /* 好禮贈送 - 贈品兌換 */
  getGiftList,
  copyEvent,
  deleteEvent,
  getEvent,
  updateEvent,
  addEvent,
  getMemberList,
  changeStatus,
  patchExchangeCodeRules,
  generateSystemExchangeCode,
  importExchangeCode,
  exportExchangeCode,
  getExchangeCodeList,
  getExchangeCodeDetail,
  addExchangeCode,
  switchExchangeCodeType,
  batchDeleteExchangeCode,
  getMemberOutline,
  /* 好禮贈送 - 專屬好禮 */
  getVipGiftList,
  addVipGift,
  getVipGiftInfo,
  updateVipGiftInfo,
  deleteVipGiftInfo,
  changeVipGiftStatus,
  copyVipGiftInfo,
  getVipExchangeCodeList,
  getVipExchangeCodeDetail,
  importVipExchangeCode,
  addVipExchangeCode,
  switchVipExchangeCodeType,
  batchDeleteVipExchangeCode,
  checkVipExchangeRule,
  getVipExchangedRecord,
  getVipExchangeRecordDetail,
  getVipExchangeLink,
  vipExchangeUnlink,
  getDesignatedList,
  /* 好禮贈送 - 好禮名單 */
  getGiftExchangeRecord,
  getGiftExchangeRecordDetail,
  getExchangeRecordDetail,
  getVipGiftExchangeRecord,
  getVipGiftExchangeRecordDetail,
  getGiftDelivery,
  getGiftDeliveryDetail,
  patchGiftDelivery,
  postNotificationGift,
  getGiftDeliveryExport,
  postImportProductItems,
  getValidateCodeSetting,
  getVipGiftReimburse,
  getVipGiftReimburseDetail,
  patchVipGiftReimburse,
  getGiftReimburse,
  getGiftReimburseDetail,
  patchGiftReimburse,
  /* 點數活動 API  */
  getPointActivityListApi,
  getPointActivitySettingApi,
  addBarcodeRuleApi,
  stopPointActivityApi,
  deletePointActivityApi,
  getEditPointActivityApi,
  editPointActivityApi,
  /* 點數歷程 API  */
  getPointHistoryListApi,
  getCustomIdApi,
  additionalPointApi,
  uploadFileApi,
  getPointHistoryDetail,
  /* 廠商Token  */
  getOpenApi,
  getOpenApiOnce,
  postOpenApi,
  deleteOpenApi,
  patchOpenApiStop,
  patchOpenApiActive,
  patchOpenApiExtend,
  /* 帳號管理  */
  getAccountManagement,
  patchTokenValidity,
  patchPasswordNew,
  patchPasswordReset,
  deleteAccount,
  patchAccountStatus,
  getResetPasswordTokenValidity,
}
