import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, withRouter, useHistory } from 'react-router-dom'
import { getIsMaintenance } from 'api/ApiMain'
import { PAGE_PATHS } from 'constant'
import { getReloadRedirectUrl } from 'helpers/common'
import PrivacyPolicy from 'pages/privacy-policy/PrivacyPolicy'
import { RootContext } from 'RootContext'
import PageRoutes from 'routers/PageRoutes'
import VerifyRoutes from 'routers/VerifyRouters'
import { authResultSelector } from 'store/auth/authSelector'
import { updateAppLoading } from 'store/global/globalSlice'

const WithVerifyRoutes = withRouter(VerifyRoutes)
const WithPageRoutes = withRouter(PageRoutes)

function AppRouter({ onChange }) {
  const rootData = useContext(RootContext)
  const history = useHistory()
  const authResult = useSelector(authResultSelector)
  const isVerify = history.location.pathname.includes('verify')
  const isPolicy = history.location.pathname === PAGE_PATHS.privacyPolicy

  const dispatch = useDispatch()
  const handleMaintenance = useCallback(() => {
    getIsMaintenance()
  }, [])

  useEffect(() => {
    if (
      (rootData?.brandId &&
        rootData?.shopId &&
        rootData?.prevRootData?.brandId &&
        rootData?.brandId !== rootData?.prevRootData?.brandId) ||
      (rootData?.prevRootData?.shopId &&
        rootData?.shopId !== rootData?.prevRootData?.shopId)
    ) {
      const redirectUrl = getReloadRedirectUrl(window.location.href)
      const redirectPathname = new URL(redirectUrl).pathname
      history.push(redirectPathname)
    }
  }, [history, rootData])

  useEffect(() => {
    // 路由監聽事件
    if (history) {
      history.listen(() => {
        handleMaintenance()
        onChange(history.location.pathname)
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(updateAppLoading(false))
    const { accessToken, isLoggedIn } = authResult
    const { state } = history.location

    if (!accessToken && !isLoggedIn && !isVerify && !isPolicy) {
      history.push(PAGE_PATHS.login)
    } else {
      history.push(history.location.pathname, state)
    }
  }, [authResult, dispatch, history, isPolicy, isVerify])
  return (
    <Switch>
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/verify" component={WithVerifyRoutes} />
      <Route path="/" component={WithPageRoutes} />
    </Switch>
  )
}

export default AppRouter

AppRouter.propTypes = {
  onChange: PropTypes.func.isRequired,
}
