import PropTypes from 'prop-types'
import { ReactComponent as IconCancelSvg } from 'assets/images/modal/icon_btn_cancel.svg'
import { ReactComponent as IconCheckSvg } from 'assets/images/modal/icon_btn_check.svg'
import { colorObj } from 'assets/styles/Variable/Color'
import { Modal, Table } from 'components/units'
import {
  PermissionTableWrapper,
  IconWrapper,
  IconInfo,
  ModuleCell,
  PermissionCell,
} from './RolePermissionModalStyle'

function IconCheck() {
  return (
    <IconWrapper color={colorObj.secondary}>
      <IconCheckSvg />
    </IconWrapper>
  )
}
function IconCancel() {
  return (
    <IconWrapper color={colorObj.gray}>
      <IconCancelSvg />
    </IconWrapper>
  )
}

const accessLevels = {
  viewOnly: '僅可檢視',
  deleteForbidden: '不可刪除',
  accessAll: <IconCheck />,
  accessForbidden: <IconCancel />,
}

const tableColumns = () => [
  {
    dataField: 'module',
    text: (
      <div className="table-slash-box">
        <div className="table-slash">
          <span className="title left-title">功能</span>
          <span className="title right-title">角色</span>
        </div>
      </div>
    ),
    formatter: (cell) => {
      return (
        <ModuleCell>
          <span>{cell}</span>
        </ModuleCell>
      )
    },
  },
  {
    dataField: 'admin',
    text: '總管理者',
    formatter: (cell) => {
      return (
        <PermissionCell>
          <span>{cell}</span>
        </PermissionCell>
      )
    },
    headerStyle: {
      verticalAlign: 'middle',
    },
  },
  {
    dataField: 'marketer',
    text: '行銷人員',
    formatter: (cell) => {
      return (
        <PermissionCell>
          <span>{cell}</span>
        </PermissionCell>
      )
    },
    headerStyle: {
      verticalAlign: 'middle',
    },
  },
  {
    dataField: 'customerService',
    text: '客服人員',
    formatter: (cell) => {
      return (
        <PermissionCell>
          <span>{cell}</span>
        </PermissionCell>
      )
    },
    headerStyle: {
      verticalAlign: 'middle',
    },
  },
  {
    dataField: 'storeStaff',
    text: '門店人員',
    formatter: (cell) => {
      return (
        <PermissionCell>
          <span>{cell}</span>
        </PermissionCell>
      )
    },
    headerStyle: {
      verticalAlign: 'middle',
    },
  },
  {
    dataField: 'viewer',
    text: '檢視者',
    formatter: (cell) => {
      return (
        <PermissionCell>
          <span>{cell}</span>
        </PermissionCell>
      )
    },
    headerStyle: {
      verticalAlign: 'middle',
    },
  },
]

const permissionData = [
  {
    module: '數據分析',
    admin: accessLevels.viewOnly,
    marketer: accessLevels.viewOnly,
    customerService: accessLevels.viewOnly,
    storeStaff: accessLevels.viewOnly,
    viewer: accessLevels.viewOnly,
  },
  {
    module: '會員資料',
    admin: accessLevels.accessAll,
    marketer: accessLevels.deleteForbidden,
    customerService: accessLevels.deleteForbidden,
    storeStaff: accessLevels.deleteForbidden,
    viewer: accessLevels.viewOnly,
  },
  {
    module: '會籍設定',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.viewOnly,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: '點數設定',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.viewOnly,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: '點數歷程',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.accessAll,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: '點數活動',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.viewOnly,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: '商品活動',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.viewOnly,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: 'QRCode 活動',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.viewOnly,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: '活動表單紀錄',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.accessAll,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: '專屬好禮',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.viewOnly,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: '贈品兌換',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.viewOnly,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: '好禮名單：專屬好禮',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.accessAll,
    storeStaff: accessLevels.accessAll,
    viewer: accessLevels.viewOnly,
  },
  {
    module: '好禮名單：贈品兌換',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.accessAll,
    storeStaff: accessLevels.accessAll,
    viewer: accessLevels.viewOnly,
  },
  {
    module: '好禮名單：宅配出貨',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.accessAll,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: '好禮名單：好禮核銷',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.accessAll,
    storeStaff: accessLevels.accessAll,
    viewer: accessLevels.viewOnly,
  },
  {
    module: '好禮名單：贈品核銷',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessAll,
    customerService: accessLevels.accessAll,
    storeStaff: accessLevels.accessAll,
    viewer: accessLevels.viewOnly,
  },
  {
    module: '帳號管理',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessForbidden,
    customerService: accessLevels.accessForbidden,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
  {
    module: '廠商 Token',
    admin: accessLevels.accessAll,
    marketer: accessLevels.accessForbidden,
    customerService: accessLevels.accessForbidden,
    storeStaff: accessLevels.accessForbidden,
    viewer: accessLevels.accessForbidden,
  },
]

const text = {
  title: '角色權限表',
  subTitle: '提供預設的角色群組，套用對應的功能權限',
}

function RolePermissionModal(props) {
  const { show, onClose } = props
  return (
    <Modal
      show={show}
      onClose={onClose}
      titleText={text.title}
      subTitleText={text.subTitle}
      closeBtnText="關閉"
      confirmBtnVisible={false}
      modalOption={{ maxwidth: '700px' }}
    >
      <PermissionTableWrapper>
        <Table
          headerClasses="table-header"
          bodyClasses="paragraph"
          className="overflow-auto"
          data={permissionData}
          columns={tableColumns()}
          keyField="no"
          striped
          rowStyle={{
            padding: '0px',
          }}
        />
      </PermissionTableWrapper>
      <IconInfo>
        <div className="icon-item">
          <IconCheck />
          <p>皆可操作</p>
        </div>
        <div className="icon-item">
          <IconCancel />
          <p>不可操作</p>
        </div>
      </IconInfo>
    </Modal>
  )
}

RolePermissionModal.defaultProps = {
  show: false,
  onClose: () => {},
}

RolePermissionModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
}

export default RolePermissionModal
