const initState = {
  currentCustomId: '',
  customId: '-',
  name: '-',
  description: '',
  forFreePoints: false,
  forBucklePoint: false,
  pointGain: 0,
  additionalInstructions: '',
  isShowCustomIdError: false,
}

/**
 * @description 補登點數初始化 state
 * @type {{}}
 */
export const recordRootState = {
  ...initState,
}

/**
 * @description 補登點數 Reducer
 * @param state
 * @param payload
 * @param type
 * @constructor
 */
export const PointRecordReducer = (state, { payload, type }) => {
  switch (type) {
    case 'CHANGE_FIELD': {
      return {
        ...state,
        [payload.keyName]: payload.value,
      }
    }

    case 'CHANGE_POINT_GAIN': {
      return {
        ...state,
        pointGain: payload,
      }
    }

    case 'CLEAR_FIELDS': {
      return {
        ...initState,
      }
    }

    case 'UPDATE_CUSTOM_ID_ERROR': {
      return {
        ...state,
        customId: '-',
        name: '-',
        isShowCustomIdError: payload,
      }
    }

    case 'UPDATE_MEMBER_INFO': {
      return {
        ...state,
        customId: payload.customId,
        name: payload.name,
        isShowCustomIdError: false,
      }
    }

    case 'UPDATE_POINT_TYPE': {
      return {
        ...state,
        forFreePoints: payload === 'forFreePoints',
        forBucklePoint: payload === 'forBucklePoint',
      }
    }
  }
}
