import { useState, useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import { showToast } from 'api/ApiCommon'
import { addExchangeCode } from 'api/ApiMain'
import { ReactComponent as TooltipSvg } from 'assets/images/icon_tooltip.svg'
import { color } from 'assets/styles/Variable/Color'
import { captionLabel } from 'components/pages/Gift/ExchangeSerialNumberConfig'
import {
  TextLink,
  AddSerialnumber,
} from 'components/pages/Gift/ExchangeSerialNumberStyle'
import { InValidFeedback } from 'components/templates'
import { Col, Button, Tooltip, Input, Loader } from 'components/units'
import { number } from 'helpers/format'
import { statusFormatter } from 'helpers/tableElFormat'
import { tabStatus } from 'pages/Gift/config'
import { modalType } from 'pages/Gift/ExchangeSetting'

function Caption({ paginationProps, isShowCaption, listTotal, uploadCount }) {
  const { totalCount, conditionalCount, conditionalUnexchangedCodeCount } =
    listTotal
  const isUploadDone = totalCount === uploadCount

  return (
    <div className="caption">
      <Col lg="6" className="label-title">
        <span className="white-space-nowrap">{captionLabel.total}</span>
        {conditionalCount > 0 ? (
          <div className="caption-text">{`未兌換 ${number(
            conditionalUnexchangedCodeCount
          )} / 共 ${number(conditionalCount)} 筆`}</div>
        ) : (
          <div className="caption-text">共 0 筆</div>
        )}
        <InValidFeedback show={isShowCaption || isUploadDone}>
          {`序號數不符合上架數量 ${number(uploadCount)}`}
        </InValidFeedback>
      </Col>
      <Col lg="6" className="label-title">
        <span className="white-space-nowrap mr-1">
          {captionLabel.sizeParPage}
        </span>
        <SizePerPageDropdownStandalone
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
      </Col>
    </div>
  )
}

// 用於「贈品序號 / 新增贈品」中的序號輸入 input
function InfoCell({ data, ...props }) {
  const {
    brandId,
    handleSearch,
    giftSettingId,
    disabled,
    updateFn,
    handleUniteModalSwitch,
    setTransparentMask,
    isSettingDone,
  } = props
  const [state, setState] = useState('')
  const [valid, setValid] = useState(null)
  const [invalidMsg, setInvalidMsg] = useState({})
  const [inputDisabled, setInputDisabled] = useState(false)
  const inputRef = useRef(null)

  const appendContentConfig = inputDisabled
    ? {
        appendContent: <Loader size="sm" colorType="primary" />,
        appendContentBgColor: '#fff',
        appendContentHaveBorder: false,
        isFocusCocatAppend: true,
      }
    : {}

  const handleChange = (e) => {
    setState(e.target.value)
    setValid(null)
  }

  const handleKeyUp = async (e) => {
    // 按下Enter鍵
    if (e.keyCode !== 13) return
    if (e.target.value === '') return
    setInputDisabled(true)
    setTransparentMask(true)
    try {
      const rsp = await addExchangeCode(brandId, giftSettingId, {
        giftCode: e.target.value,
      })
      const giftSettingStatus = rsp.data?.data?.giftSettingStatus
      updateFn(giftSettingStatus)
      await handleSearch()
      if (giftSettingStatus !== tabStatus.unavailable) {
        handleUniteModalSwitch(true, { type: modalType.goList })
      }
      showToast(rsp, '新增贈品序號成功')
      setState('')
    } catch (error) {
      const errors = error?.errors
      const errorMsgGiftCode = errors?.giftCode ?? []

      if (errorMsgGiftCode?.length > 0) {
        setValid(false)
        setInvalidMsg({
          giftCode: errorMsgGiftCode[0],
        })
      }
    }
    setInputDisabled(false)
    setTransparentMask(false)
  }

  useEffect(() => {
    if (isSettingDone) {
      inputRef.current.focus()
    }
  }, [isSettingDone])

  return (
    <AddSerialnumber>
      <Input
        formControlOption={{
          type: 'text',
          ref: inputRef,
          value: state,
          onChange: handleChange,
          onKeyUp: handleKeyUp,
          disabled: disabled || inputDisabled,
          placeholder: '請輸入符合序號規則的字元',
          maxLength: 512,
        }}
        {...appendContentConfig}
      />
      <InValidFeedback show={valid}>{invalidMsg?.giftCode}</InValidFeedback>
      <Form.Text>點擊 Enter 確認送出</Form.Text>
    </AddSerialnumber>
  )
}

function UploadBtn({ onChange }) {
  const inputRef = useRef(null)
  return (
    <>
      <Button variant={color.primary} onClick={() => inputRef.current.click()}>
        上傳檔案
      </Button>
      <Input
        formControlOption={{
          style: { display: 'none' },
          ref: inputRef,
          type: 'file',
          accept: '.csv',
          onChange: (e) => {
            const [file] = e.currentTarget.files
            if (typeof onChange === 'function') onChange(file)
          },
        }}
      />
    </>
  )
}

const formatterInfo = (props) =>
  function renderInfo(cell, data) {
    if (cell) return cell
    return <InfoCell cell={cell} data={data} {...props} />
  }

const headerFormatterInfo = () => (
  <>
    贈品序號
    <Tooltip triggerElement={<TooltipSvg />} globalOption={{ effect: 'solid' }}>
      新增後無法編輯
      <br />
      請先刪除，再次新增
    </Tooltip>
  </>
)

const headerFormatterStatus = (column, colIndex, { sortElement }) => (
  <>
    狀態
    <Tooltip triggerElement={<TooltipSvg />} globalOption={{ effect: 'solid' }}>
      依回傳資料
      <br />
      更新內容與狀態
    </Tooltip>
    {sortElement}
  </>
)

const formatterStatus = (cell, row) => {
  const { statusDescription } = row
  let statusParameter = [false, false, false]
  // 代碼對照
  // 0: 未兌換
  // 1: 已兌換
  // 2: 已領取
  // 90: 已過期
  switch (cell) {
    case 0:
      statusParameter = [false, false, true]
      break
    case 1:
      break
    case 2:
      statusParameter = [true, false, false]
      break
    case 90:
      statusParameter = [false, false, true]
      break
    default:
      statusParameter = [false, false, true, false]
      break
  }
  return statusFormatter(statusDescription, ...statusParameter)
}

const formatterMember = (props) =>
  function renderMember(cell, data) {
    const { memberId, memberLevel, memberCellPhone, memberName } = data
    const { onClick } = props
    const payload = {
      memberId,
      userInfo: {
        level: memberLevel,
        phone: memberCellPhone,
        name: memberName,
      },
    }
    const handleClick = () => typeof onClick === 'function' && onClick(payload)

    return <TextLink onClick={handleClick}>{cell}</TextLink>
  }

export {
  Caption,
  UploadBtn,
  formatterInfo,
  headerFormatterInfo,
  headerFormatterStatus,
  formatterStatus,
  formatterMember,
}
