import {
  createSelector,
  createSlice,
  createAsyncThunk,
  isAnyOf,
} from '@reduxjs/toolkit'
import { getSerialBatchModule } from 'api/ApiMain'
import { handleCloseToast } from 'components/units/Toast'
import ToastContent from 'components/units/ToastContent'
import { PAGE_PATHS } from 'constant'
import { UPLOAD_STAGE } from 'constant/toast'
import { getRandomId } from 'helpers/common'
import { SERIAL_BATCH_UPLOAD } from 'store/constants'

// Init Config
export const serialBatchUploadInitState = {
  isConnectionBuilt: false,
  batchUploadModuleRsp: {
    routes: [],
  },
  gift: {
    brandId: null,
    giftBrandSerialNo: null,
    giftSettingStatus: null,
    moduleName: '',
    fileName: '',
    giftName: '',
    totalCount: null,
    currentCount: null,
    successCount: null,
    failCount: null,
    reportLink: '',
    uploadSuccess: null,
    isServerError: false,
  },
  vipGift: {
    brandId: null,
    vipGiftBrandSerialNo: null,
    vipGiftSettingStatus: null,
    moduleName: '',
    fileName: '',
    vipGiftName: '',
    totalCount: null,
    currentCount: null,
    successCount: null,
    failCount: null,
    reportLink: '',
    uploadSuccess: null,
    isServerError: false,
  },
}

// Thunk
export const getSerialBatchModuleThunk = createAsyncThunk(
  `${SERIAL_BATCH_UPLOAD}/getSerialBatchModule`,
  async (brandId) => {
    try {
      const rsp = await getSerialBatchModule(brandId)
      return rsp
    } catch (err) {
      return err
    }
  }
)
// Slice
const serialBatchUploadSlice = createSlice({
  name: SERIAL_BATCH_UPLOAD,
  initialState: serialBatchUploadInitState,
  reducers: {
    startConnection(state) {
      //   action.payload 格式應為 { module: 'moduleA' }
      state.isConnectionBuilt = true
    },
    stopConnection(state, action) {
      const { module } = action.payload
      state[module] = {
        ...serialBatchUploadInitState[module],
      }
      state.batchUploadModuleRsp.routes =
        state.batchUploadModuleRsp.routes.filter(
          (route) => route?.name !== module
        )
      state.isConnectionBuilt = false
    },
    updateIsConnectionBuilt: (state, action) => {
      state.isConnectionBuilt = action.payload
    },
    updateSerialUploadProgress(state, action) {
      const { module, data } = action.payload
      if (data?.uploadSuccess === true) {
        // 將已完成的匯入中 toast 關閉，完成後的 toastId 改為亂數以防再次上傳時重複
        handleCloseToast(
          `${module}-${data.giftBrandSerialNo || data.vipGiftBrandSerialNo}`
        )
      }
      state[module] = { ...state[module], ...data }
    },
    resetSerialUploadProgress: (state, action) => {
      const { module } = action.payload
      state[module] = {
        ...serialBatchUploadInitState[module],
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getSerialBatchModuleThunk.fulfilled,
        getSerialBatchModuleThunk.rejected
      ),
      (state, action) => {
        if (action.payload) {
          state.batchUploadModuleRsp = action.payload
        }
      }
    )
  },
})

// Selector
export const signalrStatusSelector = createSelector(
  (state) => state[SERIAL_BATCH_UPLOAD].isConnectionBuilt,
  (isConnectionBuilt) => {
    return isConnectionBuilt
  }
)

export const batchModuleSelector = createSelector(
  (state) => state[SERIAL_BATCH_UPLOAD].batchUploadModuleRsp,
  (batchUploadModuleRsp) => {
    return batchUploadModuleRsp.routes.map((route) => route.name)
  }
)
export const batchToastSelector = createSelector(
  (state) => state[SERIAL_BATCH_UPLOAD].gift,
  (state) => state[SERIAL_BATCH_UPLOAD].vipGift,
  (gift, vipGift) => {
    const toastDataHelper = ({
      brandId,
      giftBrandSerialNo,
      vipGiftBrandSerialNo,
      giftSettingStatus,
      vipGiftSettingStatus,
      moduleName,
      fileName,
      giftName,
      vipGiftName,
      totalCount,
      currentCount,
      successCount,
      failCount,
      reportLink,
      uploadSuccess,
      isServerError,
    }) => {
      const toastTypeConfig = {
        true: 'completed',
        false: 'error',
        null: 'importing',
      }
      const toastType = toastTypeConfig[uploadSuccess]
      const redirectUrlConfig = {
        gift: PAGE_PATHS.gift.exchange,
        vipGift: PAGE_PATHS.gift.exclusive,
      }
      const isCompletedToast = toastType === UPLOAD_STAGE.COMPLETED
      const redirectUrl = redirectUrlConfig[moduleName] ?? ''
      const tab = giftSettingStatus || vipGiftSettingStatus
      const redirectTab = isCompletedToast ? tab : null

      const importContent = {
        content: fileName,
        progress: `${Math.round((currentCount / totalCount) * 100) || 0}`,
        footer: null,
        toastId: `${moduleName}-${giftBrandSerialNo || vipGiftBrandSerialNo}`,
      }

      const completedContent = {
        content: (
          <ToastContent successCount={successCount} failCount={failCount} />
        ),
        progress: null,
        footer: { reportLink, redirectUrl, redirectTab },
        toastId: getRandomId(), // 完成後的 toastId 改為亂數以防再次上傳時重複
      }

      const toastData = {
        type: toastType,
        isServerError,
        brandId,
        message: {
          module: moduleName,
          title: `No.${giftBrandSerialNo || vipGiftBrandSerialNo} ${
            giftName || vipGiftName
          }`,
          content: isCompletedToast
            ? completedContent.content
            : importContent.content,
          progress: isCompletedToast
            ? completedContent.progress
            : importContent.progress,
        },
        footer: isCompletedToast
          ? completedContent.footer
          : importContent.footer,
        toastId: isCompletedToast
          ? completedContent.toastId
          : importContent.toastId,
      }

      return toastData
    }
    const toasts = [gift, vipGift]
      .filter((item) => item.fileName)
      .map((item) => toastDataHelper(item))

    return toasts
  }
)

export const serialBatchUploadReducer = serialBatchUploadSlice.reducer
export const {
  startConnection,
  stopConnection,
  updateIsConnectionBuilt,
  updateSerialUploadProgress,
  resetSerialUploadProgress,
} = serialBatchUploadSlice.actions
