import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import { useDispatch, useSelector } from 'react-redux'
import {
  getQrcodeScanFailed,
  getQrcodeScanFailedOnce,
  patchQrcodeScanFailedStatus,
} from 'api/ApiMain'
import {
  Checkbox,
  Modal,
  NoDataIndication,
  Table,
  Tooltip,
} from 'components/units'
import PaginationInput from 'components/units/PaginationInput'
import { COMPONENT_PERMISSIONS } from 'constant'
import {
  ActivityFormRecordListContext,
  TabSectionContext,
} from 'context/pageContext'
import * as format from 'helpers/format'
import { dateFormatter, ellipsisFormatter } from 'helpers/tableElFormat'
import { RootContext } from 'RootContext'
import { updatePageLoading } from 'store/global/globalSlice'
import { isPermissionAllowed } from 'store/permission/permissionSlice'
import {
  searchSectionSetting,
  tabSectionSetting,
} from '../ActivityFormRecordListConfig'
import {
  TableImageStyle,
  GetPointFailDetailStyle,
  TableWrapperStyle,
} from '../ActivityFormRecordListStyle'

const { defaultSort } = searchSectionSetting
const { modalSetting } = tabSectionSetting
const { modalType, modalConfig } = modalSetting

// 表格中圖片處理
const imageFormatter = (cell) => {
  return (
    <TableImageStyle>
      <img src={cell} alt="QRcode" />
    </TableImageStyle>
  )
}

// 表格中內容過長要...的處理
const noteFormatter = (cell, row) => {
  const { prodcutBarCode, prodcutName } = row
  const itemName = <div>{ellipsisFormatter(cell, 2)}</div>
  const itemExtraDescription = prodcutBarCode && prodcutName && (
    <div className="extra-description mt-1">
      {ellipsisFormatter(
        `${prodcutBarCode + (prodcutName ? ` | ${prodcutName}` : '')}`,
        1
      )}
    </div>
  )
  return (
    <>
      {itemName}
      {itemExtraDescription}
    </>
  )
}

// 表格中紀錄checkbox是否勾選的狀態
const statusFormatter = (
  cell,
  row,
  rowIndex,
  { brandId, pointStatus, setPointStatus, handlePatchQrcodeScanFailedStatus },
  isDisabled
) => {
  const onChange = async (e) => {
    try {
      setPointStatus((prevState) => {
        const newStatus = [...prevState]
        newStatus[rowIndex] = e.target.checked
        return newStatus
      })
      const status = e.target.checked ? 'done' : 'pending'
      await handlePatchQrcodeScanFailedStatus(brandId, row.scanFailedId, status)
    } catch (error) {
      setPointStatus((prevState) => {
        const newStatus = [...prevState]
        newStatus[rowIndex] = !e.target.checked
        return newStatus
      })
    }
  }
  return (
    <Tooltip
      triggerElement={
        <Checkbox
          checked={!!pointStatus[rowIndex]}
          onChange={onChange}
          disabled={isDisabled}
        />
      }
      globalOption={{
        effect: 'solid',
      }}
    >
      標示處理狀態
    </Tooltip>
  )
}

// 表格的column設定
const columns = (
  brandId,
  pointStatus,
  showFailedModal,
  setPointStatus,
  handlePatchQrcodeScanFailedStatus,
  isDisabled
) => {
  const handleFailModalEvent = {
    onClick: (e, col, colIndex, row, rowIndex) => {
      showFailedModal(row, rowIndex, pointStatus, brandId)
    },
  }
  return [
    {
      dataField: 'createdTime',
      text: '日期',
      sort: true,
      classes: 'cursor-pointer',
      formatter: dateFormatter,
      events: handleFailModalEvent,
    },
    {
      dataField: 'customId',
      text: '會員編號',
      classes: 'cursor-pointer',
      events: handleFailModalEvent,
    },
    {
      dataField: 'memberName',
      text: '會員',
      classes: 'cursor-pointer',
      events: handleFailModalEvent,
    },
    {
      dataField: 'picture',
      text: '圖片',
      classes: 'cursor-pointer',
      formatter: imageFormatter,
      events: handleFailModalEvent,
    },
    {
      dataField: 'note',
      text: '說明',
      classes: 'cursor-pointer',
      formatter: noteFormatter,
      style: {
        maxWidth: '275px',
      },
      events: handleFailModalEvent,
    },
    {
      dataField: 'code',
      text: '序號',
      classes: 'cursor-pointer',
      events: handleFailModalEvent,
    },
    {
      dataField: 'status',
      text: '狀態',
      sort: true,
      formatter: (...params) => statusFormatter(...params, isDisabled),
      formatExtraData: {
        brandId,
        pointStatus,
        setPointStatus,
        handlePatchQrcodeScanFailedStatus,
      },
      classes: 'td-status',
    },
  ]
}

function GetPointFail() {
  const dispatch = useDispatch()
  const rootData = useContext(RootContext)

  const activityFormRecordListContext = useContext(
    ActivityFormRecordListContext
  )
  const { apiPayload, setApiPayload, tableRow, setTableRow, setTotalSize } =
    activityFormRecordListContext

  const tabSectionContext = useContext(TabSectionContext)
  const { paginationProps, paginationTableProps } = tabSectionContext
  const [modalState, setModalState] = useState({
    show: false,
    titleText: '',
    closeBtnText: '',
    closeBtnVisible: true,
    confirmBtnText: '',
    confirmBtnVisible: true,
    variant: '',
    childrenType: '',
    childrenPayload: {},
    onConfirm: null,
  })
  const { UPDATE } = COMPONENT_PERMISSIONS.activityFormRecord
  const isDisabled = !useSelector(isPermissionAllowed(UPDATE, 'or'))

  const [tableLoading, setTableLoading] = useState(false)

  // 表格中狀態欄位checkbox的勾選情況(只紀錄當下顯示中的row)
  const [pointStatus, setPointStatus] = useState([false])

  const getContent = (type, payload) => {
    switch (type) {
      case modalType.getPointFailInfo: {
        return (
          <GetPointFailDetail
            imageSrc={payload.imageSrc}
            basicInfo={payload.basicInfo}
            extraInfo={payload.extraInfo}
          />
        )
      }
      default:
        return null
    }
  }
  const showFailedModal = async (row, rowIndex, pointStatusParam, brandId) => {
    try {
      const getQrcodeScanFailedOnceDadta = await getQrcodeScanFailedOnce(
        row.scanFailedId,
        { brandId }
      )
      setModalState({
        ...modalState,
        show: true,
        titleText: modalConfig.getPointFailInfo.title,
        closeBtnText: modalConfig.getPointFailInfo.close,
        closeBtnVisible: true,
        confirmBtnText: modalConfig.getPointFailInfo.confirm,
        confirmBtnVisible: false,
        childrenType: modalType.getPointFailInfo,
        childrenPayload: {
          imageSrc: getQrcodeScanFailedOnceDadta?.picture,
          basicInfo: {
            list: [
              {
                label: '狀態',
                value: getQrcodeScanFailedOnceDadta?.status,
                id: 'status',
              },
              {
                label: '時間',
                value: getQrcodeScanFailedOnceDadta?.createdDatetime,
                id: 'createdDatetime',
              },
              {
                label: '會員',
                value: getQrcodeScanFailedOnceDadta?.memberName,
                id: 'memberName',
              },
              {
                label: '手機',
                value: getQrcodeScanFailedOnceDadta?.cellPhone,
                id: 'cellPhone',
              },
            ],
          },
          extraInfo: {
            list: [
              {
                label: '說明',
                value: getQrcodeScanFailedOnceDadta?.note,
                id: 'note',
              },
              {
                label: '序號',
                value: getQrcodeScanFailedOnceDadta?.code,
                id: 'code',
              },
            ],
          },
        },
      })
    } catch {
      setModalState({ ...modalState, state: false })
    }
  }

  const handleClose = () => {
    setModalState({
      ...modalState,
      show: false,
    })
  }

  // 處理表格中狀態欄位checkbox的勾選情況
  const handlePointStatus = (row) => {
    const arr = row.map((item) => item.isDone)
    setPointStatus(arr)
  }
  // table有remote屬性時，需要設定此事件來處理表格變化
  const handleTableChange = (type, data) => {
    if (Array.isArray(tableRow) && tableRow?.length > 0 && type === 'sort') {
      const { sortField, sortOrder } = data
      setApiPayload((prevState) => {
        return {
          ...prevState,
          sf: sortField,
          so: sortOrder,
        }
      })
    }
  }
  // 處理checkbox勾選狀態改變時，呼叫api
  const handlePatchQrcodeScanFailedStatus = async (
    brandId,
    scanFailedId,
    status
  ) => {
    if (brandId) {
      await patchQrcodeScanFailedStatus(scanFailedId, status, {
        brandId,
      })
    }
  }
  // 處理QRcode集點失敗的api呼叫
  const handleGetQrcodeScanFailed = useCallback(async () => {
    const brandId = rootData?.brandId
    if (brandId) {
      setTableRow([])
      setTableLoading(true)
      try {
        const qrcodeScanFailedData = await getQrcodeScanFailed({
          brandId,
          ...apiPayload,
        })
        setTableRow(qrcodeScanFailedData.rows || [])
        setTotalSize(qrcodeScanFailedData.totalSize)
      } catch {
        setTableRow([])
        setTotalSize(0)
      } finally {
        dispatch(updatePageLoading(false))
        setTableLoading(false)
      }
    }
  }, [rootData?.brandId, setTableRow, apiPayload, setTotalSize, dispatch])

  useEffect(() => {
    if (Array.isArray(tableRow)) {
      handlePointStatus(tableRow)
    }
  }, [tableRow])

  useEffect(() => {
    handleGetQrcodeScanFailed()
  }, [handleGetQrcodeScanFailed])

  return (
    <>
      <Caption paginationProps={paginationProps} />
      <TableWrapperStyle>
        <Table
          headerClasses="table-header"
          bodyClasses="paragraph"
          className="overflow-auto mt-3"
          keyField="scanFailedId"
          data={tableRow}
          columns={columns(
            rootData?.brandId,
            pointStatus,
            showFailedModal,
            setPointStatus,
            handlePatchQrcodeScanFailedStatus,
            isDisabled
          )}
          striped
          noDataIndication={
            <NoDataIndication
              isLoading={tableLoading}
              message={tableLoading ? '載入中...' : '尚無資料'}
            />
          }
          remote
          onTableChange={handleTableChange}
          defaultSorted={[
            {
              dataField: defaultSort.sf,
              order: defaultSort.so,
            },
          ]}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationTableProps}
        />
      </TableWrapperStyle>
      <div className="d-flex justify-content-center">
        <PaginationListStandalone
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationProps}
        />
        <PaginationInput
          onPageChange={paginationProps.onPageChange}
          page={paginationProps.page}
          sizePerPage={paginationProps.sizePerPage}
          totalSize={paginationProps.totalSize}
        />
      </div>
      <Modal
        show={modalState.show}
        titleText={modalState.titleText}
        closeBtnText={modalState.closeBtnText}
        closeBtnVisible={modalState.closeBtnVisible}
        confirmBtnText={modalState.confirmBtnText}
        confirmBtnVisible={modalState.confirmBtnVisible}
        variant={modalState.variant}
        onClose={handleClose}
      >
        {getContent(modalState.childrenType, modalState.childrenPayload)}
      </Modal>
    </>
  )
}

/* -------------------------------- 
  Caption 區塊
-----------------------------------*/
function Caption() {
  const tabSectionContext = useContext(TabSectionContext)
  const { paginationProps } = tabSectionContext

  return (
    <Row className="caption my-0">
      <Col className="label-title">
        <div>
          總項目：
          <span className="paragraph-info">
            {format.number(paginationProps.totalSize)} 筆
          </span>
        </div>
      </Col>
      <Col className="label-title d-flex justify-content-end">
        <span className="white-space-nowrap mr-1">每頁</span>
        <SizePerPageDropdownStandalone
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
      </Col>
    </Row>
  )
}
/* -------------------------------- 
  集點失敗詳細內容 Modal 區塊
-----------------------------------*/
function GetPointFailDetail({ imageSrc, basicInfo, extraInfo }) {
  return (
    <GetPointFailDetailStyle className="fz15 text-left">
      <div className="info-group">
        <img src={imageSrc} className="mb-4" alt="QRcode" />
        <ul className="basicinfo-list">
          {basicInfo.list.map((item, index) => {
            return (
              <li
                key={`basicinfo-list-item-${item.id}`}
                className={`${
                  index !== basicInfo.list.length - 1 ? 'mb-3' : ''
                }`}
              >
                <span className="list-title">{item.label}：</span>
                <span className="list-value">{item.value}</span>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="info-group">
        <ul className="extrainfo-list">
          {extraInfo.list.map((item, index) => {
            return (
              <li
                key={`extrainfo-list-item-${item.id}`}
                className={`d-flex ${
                  index !== extraInfo.list.length - 1 ? 'mb-3' : ''
                }`}
              >
                <span className="list-title white-space-nowrap">
                  {item.label}：
                </span>
                <span className="list-value">{item.value}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </GetPointFailDetailStyle>
  )
}

export default GetPointFail

GetPointFailDetail.propTypes = {
  imageSrc: PropTypes.string,
  basicInfo: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  extraInfo: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
}

GetPointFailDetail.defaultProps = {
  imageSrc: '',
  basicInfo: {
    list: [],
  },
  extraInfo: {
    list: [],
  },
}
