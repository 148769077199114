import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PasswordSettingForm from 'components/pages/passwordSetting/widgets/PasswordSettingForm'

import { showTopToast } from 'components/units/Toast'
import { VERIFY } from 'constant/verify'
import { clearAllLocalStorage } from 'helpers/common'
import { tokenSelector } from 'store/account-verify/accountVerifySlice'
import {
  passwordRspSelector,
  patchPasswordNewThunk,
  patchPasswordResetThunk,
} from 'store/password-setting/passwordSettingSlice'

function PasswordSettingContainer() {
  const dispatch = useDispatch()
  const { token, type } = useSelector(tokenSelector)
  const fetchResult = useSelector(passwordRspSelector)
  const history = useHistory()

  const handleSubmit = (formData) => {
    const { password } = formData
    const data = {
      token,
      password,
    }
    if (type === VERIFY.RESET_PASSWORD) {
      dispatch(patchPasswordResetThunk(data))
    } else {
      dispatch(patchPasswordNewThunk(data))
    }
  }

  useEffect(() => {
    const handleFetchResult = () => {
      const { toastType, toastMsg, redirectPath, isLogout } = fetchResult
      if (!toastType || !toastMsg || !redirectPath) return

      showTopToast(
        {
          message: toastMsg,
        },
        toastType,
        null
      )
      if (isLogout) {
        clearAllLocalStorage()
      }
      history.push(redirectPath)
    }
    handleFetchResult()
  }, [fetchResult, history, type])

  return <PasswordSettingForm onSubmit={handleSubmit} />
}

export default PasswordSettingContainer
