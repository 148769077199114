import { useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Loader } from 'components/units'
import { showTopToast } from 'components/units/Toast'
import { TOAST } from 'constant/common'
import { VERIFY } from 'constant/verify'
import { clearAllLocalStorage } from 'helpers/common'
import {
  patchTokenValidityThunk,
  getResetPasswordTokenValidityThunk,
  redirectDataSelector,
  updateToken,
  updateVerifyType,
  resetPasswordRedirectDataSelector,
} from 'store/account-verify/accountVerifySlice'

export default function Redirect() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { redirectPath, isFetch, isSuccess } = useSelector(redirectDataSelector)
  const {
    redirectPath: resetPasswordRedirectPath,
    isFetch: resetPasswordIsFetch,
    isReset,
  } = useSelector(resetPasswordRedirectDataSelector)
  const { token } = useParams()
  const searchParams = new URLSearchParams(history.location.search)
  const verifyTypeRef = useRef(searchParams.get('type'))
  const verifyType = verifyTypeRef.current

  const handleSetPasswordRedirect = useCallback(() => {
    if (isFetch && redirectPath) {
      if (isSuccess) {
        // 跳轉頁只需管理『已設定過密碼且 token 有效』的 toast 情況
        showTopToast(
          { message: '啟用帳號成功，請進行登入' },
          TOAST.SUCCESS,
          null
        )
        history.push(redirectPath)
      } else {
        history.push(redirectPath)
      }
    }
  }, [history, isFetch, isSuccess, redirectPath])

  const handleResetPasswordRedirect = useCallback(() => {
    if (resetPasswordIsFetch && resetPasswordRedirectPath) {
      if (isReset) {
        showTopToast(
          {
            message: '已完成重設密碼，請使用新密碼進行登入',
          },
          TOAST.ERROR,
          null
        )
        clearAllLocalStorage()
        history.push(resetPasswordRedirectPath)
      } else {
        history.push(resetPasswordRedirectPath)
      }
    }
  }, [history, isReset, resetPasswordIsFetch, resetPasswordRedirectPath])

  const handleRedirect = useCallback(
    (type) => {
      const redirectActions = {
        resetPassword: () => handleResetPasswordRedirect(),
        enableAccount: () => handleSetPasswordRedirect(),
      }
      redirectActions[type]()
    },
    [handleResetPasswordRedirect, handleSetPasswordRedirect]
  )

  useEffect(() => {
    if (token && verifyType) {
      dispatch(updateToken(token))
      dispatch(updateVerifyType(verifyType))
      if (verifyType === VERIFY.RESET_PASSWORD) {
        dispatch(getResetPasswordTokenValidityThunk(token))
      } else {
        dispatch(patchTokenValidityThunk(token))
      }
    }
  }, [dispatch, verifyType, token])

  useEffect(() => {
    if (verifyType && (isFetch || resetPasswordIsFetch)) {
      handleRedirect(verifyType)
    }
  }, [handleRedirect, isFetch, verifyType, resetPasswordIsFetch])

  return <Loader isHide={false} />
}
