import PropTypes from 'prop-types'
import {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
  Fragment,
  useCallback,
} from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMemberShip,
  getMemberShipLevels,
  getMemberShipLevelOnce,
  patchMemberShipBase,
  postMemberShipBase,
  patchMemberShipStartCompute,
  deleteMemberShipLevel,
  deleteMemberShipLevels,
} from 'api/ApiMain'
import { ReactComponent as IconCancel } from 'assets/images/button/icon_cancel.svg'
import { ReactComponent as IconEdit } from 'assets/images/button/icon_edit.svg'
import { ReactComponent as IconSave } from 'assets/images/button/icon_save.svg'
import iconTooltip from 'assets/images/icon_tooltip.svg'
import { colorObj } from 'assets/styles/Variable/Color'
import withPermission from 'components/hocs/withPermission'
import RuleSettingModal from 'components/pages/Member/RuleSettingModal'
import DeleteWarningModal from 'components/pages/MembershipSetting/DeleteWarningModal'
import handleCatch from 'components/pages/MembershipSetting/MembershipSettingHelper'
import {
  IconButton,
  Button,
  Card,
  Select,
  Table,
  Tooltip,
  NoDataIndication,
} from 'components/units'
import { ButtonList } from 'components/widgets'
import { COMPONENT_PERMISSIONS } from 'constant'
import { dataTypeTranslate, handleXSS } from 'helpers/common'
import { RootContext } from 'RootContext'
import {
  getGlobalState,
  updateDataLoading,
  updatePageLoading,
} from 'store/global/globalSlice'
import { isPermissionAllowed } from 'store/permission/permissionSlice'
import LocalStyle from './MembershipSettingStyle'
// Reducer 會籍設定頁按鈕狀態控制
const reducerBtnsState = (state, action) => {
  const newState = JSON.parse(JSON.stringify(state))
  const { type, task } = action

  switch (type) {
    case 'display':
    case 'loading':
    case 'disabled':
      if (task && Array.isArray(task)) {
        task.forEach((taskItem) => {
          Object.entries(taskItem).forEach(([taskType, taskData]) => {
            newState[type][taskType] = {
              ...newState[type][taskType],
              ...taskData,
            }
          })
        })
      }
      break
    default:
      break
  }
  return newState
}

function ConfirmBtn({ memberShipData, btnsState, onClick, isDataLoading }) {
  const date = memberShipData?.startCompute?.date
  const baseSettingId = memberShipData?.baseSetting?.id
  const disalbed = memberShipData?.startCompute?.disabled
  const btnLoading = btnsState?.loading?.others?.startCalculate
  const btnDisabled = btnsState?.disabled?.others?.startCalculate
  const { READ, CREATE } = COMPONENT_PERMISSIONS.membershipSetting
  const PermissionStartCalculateButton = withPermission(Button, CREATE)
  const PermissionDoneCalculateButton = withPermission(Button, READ)
  const isDisabledBtn =
    baseSettingId === 0 || btnDisabled || (disalbed && date) || isDataLoading

  return (
    <div className="position-relative text-right">
      {!date ? (
        <PermissionStartCalculateButton
          variant="outline-gray"
          isLoading={btnLoading}
          onClick={onClick}
          disabled={isDisabledBtn}
          loaderOption={{ colorType: 'dark' }}
        >
          開始計算
        </PermissionStartCalculateButton>
      ) : (
        <PermissionDoneCalculateButton
          variant="outline-gray"
          disabled
          loaderOption={{ colorType: 'dark' }}
        >
          {`此設定於 ${date} 生效，修改請聯繫 Migo 客服`}
        </PermissionDoneCalculateButton>
      )}
    </div>
  )
}

function NewLevelBtn({ memberShipData, btnsState, onClick, isDataLoading }) {
  const date = memberShipData?.startCompute?.date
  if (date) return null
  const baseSettingId = memberShipData?.baseSetting?.id
  const baseSettingStore = btnsState?.display?.baseSetting?.store
  const { CREATE } = COMPONENT_PERMISSIONS.membershipSetting
  const PermissionAddLevel = withPermission(Button, CREATE)
  return (
    <PermissionAddLevel
      variant="outline-primary"
      disabled={baseSettingId === 0 || baseSettingStore || isDataLoading}
      onClick={onClick}
      size="sm"
    >
      新增等級
    </PermissionAddLevel>
  )
}

function MembershipSetting() {
  const dispatch = useDispatch()
  const { isPageLoading, isDataLoading } = useSelector(getGlobalState)
  // global data
  const rootData = useContext(RootContext)
  const { brandId, shopId } = rootData
  // 會籍設定資料(基本設定+會員等級區塊)
  const [memberShipData, setMemberShipData] = useState(null)

  // 會籍設定頁所有按鈕觸發的狀態控制
  const [btnsState, dispatchBtnsState] = useReducer(reducerBtnsState, {
    display: {
      baseSetting: {
        store: false,
        cancel: false,
        edit: true,
      },
      pointSetting: {
        store: true,
      },
      others: {
        startCalculate: true,
      },
    },
    loading: {
      baseSetting: {
        store: false,
        cancel: false,
        edit: false,
      },
      pointSetting: {
        store: false,
      },
      others: {
        startCalculate: false,
      },
    },
    disabled: {
      baseSetting: {
        store: false,
        cancel: false,
        edit: false,
      },
      pointSetting: {
        store: false,
      },
      others: {
        startCalculate: false,
      },
    },
  })

  const { UPDATE, DELETE } = COMPONENT_PERMISSIONS.membershipSetting
  const PermissionBasicSettingButtonList = withPermission(ButtonList, UPDATE)

  // 基本設定的表單ref
  const baseSettingFormRef = useRef(null)

  // 基本設定的欄位值
  const [baseSettingInputValues, setBaseSettingInputValues] = useState({})

  // 基本設定欄位在編輯前的欄位值
  const [baseSettingPrevInputValues, setBaseSettingPrevInputValues] = useState(
    {}
  )

  // 基本設定欄位是否驗證
  const [baseSettingValidated, setBaseSettingValidated] = useState(false)

  // 基本設定修改"會級累積依"的條件後，控制是否彈出modal提醒視窗
  const [isShowDeleteWarning, setShowDeleteWarning] = useState(false)

  // 會員等級區塊，卡片區塊是否loading
  const [isLoadingRuleSetting, setLoadingRuleSetting] = useState(false)

  // 會員等級區塊，表格資料
  const [ruleSettingTableData, setRuleSettingTableData] = useState(null)

  // 會員等級區塊，表格狀態(目前排序的欄位名稱、目前是升冪還是降冪)
  const [ruleSettingTableState, setRuleSettingTableState] = useState({
    sortFieldName: 'level',
    sortOrder: 'desc',
  })

  // 會員等級區塊，新增等級modal顯示/隱藏
  const [isShowModalRuleSetting, setShowModalRuleSetting] = useState(false)

  // 會員等級區塊，新增、編輯等級modal視窗剛打開時是否要loading
  const [isLoadingModalRuleSetting, setLoadingModalRuleSetting] =
    useState(false)

  // 會員等級區塊，判斷modal打開時是要新增等級還是編輯會級
  const isEditModalRuleSetting = useRef(false)

  // 會員等級區塊，modal內所需的會員資料 (會員等級id:membershipLevelId、會級累積方式：accumulativeWay)
  const modalRuleSettingMembershipLevelOnceData = useRef({})

  // 會員等級區塊，modal內，編輯該項目的當前資料
  const ruleSettingEditOnceData = useRef(null)

  // 會員等級區塊的欄位值
  const [ruleSettingModalInputValues, setRuleSettingModalInputValues] =
    useState({})

  // functionButton欄位顯示
  const isHiddenFuncBtnCol =
    !useSelector(
      isPermissionAllowed([
        ...COMPONENT_PERMISSIONS.membershipSetting.UPDATE,
        ...COMPONENT_PERMISSIONS.membershipSetting.DELETE,
      ])
    ) || memberShipData?.startCompute?.disabled
  // select 的select值處理
  const handleSelected = (optionItems) => {
    if (Array.isArray(optionItems)) {
      const selectedOption = optionItems.find(
        (optionItem) => optionItem.selected
      )
      if (selectedOption) {
        return selectedOption
      }
      if (optionItems.length > 0) {
        const firstOption = optionItems[0]
        firstOption.selected = true
        return firstOption
      }
    }
    return null
  }

  // 基本設定的欄位初值處理
  const handleBaseSettingInitFieldValue = useCallback((baseSettingData) => {
    // 編輯前的值(取消編輯要顯示的)
    setBaseSettingPrevInputValues({
      membershipThresholdCategory: handleSelected(
        baseSettingData?.membershipThresholdCategoryList
      ),
      membershipUpgradeCategory: handleSelected(
        baseSettingData?.membershipUpgradeCategoryList
      ),
      membershipUpgradeTimeCategory: handleSelected(
        baseSettingData?.membershipUpgradeTimeCategoryList
      ),
      membershipUpgradeConsumptionPointCategory: handleSelected(
        baseSettingData?.membershipUpgradeConsumptionPointCategoryList
      ),
      membershipRenewConsumptionPointCategory: handleSelected(
        baseSettingData?.membershipRenewConsumptionPointCategoryList
      ),
      membershipDowngradeTimeCategory: handleSelected(
        baseSettingData?.membershipDowngradeTimeCategoryList
      ),
    })
    // 正在編輯改變的值
    setBaseSettingInputValues({
      membershipThresholdCategory: handleSelected(
        baseSettingData?.membershipThresholdCategoryList
      ),
      membershipUpgradeCategory: handleSelected(
        baseSettingData?.membershipUpgradeCategoryList
      ),
      membershipUpgradeTimeCategory: handleSelected(
        baseSettingData?.membershipUpgradeTimeCategoryList
      ),
      membershipUpgradeConsumptionPointCategory: handleSelected(
        baseSettingData?.membershipUpgradeConsumptionPointCategoryList
      ),
      membershipRenewConsumptionPointCategory: handleSelected(
        baseSettingData?.membershipRenewConsumptionPointCategoryList
      ),
      membershipDowngradeTimeCategory: handleSelected(
        baseSettingData?.membershipDowngradeTimeCategoryList
      ),
    })
  }, [])

  // 會籍設定的全部資料(不包含表格列表)從api獲取後的處理(
  const handleMemberShipData = useCallback(
    async (afterFn) => {
      try {
        dispatch(updateDataLoading(true))
        const getMemberShipData = await getMemberShip(brandId)
        const disabled = getMemberShipData?.startCompute?.disabled ?? false
        dispatchBtnsState({
          type: 'disabled',
          task: [{ others: { startCalculate: disabled } }],
        })
        setMemberShipData(getMemberShipData)
        modalRuleSettingMembershipLevelOnceData.current = {
          ...modalRuleSettingMembershipLevelOnceData.current,
          accumulativeWay: getMemberShipData?.modal?.accumulativeWay,
        }
        handleBaseSettingInitFieldValue(getMemberShipData?.baseSetting)
        if (typeof afterFn === 'function') {
          afterFn()
        }
      } catch (error) {
        handleCatch(error)
      } finally {
        dispatch(updateDataLoading(false))
      }
    },
    [brandId, dispatch, handleBaseSettingInitFieldValue]
  )

  // "開始計算"按鈕按下後的處理
  const handleStartCal = async (dispatchParam) => {
    dispatchParam({
      type: 'loading',
      task: [
        {
          others: {
            startCalculate: true,
          },
          baseSetting: {
            store: true,
          },
        },
      ],
    })
    dispatchParam({
      type: 'disabled',
      task: [
        {
          others: {
            startCalculate: true,
          },
        },
      ],
    })
    try {
      await patchMemberShipStartCompute(
        rootData?.brandId,
        memberShipData?.baseSetting?.id
      )
      handleMemberShipData(() => {
        dispatchParam({
          type: 'loading',
          task: [
            {
              others: {
                startCalculate: false,
              },
              baseSetting: {
                store: false,
              },
            },
          ],
        })
        dispatchParam({
          type: 'display',
          task: [
            {
              baseSetting: {
                store: false,
                cancel: false,
                edit: true,
              },
            },
          ],
        })
        dispatchParam({
          type: 'disabled',
          task: [
            {
              others: {
                startCalculate: false,
              },
            },
          ],
        })
      })
    } catch (error) {
      handleCatch(error, () => {
        dispatchParam({
          type: 'loading',
          task: [
            {
              others: {
                startCalculate: false,
              },
              baseSetting: {
                store: false,
              },
            },
          ],
        })
        dispatchParam({
          type: 'display',
          task: [
            {
              baseSetting: {
                store: false,
                cancel: false,
                edit: true,
              },
            },
          ],
        })
        dispatchParam({
          type: 'disabled',
          task: [
            {
              others: {
                startCalculate: false,
              },
            },
          ],
        })
      })
    }
  }
  // 基本設定的消費贈點，欄位驗證的warning判斷
  const handleBaseSettingUpgradeCategoryFieldsError = (
    formValidated,
    inputValues
  ) => {
    let isError = false
    if (formValidated) {
      if (!inputValues?.membershipUpgradeCategory) {
        isError = true
      } else {
        isError = false
      }
    }
    return isError
  }
  // 基本設定的升等時間，欄位驗證的warning判斷
  const handleBaseSettingUpgradeTimeFieldsError = (
    formValidated,
    inputValues
  ) => {
    let isError = false
    if (formValidated) {
      if (
        !inputValues?.membershipUpgradeTimeCategory ||
        !inputValues?.membershipUpgradeConsumptionPointCategory
      ) {
        isError = true
      } else {
        isError = false
      }
    }
    return isError
  }
  // 基本設定的欄位onChange處理
  const onChangeBaseSettingField = ({ target, ...prop }) => {
    /*
      name、value表示參數是傳入event來做處理
      finalName、finalValue表示參數是傳入欄位name跟value來做處理
      可以擇一選擇參數帶入處理方式  
    */
    const name = target?.name ?? null
    const value = target?.value ?? null
    let { fieldName, fieldValue } = prop
    fieldName = fieldName ?? null
    fieldValue = fieldValue ?? null
    const finalName = fieldName ?? name
    const finalValue = fieldValue ?? value

    if (target?.type === 'checkbox') {
      const { checked } = target
      setBaseSettingInputValues((prevInputValues) => {
        return {
          ...prevInputValues,
          [finalName]: checked,
        }
      })
    } else if (target?.type.includes('select')) {
      const { eventKey, optionItems } = prop
      setBaseSettingInputValues((prevInputValues) => {
        const newInputValues = optionItems[eventKey]
        newInputValues.selected = true
        return {
          ...prevInputValues,
          [finalName]: newInputValues,
        }
      })
    } else {
      setBaseSettingInputValues((prevInputValues) => {
        return {
          ...prevInputValues,
          [finalName]: finalValue,
        }
      })
    }
  }
  // 基本設定的取消事件處理
  const handleBaseSettingCancelBtnEvent = (dispatchParam) => {
    dispatchParam({
      type: 'display',
      task: [
        {
          baseSetting: {
            edit: true,
            cancel: false,
            store: false,
          },
        },
      ],
    })
    setBaseSettingInputValues(baseSettingPrevInputValues)
    setBaseSettingValidated(false)
  }
  // 基本設定的編輯事件處理
  const handleBaseSettingEditBtnEvent = (dispatchParam) => {
    dispatchParam({
      type: 'display',
      task: [
        {
          baseSetting: {
            edit: false,
            cancel: true,
            store: true,
          },
        },
      ],
    })
  }
  // 刪除所有會員等級區塊表格的資料
  const deleteRuleSettingTableData = async (afterFn) => {
    try {
      const deleteMemberShipLevelsPromise = deleteMemberShipLevels(
        rootData?.brandId
      )
      await deleteMemberShipLevelsPromise
      if (typeof afterFn === 'function') {
        afterFn()
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleRuleSettingTableData = useCallback(
    async (tableFilterCondition, afterFn) => {
      setLoadingRuleSetting(true)
      try {
        const getMemberShipLevelsData = await getMemberShipLevels(brandId, {
          sf: tableFilterCondition?.sortFieldName,
          so: tableFilterCondition?.sortOrder,
        })
        setRuleSettingTableData(getMemberShipLevelsData)
        if (typeof afterFn === 'function') {
          afterFn()
        }
      } catch (error) {
        handleCatch(error)
      } finally {
        setLoadingRuleSetting(false)
      }
    },
    [brandId]
  )
  // 基本設定中"會級累積依"的條件改變儲存後，modal開啟後，按下確認的處理事件
  const actionBaseSettingStoreBtnEvent = async (dispatchParam) => {
    setShowDeleteWarning(false)
    dispatchParam({
      type: 'loading',
      task: [
        {
          baseSetting: {
            store: true,
          },
        },
      ],
    })
    dispatchParam({
      type: 'disabled',
      task: [
        {
          others: {
            startCalculate: true,
          },
        },
      ],
    })
    // 編輯
    let apiFunction = patchMemberShipBase
    let parameter = [
      brandId,
      memberShipData?.baseSetting?.id,
      baseSettingInputValues,
    ]
    // 新增
    if (memberShipData?.baseSetting?.id === 0) {
      apiFunction = postMemberShipBase

      // postMemberShipBase 調用一次就得請後端改回來, 可此用 Dummy 檢查 payload
      // apiFunction = function postMemberShipBaseDummy(...parameters) {
      //   console.log('postMemberShipBase parameters:', parameters)
      // }
      parameter = [brandId, baseSettingInputValues]
    }
    // 將字串的欄位轉為number
    Object.keys(baseSettingInputValues).forEach((inputName) => {
      const inputItemValue = baseSettingInputValues[inputName]
      if (
        typeof inputItemValue === 'string' ||
        typeof inputItemValue === 'number'
      ) {
        baseSettingInputValues[inputName] = dataTypeTranslate(
          inputItemValue,
          'number'
        )
      }
    })

    try {
      await apiFunction.call(this, ...parameter)
      setBaseSettingValidated(false)
      handleMemberShipData(() => {
        dispatchParam({
          type: 'display',
          task: [
            {
              baseSetting: {
                edit: true,
                cancel: false,
                store: false,
              },
            },
          ],
        })
        dispatchParam({
          type: 'loading',
          task: [
            {
              baseSetting: {
                store: false,
              },
            },
          ],
        })
        dispatchParam({
          type: 'disabled',
          task: [
            {
              others: {
                startCalculate: false,
              },
            },
          ],
        })
      })
      // 有提醒視窗
      if (isShowDeleteWarning) {
        deleteRuleSettingTableData(() => {
          handleRuleSettingTableData(ruleSettingTableState)
        })
      }
    } catch (error) {
      handleCatch(error, () => {
        dispatchParam({
          type: 'loading',
          task: [
            {
              baseSetting: {
                store: false,
              },
            },
          ],
        })
      })
    }
  }

  // 基本設定的儲存事件處理(編輯、新增)
  const handleBaseSettingStoreBtnEvent = (e, dispatchParam) => {
    e.preventDefault()
    const form = baseSettingFormRef.current
    const isValidated = form.checkValidity()
    setBaseSettingValidated(true)

    // form的欄位判斷都通過後，再進行特別判斷
    if (isValidated) {
      // 特別判斷
      const isExtraValidated =
        !handleBaseSettingUpgradeCategoryFieldsError(
          true,
          baseSettingInputValues
        ) &&
        !handleBaseSettingUpgradeTimeFieldsError(true, baseSettingInputValues)

      if (isExtraValidated) {
        if (
          // 會級累積依...條件是否與上次儲存前不一致
          baseSettingPrevInputValues?.membershipUpgradeCategory?.id !==
            baseSettingInputValues?.membershipUpgradeCategory?.id &&
          // 會員等級區塊的表格中，是否有資料
          Array.isArray(ruleSettingTableData?.rows) &&
          ruleSettingTableData?.rows?.length > 0
        ) {
          setShowDeleteWarning(true)
        } else {
          actionBaseSettingStoreBtnEvent(dispatchParam)
        }
      }
    }
  }

  // 基本設定中"會級累積依"的條件改變後，modal開啟/關閉處理
  const handleDeleteWarningModalShow = (boolean) => {
    setShowDeleteWarning(boolean)
  }

  // 把 API 資料轉換為 select 的 option 的格式
  // 未來有機會做成 redux selector
  const convertToSelectOptions = (arrData) => {
    if (Array.isArray(arrData)) {
      return arrData.map((dataItem, dataIndex) => {
        const { name, id } = dataItem
        return {
          text: name,
          value: id,
          dropdownItemOption: {
            eventKey: dataIndex,
          },
        }
      })
    }
    return []
  }

  // 會員等級區塊modal顯示視窗
  const handleRuleSettingModalShow = (bool) => setShowModalRuleSetting(bool)
  // 會員等級區塊的新增等級功能
  const handleRuleSettingCreate = (tableData) => {
    isEditModalRuleSetting.current = false
    handleRuleSettingModalShow(true)
    if (Array.isArray(tableData) && tableData.length > 0) {
      modalRuleSettingMembershipLevelOnceData.current = {
        ...modalRuleSettingMembershipLevelOnceData.current,
        membershipLevelId: undefined,
      }
    }
    setRuleSettingModalInputValues({})
  }
  // 會員等級區塊的編輯功能
  const handleRuleSettingEdit = async (tableData, index) => {
    isEditModalRuleSetting.current = true
    setRuleSettingModalInputValues({})
    handleRuleSettingModalShow(true)
    if (tableData) {
      modalRuleSettingMembershipLevelOnceData.current = {
        ...modalRuleSettingMembershipLevelOnceData.current,
        membershipLevelId: tableData?.[index]?.membershipLevelId,
      }
      setLoadingModalRuleSetting(true)
      try {
        const getMemberShipLevelOnceData = await getMemberShipLevelOnce(
          brandId,
          modalRuleSettingMembershipLevelOnceData.current.membershipLevelId
        )
        ruleSettingEditOnceData.current = getMemberShipLevelOnceData
        modalRuleSettingMembershipLevelOnceData.current = {
          ...modalRuleSettingMembershipLevelOnceData.current,
        }
        setRuleSettingModalInputValues(getMemberShipLevelOnceData || {})
      } catch (error) {
        handleCatch(error)
      } finally {
        setLoadingModalRuleSetting(false)
      }
    }
  }
  // 會員等級區塊的刪除功能
  const handleRuleSettingRemove = async (tableData, index) => {
    if (tableData) {
      setLoadingRuleSetting(true)
      const membershipLevelId = tableData?.[index]?.membershipLevelId
      try {
        await deleteMemberShipLevel(brandId, membershipLevelId)
        toast.success('刪除會級成功')
        handleRuleSettingTableData(ruleSettingTableState)
      } catch (error) {
        handleCatch(error)
      } finally {
        setLoadingRuleSetting(false)
      }
    }
  }

  // 會員等級區塊表格的等級內容呈現
  const levelFormatter = (cell) => {
    if (cell) {
      const { code, isLowest } = cell
      return (
        <>
          {code}
          {isLowest && <span className="level-lowest-notation ml-1">最低</span>}
        </>
      )
    }
    return null
  }
  // 會員等級區塊表格的升等條件內容呈現
  const upgradeFormatter = (cell) => {
    if (cell?.text) {
      return (
        <div className="table-cell-condition">
          <div
            className={cell?.isManual ? 'd-inline-block mr-1' : undefined}
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: handleXSS(cell?.text) }}
          />
          {cell?.isManual && (
            <Tooltip
              triggerElement={<img src={iconTooltip} alt="" />}
              globalOption={{
                effect: 'solid',
              }}
            >
              若新增下一等級，
              <br /> 需同為「依資料更新」
            </Tooltip>
          )}
        </div>
      )
    }
    return null
  }

  // 會員等級區塊表格的續會條件內容呈現
  const renewFormatter = (cell) => {
    if (cell?.text) {
      return (
        <div className="table-cell-condition">
          <div
            className={cell?.isManual ? 'd-inline-block mr-1' : undefined}
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: handleXSS(cell?.text) }}
          />
        </div>
      )
    }
    return null
  }
  // 會員等級區塊表格的會員效期內容呈現
  const validPeriodFormatter = (cell) => {
    if (cell) {
      const { name } = cell
      return <span className="d-inline-flex align-items-center">{name}</span>
    }
    return null
  }
  // 會員等級區塊表格的狀態內容呈現
  const buttonStatusFormatter = (
    cell,
    row,
    rowIndex,
    { btnsState: btnsStateParam }
  ) => {
    const PermissionEditIcon = withPermission(IconButton, UPDATE)
    const PermissionDeleteIcon = withPermission(IconButton, DELETE)

    return (
      <span className="icon-feature-container d-flex align-items-center">
        {Object.keys(cell).map((state) => {
          let triggerElement = null
          if (cell[state].visible) {
            switch (state) {
              case 'editBtn':
                triggerElement = (
                  <PermissionEditIcon
                    type="edit"
                    tooltip="編輯"
                    onClick={() => {
                      handleRuleSettingEdit(
                        ruleSettingTableData?.rows ?? [],
                        rowIndex
                      )
                    }}
                    className={`setting-img ${
                      btnsStateParam?.display?.baseSetting?.store
                        ? 'custom-disabled'
                        : ''
                    }`}
                  />
                )
                break
              case 'deleteBtn':
                triggerElement = (
                  <PermissionDeleteIcon
                    color={colorObj.lightGray}
                    tooltip="刪除"
                    type="delete"
                    onClick={() => {
                      handleRuleSettingRemove(
                        ruleSettingTableData?.rows ?? [],
                        rowIndex
                      )
                    }}
                    className={`setting-img ${
                      btnsStateParam?.display?.baseSetting?.store
                        ? 'custom-disabled'
                        : ''
                    }`}
                  />
                )
                break
              default:
                break
            }
          }
          return (
            <Fragment key={`icon-feature-${rowIndex}-${state}`}>
              {triggerElement}
            </Fragment>
          )
        })}
      </span>
    )
  }

  // 會員等級區塊表格的body內容
  const tableCols = [
    // 此行為修正 isHidden 參數無法作用的 walkaround
    { dataField: '', text: '', hidden: true },
    {
      dataField: 'level',
      text: '等級',
      sort: true,
      formatter: levelFormatter,
      sortValue: (cell, row) => {
        return row.membershipLevelId
      },
    },
    { dataField: 'name', text: '名稱' },
    {
      dataField: 'upgrade',
      text: '升等條件',
      formatter: upgradeFormatter,
    },
    {
      dataField: 'renew',
      text: '續會條件',
      formatter: renewFormatter,
    },
    {
      dataField: 'validPeriod',
      text: '會員效期',
      formatter: validPeriodFormatter,
    },
    {
      dataField: 'funcButton',
      text: '',
      formatter: buttonStatusFormatter,
      formatExtraData: {
        btnsState,
      },
      style: () => {
        return {
          minWidth: '75px',
        }
      },
      hidden: isHiddenFuncBtnCol,
    },
  ]

  // 會員等級區塊的表格事件變動
  const handleTableChange = (type, newState) => {
    if (
      Array.isArray(ruleSettingTableData?.rows) &&
      ruleSettingTableData?.rows?.length > 0 &&
      type === 'sort'
    ) {
      const postData = {
        ...ruleSettingTableState,
        sortFieldName: newState.sortField,
        sortOrder: newState.sortOrder,
      }
      setRuleSettingTableState(postData)
      handleRuleSettingTableData(postData)
    }
  }

  useEffect(() => {
    if (brandId || brandId === 0) {
      const memberShipPromise = handleMemberShipData()
      const ruleSettingTablePromise = handleRuleSettingTableData(
        ruleSettingTableState
      )
      Promise.all([memberShipPromise, ruleSettingTablePromise]).finally(() => {
        dispatch(updatePageLoading(false))
      })
    }
  }, [
    brandId,
    shopId,
    handleMemberShipData,
    handleRuleSettingTableData,
    ruleSettingTableState,
    dispatch,
  ])

  return (
    <LocalStyle>
      <div className="membership-setting-container">
        <Container>
          <Row>
            <Col className="no-padding">
              <h1>會籍設定</h1>
            </Col>
            <Col className="no-padding">
              <div className="position-relative text-right">
                <ConfirmBtn
                  memberShipData={memberShipData}
                  btnsState={btnsState}
                  onClick={() => handleStartCal(dispatchBtnsState)}
                  isDataLoading={isDataLoading}
                />
              </div>
            </Col>
          </Row>
          <Row className="margin-t-24 mb-0">
            <Card className="w-100" isLoading={isDataLoading}>
              <Form
                noValidate
                validated={baseSettingValidated}
                ref={baseSettingFormRef}
              >
                <Form.Row>
                  <Col className="no-padding">
                    <h3 className="migo-blue">基本設定</h3>
                  </Col>
                  <Col className="no-padding text-right">
                    <PermissionBasicSettingButtonList className="justify-content-end">
                      {btnsState?.display?.baseSetting?.cancel &&
                        !memberShipData?.startCompute?.date && (
                          <Button
                            className="basetting-button btn-cancel"
                            variant="outline-gray"
                            onClick={() =>
                              handleBaseSettingCancelBtnEvent(dispatchBtnsState)
                            }
                            disabled={btnsState?.disabled?.baseSetting?.cancel}
                            size="md"
                          >
                            <IconCancel />
                            取消
                          </Button>
                        )}
                      {btnsState?.display?.baseSetting?.store &&
                        !memberShipData?.startCompute?.date && (
                          <Button
                            className="basetting-button"
                            variant="outline-primary"
                            size="md"
                            onClick={(e) =>
                              handleBaseSettingStoreBtnEvent(
                                e,
                                dispatchBtnsState
                              )
                            }
                            disabled={btnsState?.disabled?.baseSetting?.store}
                          >
                            <IconSave />
                            儲存
                          </Button>
                        )}
                      {btnsState?.display?.baseSetting?.edit &&
                        !memberShipData?.startCompute?.date && (
                          <Button
                            className="basetting-button margin-l-16"
                            variant="outline-gray"
                            onClick={() =>
                              handleBaseSettingEditBtnEvent(dispatchBtnsState)
                            }
                            disabled={btnsState?.disabled?.baseSetting?.edit}
                            size="md"
                          >
                            <IconEdit />
                            編輯
                          </Button>
                        )}
                    </PermissionBasicSettingButtonList>
                  </Col>
                </Form.Row>
                <Form.Row className="margin-t-24 mb-3">
                  <Col className="no-padding">
                    <div className="base-setting-container fz15 d-flex">
                      <span className="base-setting-title white-space-nowrap">
                        會籍門檻：
                      </span>
                      <div className="base-setting-content">
                        <Select
                          optionItems={convertToSelectOptions(
                            memberShipData?.baseSetting
                              ?.membershipThresholdCategoryList
                          )}
                          selectedValue={
                            baseSettingInputValues?.membershipThresholdCategory
                              ?.id
                          }
                          isClearSelectedValue={
                            !baseSettingInputValues?.membershipThresholdCategory
                          }
                          formControlOption={{
                            placeholder: '請選擇',
                            name: 'membershipThresholdCategory',
                            isInvalid:
                              baseSettingValidated &&
                              !baseSettingInputValues?.membershipThresholdCategory,
                          }}
                          dropdownToggleOption={{
                            disabled: btnsState?.display?.baseSetting?.edit,
                          }}
                          // 這邊的onChange並非對應到formControl的onChange，因此才需要另外設計成以下格式
                          onChange={(
                            event,
                            value,
                            formControlRef,
                            eventKey
                          ) => {
                            onChangeBaseSettingField({
                              target: formControlRef,
                              optionItems:
                                memberShipData?.baseSetting
                                  ?.membershipThresholdCategoryList,
                              eventKey,
                            })
                          }}
                        />
                        {handleBaseSettingUpgradeCategoryFieldsError(
                          baseSettingValidated,
                          baseSettingInputValues
                        ) && (
                          <div className="custom-invalid-feedback w-100">
                            尚未填寫
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row className="mt-3 mb-3">
                  <Col className="no-padding">
                    <div className="base-setting-container fz15 d-flex">
                      <span className="base-setting-title white-space-nowrap">
                        會籍依據：
                      </span>
                      <div className="base-setting-content">
                        <Select
                          optionItems={convertToSelectOptions(
                            memberShipData?.baseSetting
                              ?.membershipUpgradeCategoryList
                          )}
                          selectedValue={
                            baseSettingInputValues?.membershipUpgradeCategory
                              ?.id
                          }
                          isClearSelectedValue={
                            !baseSettingInputValues?.membershipUpgradeCategory
                          }
                          formControlOption={{
                            placeholder: '請選擇',
                            name: 'membershipUpgradeCategory',
                            isInvalid:
                              baseSettingValidated &&
                              !baseSettingInputValues?.membershipUpgradeCategory,
                          }}
                          dropdownToggleOption={{
                            disabled: btnsState?.display?.baseSetting?.edit,
                          }}
                          // 這邊的onChange並非對應到formControl的onChange，因此才需要另外設計成以下格式
                          onChange={(
                            event,
                            value,
                            formControlRef,
                            eventKey
                          ) => {
                            onChangeBaseSettingField({
                              target: formControlRef,
                              optionItems:
                                memberShipData?.baseSetting
                                  ?.membershipUpgradeCategoryList,
                              eventKey,
                            })
                          }}
                        />
                        {handleBaseSettingUpgradeCategoryFieldsError(
                          baseSettingValidated,
                          baseSettingInputValues
                        ) && (
                          <div className="custom-invalid-feedback w-100">
                            尚未填寫
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row className="mt-3 mb-3">
                  <Col className="no-padding">
                    <div className="base-setting-container fz15 d-flex">
                      <span className="base-setting-title white-space-nowrap">
                        升等規則：
                      </span>
                      <div className="base-setting-content">
                        會員效期內，符合升等條件
                        <Select
                          className="mx-1"
                          optionItems={convertToSelectOptions(
                            memberShipData?.baseSetting
                              ?.membershipUpgradeTimeCategoryList
                          )}
                          selectedValue={
                            baseSettingInputValues
                              ?.membershipUpgradeTimeCategory?.id
                          }
                          isClearSelectedValue={
                            !baseSettingInputValues?.membershipUpgradeTimeCategory
                          }
                          formControlOption={{
                            placeholder: '請選擇',
                            name: 'membershipUpgradeTimeCategory',
                            isInvalid:
                              baseSettingValidated &&
                              !baseSettingInputValues?.membershipUpgradeTimeCategory,
                          }}
                          dropdownToggleOption={{
                            disabled: btnsState?.display?.baseSetting?.edit,
                          }}
                          // 這邊的onChange並非對應到formControl的onChange，因此才需要另外設計成以下格式
                          onChange={(
                            event,
                            value,
                            formControlRef,
                            eventKey
                          ) => {
                            onChangeBaseSettingField({
                              target: formControlRef,
                              optionItems:
                                memberShipData?.baseSetting
                                  ?.membershipUpgradeTimeCategoryList,
                              eventKey,
                            })
                          }}
                        />
                        ，累積消費金額/點數
                        <Select
                          className="mx-1"
                          optionItems={convertToSelectOptions(
                            memberShipData?.baseSetting
                              ?.membershipUpgradeConsumptionPointCategoryList
                          )}
                          selectedValue={
                            baseSettingInputValues
                              ?.membershipUpgradeConsumptionPointCategory?.id
                          }
                          isClearSelectedValue={
                            !baseSettingInputValues?.membershipUpgradeConsumptionPointCategory
                          }
                          formControlOption={{
                            placeholder: '請選擇',
                            name: 'membershipUpgradeConsumptionPointCategory',
                            isInvalid:
                              baseSettingValidated &&
                              !baseSettingInputValues?.membershipUpgradeConsumptionPointCategory,
                          }}
                          dropdownToggleOption={{
                            disabled: btnsState?.display?.baseSetting?.edit,
                          }}
                          // 這邊的onChange並非對應到formControl的onChange，因此才需要另外設計成以下格式
                          onChange={(
                            event,
                            value,
                            formControlRef,
                            eventKey
                          ) => {
                            onChangeBaseSettingField({
                              target: formControlRef,
                              optionItems:
                                memberShipData?.baseSetting
                                  ?.membershipUpgradeConsumptionPointCategoryList,
                              eventKey,
                            })
                          }}
                        />
                        {handleBaseSettingUpgradeTimeFieldsError(
                          baseSettingValidated,
                          baseSettingInputValues
                        ) && (
                          <div className="custom-invalid-feedback w-100">
                            尚未填寫
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row className="mt-3 mb-3">
                  <Col className="no-padding">
                    <div className="base-setting-container fz15 d-flex">
                      <span className="base-setting-title white-space-nowrap">
                        續會規則：
                      </span>
                      <div className="base-setting-content">
                        會籍到期後，符合續會條件隔日續會，累積消費金額/點數
                        <Select
                          className="mx-1"
                          optionItems={convertToSelectOptions(
                            memberShipData?.baseSetting
                              ?.membershipRenewConsumptionPointCategoryList
                          )}
                          selectedValue={
                            baseSettingInputValues
                              ?.membershipRenewConsumptionPointCategory?.id
                          }
                          isClearSelectedValue={
                            !baseSettingInputValues?.membershipRenewConsumptionPointCategory
                          }
                          formControlOption={{
                            placeholder: '請選擇',
                            name: 'membershipRenewConsumptionPointCategory',
                            isInvalid:
                              baseSettingValidated &&
                              !baseSettingInputValues?.membershipRenewConsumptionPointCategory,
                          }}
                          dropdownToggleOption={{
                            disabled: btnsState?.display?.baseSetting?.edit,
                          }}
                          onChange={(
                            event,
                            value,
                            formControlRef,
                            eventKey
                          ) => {
                            onChangeBaseSettingField({
                              target: formControlRef,
                              optionItems:
                                memberShipData?.baseSetting
                                  ?.membershipRenewConsumptionPointCategoryList,
                              eventKey,
                            })
                          }}
                        />
                        {handleBaseSettingUpgradeTimeFieldsError(
                          baseSettingValidated,
                          baseSettingInputValues
                        ) && (
                          <div className="custom-invalid-feedback w-100">
                            尚未填寫
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row className="mt-3">
                  <Col className="no-padding">
                    <div className="base-setting-container fz15 d-flex">
                      <span className="base-setting-title white-space-nowrap">
                        降級規則：
                      </span>
                      <div className="base-setting-content base-setting-content-downgrade-time">
                        <Select
                          optionItems={convertToSelectOptions(
                            memberShipData?.baseSetting
                              ?.membershipDowngradeTimeCategoryList
                          )}
                          selectedValue={
                            baseSettingInputValues
                              ?.membershipDowngradeTimeCategory?.id
                          }
                          formControlOption={{
                            name: 'membershipDowngradeTimeCategory',
                          }}
                          dropdownToggleOption={{
                            disabled: true,
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Form.Row>
              </Form>
            </Card>
          </Row>
          <Row className="margin-t-24 mb-40">
            <Card className="w-100">
              <div>
                <Row>
                  <Col>
                    <h3 className="migo-blue">會員等級</h3>
                  </Col>
                  <Col className="label-title text-right d-flex align-items-center justify-content-end">
                    <NewLevelBtn
                      memberShipData={memberShipData}
                      btnsState={btnsState}
                      onClick={() => {
                        isEditModalRuleSetting.current = false
                        const rowsLength =
                          ruleSettingTableData?.rows?.length ?? 0
                        handleRuleSettingCreate(
                          ruleSettingTableData?.rows ?? [],
                          rowsLength - 1
                        )
                      }}
                      isDataLoading={isDataLoading}
                    />
                  </Col>
                </Row>
                <Row className="margin-t-24 mb-0">
                  <Col>
                    <Table
                      remote
                      className="table-rule-setting"
                      headerClasses="table-header"
                      bodyClasses="paragraph"
                      keyField="membershipLevelId"
                      onTableChange={handleTableChange}
                      data={ruleSettingTableData?.rows || []}
                      columns={tableCols}
                      bordered={false}
                      defaultSorted={[{ dataField: 'level', order: 'desc' }]}
                      striped
                      hover
                      noDataIndication={
                        <NoDataIndication
                          isLoading={
                            isPageLoading ? false : isLoadingRuleSetting
                          }
                          message={
                            isLoadingRuleSetting ? '載入中...' : '尚無資料'
                          }
                        />
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Card>
          </Row>
        </Container>
        {memberShipData?.modal && isShowModalRuleSetting && (
          <RuleSettingModal
            isEdit={isEditModalRuleSetting.current}
            membershipLevelId={
              modalRuleSettingMembershipLevelOnceData.current?.membershipLevelId
            }
            accumulativeWay={
              modalRuleSettingMembershipLevelOnceData.current?.accumulativeWay
            }
            isShowModalRuleSetting={isShowModalRuleSetting}
            isLoadingModalRuleSetting={isLoadingModalRuleSetting}
            modalData={memberShipData?.modal}
            inputValuesData={ruleSettingModalInputValues}
            ruleSettingRowsData={ruleSettingTableData?.rows} // 會員等級區塊的表格資料
            handleRuleSettingModalShow={handleRuleSettingModalShow}
            updateRuleSettingRowsData={() =>
              handleRuleSettingTableData(ruleSettingTableState)
            } // 更新會員等級區塊的表格資料
            dispatchBtnsState={dispatchBtnsState}
          />
        )}
      </div>
      {isShowDeleteWarning && (
        <DeleteWarningModal
          isShowModal={isShowDeleteWarning}
          handleModalClose={() => handleDeleteWarningModalShow(false)}
          handleModalConfirm={() =>
            actionBaseSettingStoreBtnEvent(dispatchBtnsState)
          }
        />
      )}
    </LocalStyle>
  )
}

export default MembershipSetting

ConfirmBtn.propTypes = {
  memberShipData: PropTypes.shape({
    startCompute: PropTypes.shape({
      date: PropTypes.string,
      disabled: PropTypes.bool,
    }),
    baseSetting: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  btnsState: PropTypes.shape({
    loading: PropTypes.shape({
      others: PropTypes.shape({
        startCalculate: PropTypes.bool,
      }),
    }),
    disabled: PropTypes.shape({
      others: PropTypes.shape({
        startCalculate: PropTypes.bool,
      }),
    }),
  }),
  onClick: PropTypes.func,
  isDataLoading: PropTypes.bool,
}

ConfirmBtn.defaultProps = {
  memberShipData: {},
  btnsState: {},
  onClick: () => {},
  isDataLoading: false,
}
NewLevelBtn.propTypes = {
  memberShipData: PropTypes.shape({
    startCompute: PropTypes.shape({
      date: PropTypes.string,
      disabled: PropTypes.bool,
    }),
    baseSetting: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  btnsState: PropTypes.shape({
    loading: PropTypes.shape({
      others: PropTypes.shape({
        startCalculate: PropTypes.bool,
      }),
    }),
    disabled: PropTypes.shape({
      others: PropTypes.shape({
        startCalculate: PropTypes.bool,
      }),
    }),
    display: PropTypes.shape({
      baseSetting: PropTypes.shape({
        store: PropTypes.bool,
      }),
    }),
  }),
  onClick: PropTypes.func,
  isDataLoading: PropTypes.bool,
}

NewLevelBtn.defaultProps = {
  memberShipData: {},
  btnsState: {},
  onClick: () => {},
  isDataLoading: false,
}
