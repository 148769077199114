import PropTypes from 'prop-types'
import { color } from 'assets/styles/Variable/Color'
import BasicModal from 'components/units/Modal'

function StopWarningModal(props) {
  const { handleModalConfirm, handleModalClose, isShowModal } = props

  return (
    <BasicModal
      show={isShowModal}
      onClose={handleModalClose}
      onConfirm={handleModalConfirm}
      titleText="即將停用"
      closeBtnText="返回"
      confirmBtnText="停用"
      variant={color.danger}
    >
      停用後將無法復原，
      <br />
      確定停用？
    </BasicModal>
  )
}

export default StopWarningModal

StopWarningModal.propTypes = {
  handleModalConfirm: PropTypes.func,
  handleModalClose: PropTypes.func,
  isShowModal: PropTypes.bool,
}

StopWarningModal.defaultProps = {
  handleModalConfirm: () => {},
  handleModalClose: () => {},
  isShowModal: false,
}
