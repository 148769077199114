/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import {
  buildType,
  formConfig,
  codeGetType,
} from 'components/pages/Gift/ExchangeSerialNumberConfig'
import { InValidFeedback } from 'components/templates'
import { Col, Radio, Row, Input, Select, Button } from 'components/units'

function SerialNumberSetting({
  isFieldsDisabled,
  isConfirmDisabled,
  valid,
  setting,
  barcodeTypeNotice,
  rulesUpdatedNotice,
  onSettingChange,
  isReceiveWayLink,
  onConfirmRules,
}) {
  return (
    <>
      <Form.Row className="border-bottom">
        <Form.Group>設定並產生序號，作為會員領取憑證</Form.Group>
      </Form.Row>
      <Form.Row>
        {/* 序號建立 buildType */}
        <Col lg="6">
          <fieldset disabled={isFieldsDisabled}>
            <Form.Group>
              <Form.Label>{formConfig.build.label}</Form.Label>
              <Col>
                <Row>
                  <Radio
                    key={buildType.user}
                    id="user"
                    value={buildType.user}
                    name={buildType.user}
                    label="自行新增"
                    checked={buildType.user === Number(setting.type)}
                    onChange={onSettingChange('type')}
                  />
                  {/* 線上連結沒有系統生成 */}
                  {!isReceiveWayLink && (
                    <Radio
                      key={buildType.system}
                      id="system"
                      value={buildType.system}
                      name={buildType.system}
                      label="系統生成"
                      checked={buildType.system === Number(setting.type)}
                      onChange={onSettingChange('type')}
                    />
                  )}
                </Row>
                {/* 規則提示，只在「自行新增」時顯示 */}
                {setting.type === buildType.user && (
                  <Row>
                    <Form.Text>{barcodeTypeNotice}</Form.Text>
                  </Row>
                )}
              </Col>
            </Form.Group>
          </fieldset>
        </Col>
        {/* 序號領取 codeGetType */}
        {setting.type && (
          <Col lg="6">
            <fieldset
              disabled={isFieldsDisabled || setting.type === buildType.system}
            >
              <Form.Group>
                <Form.Label>{formConfig.codeGetType.label}</Form.Label>
                <Col>
                  {formConfig.codeGetType.options.map((item) => (
                    <Row key={item.id}>
                      <Radio
                        id={item.name}
                        value={item.id}
                        name={item.name}
                        label={item.label}
                        checked={
                          setting.type === buildType.system
                            ? item.id === codeGetType.differentCode
                            : item.id === setting.codeGetType
                        }
                        onChange={onSettingChange('codeGetType')}
                      />
                    </Row>
                  ))}

                  <Row>
                    <InValidFeedback show={valid.codeGetType.valid}>
                      {valid.codeGetType.msg}
                    </InValidFeedback>
                  </Row>
                </Col>
              </Form.Group>
            </fieldset>
          </Col>
        )}
      </Form.Row>
      {/* 系統生成才顯示的欄位：序號前綴、序號總長 */}
      {setting.type === buildType.system && (
        <Form.Row>
          <Col lg="6">
            <fieldset disabled={isFieldsDisabled}>
              <Form.Group>
                <Form.Label>{formConfig.prefix.label}</Form.Label>
                <Col>
                  <Row>
                    <Input
                      className="form-row-input-fill"
                      onChange={onSettingChange('prefix')}
                      formControlOption={{
                        value: setting.prefix,
                        placeholder: formConfig.prefix.placeholder,
                        maxLength: 4,
                      }}
                    />
                  </Row>
                  <Row>
                    <InValidFeedback show={valid.prefix.valid}>
                      {valid.prefix.msg}
                    </InValidFeedback>
                  </Row>
                </Col>
              </Form.Group>
            </fieldset>
          </Col>
          <Col lg="6">
            <fieldset disabled={isFieldsDisabled}>
              <Form.Group>
                <Form.Label>{formConfig.length.label}</Form.Label>
                <Col>
                  <Row>
                    <Select
                      className="form-row-input-fill"
                      selectedValue={setting.length}
                      optionItems={formConfig.length.options}
                      formControlOption={{
                        placeholder: formConfig.length.placeholder,
                      }}
                      onChange={onSettingChange('length')}
                    />
                  </Row>
                  <Row>
                    <InValidFeedback show={valid.length.valid}>
                      {valid.length.msg}
                    </InValidFeedback>
                  </Row>
                </Col>
              </Form.Group>
            </fieldset>
          </Col>
        </Form.Row>
      )}
      <Form.Row className="border-bottom pb-4">
        <Col className="d-flex justify-content-end align-items-center">
          <Form.Text className="w-auto px-4">{rulesUpdatedNotice}</Form.Text>
          <Button
            onClick={onConfirmRules}
            disabled={isFieldsDisabled || isConfirmDisabled}
          >
            確認規則
          </Button>
        </Col>
      </Form.Row>
    </>
  )
}

export default SerialNumberSetting

SerialNumberSetting.propTypes = {
  isFieldsDisabled: PropTypes.bool.isRequired,
  isConfirmDisabled: PropTypes.bool.isRequired,
  valid: PropTypes.shape({
    codeGetType: PropTypes.shape({
      valid: PropTypes.bool,
      msg: PropTypes.string,
    }),
    prefix: PropTypes.shape({
      valid: PropTypes.bool,
      msg: PropTypes.string,
    }),
    length: PropTypes.shape({
      valid: PropTypes.bool,
      msg: PropTypes.string,
    }),
  }).isRequired,
  setting: PropTypes.shape({
    type: PropTypes.number,
    codeGetType: PropTypes.number,
    prefix: PropTypes.string,
    length: PropTypes.number,
    total: PropTypes.number,
    uploadCount: PropTypes.number,
  }).isRequired,
  rulesUpdatedNotice: PropTypes.string.isRequired,
  barcodeTypeNotice: PropTypes.string.isRequired,
  onSettingChange: PropTypes.func.isRequired,
  isReceiveWayLink: PropTypes.bool.isRequired,
  onConfirmRules: PropTypes.func.isRequired,
}
