import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import { patchPasswordNew, patchPasswordReset } from 'api/ApiMain'
import { VERIFY, passwordSettingToast, PASSWORD_STATUS } from 'constant/verify'
import { ACCOUNT_VERIFY, PASSWORD_SETTING } from 'store/constants'

export const passwordSettingInitState = {
  // 設定密碼、重設密碼API回傳值
  passwordRsp: {
    data: {
      status: null, // 1:設定密碼成功 2:因Token 無效，操作失敗 3:因密碼已設定過，操作失敗
      statusDescription: null,
    },
    code: null,
    msg: null,
    errors: null,
  },
}

export const patchPasswordNewThunk = createAsyncThunk(
  `${PASSWORD_SETTING}/patchPasswordNew`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await patchPasswordNew(data)
      return response
    } catch (error) {
      // 這裡為了讓reducer資料完整有存完整api回傳，但api錯誤的toast已在上層處理
      return rejectWithValue(error)
    }
  }
)
export const patchPasswordResetThunk = createAsyncThunk(
  `${PASSWORD_SETTING}/patchPasswordReset`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await patchPasswordReset(data)
      return response
    } catch (error) {
      // 這裡為了讓reducer資料完整有存完整api回傳，但api錯誤的toast已在上層處理
      return rejectWithValue(error)
    }
  }
)

const passwordSettingSlice = createSlice({
  name: `${PASSWORD_SETTING}`,
  initialState: passwordSettingInitState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        patchPasswordNewThunk.fulfilled,
        patchPasswordNewThunk.rejected,
        patchPasswordResetThunk.fulfilled,
        patchPasswordResetThunk.rejected
      ),
      (state, action) => {
        if (action.payload.data) {
          state.passwordRsp = action.payload
        } else {
          state.passwordRsp = {
            ...action.payload,
            data: passwordSettingInitState.passwordRsp.data,
          }
        }
      }
    )
  },
})

export const passwordRspSelector = createSelector(
  (state) => state[PASSWORD_SETTING].passwordRsp,
  (state) => state[ACCOUNT_VERIFY].verifyType,
  (passwordRsp, verifyType) => {
    const { status, statusDescription } = passwordRsp.data
    const { ENABLED, ENABLED_PASSWORD_EXISTED } = PASSWORD_STATUS
    return {
      toastType: passwordSettingToast[status]?.toastType || '',
      toastMsg: statusDescription || '',
      redirectPath: passwordSettingToast[status]?.redirectPath || '',
      isLogout:
        verifyType === VERIFY.RESET_PASSWORD &&
        (status === ENABLED || status === ENABLED_PASSWORD_EXISTED),
    }
  }
)

export const passwordSettingReducer = passwordSettingSlice.reducer
