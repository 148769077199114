import {
  createContext,
  useContext,
  useEffect,
  useState,
  useReducer,
  useCallback,
} from 'react'
import { useHistory } from 'react-router'
import { Tabs, Tab } from 'react-bootstrap'
import { startOfDay, startOfToday, formatISO } from 'date-fns'
import {
  addVipGift,
  getMemberList,
  getDesignatedList,
  getVipGiftInfo,
  updateVipGiftInfo,
  getVipExchangeLink,
  vipExchangeUnlink,
} from 'api/ApiMain'
import { Breadcrumb, Button, Modal, Mask, Loader } from 'components/units'
import { ExclusivePage } from 'components/pages/Gift'
import { Basic } from 'components/templates'
import { PAGE_PATHS } from 'constant'
import { settingPageType, tabStatus } from 'pages/Gift/config'
import {
  initValid,
  initContent,
  giftType,
  fieldType,
  formConfig,
  receiveMethodType,
  discountType,
  eventType,
  contentType,
  resAtrr,
} from 'components/pages/Gift/ExclusiveContentConfig'
import {
  contentReducer,
  handleValid,
  handleApiError,
  updateValidSetting,
} from 'components/pages/Gift/ExclusiveContentHelper'
import { color } from 'assets/styles/Variable/Color'
import {
  StyledContentSection,
  StyledSideSection,
  TransparentMask,
} from 'pages/Gift/ExclusiveSettingStyle'
import { ReactComponent as IconCancel } from 'assets/images/button/icon_cancel.svg'
import { ReactComponent as IconSave } from 'assets/images/button/icon_save.svg'
import { RootContext } from 'RootContext'
import { showToast } from 'api/ApiCommon'
import { useDispatch, useSelector } from 'react-redux'
import { updatePageLoading } from 'store/global/globalSlice'
import {
  updateVipGiftConfig,
  vipGiftListSelector,
} from 'store/vip-gift-exchange/vipGiftExchangeListSlice'

const ExclusiveSettingContext = createContext()
ExclusiveSettingContext.displayName = 'ExclusiveSettingContext'

const tabType = {
  content: 'content',
  serialNumber: 'serialNumber',
  sendList: 'sendList',
}

const tabConfig = () => [
  {
    eventKey: tabType.content,
    title: '好禮內容',
    component: <ExclusivePage.ExclusiveContent />,
  },
  {
    eventKey: tabType.serialNumber,
    title: '好禮序號',
    component: <ExclusivePage.ExclusiveSerialNumber />,
  },
  {
    eventKey: tabType.sendList,
    title: '發送名單',
    component: <ExclusivePage.ExclusiveSendList />,
  },
]

const headerEventType = {
  confirmToExit: 'confirmToExit',
  confirmToSubmit: 'confirmToSubmit',
  exit: 'exit',
  submit: 'submit',
  unlink: 'unlink',
  goList: 'goList',
}

const modalType = {
  exit: 'exit',
  updateSetting: 'updateSetting',
  save: 'save',
  unlink: 'unlink',
  available: 'available',
  goList: 'goList',
}

const modalConfig = {
  [modalType.exit]: {
    title: '即將離開',
    confirm: '離開',
    close: '返回',
    content: ['已編輯的資料將不會儲存，', '確定離開編輯？'],
    variant: color.danger,
  },
  [modalType.updateSetting]: {
    title: '變更設定',
    confirm: '繼續',
    close: '返回',
    content: ['變更內容選項，', '將刪除已建立之序號內容。', '是否繼續執行？'],
    variant: color.danger,
  },
  [modalType.save]: {
    title: '儲存進度',
    confirm: '儲存',
    close: '返回',
    content: ['是否儲存目前編輯進度？'],
    variant: color.primary,
  },
  [modalType.unlink]: {
    title: '即將解除',
    confirm: '解除',
    close: '返回',
    content: ['解除連結後將無法復原，', '確定解除？'],
    variant: color.danger,
  },
  [modalType.goList]: {
    title: '返回列表',
    confirm: '確認',
    closeBtnVisible: false,
    isHideCloseButton: true,
    modalOption: {
      backdrop: 'static',
      keyboard: false,
    },
    content: ['本活動符合上架條件，', '已排程自動上架。'],
    variant: color.primary,
  },
}

const composeApiPaylaod = ({ data, isNew }) => {
  const bodyFormData = new FormData()

  // 如果是新增，並且確實有值，才把它納入傳給 API 的值當中
  if (isNew && data[fieldType.linkGift].vipGiftSettingId) {
    bodyFormData.append(
      'LinkVipGiftSettingId',
      data[fieldType.linkGift].vipGiftSettingId
    )
  }
  bodyFormData.append('Name', data[fieldType.eventName])
  bodyFormData.append('GiftType', data[fieldType.eventType])

  if (data[fieldType.eventType] === eventType.complimentaryFullAmount) {
    bodyFormData.append(
      'OverSpentPerDealCond',
      data[fieldType.consumerCondition].condition
    )
    bodyFormData.append(
      'NotSplit',
      data[fieldType.consumerCondition].check || false
    )
  }

  bodyFormData.append('Restrictions', data[fieldType.receiveLimit])
  bodyFormData.append('DesignatedCount', data[fieldType.receiveCount])
  bodyFormData.append('ForNewMember', data[fieldType.target].forNewMember)
  bodyFormData.append(
    'ForInitialMember',
    data[fieldType.target].forInitialMember
  )
  // 新會員為true時，不需傳送會員等級
  if (data[fieldType.target].forNewMember !== true) {
    data[fieldType.target].memberLevels
      .filter((i) => i.selected)
      .forEach((i, key) => {
        bodyFormData.append(`MemberLevels[${key}].id`, i.id)
        bodyFormData.append(`MemberLevels[${key}].code`, i.code)
        bodyFormData.append(`MemberLevels[${key}].name`, i.name)
      })
  }
  data[fieldType.target].designatedList
    .filter((i) => i.selected)
    .forEach((i) => {
      bodyFormData.append(`DesignatedList.code`, i.code)
      bodyFormData.append(
        `DesignatedList.name`,
        i.name.replace(`ID:${i.code} - `, '')
      )
    })
  bodyFormData.append('ContentType', data[fieldType.giftType])

  if (data[fieldType.giftType] === giftType.offer) {
    bodyFormData.append(
      'DiscountType',
      data[fieldType.giftSpecialOffer].check ===
        formConfig[fieldType.giftSpecialOffer].radioOption[0]
        ? discountType.discount
        : discountType.consumerAmount
    )
    bodyFormData.append(
      'DiscountAmount',
      data[fieldType.giftSpecialOffer].discount
    )
    bodyFormData.append(
      'DiscountPercentage',
      data[fieldType.giftSpecialOffer].consumerAmount
    )
    bodyFormData.append(
      'ReceiveWay',
      data[fieldType.receiveMethod] ===
        formConfig[fieldType.receiveMethod].radioOption[0]
        ? receiveMethodType.online
        : receiveMethodType.shop
    )
  } else if (data[fieldType.giftType] === giftType.point) {
    bodyFormData.append('Point', data[fieldType.giftPoint])
  } else {
    bodyFormData.append(`NewVipGiftSettingImgs`, data[fieldType.giftImage])
    data.deleteExistImage &&
      bodyFormData.append(`DelVipGiftSettingImgIds`, data.deleteExistImage)
    bodyFormData.append('ProductId', data[fieldType.giftCode])
    bodyFormData.append('ProductName', data[fieldType.giftName])
    bodyFormData.append('ProductColor', data[fieldType.giftColor])
    bodyFormData.append('ProductSize', data[fieldType.giftSize])
    bodyFormData.append(
      'ReceiveWay',
      data[fieldType.receiveMethod] ===
        formConfig[fieldType.receiveMethod].radioOption[0]
        ? receiveMethodType.online
        : receiveMethodType.shop
    )
  }

  bodyFormData.append('Amount', data[fieldType.giftAmount])

  bodyFormData.append(
    'ExchangeStartDate',
    data[fieldType.eventStart].check ===
      formConfig[fieldType.eventStart].radioOption[0]
      ? formatISO(startOfToday())
      : formatISO(data[fieldType.eventStart].calendar)
  )
  bodyFormData.append(
    'ExchangeEndDate',
    data[fieldType.eventEnd].check ===
      formConfig[fieldType.eventEnd].radioOption[0]
      ? ''
      : formatISO(data[fieldType.eventEnd].calendar)
  )
  bodyFormData.append(
    'AutoAvailable',
    data[fieldType.autoUpload] ===
      formConfig[fieldType.autoUpload].radioOption[0]
  )

  if (data[fieldType.receiveMethod]) {
    bodyFormData.append(
      'GiftCodeStartDate',
      formatISO(data[fieldType.receiveStart])
    )
    bodyFormData.append(
      'giftCodeEndDateType',
      data[fieldType.giftCodeEndTimeType]
    )

    bodyFormData.append(
      'giftCodeEndDate',
      data[fieldType.giftCodeEndTimeType] === 2
        ? formatISO(data[fieldType.giftCodeEndTime])
        : ''
    )
    bodyFormData.append(
      'giftCodeEndDateAfterDays',
      data[fieldType.giftCodeEndTimeType] === 3
        ? data[fieldType.giftCodeEndTimeAfterDays]
        : ''
    )
  }
  bodyFormData.append('Description', data[fieldType.giftInfo])

  return bodyFormData
}

const parserRes = ({ data, memberLevels, designatedList }) => {
  const parserMemberLevels = (list) => {
    return memberLevels.map((member) => ({
      ...member,
      selected: list.some((item) => item.id === member.id && item.selected),
    }))
  }
  const parserDesignatedList = (designatedListSelectedItem) => {
    return designatedList.map((designatedItem) => ({
      ...designatedItem,
      selected: designatedItem.code === designatedListSelectedItem.code,
    }))
  }
  const parserDiscountType = (value) => {
    if (value === undefined || value === null) return ''
    if (value === discountType.discount)
      return formConfig[fieldType.giftSpecialOffer].radioOption[0]
    return formConfig[fieldType.giftSpecialOffer].radioOption[1]
  }

  const parserReceiveMethod = (value) => {
    if (value === undefined || value === null) return ''
    if (value === receiveMethodType.online)
      return formConfig[fieldType.receiveMethod].radioOption[0]
    return formConfig[fieldType.receiveMethod].radioOption[1]
  }

  const parserAutoUpload = (value) => {
    if (value === undefined || value === null) return ''
    if (value) return formConfig[fieldType.autoUpload].radioOption[0]
    return formConfig[fieldType.autoUpload].radioOption[1]
  }
  return {
    [fieldType.systemNumber]: data?.brandSerialNo ?? '-',
    [fieldType.linkGift]: {
      vipGiftSettingId: data?.linkInfo?.linkedVipGiftSettingId ?? '',
      serialNo: data?.linkInfo?.linkedBrandSerialNo ?? '',
      content: data?.linkInfo?.linkedName ?? '',
      isMain: data?.linkInfo?.isMain ?? '',
    },
    [fieldType.eventName]: data?.name ?? '',
    [fieldType.eventType]: data?.giftType ?? '',
    [fieldType.eventStart]: {
      check: formConfig[fieldType.eventStart].radioOption[1],
      calendar: data?.exchangeStartTime
        ? new Date(data?.exchangeStartTime)
        : startOfDay(new Date()),
    },
    [fieldType.eventEnd]: {
      check:
        data?.exchangeEndTime === null
          ? formConfig[fieldType.eventEnd].radioOption[0]
          : formConfig[fieldType.eventEnd].radioOption[1],
      calendar: data?.exchangeEndTime
        ? new Date(data?.exchangeEndTime)
        : startOfDay(new Date()),
    },
    [fieldType.receiveLimit]: data?.restrictions ?? '',
    [fieldType.receiveCount]: data?.designatedCount ?? '',
    [fieldType.receiveMethod]: parserReceiveMethod(data?.receiveWay),
    [fieldType.receiveStart]: data?.giftCodeStartTime
      ? new Date(data?.giftCodeStartTime)
      : startOfDay(new Date()),
    [fieldType.target]: {
      forNewMember: data?.forNewMember ?? '',
      forInitialMember: data?.forInitialMember ?? '',
      memberLevels: parserMemberLevels(data?.memberLevelList ?? []),
      designatedList: parserDesignatedList(data?.designatedList ?? []),
    },
    [fieldType.consumerCondition]: {
      check: data?.notSplit ?? '',
      condition: data?.overSpentPerDealCond ?? '',
    },
    [fieldType.giftType]: data?.contentType ?? '',
    [fieldType.giftSpecialOffer]: {
      check: parserDiscountType(data?.discountType),
      discount: data?.discountAmount ?? '',
      consumerAmount: data?.discountPercentage ?? '',
    },
    [fieldType.giftPoint]: data?.point ?? '',
    [fieldType.giftImage]: { ...data?.images[0] },
    [fieldType.giftCode]: data?.productId ?? '',
    [fieldType.giftName]: data?.productName ?? '',
    [fieldType.giftColor]: data?.productColor ?? '',
    [fieldType.giftSize]: data?.productSize ?? '',
    [fieldType.giftAmount]: data?.amount ?? '',
    [fieldType.giftInfo]: data?.description ?? '',
    [fieldType.autoUpload]: parserAutoUpload(data?.autoAvailable),

    [resAtrr.giftCodeCount]: data?.giftCodeCount ?? 0,
    [resAtrr.sendCount]: data?.sentCount ?? 0,
    vipGiftSettingId: data?.vipGiftSettingId ?? -1,
    barcodeTypeNotice: data?.barcodeTypeNotice ?? '',
    giftCodeGetType: data?.giftCodeGetType ?? '',
    giftCodeLength: data?.giftCodeLength ?? '',
    giftCodePrefix: data?.giftCodePrefix ?? '',
    giftCodeType: data?.giftCodeType ?? '',

    [fieldType.giftCodeEndTimeType]: data?.giftCodeEndTimeType ?? '',
    [fieldType.giftCodeEndTimeAfterDays]: data?.giftCodeEndTimeAfterDays ?? '',
    [fieldType.giftCodeEndTime]: data?.giftCodeEndTime
      ? new Date(data?.giftCodeEndTime)
      : startOfDay(new Date()),
  }
}

const parserLinkRes = (data) =>
  [{ text: '請選擇', value: -1 }].concat(
    data.map((i) => ({
      text: i.brandSerialNoWithName,
      value: i.vipGiftSettingId,
    }))
  )

function NavSection() {
  const { handleEvent, viewType } = useContext(ExclusiveSettingContext)
  const now = {
    [settingPageType.new]: '新增好禮',
    [settingPageType.edit]: '編輯好禮',
    [settingPageType.view]: '檢視好禮',
  }
  const BreadcrumbConfig = {
    now: now[viewType],
    pathList: [
      { title: '好禮贈送', slash: true },
      {
        title: '專屬好禮',
        onClick: () => handleEvent(headerEventType.confirmToExit),
      },
    ],
  }
  return <Breadcrumb {...BreadcrumbConfig} />
}

function SideSection() {
  const { handleEvent, viewType, pageType } = useContext(
    ExclusiveSettingContext
  )
  const showSaveTypes = [settingPageType.new, settingPageType.edit]

  const handleSubmit = (e, { handleButtonLocalLoading }) => {
    handleEvent(headerEventType.submit, handleButtonLocalLoading)
  }

  return (
    <StyledSideSection>
      {pageType === tabType.content &&
      showSaveTypes.some((i) => i === viewType) ? (
        <>
          <Button
            variant="outline-gray"
            onClick={() => handleEvent(headerEventType.confirmToExit)}
          >
            <IconCancel />
            取消
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleSubmit}
            loaderOption={{
              bgColor: 'transparent',
              colorType: '#fff',
            }}
          >
            <IconSave />
            儲存
          </Button>
        </>
      ) : (
        <Button
          variant="outline-gray"
          onClick={() => handleEvent(headerEventType.exit)}
        >
          返回列表
        </Button>
      )}
    </StyledSideSection>
  )
}

function ExclusiveSetting() {
  const dispatch = useDispatch()
  const rootData = useContext(RootContext)
  const history = useHistory()
  const { state } = history.location
  const apiPayload = state?.apiPayload
  const { usingLinkGift } = useSelector(vipGiftListSelector)
  const brandId = apiPayload?.brandId
  const type = state?.type
  const [isTabLoading, setTabLoading] = useState(false)
  // page為tab頁籤內容的eventKey
  const [page, setPage] = useState(tabType.content)
  const [infoState, dispatchLocal] = useReducer(contentReducer, initContent)
  const [infoValid, setInfoValid] = useState(initValid)
  const [memberLevels, setMemberLevels] = useState([])
  const [designatedList, setDesignatedList] = useState([])
  const [modal, setModal] = useState({ type: modalType.exit, show: false })
  const [defaultGiftType, setDefaultGiftType] = useState(null)
  const [stateFromEdit, setStateFromEdit] = useState(null)
  const [settingId, setSettingId] = useState(null)
  const [mask, setMask] = useState(false)
  const [transparentMask, setTransparentMask] = useState(false)
  const [linkedGiftSettingId, setLinkedGiftSettingId] = useState(null)
  const [tabDisabled, setTabDisabled] = useState({
    [tabType.content]: false,
    [tabType.serialNumber]: false,
    [tabType.sendList]: false,
  })
  const toastMessage = {
    new: '新增好禮內容成功',
    edit: '編輯好禮內容成功',
  }

  const giftSettingId = apiPayload?.giftSettingId || settingId
  const isNewPage = type === settingPageType.new
  const isViewPage = type === settingPageType.view
  const isEditPage = type === settingPageType.edit

  const isTabShow = (eventKey) => {
    const showList = [tabType.content]

    if (infoState[fieldType.giftType] === giftType.point) {
      showList.push(tabType.sendList)
    } else {
      showList.push(tabType.serialNumber)
    }

    return showList.some((item) => item === eventKey)
  }

  const exitPage = useCallback(
    ({ giftSettingStatus = null } = {}) => {
      history.push(PAGE_PATHS.gift.exclusive, {
        apiPayload: {
          ...apiPayload,
          state: giftSettingStatus || stateFromEdit || apiPayload.state,
        },
      })
    },
    [history, apiPayload, stateFromEdit]
  )

  const handleOpenModal = (type) => setModal({ type, show: true })

  const handleCloseModal = () => setModal((prev) => ({ ...prev, show: false }))

  const checkToSubmit = () => {
    if (!defaultGiftType) return true
    if (infoState[fieldType.giftType] !== giftType.point) return true
    if (infoState[fieldType.giftType] !== defaultGiftType) {
      handleOpenModal(modalType.updateSetting)
      return false
    }
    return true
  }

  const execute = () => {
    const payload = composeApiPaylaod({ data: infoState, isNew: isNewPage })
    if (isNewPage && !giftSettingId) return addVipGift(brandId, payload)
    return updateVipGiftInfo(brandId, giftSettingId, payload)
  }

  const handleUnlink = async () => {
    try {
      await vipExchangeUnlink(brandId, giftSettingId)
      let memberLevels = []
      let vipGiftInfo = {}
      const memberListRes = await getMemberList(brandId)
      memberLevels = memberListRes?.data?.data?.memberLevelItemList ?? []
      const infoRes = await getVipGiftInfo(brandId, giftSettingId)
      vipGiftInfo = parserRes({
        data: infoRes.data?.data ?? {},
        memberLevels,
        designatedList,
      })
      setInfoValid(updateValidSetting(vipGiftInfo))
      dispatchLocal({ type: contentType.updateFromApi, payload: vipGiftInfo })
      handleCloseModal()
    } catch (error) {
      console.log(
        '🚀 ~ file: ExclusiveSetting.js ~ line 482 ~ handleUnlink ~ error',
        error
      )
    } finally {
      handleCloseModal()
    }
  }

  const handleSubmit = async () => {
    const isEnabledSubmit = checkToSubmit()
    if (isEnabledSubmit) {
      composeApiPaylaod({ data: infoState, isNew: isNewPage })
      const isValid = await handleValid({
        data: infoState,
        updateValidState: setInfoValid,
      })
      if (!isValid) return

      try {
        const rsp = await execute()
        const giftSettingStatus = rsp?.data?.data?.vipGiftSettingStatus ?? null
        if (isNewPage) {
          showToast(rsp, toastMessage.new)
        } else if (isEditPage) {
          showToast(rsp, toastMessage.edit)
        }
        exitPage({ giftSettingStatus })
      } catch (error) {
        if (error.code === 400)
          handleApiError({ error, updateFn: setInfoValid })
      }
    }
  }

  const handleEvent = async (type, handleButtonLocalLoading) => {
    switch (type) {
      case headerEventType.exit:
        exitPage()
        break
      case headerEventType.submit:
        handleButtonLocalLoading(true)
        await handleSubmit()
        handleButtonLocalLoading(false)
        break
      case headerEventType.confirmToExit:
        handleOpenModal(modalType.exit)
        break
      case headerEventType.confirmToSubmit:
        handleSubmit()
        break
      case headerEventType.unlink:
        handleOpenModal(modalType.unlink)
        break
      case headerEventType.goList:
        handleOpenModal(modalType.goList)
        break
      default:
        break
    }
  }

  const handleModalConfirm =
    (type) =>
    async ({ handleModalLoading }) => {
      let result = null
      switch (type) {
        case modalType.exit:
          handleEvent(headerEventType.exit)
          break
        case modalType.updateSetting:
          setDefaultGiftType(null)
          handleCloseModal()
          handleEvent(headerEventType.confirmToSubmit)
          break
        case modalType.save:
          handleModalLoading(true)
          result = await handleValid({
            data: infoState,
            updateValidState: setInfoValid,
          })
          if (result) {
            const nextEventKey = tabConfig()
              .filter((i) => isTabShow(i.eventKey))
              .find((i) => i.eventKey !== tabType.content).eventKey
            try {
              const rsp = await execute()
              if (isNewPage) {
                // 表示在新增模式下，有切換頁籤過，變成發編輯的api
                if (settingId) {
                  showToast(rsp, toastMessage.edit)
                } else {
                  showToast(rsp, toastMessage.new)
                }
              } else if (isEditPage) {
                showToast(rsp, toastMessage.edit)
              }
              const giftSettingStatus =
                rsp?.data?.data?.vipGiftSettingStatus ?? null
              const vipGiftSettingId = rsp?.data?.data?.vipGiftSettingId
              setStateFromEdit(giftSettingStatus)
              if (vipGiftSettingId) {
                setSettingId(vipGiftSettingId)
              }
              handleCloseModal()
              setPage(nextEventKey)
            } catch {
              handleModalLoading(false)
            }
          } else {
            handleCloseModal()
          }
          handleModalLoading(false)
          break
        case modalType.unlink:
          result = handleUnlink()
          break
        case modalType.goList:
          history.push(PAGE_PATHS.gift.exclusive)
          break
        default:
          break
      }
    }

  const handleSelect = async (value) => {
    if (isViewPage) return setPage(value)
    if (
      stateFromEdit &&
      stateFromEdit !== tabStatus.unavailable &&
      giftSettingId
    ) {
      if (value === tabType.content && giftSettingId) {
        return setModal({ type: modalType.goList, show: true })
      }
    } else if (value !== tabType.content && value !== page) {
      return setModal({ type: modalType.save, show: true })
    }
    setPage(value)
  }

  const handleTabLoading = (bool) => {
    setTabLoading(bool)
  }

  const handleGetDesignatedList = useCallback(() => {
    const designatedListData = []
    let designatedListPromise = new Promise(() => {})
    if (isNewPage) {
      designatedListPromise = getDesignatedList(brandId)
    } else {
      designatedListPromise = getDesignatedList(brandId, {
        vipGiftSettingId: giftSettingId,
      })
    }
    designatedListPromise.then(async (designatedListRes) => {
      const designatedListFormatData =
        designatedListRes?.data?.data?.designatedList.map(({ code, name }) => ({
          code,
          name: `ID:${code} - ${name}`,
        }))
      setDesignatedList(designatedListFormatData ?? designatedListData)
    })
    return designatedListPromise
  }, [isNewPage, giftSettingId, brandId])

  useEffect(() => {
    const init = async () => {
      const { brandId, giftSettingId } = apiPayload
      const memberLevels = []
      const designatedList = []
      let vipGiftInfo = {}
      handleTabLoading(true)
      // 為了讓這邊的api同時執行，因此不用await來等待
      const memberListPromise = getMemberList(brandId)
      memberListPromise.then(async (memberListRes) => {
        setMemberLevels(
          memberListRes?.data?.data?.memberLevelItemList ?? memberLevels
        )
      })
      const designatedListPromise = handleGetDesignatedList()
      if (isNewPage) {
        Promise.all([memberListPromise, designatedListPromise])
          .then(async ([memberListData, designatedListData]) => {
            const memberLevels_ =
              memberListData?.data?.data?.memberLevelItemList ?? memberLevels
            const designatedList_ =
              designatedListData?.data?.data?.designatedList ?? designatedList
            if (usingLinkGift) {
              const linkRes = await getVipExchangeLink(brandId) // 有 thunk: getVipExchangeLinkThunk，待替換
              const linkList = linkRes?.data?.data?.canBeLinkedList ?? []
              dispatchLocal({
                type: contentType.init,
                payload: {
                  memberLevels: memberLevels_,
                  designatedList: designatedList_,
                  linkList: parserLinkRes(linkList),
                },
              })
              handleTabLoading(false)
            } else {
              dispatchLocal({
                type: contentType.init,
                payload: {
                  memberLevels: memberLevels_,
                  designatedList: designatedList_,
                  linkList: [],
                },
              })
            }
          })
          .finally(() => {
            setTabLoading(false)
          })
      } else {
        const vipGiftInfoPromise = getVipGiftInfo(brandId, giftSettingId)
        Promise.all([
          memberListPromise,
          designatedListPromise,
          vipGiftInfoPromise,
        ])
          .then(([memberListRes, designatedListRes, vipGiftInfoRes]) => {
            vipGiftInfo = parserRes({
              data: vipGiftInfoRes.data?.data ?? {},
              memberLevels:
                memberListRes?.data?.data?.memberLevelItemList ?? memberLevels,
              designatedList:
                designatedListRes?.data?.data?.designatedList ?? designatedList,
            })
            setInfoValid(updateValidSetting(vipGiftInfo))
            dispatchLocal({
              type: contentType.updateFromApi,
              payload: vipGiftInfo,
            })
          })
          .finally(() => {
            setTabLoading(false)
            dispatch(updatePageLoading(false))
          })
      }
    }
    if (page === tabType.content) init()
    dispatch(updatePageLoading(false))
  }, [
    isNewPage,
    usingLinkGift,
    apiPayload,
    page,
    dispatch,
    handleGetDesignatedList,
  ])

  useEffect(() => {
    if (rootData.brandId && rootData.brandId !== apiPayload.brandId) exitPage()
  }, [rootData.brandId, apiPayload.brandId, exitPage])

  useEffect(() => {
    if (infoState[fieldType.linkGift].vipGiftSettingId) {
      setLinkedGiftSettingId((prev) => {
        if (prev !== infoState[fieldType.linkGift].vipGiftSettingId)
          return infoState[fieldType.linkGift].vipGiftSettingId
        return prev
      })
    }
  }, [infoState])

  useEffect(() => {
    const updateMemberData = async (vipGiftSettingId) => {
      let memberLevels = []
      let vipGiftInfo = {}
      // 為了讓這邊的api同時執行，因此不用await來等待
      const memberListPromise = getMemberList(brandId)
      memberListPromise.then(async (memberListRes) => {
        memberLevels =
          memberListRes?.data?.data?.memberLevelItemList ?? memberLevels
      })
      const infoRes = await getVipGiftInfo(brandId, vipGiftSettingId)
      const resData = infoRes.data?.data
      resData.linkInfo = {
        linkedVipGiftSettingId: resData.vipGiftSettingId,
        linkedBrandSerialNo: resData.brandSerialNo,
        isMain: false,
      }
      resData.brandSerialNo = '-'
      resData.receiveWay =
        resData.receiveWay === receiveMethodType.online
          ? receiveMethodType.shop
          : receiveMethodType.online
      vipGiftInfo = parserRes({
        data: resData ?? {},
        memberLevels,
        designatedList: designatedList ?? [],
      })
      setInfoValid(updateValidSetting(vipGiftInfo))
      dispatchLocal({ type: contentType.updateFromApi, payload: vipGiftInfo })
    }

    if (isNewPage && linkedGiftSettingId > 0)
      updateMemberData(linkedGiftSettingId)
  }, [isNewPage, brandId, linkedGiftSettingId, designatedList])

  useEffect(() => {
    dispatch(updateVipGiftConfig({ pageType: type }))
  }, [infoState, dispatch, type])

  return (
    <ExclusiveSettingContext.Provider
      value={{
        history,
        infoState: [infoState, dispatchLocal],
        infoValid: [infoValid, setInfoValid],
        memberLevels: [memberLevels, setMemberLevels],
        designatedList: [designatedList, setDesignatedList],
        stateFromEdit: [stateFromEdit, setStateFromEdit],
        settingId,
        tabDisabledConfig: [tabDisabled, setTabDisabled],
        mask: [mask, setMask],
        transparentMask: [transparentMask, setTransparentMask],
        handleEvent,
        viewType: type,
        pageType: page,
      }}
    >
      <Basic navSection={NavSection} sideSection={SideSection}>
        <StyledContentSection>
          <Tabs activeKey={page} onSelect={handleSelect}>
            {tabConfig()
              .filter((i) => isTabShow(i.eventKey))
              .map((tab) => (
                <Tab
                  eventKey={tab.eventKey}
                  title={tab.title}
                  key={tab.eventKey}
                >
                  <Loader isHide={!isTabLoading} />
                  {tab.component}
                </Tab>
              ))}
          </Tabs>
        </StyledContentSection>
      </Basic>
      <Modal
        show={modal.show}
        onClose={handleCloseModal}
        onConfirm={handleModalConfirm(modal.type)}
        variant={modalConfig[modal.type].variant}
        titleText={modalConfig[modal.type].title}
        confirmBtnText={modalConfig[modal.type].confirm}
        closeBtnText={modalConfig[modal.type].close}
        {...modalConfig[modal.type]}
      >
        {modalConfig[modal.type].content.map((i, key) => (
          <p key={key}>{i}</p>
        ))}
      </Modal>
      <Mask show={mask} />
      <TransparentMask>
        <Mask show={transparentMask} />
      </TransparentMask>
    </ExclusiveSettingContext.Provider>
  )
}

export default ExclusiveSetting
export { ExclusiveSettingContext, tabType, headerEventType }
