/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import { useState, useEffect, useCallback } from 'react'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import {
  Caption,
  DetailModal,
} from 'components/pages/Gift/ListExclusiveGiftSubComponents'
import { Table, NoDataIndication, Button, Row, Col } from 'components/units'
import PaginationInput from 'components/units/PaginationInput'
import { statusFormatter } from 'helpers/tableElFormat'
import useSelectedRows from 'hooks/useSelectedRows'
import { paginationOptions } from 'pages/Gift/config'
import { authAccountSelector } from 'store/auth/authSelector'
import {
  getValidateCodeSettingThunk,
  getVipGiftVerificationListThunk,
  getVipGiftVerificationDetailThunk,
  patchVipGiftVerificationThunk,
} from 'store/gift-list/giftListAsync'
import {
  resetDetailModal,
  resetVerificationResult,
  validateCodeSettingSelector,
  vipGiftVerificationListSelector,
  vipGiftVerificationDetailSelector,
  vipGiftVerificationResultSelector,
} from 'store/gift-list/giftListSlice'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'
import BatchModal from '../widgets/BatchModal'
import MethodModal from '../widgets/MethodModal'

export default function VipGiftVerificationList({
  tablePayload,
  sortable,
  searchPayload,
  setTablePayload,
  tabValue,
}) {
  const dispatch = useDispatch()
  const { brandId } = useSelector(authAccountSelector)
  const { isLiffEnabled, sourceList, shopList, consumeChannelList } =
    useSelector(validateCodeSettingSelector)
  const { list, totalSize } = useSelector(vipGiftVerificationListSelector)
  const vipGiftVerificationDetail = useSelector(
    vipGiftVerificationDetailSelector
  )
  const verificationResult = useSelector(vipGiftVerificationResultSelector)
  const { isDataLoading } = useSelector(getGlobalState)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isShowBatchModal, setIsShowBatchModal] = useState(false)
  const [isShowMethodModal, setIsShowMethodModal] = useState(false)

  const [modalMethod, setModalMethod] = useState(null)
  const [modalDetail, setModalDetail] = useState(null)
  const [detailList, setDetailList] = useState([])

  const selectedLimit = 50
  const {
    isSelectedEmpty,
    selectedKeys,
    nonSelectableKeys,
    handleSelectSingle,
    handleSelectAll,
    clearSelectedKeys,
  } = useSelectedRows({ list, selectedLimit })

  /* 選擇核銷方式 */
  const handleMethodChange = (e, selectedValue) => {
    setModalMethod(selectedValue)
    const newDetail = selectedValue === 'Shop' ? shopList : consumeChannelList
    setDetailList(newDetail)
    setModalDetail(null)
  }

  /* 選擇核銷「門店」或「通路」的內容 */
  const handleDetailChange = (e, selectedValue) => {
    setModalDetail(selectedValue)
  }

  /**
   * 關閉核銷方式 modal
   * 重置選擇的核銷方式 & 核銷內容（貳樓等廠商會設定）
   */
  const handleMethodModalClose = () => {
    setModalMethod(null)
    setModalDetail(null)
    setIsShowMethodModal(false)
  }

  /* 確認核銷方式，進入下一段 modal */
  const handleMethodModalConfirm = () => {
    setIsShowMethodModal(false)
    setIsShowBatchModal(true)
  }

  /* 關閉發送詳情 Modal 並重置資料 */
  const handleModalClose = () => {
    setIsShowModal(false)
    dispatch(resetDetailModal())
  }

  /* 關閉批次核銷 modal */
  const handleBatchModalClose = () => {
    setIsShowBatchModal(false)
    setModalMethod(null)
    setModalDetail(null)
  }

  /* 返回核銷方式 modal */
  const handleBatchModalCancel = () => {
    if (isLiffEnabled) setIsShowMethodModal(true)
    setIsShowBatchModal(false)
  }

  /**
   * 點擊批次核銷按鈕
   * 若有開啟啟用，則顯示核銷方式 modal，否則直接顯示批次核銷 modal
   */
  const handleValidateClick = () => {
    if (isLiffEnabled) {
      setIsShowMethodModal(true)
    } else {
      setIsShowBatchModal(true)
    }
  }

  /* 確認批次核銷 */
  const handleBatchModalConfirm = () => {
    const apiPayload = {
      brandId,
      vipGiftRecordIdList: selectedKeys,
    }
    if (modalMethod === 'Shop') apiPayload.shopId = modalDetail
    if (modalMethod === 'ConsumeChannel')
      apiPayload.consumeChannel = modalDetail
    handleBatchModalClose()
    dispatch(patchVipGiftVerificationThunk(apiPayload))
    clearSelectedKeys()
  }

  /* 點擊 Table Row */
  const handleRowClick = (e, row) => {
    const { giftRecordObjId } = row
    dispatch(getVipGiftVerificationDetailThunk({ giftRecordObjId, brandId }))
    setIsShowModal(true)
  }

  /* Table 的排序、分頁、搜尋等變動 */
  const handleTableChange = (type, data) => {
    const { page, sizePerPage, sortField, sortOrder } = data

    const updatedPayload = {
      ...tablePayload,
      p: page || tablePayload.p,
      ps: sizePerPage || tablePayload.ps,
      so: sortOrder || tablePayload.so,
      sf: sortField || tablePayload.sf,
    }

    setTablePayload(updatedPayload)
  }

  /* 獲取好禮核銷列表 Thunk */
  const updateListData = useCallback(async () => {
    try {
      if (tabValue !== 'vipGiftVerification') return

      const apiPayload = {
        ...tablePayload,
        ...searchPayload,
        brandId,
      }
      await dispatch(updateDataLoading(true))
      await dispatch(getVipGiftVerificationListThunk(apiPayload))
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(updateDataLoading(false))
    }
  }, [brandId, dispatch, searchPayload, tabValue, tablePayload])

  /* Table 的單行設定 */
  const selectRow = {
    mode: 'checkbox',
    onSelect: handleSelectSingle,
    onSelectAll: handleSelectAll,
    selected: selectedKeys,
    nonSelectable: nonSelectableKeys,
  }

  const columns = [
    { dataField: 'key', text: '', hidden: true },
    { dataField: 'sendTime', text: '發送日期', sort: sortable },
    { dataField: 'customClientId', text: '會員編號', sort: sortable },
    { dataField: 'member', text: '會員' },
    { dataField: 'phone', text: '手機' },
    { dataField: 'receiveWay', text: '領取方式', sort: sortable },
    { dataField: 'name', text: '名稱' },
    { dataField: 'content', text: '內容' },
    {
      dataField: 'status',
      text: '狀態',
      sort: sortable,
      // 好禮核銷 tab 狀態只會有「已發送」
      formatter: (cell, row) => {
        const { statusDescription } = row
        const statusParameter = [false, false, false]
        return statusFormatter(statusDescription, ...statusParameter)
      },
      style: { minWidth: '80px' },
    },
  ]

  /* 獲取好禮核銷列表 */
  useEffect(() => {
    updateListData()
  }, [updateListData])

  /* 獲取核銷方式設定 */
  useEffect(() => {
    if (tabValue !== 'vipGiftVerification') return
    dispatch(getValidateCodeSettingThunk({ brandId }))
  }, [brandId, dispatch, tabValue])

  /* 依據 API 結果顯示核銷成功、失敗筆數，並重新抓 list 資料 */
  useEffect(() => {
    const { isFetch, totalCount, successCount, failedCount } =
      verificationResult
    if (!isFetch || !totalCount) return
    const text = `批次核銷：${successCount} 筆成功；${failedCount} 筆失敗`
    toast.success(text)

    // 重置核銷結果
    dispatch(resetVerificationResult())

    // 重新抓取列表資料
    updateListData()
  }, [verificationResult, updateListData, dispatch])

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          ...paginationOptions,
          totalSize,
          page: tablePayload.p,
          sizePerPage: tablePayload.ps,
        })}
      >
        {({ paginationProps, paginationTableProps }) => {
          return (
            <>
              <Row className="d-flex align-items-center">
                <Col className="flex-grow-1">
                  <Caption
                    paginationProps={paginationProps}
                    total={totalSize}
                  />
                </Col>
                <Button
                  className="ml-3 mb-1"
                  variant={isSelectedEmpty ? 'outline-gray' : 'outline-dark'}
                  size="sm"
                  onClick={handleValidateClick}
                  disabled={isSelectedEmpty}
                >
                  批次核銷
                </Button>
              </Row>
              <Table
                headerClasses="table-header"
                bodyClasses="paragraph"
                className="overflow-auto"
                keyField="key"
                data={isDataLoading ? [] : list}
                columns={columns}
                rowEvents={{ onClick: handleRowClick }}
                selectRow={selectRow}
                rowStyle={{ cursor: 'pointer' }}
                onTableChange={handleTableChange}
                striped
                remote
                noDataIndication={
                  <NoDataIndication
                    isLoading={isDataLoading}
                    message={isDataLoading ? '載入中...' : '尚無資料'}
                  />
                }
                {...paginationTableProps}
              />
              <div className="d-flex justify-content-center">
                <PaginationListStandalone {...paginationProps} />
                <PaginationInput
                  onPageChange={paginationProps.onPageChange}
                  page={paginationProps.page}
                  sizePerPage={paginationProps.sizePerPage}
                  totalSize={paginationProps.totalSize}
                />
              </div>
            </>
          )
        }}
      </PaginationProvider>
      <DetailModal
        show={isShowModal}
        data={vipGiftVerificationDetail}
        isDefaultDataLoading={!vipGiftVerificationDetail.isFetch}
        onClose={handleModalClose}
      />
      <BatchModal
        show={isShowBatchModal}
        onCancel={handleBatchModalCancel}
        onClose={handleBatchModalClose}
        onConfirm={handleBatchModalConfirm}
      />
      <MethodModal
        show={isShowMethodModal}
        methodList={sourceList}
        detailList={detailList}
        method={modalMethod}
        detail={modalDetail}
        onMethodChange={handleMethodChange}
        onDetailChange={handleDetailChange}
        onClose={handleMethodModalClose}
        onConfirm={handleMethodModalConfirm}
      />
    </div>
  )
}

VipGiftVerificationList.propTypes = {
  tablePayload: PropTypes.shape({
    p: PropTypes.number.isRequired,
    ps: PropTypes.number.isRequired,
    sf: PropTypes.string.isRequired,
    so: PropTypes.string.isRequired,
  }).isRequired,
  sortable: PropTypes.bool.isRequired,
  searchPayload: PropTypes.shape({}).isRequired,
  setTablePayload: PropTypes.func.isRequired,
  tabValue: PropTypes.string.isRequired,
}
