import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import GlobalStyle from 'assets/styles/GlobalStyle'
import RootContextProvider from 'RootContext'
import store from 'store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <GlobalStyle />
    <Provider store={store}>
      <RootContextProvider value={{}}>
        <App />
      </RootContextProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
