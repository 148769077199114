import GetPointFail from './GetPointFail'
import GetPointSuccess from './GetPointSuccess'

// TabSection
const TabSectionSub = {
  GetPointFail,
  GetPointSuccess,
}

export { TabSectionSub }
