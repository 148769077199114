export const PAGE_PATHS = {
  verify: {
    settingPassword: '/verify/settingPassword',
    forgetPassword: '/verify/resettingPassword',
    verifyFailed: '/verify/verifyFailed',
  },
  privacyPolicy: '/privacy-policy',
  login: '/login',
  home: '/',
  dataAnalysis: '/data-analysis',
  member: {
    new: '/member/new',
    edit: '/member/edit/:memberId',
    search: '/member',
  },
  membershipSetting: '/membership-setting',
  pointContent: {
    history: '/point-history',
    setting: '/point-setting',
  },
  activitiesCode: {
    productActivity: '/product-activity',
    productActivityNew: '/product-management',
    productActivityEdit: '/product-edit',
    pointActivity: '/point-activity',
    qrcodeActivity: '/qrcode-activity',
    qrcodeActivityContent: '/qrcode-activity-content',
    activityFormRecord: '/activity-form-record',
  },
  gift: {
    exchange: '/gift-exchange',
    exchangeSetting: '/gift-setting',
    list: '/gift-list',
    exclusive: '/gift-exclusive',
    exclusiveSetting: '/gift-exclusive-setting',
  },
  systemConfiguration: {
    companyToken: '/company-token',
    accountManagement: '/account-management',
    accountManagementNew: '/account-management-content/:pageType',
    accountManagementEdit: '/account-management-content/:pageType/:accountId',
  },
}

export const COMPONENT_PERMISSIONS = {
  member: {
    CREATE: ['Member_c'],
    READ: ['Member_r'],
    UPDATE: ['Member_u'],
    DELETE: ['Member_d'],
  },
  membershipSetting: {
    CREATE: ['MembershipSetting-MemberLevel_c'],
    READ: ['MembershipSetting-MemberLevel_r'],
    UPDATE: ['MembershipSetting-MemberLevel_u'],
    DELETE: ['MembershipSetting-MemberLevel_d'],
  },
  point: {
    CREATE: ['Point_c'],
    READ: ['Point_r'],
    UPDATE: ['Point_u'],
    DELETE: ['Point_d'],
  },
  pointHistory: {
    CREATE: ['PointHistory_c'],
    READ: ['PointHistory_r'],
    UPDATE: ['PointHistory_u'],
    DELETE: ['PointHistory_d'],
  },
  barcodeRule: {
    CREATE: ['BarcodeRule_c'],
    READ: ['BarcodeRule_r'],
    UPDATE: ['BarcodeRule_u'],
    DELETE: ['BarcodeRule_d'],
  },
  productRule: {
    CREATE: ['ProductRule_c'],
    READ: ['ProductRule_r'],
    UPDATE: ['ProductRule_u'],
    DELETE: ['ProductRule_d'],
  },
  qrcodeActivity: {
    CREATE: ['QRCodeActivity_c'],
    READ: ['QRCodeActivity_r'],
    UPDATE: ['QRCodeActivity_u'],
    DELETE: ['QRCodeActivity_d'],
  },
  activityFormRecord: {
    CREATE: ['ActivityFormRecord_c'],
    READ: ['ActivityFormRecord_r'],
    UPDATE: ['ActivityFormRecord_u'],
    DELETE: ['ActivityFormRecord_d'],
  },
  vipGift: {
    CREATE: ['VipGift_c'],
    READ: ['VipGift_r'],
    UPDATE: ['VipGift_u'],
    DELETE: ['VipGift_d'],
  },
  gift: {
    CREATE: ['Gift_c'],
    READ: ['Gift_r'],
    UPDATE: ['Gift_u'],
    DELETE: ['Gift_d'],
  },
  giftShippingList: {
    CREATE: ['Gift-ShippingList_c'],
    READ: ['Gift-ShippingList_r'],
    UPDATE: ['Gift-ShippingList_u'],
    DELETE: ['Gift-ShippingList_d'],
  },
  giftListVipGift: {
    CREATE: ['GiftList-VipGift_c'],
    READ: ['GiftList-VipGift_r'],
    UPDATE: ['GiftList-VipGift_u'],
    DELETE: ['GiftList-VipGift_d'],
  },
  giftListGift: {
    CREATE: ['GiftList-Gift_c'],
    READ: ['GiftList-Gift_r'],
    UPDATE: ['GiftList-Gift_u'],
    DELETE: ['GiftList-Gift_d'],
  },
  giftLisGiftVerification: {
    CREATE: ['GiftList-VipGift_c'],
    READ: ['GiftList-VipGift_r'],
    UPDATE: ['GiftList-VipGift_u'],
    DELETE: ['GiftList-VipGift_d'],
  },
  companyToken: {
    CREATE: ['OpenApiToken_c'],
    READ: ['OpenApiToken_r'],
    UPDATE: ['OpenApiToken_u'],
    DELETE: ['OpenApiToken_d'],
  },
  accountManagement: {
    CREATE: ['AccountManage_c'],
    READ: ['AccountManage_r'],
    UPDATE: ['AccountManage_u'],
    DELETE: ['AccountManage_d'],
  },
  dataAnalysis: {
    CREATE: ['ThemedDashboard_c'],
    READ: ['ThemedDashboard_r'],
    UPDATE: ['ThemedDashboard_u'],
    DELETE: ['ThemedDashboard_d'],
  },
}

export const PAGE_PERMISSIONS = {
  home: ['Dashboard_r'],
  dataAnalysis: [
    ...COMPONENT_PERMISSIONS.dataAnalysis.CREATE,
    ...COMPONENT_PERMISSIONS.dataAnalysis.READ,
    ...COMPONENT_PERMISSIONS.dataAnalysis.UPDATE,
    ...COMPONENT_PERMISSIONS.dataAnalysis.DELETE,
  ],
  memberNew: [...COMPONENT_PERMISSIONS.member.CREATE],
  memberEdit: [
    ...COMPONENT_PERMISSIONS.member.READ,
    ...COMPONENT_PERMISSIONS.member.UPDATE,
    ...COMPONENT_PERMISSIONS.member.DELETE,
  ],
  memberSearch: [...COMPONENT_PERMISSIONS.member.READ],
  membershipSetting: [
    ...COMPONENT_PERMISSIONS.membershipSetting.READ,
    ...COMPONENT_PERMISSIONS.membershipSetting.CREATE,
    ...COMPONENT_PERMISSIONS.membershipSetting.UPDATE,
  ],
  pointSetting: [
    ...COMPONENT_PERMISSIONS.point.READ,
    ...COMPONENT_PERMISSIONS.point.CREATE,
    ...COMPONENT_PERMISSIONS.point.UPDATE,
  ],
  pointHistory: [...COMPONENT_PERMISSIONS.pointHistory.READ],
  pointActivity: [
    ...COMPONENT_PERMISSIONS.barcodeRule.READ,
    ...COMPONENT_PERMISSIONS.barcodeRule.CREATE,
    ...COMPONENT_PERMISSIONS.barcodeRule.UPDATE,
  ],
  productActivity: [
    ...COMPONENT_PERMISSIONS.productRule.READ,
    ...COMPONENT_PERMISSIONS.productRule.CREATE,
    ...COMPONENT_PERMISSIONS.productRule.UPDATE,
  ],
  productActivityManagementEntry: [
    ...COMPONENT_PERMISSIONS.productRule.READ,
    ...COMPONENT_PERMISSIONS.productRule.CREATE,
    ...COMPONENT_PERMISSIONS.productRule.UPDATE,
  ],
  qrcodeActivity: [
    ...COMPONENT_PERMISSIONS.qrcodeActivity.READ,
    ...COMPONENT_PERMISSIONS.qrcodeActivity.CREATE,
    ...COMPONENT_PERMISSIONS.qrcodeActivity.UPDATE,
  ],
  qrcodeActivityContent: [
    ...COMPONENT_PERMISSIONS.qrcodeActivity.READ,
    ...COMPONENT_PERMISSIONS.qrcodeActivity.CREATE,
    ...COMPONENT_PERMISSIONS.qrcodeActivity.UPDATE,
  ],
  activityFormRecord: [
    ...COMPONENT_PERMISSIONS.activityFormRecord.READ,
    ...COMPONENT_PERMISSIONS.activityFormRecord.CREATE,
  ],
  giftExclusive: [
    ...COMPONENT_PERMISSIONS.vipGift.READ,
    ...COMPONENT_PERMISSIONS.vipGift.CREATE,
    ...COMPONENT_PERMISSIONS.vipGift.UPDATE,
  ],
  giftExclusiveSetting: [
    ...COMPONENT_PERMISSIONS.vipGift.READ,
    ...COMPONENT_PERMISSIONS.vipGift.CREATE,
    ...COMPONENT_PERMISSIONS.vipGift.UPDATE,
  ],
  giftExchange: [
    ...COMPONENT_PERMISSIONS.gift.READ,
    ...COMPONENT_PERMISSIONS.gift.CREATE,
    ...COMPONENT_PERMISSIONS.gift.UPDATE,
  ],
  giftSetting: [
    ...COMPONENT_PERMISSIONS.gift.READ,
    ...COMPONENT_PERMISSIONS.gift.CREATE,
    ...COMPONENT_PERMISSIONS.gift.UPDATE,
  ],
  // 好禮名單在以下模組中，至少要有一個有 read 權限
  giftList: [
    ...COMPONENT_PERMISSIONS.giftListVipGift.READ,
    ...COMPONENT_PERMISSIONS.giftListGift.READ,
    ...COMPONENT_PERMISSIONS.giftShippingList.READ,
    ...COMPONENT_PERMISSIONS.giftLisGiftVerification.READ,
  ],
  companyToken: [
    ...COMPONENT_PERMISSIONS.companyToken.READ,
    ...COMPONENT_PERMISSIONS.companyToken.CREATE,
    ...COMPONENT_PERMISSIONS.companyToken.UPDATE,
    ...COMPONENT_PERMISSIONS.companyToken.DELETE,
  ],

  accountManagement: [
    ...COMPONENT_PERMISSIONS.accountManagement.READ,
    ...COMPONENT_PERMISSIONS.accountManagement.CREATE,
    ...COMPONENT_PERMISSIONS.accountManagement.UPDATE,
    ...COMPONENT_PERMISSIONS.accountManagement.DELETE,
  ],
}

export const PAGE_BREADCRUMB = {
  home: {
    pathList: [],
    title: '首頁',
  },
  dataAnalysis: {},
  member: {
    new: {
      pathList: [{ title: '會員名單', slash: true }],
      title: '會員新增',
    },
    edit: {
      pathList: [
        { title: '會員名單', slash: true },
        { title: '會員名單', slash: false },
      ],
      title: '會員資料',
    },
    editting: {
      pathList: [
        { title: '會員名單', slash: true },
        { title: '會員查詢', slash: true },
        { title: '會員資料', slash: false },
      ],
      title: '會員資料編輯',
    },
    search: {
      pathList: [{ title: '會員名單', slash: true }],
      title: '會員查詢',
    },
  },
  membershipSetting: {
    pathList: [],
    title: '會籍設定',
  },
  pointContent: {
    history: {
      pathList: [{ title: '點數內容', slash: true }],
      title: '點數歷程',
    },
    setting: {
      pathList: [{ title: '點數內容', slash: true }],
      title: '點數設定',
    },
  },
  activitiesCode: {
    productActivity: {
      pathList: [{ title: '活動條碼', slash: true }],
      title: '商品活動',
      description: '針對消費商品，做額外贈點、不贈點之活動內容',
    },
    productActivityNew: {
      pathList: [
        { title: '活動條碼', slash: true },
        { title: '商品活動', slash: true },
      ],
      title: '新增商品活動',
    },
    productActivityEdit: {
      pathList: [
        { title: '活動條碼', slash: true },
        { title: '商品活動', slash: true },
      ],

      title: '編輯商品活動',
    },
    pointActivity: {
      pathList: [{ title: '活動條碼', slash: true }],
      title: '點數活動',
      description: '針對單筆消費，做使用點數折抵金額、額外贈點之活動內容',
    },
    qrcodeActivity: {
      pathList: [{ title: '活動條碼', slash: true }],
      title: 'QRCode 活動',
      description: '提供 QRCode 給會員掃碼之活動內容',
    },
    qrcodeActivityContent: {
      pathList: [
        { title: '活動條碼', slash: true },
        { title: 'QRCode 活動', slash: false },
      ],
      title: '新增 QRCode 活動',
    },
    activityFormRecord: {
      pathList: [{ title: '活動條碼', slash: true }],
      title: '活動表單紀錄',
    },
  },
  gift: {
    exchange: {
      pathList: [{ title: '好禮贈送', slash: true }],
      title: '贈品兌換',
    },
    setting: {
      pathList: [
        { title: '好禮贈送', slash: true },
        { title: '贈品兌換', slash: false },
      ],
      title: '新增贈品',
    },
    list: {
      pathList: [{ title: '好禮贈送', slash: true }],
      title: '好禮名單',
    },
    exclusive: {
      pathList: [{ title: '好禮贈送', slash: true }],
      title: '專屬好禮',
    },
    exclusiveSetting: {
      pathList: [
        { title: '好禮贈送', slash: true },
        { title: '專屬好禮', slash: false },
      ],
      title: '新增好禮',
    },
  },
  systemConfiguration: {
    accountManagement: {
      pathList: [{ title: '系統設定', slash: true }],
      title: '帳號管理',
    },
    accountManagementNew: {
      pathList: [
        { title: '系統設定', slash: true },
        { title: '帳號管理', slash: false },
      ],
      title: '新增帳號',
    },
    accountManagementEdit: {
      pathList: [
        { title: '系統設定', slash: true },
        { title: '帳號管理', slash: false },
      ],
      title: '編輯帳號',
    },
    companyToken: {
      pathList: [{ title: '系統設定', slash: true }],
      title: '廠商 Token',
    },
  },
}
