import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import withPermission from 'components/hocs/withPermission'
import { Button } from 'components/units'
import { COMPONENT_PERMISSIONS, PAGE_PATHS } from 'constant'
import * as format from 'helpers/format'

/* -------------------------------- 
  Caption 區塊
-----------------------------------*/

function Caption({ paginationProps, history }) {
  // 頁面類型(新增、編輯、檢視)
  const settingPageType = {
    new: 'new',
    edit: 'edit',
    view: 'view',
  }
  const { CREATE } = COMPONENT_PERMISSIONS.qrcodeActivity
  const PermissionAddActivityButton = withPermission(Button, CREATE)

  const handleNewActivity = () => {
    history.push({
      pathname: PAGE_PATHS.activitiesCode.qrcodeActivityContent,
      state: {
        ...history.location.state,
        pageType: settingPageType.new,
        qrcodeOnceData: null, // 表示新增活動，有值則為編輯活動
      },
    })
  }

  return (
    <Row className="caption my-0">
      <Col className="label-title">
        <div>
          總項目：
          <span className="paragraph-info">
            {format.number(paginationProps.totalSize)} 筆
          </span>
        </div>
      </Col>
      <Col className="label-title d-flex justify-content-end">
        <span className="white-space-nowrap mr-1">每頁</span>
        <SizePerPageDropdownStandalone
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
        <span>
          <PermissionAddActivityButton
            variant="outline-primary"
            className="ml-4"
            size="sm"
            onClick={handleNewActivity}
          >
            新增活動
          </PermissionAddActivityButton>
        </span>
      </Col>
    </Row>
  )
}

export default Caption

// add proptype for Caption
Caption.propTypes = {
  paginationProps: PropTypes.shape({
    totalSize: PropTypes.number,
    sizePerPage: PropTypes.number,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({
        pageType: PropTypes.string,
      }),
    }),
  }),
}

// add default
Caption.defaultProps = {
  paginationProps: {},
  history: {},
}
