import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'

const PointActivityListStyle = styled.div`
  .table-toolbar {
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
    color: ${colorObj.darkGray};
  }
  .total-size {
    color: ${colorObj.darkerGray};
  }
`

export default PointActivityListStyle
