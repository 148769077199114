import styled, { css } from 'styled-components'
import { useState, useRef, useContext } from 'react'
import { Calendar } from 'react-date-range'
import Modal from 'react-bootstrap/Modal'
import format from 'date-fns/format'
import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import isValid from 'date-fns/isValid'
import dateFnsLocale from 'date-fns/locale/zh-TW'
import ReactTooltip from 'react-tooltip'
import iconModalClose from 'assets/images/modal/icon_modalClose.svg'
import {
  clearSessionStorage,
  getRandomId,
  getSessionStorage,
} from 'helpers/common'
import { RootContext } from 'RootContext'
import addressData from 'constant/json/address.json'
import addressDataKarihome from 'constant/json/addresskarihome.json'
import { StyleSelect } from '../units/Select'
import { StyleInput } from '../units/Input'
import { StyleCheckbox } from '../units/Checkbox'
import { StyleRadio } from '../units/Radio'
import { StyleButton } from '../units/Button'
import { colorObj } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'
import { regexRuleNormal } from 'helpers/validation'

// 地址的City欄位
const addressCity = []
const addressCityKarihome = [] // 卡洛塔妮客制

for (const cityItem of addressData) {
  const { Code: cityCode, Name: cityName, Id: cityId } = cityItem
  addressCity.push({
    code: cityCode,
    name: cityName,
    id: cityId,
  })
}
// 卡洛塔妮客制
for (const cityItem of addressDataKarihome) {
  const { Code: cityCode, Name: cityName, Id: cityId } = cityItem
  addressCityKarihome.push({
    code: cityCode,
    name: cityName,
    id: cityId,
  })
}

const requiredWarning = '尚未填寫'

function StyleFieldTypeSwitch(props) {
  let {
    formIsTriggerValidate, // 有使用<Form>包起來的表單是否觸發發送表單來進行驗證
    fieldType, // fixFields、optionalFields、cutomerFields
    // eslint-disable-next-line no-unused-vars
    label, // checkbox & radio的文字選項
    children,
    formControlOption, // 設定表單欄位所需的值(name、require、value...)
    inputOption, // StyleInput類型(text、number...)所需參數
    selectOption, // StyleSelect類型所需參數
    dateOption, // 設定日期元件的參數
    feedbackText, // 欄位error的顯示
    // eslint-disable-next-line no-unused-vars
    containerClass, // 容器的class名稱
    title, // img類型的modal標題
    ...commonProps
  } = props
  const rootData = useContext(RootContext)
  const [dateValue, setDateValue] = useState(null)
  const [imgModal, setImgModal] = useState({
    status: false,
  })
  const reactTooltipRef = useRef()

  // tooltip的日期calendar選取處理
  const handleCalendarSelected = (dateValue) => {
    return dateValue ? new Date(dateValue) : null
  }
  // tooltip的日期calendar關閉處理
  const handleCloseCalendar = () => {
    const TooltipRef = reactTooltipRef.current
    TooltipRef.tooltipRef = null
    ReactTooltip.hide()
  }

  // tooltip的日期calendar清空欄位處理
  const handleClearCalendar = (props, formControlOption) => {
    if (formControlOption.value || dateValue) {
      formControlOption.value = null
      setDateValue(null)
      handleDateChange(null, '', props, formControlOption)
    }
  }

  // select 的option 配置
  const handleSelectOption = (arrData) => {
    const newArrData = []
    if (Array.isArray(arrData)) {
      for (const [dataIndex, dataItem] of arrData.entries()) {
        const { name, id, code, optionId } = dataItem
        newArrData.push({
          text: name,
          value: id ?? optionId ?? code,
          dropdownItemOption: {
            eventKey: dataIndex,
          },
        })
      }
    }
    return newArrData
  }
  /**
   * 處理下拉選單被選取的selected值
   * 將被選取的選項，比對下拉選單項目，找出對應的項目。
   * 比對優先順序為：optionId > id > code
   *
   * @param {object} valueObj - 被選取的選項。
   * @param {string} valueObj.name - 該選項的名稱。
   * @param {string} valueObj.id - 該選項的 ID。
   * @param {string} valueObj.code - 該選項的 代碼。
   * @param {string} valueObj.optionId - 該選項的 optionId，地址鄉鎮市區時使用。
   * @param {array} options - 包含下拉選單選項的陣列。
   * @returns {string} 對應到的項目，如果找不到則為空字串。
   */
  const getInitSelectedValue = (valueObj = {}, options) => {
    if (!Array.isArray(options)) {
      return ''
    }
    const { name, id, code, optionId } = valueObj

    // 從 optionId 開始檢查，如果有值就拿去比對，把對應的 item 抓出來
    const foundItem = options.find((optionItem) => {
      if (optionId) {
        return optionItem.id === optionId
      }
      if (id) {
        return optionItem.id === id
      }
      if (name) {
        return optionItem.name === name
      }
      if (code) {
        return optionItem.code === code
      }
      return false
    })

    if (foundItem) {
      return foundItem.optionId ?? foundItem.id ?? foundItem.code ?? ''
    }

    return ''
  }
  // 日期欄位變化
  const handleDateChange = (value, formatRules, props, formControlOption) => {
    const { onChange } = props
    const { name } = formControlOption
    if (value) {
      value = formatISO(new Date(value), formatRules)
      setDateValue(value)
    }
    if (typeof onChange === 'function') {
      onChange({ fieldValue: value, fieldName: name })
    }
  }
  // 圖片欄位modal顯示開關
  const toggleImgModal = (isShow) => {
    setImgModal((prevState) => {
      return {
        ...prevState,
        status: isShow,
      }
    })
  }
  // checkbox取得checked的項目
  const handleChecked = (code, valueList) => {
    if (valueList) {
      for (const valueItem of valueList) {
        const { code: valueCode } = valueItem
        if (code === valueCode) {
          return true
        }
      }
    }
    return false
  }

  const renderSwitch = (type, fieldProps = {}) => {
    let { options, fixedInputprops, ...props } = fieldProps
    const disabled = formControlOption?.disabled
    const value = formControlOption?.value

    switch (type) {
      // datetime類型已經被廢棄，改為datetime-local
      case 'datetime':
      case 'date':
      case 'time': {
        let formatRules = ''
        if (type === 'datetime') {
          formatRules = 'yyyy/MM/ddThh:mm:ss'
        } else if (type === 'date') {
          formatRules = 'yyyy/MM/dd'
        } else if (type === 'time') {
          formatRules = 'hh:mm:ss'
        }

        const formControlValue = formControlOption?.value
        const getValue = () => {
          let resultValue = ''
          if (!(formControlValue === undefined || formControlValue === null)) {
            // 若為生日直接取日期，不使用套件取值，以避免時區轉換問題
            if (formControlOption?.name === 'birthday') {
              resultValue = formControlOption.value
                .split('T')[0]
                .replace(/-/g, '/')
            } else if (isValid(parseISO(formControlValue))) {
              resultValue = format(
                parseISO(formControlOption.value),
                formatRules
              )
            }
            // 輸入注音、中文的話
            else if (formControlValue) {
              resultValue = formControlValue.replace(
                /[\u3105-\u3129\u02CA\u02C7\u02CB\u02D9]/g,
                ''
              )
            }
          } else if (dateValue) {
            resultValue = format(new Date(dateValue), formatRules)
          }
          return resultValue
        }

        const fieldSuffix = inputOption?.fieldSuffix // 欄位的後綴字
        if (fieldSuffix) {
          inputOption = {
            ...inputOption,
            appendContent: fieldSuffix ?? '',
            appendContentBgColor: 'transparent',
            isFocusCocatAppend: true,
            appendContentHaveBorder: false,
            appendContentPadding: '0 8px',
          }
        }
        delete inputOption?.fieldSuffix
        const Input = (
          <StyleInput
            {...inputOption}
            className="w-100"
            feedbackText={feedbackText}
            formControlOption={{
              ...formControlOption,
              ...fixedInputprops,
              autoComplete: 'off',
              onKeyDown(e) {
                e.preventDefault()
              },
              onCompositionEnd(e) {
                e.target.value = e.target.value.substr(0, 10)
              },
              value: getValue(),
            }}
          />
        )
        if (disabled) {
          return Input
        }
        const id = getRandomId()
        const globalOption = {
          eventOff: disabled ? 'click' : '',
          overridePosition: ({ left, top }) => {
            if (top < 0) {
              top = 75
            } else if (top <= 48) {
              top += 48
            }
            return {
              left,
              top,
            }
          },
        }
        const specificOption = {
          'data-background-color': '#fff',
          'data-event': 'click',
          'data-scroll-hide': false,
          'data-effect': 'solid',
        }

        return (
          <>
            <span data-tip data-for={id} {...specificOption}>
              {Input}
            </span>
            <ReactTooltip id={id} {...globalOption} ref={reactTooltipRef}>
              <Calendar
                locale={dateFnsLocale}
                maxDate={props?.max || fixedInputprops?.max}
                minDate={props?.min || fixedInputprops?.min}
                dragSelectionEnabled={false}
                color={colorObj.primary}
                onChange={(value) => {
                  handleCloseCalendar()
                  handleDateChange(value, formatRules, props, formControlOption)
                }}
                date={handleCalendarSelected(
                  formControlOption?.value ?? dateValue
                )}
                {...dateOption}
              />
              <div className="react-date-range-calendar-feature-container text-right mb-2">
                <StyleButton
                  className="mx-1"
                  variant="outline-gray"
                  colorType="gray"
                  colorAlpha="1"
                  size="md"
                  onClick={() => handleClearCalendar(props, formControlOption)}
                >
                  清除
                </StyleButton>
                <StyleButton
                  className="mx-1"
                  variant="outline-gray"
                  colorType="gray"
                  colorAlpha="1"
                  size="md"
                  onClick={() => handleCloseCalendar()}
                >
                  關閉
                </StyleButton>
              </div>
            </ReactTooltip>
          </>
        )
      }
      case 'email': {
        if (fieldType === 'fixFields') {
          feedbackText = '請輸入正確的Email格式'
        }
        return (
          <StyleInput
            {...props}
            feedbackText={
              formControlOption?.value === '' ? requiredWarning : feedbackText
            }
            formControlOption={{
              type,
              pattern: regexRuleNormal.email,
              ...formControlOption,
              ...fixedInputprops,
              value: formControlOption?.value ?? '',
            }}
          >
            {children}
          </StyleInput>
        )
      }
      case 'select': {
        // 固定欄位
        const initPlaceholderList = ['city', 'dist']
        if (
          fieldType === 'fixFields' &&
          initPlaceholderList.includes(formControlOption?.name) &&
          !formControlOption.placeholder
        ) {
          formControlOption.placeholder = '請選擇'
        }
        return (
          <StyleSelect
            {...props}
            {...selectOption}
            feedbackText={
              formControlOption?.value === '' ? requiredWarning : feedbackText
            }
            selectedValue={getInitSelectedValue(value, options)}
            formControlOption={{
              ...formControlOption,
              ...fixedInputprops,
              isInvalid:
                formIsTriggerValidate &&
                formControlOption?.required &&
                !formControlOption?.value,
            }}
            optionItems={handleSelectOption(options)}
          />
        )
      }
      case 'radio': {
        const required = formControlOption?.required
        return (
          <>
            {Array.isArray(options) &&
              options.map((item, index) => {
                const { name, code, selected, combineFields } = item
                let label = name
                const hasCombineFields =
                  combineFields &&
                  Array.isArray(combineFields) &&
                  combineFields.length > 0

                if (hasCombineFields) {
                  let maskElement = null
                  label = (
                    <div className="d-flex align-items-center w-100">
                      <span>{name}</span>
                      <div className="combine-fields-container ml-1">
                        {combineFields.map(
                          (combineFieldItem, combineFieldIndex) => {
                            const { fieldType: type, ...combineFieldProps } =
                              combineFieldItem

                            const fieldTypeSwitchProps = {
                              formIsTriggerValidate,
                              type,
                              feedbackText:
                                combineFieldProps?.errorMessage || '尚未填寫',
                              formControlOption: {
                                pattern: combineFieldProps.fieldRegex || null,
                                required: combineFieldProps.isRequired,
                                disabled: !!(
                                  !selected || !combineFieldProps.canModify
                                ),
                                name: combineFieldProps?.name,
                                placeholder: combineFieldProps?.placeholder,
                                tip: combineFieldProps.tip,
                                errormessage: combineFieldProps.errormessage,
                                maxLength: combineFieldProps?.fieldLength,
                                // value: combineFieldProps?.value ?? "",
                                defaultValue: combineFieldProps.value ?? '',
                              },
                              options,
                              onChange: props.onChange,
                              fieldType,
                            }

                            if (
                              fieldTypeSwitchProps?.formControlOption?.disabled
                            ) {
                              maskElement = true
                            }

                            return (
                              // 此為遞迴元件的應用
                              <StyleFieldTypeSwitch
                                key={`radio-combineField-${
                                  code ?? id ?? combineFieldIndex
                                }`}
                                {...fieldTypeSwitchProps}
                                data-fieldtype={fieldType}
                                data-category={formControlOption.name}
                                data-combinefields-index={combineFieldIndex}
                              />
                            )
                          }
                        )}
                      </div>
                    </div>
                  )
                  /*
                      製作一個為空值且disabled的input，否則原始input因為設定defaultValue的關係，
                      使的checkbox關閉再打開時，input上的值(這邊指的是ui視覺)並未清空。
                      而若原始input不用defaultValue，而是用value，會有個無法輸入中文的問題
                    */
                  if (maskElement) {
                    label = (
                      <div className="d-flex align-items-center w-100">
                        <span>{name}</span>
                        <div className="combine-fields-container ml-1">
                          <StyleInput
                            formControlOption={{
                              value: '',
                              disabled: true,
                            }}
                          />
                        </div>
                      </div>
                    )
                  }
                }
                // 若id未填寫，則隨機產生英數字組合
                const id = getRandomId()
                return (
                  <StyleRadio
                    key={`radio-item-${code ?? id ?? index}`}
                    {...props}
                    {...formControlOption}
                    label={label}
                    value={code}
                    id={props.id ?? id}
                    data-value-type={typeof code}
                    inline={
                      fixedInputprops?.inline === true || props.inline === true
                    }
                    checked={
                      fieldType !== 'optionalFields' &&
                      fieldType !== 'customizedFields' &&
                      fieldType !== 'expandFields'
                        ? // 固定欄位 or 一般欄位
                          typeof code === 'string'
                          ? String(formControlOption?.value) === String(code)
                          : formControlOption?.value === code
                        : // 彈性欄位、客製欄位、擴充欄位
                          formControlOption?.value?.code === code
                    }
                    isInvalid={
                      !!(
                        required &&
                        index === options.length - 1 &&
                        formIsTriggerValidate &&
                        Object.keys(formControlOption?.value).length < 1
                      )
                    }
                    feedback={feedbackText}
                  />
                )
              })}
          </>
        )
      }
      case 'checkbox': {
        const required = formControlOption?.required

        return (
          <>
            {Array.isArray(options)
              ? options.map((item, index) => {
                  const { name, code, id, combineFields } = item
                  let label = name
                  const hasCombineFields =
                    combineFields &&
                    Array.isArray(combineFields) &&
                    combineFields.length > 0

                  if (hasCombineFields) {
                    let maskElement = null
                    const disabledCombineFields = getSessionStorage(
                      'disabledCombineFields'
                    )
                    let disabledCombineFieldsData = null
                    if (disabledCombineFields) {
                      disabledCombineFieldsData = JSON.parse(
                        disabledCombineFields
                      )
                    }
                    const isCancelSave = getSessionStorage('isCancelSave')

                    label = (
                      <div className="d-flex align-items-center w-100">
                        <span>{name}</span>
                        <div className="combine-fields-container ml-1">
                          {combineFields.map(
                            (combineFieldItem, combineFieldIndex) => {
                              const { fieldType: type, ...combineFieldProps } =
                                combineFieldItem

                              const fieldTypeSwitchProps = {
                                formIsTriggerValidate,
                                type,
                                feedbackText: '', // combineFields下的欄位不顯示錯誤提醒，只顯示紅框
                                formControlOption: {
                                  pattern: combineFieldProps.fieldRegex || null,
                                  required: combineFieldProps.isRequired,
                                  disabled: !!(
                                    formControlOption.disabled ||
                                    !combineFieldProps.canModify
                                  ),
                                  name: combineFieldProps?.name,
                                  placeholder: combineFieldProps?.placeholder,
                                  tip: combineFieldProps.tip,
                                  errormessage: combineFieldProps.errormessage,
                                  maxLength: combineFieldProps?.fieldLength,
                                  // value: combineFieldProps?.value ?? "",
                                  defaultValue: disabledCombineFieldsData?.[
                                    combineFieldProps?.name
                                  ]
                                    ? ''
                                    : combineFieldProps.value ?? '',
                                },
                                options,
                                onChange: props.onChange,
                                fieldType,
                              }
                              if (
                                fieldTypeSwitchProps?.formControlOption
                                  ?.disabled &&
                                disabledCombineFieldsData?.[
                                  combineFieldProps?.name
                                ]
                              ) {
                                maskElement = true
                              }
                              if (isCancelSave) {
                                maskElement = false
                              }

                              return (
                                // 此為遞迴元件的應用
                                <StyleFieldTypeSwitch
                                  key={`checkbox-combineField-${
                                    combineFieldProps.name ?? combineFieldIndex
                                  }`}
                                  {...fieldTypeSwitchProps}
                                  data-fieldtype={fieldType}
                                  data-category={formControlOption.name}
                                  data-combinefields-index={combineFieldIndex}
                                />
                              )
                            }
                          )}
                        </div>
                      </div>
                    )
                    /*
                      製作一個為空值且disabled的input，否則原始input因為設定defaultValue的關係，
                      使的checkbox關閉再打開時，input上的值(這邊指的是ui視覺)並未清空。
                      而若原始input不用defaultValue，而是用value，會有個無法輸入中文的問題
                    */
                    if (maskElement) {
                      label = (
                        <div className="d-flex align-items-center w-100">
                          <span>{name}</span>
                          <div className="combine-fields-container ml-1">
                            <StyleInput
                              formControlOption={{
                                value: '',
                                disabled: true,
                              }}
                            />
                          </div>
                        </div>
                      )
                    }
                  }
                  clearSessionStorage('isCancelSave')
                  return (
                    <StyleCheckbox
                      id={
                        // 若id未填寫，則隨機產生英數字組合
                        id ?? getRandomId()
                      }
                      data-value-type={typeof code}
                      isInvalid={
                        !!(
                          required &&
                          index === options.length - 1 &&
                          formIsTriggerValidate &&
                          formControlOption?.value.length < 1
                        )
                      }
                      key={`style-checkbox-${code ?? id ?? index}`}
                      label={label}
                      value={code}
                      checked={
                        Array.isArray(formControlOption?.value)
                          ? handleChecked(code, formControlOption?.value)
                          : formControlOption?.checked ?? false
                      }
                      {...props}
                      {...formControlOption}
                      feedback={
                        formControlOption?.value === ''
                          ? requiredWarning
                          : feedbackText
                      }
                    />
                  )
                })
              : null}
          </>
        )
      }
      case 'textarea':
        return (
          <StyleInput
            {...props}
            feedbackText={
              formControlOption?.value === '' ? requiredWarning : feedbackText
            }
            formControlOption={{
              as: 'textarea',
              ...formControlOption,
              ...fixedInputprops,
              value: formControlOption?.value ?? '',
            }}
          />
        )
      case 'hidden':
        return (
          <StyleInput
            {...props}
            feedbackText={feedbackText}
            formControlOption={{
              type: 'hidden',
              ...formControlOption,
              ...fixedInputprops,
              value: formControlOption?.value ?? '',
            }}
          />
        )
      case 'url': {
        return (
          <>
            {value && (
              <>
                <img
                  className={props.className}
                  src={value ?? ''}
                  alt="field-type-switch-img-zoom-out"
                  onClick={() => toggleImgModal(true)}
                />

                <Modal
                  show={imgModal.status}
                  onHide={() => toggleImgModal(false)}
                >
                  <ImgModalStyle>
                    <Modal.Header className="w-100">
                      <Modal.Title>{title}</Modal.Title>
                      <div>
                        <img
                          className="cursor-pointer"
                          src={iconModalClose}
                          alt="icon-modal-close"
                          onClick={() => toggleImgModal(false)}
                        />
                      </div>
                    </Modal.Header>
                    <Modal.Body className="w-100">
                      <div className="img-zoom-in-container">
                        <img
                          className="w-100"
                          src={value ?? ''}
                          alt="field-type-switch-img-zoom-in"
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="w-100">
                      <StyleButton
                        variant="outline-darkerGray"
                        onClick={() => toggleImgModal(false)}
                      >
                        關閉
                      </StyleButton>
                    </Modal.Footer>
                  </ImgModalStyle>
                </Modal>
              </>
            )}
          </>
        )
      }
      default: {
        const fieldSuffix = inputOption?.fieldSuffix // 欄位的後綴字
        if (fieldSuffix) {
          props = {
            ...props,
            appendContent: fieldSuffix ?? '',
            appendContentBgColor: 'transparent',
            isFocusCocatAppend: true,
            appendContentHaveBorder: false,
            appendContentPadding: '0 8px',
          }
        }
        delete inputOption?.fieldSuffix

        const valueOrDefaultValue = {
          defaultValue: formControlOption?.defaultValue,
        }
        if (
          formControlOption?.defaultValue === undefined ||
          formControlOption?.defaultValue === null
        ) {
          valueOrDefaultValue.value = formControlOption?.value ?? ''
        }
        return (
          <StyleInput
            {...props}
            {...inputOption}
            feedbackText={
              formControlOption?.value === '' ? requiredWarning : feedbackText
            }
            formControlOption={{
              ...formControlOption,
              ...fixedInputprops,
              ...valueOrDefaultValue,
            }}
          />
        )
      }
    }
  }

  const handleFixFields = (props, formControlOption) => {
    let type = ''
    const inputProps = {}
    const { name } = formControlOption
    switch (name) {
      case 'shop':
        type = 'select'
        break
      case 'membership_setting_level':
        type = 'select'
        break
      case 'name':
        type = 'text'
        inputProps.maxLength = 50
        inputProps.placeholder = '上限 50 字'
        inputProps.pattern = regexRuleNormal.name
        break
      case 'birthday':
        type = 'date'
        inputProps.max = new Date()
        inputProps.min = new Date('1900/1/1')
        break
      case 'gender':
        type = 'radio'
        inputProps.inline = true
        break
      case 'cell_phone':
        type = 'tel'
        inputProps.maxLength = 10
        inputProps.pattern = '^09[0-9]{8}$'
        feedbackText = '手機格式不正確'
        break
      case 'phone_1':
        type = 'text'
        inputProps.maxLength = 3
        break
      case 'phone_2':
        type = 'text'
        inputProps.maxLength = 20
        break
      case 'email':
        type = 'email'
        inputProps.maxLength = 128
        break
      case 'city':
        type = 'select'
        inputProps.options = addressCity
        if (rootData?.brandId === 90) {
          inputProps.options = addressCityKarihome
        }
        break
      case 'dist':
        type = 'select'
        break
      case 'address':
        type = 'text'
        inputProps.maxLength = 200
        break
      case 'registered_date':
        type = 'datetime'
        break
      case 'last_modify_time':
        type = 'datetime'
        break
      case 'start_date':
        type = 'datetime'
        break
      case 'end_date':
        type = 'datetime'
        break
      case 'custom_id':
        type = 'text'
        break
      default:
        break
    }
    const content = renderSwitch(type, {
      ...props,
      options: inputProps.options ?? props.options,
      fixedInputprops: inputProps,
    })
    return content
  }
  const handleOptionalFields = (props) => {
    const content = renderSwitch(props.type, { ...props })
    return content
  }
  const handleCustomizedFields = (props) => {
    const content = renderSwitch(props.type, { ...props })
    return content
  }
  const handleExpandFields = (props) => {
    const content = renderSwitch(props.type, { ...props })
    return content
  }
  // 不屬於任何fieldType
  const handleWithoutFieldType = (type, props) => {
    const content = renderSwitch(type, props)
    return content
  }

  const handleFieldsTemplate = () => {
    switch (fieldType) {
      case 'fixFields':
        return handleFixFields(commonProps, formControlOption)
      case 'optionalFields':
        return handleOptionalFields(commonProps)
      case 'customizedFields':
        return handleCustomizedFields(commonProps)
      case 'expandFields':
        return handleExpandFields(commonProps)
      default:
        return handleWithoutFieldType(props.type, commonProps)
    }
  }

  const fieldContent = handleFieldsTemplate()

  return (
    <LocalStyle {...props}>
      {fieldContent}
      {props?.formControlOption?.tip && (
        <div className="tip-container px-2">
          {props?.formControlOption?.tip}
        </div>
      )}
    </LocalStyle>
  )
}
// 個別欄位樣式設定
const eachStyle = ({ fieldType, formControlOption, type }) => {
  // 固定欄位
  if (fieldType === 'fixFields') {
    switch (formControlOption?.name) {
      case 'phone_1':
        return css`
          padding-right: ${spacingObj.micro};
        `
      case 'city':
      case 'dist':
        return css`
          width: 15%;
          min-width: 104px;
          padding-right: ${spacingObj.micro};
          .dropdown-toggle {
            width: 100%;
          }
        `
      case 'shop':
        return css`
          .dropdown-toggle {
            overflow: hidden;
            white-space: no-wrap;
            text-overflow: ellipsis;
          }
        `
      case 'birthday':
        return css`
          .__react_component_tooltip {
            &.show {
              &[data-id='tooltip'] {
                box-shadow: 0 0 10px #888888;
                opacity: 1;
                pointer-events: initial;
              }
            }
          }
          .rdrCalendarWrapper {
            &::before {
              content: '';
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: -1;
            }
            .rdrSelected {
              color: ${colorObj.primary} !important;
              filter: none;
            }
          }
        `
      default:
        break
    }
  }
  // 依照欄位類型
  switch (type) {
    case 'select':
      return css`
        .dropdown-toggle {
          width: 100%;
        }
      `
    case 'textarea':
      return css`
        textarea {
          resize: none;
        }
      `
    case 'datetime':
    case 'date':
    case 'time':
      return css`
        .__react_component_tooltip {
          &.show {
            &[data-id='tooltip'] {
              box-shadow: 0 0 10px #888888;
              opacity: 1;
              pointer-events: initial;
            }
          }
        }
        .rdrCalendarWrapper {
          &::before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
          }
          .rdrSelected {
            color: ${colorObj.primary} !important;
            filter: none;
          }
        }
      `
    case 'url':
      return css`
        img {
          max-height: 60px;
          cursor: pointer;
        }
      `
    default:
      break
  }
}
const ImgModalStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .img-zoom-in-container {
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      max-width: 320px;
    }
  }
`

function LocalStyle({
  fieldType,
  containerClass,
  children,
  formControlOption,
  type,
}) {
  return (
    <CommonStyle
      fieldType={fieldType}
      formControlOption={formControlOption}
      type={type}
      className={`field-type-switch-container ${containerClass ?? ''} ${
        type === 'checkbox' ? 'checkboxs-container' : ''
      }`}
    >
      {children}
    </CommonStyle>
  )
}

const CommonStyle = styled.div`
  width: 100%;

  .style-tooltip-container {
    [data-id='tooltip'] {
      pointer-events: initial;
      box-shadow: 0 0 10px #888888;
    }
  }
  .tip-container {
    font-size: 80%;
    margin-top: 0.25rem;
    color: ${colorObj.gray};
  }
  ${(props) =>
    eachStyle({
      fieldType: props.fieldType,
      formControlOption: props.formControlOption,
      type: props.type,
    })};
`

export default StyleFieldTypeSwitch
