import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const LocalStyle = styled.div`
  margin: 0 auto;
  padding: 40px;
  max-width: 1280px;

  .title {
    margin-top: 0;
    margin-bottom: ${spacingObj.medium};
    font-size: 26px;
    color: ${colorObj.dark};
  }

  div {
    margin-top: ${spacingObj.medium};
    font-size: 18px;
    color: ${colorObj.dark};
  }
  p {
    margin-top: ${spacingObj.small};
    margin-bottom: 0;
    font-size: 15px;
    color: ${colorObj.darkGray};
  }

  ul {
    padding-left: 50px;
    margin-bottom: 0;
    li {
      list-style-type: disc;
    }
  }
`

function PrivacyPolicy() {
  return (
    <LocalStyle>
      <div className="title">隱私權聲明</div>
      <p>
        親愛的客戶您好，感謝您使用本平台對您寶貴的會員資訊進行管理！
        <br />
        為因應社會環境及法令規定的變遷與科技的進步，功典資訊股份有限公司（以下簡稱本公司）訂定本隱私權保護聲明並適用於「會員忠誠平台（LitLoyal）」（以下簡稱本平台），本公司相當重視對於使用者於使用本平台個人資料或網路活動情況之蒐集、運用與隱私權保護。本平台為代本公司之客戶品牌（下稱合作對象）提供會員管理與互動體驗之服務系統，對於為本公司客戶代為進行之資料處理及利用均遵守中華民國政府之「個人資料保護法」及相關法令規範，且妥善保護使用者之個人資料向來是本公司之首要目標。在此向您說明本公司本平台之隱私權保護政策，及相關資料保護之措施。
      </p>
      <div>第一條 資料蒐集</div>
      <p>
        一、基於本平台為提供合作對象品牌服務與管理會員資訊之中立系統，用以提供您與使用者最佳的會員互動與貴賓體驗，請理解當您於本平台瀏覽本公司之相關服務訊息時，並不需要提供任何個人資料，但當您在本平台舉行活動、或透過本平台遞交屬於您所持有的會員資料時，本公司或合作對象即可能會要求您登錄個人姓名、連絡方式，以方便與您取得連繫、完成交易、提供服務或處理訂購程序。
        <br />
        <br />
        二、當您在本平台上舉辦/提供實際消費行為或活動時，我們會留存並處理您所提供的參與者相關帳務資料，除此之外，基於您於本平台所進行的會員服務體驗，包括但不限於參與市場分析、贈獎活動、接受本平台代您所發送、執行之會員及客戶權益通知，均有可能依實際服務項目請您提供資料。
        <br />
        <br />
        三、平台有可能會記錄您上網的 IP
        位址、上網時間以及在本平台內所瀏覽的網頁等資料，以作為本平台流量與與使用者網路行為統計分析，以利提升本平台服務品質。
        <br />
        <br />
        四、資料蒐集之目的包括:
        商品銷售、金融交易授權、物流配送、廣告行銷、市場分析、贈獎活動、會員服務、相關權益與活動之通知、以及其它未來所有可能衍生之服務。
        <br />
        <br />
        五、依據法務部頒佈之「個人資料保護法之特定目的及個人資料之類別」，本公司為您處理、利用及保有您所持有的會員個人資料之可能種類列示如下。
        <ul>
          <li>
            Ｃ○○一
            辨識個人者。（姓名、職稱、住址、工作地址、以前地址、住家電話號碼、行動電話、網路平臺申請之帳號、通訊及戶籍地址、電子郵遞地址及其他任何可辨識資料本人者等。）
          </li>
          <li>Ｃ○一一 個人描述。（例如：年齡、性別、出生年月日、等。） </li>
          <li>Ｃ○三一 住家及設施。（如：住所地址）</li>
        </ul>
        <br />
        六、依據法務部頒佈之「個人資料保護法之特定目的及個人資料之類別」，本公司就您所提供本公司您所持有的會員資料之資訊，將有可能處理、利用及保有您個人資料之特定目的列示如下：
        <ul>
          <li>040 行銷</li> <li>090 消費者、客戶管理與服務</li>
          <li>091 消費者保護</li> <li>107 採購與供應管理</li>
          <li>129 會計與相關服務</li> <li>136 資（通）訊與資料庫管理</li>
          <li>152 廣告或商業行為管理</li> <li>157 調查、統計與研究分析</li>
          <li>181 其他經營合於營業登記項目或組織章程所定之業務</li>
          <li>132 經營傳播業務</li>
        </ul>
      </p>
      <div>第二條 個資處理與利用</div>
      <p>
        一、本公司對於會員提供之個人資料，依個人資料保護相關法令規定，於本公司提供之線上訂購、行銷、售後服務、與其他契約或類似契約義務等服務範圍內為處理及利用，並妥善維護其安全性。
        <br />
        <br />
        二、在以下情況下，本公司有權查看您於本平台上所提供處理之資料，或提供予有權機關或單位、或主張其權利受侵害並提出適當證明之第三人：
        <br />※ 依法令規定、或依司法機關或其他依法行政機關及單位的命令；
        <br />※ 為執行本約定條款、或消費者違反約定條款；
        <br />※ 為維護本公司系統之正常運作及安全；
        <br />※ 為保護本公司、其他消費者、或其他第三人的合法權益；
        <br />
        <br />
        三、本平台所保有與處理之個人資料以最小化資訊揭露原則，除非另有法令規範，或另行取得您的同意，否則我們不會向前述以外之第三人揭露您所提供之個人資料。
        <br />
        <br />
        四、為提供會員持續性服務，本公司個資利用之期間直到您提出申請不再使用本平台相關服務，我們的客服人員如有受到相關的委託，將確認並告知您相關權益後進行刪除。
        <br />
        <br />
        五、個資利用之地區除法令另有規定外，原則上我們僅會基於您授權範圍進行資料之處理與利用。
      </p>
      <div>第三條 個資安全</div>
      <p>
        本公司承諾提供之資訊系統符合目前科技技術（包括:但不限於交易傳輸加密、動態簡訊認證、防火牆存取控制），本公司之服務系統業已通過國際資訊安全管理系統ISO27001，若消費者發現本系統有錯誤或疏失，請立即通知本公司。
      </p>
      <div>第四條 網路暫存資料的使用</div>
      <p>
        為提升本平台服務，本平台會使用第三方套件的技術與瀏覽器儲存技術，以便於提供會員所需要的服務，您可以在瀏覽器功能選項關閉或刪除相關紀錄，但有可能發生無法順利登入本平台使用相關服務或無法記錄、調取先前資料等狀況（包括但不限於預定、消費、點數存入等行為紀錄）。
      </p>
      <div>第五條 個資當事人權利</div>
      <p>
        您在本平台註冊為會員後，
        若您提供予本平台之個人資料事後有任何變動或欲更正補充，請您依照本平台之指示進行線上登入以維護您持有的會員資料或連絡客服專線進行更新，否則本公司對於依您留存之會員資料提供相關服務致您受有損失時，不負任何賠償責任。
      </p>
      <div>第六條 隱私權保護聲明之修訂與公告</div>
      <p>
        本公司得因應科技發展與市場變動而修訂隱私權保護聲明，隱私權保護聲明以本公司官方本平台最新公告版本為準，如有更新不另行通知。基於本平台為建會員管理之使用工具，如果您對於本平台隱私權聲明、或與個人資料有關之相關事項有任何疑問，得與本公司客服人員（service@migocorp.com）聯繫。
      </p>
    </LocalStyle>
  )
}
export default PrivacyPolicy
