import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const ToastCustomWrapper = styled.div`
  font-size: 15px;
  color: ${colorObj.dark};
  .toast-wrapper {
    min-width: 250px;
    margin-top: 16px;
    padding-bottom: 16px;
    &-flexbox {
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 0px;
    }
  }
  .toast-wrapper-default {
    margin: 0px;
    &-flexbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 0px;
      padding-right: 0px;
      box-sizing: border-box;
    }
  }
  .toast-content-flex {
    display: flex;
    flex-direction: column;
  }
  h3 {
    margin: 0;
    font-size: 18px;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .toast-content {
    line-height: 24px;
  }
  .toast-cancelText {
    color: ${colorObj.gray};
    text-align: center;
    line-height: 16px;
    margin-left: 10px;
    font-size: 13px;
    cursor: pointer;
  }
  svg {
    cursor: pointer;
    text-align: center;
    margin-bottom: 1px;
  }
  .toast-action {
    margin-top: ${spacingObj.small};
    text-decoration: underline;
    color: ${colorObj.primary};
    cursor: pointer;
  }
`

const UploadToastWrapper = styled.div`
  font-size: 15px;
  color: ${colorObj.dark};
  background-color: white;
  // 元件設計稿寬為 321px，但是 react-hot-toast 的計算方式，需手動計算 padding 並扣掉
  width: 272px;
`

const ToastHeader = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    flex-grow: 1;
    display: flex;
  }
  > :nth-child(2) {
    width: 16px;
    height: 11px;
    transform: ${(props) => (props.isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
`

const ToastBody = styled.div`
  display: flex;
  flex-direction: column;
  .text-style {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 24px;
  }
  .module {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin-top: 16px;
    span {
      flex-grow: 1;
      font-size: 18px;
    }
    button {
      color: ${colorObj.gray};
      font-size: 13px;
      white-space: nowrap;
      border: none;
      background: none;
      padding: 0px;
    }
  }
`
const ToastFooter = styled.div`
  width: fit-content;
  padding-top: 16px;
  display: none;
  button,
  a {
    color: ${colorObj.primary};
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  &.show {
    display: block;
  }
`

export {
  ToastCustomWrapper,
  UploadToastWrapper,
  ToastHeader,
  ToastBody,
  ToastFooter,
}
