import { Fragment, useEffect, useState } from 'react'
import { FormCheck } from 'react-bootstrap'
import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const weeklyOption = {
  list: [
    {
      text: '一',
      name: 'mon',
      value: 'mon',
      checked: false,
    },
    {
      text: '二',
      name: 'tue',
      value: 'tue',
      checked: false,
    },
    {
      text: '三',
      name: 'wed',
      value: 'wed',
      checked: false,
    },
    {
      text: '四',
      name: 'thu',
      value: 'thu',
      checked: false,
    },
    {
      text: '五',
      name: 'fri',
      value: 'fri',
      checked: false,
    },
    {
      text: '六',
      name: 'sat',
      value: 'sat',
      checked: false,
    },
    {
      text: '日',
      name: 'sun',
      value: 'sun',
      checked: false,
    },
  ],
}
// css樣式
const WeeklyStyle = styled.div`
  width: 337px;

  .form-check-inline {
    color: ${colorObj.darkerGray};
    justify-content: center;
    align-items: center;
    margin: 0;
    border-color: ${colorObj.darkerGray};
    border-width: 1px 1px 1px 0;
    border-style: solid;
    width: calc(100% / 7);

    :first-of-type {
      border-left-width: 1px;
      border-radius: ${spacingObj.micro} 0 0 ${spacingObj.micro};
    }
    :last-of-type {
      border-radius: 0 ${spacingObj.micro} ${spacingObj.micro} 0;
    }
    > input {
      display: none;

      :checked {
        + label {
          color: ${colorObj.white} !important;
          background-color: ${colorObj.primary};
        }
      }
    }
    > label {
      width: 100%;
      cursor: pointer;
      text-align: center;
      padding: ${spacingObj.mini} 0;
      white-space: nowrap;
      color: ${colorObj.darkerGray} !important;
    }
  }
`

function Weekly({ onChange, className, fieldName, value, isInit, ...prop }) {
  const [list, setList] = useState(weeklyOption.list)
  const [isFirstLoad, setFirstLoad] = useState(true)

  const handleChange = (e, index) => {
    let obj = {}

    const newState = [...list]
    const checkedItem = JSON.parse(JSON.stringify(newState[index]))
    checkedItem.checked = e.target.checked
    newState[index] = checkedItem
    obj = {
      [fieldName]: {},
    }
    if (fieldName) {
      for (const item of newState) {
        const { name, checked } = item
        obj[fieldName][name] = checked
      }
    }

    setList(newState)
    setFirstLoad(false)
    if (typeof onChange === 'function') {
      onChange(e, index, obj)
    }
  }

  // 處理是否reset
  useEffect(() => {
    if (isInit) {
      setList(weeklyOption.list)
    }
  }, [isInit])

  // 處理初次載入時，有值的情況(編輯狀態)
  useEffect(() => {
    if (isFirstLoad && value) {
      const deepCloneList = JSON.parse(JSON.stringify(weeklyOption.list))
      for (const valueKeyName in value) {
        for (const [index, item] of deepCloneList.entries()) {
          const { name } = item
          if (name === valueKeyName) {
            deepCloneList[index].checked = value[valueKeyName]
            break
          }
        }
      }
      setList(deepCloneList)
    }
  }, [isFirstLoad, value])

  return (
    <WeeklyStyle>
      {list.map((item, index) => {
        const { name, value, text, checked } = item
        return (
          <Fragment key={`weekly-${index}`}>
            <FormCheck
              {...prop}
              type="checkbox"
              className={className}
              name={name}
              value={value}
              inline
              label={text}
              // id取隨機值(主要是讓點選label也能觸發checkbox)
              id={prop?.id || Math.random().toString(36).substring(2)}
              checked={checked}
              onChange={(e) => handleChange(e, index)}
            />
          </Fragment>
        )
      })}
    </WeeklyStyle>
  )
}

export default Weekly
