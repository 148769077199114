import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoginForm from 'components/pages/login/widgets/LoginForm'
import { postAuthThunk, postPasswordResetThunk } from 'store/auth/authAsync'
import { authResultSelector } from 'store/auth/authSelector'
import { updateIsFetch } from 'store/auth/authSlice'

function LoginContainer() {
  const history = useHistory()
  const dispatch = useDispatch()
  const authResult = useSelector(authResultSelector)
  const [isFetchingForgetPassword, setIsFetchingForgetPassword] =
    useState(false)

  const handleSubmit = async ({ formData, actionType }) => {
    const thunkConfig = {
      'password-forget': {
        thunk: postPasswordResetThunk,
        payload: { email: formData.email },
      },
      login: {
        thunk: postAuthThunk,
        payload: formData,
      },
    }
    const { thunk, payload } = thunkConfig[actionType]
    if (actionType === 'password-forget') {
      setIsFetchingForgetPassword(true)
    }
    try {
      await dispatch(thunk(payload))
    } catch (e) {
      console.log('err', e)
    } finally {
      setIsFetchingForgetPassword(false)
    }
  }
  const handleInputChange = () => {
    dispatch(updateIsFetch(false))
  }

  useEffect(() => {
    const { isLoggedIn, accessToken } = authResult
    if (accessToken && isLoggedIn) {
      history.push('/')
    }
  }, [authResult, history])

  return (
    <LoginForm
      formError={authResult.loginFormError}
      isFetchingForgetPassword={isFetchingForgetPassword}
      onChange={handleInputChange}
      onSubmit={handleSubmit}
    />
  )
}

export default LoginContainer

LoginContainer.propTypes = {
  auth: PropTypes.shape({
    value: PropTypes.shape({
      loading: PropTypes.bool,
      accessToken: PropTypes.string,
      isLoggedIn: PropTypes.bool,
    }).isRequired,
  }),
}

LoginContainer.defaultProps = {
  auth: {
    value: {
      loading: true,
      accessToken: '',
      isLoggedIn: false,
    },
  },
}
