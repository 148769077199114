import {
  useState,
  useEffect,
  createContext,
  useContext,
  useReducer,
  useCallback,
} from 'react'
import { useHistory, Redirect } from 'react-router'
import { Tabs, Tab } from 'react-bootstrap'
import { isAfter, startOfToday, formatISO } from 'date-fns'
import { Breadcrumb, Button, Modal, Mask, Loader } from 'components/units'
import { Basic } from 'components/templates'
import { number } from 'helpers/format'
import { ExchangePage } from 'components/pages/Gift'
import {
  settingPageType,
  tabStatus as configTabStatus,
} from 'pages/Gift/config'
import { ReactComponent as IconCancel } from 'assets/images/button/icon_cancel.svg'
import { ReactComponent as IconSave } from 'assets/images/button/icon_save.svg'
import { getEvent, getMemberList, addEvent, updateEvent } from 'api/ApiMain'
import { PAGE_PATHS } from 'constant'
import { color } from 'assets/styles/Variable/Color'
import {
  validMsg,
  initState,
  stateReducer,
  initValid,
  exchangePathway,
  collectionMethod,
} from 'components/pages/Gift/ExchangeContentConfig'
import {
  StyledSideSection,
  StyledContentSection,
  TransparentMask,
} from 'pages/Gift/ExchangeSettingStyle'
import { RootContext } from 'RootContext'
import { showToast } from 'api/ApiCommon'
import { useDispatch, useSelector } from 'react-redux'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'
import { updateGiftExchangeState } from 'store/gift-exchange/giftExchangeSlice'

const tabStatus = {
  content: 'content',
  giftNo: 'giftNo',
  exchangeList: 'exchangeList',
}

const SettingContext = createContext()
SettingContext.displayName = 'SettingContext'

const modalType = {
  exit: 'exit',
  available: 'available',
  goList: 'goList',
}

const modalConfig = {
  [modalType.exit]: {
    title: '即將離開',
    confirm: '離開',
    close: '返回',
    content: ['已編輯的資料將不會儲存，', '確定離開編輯？'],
    variant: color.danger,
  },
  [modalType.goList]: {
    title: '返回列表',
    confirm: '確認',
    closeBtnVisible: false,
    isHideCloseButton: true,
    modalOption: {
      backdrop: 'static',
      keyboard: false,
    },
    content: ['本活動符合上架條件，', '已排程自動上架。'],
    variant: color.primary,
  },
}

function NavSection() {
  const { history, openModal } = useContext(SettingContext)
  const { type } = history.location.state
  const now = {
    [settingPageType.new]: '新增贈品',
    [settingPageType.edit]: '編輯贈品',
    [settingPageType.view]: '檢視贈品',
  }
  const BreadcrumbConfig = {
    now: now[type],
    pathList: [
      { title: '好禮贈送', slash: true },
      {
        title: '贈品兌換',
        onClick: openModal,
      },
    ],
  }
  return <Breadcrumb {...BreadcrumbConfig} />
}

function SideSection() {
  const { history, submit, openModal, exitPage, pageType } =
    useContext(SettingContext)
  const { type } = history.location.state
  const showSaveTypes = [settingPageType.new, settingPageType.edit]
  const handleSubmit = async (e, { handleButtonLocalLoading }) => {
    handleButtonLocalLoading(true)
    await submit()
    handleButtonLocalLoading(false)
  }
  return (
    <StyledSideSection>
      {pageType === tabStatus.content &&
      showSaveTypes.some((i) => i === type) ? (
        <>
          <Button variant="outline-gray" onClick={openModal}>
            <IconCancel />
            取消
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleSubmit}
            loaderOption={{
              bgColor: 'transparent',
              colorType: '#fff',
            }}
          >
            <IconSave />
            儲存
          </Button>
        </>
      ) : (
        <Button variant="outline-gray" onClick={exitPage}>
          返回列表
        </Button>
      )}
    </StyledSideSection>
  )
}

const isEmptyValue = new RegExp('^s*$')
const errorMsg = ({ value, isRequired = true }) => {
  const isEmptyValue = new RegExp('^s+||null$')
  if (isRequired && isEmptyValue.test(value)) return validMsg.empty
}

const validate = ({ rule, value }) => new RegExp(rule).test(value)

const handleExchangeMethodValid = ({
  rule,
  exchangeMethodObj,
  dataExchangePathway,
}) => {
  let result = false
  const { onlyMoney, onlyPoint, pointMoney } = exchangeMethodObj

  if (pointMoney?.check && (!pointMoney?.point || !pointMoney?.money))
    return false

  if (onlyMoney?.check && !onlyMoney?.money) return false

  if (onlyPoint?.check && !onlyPoint?.point) return false
  const hasCheckedArr = Object.keys(exchangeMethodObj).reduce(
    (checkArr, method) => {
      const methodObj = exchangeMethodObj[method]
      if (
        dataExchangePathway === exchangePathway.store ||
        dataExchangePathway === exchangePathway.delivery
      ) {
        switch (method) {
          case 'pointMoney':
            validate({ rule, value: methodObj?.money }) &&
              validate({ rule, value: methodObj?.point }) &&
              checkArr.push(methodObj)
            break
          case 'onlyPoint':
            validate({ rule, value: methodObj?.point }) &&
              checkArr.push(methodObj)
            break
          case 'onlyMoney':
            validate({ rule, value: methodObj?.money }) &&
              checkArr.push(methodObj)
            break
        }
      } else {
        validate({ rule, value: methodObj?.point }) && checkArr.push(methodObj)
      }
      return checkArr
    },
    []
  )
  result = !!hasCheckedArr.length

  return result
}
const handleExchangeMethodErrorMsg = (data) => {
  const dataExchangePathway = data?.exchangePathway
  const dataExchangeMethod = data?.exchangeMethod
  // 現下門店、宅配出貨
  if (
    dataExchangePathway === exchangePathway.store ||
    dataExchangePathway === exchangePathway.delivery
  ) {
    return (
      dataExchangeMethod?.onlyPoint?.point ??
      dataExchangeMethod?.onlyMoney?.money ??
      dataExchangeMethod?.pointMoney?.point
    )
  }
  // 非現下門店、宅配出貨

  return dataExchangeMethod?.onlyPoint?.point
}
const handleTargetValid = ({ rule, target }) => {
  const { forNewMember, forInitialMember, memberLevels } = target
  const result = validate({ rule, value: forNewMember })
  if (!result) return false
  if (forNewMember) return true
  return forInitialMember || memberLevels.some((i) => i.selected)
}

const handleUploadCountValid = ({
  value,
  maxCount = 100000,
  recordCount = 0,
  codeCount = null,
}) => {
  const numberfyValue = Number(value)
  let result = null
  let msg = validMsg.empty
  if (isEmptyValue.test(value)) {
    result = false
  } else if (numberfyValue > maxCount) {
    result = false
    msg = `不可高於 ${maxCount / 10000} 萬組`
  } else if (numberfyValue < recordCount) {
    result = false
    msg = `不可低於已兌換數量 ${number(recordCount)}`
  } else if (numberfyValue < codeCount) {
    result = false
    msg = `不可低於序號數量 ${number(codeCount)}`
  } else result = true
  return {
    valid: result,
    msg,
  }
}

/**
 * 驗證給定的日期範圍是否有效
 * @param {Object} options - 日期範圍選項
 * @param {Object} options.startDate - 開始日期選項
 * @param {boolean} options.startDate.check - 開始日期選擇-true立即開始，false自訂日期，沒有選擇-空
 * @param {string} options.startDate.calendar - 開始日期的日曆選擇值
 * @param {Object} options.endDate - 結束日期選項
 * @param {boolean} options.endDate.check - 結束日期選擇-true立即開始，false自訂日期，沒有選擇-空
 * @param {string} options.endDate.calendar - 結束日期的日曆選擇值
 * @param {string} options.customErrorMsg - 自定義錯誤消息
 * @returns {Object} 驗證結果對象
 * @returns {boolean} valid - 驗證結果，為 true 表示驗證通過，為 false 表示驗證失敗
 * @returns {string} msg - 驗證結果的消息，如果驗證失敗，則提供相應的錯誤消息
 */
export const handleDateRange = ({ startDate, endDate, customErrorMsg }) => {
  const { calendar: startCalendar, check: startCheck } = startDate
  const { calendar: endDateCalendar, check: endCheck } = endDate
  const isEmptyEndDate = isEmptyValue.test(endCheck)

  if (isEmptyEndDate)
    return {
      valid: false,
      msg: validMsg.empty,
    }

  const isInValidDate =
    !startCheck && !endCheck && isAfter(startCalendar, endDateCalendar)

  if (isInValidDate)
    return {
      valid: false,
      msg: customErrorMsg || validMsg.exchangeStart,
    }

  return { valid: true, msg: '' }
}

const handleValid = ({ data, updateFn }) => {
  const rule = {
    name: '^.+$',
    color: '^.+$',
    size: '^.+$',
    target: '^(true|false)$',
    exchangePathway: '^(1|2|3|4|5){1}$',
    collectionMethod: `^(${collectionMethod.store}|${collectionMethod.online}|${collectionMethod.link}){1}$`,
    exchangeMethod: '^[1-9][0-9]{0,5}$',
    giftCode: '^.+$',
    autoUpload: '^(true|false)$',
    exchangeStart: '^(true|false)$',
    exchangeEnd: '^(true|false)$',
    collectionStart: '^.+$',
    collectionEnd: '^(true|false)$',
  }
  return new Promise((resolve) => {
    updateFn((validState) => {
      const result = JSON.parse(JSON.stringify(validState))
      const maxUploadCount =
        data.exchangePathway === exchangePathway.liff ? 100000 : 1000000

      for (const item in validState) {
        if (validState[item]?.required) {
          switch (item) {
            case 'name':
            case 'exchangePathway':
            case 'collectionMethod':
            case 'giftCode':
            case 'autoUpload':
              result[item] = {
                ...result[item],
                isValid: validate({ rule: rule[item], value: data[item] }),
                msg: errorMsg({ value: data[item] }),
              }
              break

            case 'collectionStart':
              // 領取起始需晚於兌換起始
              const { valid: collectionStartValid, msg: collectionStartMsg } =
                handleDateRange({
                  startDate: data.exchangeStart,
                  endDate: {
                    check: false,
                    calendar: data.collectionStart,
                  },
                })

              result[item] = {
                ...result[item],
                isValid: collectionStartValid,
                msg: collectionStartMsg,
              }
              break
            case 'target':
              result[item] = {
                ...result[item],
                isValid: handleTargetValid({
                  rule: rule[item],
                  target: data.target,
                }),
              }
              break

            case 'uploadCount': {
              const { valid, msg } = handleUploadCountValid({
                value: data[item],
                maxCount: maxUploadCount,
                codeCount: data.giftCodeCount,
                recordCount: data.giftRecordCount,
              })
              result[item] = {
                ...result[item],
                isValid: valid,
                msg,
              }
              break
            }
            case 'exchangeMethod':
              result[item] = {
                ...result[item],
                isValid: handleExchangeMethodValid({
                  rule: rule[item],
                  exchangeMethodObj: data.exchangeMethod,
                  dataExchangePathway: data.exchangePathway,
                }),
                msg: errorMsg({
                  value: handleExchangeMethodErrorMsg(data),
                }),
              }
              break

            case 'exchangeStart':
              result[item] = {
                ...result[item],
                isValid: validate({
                  rule: rule[item],
                  value: data[item].check,
                }),
                msg: errorMsg({ value: data[item].check }),
              }
              break

            case 'exchangeEnd':
              const { valid: exchangeEndValid, msg: exchangeEndMsg } =
                handleDateRange({
                  startDate: data.exchangeStart,
                  endDate: data[item],
                })
              result[item] = {
                ...result[item],
                isValid: exchangeEndValid,
                msg: exchangeEndMsg,
              }
              break

            case 'collectionEnd':
              const { valid: collectionEndValid, msg: collectionEndMsg } =
                handleDateRange({
                  startDate: { check: false, calendar: data.collectionStart },
                  endDate: data[item],
                  customErrorMsg: validMsg.collectionStart,
                })
              result[item] = {
                ...result[item],
                isValid: collectionEndValid,
                msg: collectionEndMsg,
              }
              break

            default:
              break
          }
        } else {
          switch (item) {
            case 'giftInfo':
              result.giftInfo = {
                isValid: null,
                msg: '',
              }
              break
            default:
              break
          }
        }
      }
      const requiredValiddResult = Object.keys(result)
        .filter((item) => result[item].required)
        .every((item) => result[item].isValid)

      const nonRequiredValiddResult = Object.keys(result)
        .filter((item) => !result[item].required)
        .every(
          (item) => result[item].isValid || result[item].isValid === null,
          Object.keys(result).filter((item) => !result[item].required)
        )
      resolve(requiredValiddResult && nonRequiredValiddResult)
      return { ...result }
    })
  })
}

const composeApiPayload = (data) => {
  const bodyFormData = new FormData()
  bodyFormData.append('Name', data.name)
  bodyFormData.append('Color', data.color)
  bodyFormData.append('Size', data.size)
  bodyFormData.append('ForNewMember', data.target.forNewMember)
  bodyFormData.append('ForInitialMember', data.target.forInitialMember)
  bodyFormData.append('ExchangeWay', data.exchangePathway)
  bodyFormData.append('GiftCustomId', data.giftCode)
  bodyFormData.append('Amount', data.uploadCount)
  bodyFormData.append('EveryMemberOnce', data.exchangeLimit)
  bodyFormData.append(
    'ExchangeStartDate',
    data.exchangeStart.check
      ? formatISO(startOfToday())
      : formatISO(data.exchangeStart.calendar)
  )
  bodyFormData.append(
    'ExchangeEndDate',
    data.exchangeEnd.check ? '' : formatISO(data.exchangeEnd.calendar)
  )
  bodyFormData.append('AutoAvailable', data.autoUpload)
  bodyFormData.append('Description', data.giftInfo)
  data.images.forEach((image) =>
    bodyFormData.append(`newGiftSettingImgs`, image)
  )
  data.removeImages.forEach((image) => {
    bodyFormData.append(`delGiftSettingImgIds`, image.giftSettingImgId)
  })
  data.target.memberLevels
    .filter((i) => i.selected)
    .forEach((i, key) => {
      bodyFormData.append(`MemberLevels[${key}].id`, i.id)
      bodyFormData.append(`MemberLevels[${key}].code`, i.code)
      bodyFormData.append(`MemberLevels[${key}].name`, i.name)
    })
  if (
    data.exchangePathway === exchangePathway.store ||
    data.exchangePathway === exchangePathway.delivery
  ) {
    data?.exchangeMethod?.onlyPoint?.point &&
      bodyFormData.append(
        'ExchangeMethod.OnlyPoint.Value',
        data?.exchangeMethod?.onlyPoint?.point
      )
    data?.exchangeMethod?.onlyPoint?.barcode &&
      bodyFormData.append(
        'ExchangeMethod.OnlyPoint.Barcode',
        data?.exchangeMethod?.onlyPoint?.barcode
      )
    data?.exchangeMethod?.pointMoney?.point &&
      bodyFormData.append(
        'ExchangeMethod.PointMoney.Point.Value',
        data?.exchangeMethod?.pointMoney?.point
      )
    data?.exchangeMethod?.pointMoney?.money &&
      bodyFormData.append(
        'ExchangeMethod.PointMoney.Money.Value',
        data?.exchangeMethod?.pointMoney?.money
      )
    data?.exchangeMethod?.pointMoney?.barcode &&
      bodyFormData.append(
        'ExchangeMethod.PointMoney.Barcode',
        data?.exchangeMethod?.pointMoney?.barcode
      )
    data?.exchangeMethod?.onlyMoney?.money &&
      bodyFormData.append(
        'ExchangeMethod.OnlyMoney.Value',
        data?.exchangeMethod?.onlyMoney?.money
      )
    data?.exchangeMethod?.onlyMoney?.barcode &&
      bodyFormData.append(
        'ExchangeMethod.OnlyMoney.Barcode',
        data?.exchangeMethod?.onlyMoney?.barcode
      )
  } else {
    bodyFormData.append(
      'ExchangeMethod.OnlyPoint.Value',
      data?.exchangeMethod?.onlyPoint?.point
    )
  }
  if (
    data.exchangePathway === exchangePathway.liff ||
    data.exchangePathway === exchangePathway.sms
  ) {
    bodyFormData.append('ReceiveWay', data?.collectionMethod)
    bodyFormData.append('GiftCodeStartDate', formatISO(data?.collectionStart))
    bodyFormData.append(
      'GiftCodeEndDate',
      data?.collectionEnd?.check ? '' : formatISO(data?.collectionEnd?.calendar)
    )
  }

  return bodyFormData
}

const handleApiError = ({ error, updateFn }) => {
  const { errors } = error
  const config = {
    Amount: [
      {
        type: '上架數量低於已兌換數量',
        attr: 'uploadCount',
        msg: validMsg.lessThanExchanged,
      },
      {
        type: '上架數量低於序號總數',
        attr: 'uploadCount',
        msg: validMsg.lessThanGenerated,
      },
    ],
    Description: [
      {
        type: '贈品介紹超過 2,000 字',
        attr: 'giftInfo',
        msg: validMsg.giftInfo,
      },
    ],
    GiftCodeStartDate: [
      {
        type: '領取起始日不可小於兌換起始日',
        attr: 'collectionStart',
        msg: validMsg.exchangeStart,
      },
      {
        type: '領取結束日不可小於兌換結束日',
        attr: 'collectionEnd',
        msg: validMsg.exchangeEnd,
      },
      {
        type: '兌換結束日為永久，領取結束日須相同',
        attr: 'collectionEnd',
        msg: validMsg.collectionEndPermanent,
      },
    ],
  }

  for (const type in errors) {
    for (const errorMsg of errors[type]) {
      if (
        config[type] === undefined ||
        config[type].findIndex((i) => i.type === errorMsg) === -1
      )
        break

      const { msg, attr } = config[type].find((i) => i.type === errorMsg)
      updateFn((prevState) => ({
        ...prevState,
        [attr]: {
          ...prevState[attr],
          isValid: false,
          msg,
        },
      }))
    }
  }
}

/* tab 的按鈕及對應的顯示元件 */
const tabConfig = () => [
  {
    eventKey: tabStatus.content,
    title: '贈品內容',
    component: <ExchangePage.ExchangeContent />,
  },
  {
    eventKey: tabStatus.giftNo,
    title: '贈品序號',
    component: <ExchangePage.SerialNumberTab />,
  },
  {
    eventKey: tabStatus.exchangeList,
    title: '兌換名單',
    component: <ExchangePage.ExchangeSendList />,
  },
]

function Setting() {
  const dispatch = useDispatch()
  const { isDataLoading } = useSelector(getGlobalState)
  const rootData = useContext(RootContext)
  const history = useHistory()
  // TODO: 活動狀態
  const state = history.location?.state
  const apiPayload = state?.apiPayload
  const type = state?.type
  const [modal, setModal] = useState(false)
  const [mask, setMask] = useState(false)
  const [transparentMask, setTransparentMask] = useState(false)
  const [saveModal, setSaveModal] = useState({ show: false, nextPage: null })
  const [uniteModal, setUniteModal] = useState({
    type: modalType.exit,
    show: false,
  })
  const [data, setData] = useState(initState)
  const [page, setPage] = useState(tabStatus.content)
  const [infoState, infoDispatch] = useReducer(stateReducer, initState)
  const [infoValidStatus, setValidStatus] = useState(initValid)
  const [memberLevelList, setMemberLevelList] = useState([])
  const [stateFromEdit, setStateFromEdit] = useState(null)
  const [settingId, setSettingId] = useState(null)

  const giftSettingId = apiPayload?.giftSettingId || settingId
  const isNewPage = type === settingPageType.new
  const isViewPage = type === settingPageType.view
  const isEditPage = type === settingPageType.edit
  const toastMessage = {
    new: '新增贈品內容成功',
    edit: '編輯贈品內容成功',
  }

  const isTabShow = (eventKey) => {
    const showList = [tabStatus.content]

    if (infoState.exchangePathway === exchangePathway.liff) {
      showList.push(tabStatus.giftNo)
    } else {
      showList.push(tabStatus.exchangeList)
    }

    return showList.some((item) => item === eventKey)
  }
  const handleUniteModalSwitch = (show, options = {}) => {
    setUniteModal((prev) => ({ ...prev, show, ...options }))
  }
  const openModal = () => setModal(true)
  const exitPage = useCallback(
    ({ giftSettingStatus = null } = {}) => {
      history.push(PAGE_PATHS.gift.exchange, {
        apiPayload: {
          ...apiPayload,
          state: giftSettingStatus || stateFromEdit || apiPayload.state,
        },
      })
    },
    [history, apiPayload, stateFromEdit]
  )

  const execute = () => {
    const payload = composeApiPayload(infoState)
    if (isNewPage && !giftSettingId)
      return addEvent(apiPayload.brandId, payload)
    return updateEvent(apiPayload.brandId, giftSettingId, payload)
  }

  const submit = async () => {
    const isValid = await handleValid({
      data: infoState,
      updateFn: setValidStatus,
    })
    if (!isValid) return
    let giftSettingStatus = null
    try {
      const res = await execute()
      giftSettingStatus = res?.data?.data?.giftSettingStatus
      if (isNewPage) {
        showToast(res, toastMessage.new)
      } else if (isEditPage) {
        showToast(res, toastMessage.edit)
      }
      exitPage({ giftSettingStatus })
    } catch (error) {
      if (error.code === 400)
        handleApiError({ error, updateFn: setValidStatus })
    }
  }

  const openSaveModal = (nextPage) => setSaveModal({ type: true, nextPage })
  const confirmSave = async ({ handleModalLoading }) => {
    const isValid = await handleValid({
      data: infoState,
      updateFn: setValidStatus,
    })
    if (isValid) {
      dispatch(updateDataLoading(true))
      handleModalLoading(true)
      try {
        const executeRsp = await execute()
        if (isNewPage) {
          // 表示在新增模式下，有切換頁籤過，變成發編輯的api
          if (settingId) {
            showToast(executeRsp, toastMessage.edit)
          } else {
            showToast(executeRsp, toastMessage.new)
          }
        } else if (isEditPage) {
          showToast(executeRsp, toastMessage.edit)
        }
        setStateFromEdit(executeRsp?.data?.data?.giftSettingStatus ?? null)

        const newGiftSettingId =
          executeRsp?.data?.data?.giftSettingId ?? giftSettingId
        if (newGiftSettingId) {
          setSettingId(newGiftSettingId)
        }
        const getEventRsp = await getEvent(apiPayload.brandId, newGiftSettingId)
        setData({
          ...getEventRsp.data.data,
          memberLevelItemList: memberLevelList,
        })
        setPage(saveModal.nextPage)
      } catch (error) {
        if (error.code === 400)
          handleApiError({ error, updateFn: setValidStatus })
      } finally {
        dispatch(updateDataLoading(false))
        handleModalLoading(false)
        setSaveModal({ show: false, nextPage: null })
      }
    } else {
      setSaveModal({ show: false, nextPage: null })
    }
  }

  const handleSelect = async (value) => {
    if (isViewPage) return setPage(value)
    if (
      stateFromEdit &&
      stateFromEdit !== configTabStatus.unavailable &&
      giftSettingId
    ) {
      if (value === tabStatus.content && giftSettingId) {
        return setUniteModal({ type: modalType.goList, show: true })
      }
    } else if (page === tabStatus.content && value !== page) {
      return openSaveModal(value)
    }
    setPage(value)
  }

  const handleModalConfirm = (type) => async () => {
    switch (type) {
      case modalType.goList:
        history.push(PAGE_PATHS.gift.exchange)
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (page === 'content') {
      dispatch(updateDataLoading(false))
    }
  }, [page])

  useEffect(() => {
    dispatch(updateDataLoading(true))

    const fetchMemberList = async () => {
      try {
        const res = await getMemberList(apiPayload.brandId)
        const memberLevelItemList = res?.data?.data?.memberLevelItemList ?? []

        if (isNewPage) {
          setData((prev) => ({ ...prev, memberLevelItemList }))
        } else {
          try {
            if (!apiPayload.giftSettingId) return
            const res2 = await getEvent(
              apiPayload.brandId,
              apiPayload.giftSettingId
            )
            setData({ ...res2.data.data, memberLevelItemList })
          } catch (error) {
            // 處理錯誤情況
          }
        }

        setMemberLevelList(memberLevelItemList)
      } catch (error) {
        // 處理錯誤情況
      } finally {
        dispatch(updateDataLoading(false))
      }
    }

    fetchMemberList()
  }, [isNewPage, apiPayload, dispatch])

  useEffect(() => {
    setValidStatus((prevState) => ({
      ...prevState,
      collectionMethod: {
        ...prevState.collectionMethod,
        required: infoState.exchangePathway === exchangePathway.liff,
      },
      collectionStart: {
        ...prevState.collectionStart,
        required: infoState.exchangePathway === exchangePathway.liff,
      },
      collectionEnd: {
        ...prevState.collectionEnd,
        required: infoState.exchangePathway === exchangePathway.liff,
      },
    }))
  }, [infoState.exchangePathway])

  useEffect(() => {
    if (rootData.brandId && rootData.brandId !== apiPayload.brandId) exitPage()
  }, [rootData.brandId, apiPayload.brandId, exitPage])

  useEffect(() => {
    if (type && apiPayload?.state) {
      dispatch(
        updateGiftExchangeState({
          editType: type,
          state: apiPayload.state,
        })
      )
    }
  }, [apiPayload?.state, type, dispatch])

  if (state === undefined) return <Redirect to={PAGE_PATHS.gift.exchange} />
  return (
    <SettingContext.Provider
      value={{
        history,
        defaultData: data,
        settingId,
        submit,
        openModal,
        handleUniteModalSwitch,
        exitPage,
        viewType: type,
        pageType: page,
        infoState: [infoState, infoDispatch],
        infoValid: [infoValidStatus, setValidStatus],
        stateFromEdit: [stateFromEdit, setStateFromEdit],
        setMask,
        setTransparentMask,
      }}
    >
      <Basic navSection={NavSection} sideSection={SideSection}>
        <StyledContentSection>
          <Tabs activeKey={page} onSelect={handleSelect}>
            {tabConfig()
              .filter((i) => isTabShow(i.eventKey))
              .map((tab) => (
                <Tab
                  eventKey={tab.eventKey}
                  title={tab.title}
                  key={tab.eventKey}
                >
                  <Loader isHide={!isDataLoading} />
                  {tab.component}
                </Tab>
              ))}
          </Tabs>
        </StyledContentSection>
      </Basic>
      <Modal
        show={uniteModal.show}
        onClose={() => handleUniteModalSwitch(false)}
        onConfirm={handleModalConfirm(uniteModal.type)}
        variant={modalConfig[uniteModal.type].variant}
        titleText={modalConfig[uniteModal.type].title}
        confirmBtnText={modalConfig[uniteModal.type].confirm}
        closeBtnText={modalConfig[uniteModal.type].close}
        {...modalConfig[uniteModal.type]}
      >
        {modalConfig[uniteModal.type].content.map((i, key) => (
          <p key={key}>{i}</p>
        ))}
      </Modal>
      {/* 由於以下這邊的modal沒有像專屬好禮那樣統一做多個處理，因此這邊未來需要改成專屬好禮那種方式 */}
      <Modal
        show={modal}
        onClose={() => setModal(false)}
        onConfirm={exitPage}
        variant={color.danger}
        titleText="即將離開"
        confirmBtnText="離開"
        closeBtnText="返回"
      >
        <p>已編輯的資料將不會儲存，</p>
        <p>確定離開編輯？</p>
      </Modal>
      <Modal
        show={saveModal.show}
        onClose={() => {
          dispatch(updateDataLoading(false))
          setSaveModal({ show: false, nextPage: null })
        }}
        onConfirm={confirmSave}
        variant={color.primary}
        titleText="儲存進度"
        confirmBtnText="儲存"
        closeBtnText="返回"
      >
        <p>是否儲存目前編輯進度？</p>
      </Modal>
      <Mask show={mask} />
      <TransparentMask>
        <Mask show={transparentMask} />
      </TransparentMask>
    </SettingContext.Provider>
  )
}

export default Setting
export { SettingContext, tabStatus, modalType }
