import { configureStore, combineReducers } from '@reduxjs/toolkit'
import accountManagementReducer from 'store/account-management/accountManagementSlice'
import { accountSettingReducer } from 'store/account-setting/accountSettingSlice'
import authReducer from 'store/auth/authSlice'
import createBatchUploadMiddleware from 'store/batchUploadMiddleWare'
import dataAnalysisReducer from 'store/data-analysis/dataAnalysisSlice'
import globalReducer from 'store/global/globalSlice'
import globalLoadingMiddleware from 'store/globalLoadingMiddleware'
import permissionReducer from 'store/permission/permissionSlice'
import accountVerifyReducer from './account-verify/accountVerifySlice'
import memberDashboardSlice from './dashboard/membersDashboardSlice'
import dealInfoReducer from './deal-info/dealInfoSlice'
import giftExchangeSerialReducer from './gift-exchange/giftExchangeSerialSlice'
import giftExchangeReducer from './gift-exchange/giftExchangeSlice'
import giftListReducer from './gift-list/giftListSlice'
import memberOutlineReducer from './member-outline/memberOutlineSlice'
import { passwordSettingReducer } from './password-setting/passwordSettingSlice'
import pointHistoryReducer from './point-history/pointHistorySlice'
import pointRecordReducer from './point-record/pointRecordSlice'
import pointSettingReducer from './point-setting/pointSettingSlice'
import qrcodeActivityFormReducer from './qrcode-activity-form/qrcodeActivityFormSlice'
import qrcodeSerialNumberDetailReducer from './qrcode-serail-number-detail/qrcodeSerialNumberDetailSlice'
import { serialBatchUploadReducer } from './serial-batch-upload/serialBatchUploadSlice'
import vipGiftExchangeListReducer from './vip-gift-exchange/vipGiftExchangeListSlice'
import vipGiftExchangeSettingReducer from './vip-gift-exchange/vipGiftExchangeSettingSlice'
import vipGiftExchangeReducer from './vip-gift-exchange/vipGiftExchangeSlice'

const store = configureStore({
  reducer: {
    permissions: permissionReducer,
    global: globalReducer,
    auth: authReducer,
    memberDashboard: memberDashboardSlice,
    accountManagement: accountManagementReducer,
    accountSetting: accountSettingReducer,
    accountVerify: accountVerifyReducer,
    passwordSetting: passwordSettingReducer,
    serialBatchUpload: serialBatchUploadReducer,
    pointRecord: pointRecordReducer,
    dealInfo: dealInfoReducer,
    memberOutline: memberOutlineReducer,
    giftExchange: giftExchangeReducer,
    giftExchangeSerial: giftExchangeSerialReducer,
    vipGiftExchange: vipGiftExchangeReducer,
    vipGiftExchangeList: vipGiftExchangeListReducer,
    vipGiftExchangeSetting: vipGiftExchangeSettingReducer,
    giftList: giftListReducer,
    qrcodeSerialNumberDetail: qrcodeSerialNumberDetailReducer,
    pointHistory: pointHistoryReducer,
    qrcodeActivityFormRecord: qrcodeActivityFormReducer,
    dataAnalysis: dataAnalysisReducer,
    pointSetting: pointSettingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      globalLoadingMiddleware,
      createBatchUploadMiddleware(),
    ]),
})

// rootReducer、setupStore 用於測試
const rootReducer = combineReducers({
  permissions: permissionReducer,
  global: globalReducer,
})

function setupStore(preloadedState) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  })
}

export default store
export { setupStore }
