import styled from 'styled-components'

const StyledSideSetion = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

export { StyledSideSetion }
