import { useEffect, useState, useCallback } from 'react'
import { Tab } from 'react-bootstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { handleError } from 'api/ApiCommon'
import {
  changeVipGiftStatus,
  deleteVipGiftInfo,
  copyVipGiftInfo,
} from 'api/ApiMain'
import { color } from 'assets/styles/Variable/Color'
import {
  tableFnType,
  rowStyle,
  columns,
  initModalData,
} from 'components/pages/Gift/ExclusiveMainConfig'
import { TabsWrapper } from 'components/pages/Gift/ExclusiveMainStyle'
import { Table, NoDataIndication, Modal } from 'components/units'
import { TableToolBar } from 'components/units/Table'
import { PAGE_PATHS, COMPONENT_PERMISSIONS } from 'constant'
import { paginationOptions } from 'constant/common'
import { settingPageType, tabConfig } from 'pages/Gift/config'
import { authAccountSelector } from 'store/auth/authSelector'
import { updatePageLoading } from 'store/global/globalSlice'
import { isPermissionAllowed } from 'store/permission/permissionSlice'
import {
  getVipExchangeListThunk,
  resetVipGiftExchangeList,
  vipGiftListSelector,
} from 'store/vip-gift-exchange/vipGiftExchangeListSlice'

const initApiPayload = {
  p: 1,
  ps: 25,
  sf: 'updatedTime',
  so: 'desc',
}
function ExclusiveList() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { brandId } = useSelector(authAccountSelector)
  const { rows, totalSize } = useSelector(vipGiftListSelector)
  const [modal, setModal] = useState(initModalData)
  const [isLoading, setIsLoading] = useState(false)
  const isPermission = useSelector(
    isPermissionAllowed([
      ...COMPONENT_PERMISSIONS.vipGift.UPDATE,
      ...COMPONENT_PERMISSIONS.vipGift.DELETE,
    ])
  )
  // 由於列表頁面有多個tab，所以需要判斷是否有從歷史紀錄帶入的狀態
  // 有歷史記錄的情境：從編輯/檢視頁面返回列表頁面
  const giftStatusFromHistory = history.location.state?.apiPayload?.state
  const [apiPayload, setApiPayload] = useState({
    ...initApiPayload,
    state: giftStatusFromHistory || 'available',
  })
  const isHidden = apiPayload.state === 'unavailable' && !isPermission

  const openModal = (payload) => setModal({ status: true, payload })
  const closeModal = () => setModal(initModalData)

  const getList = useCallback(async () => {
    try {
      dispatch(resetVipGiftExchangeList())
      setIsLoading(true)
      await dispatch(getVipExchangeListThunk({ brandId, apiPayload }))
    } catch (error) {
      handleError(error)
    } finally {
      dispatch(updatePageLoading(false))
      setIsLoading(false)
    }
  }, [apiPayload, brandId, dispatch])

  const handleModalConfirm = async ({ handleModalLoading }) => {
    const { brandId: confirmBrandId, giftSettingId } = modal.payload
    handleModalLoading(true)
    try {
      await changeVipGiftStatus(confirmBrandId, giftSettingId, { act: 'seal' })
      await getList()
    } catch (error) {
      handleError(error)
    }
    setModal(initModalData)
    handleModalLoading(false)
  }
  const isCheckboxChecked = apiPayload.sf === initApiPayload.sf
  const handleCheckboxChange = (e) => {
    e.preventDefault()
    setApiPayload({
      ...apiPayload,
      sf: initApiPayload.sf,
      so: initApiPayload.so,
    })
  }

  const handleTableChange = (type, value) => {
    const { sortField, sortOrder, sizePerPage, page } = value
    setApiPayload({
      ...apiPayload,
      p: page || apiPayload.p,
      ps: sizePerPage || apiPayload.ps,
      sf: sortField || apiPayload.sf,
      so: sortOrder || apiPayload.so,
    })
  }

  const handleTabChange = (value) => {
    setApiPayload({
      ...apiPayload,
      state: value,
    })
  }
  const handleEvent = async ({ type, payload }) => {
    const { giftSettingId } = payload

    switch (type) {
      case tableFnType.edit:
      case tableFnType.view:
        history.push(PAGE_PATHS.gift.exclusiveSetting, {
          type:
            type === tableFnType.edit
              ? settingPageType.edit
              : settingPageType.view,
          apiPayload: { ...apiPayload, giftSettingId, brandId },
        })
        break

      case tableFnType.sealing:
        openModal({ brandId, giftSettingId })
        break

      case tableFnType.upload: {
        try {
          await changeVipGiftStatus(brandId, giftSettingId, { act: 'avail' })
          await getList()
        } catch (error) {
          handleError(error)
        }
        break
      }

      case tableFnType.takeDown: {
        try {
          await changeVipGiftStatus(brandId, giftSettingId, { act: 'unavail' })
          await getList()
        } catch (error) {
          handleError(error)
        }
        break
      }

      case tableFnType.remove:
        try {
          await deleteVipGiftInfo(brandId, giftSettingId)
          await getList()
        } catch (error) {
          handleError(error)
        }
        break

      case tableFnType.copy:
        try {
          await copyVipGiftInfo(brandId, giftSettingId)
          await getList()
        } catch (error) {
          handleError(error)
        }
        break

      default:
        break
    }
  }

  useEffect(() => {
    if (brandId) getList()
  }, [brandId, getList])

  return (
    <PaginationProvider
      pagination={paginationFactory({
        ...paginationOptions,
        totalSize,
        page: apiPayload.p,
        sizePerPage: apiPayload.ps,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <>
          <TabsWrapper
            defaultActiveKey={apiPayload.state}
            onSelect={handleTabChange}
            activeKey={apiPayload.state}
          >
            {tabConfig.map(({ eventKey, title }) => (
              <Tab key={eventKey} eventKey={eventKey} title={title}>
                <TableToolBar
                  totalSize={totalSize}
                  paginationProps={paginationProps}
                  sizePerPage={apiPayload.ps}
                  sortByCkeckBoxSetting={{
                    label: '依更新時間排序',
                    name: 'vipGiftSortByCheckbox',
                    id: 'vipGiftSortByCheckbox',
                    isShow: true,
                    isChecked: isCheckboxChecked,
                    onChange: handleCheckboxChange,
                  }}
                />
                <Table
                  headerClasses="table-header"
                  bodyClasses="paragraph"
                  keyField="no"
                  data={rows}
                  columns={columns(handleEvent, isHidden, apiPayload.state)}
                  rowStyle={rowStyle(apiPayload.state)}
                  onTableChange={handleTableChange}
                  striped
                  remote
                  noDataIndication={
                    <NoDataIndication
                      isLoading={isLoading}
                      message={isLoading ? '載入中...' : '尚無資料'}
                    />
                  }
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...paginationTableProps}
                />
                <PaginationListStandalone
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...paginationProps}
                />
              </Tab>
            ))}
          </TabsWrapper>
          <Modal
            show={modal.status}
            onClose={closeModal}
            onConfirm={handleModalConfirm}
            variant={color.danger}
            titleText="即將封存"
            confirmBtnText="封存"
            closeBtnText="返回"
          >
            <p>是否繼續執行封存？</p>
            <p>封存後，該項目將無法編輯；</p>
            <p>未發送之序號，可重複新增。</p>
          </Modal>
        </>
      )}
    </PaginationProvider>
  )
}

export default ExclusiveList
