import { number } from 'helpers/format'
import {
  headerFormatterInfo,
  formatterInfo,
  headerFormatterStatus,
  formatterStatus,
} from 'components/pages/Gift/ExchangeSerialNumberSubComponents'
import { colorObj } from 'assets/styles/Variable/Color'

const buildType = {
  user: 10,
  system: 20,
}

const codeGetType = {
  differentCode: 10,
  sameCode: 20,
}

const searchType = {
  code: 1,
  phone: 2,
}

const statusType = {
  notExchanged: 0,
  exchanged: 1,
  received: 2,
  expired: 90,
}

const formConfig = {
  build: {
    label: '序號建立：',
    options: [
      { id: buildType.user, label: '自行新增', name: 'user' },
      { id: buildType.system, label: '系統生成', name: 'system' },
    ],
  },
  codeGetType: {
    label: '序號領取：',
    options: [
      {
        id: codeGetType.differentCode,
        label: '不同序號，同一序號限領取一次',
        name: 'differentCode',
      },
      { id: codeGetType.sameCode, label: '相同序號', name: 'sameCode' },
    ],
  },
  prefix: { label: '序號前綴：', placeholder: '4 碼半形大寫英數' },
  length: {
    label: '序號總長：',
    placeholder: '請選擇',
    options: [
      { value: 10, text: '10碼' },
      { value: 11, text: '11碼' },
      { value: 12, text: '12碼' },
      { value: 13, text: '13碼' },
      { value: 14, text: '14碼' },
      { value: 15, text: '15碼' },
      { value: 16, text: '16碼' },
      { value: 17, text: '17碼' },
      { value: 18, text: '18碼' },
      { value: 19, text: '19碼' },
      { value: 20, text: '20碼' },
      { value: 21, text: '21碼' },
      { value: 22, text: '22碼' },
      { value: 23, text: '23碼' },
      { value: 24, text: '24碼' },
    ],
  },
  total: {
    label: '生成組數：',
    placeholder: '單次不可高於 1 萬組',
    tip: (value) => `贈品上架數量： ${value} 組`,
    button: '生成序號',
  },
}

const searchConfig = {
  label: '查詢清單：',
  options: [
    { value: searchType.code, text: '序號' },
    { value: searchType.phone, text: '手機' },
  ],
  search: '查詢',
  clear: '清除條件',
}

const validMsg = {
  prefix: '請輸入 4 碼半形大寫英數',
  generateLimit: '單次不可高於 1 萬組',
  uploadLimit: '不可高於上架數量',
  prefixApi: (value) => `總數不可高於 10 萬組，剩餘 ${number(value)} 組 `,
  totalApi: (value) => `贈品上架數量： ${value} 組`,
  empty: '尚未填寫',
}

const paginationOptions = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

const captionLabel = {
  total: '資料總計：',
  sizeParPage: '每頁',
  delete: '批次刪除',
  export: '匯出未兌換序號',
  dropdown: '新增/匯出',
}

const eventAction = {
  addOnlyOne: 'addOnlyOne',
  addMany: 'addMany',
  export: 'export',
}

const dropdownOptions = ({
  fn,
  uploadIsHide,
  uploadDisabled,
  exportDisabled,
}) => [
  {
    text: '批次上傳序號',
    divider: true,
    dropdownItemOption: {
      eventKey: eventAction.addMany,
      onSelect: () => fn(eventAction.addMany),
      disabled: uploadDisabled,
    },
    hide: uploadIsHide,
  },
  {
    text: '匯出未兌換序號',
    dropdownItemOption: {
      eventKey: eventAction.export,
      onSelect: () => fn(eventAction.export),
      disabled: exportDisabled,
    },
    hide: false,
  },
]

const defaultSort = {
  sf: 'no',
  so: 'desc',
}

const columns = (columnsObj, handleUniteModalSwitch) => [
  { dataField: 'no', text: 'No.', sort: true },
  {
    dataField: 'giftCode',
    text: '',
    attrs: () => ({
      style: { maxWidth: '400px', overflowWrap: 'break-word' },
    }),
    headerFormatter: headerFormatterInfo,
    formatter: formatterInfo(columnsObj.giftCode),
    formatExtraData: {
      handleUniteModalSwitch,
    },
  },
  {
    dataField: 'memberName',
    text: '會員',
    formatter: (cell, row, rowIndex, { columnsObj }) => {
      const { listTotal, isSameCode } = columnsObj
      const { conditionalReceivedCount, exchangedCount } = listTotal || {}
      const { giftCode } = row

      // 使用「相同序號」並已經輸入序號時，顯示已領取及已兌換的數量
      if (isSameCode && giftCode) {
        return `已領 ${conditionalReceivedCount}，已換 ${exchangedCount}`
      }

      if (!cell) return null
      return cell
    },
    formatExtraData: {
      columnsObj,
    },
    events: {
      onClick: (e, column, columnIndex, row) => {
        const { memberId, giftExchangeCodeId } = row
        if (!memberId) return
        const payload = {
          memberId,
          giftExchangeCodeId,
        }
        columnsObj.member.onClick(payload)
      },
    },
    style: (cell) => {
      if (!cell) return {}
      return {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: colorObj.primary,
      }
    },
  },
  { dataField: 'memberCellPhone', text: '手機' },
  { dataField: 'exchangedDateTime', text: '兌換時間', sort: true },
  { dataField: 'exchangedShopName', text: '領取門店', sort: true },
  {
    dataField: 'status',
    text: '',
    headerFormatter: headerFormatterStatus,
    formatter: formatterStatus,
    sort: true,
    headerClasses: 'setting-no-status',
  },
]

const selectRow = ({
  onSelect,
  onSelectAll,
  selected,
  nonSelectable,
  hideSelectColumn,
}) => ({
  mode: 'checkbox',
  nonSelectable: [null, ...nonSelectable],
  selected,
  onSelect,
  onSelectAll,
  hideSelectColumn,
})

const initSearch = {
  searchType: searchType.code,
  keyword: '',
}

const initPayload = {
  ...defaultSort,
  searchType: '',
  keyword: '',
  p: 1,
  ps: 25,
}

const initValid = {
  prefix: { valid: null, msg: '' },
  length: { valid: null, msg: '' },
  total: { valid: null, msg: '' },
  codeGetType: { valid: null, msg: '' },
}

export {
  buildType,
  searchType,
  codeGetType,
  statusType,
  formConfig,
  searchConfig,
  validMsg,
  paginationOptions,
  captionLabel,
  eventAction,
  dropdownOptions,
  defaultSort,
  columns,
  selectRow,
  initSearch,
  initPayload,
  initValid,
}
