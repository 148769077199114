import styled, { css } from 'styled-components'
import Spinner from 'react-bootstrap/Spinner'
import { colorObj, method } from 'assets/styles/Variable/Color'

const { colorHandle } = method

// Loader 共用樣式
const loaderCommonStyles = (props) => {
  const { colorType, colorAlpha, bgColor = 'white', bgColorAlpha = 0.8 } = props

  return css`
    &.style-loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: ${colorHandle(bgColor, colorObj, bgColorAlpha)};

      [class*='spinner-'] {
        color: ${colorType
          ? colorHandle(colorType, colorObj, colorAlpha)
          : colorObj.dark};
      }

      .load-content {
        color: ${colorType
          ? colorHandle(colorType, colorObj, colorAlpha)
          : colorObj.dark};
      }
    }
    &.appLoading {
      z-index: 22;
    }
  `
}

// Tooltip 基礎樣式
const loaderBaseStyles = (props) => {
  return css`
    ${loaderCommonStyles(props)};
  `
}

const StyleLoader = styled((props) => {
  const {
    className,
    colorType, // eslint-disable-line no-unused-vars
    colorAlpha, // eslint-disable-line no-unused-vars
    bgColor, // eslint-disable-line no-unused-vars
    bgColorAlpha, // eslint-disable-line no-unused-vars
    isHide,
    loadContent,
    ...prop
  } = props
  return (
    <>
      {!isHide && (
        <div className={`style-loader-container ${className}`}>
          <Spinner animation="border" {...prop} />
          <span
            className={`load-content ml-2 ${loadContent?.className} ${
              !loadContent && 'd-none'
            }`}
          >
            {loadContent?.html}
          </span>
        </div>
      )}
    </>
  )
})`
  ${(props) => loaderBaseStyles(props)};
`

export { StyleLoader }
