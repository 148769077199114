import { useEffect, useContext, useState, useCallback } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { color } from 'assets/styles/Variable/Color'
import { collectionMethod } from 'components/pages/Gift/ExchangeContentConfig'
// eslint-disable-next-line import/no-cycle
import {
  buildType,
  statusType,
  initPayload,
  initValid,
} from 'components/pages/Gift/ExchangeSerialNumberConfig'
import {
  handleDefaultSubmit,
  handleGenerate,
  handleUploadCsv,
  handleValid,
  handleIsLackOfRules,
} from 'components/pages/Gift/ExchangeSerialNumberHelper'
import SettingNoWrapper from 'components/pages/Gift/ExchangeSerialNumberStyle'
import SerialNumberSetting from 'components/pages/Gift/gift-exchange/containers/SerialNumberSetting'
import SerialNumberTable from 'components/pages/Gift/gift-exchange/containers/SerialNumberTable'
import MemberModal from 'components/pages/Gift/gift-exchange/widgets/MemberModal'
import { Modal } from 'components/units'
import { showTopToast } from 'components/units/Toast'
import { TOAST } from 'constant/common'
import { fieldRestrictNumberZero } from 'helpers/validation'
import { tabStatus as listTabStatus } from 'pages/Gift/config'
import { SettingContext, tabStatus } from 'pages/Gift/ExchangeSetting'
import { authAccountSelector } from 'store/auth/authSelector'
import {
  patchExchangeCodeRulesThunk,
  batchDeleteExchangeCodeThunk,
  getExchangeCodeListThunk,
  switchExchangeCodeTypeThunk,
  exchangeCodeListSelector,
  exchangeRulesStateSelector,
  messageStateSelector,
  resetToastState,
  resetPatchExchangeCodeRulesRsp,
} from 'store/gift-exchange/giftExchangeSerialSlice'
import {
  getExchangeCodeDetailThunk,
  getGiftExchangeSettingThunk,
  exchangeCodeDetailSelector,
  giftExchangeStateSelector,
  resetGiftExchangeDetailModal,
} from 'store/gift-exchange/giftExchangeSlice'
import {
  updateListLoading,
  updateDataLoading,
  updateMaskOpen,
} from 'store/global/globalSlice'
import {
  resetMemberOutline,
  getMemberOutlineThunk,
} from 'store/member-outline/memberOutlineSlice'
import { startConnection } from 'store/serial-batch-upload/serialBatchUploadSlice'
import SystemGenerateCount from './gift-exchange/widgets/SystemGenerateCount'

function SerialNumberTab() {
  const dispatch = useDispatch()
  const { isView, isEdit } = useSelector(giftExchangeStateSelector)
  const {
    isDefaultRulesExisted,
    defaultRules,
    uploadCount,
    isAmountEnough,
    isCodeExchanged,
    isSystemType,
    isUserType,
    isSameCode,
  } = useSelector(exchangeRulesStateSelector)
  const listTotal = useSelector(exchangeCodeListSelector)
  const { toastMsg, rulesUpdatedTime } = useSelector(messageStateSelector)
  const { brandId } = useSelector(authAccountSelector)
  const context = useContext(SettingContext)
  const {
    defaultData,
    setMask,
    handleUniteModalSwitch,
    setTransparentMask,
    pageType,
    history,
    settingId,
    infoState: [infoState],
    stateFromEdit: [, setStateFromEdit],
  } = context
  const isThisPageNow = pageType === tabStatus.giftNo
  const isReceiveWayLink = defaultData?.receiveWay === collectionMethod.link
  // apiPayload?.giftSettingId 為編輯 ＆ 檢視進入頁面，context?.settingId 為新增進入頁面
  const giftSettingId =
    history?.location?.state?.apiPayload?.giftSettingId || settingId
  const [setting, setSetting] = useState({
    ...defaultRules,
    uploadCount,
    existedTotalCount: listTotal.totalCount,
    total: null,
    isSetting: false,
  })
  const [payload, setPayload] = useState(initPayload)
  const [selectedList, setSelectedList] = useState([])
  const [isDeleteModal, setDeleteModal] = useState(false)
  const [valid, setValid] = useState(initValid)
  const modalContent = useSelector(exchangeCodeDetailSelector)
  const isSelected = selectedList.some((i) => i?.no)
  const isSettingTypeExisted = setting.type !== ''
  const barcodeTypeNotice = infoState?.barcodeTypeNotice
  const [isImportModal, setImportModal] = useState(false)
  const [isUploadModal, setUploadModal] = useState(false)
  const [isMemberModal, setMemberModal] = useState(false)
  const [isValidate, setIsValidate] = useState(false)
  /* 處理 UI 需求：手動生成時，table 需顯示 input 介面 */
  const rowsWithUiData =
    payload.p === 1 && isUserType && isEdit
      ? [{ no: null, giftCode: null }, ...listTotal.rows]
      : listTotal.rows
  const isLackOfRules = handleIsLackOfRules(setting)

  const handleListQuery = async (data) => {
    const updateQueryData = { ...payload, ...data }
    setPayload(updateQueryData)
    await dispatch(
      getExchangeCodeListThunk({
        brandId,
        giftSettingId,
        payload: updateQueryData,
      })
    )
  }

  /**
   * 取消變更規則
   * 恢復原有設定，並讓「確認規則」按鈕也恢復為 disabled 狀態
   */
  const handleDeleteModalClose = () => {
    setDeleteModal(false)
    setSetting((prev) => ({ ...prev, ...defaultRules, isSetting: false }))
  }

  const handleDeleteModalConfirm = async ({ handleModalLoading }) => {
    handleModalLoading(true)
    setStateFromEdit(listTabStatus.unavailable)
    const data = {
      giftCodeType: setting.type,
      giftCodePrefix: setting.prefix,
      giftCodeLength: setting.length,
      giftCodeGetType: setting.codeGetType,
    }
    await dispatch(
      switchExchangeCodeTypeThunk({
        brandId,
        giftSettingId,
        body: data,
      })
    )
    await dispatch(
      patchExchangeCodeRulesThunk({
        brandId,
        giftSettingId,
        payload: data,
      })
    )
    await dispatch(getGiftExchangeSettingThunk({ brandId, giftSettingId }))
    await dispatch(
      getExchangeCodeListThunk({ brandId, giftSettingId, payload })
    )
    handleModalLoading(false)
    setDeleteModal(false)
    setSetting({ ...setting, isSetting: false })
  }

  /* 確認規則按鈕 onClick function */
  const handleConfirmRules = async () => {
    setIsValidate(true)
    const { validState, isValidPass } = handleValid({ data: setting })
    setValid(validState)
    if (!isValidPass) return
    const isUpdateWarning =
      setting.type !== defaultRules.type ||
      setting.length !== defaultRules.length ||
      setting.prefix !== defaultRules.prefix ||
      setting.codeGetType !== defaultRules.codeGetType
    if (isUpdateWarning && listTotal.totalCount) {
      setDeleteModal(true)
    } else {
      await dispatch(
        patchExchangeCodeRulesThunk({
          brandId,
          giftSettingId,
          payload: {
            giftCodeType: setting.type,
            giftCodePrefix: setting.prefix,
            giftCodeLength: setting.length,
            giftCodeGetType: setting.codeGetType,
          },
        })
      )
      await dispatch(getGiftExchangeSettingThunk({ brandId, giftSettingId }))
      await dispatch(
        getExchangeCodeListThunk({ brandId, giftSettingId, payload })
      )
      setSetting({ ...setting, isSetting: false })
    }
  }

  /* 搜尋的 API 呼叫 */
  const handleSearch = useCallback(async () => {
    if (!isThisPageNow) return
    setTransparentMask(true)
    await dispatch(
      getExchangeCodeListThunk({ brandId, giftSettingId, payload })
    )
    setTransparentMask(false)
  }, [
    brandId,
    dispatch,
    giftSettingId,
    isThisPageNow,
    payload,
    setTransparentMask,
  ])

  /* 處理批次上傳序號 */
  const handleUploadBtnOnChange = handleUploadCsv({
    brandId,
    giftSettingId,
    infoState,
    handleSearch,
    setMask,
    setUploadModal,
    setStateFromEdit,
    setImportModal,
    handleSyncUploadProgress: () => {
      dispatch(startConnection({ module: 'gift' }))
    },
  })

  /* 控制生成序號 toast 所使用的灰色遮罩 */
  const handleGlobalMask = (bool) => {
    dispatch(updateMaskOpen(bool))
  }

  /* 產生序號 */
  const handleGenerateCode = async () => {
    setIsValidate(true)
    const { validState, isValidPass } = handleValid({ data: setting })
    setValid(validState)
    if (!isValidPass) return
    await handleGenerate({
      setting,
      setValid,
      setMask: handleGlobalMask,
      handleSearch,
      brandId,
      giftSettingId,
      setStateFromEdit,
      handleUniteModalSwitch,
      handleDataLoading: (bool) => dispatch(updateDataLoading(bool)),
    })
  }

  const handleMemberModalClose = () => {
    setMemberModal(false)
    dispatch(resetMemberOutline())
    dispatch(resetGiftExchangeDetailModal())
  }
  const handleMemberModalConfirm = () => {
    window.open(`/member/edit/${modalContent.memberId}`)
    handleMemberModalClose()
  }

  const handleClickRow = (rowData) => {
    const { memberId, giftExchangeCodeId } = rowData
    dispatch(getMemberOutlineThunk({ memberId, brandId }))
    dispatch(
      getExchangeCodeDetailThunk({
        brandId,
        giftSettingId,
        giftExchangeCodeId,
      })
    )
    setMemberModal(true)
  }

  const handleSettingChange =
    (type) =>
    (e, ...props) => {
      let updateValue = null
      const [value] = props
      const isSystem = Number(e.target.value) === buildType.system

      switch (type) {
        case 'type':
          updateValue = {
            [type]: Number(e.target.value),
            codeGetType: isSystem ? 10 : null,
            prefix: isSystem ? setting.prefix : '',
            length: isSystem ? setting.length : 0,
            total: isSystem ? setting.total : null,
          }
          setIsValidate(false)
          break
        case 'prefix':
          updateValue = { [type]: e.target.value }
          break
        case 'total':
          updateValue = { [type]: Number(fieldRestrictNumberZero(e)) }
          break
        case 'length':
          updateValue = { [type]: value }
          break
        case 'codeGetType':
          updateValue = { [type]: Number(e.target.value) }
          break
        default:
          break
      }

      const isDifferent = Object.keys(defaultRules).some((key) => {
        if (key === type) {
          return updateValue[key] !== defaultRules[key]
        }
        return setting[key] !== defaultRules[key]
      })

      setSetting((prev) => ({
        ...prev,
        ...updateValue,
        isSetting: isDifferent,
      }))

      // 非 type 欄位，且 isValidate true，才即時驗證
      if (isValidate && type !== 'type') {
        const { validState } = handleValid({
          data: { ...setting, ...updateValue },
        })
        setValid(validState)
      }
    }

  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      const selectedItem = {
        no: row.no,
        giftExchangeCodeId: row.giftExchangeCodeId,
      }
      return setSelectedList((prev) => [...prev, selectedItem])
    }
    return setSelectedList((prev) => prev.filter((i) => i.no !== row.no))
  }

  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const selectAllList = rows.map((i) => ({
        no: i.no,
        giftExchangeCodeId: i.giftExchangeCodeId,
      }))
      return setSelectedList(selectAllList)
    }
    return setSelectedList([])
  }

  const columnsObj = {
    giftCode: {
      handleSearch,
      brandId,
      giftSettingId,
      disabled: isAmountEnough,
      updateFn: setStateFromEdit,
      handleUniteModalSwitch,
      setTransparentMask,
      isSettingDone: !setting.isSetting,
    },
    member: {
      onClick: handleClickRow,
    },
    listTotal, // 主要抓「已領取和尚未領取資料」
    isSameCode, // 判斷序號領取類型
  }

  const selectObj = {
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    selected: selectedList.map((i) => i.no),
    nonSelectable: listTotal.rows
      .filter(
        (i) =>
          i.status === statusType.exchanged || i.status === statusType.received
      )
      .map((i) => i.no),
    hideSelectColumn: isView,
  }

  const handleBatchRemove = useCallback(
    async (e, { handleButtonLocalLoading }) => {
      handleButtonLocalLoading(true)
      try {
        const body = { rows: selectedList }
        await dispatch(
          batchDeleteExchangeCodeThunk({ brandId, giftSettingId, body })
        )
        setStateFromEdit(listTabStatus.unavailable) // TODO:[重構]待確認 setStateFromEdit 行為
        setSelectedList([])
        dispatch(updateListLoading(true))
        await handleSearch()
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(updateListLoading(false))
        handleButtonLocalLoading(false)
      }
    },
    [
      selectedList,
      brandId,
      giftSettingId,
      setStateFromEdit,
      dispatch,
      handleSearch,
    ]
  )

  /* 初始化序號相關設定 */
  useEffect(() => {
    if (brandId && giftSettingId && isThisPageNow) {
      dispatch(getGiftExchangeSettingThunk({ brandId, giftSettingId }))
      dispatch(
        getExchangeCodeListThunk({
          brandId,
          giftSettingId,
          payload: initPayload,
        })
      )
    }
    return () => {
      dispatch(resetPatchExchangeCodeRulesRsp())
    }
  }, [brandId, dispatch, giftSettingId, isThisPageNow])

  useEffect(() => {
    setSetting((prev) => ({
      ...prev,
      ...defaultRules,
      uploadCount,
      existedTotalCount: listTotal.totalCount,
    }))
  }, [defaultRules, listTotal.totalCount, uploadCount])

  /* 顯示 toast:[重構] 覆蓋既有序號規則、批次刪除序號成功 ，重構後會改放到各自 container */
  useEffect(() => {
    if (!toastMsg) return
    showTopToast({ message: toastMsg }, TOAST.SUCCESS)
    dispatch(resetToastState())
  }, [dispatch, toastMsg])

  return (
    <SettingNoWrapper>
      <Form className="rules-form" onSubmit={handleDefaultSubmit}>
        <SerialNumberSetting
          isFieldsDisabled={isView || isCodeExchanged}
          isConfirmDisabled={!setting.isSetting || isLackOfRules || isView}
          valid={valid}
          setting={setting}
          rulesUpdatedNotice={rulesUpdatedTime}
          barcodeTypeNotice={barcodeTypeNotice}
          isReceiveWayLink={isReceiveWayLink}
          onSettingChange={handleSettingChange}
          onConfirmRules={handleConfirmRules}
        />
      </Form>
      {/* 設定過 rules && 系統建立 && 編輯狀態，才顯示生成序號 widget */}
      <Form onSubmit={handleDefaultSubmit}>
        {isDefaultRulesExisted && isSystemType && isEdit && (
          <SystemGenerateCount
            isFieldsDisabled={setting.isSetting || isAmountEnough}
            isGenerateDisabled={!setting.total}
            setting={setting}
            valid={valid}
            onSettingChange={handleSettingChange}
            onGenerate={handleGenerateCode}
          />
        )}
      </Form>
      {/* 已設定過 rules 才顯示列表 */}
      {isDefaultRulesExisted && (
        <SerialNumberTable
          defaultRules={defaultRules}
          isSettingTypeExisted={isSettingTypeExisted}
          isSystemType={isSystemType}
          isUserType={isUserType}
          isEdit={isEdit}
          isSelected={isSelected}
          isImportModal={isImportModal}
          isUploadModal={isUploadModal}
          isSameCode={isSameCode}
          payload={payload}
          listTotal={listTotal}
          columnsObj={columnsObj}
          selectObj={selectObj}
          uploadCount={uploadCount}
          data={rowsWithUiData}
          giftSettingId={giftSettingId}
          context={context}
          onBatchRemove={handleBatchRemove}
          onUploadChange={handleUploadBtnOnChange}
          onListQuery={handleListQuery}
          setUploadModal={setUploadModal}
          setMask={setMask}
        />
      )}

      <Modal
        show={isDeleteModal}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteModalConfirm}
        variant={color.danger}
        titleText="變更設定"
        confirmBtnText="繼續"
        closeBtnText="返回"
      >
        <p>變更序號設定，</p>
        <p>將刪除已建立之序號內容。</p>
        <p>是否繼續執行？</p>
      </Modal>

      <MemberModal
        modalContent={modalContent}
        isMemberModal={isMemberModal}
        handleMemberModalClose={handleMemberModalClose}
        handleMemberModalConfirm={handleMemberModalConfirm}
      />
    </SettingNoWrapper>
  )
}

export default SerialNumberTab
