import { format, getYear, getMonth, getDate, formatISO } from 'date-fns'
import { useRef, useState, useEffect } from 'react'
import { Modal, Form, Col, Row, Container } from 'react-bootstrap'
import { DateRange } from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'
import { toast } from 'react-hot-toast'
import { postGainPointRule, patchGainPointRule } from 'api/ApiMain'
import iconCalendar from 'assets/images/icon_calendar.svg'
import iconTooltip from 'assets/images/icon_tooltip.svg'
import iconModalClose from 'assets/images/modal/icon_modalClose.svg'
import { handleCatch } from 'components/pages/PointSetting/PointSettingHelper'
import {
  Button,
  Checkbox,
  Input,
  Loader,
  MonthlyDay,
  MonthlyWeek,
  Radio,
  Select,
  Tooltip,
  Weekly,
  YearlyDay,
} from 'components/units'
import { dataTypeTranslate } from 'helpers/common'
import { fieldRestrictNumberZero } from 'helpers/validation'
import LocalStyle from './PointSettingModalStyle'

const requiredWarning = '尚未填寫'
// 欄位對應的驗證規則要顯示的文字
const fieldRules = {
  name: requiredWarning,
  pointCategory: requiredWarning,
  validDate: requiredWarning,
  memberObject: requiredWarning,
  condition: requiredWarning,
  content: requiredWarning,
}
// 每週指定日期初值
const pointDateWeeklyOption = (dataObj) => {
  const list = [
    {
      text: '一',
      name: 'mon',
      value: 'mon',
      checked: false,
    },
    {
      text: '二',
      name: 'tue',
      value: 'tue',
      checked: false,
    },
    {
      text: '三',
      name: 'wed',
      value: 'wed',
      checked: false,
    },
    {
      text: '四',
      name: 'thu',
      value: 'thu',
      checked: false,
    },
    {
      text: '五',
      name: 'fri',
      value: 'fri',
      checked: false,
    },
    {
      text: '六',
      name: 'sat',
      value: 'sat',
      checked: false,
    },
    {
      text: '日',
      name: 'sun',
      value: 'sun',
      checked: false,
    },
  ]
  if (dataObj) {
    return list.map((item) => ({
      ...item,
      checked: dataObj.hasOwnProperty(item.name)
        ? dataObj[item.name]
        : item.checked,
    }))
  }
  return list
}
// 每月指定日期初值
const pointDateMonthlyDatesOption = (list) => {
  const arr = Array.from({ length: 31 }, (_, index) => {
    const key = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`
    return {
      text: key,
      value: parseInt(key, 10),
      checked: Array.isArray(list?.days)
        ? list.days.includes(index + 1)
        : false,
    }
  })
  arr.push({
    text: '最後一日',
    value: 'lastDay',
    checked: list?.lastDay,
  })
  return arr
}
// 每月指定週期初值
const pointDateMonthlyWeekdaysOption = (dataObj) => {
  const obj = {
    serialNumber: [
      {
        name: 'first',
        checked: false,
        text: '第一個',
        value: 1,
      },
      {
        name: 'second',
        checked: false,
        text: '第二個',
        value: 2,
      },
      {
        name: 'third',
        checked: false,
        text: '第三個',
        value: 3,
      },
      {
        name: 'fourth',
        checked: false,
        text: '第四個',
        value: 4,
      },
      {
        name: 'last',
        checked: false,
        text: '最後的',
        value: 'last',
      },
    ],
    dayOfWeek: [
      {
        name: 'mon',
        checked: false,
        text: '一',
        value: 1,
      },
      {
        name: 'tue',
        checked: false,
        text: '二',
        value: 2,
      },
      {
        name: 'wed',
        checked: false,
        text: '三',
        value: 3,
      },
      {
        name: 'thu',
        checked: false,
        text: '四',
        value: 4,
      },
      {
        name: 'fri',
        checked: false,
        text: '五',
        value: 5,
      },
      {
        name: 'sat',
        checked: false,
        text: '六',
        value: 6,
      },
      {
        name: 'sun',
        checked: false,
        text: '日',
        value: 0,
      },
    ],
  }
  if (dataObj) {
    const { dayOfWeek, ...weeksOfMonth } = dataObj
    const updateCheckedItems = (source, target) => {
      Object.keys(source).forEach((key) => {
        const item = target.find((item) => item.name === key)
        if (item) {
          item.checked = source[key]
        }
      })
    }
    // 第ｎ個的checked處理
    updateCheckedItems(weeksOfMonth, obj.serialNumber)
    // 星期幾的checked處理
    updateCheckedItems(dayOfWeek, obj.dayOfWeek)
  }
  return { ...obj }
}
// 每年指定日期初值
const pointDateYearlyDetermineDatesOption = (list) => {
  const dates = Array.isArray(list)
    ? list.map((dateItem) => ({
        year: getYear(new Date()),
        month: dateItem.month,
        day: dateItem.day,
      }))
    : []

  return { dates }
}

// 處理被選取的selected項目資料
const handleSelectedItem = (optionItems) => {
  if (Array.isArray(optionItems)) {
    return optionItems.find((optionItem) => optionItem.selected) || null
  }
  return null
}
// 處理被選取的checked項目資料
const handleCheckedItem = (list = []) => {
  return list.filter(({ selected }) => selected)
}
// 處理被選取的checked值
const handleChecked = (list = [], currentId = 0) => {
  return list.some(({ id, selected }) => id === currentId && selected)
}
// select 的option 配置
const handleSelectOption = (arrData) => {
  if (Array.isArray(arrData)) {
    return arrData.map((dataItem, dataIndex) => {
      const { name, id } = dataItem
      return {
        text: name,
        value: id,
        dropdownItemOption: {
          eventKey: dataIndex,
        },
      }
    })
  }
  return []
}

const initInputValues = ({ inputValuesData, modalData, isEdit }) => ({
  name: inputValuesData?.name ?? null,
  pointCategory: handleSelectedItem(inputValuesData?.pointCategoryList),
  startDate: inputValuesData?.startDate || null,
  endDate: inputValuesData?.endDate || null,
  forNewMember: inputValuesData?.forNewMember || false,
  forInitialMember: inputValuesData?.forInitialMember ?? false,
  memberLevels: handleCheckedItem(
    isEdit ? inputValuesData?.memberLevelList : modalData?.memberLevelList
  ),
  birthdayType: handleSelectedItem(inputValuesData?.birthdayTypeList), // 注意這邊由於新增時api要給birthdayType，但get時api卻是得到birthdayTypeList
  firstAmount: inputValuesData?.isFirstAmount, // 注意這邊由於新增時api要給firstAmount，但get時api卻是得到isFirstAmount
  overSpentPerDealCond: inputValuesData?.overSpentPerDealCond ?? null,
  notSplit: inputValuesData?.notSplit ?? false,
  dateOption: inputValuesData?.dateOption ?? null,
  daily: inputValuesData?.daily ?? null,
  weekly: inputValuesData?.weekly ?? null,
  monthlyDates: inputValuesData?.monthlyDates ?? null,
  monthlyDayOfWeek: inputValuesData?.monthlyDayOfWeek ?? null,
  yearlyDates: inputValuesData?.yearlyDates ?? { dates: [] },
  pointMultiple: handleSelectedItem(inputValuesData?.pointMultipleList),
  pointMultipleMaxResult: inputValuesData?.pointMultipleMaxResult ?? null,
  additionalPoint: inputValuesData?.additionalPoint,
})

const initPointSettingState = (inputValuesData, modalData) => {
  const validPeriodOptions = {
    true: 'forever',
    false: 'selectDate',
    undefined: null,
  }

  const memberObjectOptions = {
    true: 'forNewMember',
    false: 'selectMember',
    undefined: null,
  }

  const validPeriod = validPeriodOptions[inputValuesData?.validPeriodForever]
  const memberObject = memberObjectOptions[inputValuesData?.forNewMember]
  const givePointOptions = {
    true: 'pointMultiple',
    false: inputValuesData?.usingAdditionalPoint ? 'extraPoint' : 'noPoint',
    undefined: null,
  }
  const givePointContnt = givePointOptions[inputValuesData?.usingPointMultiple]

  return {
    validPeriod, // 效期的radio
    memberObject, // 會員的radio
    memberAllLevel: inputValuesData?.isAllMemberLevelChecked, // 會員的checkbox(勾選“全部”)
    memberLevelList: modalData?.memberLevelList, // 會員的checkbox
    forInitialMember: !!inputValuesData?.forInitialMember, // 註冊會員的checkbox
    isShowValidDate: inputValuesData?.startDate && inputValuesData?.endDate,
    isFirstAmount: inputValuesData?.isFirstAmount, // 條件的首筆消費checkbox
    singleSpentChecked: inputValuesData?.usingOverSpentPerDealCond, // 條件的單筆消費checkbox
    singleSpendNotSplit: inputValuesData?.notSplit, // 條件的單筆消費裡消費金額不累送checkbox
    memberBirthdayChecked: inputValuesData?.birthdayTypeList?.some(
      (item) => item.selected
    ), // 條件的單筆消費裡會員生日checkbox
    givePointDateChecked: inputValuesData?.usingDateOption, // 條件的贈點時間點checkbox
    givePointDate: inputValuesData?.dateOption, // 條件的贈點時間點radio
    givePointDateWeeklyOption: pointDateWeeklyOption(inputValuesData?.weekly), // 條件的每週按鈕(checkbox)
    givePointDateMonthlyDatesOption: pointDateMonthlyDatesOption(
      inputValuesData?.monthlyDates
    ), // 條件的每月，指定日期按鈕(checkbox)
    givePointDateMonthlyWeekdaysOption: pointDateMonthlyWeekdaysOption(
      inputValuesData?.monthlyDayOfWeek
    ), // 條件的每月，指定週期按鈕(checkbox)
    givePointDateYearlyDetermineDatesOptionCount: 0,
    givePointDateYearlyDetermineDatesOption:
      pointDateYearlyDetermineDatesOption(inputValuesData?.yearlyDates?.dates),
    givePointContnt, // 內容的radio
  }
}

const initPointSettingDateRangeOption = (inputValuesData) => [
  {
    startDate: inputValuesData?.startDate
      ? new Date(inputValuesData?.startDate)
      : null,
    endDate: inputValuesData?.endDate
      ? new Date(inputValuesData?.endDate)
      : new Date(''),
    key: 'selection',
  },
]

function PointSettingModal(props) {
  const {
    brandId,
    isEdit,
    isShowModalPointSetting,
    modalData,
    inputValuesData,
    gainPointRuleId,
  } = props

  // 事件
  const {
    isLoadingModalPointSetting,
    handlePointSettingModalShow,
    updatePointSettingRowsData,
  } = props
  // 表單form是否驗證欄位規則
  const [validated, setValidated] = useState(false)

  // 表單欄位值
  const [inputValues, setInputValues] = useState(
    initInputValues({ inputValuesData, modalData, isEdit })
  )

  // //表單欄位驗證成功送出是否執行loading
  // const [isLoadingModal, setLoadingModal] = useState(isLoadingModalPointSetting)

  // modal-贈點設定的欄位errors
  const [fieldsError] = useState({
    name: fieldRules.name,
    pointCategory: fieldRules.pointCategory,
    validDate: fieldRules.validDate,
    memberObject: fieldRules.memberObject,
    condition: fieldRules.condition,
    content: fieldRules.content,
  })

  // modal-贈點設定，資料值
  const [pointSettingState, setPointSettingState] = useState(
    initPointSettingState(inputValuesData, modalData)
  )
  // 處理效期的"選擇日期"
  const [pointSettingDateRangeOption, setPointSettingDateRangeOption] =
    useState(initPointSettingDateRangeOption(inputValuesData))

  const formRef = useRef(null)

  // 欄位onChange處理
  const onChangeField = ({ target, ...prop }) => {
    /*
      name、value表示參數是傳入event來做處理
      finalName、finalValue表示參數是傳入欄位name跟value來做處理
      可以擇一選擇參數帶入處理方式  
    */
    const name = target?.name ?? null
    const value = target?.value ?? null
    let { fieldName, fieldValue } = prop
    fieldName = fieldName ?? null
    fieldValue = fieldValue ?? null
    const finalName = fieldName ?? name
    const finalValue = fieldValue ?? value

    if (target?.type === 'checkbox') {
      const { checked } = target
      if (finalValue === 'all') {
        // 對象：指定會級：全部
        if (target?.name === 'memberLevels') {
          setPointSettingState((prevState) => {
            return {
              ...prevState,
              memberAllLevel: checked,
            }
          })
          // 設定欄位值
          setInputValues((prevState) => {
            const newMemberLevels = JSON.parse(
              JSON.stringify(modalData.memberLevelList)
            )
            newMemberLevels.forEach((item, index) => {
              newMemberLevels[index].selected = checked
            })
            return {
              ...prevState,
              memberLevels: newMemberLevels,
              forInitialMember: checked,
            }
          })
        }
      } else {
        // 對象：指定會級：
        if (
          target?.name === 'memberLevels' ||
          target?.name === 'forInitialMember'
        ) {
          setPointSettingState((prevState) => {
            let newMemberAllLevel = false
            let memberLevelCheckedCount = 0
            let forInitialMemberChecked = true

            if (target?.name === 'memberLevels') {
              if (checked) {
                memberLevelCheckedCount++
                if (
                  inputValues?.memberLevels.length ===
                  prevState.memberLevelList.length
                ) {
                  memberLevelCheckedCount = 1
                } else if (inputValues?.memberLevels.length > 0) {
                  memberLevelCheckedCount++
                }
              } else if (
                inputValues?.memberLevels.length ===
                prevState.memberLevelList.length
              ) {
                memberLevelCheckedCount = prevState.memberLevelList.length - 1
              }
              forInitialMemberChecked = inputValues.forInitialMember
            }
            if (target?.name === 'forInitialMember') {
              forInitialMemberChecked = checked
              for (const item of inputValues?.memberLevels) {
                const { selected } = item
                if (selected) {
                  memberLevelCheckedCount++
                }
              }
            }
            if (
              checked &&
              memberLevelCheckedCount === prevState?.memberLevelList?.length &&
              forInitialMemberChecked
            ) {
              newMemberAllLevel = true
            } else {
              newMemberAllLevel = false
            }

            return {
              ...prevState,
              memberAllLevel: newMemberAllLevel,
            }
          })
          setInputValues((prevState) => {
            const newMemberLevels = JSON.parse(
              JSON.stringify(pointSettingState.memberLevelList)
            )
            if (target?.name === 'memberLevels') {
              for (const [
                newMemberLevelIndex,
                newMemberLevelItem,
              ] of newMemberLevels.entries()) {
                const { id: newMemberLevelId } = newMemberLevelItem
                for (const inputValuesMemberLevelItem of prevState.memberLevels) {
                  const { id: inputValuesMemberLevelId } =
                    inputValuesMemberLevelItem
                  if (newMemberLevelId === inputValuesMemberLevelId) {
                    newMemberLevels[newMemberLevelIndex] =
                      inputValuesMemberLevelItem
                    break
                  }
                }
              }
              newMemberLevels[prop.index].selected = checked
            }
            return {
              ...prevState,
              [finalName]:
                finalName === 'forInitialMember'
                  ? checked
                  : handleCheckedItem(newMemberLevels),
            }
          })
        }
        // 其他checkbox
        else {
          setInputValues((prevState) => {
            return {
              ...prevState,
              [finalName]: checked,
            }
          })
        }
      }
    } else if (target?.type.includes('select')) {
      const { eventKey, optionItems } = prop
      setInputValues((prevInputValues) => {
        const newInputValues = optionItems[eventKey]
        return {
          ...prevInputValues,
          [finalName]: newInputValues,
        }
      })
    } else {
      setInputValues((prevInputValues) => {
        return {
          ...prevInputValues,
          [finalName]: finalValue,
        }
      })
    }
  }

  // 處理對象類型中，欄位驗證的warning判斷
  const handleMemberObjectFieldsError = (
    formValidated,
    pointSettingStateParam,
    inputValuesParam
  ) => {
    let isError = false
    if (formValidated) {
      // 選擇指定會級且未勾選任何項目
      if (
        pointSettingStateParam?.memberObject !== 'forNewMember' &&
        inputValuesParam?.memberLevels?.length < 1 &&
        inputValuesParam?.forInitialMember === false
      ) {
        isError = true
      } else {
        isError = false
      }
    }
    return isError
  }

  // 處理條件類型中，欄位驗證的warning判斷
  const handleConditionFieldsError = (
    formValidated,
    pointSettingStateParam,
    inputValuesParam
  ) => {
    let isError = false

    if (formValidated) {
      if (
        // 此條件暫時隱藏，未來視情況打開
        // //單筆消費金額、贈點時間點沒有勾選任一項
        // (!pointSettingState?.singleSpentChecked &&
        //   !pointSettingState?.givePointDateChecked) ||

        // 會員生日勾選卻沒選下拉選單
        (pointSettingStateParam?.memberBirthdayChecked &&
          !inputValuesParam?.birthdayType) ||
        // 單筆消費金額勾選卻沒填幾元
        (pointSettingStateParam?.singleSpentChecked &&
          !inputValuesParam?.overSpentPerDealCond &&
          inputValuesParam?.overSpentPerDealCond !== 0) ||
        // 贈點時間點勾選
        (pointSettingStateParam?.givePointDateChecked &&
          // 此條件暫時隱藏，未來視情況打開
          // //沒選每日
          // !inputValues?.daily &&

          // 選了每週，卻沒填寫日期
          !inputValuesParam?.weekly &&
          // 選了每月，指定日期，卻沒填寫日期
          !inputValuesParam?.monthlyDates &&
          // 選了每月，指定週期，卻沒填寫完整日期
          !inputValuesParam?.monthlyDayOfWeek &&
          // 選了每年，指定日期，卻沒填寫日期
          (!inputValuesParam?.yearlyDates ||
            inputValuesParam?.yearlyDates?.dates.length < 1))
      ) {
        isError = true
      } else {
        isError = false
      }
    }
    return isError
  }

  // 處理內容類型中，根據類別類型中是否選擇不贈點（因為會根據選擇贈點類型決定是否顯示部分項目）
  const detectIsNotGivePoint = () => {
    return inputValues?.pointCategory?.code === 'B'
  }

  // 處理內容類型中，欄位驗證的warning判斷
  const handleContentFieldsError = (
    formValidated,
    pointSettingStateParam,
    inputValuesParam
  ) => {
    let isError = false
    if (formValidated) {
      // 類別類型選取了"不贈點"
      if (detectIsNotGivePoint()) {
        isError = false
      } else if (
        // 內容的radio都沒點選
        !pointSettingStateParam?.givePointContnt ||
        // 點選"點數贈送"但贈送幾倍未填
        (pointSettingStateParam?.givePointContnt === 'pointMultiple' &&
          !inputValuesParam?.pointMultiple) ||
        // 點選"點數加贈"但贈送幾點以及最多幾點未填
        (pointSettingStateParam?.givePointContnt === 'extraPoint' &&
          !inputValuesParam?.additionalPoint)
      ) {
        isError = true
      } else {
        isError = false
      }
    }
    return isError
  }

  // 表單發送處理
  const handleSubmit = async (e, handleButtonLocalLoading) => {
    e.preventDefault()
    const form = formRef.current
    // 判斷form的欄位中，有填寫name值及驗證規則(required、pattern)的項目
    const isValidated = form.checkValidity()
    setValidated(true)

    // form的欄位判斷都通過後，再進行特別判斷
    if (isValidated) {
      // 特別判斷
      let isExtraValidated = true
      if (
        // 類別：select 特別判斷，因爲傳統select不會有沒預設的情形
        !inputValues?.pointCategory ||
        // 對象：特別判斷，因爲是group，為了保持inputValues直接就是可以輸出的欄位，而這邊的取值不是從name，所以要另外判斷
        handleMemberObjectFieldsError(
          isValidated,
          pointSettingState,
          inputValues
        ) ||
        // 條件：欄位特別判斷，因爲是group，為了保持inputValues直接就是可以輸出的欄位，而這邊這邊的取值不是從name，所以要另外判斷
        handleConditionFieldsError(
          isValidated,
          pointSettingState,
          inputValues
        ) ||
        // 內容：欄位特別判斷，因為有select，傳統select不會有沒預設的情形
        handleContentFieldsError(isValidated, pointSettingState, inputValues)
      ) {
        isExtraValidated = false
      }
      if (isExtraValidated) {
        /* 處理條件：每年，指定日期，若欄位為空，傳入的陣列移除該項 */
        inputValues.yearlyDates.dates = inputValues.yearlyDates.dates.filter(
          (dateItem) => dateItem.month || dateItem.day
        )

        /* 處理想要轉換為number的欄位 */
        const translateNumberFields = [
          'overSpentPerDealCond',
          'pointMultipleMaxResult',
          'additionalPoint',
        ]
        translateNumberFields.forEach((inputName) => {
          const inputItemValue = inputValues[inputName]
          if (inputItemValue !== undefined) {
            inputValues[inputName] = dataTypeTranslate(inputItemValue, 'number')
          }
        })

        handleButtonLocalLoading(true)
        // 編輯贈點
        if (isEdit) {
          try {
            await patchGainPointRule(brandId, gainPointRuleId, inputValues)
            await updatePointSettingRowsData()
            toast.success('編輯贈點成功')
            handlePointSettingModalShow(false)
          } catch (error) {
            handleCatch(error)
          } finally {
            handleButtonLocalLoading(false)
          }
        }
        // 新增贈點
        else {
          try {
            await postGainPointRule(brandId, inputValues)
            toast.success('新增贈點成功')
            handlePointSettingModalShow(false)
            updatePointSettingRowsData()
          } catch (error) {
            handleCatch(error)
          } finally {
            handleButtonLocalLoading(false)
          }
        }
      }
    }
  }
  // 處理date range取日期
  const getDateRangeValue = (startDate, endDate) => {
    let value = ''
    if (startDate && endDate) {
      value = `${format(new Date(startDate), 'yyyy/MM/dd')}~${format(
        new Date(endDate),
        'yyyy/MM/dd'
      )}`
    }
    return value
  }
  // 處理效期radio欄位改變
  const handleValidPeriodChange = (e) => {
    setPointSettingState((preState) => ({
      ...preState,
      validPeriod: e.target.value,
    }))
    let startDate = null
    let endDate = null
    if (e.target.value === 'forever') {
      setPointSettingDateRangeOption([
        {
          startDate: null,
          endDate: new Date(''),
          key: 'selection',
        },
      ])
    } else if (e.target.value === 'selectDate') {
      startDate = null
      endDate = null
    }
    setInputValues((prevState) => {
      return {
        ...prevState,
        startDate,
        endDate,
      }
    })
  }
  // 處理效期date欄位顯示與否
  const handleValidDateShow = (isShow) => {
    setPointSettingState((prevState) => {
      return { ...prevState, isShowValidDate: isShow }
    })
  }

  // 處理對象radio欄位改變
  const handleMemberObjectChange = (e) => {
    setPointSettingState((preState) => ({
      ...preState,
      memberObject: e.target.value,
    }))
  }

  // 處理條件類型中，單筆消費checkbox欄位改變
  const handleSingleSpentChange = (e) => {
    setPointSettingState((preState) => ({
      ...preState,
      singleSpentChecked: e.target.checked,
    }))
    setInputValues((prevState) => {
      return {
        ...prevState,
        overSpentPerDealCond: null, // reset
        notSplit: false, // reset
      }
    })
  }
  // 處理條件類型中，會員生日checkbox欄位改變
  const handleMemberBirthdayCheckedChange = (e) => {
    setPointSettingState((preState) => ({
      ...preState,
      memberBirthdayChecked: e.target.checked,
    }))
    if (!e.target.checked) {
      setInputValues((prevState) => {
        return {
          ...prevState,
          birthdayType: null,
        }
      })
    }
  }
  // 處理條件類型中，贈點時間點checkbox欄位改變
  const handleGivePointDateCheckedChange = (e) => {
    setPointSettingState((preState) => ({
      ...preState,
      givePointDateChecked: e.target.checked,
    }))
    if (!e.target.checked) {
      setInputValues((prevState) => {
        return {
          ...prevState,
          dateOption: null,
          daily: null,
          weekly: null,
          monthlyDates: null,
          monthlyDayOfWeek: null,
          yearlyDates: {
            dates: [],
          },
        }
      })
    }
  }
  // 處理條件類型中，贈點時間點-每日、每週、每月、每年的radio欄位改變
  const handleGivePointDateChange = (e) => {
    setPointSettingState((prevState) => ({
      ...prevState,

      givePointDate: e.target.value,
      givePointDateWeeklyOption: pointDateWeeklyOption(), // reset
      givePointDateMonthlyDatesOption: pointDateMonthlyDatesOption(), // reset
      givePointDateMonthlyWeekdaysOption: pointDateMonthlyWeekdaysOption(), // reset
      givePointDateYearlyDetermineDatesOption:
        pointDateYearlyDetermineDatesOption(
          inputValuesData?.yearlyDates?.dates
        ), // reset
    }))
    setInputValues((prevState) => {
      return {
        ...prevState,
        dateOption: e.target.value,
        daily: null, // reset
        weekly: null, // reset
        monthlyDates: null, // reset
        monthlyDayOfWeek: null, // reset
        yearlyDates: {
          dates: [],
        }, // reset
      }
    })

    // 勾選每日，改變inputValue中 daily 的值
    if (e.target.value === 'daily') {
      setInputValues((prevState) => {
        return {
          ...prevState,

          daily: e.target.checked, // 當日被勾選時設為 true
          weekly: null, // reset
          monthlyDates: null, // reset
          monthlyDayOfWeek: null, // reset
          yearlyDates: {
            dates: [],
          }, // reset
        }
      })
    }
  }

  // 處理條件類型中，贈點時間點-每週的button欄位改變
  const handleGivePointDateWeeklyOptionChange = (e, index) => {
    const { checked } = e.target
    const { value } = e.target

    setPointSettingState((prevState) => {
      const newGivePointDateWeeklyOption = [
        ...prevState.givePointDateWeeklyOption,
      ]
      newGivePointDateWeeklyOption[index] = {
        ...newGivePointDateWeeklyOption[index],
        checked,
      }
      return {
        ...prevState,
        givePointDateWeeklyOption: newGivePointDateWeeklyOption,
      }
    })
    setInputValues((prevState) => {
      let newWeekly = prevState.weekly
        ? JSON.parse(JSON.stringify(prevState.weekly))
        : null
      if (newWeekly) {
        let checkFalseCount = Object.values(newWeekly).length
        newWeekly[value] = checked

        checkFalseCount = Object.values(newWeekly).filter(
          (item) => !item
        ).length
        // 未勾選任一項目
        if (!checkFalseCount) {
          newWeekly = null
        }
      }
      // 從完全沒勾選，開始勾選
      else {
        newWeekly = pointSettingState.givePointDateWeeklyOption.reduce(
          (obj, item) => ({
            ...obj,
            [item.name]:
              item.name ===
              pointSettingState.givePointDateWeeklyOption[index].name
                ? true
                : item.checked,
          }),
          {}
        )
      }
      return {
        ...prevState,
        weekly: newWeekly,
      }
    })
  }
  // 處理條件類型中，贈點時間點-每月指定日期的button欄位改變
  const handleGivePointDateMonthlyDatesOptionChange = (e, index) => {
    const { checked } = e.target
    const value = parseInt(e.target.value, 10)

    setPointSettingState((preState) => {
      const newGivePointDateMonthlyDatesOption = [
        ...preState.givePointDateMonthlyDatesOption,
      ]
      newGivePointDateMonthlyDatesOption[index] = {
        ...newGivePointDateMonthlyDatesOption[index],
        checked,
      }
      return {
        ...preState,
        givePointDateMonthlyDatesOption: newGivePointDateMonthlyDatesOption,
      }
    })
    setInputValues((prevState) => {
      let newMonthlyDates = prevState.monthlyDates
        ? JSON.parse(JSON.stringify(prevState.monthlyDates))
        : null

      if (newMonthlyDates) {
        if (checked) {
          // 最後一日
          if (index + 1 === 32) {
            newMonthlyDates.lastDay = checked
          } else {
            newMonthlyDates.days.push(value)
          }
        } else {
          // 最後一日
          if (index + 1 === 32) {
            newMonthlyDates.lastDay = checked
            if (
              newMonthlyDates?.days?.length - 1 <= 0 &&
              !newMonthlyDates.lastDay
            ) {
              newMonthlyDates = null
            }
          } else {
            const removeIndex = prevState?.monthlyDates?.days?.indexOf(value)
            // -1的原因是因為取得的值是上一個state的並非最新值
            if (newMonthlyDates?.days?.length - 1 > 0) {
              newMonthlyDates?.days?.splice(removeIndex, 1)
            } else if (!newMonthlyDates.lastDay) {
              newMonthlyDates = null
            }
          }
        }
      }
      // 從完全沒勾選，開始勾選
      else {
        // 最後一日
        if (index + 1 === 32) {
          newMonthlyDates = {
            days: [],
            lastDay: true,
          }
        } else {
          newMonthlyDates = {
            days: [value],
            lastDay: false,
          }
        }
      }
      // 排序
      if (newMonthlyDates?.days) {
        newMonthlyDates.days = newMonthlyDates.days.sort((a, b) => {
          return a - b
        })
      }
      return {
        ...prevState,
        monthlyDates: newMonthlyDates,
      }
    })
  }
  // 處理條件類型中，贈點時間點-每月指定週期的button欄位改變
  const handleGivePointDateMonthlyWeekdaysOptionChange = (
    e,
    index,
    typeName
  ) => {
    if (typeName === 'serialList') {
      typeName = 'serialNumber'
    } else if (typeName === 'weekList') {
      typeName = 'dayOfWeek'
    }
    setPointSettingState((prevState) => {
      const newArrData = [
        ...prevState.givePointDateMonthlyWeekdaysOption?.[typeName],
      ]
      newArrData[index].checked = e.target.checked

      return {
        ...prevState,
        givePointDateMonthlyWeekdaysOption: {
          ...prevState.givePointDateMonthlyWeekdaysOption,
          [typeName]: newArrData,
        },
      }
    })
    setInputValues((prevState) => {
      let newMonthlyDayOfWeek = {}
      // 驗證只要每月的第Ｎ個禮拜或者禮拜幾，有一項沒填(需兩種都至少選一個)，則不寫入inputValues(帶入null)
      if (
        pointSettingState?.givePointDateMonthlyWeekdaysOption?.serialNumber.every(
          (item) => item.checked === false
        ) ||
        pointSettingState?.givePointDateMonthlyWeekdaysOption?.dayOfWeek.every(
          (item) => item.checked === false
        )
      ) {
        newMonthlyDayOfWeek = null
      } else {
        for (const keyName in pointSettingState.givePointDateMonthlyWeekdaysOption) {
          if (keyName === 'serialNumber') {
            for (const serialItem of pointSettingState
              .givePointDateMonthlyWeekdaysOption[keyName]) {
              const { name, checked } = serialItem
              newMonthlyDayOfWeek[name] = checked
            }
          } else if (keyName === 'dayOfWeek') {
            const newDayOfWeek = {}
            for (const serialItem of pointSettingState
              .givePointDateMonthlyWeekdaysOption[keyName]) {
              const { name, checked } = serialItem
              newDayOfWeek[name] = checked
            }
            newMonthlyDayOfWeek = {
              ...newMonthlyDayOfWeek,
              dayOfWeek: newDayOfWeek,
            }
          }
        }
      }

      return {
        ...prevState,
        monthlyDayOfWeek: newMonthlyDayOfWeek,
      }
    })
  }

  // 處理條件類型中，贈點時間點-每年，指定日期的input欄位改變
  const handleGivePointDateYearlyDetermineDatesOptionChange = (
    value,
    index
  ) => {
    setPointSettingState((prevState) => {
      const newGivePointDateYearlyDetermineDatesOption = {
        ...prevState.givePointDateYearlyDetermineDatesOption,
      }
      newGivePointDateYearlyDetermineDatesOption.dates[index] = {
        year: getYear(value),
        month: getMonth(value) + 1,
        day: getDate(value),
      }
      return {
        ...prevState,
        givePointDateYearlyDetermineDatesOption:
          newGivePointDateYearlyDetermineDatesOption,
      }
    })
    setInputValues((prevState) => {
      let newYearlyDates = prevState.yearlyDates
        ? {
            ...prevState.yearlyDates,
          }
        : null
      if (newYearlyDates) {
        newYearlyDates.dates[index] = {
          month: getMonth(value) + 1,
          day: getDate(value),
        }
      } else {
        newYearlyDates = {
          dates: [
            {
              month: getMonth(value) + 1,
              day: getDate(value),
            },
          ],
        }
      }
      return {
        ...prevState,
        yearlyDates: newYearlyDates,
      }
    })
  }

  // 處理內容類型中，點數贈送、點數加贈、不贈點的radio欄位改變
  const handleGivePointContentChange = (e) => {
    setPointSettingState((prevState) => ({
      ...prevState,
      givePointContnt: e.target.value,
    }))
    setInputValues((prevState) => ({
      ...prevState,
      pointMultiple: null, // reset
      pointMultipleMaxResult: null, // reset
      additionalPoint: null, // reset
    }))
  }

  const detectIsGivePoint = () => {
    return inputValues?.pointCategory?.code === 'A'
  }
  useEffect(() => {
    setInputValues(initInputValues({ inputValuesData, modalData, isEdit }))
    setPointSettingState(initPointSettingState(inputValuesData, modalData))
    setPointSettingDateRangeOption(
      initPointSettingDateRangeOption(inputValuesData)
    )
  }, [inputValuesData, modalData, isEdit])

  return (
    <LocalStyle
      backdrop="static"
      show={isShowModalPointSetting}
      onHide={() => handlePointSettingModalShow(false)}
      centered
      className="point-setting-modal-conatiner"
      data-testid="point-setting-modal-conatiner"
    >
      <Loader isHide={!isLoadingModalPointSetting} />
      <Modal.Header>
        <Modal.Title>贈點設定</Modal.Title>
        <div>
          <img
            src={iconModalClose}
            className="setting-img"
            alt=""
            onClick={() => handlePointSettingModalShow(false)}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} ref={formRef} role="form">
          <Form.Group className="mb-0">
            <Form.Row>
              <Form.Label>
                <div className="list-title">名稱：</div>
              </Form.Label>
              <Col>
                <Input
                  formControlOption={{
                    required: true,
                    name: 'name',
                    value: inputValues?.name || '',
                    onChange: onChangeField,
                    'aria-label': 'name',
                  }}
                  feedbackText={fieldsError?.name}
                />
              </Col>
            </Form.Row>
            <Form.Row className="mt-3">
              <Form.Label>
                <div className="list-title">類別：</div>
              </Form.Label>
              <Col>
                <Select
                  optionItems={handleSelectOption(modalData?.pointCategoryList)}
                  dropdownToggleOption={{
                    className: 'w-100',
                  }}
                  selectedValue={inputValues && inputValues?.pointCategory?.id}
                  formControlOption={{
                    required: false,
                    name: 'pointCategory',
                    'aria-label': 'pointCategory',
                    isInvalid: validated && !inputValues?.pointCategory,
                  }}
                  feedbackText={fieldsError?.pointCategory}
                  // 這邊的onChange並非對應到formControl的onChange，因此才需要另外設計成以下格式
                  onChange={(event, value, formControlRef, eventKey) => {
                    onChangeField({
                      target: formControlRef,
                      optionItems: modalData?.pointCategoryList,
                      eventKey,
                    })
                  }}
                />
              </Col>
            </Form.Row>
            <Form.Row className="mt-3">
              <Form.Label>
                <div className="list-title">效期：</div>
              </Form.Label>
              <Col>
                <Radio
                  id="rdForever"
                  value="forever"
                  name="validDate"
                  label="永久"
                  required
                  checked={pointSettingState?.validPeriod === 'forever'}
                  onChange={(e) => {
                    handleValidPeriodChange(e)
                    handleValidDateShow(false)
                  }}
                />

                <Radio
                  className="mt-3"
                  id="rdSelectDate"
                  name="validDate"
                  value="selectDate"
                  label="選擇日期"
                  required
                  inline
                  checked={pointSettingState?.validPeriod === 'selectDate'}
                  onChange={handleValidPeriodChange}
                />
                {pointSettingState?.validPeriod === 'selectDate' && (
                  <Input
                    className="input-valid-date-rage d-inline-flex"
                    prependContent={
                      <img src={iconCalendar} alt="icon-calendar" />
                    }
                    prependContentHaveBorder={false}
                    prependContentBgColor="transparent"
                    formControlOption={{
                      required: true,
                      'aria-label': 'choice-date',
                      value:
                        getDateRangeValue(
                          inputValues?.startDate,
                          inputValues?.endDate
                        ) || '',
                      onKeyDown(e) {
                        e.preventDefault()
                      },
                    }}
                    onClick={() => handleValidDateShow(true)}
                  />
                )}
                {validated &&
                  pointSettingState?.validPeriod !== 'forever' &&
                  !inputValues?.startDate &&
                  !inputValues?.endDate && (
                    <div className="custom-invalid-feedback">
                      {fieldsError?.validDate}
                    </div>
                  )}
                {pointSettingState?.validPeriod === 'selectDate' &&
                  pointSettingState?.isShowValidDate && (
                    <DateRange
                      locale={locales.zhTW}
                      onChange={(item) => {
                        setPointSettingDateRangeOption([item.selection])
                        setInputValues((prevState) => {
                          return {
                            ...prevState,
                            startDate: formatISO(
                              item?.selection?.startDate ?? new Date()
                            ),
                            endDate: formatISO(
                              item?.selection?.endDate ?? new Date()
                            ),
                          }
                        })
                      }}
                      showDateDisplay={false}
                      ranges={pointSettingDateRangeOption}
                      rangeColors={['#4B65A6']}
                      dateDisplayFormat="yyyy/MM/dd"
                    />
                  )}
              </Col>
            </Form.Row>
            <hr className="my-4" />
            <Form.Row>
              <Form.Label>
                <div className="list-title">對象：</div>
              </Form.Label>
              <Col className="position-relative">
                <Radio
                  id="rdNewMember"
                  name="memberObject"
                  value="forNewMember"
                  label="新會員"
                  checked={pointSettingState?.memberObject === 'forNewMember'}
                  onChange={(e) => {
                    handleMemberObjectChange(e)
                    setInputValues((prevState) => {
                      return {
                        ...prevState,
                        forNewMember: true,
                        forInitialMember: false,
                        memberLevels: handleCheckedItem(
                          isEdit
                            ? inputValuesData?.memberLevelList
                            : modalData?.memberLevelList
                        ),
                      }
                    })
                  }}
                />

                <Radio
                  className="mt-3"
                  id="rdDetermineLevel"
                  name="memberObject"
                  value="selectMember"
                  label="指定會級"
                  required
                  checked={pointSettingState?.memberObject === 'selectMember'}
                  onChange={(e) => {
                    handleMemberObjectChange(e)
                    setInputValues((prevState) => {
                      return {
                        ...prevState,
                        forNewMember: false,
                        memberLevelList: modalData?.memberLevelList,
                      }
                    })
                    setPointSettingState((prevState) => {
                      return {
                        ...prevState,
                        memberAllLevel: false,
                      }
                    })
                  }}
                />
                {pointSettingState?.memberObject === 'selectMember' && (
                  <div className="ml-4">
                    <Checkbox
                      className="margin-t-8 checkbox-member-level"
                      label="全部"
                      inline
                      required={
                        inputValues?.forInitialMember === false &&
                        inputValues?.memberLevels?.every(
                          (item) => item.selected === false
                        )
                      }
                      value="all"
                      name="memberLevels"
                      id="cbx_mbrObj_all"
                      checked={!!pointSettingState?.memberAllLevel}
                      onChange={(e) => {
                        onChangeField(e)
                      }}
                    />
                    <Checkbox
                      className="margin-t-8 checkbox-member-level"
                      label={
                        <>
                          <span>註冊會員</span>
                          <Tooltip
                            triggerElement={
                              <img
                                src={iconTooltip}
                                alt="for-initial-member-tooltip"
                              />
                            }
                            globalOption={{
                              effect: 'solid',
                            }}
                          >
                            註冊但未達 Lv1 之會員
                          </Tooltip>
                        </>
                      }
                      inline
                      required={
                        inputValues?.forInitialMember === false &&
                        inputValues?.memberLevels?.every(
                          (item) => item.selected === false
                        )
                      }
                      value={inputValues?.forInitialMember}
                      name="forInitialMember"
                      id="for-initial-member"
                      key="for-initial-member"
                      checked={inputValues?.forInitialMember === true}
                      onChange={(e) => {
                        onChangeField(e)
                      }}
                    />
                    {modalData?.memberLevelList &&
                      Array.isArray(modalData?.memberLevelList) &&
                      modalData?.memberLevelList.map((levelItem, i) => {
                        const { name, id } = levelItem
                        return (
                          <Checkbox
                            className="margin-t-8 checkbox-member-level"
                            label={name}
                            inline
                            required={
                              inputValues?.forInitialMember === false &&
                              inputValues?.memberLevels?.every(
                                (item) => item.selected === false
                              )
                            }
                            value={id}
                            name="memberLevels"
                            id={`cbx_mbrObj_${id}`}
                            key={`forMemberLevel-${i}`}
                            checked={handleChecked(
                              inputValues?.memberLevels,
                              id
                            )}
                            onChange={(e) => {
                              onChangeField({
                                target: e.target,
                                index: i,
                              })
                            }}
                          />
                        )
                      })}
                  </div>
                )}
                {handleMemberObjectFieldsError(
                  validated,
                  pointSettingState,
                  inputValues
                ) && (
                  <div className="custom-invalid-feedback">
                    {fieldsError?.memberObject}
                  </div>
                )}
              </Col>
            </Form.Row>
            <hr className="my-4" />
            <Form.Row>
              <Form.Label>
                <div className="list-title">條件：</div>
              </Form.Label>
              <Col className="d-flex flex-wrap justify-content-between">
                {detectIsGivePoint() && (
                  <div className="checkbox-member-birthday w-100 d-flex align-items-center mb-3">
                    <Checkbox
                      label="會員生日"
                      id="cbxMemberBirthday"
                      checked={!!pointSettingState?.memberBirthdayChecked}
                      onChange={handleMemberBirthdayCheckedChange}
                    />
                    <Select
                      className="margin-l-12 mr-auto"
                      optionItems={handleSelectOption(
                        modalData?.birthdayTypeList
                      )}
                      dropdownToggleOption={{
                        disabled: !pointSettingState?.memberBirthdayChecked,
                      }}
                      selectedValue={inputValues?.birthdayType?.id}
                      isClearSelectedValue={!inputValues?.birthdayType}
                      hideFeedbackText
                      formControlOption={{
                        required: false,
                        name: 'birthdayType',
                        isInvalid:
                          validated &&
                          pointSettingState?.memberBirthdayChecked &&
                          !inputValues?.birthdayType,
                      }}
                      // 這邊的onChange並非對應到formControl的onChange，因此才需要另外設計成以下格式
                      onChange={(event, value, formControlRef, eventKey) => {
                        onChangeField({
                          target: formControlRef,
                          optionItems: modalData?.birthdayTypeList,
                          eventKey,
                        })
                      }}
                    />
                  </div>
                )}
                <Checkbox
                  className={`w-100 ${
                    // 類別選擇贈點
                    detectIsGivePoint()
                      ? pointSettingState?.givePointDateChecked
                        ? // 勾選指定時間點
                          'mb-0'
                        : 'mb-3'
                      : 'mb-0'
                  }`}
                  label="指定時間點"
                  id="cbxGivePointDate"
                  checked={!!pointSettingState?.givePointDateChecked}
                  onChange={handleGivePointDateCheckedChange}
                />
                {pointSettingState?.givePointDateChecked && (
                  <div
                    className={`margin-l-20 w-100 ${
                      detectIsGivePoint() ? 'mb-2' : ''
                    }`}
                  >
                    {/* 暫時隱藏，未來視情況再打開 */}
                    {/* <StyleRadio
                      className="mt-2 w-100"
                      id="rdGivePointDaily"
                      name="groupGivePointDate"
                      value="daily"
                      label="每日"
                      required
                      checked={pointSettingState?.givePointDate === "daily"}
                      onChange={handleGivePointDateChange}
                    /> */}
                    <Radio
                      className="mt-3 margin-b-10"
                      id="rdGivePointDateWeekly"
                      name="groupGivePointDate"
                      value="weekly"
                      label="每週"
                      required
                      checked={pointSettingState?.givePointDate === 'weekly'}
                      onChange={handleGivePointDateChange}
                    />
                    {pointSettingState.givePointDate === 'weekly' && (
                      <Weekly
                        onChange={handleGivePointDateWeeklyOptionChange}
                        fieldName="weekly"
                        value={inputValues.weekly}
                      />
                    )}
                    <Radio
                      className="my-3"
                      label="每月，指定日期"
                      required
                      name="groupGivePointDate"
                      id="rdGivePointDateMonthlyDates"
                      value="monthlyDates"
                      checked={
                        pointSettingState?.givePointDate?.toLowerCase() ===
                        'monthlyDates'.toLowerCase()
                      }
                      onChange={handleGivePointDateChange}
                    />
                    {pointSettingState?.givePointDate?.toLowerCase() ===
                      'monthlyDates'.toLowerCase() && (
                      <div className="point-setting-assign-calendar-date-container">
                        <MonthlyDay
                          onChange={handleGivePointDateMonthlyDatesOptionChange}
                          fieldName="monthlyDay"
                          value={inputValues.monthlyDates}
                        />
                      </div>
                    )}
                    <Radio
                      className="my-3"
                      label="每月，指定週期"
                      required
                      name="groupGivePointDate"
                      id="rdGivePointDateMonthlyWeekdays"
                      value="monthlyDayOfWeek"
                      checked={
                        pointSettingState?.givePointDate?.toLowerCase() ===
                        'monthlyDayOfWeek'.toLowerCase()
                      }
                      onChange={handleGivePointDateChange}
                    />
                    {pointSettingState?.givePointDate?.toLowerCase() ===
                      'monthlyDayOfWeek'.toLowerCase() && (
                      <MonthlyWeek
                        onChange={(e, index, obj, type) =>
                          handleGivePointDateMonthlyWeekdaysOptionChange(
                            e,
                            index,
                            type
                          )
                        }
                        fieldName="monthlyDayOfWeek"
                        value={inputValues.monthlyDayOfWeek}
                      />
                    )}
                    <Radio
                      className="mt-3"
                      label="每年，指定日期"
                      required
                      name="groupGivePointDate"
                      id="rdGivePointDateYearlyDetermineDates"
                      value="yearlyDates"
                      onChange={handleGivePointDateChange}
                      checked={
                        pointSettingState.givePointDate?.toLowerCase() ===
                        'yearlyDates'.toLowerCase()
                      }
                    />
                    {pointSettingState.givePointDate?.toLowerCase() ===
                      'yearlyDates'.toLowerCase() && (
                      <YearlyDay
                        onChange={
                          handleGivePointDateYearlyDetermineDatesOptionChange
                        }
                        fieldName="yearlyDates"
                        value={{
                          dates:
                            pointSettingState
                              ?.givePointDateYearlyDetermineDatesOption?.dates,
                        }}
                      />
                    )}
                  </div>
                )}
                {detectIsGivePoint() && (
                  <div className="d-flex align-items-center">
                    <Checkbox
                      className="mr-0"
                      inline
                      checked={!!pointSettingState?.singleSpentChecked}
                      label="單筆消費滿 $"
                      id="cbxPerDealSpent"
                      onChange={handleSingleSpentChange}
                    />
                    <Input
                      className="counsume-amount ml-2 d-inline-block"
                      appendContent="元"
                      appendContentBgColor="#fff"
                      appendContentPadding="0 4px 0 0"
                      appendContentHaveBorder={false}
                      isFocusCocatAppend
                      formControlOption={{
                        required: true,
                        name: 'overSpentPerDealCond',
                        value: !pointSettingState?.singleSpentChecked
                          ? ''
                          : inputValues?.overSpentPerDealCond ?? '',
                        disabled: !pointSettingState?.singleSpentChecked,
                        onChange: onChangeField,
                        onInput: fieldRestrictNumberZero,
                      }}
                    />
                  </div>
                )}
                {handleConditionFieldsError(
                  validated,
                  pointSettingState,
                  inputValues
                ) && (
                  <div className="custom-invalid-feedback">
                    {fieldsError?.condition}
                  </div>
                )}
                {pointSettingState?.singleSpentChecked && (
                  <Checkbox
                    className="w-100 margin-l-20 mt-3"
                    label="消費金額不累送"
                    id="cbxNoAccumulate"
                    name="notSplit"
                    checked={inputValues?.notSplit ? true : false}
                    onChange={(e) => {
                      onChangeField(e)
                    }}
                  />
                )}
                {handleConditionFieldsError(
                  validated,
                  pointSettingState,
                  inputValues
                ) && (
                  <div className="custom-invalid-feedback">
                    {fieldsError?.condition}
                  </div>
                )}
              </Col>
            </Form.Row>
            {!detectIsNotGivePoint() && (
              <>
                <hr
                  className={`my-4 ${
                    inputValues?.pointCategory ? '' : 'd-none'
                  }`}
                />
                <Form.Row
                  className={`mt-3 ${
                    inputValues?.pointCategory ? '' : 'd-none'
                  }`}
                >
                  <Form.Label>
                    <div
                      className={`list-title 
                ${detectIsNotGivePoint() ? 'd-none' : ''}
                `}
                    >
                      內容：
                    </div>
                  </Form.Label>
                  <Col>
                    <>
                      <div
                        className={`radio-point-give-container justify-content-between align-items-center
                    ${detectIsNotGivePoint() ? 'd-none' : 'd-flex'}
                  `}
                      >
                        <Radio
                          id="rdPointMultiple"
                          name="groupGivePointContnt"
                          value="pointMultiple"
                          label="點數贈送"
                          required
                          onChange={handleGivePointContentChange}
                          checked={
                            pointSettingState?.givePointContnt ===
                            'pointMultiple'
                          }
                          inline
                        />
                        <Select
                          className="ptset-custom-select d-inline mr-auto"
                          optionItems={handleSelectOption(
                            modalData?.pointMultipleList
                          )}
                          dropdownToggleOption={{
                            disabled: !(
                              pointSettingState?.givePointContnt ===
                              'pointMultiple'
                            ),
                          }}
                          selectedValue={inputValues?.pointMultiple?.id}
                          isClearSelectedValue={!inputValues?.pointMultiple}
                          formControlOption={{
                            required: false,
                            name: 'pointMultiple',
                            'aria-label': 'pointMultiple',
                            isInvalid:
                              validated &&
                              pointSettingState?.givePointContnt ===
                                'pointMultiple' &&
                              !inputValues?.pointMultiple,
                          }}
                          // 這邊的onChange並非對應到formControl的onChange，因此才需要另外設計成以下格式
                          onChange={(
                            event,
                            value,
                            formControlRef,
                            eventKey
                          ) => {
                            onChangeField({
                              target: formControlRef,
                              optionItems: modalData?.pointMultipleList,
                              eventKey,
                            })
                          }}
                        />
                        <span className="white-space-nowrap fz15 mx-auto">
                          ，最多
                        </span>
                        <Input
                          appendContent="點"
                          appendContentBgColor="#fff"
                          appendContentPadding="0 4px 0 0"
                          appendContentHaveBorder={false}
                          isFocusCocatAppend
                          formControlOption={{
                            placeholder: '選填',
                            name: 'pointMultipleMaxResult',
                            disabled: !(
                              pointSettingState?.givePointContnt ===
                              'pointMultiple'
                            ),
                            value: inputValues?.pointMultipleMaxResult ?? '',
                            onChange: onChangeField,
                            onInput: fieldRestrictNumberZero,
                          }}
                        />
                      </div>
                      <div
                        className={`radio-point-multiple-give-container align-items-center justify-content-between my-2 
                    ${detectIsNotGivePoint() ? 'd-none' : 'd-flex'}
                  `}
                      >
                        <Radio
                          id="rdExtraPoint"
                          name="groupGivePointContnt"
                          value="extraPoint"
                          label="點數加贈"
                          required
                          onChange={handleGivePointContentChange}
                          checked={
                            pointSettingState?.givePointContnt === 'extraPoint'
                          }
                          inline
                        />
                        <Input
                          className="w-100"
                          appendContent="點"
                          appendContentBgColor="#fff"
                          appendContentPadding="0 4px 0 0"
                          appendContentHaveBorder={false}
                          isFocusCocatAppend
                          formControlOption={{
                            required: true,
                            name: 'additionalPoint',
                            'aria-label': 'additionalPoint',
                            disabled: !(
                              pointSettingState?.givePointContnt ===
                              'extraPoint'
                            ),
                            value: inputValues?.additionalPoint ?? '',
                            onChange: onChangeField,
                            onInput: fieldRestrictNumberZero,
                          }}
                        />
                      </div>
                      {/* 不贈點此項目暫時display:none，因為類別選不贈點這邊暫時不用顯示，但未來不排除有再出現的可能因此先隱藏 */}
                      <div className="radio-point-no-give-point-container d-none justify-content-between">
                        <Radio
                          id="rdNoPoint"
                          name="groupGivePointContnt"
                          value="noPoint"
                          label="不贈點"
                          required
                          onChange={handleGivePointContentChange}
                          checked={
                            pointSettingState?.givePointContnt === 'noPoint'
                          }
                          inline
                        />
                      </div>
                    </>
                    {handleContentFieldsError(
                      validated,
                      pointSettingState,
                      inputValues
                    ) && (
                      <div className="custom-invalid-feedback">
                        {fieldsError?.content}
                      </div>
                    )}
                  </Col>
                </Form.Row>
              </>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Container className="m-0">
          <Row className="m-0">
            <Col className="text-right">
              <Button
                variant="outline-gray"
                onClick={() => handlePointSettingModalShow(false)}
              >
                取消
              </Button>
            </Col>
            <Col>
              <Button
                onClick={(e, { handleButtonLocalLoading }) =>
                  handleSubmit(e, handleButtonLocalLoading)
                }
              >
                儲存
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </LocalStyle>
  )
}

export default PointSettingModal
