import { useRef, useState, useReducer, useContext } from 'react'
import { toast } from 'react-hot-toast'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { StyleRadio } from 'components/units/Radio'
import { StyleInput } from 'components/units/Input'
import { RootContext } from 'RootContext'
import { PointHistoryContext } from 'context/pageContext'
import { getCustomIdApi, additionalPointApi } from 'api/ApiMain'
import PointRecordModalStyle from './PointRecordModalStyle'
import { recordRootState, PointRecordReducer } from './PointRecordModalReducer'

/**
 * @description 補登點數 Modal
 * @param isShowModal 是否顯示 Modal
 * @param setIsShowRecordModal 設定是否顯示 Modal
 * @returns {JSX.Element}
 * @constructor
 */
export default function PointRecordModal({
  isShowModal = false,
  setIsShowRecordModal = () => {},
}) {
  const rootData = useContext(RootContext)
  const { getPointHistoryList } = useContext(PointHistoryContext)
  const [recordState, dispatch] = useReducer(
    PointRecordReducer,
    recordRootState
  )
  const [validated, setValidated] = useState(false)
  const formRef = useRef(null)

  async function onSubmit({ handleModalLoading }) {
    setValidated(true)
    const form = formRef.current
    if (form.checkValidity()) {
      handleModalLoading(true)
      await submitAdditionPoint()
      handleModalLoading(false)
    }
  }

  async function submitAdditionPoint() {
    try {
      const sendData = {
        brandId: rootData.brandId,
        customId: recordState.customId,
        description: recordState.description,
        forFreePoints: recordState.forFreePoints,
        forBucklePoint: recordState.forBucklePoint,
        pointGain: recordState.pointGain,
        additionalInstructions: recordState.additionalInstructions,
      }
      const respond = await additionalPointApi(sendData)

      const successStatus = 200
      if (respond?.data.code === successStatus) {
        toast.success('點數補登成功')
        onClosed()
        getPointHistoryList()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const updateFieldsDispatch = (keyName, value) => {
    dispatch({
      type: 'CHANGE_FIELD',
      payload: {
        keyName,
        value,
      },
    })
  }

  function onChangeFiled({ currentTarget: { name, value } }) {
    if (name === 'description') {
      updateFieldsDispatch(name, value)
    }

    const remarkMaxLimit = 50
    if (name === 'additionalInstructions' && value.length <= remarkMaxLimit) {
      updateFieldsDispatch(name, value)
    }
  }

  function onChangeCustomId({ currentTarget: { value } }) {
    updateFieldsDispatch('currentCustomId', value.trim())
  }

  function onChangePointGain({ currentTarget: { value } }) {
    const pointMaxLimit = 6
    // 限制輸入數字
    const regExpRules = /[^0-9]/g
    const isNumberValue = !regExpRules.test(value)
    if (value.length <= pointMaxLimit && isNumberValue) {
      dispatch({
        type: 'CHANGE_POINT_GAIN',
        payload: Number(value),
      })
    }
  }

  function onClosed() {
    setValidated(false)
    setIsShowRecordModal(false)
    updateShowCustomIdError(false)
    dispatch({
      type: 'CLEAR_FIELDS',
    })
  }

  const isDisabledQueryBtn = (recordState) => recordState.currentCustomId === ''

  async function onClickQuery() {
    try {
      const respond = await getCustomIdApi(
        recordState.currentCustomId,
        rootData.brandId
      )
      if (respond?.data?.data === null) {
        updateShowCustomIdError(true)
      } else {
        dispatch({
          type: 'UPDATE_MEMBER_INFO',
          payload: {
            customId: respond.data.data.customId,
            name: respond.data.data.name,
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  function updateShowCustomIdError(newState) {
    dispatch({
      type: 'UPDATE_CUSTOM_ID_ERROR',
      payload: newState,
    })
  }

  const renderPointGainVal = (pointGain) => {
    let point = pointGain
    // 當點數為 0 時，顯示空字串
    if (pointGain === 0) {
      point = ''
    }
    return point
  }

  function onChangePointType({ currentTarget: { id } }) {
    dispatch({
      type: 'UPDATE_POINT_TYPE',
      payload: id,
    })
  }
  const isShowMemberNameErrorMsg = (validated, recordState) => {
    return validated && recordState.customId === ''
  }
  const isShowPointTypeErrorMsg = (validated, recordState) => {
    let isShow = false
    if (validated) {
      isShow = !recordState.forFreePoints && !recordState.forBucklePoint
    }
    return isShow
  }

  const isShowNoMemberMsg = (recordState) => {
    return recordState.isShowCustomIdError && recordState.currentCustomId !== ''
  }
  return (
    <PointRecordModalStyle
      backdrop="static"
      className="point-record-modal"
      titleText="補登點數"
      confirmBtnText="儲存"
      closeBtnText="取消"
      show={isShowModal}
      onClose={onClosed}
      onConfirm={onSubmit}
    >
      <Form
        noValidate
        className="point-record-form text-left"
        validated={validated}
        ref={formRef}
      >
        <Form.Group className="d-flex mb-3">
          <Form.Label className="form-caption pt-1">查詢會員：</Form.Label>
          <div className="member-number-fields mr-2">
            <Form.Control
              required
              type="text"
              name="customId"
              onChange={onChangeCustomId}
              value={recordState.currentCustomId}
              placeholder="會員編號"
            />
            <Form.Control.Feedback type="invalid">
              請先查詢會員
            </Form.Control.Feedback>
            {isShowNoMemberMsg(recordState) ? (
              <div className="custom-invalid-feedback">查無此會員</div>
            ) : null}
          </div>
          <Button
            type="button"
            variant="outline-dark"
            disabled={isDisabledQueryBtn(recordState)}
            onClick={onClickQuery}
          >
            查詢
          </Button>
        </Form.Group>
        <Form.Group className="d-flex mb-3 flex-wrap member-name-field">
          <Form.Label className="form-caption pt-1">會員編號：</Form.Label>
          <Form.Control
            type="text"
            name="name"
            className="input-field col-9"
            value={recordState.customId}
            readOnly
          />
        </Form.Group>
        <Form.Group className="d-flex mb-3 flex-wrap member-name-field">
          <Form.Label className="form-caption pt-1">會員：</Form.Label>
          <Form.Control
            type="text"
            name="name"
            className="input-field col-9"
            value={recordState.name}
            readOnly
          />
          {isShowMemberNameErrorMsg(validated, recordState) ? (
            <div className="custom-invalid-feedback">尚未填寫</div>
          ) : null}
        </Form.Group>
        <hr className="divider" />
        <Form.Group className="d-flex mb-3">
          <Form.Label className="form-caption pt-1">名稱：</Form.Label>
          <div className="input-field-name">
            <Form.Control
              required
              type="text"
              name="description"
              className="input-field"
              placeholder="上限 50 字"
              onChange={onChangeFiled}
              maxLength={50}
              value={recordState.description}
            />
            <Form.Control.Feedback type="invalid">
              尚未填寫
            </Form.Control.Feedback>
          </div>
        </Form.Group>
        <Form.Group className="d-flex mb-3 flex-wrap point-type-field">
          <Form.Label className="form-caption pt-1">異動類別：</Form.Label>
          <div className="d-flex col-8 pl-0">
            <StyleRadio
              required
              id="forFreePoints"
              name="pointType"
              label="贈點"
              checked={recordState.forFreePoints}
              onChange={onChangePointType}
              inline
            />
            <StyleRadio
              required
              id="forBucklePoint"
              name="pointType"
              label="扣點"
              checked={recordState.forBucklePoint}
              onChange={onChangePointType}
              inline
            />
          </div>
          {isShowPointTypeErrorMsg(validated, recordState) ? (
            <div className="custom-invalid-feedback">尚未填寫</div>
          ) : null}
        </Form.Group>
        <Form.Group className="d-flex mb-3">
          <Form.Label className="form-caption pt-1">異動點數：</Form.Label>
          <StyleInput
            className="input-field-point"
            appendContent="點"
            appendContentBgColor="#fff"
            appendContentPadding="0 4px 0 0"
            appendContentHaveBorder={false}
            isFocusCocatAppend
            formControlOption={{
              required: true,
              name: 'pointGain',
              disabled: false,
              onChange: onChangePointGain,
              value: renderPointGainVal(recordState.pointGain),
              maxLength: 6,
            }}
            feedbackText="尚未填寫"
          />
        </Form.Group>
        <Form.Group className="d-flex mb-3">
          <Form.Label className="form-caption pt-1">補登說明：</Form.Label>
          <Form.Control
            as="textarea"
            className="input-field-textarea"
            placeholder="選填，上限 50 字"
            name="additionalInstructions"
            maxLength={50}
            onChange={onChangeFiled}
            value={recordState.additionalInstructions}
          />
        </Form.Group>
      </Form>
    </PointRecordModalStyle>
  )
}
