export const MODULE_NAME = {
  vipGift: '好禮序號',
  gift: '贈品序號',
  productList: '商品清單',
  giftList: '出貨名單',
  pointHistory: '點數歷程',
  qrCodeActivity: 'QRCode活動',
}

export const UPLOAD_STAGE = {
  PREPARE: 'preUpload',
  PROCESS: 'uploading',
  B2E_PROCESS: 'importing',
  COMPLETED: 'completed',
  CANCEL: 'cancel',
}

export const GENERATE_STAGE = {
  PREPARE: 'preGenerate',
  PROCESS: 'generating',
  COMPLETED: 'generated',
  CANCEL: 'cancelGenerate',
}
