import { SearchSection } from 'components/widgets'
import {
  GiftListSearchConfig,
  GiftListSearchInitError,
  GiftListSearchInitState,
} from 'helpers/searchFieldFormat'

function ListSearch({ onSearch, onReset }) {
  const handleSearch = (formValues) => {
    // 轉換為後端格式所需 key name
    const formValuesToApiKeyName = {
      startDate: 'searchStartTime',
      endDate: 'searchEndTime',
      phone: 'cellPhoneKeyword',
      itemName: 'giftNameKeyword',
      customId: 'customClientId',
    }
    const formValuesApi = Object.entries(formValues).reduce(
      (acc, [name, value]) => {
        const apiKeyName = formValuesToApiKeyName[name]
        return apiKeyName ? { ...acc, [apiKeyName]: value } : acc
      },
      {}
    )

    if (typeof onSearch === 'function') onSearch(formValuesApi)
  }

  const handleClearAllField = () => {
    if (typeof onReset === 'function') onReset()
  }
  return (
    <SearchSection
      fields={GiftListSearchConfig}
      initErrorMsg={GiftListSearchInitError}
      initState={GiftListSearchInitState}
      onSubmit={handleSearch}
      onReset={handleClearAllField}
    />
  )
}

export default ListSearch
