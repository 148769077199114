// 表單欄位中控制欄位的名稱
const formControlNames = {
  id: 'vendorId',
  name: 'vendorName',
}

// 表單欄位驗證規則不符合的提醒文字
const fieldRules = {
  common: '尚未填寫',
  [formControlNames.id]: {
    repeat: '廠商編號不可重複',
    pattern: '上限 20 字半形英文，不可重複',
  },
}
// 表單欄位中屬性的控制
const formControlPropsData = {
  [formControlNames.id]: {
    formControlOption: {
      name: formControlNames.id,
      placeholder: fieldRules[formControlNames.id].pattern,
      required: true,
      pattern: '[a-zA-Z]{1,20}',
      maxLength: 20,
      isInvalid: false,
    },
    feedbackText: fieldRules.common,
  },
  [formControlNames.name]: {
    formControlOption: {
      name: formControlNames.name,
      placeholder: '上限 20 字',
      required: true,
      maxLength: 20,
    },
    feedbackText: fieldRules.common,
  },
}

export { formControlNames, fieldRules, formControlPropsData }
