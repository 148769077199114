import { toast } from 'react-hot-toast'
import { getRandomId, toastErrorContent } from 'helpers/common'
import {
  useState,
  useEffect,
  createContext,
  useContext,
  useReducer,
  useCallback,
} from 'react'
import { useHistory, Redirect } from 'react-router'
import styled from 'styled-components'
import { Tabs, Tab } from 'react-bootstrap'
import {
  isAfter,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  format,
  isEqual,
} from 'date-fns'
import { Breadcrumb, Button, Modal, Loader } from 'components/units'
import { Basic, ContentSection } from 'components/templates'
import { SettingPage } from 'components/pages/ActivitiesCode'
import { settingPageType } from 'pages/ActivitiesCode/ProductActivity'
import { ReactComponent as IconCancel } from 'assets/images/button/icon_cancel.svg'
import { ReactComponent as IconSave } from 'assets/images/button/icon_save.svg'
import {
  getProductActivityEvent,
  getMemberList,
  addProductActivityEvent,
  updateProductActivityEvent,
  getPointproductrulemultiple,
} from 'api/ApiMain'
import { PAGE_PATHS } from 'constant'
import { color } from 'assets/styles/Variable/Color'
import {
  validMsg,
  initState,
  stateReducer,
  initValid,
  modalState,
} from 'components/pages/ActivitiesCode/ProductActivity/Sub/ProductActivityManagementConfig'
import { RootContext } from 'RootContext'
import { useDispatch, useSelector } from 'react-redux'
import {
  getGlobalState,
  updateDataLoading,
  updateMaskOpen,
} from 'store/global/globalSlice'
import { showToast } from 'api/ApiCommon'

const tabStatus = {
  content: 'content',
  editList: 'editList',
}

const SettingContext = createContext()
SettingContext.displayName = 'SettingContext'

const StyledSideSection = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  > button {
    min-width: auto !important; // ! 未來需要收斂按鈕狀態
    &:first-child {
      margin-right: 1rem;
    }
  }
`

const StyledContentSection = styled(ContentSection)`
  .tab-content {
    padding: 24px;
  }
`

function NavSection() {
  const { history, openModal } = useContext(SettingContext)
  const { type } = history.location.state
  const now = {
    [settingPageType.new]: '新增商品活動',
    [settingPageType.view]: '檢視商品活動',
    [settingPageType.edit]: '編輯商品活動',
  }

  const BreadcrumbConfig = {
    now: now[type],
    pathList: [
      { title: '活動條碼', slash: true },
      {
        title: '商品活動',
        onClick: openModal,
      },
    ],
  }
  return <Breadcrumb {...BreadcrumbConfig} />
}

function SideSection() {
  const { history, submit, openModal, exitPage, pageType } =
    useContext(SettingContext)
  const { type } = history.location.state
  const showSaveTypes = [settingPageType.new, settingPageType.edit]
  const handleSubmit = async (e, { handleButtonLocalLoading }) => {
    handleButtonLocalLoading(true)
    await submit()
    handleButtonLocalLoading(false)
  }

  return (
    <StyledSideSection>
      {pageType === tabStatus.content &&
      showSaveTypes.some((i) => i === type) ? (
        <>
          <Button variant="outline-gray" onClick={openModal}>
            <IconCancel />
            取消
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleSubmit}
            loaderOption={{
              bgColor: 'transparent',
              colorType: '#fff',
            }}
          >
            <IconSave />
            儲存
          </Button>
        </>
      ) : (
        <Button variant="outline-gray" onClick={exitPage}>
          返回列表
        </Button>
      )}
    </StyledSideSection>
  )
}

const isEmptyValue = new RegExp('^s*$')
const errorMsg = ({
  value,
  isRequired = true,
  type = '',
  maxCount = 1000000,
}) => {
  const isEmptyValue = new RegExp('^s+||null$')
  if (isRequired && isEmptyValue.test(value)) return validMsg.empty

  let errorMsg = ''
  switch (type) {
    case 'uploadCount':
      if (Number(value) > maxCount)
        errorMsg = `不可高於 ${maxCount / 10000} 萬組`
      break

    default:
      break
  }

  return errorMsg
}

const startDateErrorMsg = ({ startDate, today, immediate, permanent }) => {
  if (isEmptyValue.test(permanent)) return validMsg.empty
  if (
    !immediate &&
    !permanent &&
    isAfter(today, startDate) &&
    // startDate不可為今日
    !isEqual(
      new Date(format(startDate, 'MM/dd/yyyy')),
      new Date(format(today, 'MM/dd/yyyy'))
    )
  )
    return '效期起始日不可早於今日'
}

const endDateErrorMsg = ({ startDate, endDate, immediate, permanent }) => {
  // TODO 兌換時間以及領取時間的交互驗證
  if (isEmptyValue.test(permanent)) return validMsg.empty
  if (!immediate && !permanent && isAfter(startDate, endDate))
    return '不可早於效期起始日'
}

const validate = ({ rule, value }) => new RegExp(rule).test(value)

const handleTargetValid = ({ rule, target }) => {
  const { forNewMember, forInitialMember, memberLevels } = target
  const result = validate({ rule, value: forNewMember })
  if (!result) return false
  if (forNewMember) return true
  return forInitialMember || memberLevels.some((i) => i.selected)
}

const handleStartDate = ({ startDate, today, immediate, permanent }) => {
  if (isEmptyValue.test(permanent)) return false
  if (
    !immediate &&
    !permanent &&
    isAfter(today, startDate) &&
    // startDate不可為今日
    !isEqual(
      new Date(format(startDate, 'MM/dd/yyyy')),
      new Date(format(today, 'MM/dd/yyyy'))
    )
  )
    return false
  return true
}

const handleEndDate = ({ startDate, endDate, immediate, permanent }) => {
  // TODO 兌換時間以及領取時間的交互驗證
  if (isEmptyValue.test(permanent)) return false
  if (!immediate && !permanent && isAfter(startDate, endDate)) return false
  return true
}

const handleContent = (dataInfo) => {
  let hasValue = false
  if (
    dataInfo.pointProductMultipleCheck ||
    dataInfo.isNoGivingPoint ||
    (dataInfo.additionalPointCheck && dataInfo.additionalPoint)
  ) {
    hasValue = true
  } else {
    hasValue = false
  }

  return hasValue
}

const handleValid = ({ data, updateFn }) => {
  const rule = {
    name: '^.+$',
    target: '^(true|false)$',
    startDate: '^(true|false)$',
    endDate: '^(true|false)$',
    content: '^(true|false)$',
  }
  return new Promise((resolve) => {
    updateFn((validState) => {
      const result = JSON.parse(JSON.stringify(validState))

      for (const item in validState) {
        if (validState[item]?.required) {
          switch (item) {
            case 'name':
              result[item] = {
                ...result[item],
                isValid: validate({ rule: rule[item], value: data[item] }),
                msg: errorMsg({ value: data[item], type: item }),
              }
              break

            case 'target':
              result[item] = {
                ...result[item],
                isValid: handleTargetValid({
                  rule: rule[item],
                  target: data.target,
                }),
              }
              break
            case 'startDate':
              result[item] = {
                ...result[item],
                isValid: handleStartDate({
                  startDate: data[item].calendar,
                  today: new Date(),
                  permanent: data[item].check,
                }),
                msg: startDateErrorMsg({
                  startDate: data[item].calendar,
                  today: new Date(),
                  permanent: data[item].check,
                }),
              }
              break
            case 'endDate':
              result[item] = {
                ...result[item],
                isValid: handleEndDate({
                  startDate: data.startDate.calendar,
                  endDate: data[item].calendar,
                  immediate: data.startDate.check,
                  permanent: data[item].check,
                }),
                msg: endDateErrorMsg({
                  startDate: data.startDate.calendar,
                  endDate: data[item].calendar,
                  immediate: data.startDate.check,
                  permanent: data[item].check,
                }),
              }
              break
            case 'content':
              result[item] = {
                ...result[item],
                isValid: handleContent({
                  pointProductMultipleCheck:
                    data[item].usingPointMultiple.check,
                  additionalPointCheck: data[item].usingAdditionalPoint.check,
                  additionalPoint: data[item].usingAdditionalPoint.value,
                  isNoGivingPoint: data[item].isNoGivingPoint,
                }),
                msg: errorMsg({ value: data[item].check, type: item }),
              }

              break
            default:
              break
          }
        } else {
          switch (item) {
            default:
              break
          }
        }
      }

      const requiredValiddResult = Object.keys(result)
        .filter((item) => result[item].required)
        .every((item) => result[item].isValid)

      const nonRequiredValiddResult = Object.keys(result)
        .filter((item) => !result[item].required)
        .every(
          (item) => result[item].isValid || result[item].isValid === null,
          Object.keys(result).filter((item) => !result[item].required)
        )
      resolve(requiredValiddResult && nonRequiredValiddResult)
      return { ...result }
    })
  })
}

const composeApiPaylaod = (data) => {
  const pointProductMultiplePos = data.content.pointProductMultipleList
    .map((item) => item.id)
    .indexOf(data.content.usingPointMultiple.value)
  const memberLevelsBeSelected = data.target.memberLevels
    .filter((i) => i.selected)
    .map((i) => {
      return {
        id: i.id,
        code: i.code,
        name: i.name,
      }
    })

  const formatHMS = (date) => {
    let formatDate = date

    formatDate = setMilliseconds(formatDate, 0)
    formatDate = setSeconds(formatDate, 0)
    formatDate = setMinutes(formatDate, 0)
    formatDate = setHours(formatDate, 0)

    return format(formatDate, "yyyy-MM-dd'T'HH:mm:ss'+08:00'")
  }

  const bodyFormData = {
    name: data.name,
    startDate: !data.startDate.check
      ? formatHMS(data.startDate.calendar)
      : formatHMS(new Date()),
    endDate: !data.endDate.check ? formatHMS(data.endDate.calendar) : null,
    forNewMember: !!data.target.forNewMember,
    forInitialMember: !!data.target.forInitialMember,
    memberLevels: data.target.memberLevels
      ? memberLevelsBeSelected.length > 0
        ? memberLevelsBeSelected
        : null
      : null,
    pointProductMultiple: data.content.usingPointMultiple.check
      ? data.content.pointProductMultipleList[pointProductMultiplePos]
      : null,
    additionalPoint: data.content.usingAdditionalPoint.check
      ? data.content.usingAdditionalPoint.value
      : null,
    notSplit: data.content.usingNotSplit ? data.content.usingNotSplit : false,
  }

  return pointProductMultiplePos !== -1 ? bodyFormData : ''
}

const handleApiError = ({ error, updateFn }) => {
  const { errors } = error
  const config = {
    Description: [
      {
        type: '贈品介紹超過 1000 字',
        attr: 'giftInfo',
        msg: validMsg.giftInfo,
      },
    ],
  }

  for (const type in errors) {
    for (const errorMsg of errors[type]) {
      if (
        config[type] === undefined ||
        config[type].findIndex((i) => i.type === errorMsg) === -1
      )
        break

      const { msg, attr } = config[type].find((i) => i.type === errorMsg)
      updateFn((prevState) => ({
        ...prevState,
        [attr]: {
          ...prevState[attr],
          isValid: false,
          msg,
        },
      }))
    }
  }
}

function ProductActivityManagementEntry() {
  const dispatch = useDispatch()
  const { isDataLoading } = useSelector(getGlobalState)
  const rootData = useContext(RootContext)
  const history = useHistory()
  const { state } = history.location
  const { apiPayload } = history.location.state
  const { type } = state
  const [modal, setModal] = useState(false)
  const [modalInfo, setModalInfo] = useState('')
  const [data, setData] = useState()
  const [page, setPage] = useState(tabStatus.content)
  const [infoState, infoDispatch] = useReducer(stateReducer, initState)
  const [infoValidStatus, setValidStatus] = useState(initValid)
  const [settingId, setSettingId] = useState(null)

  const tabConfig = [
    {
      eventKey: tabStatus.content,
      title: '活動內容',
      component: <SettingPage.ProductActivityManagement />,
    },
    {
      eventKey: tabStatus.editList,
      title: '商品清單',
      component: <SettingPage.ProductList />,
    },
  ]
  const openModal = () => handleOpenModal()
  const exitPage = useCallback(
    () =>
      history.push(PAGE_PATHS.activitiesCode.productActivity, { apiPayload }),
    [history, apiPayload]
  )

  const execute = (isSwitchPage) => {
    const payload = composeApiPaylaod(infoState)
    // call API: 新增商品活動
    if (type === settingPageType.new && !settingId) {
      return addProductActivityEvent(apiPayload.brandId, payload).then(
        (rsp) => {
          showToast(rsp, '新增商品活動成功')
          if (isSwitchPage === true) {
            handleCloseModal()
            setPage(tabStatus.editList)
            setSettingId(rsp?.data?.data?.pointProductRuleId)
          } else {
            exitPage()
          }
        }
      )
    }
    // call API: 修改商品活動
    // openModal
    return updateProductActivityEvent(
      apiPayload.brandId,
      apiPayload.pointProductRuleId || settingId,
      payload
    )
      .then((rsp) => {
        showToast(rsp, '修改商品活動成功')
        if (isSwitchPage === true) {
          handleCloseModal()
          setPage(tabStatus.editList)
        } else {
          exitPage()
        }
      })
      .catch((rsp) => {
        const { code } = rsp
        handleCloseModal()
        if (code === 400 && rsp?.errors) {
          Object.entries(rsp.errors).some(([errorFieldName, errorMsgArray]) => {
            if (Array.isArray(errorMsgArray)) {
              const isStartDateError =
                errorFieldName.toLowerCase() === 'startDate'
              const errorMsg = errorMsgArray.find(Boolean)
              if (errorMsg) {
                showToast(
                  rsp,
                  `${isStartDateError ? '起始日' : errorFieldName}${errorMsg}`
                )
                return true // Stop iterating once the first error is found
              }
            }
            return false
          })
        }
      })
  }

  const submit = async (isSwitchPage) => {
    const result = await handleValid({
      data: infoState,
      updateFn: setValidStatus,
    })
    if (!result) return handleCloseModal()
    try {
      dispatch(updateDataLoading(true))
      await execute(isSwitchPage)
    } catch (error) {
      if (error.code === 400 && error.msg === null)
        handleApiError({ error, updateFn: setValidStatus })
      if (error.code >= 400) return
    } finally {
      dispatch(updateDataLoading(false))
    }
  }

  const handleCloseModal = () => {
    setModal(false)
    setModalInfo('')
  }

  const handleOpenModal = (info) => {
    if (!info) {
      setModalInfo({ ...modalState.new, type: 'modifyCancel' })
    } else {
      setModalInfo({ ...info, type: 'switchPage' })
    }

    setModal(true)
  }

  const switchPage = (info) => {
    if (info === 'editList' && type !== 'view') {
      handleOpenModal(modalState.edit)
    } else {
      setPage(tabStatus[info])
    }
  }

  const handleConfirmModal = async ({ handleModalLoading }) => {
    if (modalInfo.type === 'modifyCancel') {
      exitPage()
    }

    if (modalInfo.type === 'switchPage') {
      handleModalLoading(true)
      await submit(true)
      handleModalLoading(false)
    }
  }

  useEffect(() => {
    const fetchMemberList = async () => {
      try {
        const res = await getMemberList(apiPayload.brandId)
        const memberLevelItemList = res?.data?.data?.memberLevelItemList ?? []

        if (type === settingPageType.new) {
          const res2 = await getPointproductrulemultiple(apiPayload.brandId)
          setData((prev) => ({
            ...prev,
            memberLevelItemList,
            pointProductMultiple: res2.data.data.pointProductMultiple,
          }))
        } else {
          const res3 = await getProductActivityEvent(
            apiPayload.brandId,
            apiPayload.pointProductRuleId
          )
          setData({ ...res3.data.data, memberLevelItemList })
        }
      } catch (error) {
        // 處理錯誤情況
      } finally {
        dispatch(updateDataLoading(false))
      }
    }
    const { type, apiPayload } = state
    if (type) {
      dispatch(updateDataLoading(true))
      fetchMemberList()
    }
  }, [state, history.location.pathname])

  useEffect(() => {
    if (rootData.brandId && rootData.brandId !== apiPayload.brandId) exitPage()
  }, [rootData.brandId, apiPayload.brandId, exitPage])

  if (state === undefined)
    return <Redirect to={PAGE_PATHS.activitiesCode.productActivity} />

  return (
    <SettingContext.Provider
      value={{
        history,
        defaultData: data,
        submit,
        openModal,
        exitPage,
        viewType: type,
        pageType: page,
        infoState: [infoState, infoDispatch],
        infoValid: [infoValidStatus, setValidStatus],
        setMask: (bool) => dispatch(updateMaskOpen(bool)),
      }}
    >
      <Basic navSection={NavSection} sideSection={SideSection}>
        <StyledContentSection>
          <Tabs activeKey={page} onSelect={(info) => switchPage(info)}>
            {tabConfig.map((tab, idx) => (
              <Tab eventKey={tab.eventKey} title={tab.title} key={idx}>
                <Loader isHide={!isDataLoading} />
                {tab.component}
              </Tab>
            ))}
          </Tabs>
        </StyledContentSection>
      </Basic>
      <Modal
        show={modal}
        onClose={() => handleCloseModal()}
        onConfirm={handleConfirmModal}
        variant={color[modalInfo?.btnColor]}
        titleText={modalInfo?.titleText}
        confirmBtnText={modalInfo?.confirmBtnText}
        closeBtnText={modalInfo?.closeBtnText}
      >
        {modalInfo?.text}
      </Modal>
    </SettingContext.Provider>
  )
}

export default ProductActivityManagementEntry
export { SettingContext }
