// 列表的分頁設定
const paginationOptions = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

// 列表的操作按鈕
const FUNCTBUTTON = {
  EDIT: 'edit',
  DELETE: 'delete',
  STOP: 'stop',
  ACTIVATE: 'activate',
}
// 頁面種類
const PAGE_TYPE = {
  new: 'new',
  edit: 'edit',
}

// 錯誤訊息
const TOAST = {
  SUCCESS: 'success',
  ERROR: 'error',
  LOADING: 'loading',
}

// 月份選擇
const monthOptions = [
  { value: 1, text: '1月' },
  { value: 2, text: '2月' },
  { value: 3, text: '3月' },
  { value: 4, text: '4月' },
  { value: 5, text: '5月' },
  { value: 6, text: '6月' },
  { value: 7, text: '7月' },
  { value: 8, text: '8月' },
  { value: 9, text: '9月' },
  { value: 10, text: '10月' },
  { value: 11, text: '11月' },
  { value: 12, text: '12月' },
]

export { paginationOptions, FUNCTBUTTON, PAGE_TYPE, TOAST, monthOptions }
export default paginationOptions
