import styled, { css } from 'styled-components'
import FormControl from 'react-bootstrap/FormControl'
import { colorObj, method } from 'assets/styles/Variable/Color'

const { colorHandle } = method

// Textarea 共用樣式
const textareaCommonStyles = ({ colorType, colorAlpha }) => {
  return css`
    min-height: 32px;
    color: ${colorType
      ? colorHandle(colorType, colorObj, colorAlpha) || colorObj.dark
      : 'inherit'};
    resize: none;

    &:focus {
      color: ${colorType
        ? colorHandle(colorType, colorObj, colorAlpha) || colorObj.dark
        : 'inherit'};
    }
  `
}
// Textarea 基礎樣式
const textareaBaseStyles = ({ colorType, colorAlpha }) => {
  return css`
    ${textareaCommonStyles({ colorType, colorAlpha })};
  `
}

const StyleTextarea = styled((props) => {
  const {
    colorType, // eslint-disable-line no-unused-vars
    colorAlpha, // eslint-disable-line no-unused-vars
    feedbackText,
    feedbackTooltip,
    children, // eslint-disable-line no-unused-vars
    ...prop
  } = props
  return (
    <div className="style-textarea-container position-relative">
      <FormControl {...prop} as="textarea" />
      <FormControl.Feedback
        type="invalid"
        className="px-2"
        tooltip={feedbackTooltip || false}
      >
        {feedbackText}
      </FormControl.Feedback>
      <FormControl.Feedback
        type="valid"
        className="px-2"
        tooltip={feedbackTooltip || false}
      >
        {feedbackText}
      </FormControl.Feedback>
    </div>
  )
})`
  ${(props) => textareaBaseStyles(props)};
`

export { StyleTextarea }
