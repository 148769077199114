import PointDetailModalStyle from './PointDetailModalStyle'

/**
 * @description 點數詳情 Modal
 * @param isShowModal 是否顯示 Modal
 * @param pointDetailModalInfo 點數詳情資訊
 * @param closePointDetailModal 關閉 Modal
 * @returns {JSX.Element}
 * @constructor
 */
export default function PointDetailModal({
  isShowModal = false,
  pointDetailModalInfo = {},
  closePointDetailModal = () => {},
  isDefaultDataLoading,
}) {
  const renderRowClassName = (rowIndex) => {
    let className = `d-flex detail-row`

    // rowIndex 2 跟 6 加上分隔線 class name
    if (rowIndex === 2 || rowIndex === 6) {
      className += ` separate-line`
    }

    return className
  }

  const fieldOrder = [
    { field: 'createDateTime', name: '時間' },
    { field: 'customId', name: '會員編號' },
    { field: 'name', name: '會員' },
    { field: 'description', name: '名稱' },
    { field: 'transactionCategory', name: '異動類別' },
    { field: 'pointGain', name: '異動點數' },
    { field: 'shopName', name: '消費門店' },
    { field: 'creatorEmail', name: '補登者' },
    { field: 'additionalInstructions', name: '補登說明' },
  ]

  const renderPointInfoText = (keyName, value) => {
    let text = value

    if (keyName === 'pointGain' && value < 0) {
      text = value.toString().replace('-', '')
    }

    return text
  }

  return (
    <PointDetailModalStyle
      backdrop="static"
      className="point-detail-modal"
      titleText="點數詳情"
      closeBtnText="關閉"
      show={isShowModal}
      onClose={closePointDetailModal}
      customConfirm={() => <></>}
      isDefaultDataLoading={isDefaultDataLoading}
    >
      <div className="text-left">
        {fieldOrder.map(({ field, name }, index) => {
          return pointDetailModalInfo.hasOwnProperty(field) ? (
            <div key={index} className={renderRowClassName(index)}>
              <span className="detail-title">{name}：</span>
              <span className="detail-text">
                {renderPointInfoText(field, pointDetailModalInfo[field])}
              </span>
            </div>
          ) : (
            ''
          )
        })}
      </div>
    </PointDetailModalStyle>
  )
}
