import { useCallback, useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import { useDispatch } from 'react-redux'
import {
  getQrcodePointRecord,
  getQrcodePointRecordFieldInfo,
} from 'api/ApiMain'
import { NoDataIndication, Table } from 'components/units'
import PaginationInput from 'components/units/PaginationInput'
import {
  ActivityFormRecordListContext,
  TabSectionContext,
} from 'context/pageContext'
import * as format from 'helpers/format'
import { dateFormatter, ellipsisFormatter } from 'helpers/tableElFormat'
import { RootContext } from 'RootContext'
import { updatePageLoading } from 'store/global/globalSlice'
import { searchSectionSetting } from '../ActivityFormRecordListConfig'

const { defaultSort } = searchSectionSetting

// 表格中內容過長要...的處理
const descriptionFormatter = (cell, row) => {
  const { prodcutBarCode, prodcutName } = row
  const itemName = <div>{ellipsisFormatter(cell, 2)}</div>
  const itemExtraDescription = prodcutBarCode && prodcutName && (
    <div className="extra-description mt-1">
      {ellipsisFormatter(
        `${prodcutBarCode + (prodcutName ? ` | ${prodcutName}` : '')}`,
        1
      )}
    </div>
  )
  return (
    <>
      {itemName}
      {itemExtraDescription}
    </>
  )
}

// 表格的column設定
const columns = (optionalTableColumns) => {
  let data = [
    {
      dataField: 'createdTime',
      text: '日期',
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: 'customId',
      text: '會員編號',
    },
    {
      dataField: 'memberName',
      text: '會員',
    },
    {
      dataField: 'name',
      text: '名稱',
      formatter: descriptionFormatter,
      style: {
        maxWidth: '275px',
      },
    },
  ]
  if (Array.isArray(optionalTableColumns)) {
    data = data.concat(optionalTableColumns)
  }
  return data
}

function GetPointSuccess() {
  const dispatch = useDispatch()
  const rootData = useContext(RootContext)
  const activityFormRecordListContext = useContext(
    ActivityFormRecordListContext
  )
  const {
    apiPayload,
    setApiPayload,
    tableRow,
    setTableRow,
    setTotalSize,
    optionalTableColumns,
    setOptionalTableColumns,
  } = activityFormRecordListContext
  const tabSectionContext = useContext(TabSectionContext)
  const { paginationProps, paginationTableProps } = tabSectionContext
  const [tableLoading, setTableLoading] = useState(false)

  // table有remote屬性時，需要設定此事件來處理表格變化
  const handleTableChange = (type, data) => {
    if (Array.isArray(tableRow) && tableRow?.length > 0 && type === 'sort') {
      const { sortField, sortOrder } = data
      setApiPayload((prevState) => {
        return {
          ...prevState,
          sf: sortField,
          so: sortOrder,
        }
      })
    }
  }

  const handleGetQrcodePointRecord = useCallback(async () => {
    const brandId = rootData?.brandId
    if (brandId) {
      setTableRow([])
      setTableLoading(true)
      try {
        const qrcodeScanSuccessOptionalFieldData =
          await getQrcodePointRecordFieldInfo({
            brandId,
          })

        const qrcodeScanSuccessData = await getQrcodePointRecord({
          brandId,
          ...apiPayload,
        })

        const deepCloneRows = JSON.parse(
          JSON.stringify(qrcodeScanSuccessData.rows || [])
        )

        const fieldsLength = qrcodeScanSuccessOptionalFieldData?.fields?.length
        if (fieldsLength) {
          /* 設定table彈性欄位的header */
          const optionalColumns = []
          for (
            let i = 0;
            i < qrcodeScanSuccessOptionalFieldData.fields.length;
            i += 1
          ) {
            const item = qrcodeScanSuccessOptionalFieldData.fields[i]
            const { dataField, sort, text } = item
            optionalColumns.push({
              dataField,
              text,
              sort,
            })
          }

          /* 設定table彈性欄位的body所需的data */
          deepCloneRows.forEach((rowItem, rowIndex) => {
            const obj = {}
            rowItem.fields.forEach((item) => {
              const { dataField, value } = item
              obj[dataField] = value
            })
            deepCloneRows[rowIndex] = { ...rowItem, ...obj }
          })

          setOptionalTableColumns(optionalColumns)
        }
        setTableRow(deepCloneRows)
        setTotalSize(qrcodeScanSuccessData.totalSize)
      } catch {
        setTableRow([])
        setTotalSize(0)
      } finally {
        dispatch(updatePageLoading(false))
        setTableLoading(false)
      }
    }
  }, [
    rootData?.brandId,
    apiPayload,
    dispatch,
    setTableRow,
    setTotalSize,
    setOptionalTableColumns,
  ])

  useEffect(() => {
    handleGetQrcodePointRecord()
  }, [handleGetQrcodePointRecord])

  return (
    <>
      <Caption paginationProps={paginationProps} />
      <Table
        headerClasses="table-header"
        bodyClasses="paragraph"
        className="overflow-auto mt-3"
        keyField="id"
        data={tableRow}
        columns={columns(optionalTableColumns)}
        striped
        noDataIndication={
          <NoDataIndication
            isLoading={tableLoading}
            message={tableLoading ? '載入中...' : '尚無資料'}
          />
        }
        remote
        onTableChange={handleTableChange}
        defaultSorted={[
          {
            dataField: defaultSort.sf,
            order: defaultSort.so,
          },
        ]}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...paginationTableProps}
      />
      <div className="d-flex justify-content-center">
        {
          // eslint-disable-next-line react/jsx-props-no-spreading
          <PaginationListStandalone {...paginationProps} />
        }
        <PaginationInput
          onPageChange={paginationProps.onPageChange}
          page={paginationProps.page}
          sizePerPage={paginationProps.sizePerPage}
          totalSize={paginationProps.totalSize}
        />
      </div>
    </>
  )
}

/* -------------------------------- 
  Caption 區塊
-----------------------------------*/
function Caption() {
  const tabSectionContext = useContext(TabSectionContext)
  const { paginationProps } = tabSectionContext

  return (
    <Row className="caption my-0">
      <Col className="label-title">
        <div>
          總項目：
          <span className="paragraph-info">
            {format.number(paginationProps.totalSize)} 筆
          </span>
        </div>
      </Col>
      <Col className="label-title d-flex justify-content-end">
        <span className="white-space-nowrap mr-1">每頁</span>
        <SizePerPageDropdownStandalone
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
      </Col>
    </Row>
  )
}

export default GetPointSuccess
