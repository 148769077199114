import { Fragment } from 'react'
import { ReactComponent as IconTooltip } from 'assets/images/table/icon_table_notice.svg'
import { IconButton } from 'components/units'
import { colorObj, hexToRgbA } from 'assets/styles/Variable/Color'
import {
  dateFormatter,
  ellipsisFormatter,
  numberFormatter,
  formatTextLeftStyle,
  formatNumberLeftStyle,
  statusFormatter,
} from 'helpers/tableElFormat'
import { deleteQrcode } from 'api/ApiMain'
import { COMPONENT_PERMISSIONS, PAGE_PATHS } from 'constant'
import { modalType } from './QrcodeActivityListConfig'
import { tabOption } from '../QrcodeActivityContentConfig'
import withPermission from 'components/hocs/withPermission'
import { qrcodeActivityListStatus } from 'components/pages/ActivitiesCode/QrcodeActivity/SerialNumber/SerialNumberConfig'

const handleEdit = async (
  history,
  settingPageType,
  {
    qrCodeActivitySettingId,
    status: qrcodeActivityListStatus,
    hasQRCodeGainPointRule,
  },
  handleGetQrcodeOnce
) => {
  const { brandId } = history.location.state
  const qrcodeOnceData = await handleGetQrcodeOnce(qrCodeActivitySettingId)
  history.push({
    pathname: PAGE_PATHS.activitiesCode.qrcodeActivityContent,
    state: {
      pageType: settingPageType.edit,
      qrCodeActivitySettingId,
      qrcodeActivityListStatus,
      hasQRCodeGainPointRule,
      qrcodeOnceData,
      brandId,
    },
  })
}
const handleView = async (
  history,
  settingPageType,
  { qrCodeActivitySettingId, status: qrcodeActivityListStatus },
  handleGetQrcodeOnce
) => {
  const qrcodeOnceData = await handleGetQrcodeOnce(qrCodeActivitySettingId)
  history.push({
    pathname: PAGE_PATHS.activitiesCode.qrcodeActivityContent,
    state: {
      ...history.location.state,
      pageType: settingPageType.view,
      qrCodeActivitySettingId,
      qrcodeActivityListStatus,
      qrcodeOnceData,
    },
  })
}
const handleDelete = async (
  brandId,
  { qrCodeActivitySettingId },
  handleGetQrcode,
  handleTableLoading,
  setQrcodeListData
) => {
  try {
    handleTableLoading(true)
    setQrcodeListData([])
    await deleteQrcode(brandId, qrCodeActivitySettingId)
  } finally {
    handleGetQrcode()
  }
}
const handleStop = (handleOpenModal, rowIndex, stopQrcodeRowIndexRef) => {
  stopQrcodeRowIndexRef.current = rowIndex
  handleOpenModal(modalType.stop)
}

// 表格的日期區間內容
const dateRangeFormatter = (cell) => {
  return (
    <>
      {cell?.endDate ? (
        <>
          {dateFormatter(cell?.startDate)} ~ {dateFormatter(cell?.endDate)}
        </>
      ) : (
        <>{dateFormatter(cell?.startDate)} 起</>
      )}
    </>
  )
}

// 表格的點數內容
const pointFormatter = (
  cell,
  row,
  rowIndex,
  { history, settingPageType, hasEditPermission }
) => {
  const { hasQRCodeGainPointRule, qrCodeGainPointRuleNotice } = row

  return (
    <div>
      <span className={`${cell < 0 ? 'font-danger' : ''}`}>
        {numberFormatter(cell)}
      </span>{' '}
      點
      {hasQRCodeGainPointRule && (
        <div className="d-flex align-items-center justify-content-end">
          <span
            className="link-container"
            onClick={() => {
              history.push({
                pathname: PAGE_PATHS.activitiesCode.qrcodeActivityContent,
                search: `?tabEventKey=${tabOption.extraPoint.eventKey}`,
                state: {
                  qrCodeActivitySettingId: row.qrCodeActivitySettingId,
                  qrcodeActivityListStatus: row.status,
                  pageType:
                    // 狀態為：準備中
                    row.status === 0
                      ? // 有編輯、刪除權限
                        hasEditPermission
                        ? settingPageType.edit
                        : settingPageType.view
                      : settingPageType.view,
                },
              })
            }}
          >
            額外贈點
          </span>
          {qrCodeGainPointRuleNotice && (
            <IconTooltip className="icon-extra-point ml-1" />
          )}
        </div>
      )}
    </div>
  )
}

// 表格的兌換組數內容
const codeFormatter = (cell) => {
  const { isNotEnough, totalCount, type, exchangedCount, unexchangedCount } =
    cell
  let getContent = null
  if (isNotEnough) {
    getContent = statusFormatter('序號不足', false, true, false, false)
  }
  // 發送相同序號
  else if (type === 20 || type === 30) {
    getContent = <>已兌換 {numberFormatter(exchangedCount)} </>
  }
  // 發送不同序號
  else if (type === 10) {
    const remindCount =
      unexchangedCount < totalCount * 0.1 ? (
        statusFormatter(
          <>餘 {numberFormatter(unexchangedCount)}</>,
          false,
          true,
          false,
          false
        )
      ) : (
        <>餘 {numberFormatter(unexchangedCount)}</>
      )
    getContent = (
      <>
        {remindCount}，共 {numberFormatter(totalCount)}
      </>
    )
  } else {
    getContent = <>共 {numberFormatter(totalCount)} </>
  }
  return <>{getContent}</>
}

// 表格的狀態內容呈現
const handleStatusFormatter = (cell) => {
  let result = ''
  let statusArr = [true, false, false]
  switch (cell) {
    case qrcodeActivityListStatus.pending:
      result = '準備中'
      statusArr = statusArr.fill(false)
      break
    case qrcodeActivityListStatus.ongoing:
      result = '進行中'
      statusArr = [true, false, false]
      break
    case qrcodeActivityListStatus.expired:
      result = '已過期'
      statusArr = [false, false, true]
      break
    case qrcodeActivityListStatus.stopped:
      result = '已停用'
      statusArr = [false, false, true]
      break
  }
  return statusFormatter(result, ...statusArr)
}

// 表格的按鈕icon內容呈現
const buttonStatusFormatter = (
  cell,
  row,
  rowIndex,
  {
    history,
    settingPageType,
    brandId,
    handleGetQrcode,
    handleTableLoading,
    setQrcodeListData,
    handleOpenModal,
    stopQrcodeRowIndexRef,
    handleGetQrcodeOnce,
  }
) => {
  const { READ, UPDATE, DELETE } = COMPONENT_PERMISSIONS.qrcodeActivity
  const PermissionViewIcon = withPermission(IconButton, READ)
  const PermissionEditIcon = withPermission(IconButton, UPDATE)
  const PermissionStopIcon = withPermission(IconButton, UPDATE)
  const PermissionDeleteIcon = withPermission(IconButton, DELETE)
  return (
    <span className="icon-feature-container d-flex align-items-center">
      {Object.keys(cell).map((state) => {
        let triggerElement = null
        if (cell[state].visible) {
          switch (state) {
            case 'editBtn':
              triggerElement = (
                <PermissionEditIcon
                  onClick={() =>
                    handleEdit(
                      history,
                      settingPageType,
                      row,
                      handleGetQrcodeOnce
                    )
                  }
                  tooltip="編輯"
                  type="edit"
                  data-effect="solid"
                />
              )
              break
            case 'viewBtn':
              triggerElement = (
                <PermissionViewIcon
                  onClick={() =>
                    handleView(
                      history,
                      settingPageType,
                      row,
                      handleGetQrcodeOnce
                    )
                  }
                  tooltip="檢視"
                  type="view"
                  data-effect="solid"
                />
              )
              break
            case 'deleteBtn':
              triggerElement = (
                <PermissionDeleteIcon
                  onClick={() =>
                    handleDelete(
                      brandId,
                      row,
                      handleGetQrcode,
                      handleTableLoading,
                      setQrcodeListData
                    )
                  }
                  color={colorObj.lightGray}
                  tooltip="刪除"
                  type="delete"
                  data-effect="solid"
                />
              )
              break
            case 'stopBtn':
              triggerElement = (
                <PermissionStopIcon
                  onClick={() =>
                    handleStop(handleOpenModal, rowIndex, stopQrcodeRowIndexRef)
                  }
                  color={colorObj.lightGray}
                  tooltip="停用"
                  type="stop"
                  data-effect="solid"
                />
              )
              break
            default:
              break
          }
        }
        return (
          <Fragment key={`icon-feature-${rowIndex}-${state}`}>
            {triggerElement}
          </Fragment>
        )
      })}
    </span>
  )
}

// 表格套件需要的 columns 訊息
const tableColumns = (
  history,
  settingPageType,
  brandId,
  handleGetQrcode,
  handleTableLoading,
  setQrcodeListData,
  handleOpenModal,
  stopQrcodeRowIndexRef,
  handleGetQrcodeOnce,
  hasEditPermission
) => [
  {
    dataField: 'no',
    text: 'No.',
    sort: true,
    style: (cell, row) => {
      if (row?.code?.isNotEnough) {
        return {
          backgroundColor: hexToRgbA(colorObj.danger, 0.2),
        }
      }
    },
  },
  {
    dataField: 'name',
    text: '名稱',
    sort: true,
    style: (cell, row) => {
      const style = {
        maxWidth: '250px',
      }
      if (row?.code?.isNotEnough) {
        style.backgroundColor = hexToRgbA(colorObj.danger, 0.2)
      }
      return style
    },
    formatter: (cell) => ellipsisFormatter(cell, 2),
  },
  {
    dataField: 'validPeriod',
    text: '效期',
    sort: true,
    formatter: dateRangeFormatter,
    style: (cell, row) => {
      if (row?.code?.isNotEnough) {
        return {
          backgroundColor: hexToRgbA(colorObj.danger, 0.2),
        }
      }
    },
  },
  {
    dataField: 'point',
    text: '點數',
    formatter: pointFormatter,
    formatExtraData: { history, settingPageType, hasEditPermission },
    style: (cell, row) => {
      const style = {}
      if (row?.code?.isNotEnough) {
        style.backgroundColor = hexToRgbA(colorObj.danger, 0.2)
      }
      return { ...style, textAlign: 'end' }
    },
    headerStyle: formatTextLeftStyle(),
  },
  {
    dataField: 'code',
    text: '兌換組數',
    formatter: codeFormatter,
    style: (cell, row) => {
      let style = formatNumberLeftStyle()
      if (row?.code?.isNotEnough) {
        style = { ...style, backgroundColor: hexToRgbA(colorObj.danger, 0.2) }
      }
      return style
    },
    headerStyle: formatTextLeftStyle(),
  },
  {
    dataField: 'status',
    text: '狀態',
    sort: true,
    formatter: handleStatusFormatter,
    style: (cell, row) => {
      let style = formatNumberLeftStyle()
      if (row?.code?.isNotEnough) {
        style = { ...style, backgroundColor: hexToRgbA(colorObj.danger, 0.2) }
      }
      return style
    },
    headerStyle: formatTextLeftStyle(),
  },
  {
    dataField: 'funcButton',
    text: '',
    formatter: buttonStatusFormatter,
    formatExtraData: {
      history,
      settingPageType,
      brandId,
      handleGetQrcode,
      setQrcodeListData,
      handleOpenModal,
      stopQrcodeRowIndexRef,
      handleGetQrcodeOnce,
      handleTableLoading,
    },
    style: (cell, row) => {
      const style = {
        minWidth: '75px',
      }
      if (row?.code?.isNotEnough) {
        style.backgroundColor = hexToRgbA(colorObj.danger, 0.2)
      }
      return style
    },
  },
]

export { tableColumns }
