import styled from 'styled-components'

const LocalStyle = styled.div`
  .col-sty {
    flex: 0 0 22%;
    .form-check:first-child {
      margin-right: 0;
    }
  }
  .form-check-label {
    min-width: 65px;
  }
  .form-sub-group {
    flex: 0 0 60%;
  }
  .last-form-row-sty {
    border-bottom: 0;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  .input-wrap:first-child {
    margin-top: 0;
  }
  .input-wrap:nth-child(2) {
    margin-bottom: 8px;
  }
  .input-wrap .form-check {
    margin-right: 5px;
  }
  .input-sty-in-input-wrap {
    width: 100%;
  }
  .sub-input-wrap {
    margin-left: 20px;
  }
`

export default LocalStyle
