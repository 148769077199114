import { isBefore, format, startOfDay } from 'date-fns'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as IconCopy } from 'assets/images/button/icon_copy.svg'
import { ReactComponent as IconDelete } from 'assets/images/button/icon_delete.svg'
import { ReactComponent as IconEdit } from 'assets/images/button/icon_edit.svg'
import { ReactComponent as IconSetting } from 'assets/images/button/icon_gear.svg'
import { ReactComponent as IconSeal } from 'assets/images/button/icon_seal.svg'
import { colorObj } from 'assets/styles/Variable/Color'
import withPermission from 'components/hocs/withPermission'
import {
  FormatterNameWrapper,
  FormatterDateRangeWrapper,
  FormatterAmountWrapper,
  FormatterFnWrapper,
  TextLink,
} from 'components/pages/Gift/ExclusiveMainStyle'
import { IconButton, Dropdown } from 'components/units'
import { COMPONENT_PERMISSIONS } from 'constant'
import { getRandomId } from 'helpers/common'
import { number } from 'helpers/format'
import { tabStatus } from 'pages/Gift/config'
import { isPermissionAllowed } from 'store/permission/permissionSlice'

const { UPDATE, DELETE, CREATE } = COMPONENT_PERMISSIONS.vipGift
const PermissionDeactivateIconButton = withPermission(IconButton, UPDATE)

const typeOption = [
  { text: '入會禮', value: 1 },
  { text: '生日禮', value: 2 },
  { text: '升等禮', value: 3 },
  { text: '續會禮', value: 4 },
  { text: '滿額贈', value: 5 },
  { text: '指定禮', value: 6 },
  { text: '綁定禮', value: 7 },
]

const amountErrorCode = {
  normal: -1,
  finish: 1,
  insufficient: 2,
  fail: 3,
  linkCount: 4,
}

const amountErrorText = {
  [amountErrorCode.finish]: '已發送完畢',
  [amountErrorCode.insufficient]: '序號不足',
  [amountErrorCode.fail]: '無法生成更多序號',
  [amountErrorCode.linkCount]: '連結好禮無法發送',
}

const tableFnType = {
  view: 'viewBtn',
  edit: 'editBtn',
  upload: 'availBtn',
  takeDown: 'unavailBtn',
  remove: 'deleteBtn',
  copy: 'copyBtn',
  sealing: 'sealBtn',
}

const formatterType = (cell) =>
  typeOption.find((i) => i.value === Number(cell)).text

const formatterName = (cell, row) => {
  const [isLink, mainLinkId] = row?.linkInfo ?? [undefined, undefined]
  return (
    <>
      {isLink && <IconButton type="link" />}
      <FormatterNameWrapper>{cell}</FormatterNameWrapper>
      {mainLinkId && <div>{`(已連結 ${mainLinkId})`}</div>}
    </>
  )
}

const formatterTarget = (cell) => (
  <>
    {cell.map((item) => (
      <div key={getRandomId()}>{item}</div>
    ))}
  </>
)
const formatterDateRange = (cell) => {
  const [startDate, endDate] = cell
  const isEventPassed = isBefore(new Date(endDate), startOfDay(new Date()))

  const endDateClassName = isEventPassed ? 'dateRange-expired' : ''

  if (endDate !== null) {
    return (
      <FormatterDateRangeWrapper>
        <span>{`${format(new Date(startDate), 'yyyy/MM/dd')} ~ `}</span>
        <span className={endDateClassName}>{`${format(
          new Date(endDate),
          'yyyy/MM/dd'
        )}`}</span>
      </FormatterDateRangeWrapper>
    )
  }
  return (
    <FormatterDateRangeWrapper>{`${format(
      new Date(startDate),
      'yyyy/MM/dd'
    )} 起`}</FormatterDateRangeWrapper>
  )
}

function AmountText({ amountData }) {
  const [received, sented] = amountData
  let result = ''
  if (received) result += `已領 ${number(received)} ，`
  result += `已送 ${number(sented)}`
  return <div>{result}</div>
}

function AmountError({ amountData, handleLink }) {
  const isUpdatePermission = useSelector(isPermissionAllowed(UPDATE))
  const [, , error] = amountData
  const errorText = amountErrorText[error] || ''
  if (isUpdatePermission && error === amountErrorCode.linkCount) {
    return (
      errorText && (
        <div onClick={handleLink} className="amount-invalid">
          <TextLink>{errorText}</TextLink>
        </div>
      )
    )
  }
  return errorText && <div className="amount-invalid">{errorText}</div>
}

const formatterAmount = (handleEvent) =>
  function amount(cell) {
    const [, , , linkVipGiftSettingId] = cell
    const handleLink = () =>
      linkVipGiftSettingId &&
      handleEvent({
        type: tableFnType.edit,
        payload: { giftSettingId: linkVipGiftSettingId },
      })
    return (
      <FormatterAmountWrapper>
        <AmountText amountData={cell} />
        <AmountError amountData={cell} handleLink={handleLink} />
      </FormatterAmountWrapper>
    )
  }

function AvailableSection({ cell, row, handleEvent }) {
  return (
    <FormatterFnWrapper>
      {cell.some(([key, value]) => key === tableFnType.view && value) && (
        <IconButton
          onClick={() =>
            handleEvent({
              type: tableFnType.view,
              payload: { giftSettingId: row.vipGiftSettingId },
            })
          }
          tooltip="檢視"
          type="view"
        />
      )}
      {cell.some(([key, value]) => key === tableFnType.takeDown && value) && (
        <PermissionDeactivateIconButton
          onClick={() =>
            handleEvent({
              type: tableFnType.takeDown,
              payload: { giftSettingId: row.vipGiftSettingId },
            })
          }
          color={colorObj.lightGray}
          tooltip="下架"
          type="download"
        />
      )}
    </FormatterFnWrapper>
  )
}

function ScheduledSection({ cell, row, handleEvent }) {
  return (
    <FormatterFnWrapper>
      {cell.some(([key, value]) => key === tableFnType.view && value) && (
        <IconButton
          onClick={() =>
            handleEvent({
              type: tableFnType.view,
              payload: { giftSettingId: row.vipGiftSettingId },
            })
          }
          tooltip="檢視"
          type="view"
        />
      )}
      {cell.some(([key, value]) => key === tableFnType.takeDown && value) && (
        <PermissionDeactivateIconButton
          onClick={() =>
            handleEvent({
              type: tableFnType.takeDown,
              payload: { giftSettingId: row.vipGiftSettingId },
            })
          }
          color={colorObj.lightGray}
          tooltip="下架"
          type="download"
        />
      )}
    </FormatterFnWrapper>
  )
}

function UnavailableSection({ cell, row, handleEvent }) {
  const PermissionActivateIconButton = withPermission(IconButton, UPDATE)
  const isUpdateAllowed = useSelector(isPermissionAllowed(UPDATE))
  const isDeleteAllowed = useSelector(isPermissionAllowed(DELETE))
  const isCreateAllowed = useSelector(isPermissionAllowed(CREATE))

  // Note: cell 和 ExclusiveMainConfig.js 的 cell 格式不太一樣, 這邊的 cell 是一個二維陣列
  // 原始格式在 ExclusiveMain.js parserRows 經過轉換後變成二維陣列
  /**
   * cell example
   * [
   *   ["viewBtn", true],
   *   ["editBtn", true],
   *   ["availBtn", true],
   *   ...
   * ]
   */
  const checkButtonCapable = (key) => cell.some(([k, v]) => k === key && v)

  const isUploadBtnCapable = checkButtonCapable(tableFnType.upload)
  const isEditBtnCapable = checkButtonCapable(tableFnType.edit)
  const isCopyBtnCapable = checkButtonCapable(tableFnType.copy)
  const isDeleteBtnCapable = checkButtonCapable(tableFnType.remove)
  const isSealBtnCapable = checkButtonCapable(tableFnType.sealing)

  const unavailableDropdownText = useMemo(
    () => [
      {
        icon: <IconEdit className="unavailableIcon" />,
        text: <span>編輯</span>,
        type: tableFnType.edit,
        isVisible: isEditBtnCapable && isUpdateAllowed,
      },
      {
        icon: <IconCopy className="unavailableIcon" />,
        text: <span>複製</span>,
        type: tableFnType.copy,
        isVisible: isCopyBtnCapable && isCreateAllowed,
      },
      {
        icon: <IconDelete className="unavailableIcon" />,
        text: <span>刪除</span>,
        type: tableFnType.remove,
        isVisible: isDeleteBtnCapable && isDeleteAllowed,
      },
      {
        icon: <IconSeal className="unavailableIcon" />,
        text: <span>封存</span>,
        type: tableFnType.sealing,
        isVisible: isSealBtnCapable && isUpdateAllowed,
      },
    ],
    [
      isEditBtnCapable,
      isUpdateAllowed,
      isCopyBtnCapable,
      isCreateAllowed,
      isDeleteBtnCapable,
      isDeleteAllowed,
      isSealBtnCapable,
    ]
  )

  const menuItems = unavailableDropdownText
    .filter(({ isVisible }) => isVisible)
    .map((i) => ({
      text: (
        <>
          {i.icon}
          {i.text}
        </>
      ),
      dropdownItemOption: {
        eventKey: i.type,
        onSelect: () =>
          handleEvent({
            type: i.type,
            payload: { giftSettingId: row.vipGiftSettingId },
          }),
      },
    }))
  const menuItemsExist = menuItems.length > 0
  return (
    <FormatterFnWrapper flexEnd>
      {isUploadBtnCapable && isUpdateAllowed && (
        <PermissionActivateIconButton
          onClick={() =>
            handleEvent({
              type: tableFnType.upload,
              payload: { giftSettingId: row.vipGiftSettingId },
            })
          }
          tooltip="上架"
          type="upload"
        />
      )}
      {menuItemsExist && (
        <Dropdown
          className="formatterFnBtn"
          framed
          size="sm"
          html={<IconSetting />}
          menuItems={menuItems}
        />
      )}
    </FormatterFnWrapper>
  )
}

function SealedSection({ cell, row, handleEvent }) {
  return (
    <FormatterFnWrapper>
      {cell.some(([key, value]) => key === tableFnType.view && value) && (
        <IconButton
          onClick={() =>
            handleEvent({
              type: tableFnType.view,
              payload: { giftSettingId: row.vipGiftSettingId },
            })
          }
          tooltip="檢視"
          type="view"
        />
      )}
    </FormatterFnWrapper>
  )
}

const formatterFn = (handleEvent, tabType) =>
  function setting(cell, row) {
    switch (tabType) {
      case tabStatus.available:
        return (
          <AvailableSection cell={cell} row={row} handleEvent={handleEvent} />
        )
      case tabStatus.scheduled:
        return (
          <ScheduledSection cell={cell} row={row} handleEvent={handleEvent} />
        )
      case tabStatus.unavailable:
        return (
          <UnavailableSection cell={cell} row={row} handleEvent={handleEvent} />
        )
      case tabStatus.sealed:
        return <SealedSection cell={cell} row={row} handleEvent={handleEvent} />
      default:
        return null
    }
  }

const formatterContent = (cell) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: cell }} />
)

export {
  formatterType,
  formatterName,
  formatterFn,
  formatterDateRange,
  formatterAmount,
  formatterTarget,
  formatterContent,
}

AmountText.propTypes = {
  amountData: PropTypes.arrayOf(PropTypes.number),
}

AmountText.defaultProps = {
  amountData: [],
}

AmountError.propTypes = {
  amountData: PropTypes.arrayOf(PropTypes.number),
  handleLink: PropTypes.func,
}

AmountError.defaultProps = {
  amountData: [],
  handleLink: () => {},
}

AvailableSection.propTypes = {
  cell: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  row: PropTypes.shape({
    vipGiftSettingId: PropTypes.number,
  }),
  handleEvent: PropTypes.func,
}

AvailableSection.defaultProps = {
  cell: [],
  row: {},
  handleEvent: () => {},
}

ScheduledSection.propTypes = {
  cell: PropTypes.shape([]),
  row: PropTypes.shape({
    vipGiftSettingId: PropTypes.number,
  }),
  handleEvent: PropTypes.func,
}
ScheduledSection.defaultProps = {
  cell: [],
  row: {},
  handleEvent: () => {},
}

UnavailableSection.propTypes = {
  cell: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  row: PropTypes.shape({
    vipGiftSettingId: PropTypes.number,
  }),
  handleEvent: PropTypes.func,
}

UnavailableSection.defaultProps = {
  cell: [],
  row: {},
  handleEvent: () => {},
}

SealedSection.propTypes = {
  cell: PropTypes.shape([]),
  row: PropTypes.shape({
    vipGiftSettingId: PropTypes.number,
  }),
  handleEvent: PropTypes.func,
}

SealedSection.defaultProps = {
  cell: [],
  row: {},
  handleEvent: () => {},
}
