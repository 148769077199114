import styled from 'styled-components'
import { colorCommon, colorObj } from 'assets/styles/Variable/Color'

const FormWrapper = styled.div`
  font-size: 15px;

  .form-row {
    margin: 0;
    margin-bottom: 24px;
    border-bottom: 1px solid ${colorCommon.borderGray};
    &-lasted {
      border-bottom: none;
    }
  }

  .form-group {
    margin-bottom: 24px;
    input[type='text'] {
      height: 32px;
    }
    display: flex;
    align-items: baseline;
    &-top {
      align-items: flex-start;
      .row {
        flex-wrap: nowrap;
      }
    }
    overflow-wrap: anywhere;
  }

  .form-group-align-center {
    align-items: center;
  }
  .form-label {
    margin-bottom: 0px;
    flex: 0 0 100px;
    color: ${colorObj.darkGray};
  }

  .form-check {
    flex: 0 1 auto;
    margin-right: 16px;
    &-last {
      margin-right: 0;
    }
    &-input.is-valid ~ .form-check-label,
    &-input:valid ~ .form-check-label {
      color: black;
    }
  }

  .input-group {
    min-width: 50px;
    > input:invalid {
      border-color: ${colorObj.danger};
      + .input-group-append .input-group-text {
        border-color: ${colorObj.danger};
      }
    }
  }

  .form-control-plaintext {
    cursor: initial;
    &:focus-visible {
      outline: none;
    }
  }

  .form-calendar {
    width: 100%;
  }

  .form-column-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 auto;
    > div {
      flex: 1 0 24px;
    }
    .form-check {
      margin-right: 0;
    }
  }

  .form-calendar-group {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .form-check {
      align-self: center;
      flex: 1 0 auto;
    }
    .form-calendar {
      flex: 0 0 260px;
    }
  }

  .form-select-group {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .form-check {
      align-self: center;
      flex: 1 0 auto;
    }
    .form-select {
      flex: 0 0 220px;
    }
  }

  .form-main-group {
    display: flex;
    flex: 0 0 20%;
    margin-right: 8px;
    align-items: flex-start;
  }

  .form-sub-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0 8px;
    display: flex;
    flex: 0 0 80%;
    > .form-check {
      margin: 0px;
      flex: 0 0 110px;
    }
    > .lv-zero {
      flex: 0 0 110px;
      display: flex;
      align-items: center;
    }
  }

  .form-complex {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .form-complex-item {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 8px;
    > div:last-child {
      display: flex;
      align-items: center;
      gap: 8px;
      > div {
        width: 100%;
      }
    }
    /* &:nth-child(odd) {
      margin-bottom: 8px;
    }
    &:nth-child(even) {
      margin-bottom: 16px;
    } */
    &.form-complex-item-last {
      margin-bottom: 0;
    }
  }

  .form-invalid-feeback {
    color: ${colorObj.danger};
  }

  small {
    color: ${colorObj.gray};
  }

  small,
  .form-invalid-feeback {
    font-size: 13px;
    width: 100%;
    margin: 4px 0 0 0;
    padding: 0 0 0 8px;
    &.no-padding {
      padding: 0;
    }
  }

  .form-row-input-fill,
  .form-row-input-fill button,
  .form-group > .style-tooltip-container {
    width: 100%;
  }

  @media (min-width: 992px) {
    .form-row > .col-lg-6 {
      &:nth-child(odd) {
        padding-right: 20px !important;
      }
      &:nth-child(even) {
        padding-left: 20px !important;
      }
      &:only-child {
        padding: 0 !important;
      }
    }
  }
`

function ReceiveEndOptionsWrapper({ children, className, ...props }) {
  return (
    <div className={`form-select-group ${className ?? ''}`} {...props}>
      {children}
    </div>
  )
}
function CalendarWrapper({ children, className, ...props }) {
  return (
    <div className={`form-calendar-group ${className ?? ''}`} {...props}>
      {children}
    </div>
  )
}

function ColumnListWrapper({ children, className, ...props }) {
  return (
    <div className={`form-column-list ${className ?? ''}`} {...props}>
      {children}
    </div>
  )
}
function CheckListMainWrapper({ children, className, ...props }) {
  return (
    <div className={`form-main-group ${className ?? ''}`} {...props}>
      {children}
    </div>
  )
}
function CheckListSubWrapper({ children, className, ...props }) {
  return (
    <div className={`form-sub-group ${className ?? ''}`} {...props}>
      {children}
    </div>
  )
}

function ComplexListWrapper({ children, className, ...props }) {
  return (
    <div className={`form-complex ${className ?? ''}`} {...props}>
      {children}
    </div>
  )
}

function ComplexItem({ children, className, ...props }) {
  return (
    <div className={`form-complex-item ${className ?? ''}`} {...props}>
      {children}
    </div>
  )
}
// TODO: InValidFeedback 的 show 邏輯違反直覺，可列為重構
function InValidFeedback({ children, className, show = null, ...props }) {
  return (
    show === false && (
      <div className={`form-invalid-feeback ${className ?? ''}`} {...props}>
        {children}
      </div>
    )
  )
}

function LvZeroEl({ children, classNames, ...props }) {
  return (
    <div className={`lv-zero ${classNames ?? ''}`} {...props}>
      {children}
    </div>
  )
}

export default FormWrapper
export {
  CalendarWrapper,
  ColumnListWrapper,
  CheckListMainWrapper,
  CheckListSubWrapper,
  ComplexListWrapper,
  ComplexItem,
  InValidFeedback,
  LvZeroEl,
  ReceiveEndOptionsWrapper,
}
