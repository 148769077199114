import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { colorObj, method } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const LocalStyle = styled(Modal)`
  &.point-setting-modal-conatiner {
    .modal-dialog {
      max-width: 520px;
    }
    .checkbox-member-level {
      width: 100px;
    }
    .counsume-amount {
      width: 240px;
    }
    .give-pt-date-toggle-btn {
      background-color: ${colorObj.white};
      color: ${method.colorHandle('dark', colorObj, 0.66)};
      border-color: ${method.colorHandle('dark', colorObj, 0.66)};
      box-shadow: none !important;
      padding: 8.5px 0;
      font-size: 14px;

      &.active,
      &:active {
        background-color: ${colorObj.primary || 'inherit'};
        border-color: ${colorObj.darkerGray || 'inherit'};
      }
    }
    input[type='radio'],
    input[type='checkbox'] {
      ~ .invalid-feedback {
        margin-top: 0;
      }
    }
    .form-check {
      ~ .custom-invalid-feedback {
        position: relative;
        left: 10px;
        margin-top: ${spacingObj.micro};
      }
    }
    .input-valid-date-rage {
      width: calc(100% - 94px);

      .style-input-container {
        width: 100%;
      }
    }
    .point-setting-assign-calendar-date-container {
      .btn-group-toggle {
        .give-pt-date-toggle-btn {
          border-bottom-color: transparent;
          border-radius: 0;
          width: calc(100% / 7);
        }
        &:first-of-type {
          .give-pt-date-toggle-btn {
            &:first-of-type {
              border-top-left-radius: 4px;
            }
            &:last-of-type {
              border-top-right-radius: 4px;
            }
          }
        }
        &:last-of-type {
          .give-pt-date-toggle-btn {
            border-bottom-color: inherit;

            &:first-of-type {
              border-bottom-left-radius: 4px;
            }
            &:last-of-type {
              width: calc(100% / 7 * 4);
              border-bottom-right-radius: 4px;
            }
          }
        }
      }
    }
    .group-point-date-container {
      width: calc(50% - 4px);
      margin-left: 8px;
      margin-bottom: 8px;
      display: inline-flex;

      &:nth-of-type(2n + 1) {
        margin-left: 0;
      }
      &:nth-last-of-type(n + 2) {
        margin-bottom: 0;
      }
      &:last-of-type {
        margin-top: 8px;
      }
      .input-group-prepend {
        + .form-control {
          border-color: #ced4da;
        }
      }
      .dynamic-give-point-Date {
        > .ptset-custom-input {
          max-width: 124px;
          border-radius: 2px;
        }
      }
      [data-id='tooltip'] {
        pointer-events: initial;
        opacity: 1;
        box-shadow: 0 0 10px #888888;
        margin-top: -10px;
      }
      .rdrSelected {
        color: ${colorObj.primary} !important;
        filter: none;
      }
      .rdrCalendarWrapper {
        &::before {
          content: '';
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }
      .rdrMonthAndYearPickers {
        font-weight: normal;
      }
    }
    .btn-add-group-point-date {
      cursor: pointer;
      display: block;

      .input-group-prepend {
        + .form-control {
          cursor: pointer;
          border-color: #ced4da !important;
          background-color: ${colorObj.white};
        }

        .input-group-text {
          > span {
            margin-left: ${spacingObj.small};
            color: ${method.colorHandle('dark', colorObj, 0.5)};
            line-height: 1;
          }
        }
      }
    }
    .add-date-container {
      margin-top: ${spacingObj.mini};
    }
    .checkbox-member-birthday,
    .radio-point-give-container {
      .form-check-label {
        white-space: nowrap;
      }
      .dropdown,
      .style-input-container {
        width: 105px;
      }
      .dropdown {
        .dropdown-toggle {
          min-width: auto;
          width: 100%;
        }
      }
    }
    .radio-point-multiple-give-container {
      .form-check-label {
        white-space: nowrap;
      }
    }
    .form-label {
      display: inline-block;
      width: 75px;
    }
    .radio-point-no-give-point-container {
      ~ .custom-invalid-feedback {
        position: relative;
        left: 10px;
        color: ${colorObj.danger};
        margin-top: ${spacingObj.micro};
        font-size: 13px;
      }
    }
  }
`

export default LocalStyle
