import { startOfDay } from 'date-fns'
import { fieldRestrictNumber } from 'helpers/validation'

const validMsg = {
  empty: '尚未填寫',
  over100k: '不可高於 10 萬組',
  lessThanExchanged: '上架數量低於已兌換數量',
  lessThanGenerated: '上架數量低於序號總數',
  giftInfo: '上限 2,000 字',
  exchangeStart: '不可早於兌換起始',
  exchangeEnd: '不可早於兌換結束',
  collectionStart: '不可早於領取起始',
  collectionEnd: '不可早於領取結束',
  collectionEndPermanent: '兌換結束日為永久，領取結束日須相同',
}

const exchangePathway = {
  event: 1, // 漸強活動
  liff: 2, // Line LIFF
  store: 3, // 線下門店（已停止開發）
  sms: 4, // 簡訊發送
  delivery: 5, // 宅配出貨
}

/* 領取方式 */
const collectionMethod = {
  online: 10, // 線上序號
  store: 20, // 門店領取
  link: 30, // 線上連結
}

const formConfig = {
  systemNo: { label: '系統編號：' },
  name: { label: '名稱：', placeholder: '上限 50 字', maxLength: 50 },
  color: { label: '顏色：', placeholder: '選填，上限 5 字', maxLength: 5 },
  size: { label: '尺寸：', placeholder: '選填，上限 5 字', maxLength: 5 },
  images: {
    placeholder:
      '圖檔格式限 .png 或 .jpg，檔案大小限 ≤ 2MB，尺寸建議 600*600px',
  },
  target: { label: '對象：', placeholder: '' },
  targetAll: { label: '全會員' },
  targetZero: { label: '註冊會員', tip: '註冊但未達 Lv1 之會員' },
  exchangePathway: { label: '兌換通路：' },
  exchangePathwayMoney: {
    label: '全金額',
    subLabel: '兌換條碼：',
    placeholder: '全金額兌換之 Barcode',
    maxLength: 6,
    subMaxLength: 128,
  },
  exchangePathwayPoint: {
    label: '全點數',
    subLabel: '兌換條碼：',
    placeholder: '全點數兌換之 Barcode',
    maxLength: 6,
    subMaxLength: 128,
  },
  exchangePathwayPointMoney: {
    label: '點數加價',
    subLabel: '兌換條碼：',
    placeholder: '點數加價兌換之 Barcode',
    maxLength: 6,
    subMaxLength: 128,
  },
  exchangeMethod: {
    label: '兌換方式：',
    tip: '*兌換贈品之金額不累積點數',
  },
  collectionMethod: {
    label: '領取方式：',
    tip: '需建立『贈品序號』作為會員領取憑證',
  },
  giftCode: {
    label: '贈品識別碼：',
    placeholder: '贈品條碼/貨號',
    [`placeholder${exchangePathway.liff}`]: '贈品條碼/貨號',
    [`placeholder${exchangePathway.event}`]: '漸強活動 Template ID',
    [`placeholder${exchangePathway.store}`]: '贈品條碼/貨號',
    [`placeholder${exchangePathway.sms}`]: 'Thinker 資料表 - 贈品編號',
    maxLength: 128,
    tip: '請輸入半形英數、符號，上限 128 字',
  },
  uploadCount: {
    label: '上架數量：',
    placeholder: '',
    [`placeholder${exchangePathway.liff}`]: '不可高於 10 萬組',
    [`placeholder${exchangePathway.event}`]: '不可高於 100 萬組',
    [`placeholder${exchangePathway.store}`]: '不可高於 100 萬組',
    [`placeholder${exchangePathway.sms}`]: '上限 100 萬組',
    tip: '剩餘數量低於 10%，將紅字警示',
    maxLength: 7,
    [`maxLength${exchangePathway.liff}`]: 6,
    [`maxLength${exchangePathway.event}`]: 7,
    [`maxLength${exchangePathway.store}`]: 7,
    [`maxLength${exchangePathway.sms}`]: 7,
  },
  exchangeLimit: { label: '兌換限制：' },
  exchangeStart: { label: '兌換起始：' },
  exchangeEnd: { label: '兌換結束：' },
  autoUpload: { label: '自動上架：' },
  collectionStart: { label: '領取起始：' },
  collectionEnd: { label: '領取結束：' },
  giftInfo: { label: '贈品介紹：', placeholder: validMsg.giftInfo },
}

const initState = {
  systemNo: '-',
  name: '',
  color: '',
  size: '',
  images: [],
  removeImages: [],
  target: {
    forNewMember: '',
    forInitialMember: false,
    memberLevels: [],
  },
  memberLevelItemList: [],
  exchangePathway: '',
  exchangeMethod: {
    onlyMoney: {
      check: false,
      barcode: '',
      money: '',
      point: '',
    },
    onlyPoint: {
      check: false,
      barcode: '',
      money: '',
      point: '',
    },
    pointMoney: {
      check: false,
      barcode: '',
      money: '',
      point: '',
    }, // checkbox 邏輯 是否為 null,驗證的時候為null就跳過
  },
  collectionMethod: '',
  giftCode: '',
  uploadCount: '',
  exchangeLimit: false,
  exchangeStart: { check: '', calendar: startOfDay(new Date()) },
  exchangeEnd: { check: '', calendar: startOfDay(new Date()) },
  autoUpload: '',
  collectionStart: startOfDay(new Date()),
  collectionEnd: { check: '', calendar: startOfDay(new Date()) },
  giftInfo: '',
  barcodeTypeNotice: '',
  giftCodeCount: null,
  giftRecordCount: 0,
}

const stateReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        ...action.payload,
      }
    case 'lv0': {
      const clearupSetting = action.payload
      return {
        ...state,
        target: {
          forNewMember: clearupSetting ? false : state.target.forNewMember,
          memberLevels: state.target.memberLevels,
          forInitialMember: action.payload,
        },
      }
    }
    case 'forNewMember': {
      const clearSetting = action.payload
      return {
        ...state,
        target: {
          forNewMember: action.payload,
          memberLevels: state.memberLevelItemList.map((i) => ({
            ...i,
            selected: clearSetting ? false : i.selected,
          })),
          forInitialMember: clearSetting
            ? false
            : state.target.forInitialMember,
        },
      }
    }
    case 'memberLevelsAll': {
      const clearupSetting = action.payload
      return {
        ...state,
        target: {
          forNewMember: clearupSetting ? false : state.target.forNewMember,
          memberLevels: state.memberLevelItemList.map((i) => ({
            ...i,
            selected: action.payload,
          })),
          forInitialMember: action.payload,
        },
      }
    }
    case 'memberLevels': {
      const stateMemberLevels = state.target.memberLevels
      const selectedIndex = stateMemberLevels.findIndex(
        (level) => level.id === action.payload.id
      )
      const isNewLevel = selectedIndex === -1
      const newMemberLevels = isNewLevel
        ? [
            ...stateMemberLevels,
            { id: action.payload.id, selected: action.payload.selected },
          ]
        : stateMemberLevels.map((level, id) => {
            if (id === selectedIndex)
              return { ...level, selected: !level.selected }
            return level
          })
      const clearupSetting = newMemberLevels.some((i) => i.selected)
      return {
        ...state,
        target: {
          forNewMember: clearupSetting ? false : state.target.forNewMember,
          memberLevels: newMemberLevels,
          forInitialMember: state.target.forInitialMember,
        },
      }
    }
    case 'onlyPoint':
      return {
        ...state,
        exchangeMethod: {
          ...state.exchangeMethod,
          onlyPoint: {
            ...state.exchangeMethod.onlyPoint,
            point: action.payload,
          },
        },
      }
    case 'onlyPoint_Checkbox':
      return {
        ...state,
        exchangeMethod: {
          ...state.exchangeMethod,
          onlyPoint: {
            ...state.exchangeMethod.onlyPoint,
            check: action.payload,
            point: '',
          },
        },
      }
    case 'onlyPoint_Barcode':
      return {
        ...state,
        exchangeMethod: {
          ...state.exchangeMethod,
          onlyPoint: {
            ...state.exchangeMethod.onlyPoint,
            barcode: action.payload,
          },
        },
      }
    case 'pointMoney_Money':
      return {
        ...state,
        exchangeMethod: {
          ...state.exchangeMethod,
          pointMoney: {
            ...state.exchangeMethod.pointMoney,
            money: action.payload,
          },
        },
      }
    case 'pointMoney_Point':
      return {
        ...state,
        exchangeMethod: {
          ...state.exchangeMethod,
          pointMoney: {
            ...state.exchangeMethod.pointMoney,
            point: action.payload,
          },
        },
      }
    case 'pointMoney_Checkbox':
      return {
        ...state,
        exchangeMethod: {
          ...state.exchangeMethod,
          pointMoney: {
            ...state.exchangeMethod.pointMoney,
            check: action.payload,
            money: '',
            point: '',
          },
        },
      }
    case 'pointMoney_Barcode':
      return {
        ...state,
        exchangeMethod: {
          ...state.exchangeMethod,
          pointMoney: {
            ...state.exchangeMethod.pointMoney,
            barcode: action.payload,
          },
        },
      }
    case 'onlyMoney':
      return {
        ...state,
        exchangeMethod: {
          ...state.exchangeMethod,
          onlyMoney: {
            ...state.exchangeMethod.onlyMoney,
            money: action.payload,
          },
        },
      }
    case 'onlyMoney_Checkbox':
      return {
        ...state,
        exchangeMethod: {
          ...state.exchangeMethod,
          onlyMoney: {
            ...state.exchangeMethod.onlyMoney,
            check: action.payload,
            money: '',
          },
        },
      }
    case 'onlyMoney_Barcode':
      return {
        ...state,
        exchangeMethod: {
          ...state.exchangeMethod,
          onlyMoney: {
            ...state.exchangeMethod.onlyMoney,
            barcode: action.payload,
          },
        },
      }
    case 'exchangeEnd_Checkbox': {
      const type = action.type.split('_Checkbox')[0]
      return {
        ...state,
        [type]: {
          ...state[type],
          check: action.payload,
        },
        collectionEnd: {
          ...state.collectionEnd,
          check: action.payload || state.collectionEnd.check,
        },
      }
    }
    case 'exchangeStart_Checkbox':
    case 'collectionEnd_Checkbox': {
      const type = action.type.split('_Checkbox')[0]
      return {
        ...state,
        [type]: {
          ...state[type],
          check: action.payload,
        },
      }
    }
    case 'exchangeStart':
    case 'exchangeEnd':
    case 'collectionEnd':
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          calendar: action.payload,
        },
      }
    case 'images':
      return {
        ...state,
        [action.type]: action.payload,
        removeImages: state.removeImages.concat(
          state.images.filter((i) => i.giftSettingImgId)
        ),
      }
    case 'collectionMethod':
    case 'name':
    case 'color':
    case 'size':
    case 'exchangePathway':
    case 'giftCode':
    case 'uploadCount':
    case 'exchangeLimit':
    case 'collectionStart':
    case 'autoUpload':
    case 'giftInfo':
      return {
        ...state,
        [action.type]: action.payload,
      }
    default:
      return state
  }
}

const stateUpdate = (dispatch) => (type) => (e) => {
  const checkboxConditionType = {
    forNewMember: 'newMember',
    exchangeStart_Checkbox: 'immediately',
    exchangeEnd_Checkbox: 'exchangeEndPermanent',
    collectionEnd_Checkbox: 'collectionEndPremanent',
    autoUpload: 'auto',
  }
  switch (type) {
    case 'images':
      return dispatch({ type, payload: [e] })
    case 'memberLevels':
      return dispatch({
        type,
        payload: { id: Number(e.target.id), selected: e.target.checked },
      })
    case 'exchangePathway':
      return dispatch({ type, payload: exchangePathway[e.target.id] })
    case 'collectionMethod': {
      const { id } = e.target
      const method = id === 'collectionStore' ? 'store' : id
      return dispatch({ type, payload: collectionMethod[method] })
    }

    case 'forNewMember':
    case 'exchangeStart_Checkbox':
    case 'exchangeEnd_Checkbox':
    case 'collectionEnd_Checkbox':
    case 'autoUpload':
      return dispatch({
        type,
        payload: e.target.id === checkboxConditionType[type],
      })

    case 'memberLevelsAll':
    case 'lv0':
    case 'onlyPoint_Checkbox':
    case 'pointMoney_Checkbox':
    case 'onlyMoney_Checkbox':
    case 'exchangeLimit':
      return dispatch({ type, payload: e.target.checked })

    case 'giftInfo':
    case 'exchangeStart':
    case 'exchangeEnd':
    case 'collectionStart':
    case 'collectionEnd':
      return dispatch({ type, payload: e })

    case 'onlyPoint':
    case 'pointMoney_Money':
    case 'pointMoney_Point':
    case 'onlyMoney':
    case 'uploadCount':
      return (
        e.target.value !== '0' &&
        dispatch({ type, payload: fieldRestrictNumber(e) })
      )

    case 'pointMoney_Barcode':
    case 'onlyPoint_Barcode':
    case 'onlyMoney_Barcode':
    case 'name':
    case 'color':
    case 'size':
    case 'giftCode':
      return dispatch({ type, payload: e.target.value })

    default:
  }
}

const parserPayload = (res) => {
  return {
    systemNo: res.brandSerialNo,
    name: res.name,
    color: res.color ?? '',
    size: res.size ?? '',
    images: res.images,
    target: {
      forInitialMember: res.forInitialMember ?? false,
      forNewMember: res.forNewMember ?? '',
      memberLevels: res.memberLevelList,
    },
    memberLevelItemList: res.memberLevelItemList,
    exchangePathway: res.exchangeWay,
    exchangeMethod: res.exchangeMethod,
    collectionMethod: res.receiveWay ?? '',
    giftCode: res.giftCustomId ?? '',
    uploadCount: res.amount ?? '',
    exchangeLimit: res.everyMemberOnce || false,
    exchangeStart: {
      check: res.exchangeStartDate === null,
      calendar: res.exchangeStartDate
        ? new Date(res.exchangeStartDate)
        : startOfDay(new Date()),
    },
    exchangeEnd: {
      check: res.exchangeEndDate === null,
      calendar: res.exchangeEndDate
        ? new Date(res.exchangeEndDate)
        : startOfDay(new Date()),
    },
    collectionStart: res.giftCodeStartDate
      ? new Date(res.giftCodeStartDate)
      : startOfDay(new Date()),
    collectionEnd: {
      check: res.giftCodeEndDate === null,
      calendar: res.giftCodeEndDate
        ? new Date(res.giftCodeEndDate)
        : startOfDay(new Date()),
    },
    autoUpload: res.autoAvailable,
    giftInfo: res?.description ?? '',
    barcodeTypeNotice: res?.barcodeTypeNotice ?? '',
    giftCodeCount: res?.giftCodeCount ?? null,
    giftRecordCount: res?.giftRecordCount ?? 0,
  }
}

const initValid = {
  name: { isValid: null, msg: validMsg.empty, required: true },
  color: { isValid: null, msg: validMsg.empty },
  size: { isValid: null, msg: validMsg.empty },
  images: { isValid: null, msg: validMsg.empty },
  target: { isValid: null, msg: validMsg.empty, required: true },
  exchangePathway: { isValid: null, msg: validMsg.empty, required: true },
  exchangeMethod: { isValid: null, msg: validMsg.empty, required: true },
  collectionMethod: { isValid: null, msg: validMsg.empty },
  giftCode: { isValid: null, msg: validMsg.empty, required: true },
  uploadCount: { isValid: null, msg: validMsg.empty, required: true },
  exchangeLimit: { isValid: null, msg: validMsg.empty },
  exchangeStart: { isValid: null, msg: validMsg.empty, required: true },
  exchangeEnd: { isValid: null, msg: validMsg.empty, required: true },
  autoUpload: { isValid: null, msg: validMsg.empty, required: true },
  collectionStart: { isValid: null, msg: validMsg.empty, required: true },
  collectionEnd: { isValid: null, msg: validMsg.empty, required: true },
  giftInfo: { isValid: null, msg: validMsg.empty },
}

const imageLimitConfig = {
  size: 2048, // file unit is KB
}

export {
  validMsg,
  formConfig,
  initState,
  stateReducer,
  stateUpdate,
  parserPayload,
  initValid,
  exchangePathway,
  collectionMethod,
  imageLimitConfig,
}
