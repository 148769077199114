import { CancelToken } from 'axios'
import { generateSystemExchangeCode, importExchangeCode } from 'api/ApiMain'
import { getRandomId, removeToast } from 'helpers/common'
import { validMsg } from 'components/pages/Gift/ExchangeSerialNumberConfig'
import { tabStatus } from 'pages/Gift/config'
import { modalType } from 'pages/Gift/ExchangeSetting'
import { showTopToast, showUploadToast } from 'components/units/Toast'
import { GENERATE_STAGE } from 'constant/toast'
import { buildType } from 'components/pages/Gift/ExchangeSerialNumberConfig'

const handleValid = ({ data }) => {
  const isSystemBuild = data.type === buildType.system
  const rule = {
    prefix: '^[A-Z0-9]{4}$',
    length: '^[1][0-9]$|[2][0-4]$',
    empty: '^$',
  }

  const checkTotalValid = () => {
    if (data.total > 10000) {
      return { valid: false, msg: validMsg.generateLimit }
    } else if (data.total + data.existedTotalCount > data.uploadCount) {
      return { valid: false, msg: validMsg.uploadLimit }
    } else {
      return { valid: true, msg: '' }
    }
  }

  const validState = {
    prefix: {
      valid: isSystemBuild ? new RegExp(rule.prefix).test(data.prefix) : true,
      msg: new RegExp(rule.empty).test(data.prefix)
        ? validMsg.empty
        : validMsg.prefix,
    },
    length: {
      valid: isSystemBuild ? new RegExp(rule.length).test(data.length) : true,
      msg: validMsg.empty,
    },
    codeGetType: {
      valid: !!data.codeGetType,
      msg: validMsg.empty,
    },
    total: checkTotalValid(),
  }

  const isValidPass =
    Object.keys(validState).findIndex((attr) => !validState[attr].valid) === -1

  return { validState, isValidPass }
}

const handleApiError = ({ error, updateFn }) => {
  const { errors } = error
  const config = {
    giftCodePrefix: [
      {
        type: '在同一品牌裡，序號不可重複',
        attr: 'prefix',
        msg: '在同一品牌裡，序號前綴不可重複',
      },
    ],
  }

  for (const type in errors) {
    for (const errorMsg of errors[type]) {
      if (
        config[type] === undefined ||
        config[type].findIndex((i) => i.type === errorMsg) === -1
      )
        break

      const { msg, attr } = config[type].find((i) => i.type === errorMsg)
      updateFn((prevState) => ({
        ...prevState,
        [attr]: {
          ...prevState[attr],
          valid: false,
          msg,
        },
      }))
    }
  }
}

const handleGenerate = async ({
  setting,
  setValid,
  setMask,
  handleSearch,
  brandId,
  giftSettingId,
  setStateFromEdit,
  handleUniteModalSwitch,
  handleDataLoading,
}) => {
  const bodyFromData = new FormData()
  bodyFromData.append('generateSet', setting.total)

  const source = CancelToken.source()
  const cancelToken = { cancelToken: source.token }
  setMask(true)
  const randomId = getRandomId()

  const handleSystemGenerate = setTimeout(async () => {
    try {
      const res = await generateSystemExchangeCode(
        brandId,
        giftSettingId,
        bodyFromData,
        cancelToken
      )
      const giftSettingStatus = res.data?.data?.giftSettingStatus
      if (giftSettingStatus !== tabStatus.unavailable) {
        handleUniteModalSwitch(true, { type: modalType.goList })
      }
      setStateFromEdit(giftSettingStatus)
      showUploadToast({
        type: GENERATE_STAGE.COMPLETED,
        message: {
          module: 'gift',
          title: `${setting.total} 筆資料成功`,
        },
        toastId: getRandomId(),
      })
      handleSearch()
    } catch (err) {
      const { message } = err
      if (message) {
        showTopToast({
          type: 'error',
          message,
        })
        handleSearch()
      }
      handleApiError({ error: err, updateFn: setValid })
    } finally {
      setMask(false)
      handleDataLoading(false)
    }
  }, 6000)

  const handleCancelSystemGenerate = () => {
    clearTimeout(handleSystemGenerate)
    setMask(false)
    showUploadToast({
      type: GENERATE_STAGE.CANCEL,
      message: { module: 'gift' },
      toastId: randomId,
    })
  }

  showUploadToast({
    duration: 5,
    type: GENERATE_STAGE.PREPARE,
    message: { module: 'gift' },
    toastId: randomId,
    onClose: handleCancelSystemGenerate,
  })
}

const handleUploadCsv =
  ({
    brandId,
    giftSettingId,
    handleSearch,
    setUploadModal,
    setMask,
    setStateFromEdit,
    setImportModal,
    infoState,
    handleSyncUploadProgress,
  }) =>
  async (file) => {
    const { name: itemName, systemNo } = infoState
    const uploadTimeoutHandler = setTimeout(async () => {
      showUploadToast(uploadingToastConfig)
      try {
        const res = await importExchangeCode(
          brandId,
          giftSettingId,
          bodyFormData
        )
        const giftSettingStatus = res?.data?.data?.giftSettingStatus
        setStateFromEdit(giftSettingStatus)
        removeToast(uploadingToastConfig.toastId)
        setImportModal(true)
        handleSyncUploadProgress()
        showUploadToast(importingToastConfig)
        await handleSearch()
      } catch (error) {
        setMask(false)
        removeToast(uploadingToastConfig.toastId)
      }
    }, 6000)
    const toastMsg = {
      module: 'gift',
      title: `No.${systemNo} ${itemName}`,
      content: file.name,
    }
    const preUploadToastConfig = {
      duration: 5,
      type: 'preUpload',
      message: toastMsg,
      onClose: () => {
        clearTimeout(uploadTimeoutHandler)
        setMask(false)
        showUploadToast(cancelToastConfig)
      },
      toastId: getRandomId(),
    }
    const uploadingToastConfig = {
      type: 'uploading',
      message: toastMsg,
      toastId: getRandomId(),
    }
    const cancelToastConfig = {
      type: 'cancel',
      message: toastMsg,
      toastId: getRandomId(),
    }
    const importingToastConfig = {
      type: 'importing',
      message: { ...toastMsg, progress: '0' },
      toastId: `gift-${systemNo}`,
    }
    const bodyFormData = new FormData()

    bodyFormData.append('ImportFile', file)
    setUploadModal(false)
    showUploadToast(preUploadToastConfig)
  }

const handleDefaultSubmit = (event) => {
  event.preventDefault()
  event.stopPropagation()
}

const handleIsLackOfRules = (setting) => {
  const { type, codeGetType, prefix, length } = setting
  const isLackOfUserTypeData = type === buildType.user && !codeGetType
  const isLackOfSystemTypeData =
    type === buildType.system && (!prefix || !length || !codeGetType)
  const isLackOfRules = !type || isLackOfUserTypeData || isLackOfSystemTypeData
  return isLackOfRules
}

export {
  handleValid,
  handleGenerate,
  handleDefaultSubmit,
  handleUploadCsv,
  handleIsLackOfRules,
}
