import { useRef } from 'react'
import { Button, Input } from 'components/units'
import { color } from 'assets/styles/Variable/Color'
import BatchUploadModalStyle from './BatchUploadModalStyle'
import { downloadCsv } from 'helpers/common'

/**
 * @description 批次下載 Modal
 * @param isShowBatchUploadModal 是否顯示 Modal
 * @param setIsShowBatchUploadModal 設定是否顯示 Modal
 * @param onUploadCsvFile
 * @returns {JSX.Element}
 * @constructor
 */
export default function BatchUploadModal({
  isShowBatchUploadModal = false,
  setIsShowBatchUploadModal = () => {},
  onUploadCsvFile = () => {},
}) {
  function onClosed() {
    setIsShowBatchUploadModal(false)
  }

  /**
   * @description 產生 CSV 檔案範例
   * @param rowNumber
   * @returns {string}
   */
  const generateCsvExample = (rowNumber = 2) => {
    const rowTitle =
      '會員編號, 名稱, 增/減 (I/D), 點數 (上限六位數), 補登說明, 納入上限：T/F \r\n'
    let rowContent = ''
    for (let i = 0; i <= rowNumber; i++) {
      rowContent += `AAA010000${String(i)}, 快閃店抽獎贈點, ${
        i % 2 === 0 ? 'D' : 'I'
      }, 100, 快閃電小遊戲得獎, T\r\n`
    }
    return rowTitle + rowContent
  }

  function onClickDownloadExample() {
    downloadCsv({
      data: generateCsvExample(),
      fileName: '補登點數_匯入範本',
      isAppendTime: false,
    })
  }

  return (
    <BatchUploadModalStyle
      backdrop="static"
      className="batch-upload-modal"
      show={isShowBatchUploadModal}
      titleText="批次上傳"
      onClose={onClosed}
      customConfirm={() => <UploadBtn onChange={onUploadCsvFile} />}
    >
      <ol className="text-left">
        <li>檔案格式限 csv 檔，檔案大小限 ≤ 25MB</li>
        <li>
          <span>欄位設定說明，詳見</span>
          <i
            title="匯入範本說明 (下載)"
            className="ml-1"
            onClick={onClickDownloadExample}
          >
            匯入範本說明 (下載)
          </i>
        </li>
      </ol>
    </BatchUploadModalStyle>
  )
}

function UploadBtn({ onChange }) {
  const inputRef = useRef(null)

  return (
    <>
      <Button variant={color.primary} onClick={() => inputRef.current.click()}>
        上傳檔案
      </Button>
      <Input
        onClick={(e) => {
          e.target.value = ''
        }}
        formControlOption={{
          style: { display: 'none' },
          ref: inputRef,
          type: 'file',
          accept: '.csv',
          onChange: (e) => {
            const [file] = e.currentTarget.files
            typeof onChange === 'function' && onChange(file)
          },
        }}
      />
    </>
  )
}
