import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import withPermission from 'components/hocs/withPermission'

const SubNavLink = styled(NavLink)`
  background: #f2f2f2;
  height: 40px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgba(51, 51, 51, 0.66);
  font-size: 14px;
  font-family: 'Noto Sans TC';

  &.selected {
    color: #4b65a6;
    border-right: 2px solid #4b65a6;
    background-color: rgba(75, 101, 166, 0.1);
  }
  &:hover {
    background-color: rgba(75, 101, 166, 0.1);
    border-right: 2px solid #4b65a6;
    cursor: pointer;
    color: ${colorObj.primary};
  }
`

function SubNavItem({ subNavItem, onHomeData }) {
  const { permissionKeys, path, icon, id, title, isActive } = subNavItem
  const SubNavPermission = withPermission(SubNavLink, permissionKeys)

  return (
    <SubNavPermission
      exact
      to={path}
      activeClassName="selected"
      key={`item.title-${id}`}
      isActive={() => {
        return isActive || false
      }}
      onMouseDown={onHomeData}
    >
      {icon}
      <span className="ml-1">{title}</span>
    </SubNavPermission>
  )
}

SubNavItem.propTypes = {
  subNavItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.element,
    id: PropTypes.string.isRequired,
    permissionKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    linkedPage: PropTypes.arrayOf(PropTypes.string),
    isActive: PropTypes.bool.isRequired,
  }).isRequired,
  onHomeData: PropTypes.func.isRequired,
}

export default SubNavItem
