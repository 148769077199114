import PropTypes from 'prop-types'
import { colorObj } from 'assets/styles/Variable/Color'

export default function ToastContent({ successCount, failCount }) {
  return (
    <>
      {successCount} 筆資料成功；
      <span style={{ color: colorObj.danger }}>{failCount} 筆資料失敗</span>
    </>
  )
}

ToastContent.propTypes = {
  successCount: PropTypes.number,
  failCount: PropTypes.number,
}

ToastContent.defaultProps = {
  successCount: 0,
  failCount: 0,
}
