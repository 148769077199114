import axios from 'axios'
import { toast } from 'react-hot-toast'
import { getUrl, getAuthorization } from 'api/ApiCommon'
import {
  clearAllLocalStorage,
  toastErrorContent,
  getRandomId,
} from '../helpers/common'

const fetchData = (url, method, body, optionalProps, ...props) => {
  const authorization = getAuthorization()
  const cancelTokenProps = optionalProps?.token ?? null
  if (!cancelTokenProps && optionalProps) props = [...props, optionalProps]
  const parameters = (function () {
    let propsObj = {}
    if (props) {
      for (const propItem of props) {
        const keyName = Object.keys(propItem)[0]
        propsObj[keyName] = propItem[keyName]
      }
      if (authorization) {
        propsObj = {
          ...propsObj,
          headers: {
            ...propsObj.headers,
            authorization,
          },
        }
      }
    }

    return propsObj
  })()

  const signal = props[0]?.signal || null
  return axios({
    url: getUrl(url),
    method,
    data: body,
    crossDomain: true,
    signal,
    cancelToken: cancelTokenProps,
    ...parameters,
  })
    .then((rsp) => {
      const { status } = rsp
      // 系統維護中
      if (status === 204) {
        window.location.reload()
      }
      return rsp
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return Promise.reject({
          isCancel: true,
        })
      }
      const { status } = err.response
      if (status === 401) {
        clearAllLocalStorage()
        window.location.href = '/login'
      } else {
        const toastId = getRandomId()
        let msg = ''
        if (status >= 500) {
          msg = 'Server Error'
        } else if (status === 429) {
          toast.remove()
          msg = '系統忙碌中，請重新整理頁面'
        } else if (status !== 400) {
          msg = 'Client Error'
        }
        const showMsg = err?.data?.msg || msg
        msg &&
          toast.error(toastErrorContent(status, showMsg, toastId), {
            id: toastId,
          })

        return Promise.reject(err.response)
      }
    })
}

export { fetchData }
