import styled from 'styled-components'
import { colorObj, method } from 'assets/styles/Variable/Color'

const { colorHandle } = method
const LocalStyle = styled.div`
  width: 100%;
  margin: 24px 40px 24px;

  h3 {
    color: ${colorObj.primary};
  }
  .member-num-card-col {
    min-width: calc(100% / 4);

    .member-num-card {
      > h5 {
        font-family: 'PingFangTC-Regular', 'PingFang TC', sans-serif;
        color: ${colorHandle('dark', colorObj, 0.66) || 'inherit'};
      }
      > p {
        font-family: 'PingFangTC-Semibold', 'PingFang TC Semibold',
          'PingFang TC', sans-serif;
        font-weight: 650;
      }
    }
  }
  @media (max-width: 767px) {
    .member-num-card-col {
      min-width: calc(100% / 2);
    }
  }
`

export default LocalStyle
