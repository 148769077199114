import { toast } from 'react-hot-toast'
import { toastErrorContent } from 'helpers/common'
import { fetchData } from 'api/ApiRoute'
import { commonFetchProps, parserUrl } from 'api/ApiCommon'

const pointHistoryUrl = {
  list: 'member/pointhistory',
  additionalPoint: 'member/pointhistory',
  uploadFile: 'member/pointhistory/import',
}

const handleError400 = (res) => {
  if (res.status === 400 && res?.data?.msg) {
    toast.error((t) => toastErrorContent(res.status, res.data.msg, t.id))
  }
  return Promise.reject(res.data ?? res)
}

/**
 * @description 點數歷程列表 API method
 * @param brandId
 * @param payload
 * @returns {PromipaginationPropsse<any>}
 */

const getPointHistoryListApi = async (payload) => {
  try {
    const res = await fetchData(
      parserUrl(pointHistoryUrl.list, {}, payload),
      'GET',
      null,
      commonFetchProps
    )

    return res?.data?.data
  } catch (error) {
    handleError400(error)
  }
}

function getCustomIdApi(customId, brandId) {
  return fetchData(
    `member/${customId}/basic?brandId=${brandId}`,
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

function additionalPointApi(payload) {
  return fetchData(
    pointHistoryUrl.additionalPoint,
    'POST',
    payload,
    commonFetchProps
  ).catch(handleError400)
}

function uploadFileApi(formData, cancelToken) {
  return fetchData(
    pointHistoryUrl.uploadFile,
    'POST',
    formData,
    cancelToken
  ).catch(handleError400)
}

// 取得點數歷程詳細資料
const getPointHistoryDetail = async ({ brandId, pointRecordId }) => {
  try {
    const rsp = await fetchData(
      `member/pointhistory/${pointRecordId}?brandId=${brandId}`,
      'GET',
      null,
      commonFetchProps
    )
    return rsp?.data
  } catch (error) {
    handleError400(error)
  }
}

export {
  getPointHistoryListApi,
  getCustomIdApi,
  additionalPointApi,
  uploadFileApi,
  getPointHistoryDetail,
}
