import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import * as format from './format'

const dateFormatter = (cell) => {
  return <>{format.date(cell)}</>
}

const dateTimeFormatter = (cell) => {
  return <>{format.dateTime(cell)}</>
}

const currencyFormatter = (cell) => {
  return <>{format.currency(cell)}</>
}

const numberFormatter = (cell) => {
  return <>{format.number(cell)}</>
}
// 隨著狀態的不同會變文字顏色
const statusFormatter = (
  cell,
  successCondition,
  failCondition,
  remindCondition,
  hasSpot = true
) => {
  let color = colorObj.darkGray
  // 綠
  if (successCondition) {
    color = colorObj.success
  }
  // 紅
  else if (failCondition) {
    color = colorObj.danger
  }
  // 灰
  else if (remindCondition) {
    color = colorObj.darkGray
  }
  // 黑
  else {
    color = colorObj.dark
  }
  const spotStyle = {
    display: 'inline-block',
    width: '6px',
    height: '6px',
    minWidth: '6px',
    borderRadius: '50%',
    backgroundColor: color,
  }

  return (
    <div className="d-inline-flex align-items-center">
      {hasSpot && <span className="spot mr-2" style={spotStyle} />}
      <span style={{ color }}>{cell}</span>
    </div>
  )
}
// 文字內容過多的部分以...來呈現
const ellipsisFormatter = (cell, row = 1) => {
  const style = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: row,
    WebkitBoxOrient: 'vertical',
  }
  return <div style={style}>{cell}</div>
}

/**
 * 根據指定的儲存格數字值和額外樣式，格式化並返回左對齊的樣式物件。
 * @param {Object} options - 函式的選項物件，含 cell、extraStyle。
 * @param {number} options.cell - 要格式化的數字值。
 * @param {Object} options.extraStyle - 額外的樣式物件。
 * @returns {Object} - 格式化後的樣式物件，包含左對齊樣式和可能的顏色樣式。
 * @description
 * 此函式用於 react-bootstrap-table2 的 column 的 style 屬性時，如果不傳入 cell，請直接呼叫使用。
 * 避免直接指定在 {style:formatNumberLeftStyle} 避免套件傳入不符合格式的 param
 */
const formatNumberLeftStyle = ({ cell, extraStyle } = {}) => {
  let style = { ...extraStyle, textAlign: 'end' }
  if (cell && typeof cell === 'number') {
    style = { ...style, color: cell < 0 ? colorObj.danger : colorObj.dark }
  }
  return style
}

/**
 * 根據指定的額外樣式，格式化並返回左對齊的文本樣式物件。
 * @param {Object} extraStyle - 額外的樣式物件，用於自訂文本樣式。
 * @returns {Object} - 格式化後的文本樣式物件，包含左對齊樣式和額外樣式的合併。
 * @description
 * 此函式用於 react-bootstrap-table2 的 column 的 style 屬性時，如果不傳入 cell，請直接呼叫使用。
 * 避免直接指定在 {style:formatTextLeftStyle} 避免套件傳入不符合格式的 param
 */
const formatTextLeftStyle = (extraStyle) => {
  return {
    ...extraStyle,
    textAlign: 'end',
  }
}

const CellWrapper = styled.div`
  max-width: 300px;
  .name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .content {
    color: ${colorObj.darkGray};
  }

  .id {
    margin-right: 10px;
  }

  .gap {
    margin: 0 5px;
  }
`

/**
 * 配合 Table 處理「名稱」 cell 顯示的元件
 * 由於套件的處理方式是接收 callback 呼叫來渲染，因此配合其方式處理
 * 參考：https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/column-props.html#columnformatter-function
 */
const tableNameCell = (cell) => {
  return (
    <CellWrapper>
      <div className="name">{cell?.name}</div>
      <div className="content">
        <span className="id">{cell?.customId}</span>
        <span>{cell?.color}</span>
        <span className="gap">|</span>
        <span>{cell?.size}</span>
      </div>
    </CellWrapper>
  )
}

export {
  currencyFormatter,
  dateFormatter,
  dateTimeFormatter,
  numberFormatter,
  statusFormatter,
  ellipsisFormatter,
  formatNumberLeftStyle,
  formatTextLeftStyle,
  tableNameCell,
}
