import { createGlobalStyle } from 'styled-components'
import { dynamicStyle } from './GlobalDynamicStyle'
import { staticStyle } from './GlobalStaticStyle'

const GlobalStyle = createGlobalStyle`
    ${staticStyle};
    ${(props) => dynamicStyle(props)};
`

export default GlobalStyle
