import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { getMemberOutline } from 'api/ApiMain'
import { number } from 'helpers/format'
import { MEMBER_OUTLINE } from 'store/constants'

// 多個模組中的 領取 / 兌換名單的詳細頁，會使用到會員大綱的資料
export const memberOutlineInitState = {
  memberOutlineRsp: {
    data: {
      outline: {
        customerId: '',
        birthInfo: {
          age: 0,
          yearMonth: '',
        },
        gender: '',
        pointBalance: 0,
        customerUnitPrice: 0,
        lastDealDate: '',
        registeredDate: '',
        shopName: '',
      },
    },
    code: null,
    msg: '',
    errors: {},
    isFetch: false,
  },
}

const getMemberOutlineThunk = createAsyncThunk(
  `${MEMBER_OUTLINE}/getMemberOutline`,
  async ({ memberId, brandId }, { rejectWithValue }) => {
    try {
      const rsp = await getMemberOutline(memberId, { brandId })
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

const memberOutlineSlice = createSlice({
  name: MEMBER_OUTLINE,
  initialState: memberOutlineInitState,
  reducers: {
    resetMemberOutline: (state) => {
      state.memberOutlineRsp = memberOutlineInitState.memberOutlineRsp
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === getMemberOutlineThunk.fulfilled.type ||
        action.type === getMemberOutlineThunk.rejected.type,
      (state, action) => {
        state.memberOutlineRsp = action.payload
        state.memberOutlineRsp.isFetch = true
      }
    )
  },
})

// 攤平資料
const memberOutlineSelector = createSelector(
  (state) => state[MEMBER_OUTLINE].memberOutlineRsp,
  (memberOutlineRsp) => {
    const { outline: outlineData = {} } = memberOutlineRsp.data ?? {}
    const isMemberExist = !!memberOutlineRsp.data && memberOutlineRsp.isFetch

    return {
      memberOutlineFetch: memberOutlineRsp.isFetch,
      customerId: outlineData.customerId ?? '',
      age: outlineData.birthInfo?.age ?? 0,
      yearMonth: outlineData.birthInfo?.yearMonth ?? '',
      gender: outlineData.gender ?? '',
      pointBalance: number(outlineData.pointBalance ?? 0),
      customerUnitPrice: number(outlineData.customerUnitPrice ?? 0),
      lastDealDate: outlineData.lastDealDate ?? '',
      registeredDate: outlineData.registeredDate ?? '',
      shopName: outlineData.shopName ?? '',
      isMemberExist,
    }
  }
)

export { memberOutlineSelector, getMemberOutlineThunk }
export const { resetMemberOutline } = memberOutlineSlice.actions

export default memberOutlineSlice.reducer
