import { useCallback, useContext, useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as IconUser } from 'assets/images/header_navbar/icon_user.svg'
import { ReactComponent as IconCompany } from 'assets/images/icon_company.svg'
import { ReactComponent as IconStar } from 'assets/images/icon_star.svg'
import { ReactComponent as IconStore } from 'assets/images/icon_store.svg'
import imgLogo from 'assets/images/img-logo.png'
import ChangePassword from 'components/pages/Index/ChangePassword'
import { StyleDropdown } from 'components/units/Dropdown'
import { handleCloseToast } from 'components/units/Toast'
import {
  clearAllLocalStorage,
  clearAllSessionStorage,
  clearLocalStorage,
  getLocalStorage,
  getSessionStorage,
  setSessionStorage,
  setHomeDataStorage,
} from 'helpers/common'
import { RootContext } from 'RootContext'
import {
  getAuthCompaniesThunk,
  getAuthCompanyBrandsThunk,
  getAuthCompanyBrandShopsThunk,
  getAuthThunk,
} from 'store/auth/authAsync'
import {
  authAccountSelector,
  navbarDataSelector,
  authAndOrgStatusSelector,
} from 'store/auth/authSelector'
import { authLogout, updateAuthAccount } from 'store/auth/authSlice'
import {
  getPermissionsThunk,
  resetPermissions,
} from 'store/permission/permissionSlice'
import {
  stopConnection,
  batchModuleSelector,
} from 'store/serial-batch-upload/serialBatchUploadSlice'
import LocalStyle from './NavbarStyle'

const getStorageHomeData = () => {
  let dataString = getSessionStorage('homeData')
  // 表示是按滑鼠右鍵另開新分頁
  if (!getSessionStorage('homeData') && getLocalStorage('homeData')) {
    dataString = getLocalStorage('homeData')
    setTimeout(() => {
      clearLocalStorage('homeData')
    }, 2000)
  }
  return dataString
}

function parseOrUseDefaultInt(value, defaultValue) {
  return parseInt(Number.isNaN(value) ? defaultValue : value, 10)
}

const navbarIconConfig = {
  company: <IconCompany className="mr-2" />,
  brand: <IconStar className="mr-2" />,
  shop: <IconStore className="mr-2" />,
}

function AppNavbar() {
  const dispatch = useDispatch()
  const rootData = useContext(RootContext)
  const { resetRootValue, updateRootValue } = rootData
  const [showPwdModal, setShowPwdModal] = useState(false)
  const { hasFetchedAuth, hasFetchedShop, isSwitchShop } = useSelector(
    authAndOrgStatusSelector
  )
  const {
    account,
    companyId,
    companyIndex,
    companies,
    brandId,
    brandIndex,
    brands,
    shopId,
    shopIndex,
    shops,
  } = useSelector(authAccountSelector)
  const navbarConfig = useSelector(navbarDataSelector)
  const connectingModules = useSelector(batchModuleSelector)
  const browserOrgInfo = getStorageHomeData()

  const usePwdModal = () => ({ showPwdModal, setShowPwdModal })

  const handleCompanyMenu = async (selectedIndex) => {
    await dispatch(
      updateAuthAccount({
        companyIndex: selectedIndex,
      })
    )
    await dispatch(getAuthCompanyBrandsThunk())
    await dispatch(getAuthCompanyBrandShopsThunk())
  }

  const handleBrandMenu = async (selectedIndex) => {
    await dispatch(
      updateAuthAccount({
        brandIndex: selectedIndex,
      })
    )
    await dispatch(getAuthCompanyBrandShopsThunk())
  }

  const handleShopMenu = (selectedIndex) => {
    dispatch(updateAuthAccount({ shopIndex: selectedIndex }))
  }

  const handleOrgMenu = ({ type, selectedIndex }) => {
    const dropdownFunc = {
      company: handleCompanyMenu,
      brand: handleBrandMenu,
      shop: handleShopMenu,
    }
    dropdownFunc[type](selectedIndex)
  }

  const handleLogout = useCallback(() => {
    clearAllLocalStorage()
    clearAllSessionStorage()
    resetRootValue()
    dispatch(authLogout())
    connectingModules.forEach((module) => {
      dispatch(stopConnection({ module }))
    })
    handleCloseToast()
    window.location.href = '/login'
  }, [dispatch, connectingModules, resetRootValue])

  const handleAccountDropdown = (eventKey) => {
    const dropdownEvent = {
      updatePassword: () => {
        setShowPwdModal(true)
      },
      logout: handleLogout,
    }
    dropdownEvent[eventKey]()
  }

  const handleUpdateSessionStorage = useCallback(
    (orgInfo) => {
      let defaultValues = {
        name: '',
        companyIndex: 0,
        brandIndex: 0,
        shopIndex: 0,
        companyMenuItems: [],
        brandMenuItems: [],
        shopMenuItems: [],
      }
      try {
        // 若 browser 有資料，則取 browser 資料當作預設值
        const parsedData = JSON.parse(browserOrgInfo)?.idListData?.[0]
        if (parsedData) {
          defaultValues = { ...defaultValues, ...parsedData }
        }
      } catch (e) {
        console.error('Error parsing browserOrgInfo:', e)
      }
      const mergedValues = {
        ...defaultValues,
        ...orgInfo,
        companyIndex: parseOrUseDefaultInt(
          orgInfo.companyIndex,
          defaultValues.companyIndex
        ),
        brandIndex: parseOrUseDefaultInt(
          orgInfo.brandIndex,
          defaultValues.brandIndex
        ),
        shopIndex: parseOrUseDefaultInt(
          orgInfo.shopIndex,
          defaultValues.shopIndex
        ),
      }
      setSessionStorage(
        'homeData',
        JSON.stringify({ idListData: [mergedValues] })
      )
    },
    [browserOrgInfo]
  )

  const handleInitOrgInfo = useCallback(async () => {
    await dispatch(getAuthCompaniesThunk())
    await dispatch(getAuthCompanyBrandsThunk())
    await dispatch(getAuthCompanyBrandShopsThunk())
  }, [dispatch])

  useEffect(() => {
    if (!hasFetchedAuth) {
      dispatch(getAuthThunk())
    } else if (!account.isAuth) {
      handleLogout()
    }
  }, [hasFetchedAuth, dispatch, handleLogout, account.isAuth])

  useEffect(() => {
    // 負責重整頁面或另開分頁，要取得公司、品牌、商店資料的 init
    const handleSyncOrgInfo = async (orgInfo) => {
      const {
        companyIndex: browserCompIndex,
        brandIndex: browserBrandIndex,
        shopIndex: browserShopIndex,
        companyMenuItems,
        brandMenuItems,
        shopMenuItems,
      } = orgInfo.idListData[0]

      dispatch(
        updateAuthAccount({
          companyIndex: browserCompIndex,
          brandIndex: browserBrandIndex,
          shopIndex: browserShopIndex,
          companies: companyMenuItems,
          brands: brandMenuItems,
          shops: shopMenuItems,
        })
      )
      handleInitOrgInfo()
    }
    // 有 browserOrgInfo 資料代表是重整頁面或另開分頁
    if (account.isAuth && browserOrgInfo && !hasFetchedShop) {
      // 不論有 session or local 資料，都同步更新 session 一次
      setSessionStorage('homeData', browserOrgInfo)
      const parseOrgInfo = JSON.parse(browserOrgInfo)
      handleSyncOrgInfo(parseOrgInfo)
    }
  }, [
    dispatch,
    handleInitOrgInfo,
    account.isAuth,
    hasFetchedShop,
    browserOrgInfo,
  ])

  useEffect(() => {
    // 負責初次載入頁面，要取得公司、品牌、商店資料的 init
    // 沒有 browserOrgInfo 資料代表是初次載入頁面
    if (account.isAuth && !browserOrgInfo && !hasFetchedShop) {
      handleInitOrgInfo()
    }
  }, [
    dispatch,
    browserOrgInfo,
    handleInitOrgInfo,
    account.isAuth,
    hasFetchedShop,
  ])

  useEffect(() => {
    // 監聽 redux store 資料變化，同步更新所有資料
    if (account.isAuth && hasFetchedShop && isSwitchShop) {
      dispatch(updateAuthAccount({ prevShopId: shops?.[shopIndex]?.id }))
      dispatch(resetPermissions())
      updateRootValue((prevState) => ({
        ...prevState,
        account,
        companyId,
        brandId,
        shopId,
        companyMenuItems: companies,
        brandMenuItems: brands,
        shopMenuItems: shops,
      }))
      handleUpdateSessionStorage({
        name: account?.name,
        companyIndex,
        brandIndex,
        shopIndex,
        companyMenuItems: companies,
        brandMenuItems: brands,
        shopMenuItems: shops,
      })
      dispatch(getPermissionsThunk(brandId))
    }
  }, [
    account,
    brandId,
    brandIndex,
    brands,
    companies,
    companyId,
    companyIndex,
    dispatch,
    handleUpdateSessionStorage,
    hasFetchedShop,
    isSwitchShop,
    shopId,
    shopIndex,
    shops,
    updateRootValue,
  ])

  return (
    <LocalStyle>
      <Navbar className="app-header-navbar" variant="light">
        <div className="navbar-brand">
          <Navbar.Brand href="/" onMouseDown={setHomeDataStorage}>
            <img className="navbar-logo" src={imgLogo} alt="img-logo" />
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav>
            {navbarConfig.map((item) => {
              return (
                <StyleDropdown
                  key={item.type}
                  html={
                    <>
                      {navbarIconConfig[item.type]}
                      {item.selectedName}
                    </>
                  }
                  menuItems={item.menuItems}
                  dropdownOption={{
                    onSelect(eventKey) {
                      handleOrgMenu({
                        type: item.type,
                        selectedIndex: eventKey,
                      })
                    },
                  }}
                />
              )
            })}
            <StyleDropdown
              html={
                <>
                  <IconUser className="mr-2" />
                  {rootData?.account?.name}
                </>
              }
              menuItems={[
                {
                  text: '修改密碼',
                  dropdownItemOption: {
                    eventKey: 'updatePassword',
                  },
                },
                {
                  text: '登出',
                  dropdownItemOption: {
                    eventKey: 'logout',
                  },
                },
              ]}
              dropdownOption={{
                onSelect(eventKey) {
                  handleAccountDropdown(eventKey)
                },
              }}
            />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {showPwdModal && (
        <ChangePassword handleLogout={handleLogout} usePwdModal={usePwdModal} />
      )}
    </LocalStyle>
  )
}
export default AppNavbar
