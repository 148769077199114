import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import {
  Col,
  Container,
  Row,
  Tabs,
  Tab,
  Nav,
  Form,
  Modal,
} from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import useField from 'hooks/useField'
import LocalStyle, { CustomizedFieldListItemStyle } from './NewStyle'
import { ReactComponent as IconCancel } from '../../assets/images/button/icon_cancel.svg'
import { ReactComponent as IconSave } from '../../assets/images/button/icon_save.svg'
import iconModalClose from '../../assets/images/modal/icon_modalClose.svg'
import { getMemberOnce, postMember } from '../../api/ApiMain'
import { RootContext } from 'RootContext'
import StyleFieldTypeSwitch from '../../components/widgets/FieldTypeSwitch'
import {
  dataTypeTranslate,
  getRandomId,
  toastErrorContent,
} from '../../helpers/common'
import { useDispatch, useSelector } from 'react-redux'
import {
  getGlobalState,
  updateDataLoading,
  updatePageLoading,
} from 'store/global/globalSlice'
import { Breadcrumb, Button, Loader } from 'components/units'
import { PAGE_BREADCRUMB } from 'constant'
import { Basic } from 'components/templates'
import { ButtonList } from 'components/widgets'

function SideSection({ handleModalShow, handleSubmit }) {
  return (
    <ButtonList className="justify-content-end">
      <Button
        variant="outline-gray"
        style={{ minWidth: 'auto' }}
        onClick={handleModalShow}
      >
        <IconCancel />
        取消
      </Button>
      <Button
        variant="outline-primary"
        style={{ minWidth: 'auto' }}
        onClick={handleSubmit}
      >
        <IconSave />
        儲存
      </Button>
    </ButtonList>
  )
}

function MemberNew() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isDataLoading } = useSelector(getGlobalState)

  // global data
  const rootData = useContext(RootContext)

  // modal視窗顯示控制
  const [isShowModal, setShowModel] = useState(false)

  const formRef = useRef(null)

  // 表單form是否驗證欄位規則
  const [validated, setValidated] = useState(false)

  // 固定欄位的資料
  const [fixFieldsData, setFixFieldsData] = useState(null)

  // 彈性欄位的資料
  const [optionalFieldsData, setOptionalFieldsData] = useState(null)

  // 客制欄位的資料
  const [customizedFieldsSectionData, setCustomizedFieldsSectionData] =
    useState(null)

  // 客製化欄位的對照組欄位，
  const [customizedFieldsData, setCustomizedFieldsData] = useState(null)

  const fieldData = useField({ optional: null, customized: null }, validated)
  const {
    inputValues,
    handleInitFieldValue,
    handleInitOptionalFieldValue,
    getFixField,
    getOptionalField,
    getCustomizedFieldSection,
    getCustomizedField,
  } = fieldData
  // 基本資料的固定欄位
  const handleBaseDataFixField = (fixFieldsData) => {
    // form item 內容
    const groupItemContent = []
    const { fieldTypeSwitchProps, titles } = getFixField(fixFieldsData, {
      custom_id: {
        disabled: memoCustomIdType === 1, // 1為系統決定會員編號，2為手動輸入會員編號
      },
      shop: {
        options: rootData?.shopMenuItems,
      },
    })
    if (fieldTypeSwitchProps && Object.keys(fieldTypeSwitchProps).length > 0) {
      for (const [
        itemIndex,
        fieldTypeSwitchProp,
      ] of fieldTypeSwitchProps.entries()) {
        const title = titles[itemIndex]
        const { required, name } = fieldTypeSwitchProp.formControlOption
        const newFieldTypeSwitchProp = {
          ...fieldTypeSwitchProp,
          formControlOption: {
            ...fieldTypeSwitchProp.formControlOption,
            // 固定欄位在會員新增頁，強制為可編輯，不隨api的canmodify變化，但會員編號例外
            disabled:
              name === 'custom_id'
                ? fieldTypeSwitchProp.formControlOption.disabled
                : false,
          },
        }
        const content = [
          <Fragment
            key={`fixfield-item-content-${
              fieldTypeSwitchProp.formControlOption.name ?? itemIndex
            }`}
          >
            {title && (
              <Form.Label className="label-title mb-0">
                {title}
                {required && <span className="font-danger">*</span>} :
              </Form.Label>
            )}
            <StyleFieldTypeSwitch {...newFieldTypeSwitchProp} />
          </Fragment>,
        ]
        if (title) {
          if (name !== 'custom_id_type') {
            groupItemContent.push(content)
          }
        } else {
          groupItemContent[groupItemContent.length - 1].push(content)
        }
      }
      // form group容器
      const groupItems = []
      for (const [fieldIndex] of groupItemContent.entries()) {
        groupItems.push(
          <Form.Group key={`fixfield-item-${fieldIndex}}`}>
            {groupItemContent[fieldIndex].map((itemContent) => {
              return itemContent
            })}
          </Form.Group>
        )
      }
      return groupItems
    }
  }
  // 基本資料的固定欄位中，獲得會員編號類型
  const getBaseDataFixfieldCustomIdType = (fixFieldsData, brandId) => {
    if (fixFieldsData && brandId) {
      const customIdTypeItemData = fixFieldsData.filter(
        (item) => item.name === 'custom_id_type'
      )
      return customIdTypeItemData[0].value
    }
  }
  const memoCustomIdType = useMemo(
    () => getBaseDataFixfieldCustomIdType(fixFieldsData, rootData.brandId),
    [fixFieldsData, rootData.brandId]
  )
  // 基本資料的彈性欄位
  const handleBaseDataOptionalField = (optionalFieldsData) => {
    // form item 內容
    const groupItemContent = []
    const { fieldTypeSwitchProps, titles } =
      getOptionalField(optionalFieldsData)
    if (fieldTypeSwitchProps && Object.keys(fieldTypeSwitchProps).length > 0) {
      for (const [
        itemIndex,
        fieldTypeSwitchProp,
      ] of fieldTypeSwitchProps.entries()) {
        const title = titles[itemIndex]
        // 由於lit後端的新增、編輯邏輯沒有拆開，因此在此新增頁，這邊一律讓 disabled 關閉
        fieldTypeSwitchProps[itemIndex].formControlOption.disabled = false
        const content = [
          <Fragment
            key={`optionalfield-item-content-${
              fieldTypeSwitchProp.formControlOption.name ?? itemIndex
            }`}
          >
            {title && (
              <Form.Label className="label-title mb-0">
                {title}
                {optionalFieldsData[itemIndex].isRequired && (
                  <span className="font-danger">*</span>
                )}{' '}
                :
              </Form.Label>
            )}
            <StyleFieldTypeSwitch {...fieldTypeSwitchProp} />
          </Fragment>,
        ]
        if (title) {
          groupItemContent.push(content)
        } else {
          groupItemContent[groupItemContent.length - 1].push(content)
        }
      }
      // form group容器
      const groupItems = []
      for (const [fieldIndex] of groupItemContent.entries()) {
        groupItems.push(
          <Form.Group key={`optionalfield-item-${fieldIndex}}`}>
            {groupItemContent[fieldIndex].map((itemContent) => {
              return itemContent
            })}
          </Form.Group>
        )
      }
      return groupItems
    }
  }

  // 基本資料的客制欄位區塊對應欄位
  const handleBaseDataCustomizedFieldSection = (
    customizedFieldsSectionData,
    customizedFieldsData
  ) => {
    return getCustomizedFieldSection(
      customizedFieldsSectionData,
      customizedFieldsData
    )
  }
  // 基本資料的客制欄位
  const handleBaseDataCustomizedField = useCallback(
    (article) => {
      let outputContent = null
      if (Array.isArray(article)) {
        outputContent = article.map((articleItem, articleIndex) => {
          const { listTitle, fields } = articleItem
          return (
            <Form.Group key={`customizedfield-list-${articleIndex}}`}>
              <div className="customizedfield-list">
                {listTitle && (
                  <h5 className="customizedfield-list-title fz13">
                    {listTitle}
                  </h5>
                )}
                {Array.isArray(fields) && (
                  <div className="customizedfield-list-article">
                    {getCustomizedField(fields)?.fieldTypeSwitchProps.map(
                      (item, index) => {
                        const { display, fieldType } = fields[index]
                        const { formControlOption } = item
                        return (
                          <CustomizedFieldListItemStyle
                            className="customizedfield-list-item"
                            key={`customizedfield-list-item-${
                              formControlOption.name ?? index
                            }`}
                            fieldType={fieldType}
                          >
                            {display && (
                              <Form.Label className="pr-1">
                                {display}：
                              </Form.Label>
                            )}
                            <StyleFieldTypeSwitch {...item} />
                          </CustomizedFieldListItemStyle>
                        )
                      }
                    )}
                  </div>
                )}
              </div>
            </Form.Group>
          )
        })
      }
      return outputContent
    },
    [getCustomizedField]
  )

  // 儲存
  const handleSubmit = (e, { handleButtonLocalLoading }) => {
    e.preventDefault()
    const form = formRef.current
    // 判斷form的欄位中，有填寫name值及驗證規則(required、pattern)的項目
    const isValidated = form.checkValidity()

    setValidated(true)
    // form的欄位判斷都通過後，再進行特別判斷
    if (isValidated) {
      const postInputValues = JSON.parse(JSON.stringify(inputValues))

      if (postInputValues.optional) {
        /* 彈性欄位處理輸出需為null的情形 */
        for (const [optionalIndex, optionalFieldName] of Object.keys(
          postInputValues.optional
        ).entries()) {
          const fieldType = optionalFieldsData?.[optionalIndex]?.fieldType
          if (
            fieldType === 'checkbox' &&
            Array.isArray(postInputValues.optional[optionalFieldName]) &&
            postInputValues.optional[optionalFieldName].length < 1
          ) {
            postInputValues.optional[optionalFieldName] = dataTypeTranslate(
              postInputValues.optional[optionalFieldName],
              'null'
            )
          }
        }
      }

      if (postInputValues.optional) {
        /* 客制欄位處理輸出需為null的情形 */
        for (const [customizedIndex, customizedFieldName] of Object.keys(
          postInputValues.optional
        ).entries()) {
          const fieldType = optionalFieldsData?.[customizedIndex]?.fieldType
          if (
            fieldType === 'checkbox' &&
            Array.isArray(postInputValues.optional[customizedFieldName]) &&
            postInputValues.optional[customizedFieldName].length < 1
          ) {
            postInputValues.optional[customizedFieldName] = dataTypeTranslate(
              postInputValues.optional[customizedFieldName],
              'null'
            )
          }
        }
      }
      // 將所有值的前後空白移除
      for (const name in postInputValues) {
        const value = postInputValues[name]
        if (typeof value === 'string') {
          postInputValues[name] = value.trim()
        }
      }
      // 生日欄位時區固定為+8
      const birthdayWithTaiwanTimezone = postInputValues.birthday
        ? postInputValues.birthday.replace(/([-+]\d{2}:\d{2})/g, '+08:00')
        : postInputValues.birthday
      postInputValues.birthday = birthdayWithTaiwanTimezone

      const postMemberPromise = postMember({
        ...postInputValues,
        city: {
          code: postInputValues?.city?.code,
          name: postInputValues?.city?.name,
          option_id: postInputValues?.city?.id,
        },
        dist: {
          code: postInputValues?.dist?.code,
          name: postInputValues?.dist?.name,
          option_id: postInputValues?.dist?.id,
        },
        brand_id: rootData?.brandId,
      })
      dispatch(updateDataLoading(true))
      handleButtonLocalLoading(true)
      postMemberPromise
        .then(() => {
          toast.success('會員新增成功')
          history.push('/member')
        })
        .catch((rsp) => {
          const { status } = rsp
          if (status === 400) {
            const toastId = getRandomId()
            toast.error(toastErrorContent(status, rsp?.data?.msg, toastId), {
              id: toastId,
            })
          }
        })
        .finally(() => {
          dispatch(updateDataLoading(false))
          handleButtonLocalLoading(false)
        })
    }
  }
  // 從api獲得該頁資料
  const handleGetMemberOnceData = () => {
    dispatch(updatePageLoading(true))
    dispatch(updateDataLoading(true))
    // 會員新增時，memberId給0
    const getMemberOnceDeferred = getMemberOnce(0, {
      brandId: rootData.brandId,
    })
    getMemberOnceDeferred
      .then((rsp) => {
        // 設定固定欄位內容
        setFixFieldsData(rsp?.fixFields)
        // 設定彈性欄位內容
        setOptionalFieldsData(rsp?.optionalFields)
        // 設定客制欄位的內容
        setCustomizedFieldsSectionData(rsp?.customizedTabs)
        // 設定客制欄位時，要參考的對照欄位(根據這個對照從資料中找出對應欄位)
        setCustomizedFieldsData(rsp?.customizedFields)
        // 欄位初值
        handleInitBaseDataFieldValue(rsp?.fixFields, rsp.optionalFields)
      })
      .catch((rsp) => {
        const { status } = rsp
        if (status === 400) {
          const toastId = getRandomId()
          toast.error(toastErrorContent(status, rsp?.data?.msg, toastId), {
            id: toastId,
          })
        }
      })
      .finally(() => {
        dispatch(updateDataLoading(false))
        dispatch(updatePageLoading(false))
      })
  }
  // 處理初值
  const handleInitBaseDataFieldValue = (fixfieldData, optionalFieldData) => {
    // 固定欄位(主要是新增的時候會員編號有些時候要帶入，而不是空白)
    handleInitFieldValue(fixfieldData, {
      shop: {
        id: rootData?.shopId,
        name: rootData.shopMenuItems.filter(
          (item) => item.id === rootData?.shopId
        )[0]?.name,
      },
    })
    // 彈性欄位(主要是新增的時候會員編號有些時候要帶入，而不是空白)
    handleInitOptionalFieldValue(optionalFieldData)
  }

  const handleModalClose = () => setShowModel(false)
  const handleModalShow = () => setShowModel(true)

  const handleCancelEdit = () => {
    history.push('/member')
  }

  useEffect(() => {
    if (rootData.brandId) {
      handleGetMemberOnceData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootData.brandId, rootData.shopId])

  return (
    <>
      <Basic
        navSection={() => Breadcrumb(PAGE_BREADCRUMB.member.new)}
        sideSection={() => SideSection({ handleModalShow, handleSubmit })}
      >
        <LocalStyle>
          <Row className="align-items-end">
            <Col md="9" className="text-right"></Col>
          </Row>
          <Row>
            <Col />
          </Row>
          <Row>
            <Col>
              <Form noValidate validated={validated} ref={formRef}>
                <Tabs defaultActiveKey="basicInfo" id="noanim-tab-example">
                  <Tab eventKey="basicInfo" title="基本資料">
                    {/* 頁籤內的menu以及其內層nav */}
                    <Loader isHide={!isDataLoading} />
                    <div className="tab-content-inner-nav">
                      <Tab.Container defaultActiveKey={`fixFields-${0}}`}>
                        <Nav>
                          {/* 固定欄位頁籤 */}
                          {fixFieldsData && (
                            <Nav.Item>
                              <Nav.Link
                                eventKey={`fixFields-${0}}`}
                                className="fz14"
                              >
                                詳細資料
                              </Nav.Link>
                            </Nav.Item>
                          )}
                          {/* 客製欄位頁籤 */}
                          {Array.isArray(customizedFieldsSectionData) &&
                            customizedFieldsSectionData.map((item, index) => {
                              const { name } = item
                              return (
                                <Nav.Item
                                  key={`customizedFieldsSectionData-${index}`}
                                >
                                  <Nav.Link
                                    eventKey={`customizedFieldsSectionData-${index}`}
                                    className="fz14"
                                  >
                                    {name}
                                  </Nav.Link>
                                </Nav.Item>
                              )
                            })}
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane
                            eventKey={`fixFields-${0}}`}
                            className="tab-content-base"
                          >
                            {/* 固定欄位 */}
                            {fixFieldsData && (
                              <div className="tab-content-base-fixfields">
                                {handleBaseDataFixField(fixFieldsData)}
                              </div>
                            )}
                            {/* 彈性欄位 */}
                            {Array.isArray(optionalFieldsData) &&
                              optionalFieldsData.length > 0 && (
                                <div className="tab-content-base-optionalfields pt-3">
                                  {handleBaseDataOptionalField(
                                    optionalFieldsData
                                  )}
                                </div>
                              )}
                          </Tab.Pane>
                          {Array.isArray(customizedFieldsSectionData) &&
                            Array.isArray(customizedFieldsData) &&
                            handleBaseDataCustomizedFieldSection(
                              customizedFieldsSectionData,
                              customizedFieldsData
                            ).map((item, index) => {
                              const { article } = item
                              return (
                                <Tab.Pane
                                  key={`customizedFieldsSectionData-${index}`}
                                  className="tab-content-customized"
                                  eventKey={`customizedFieldsSectionData-${index}`}
                                >
                                  {handleBaseDataCustomizedField(article)}
                                </Tab.Pane>
                              )
                            })}
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Tab>
                </Tabs>
              </Form>
            </Col>
          </Row>
        </LocalStyle>
      </Basic>
      <Modal
        show={isShowModal}
        onHide={handleModalClose}
        dialogClassName="modal-confirm"
        centered
      >
        <Modal.Header>
          <Modal.Title>即將離開編輯</Modal.Title>
          <div>
            <img
              src={iconModalClose}
              className="setting-img"
              alt=""
              onClick={handleModalClose}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-confirm-body">
            已編輯的資料不會儲存，
            <br />
            確定離開編輯？
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-gray" onClick={handleModalClose}>
            返回
          </Button>
          <Button
            variant="danger"
            onClick={handleCancelEdit}
            loaderOption={{
              bgColor: 'transparent',
              colorType: '#fff',
            }}
          >
            離開
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MemberNew
