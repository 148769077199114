import {
  Fragment,
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteOpenApi,
  getOpenApi,
  getOpenApiOnce,
  patchOpenApiStop,
  patchOpenApiActive,
  patchOpenApiExtend,
} from 'api/ApiMain'
import { colorObj } from 'assets/styles/Variable/Color'
import { modalSetting } from 'components/pages/SystemConfiguration/CompanyToken/CompanyTokenConfig'
import { TokenModal } from 'components/pages/SystemConfiguration/CompanyToken/TokenModal'
import { Basic, ContentSection } from 'components/templates'
import {
  Breadcrumb,
  Button,
  IconButton,
  NoDataIndication,
  Table,
} from 'components/units'
import { CompanyTokenContext } from 'context/pageContext'
import { objectSort } from 'helpers/common'
import * as format from 'helpers/format'
import { dateFormatter, statusFormatter } from 'helpers/tableElFormat'
import { RootContext } from 'RootContext'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'
import { StyledSideSetion } from './CompanyTokenStyle'

const { modalType, modalConfig } = modalSetting

function NavSection() {
  const BreadcrumbConfig = {
    now: <span className="width-max-content d-inline-block">廠商 Token</span>,
    pathList: [{ title: '系統設定 ', slash: true }],
  }
  return (
    <Breadcrumb
      now={BreadcrumbConfig.now}
      pathList={BreadcrumbConfig.pathList}
    />
  )
}

function SideSection() {
  const companyTokenContext = useContext(CompanyTokenContext)
  const { setModalState, handleGetOpenApi } = companyTokenContext

  const addToken = (fn) => {
    if (typeof fn === 'function') {
      fn(handleGetOpenApi)
    }
  }

  const showTokenModal = () => {
    setModalState({
      show: true,
      titleText: modalConfig.addToken.title,
      closeBtnText: modalConfig.addToken.close,
      closeBtnVisible: true,
      confirmBtnText: modalConfig.addToken.confirm,
      confirmBtnVisible: true,
      childrenType: modalType.addToken,
      onConfirm: addToken,
    })
  }

  return (
    <StyledSideSetion>
      <Button variant="outline-primary" onClick={showTokenModal}>
        新增 Token
      </Button>
    </StyledSideSetion>
  )
}

const getTokenInfo = async (brandId, tokenId, setModalState) => {
  const openApiOnceData = await getOpenApiOnce(brandId, tokenId)
  const {
    state,
    vendorId,
    vendorName,
    validFrom,
    validUnitl,
    token,
    updatedTime,
  } = openApiOnceData

  setModalState({
    show: true,
    variant: 'outline-primary',
    titleText: modalConfig.tokenInfo.title,
    closeBtnText: modalConfig.tokenInfo.close,
    closeBtnVisible: false,
    confirmBtnText: modalConfig.tokenInfo.confirm,
    confirmBtnVisible: true,
    childrenType: modalType.tokenInfo,
    childrenPayload: {
      basicInfo: {
        list: [
          {
            label: '狀態',
            value: state,
            id: 'state',
          },
          {
            label: '廠商編號',
            value: vendorId,
            id: 'vendorId',
          },
          {
            label: '廠商名稱',
            value: vendorName,
            id: 'vendorName',
          },
          {
            label: '啟用日',
            value: format.date(validFrom),
            id: 'validFrom',
          },
          {
            label: '到期日',
            value: format.date(validUnitl),
            id: 'validUnitl',
          },
          {
            label: '廠商 Token',
            value: token,
            id: 'token',
          },
          {
            label: '更新時間',
            value: format.dateTime(updatedTime),
            id: 'updatedTime',
          },
        ],
      },
    },
    onConfirm: (fn) => {
      if (typeof fn === 'function') {
        fn(token)
      }
    },
  })
}

const handleDelete = async (
  brandId,
  row,
  setTableLoading,
  setTableData,
  handleGetOpenApi
) => {
  try {
    setTableLoading(true)
    setTableData([])
    const { tokenId } = row
    await deleteOpenApi(brandId, tokenId)
    handleGetOpenApi()
    toast.success('刪除成功')
  } finally {
    setTableLoading(false)
  }
}

const handleExtend = async (
  brandId,
  row,
  setTableLoading,
  setTableData,
  handleGetOpenApi
) => {
  const { tokenId } = row
  try {
    setTableLoading(true)
    setTableData([])
    await patchOpenApiExtend(brandId, tokenId)
    handleGetOpenApi()
    toast.success('延展成功')
  } finally {
    setTableLoading(false)
  }
}

const handleStop = async (
  brandId,
  row,
  setTableLoading,
  setTableData,
  handleGetOpenApi
) => {
  const { tokenId } = row
  try {
    setTableLoading(true)
    setTableData([])
    await patchOpenApiStop(brandId, tokenId)
    handleGetOpenApi()
    toast.success('停用成功')
  } finally {
    setTableLoading(false)
  }
}

const handleActive = async (
  brandId,
  row,
  setTableLoading,
  setTableData,
  handleGetOpenApi
) => {
  const { tokenId } = row
  try {
    setTableLoading(true)
    setTableData([])
    await patchOpenApiActive(brandId, tokenId)
    handleGetOpenApi()
    toast.success('啟用成功')
  } finally {
    setTableLoading(false)
  }
}

// 表格的按鈕icon內容呈現
const buttonStatusFormatter = (
  cell,
  row,
  rowIndex,
  { brandId, setTableLoading, setTableData, handleGetOpenApi }
) => {
  const sortObjectList = objectSort(cell, [
    'activeBtn',
    'extendBtn',
    'deleteBtn',
    'stopBtn',
  ])
  return (
    <span className="icon-feature-container d-flex align-items-center">
      {sortObjectList.map((listItem) => {
        let triggerElement = null
        const state = Object.keys(listItem)[0]
        if (listItem[state].visible) {
          switch (state) {
            case 'extendBtn':
              triggerElement = (
                <IconButton
                  onClick={() =>
                    handleExtend(
                      brandId,
                      row,
                      setTableLoading,
                      setTableData,
                      handleGetOpenApi
                    )
                  }
                  tooltip="延展效期"
                  type="tableExtend"
                  data-effect="solid"
                />
              )
              break
            case 'deleteBtn':
              triggerElement = (
                <IconButton
                  onClick={() =>
                    handleDelete(
                      brandId,
                      row,
                      setTableLoading,
                      setTableData,
                      handleGetOpenApi
                    )
                  }
                  color={colorObj.lightGray}
                  tooltip="刪除"
                  type="delete"
                  data-effect="solid"
                />
              )
              break
            case 'stopBtn':
              triggerElement = (
                <IconButton
                  onClick={() =>
                    handleStop(
                      brandId,
                      row,
                      setTableLoading,
                      setTableData,
                      handleGetOpenApi
                    )
                  }
                  color={colorObj.lightGray}
                  tooltip="停用"
                  type="stop"
                  data-effect="solid"
                />
              )
              break
            case 'activeBtn':
              triggerElement = (
                <IconButton
                  onClick={() =>
                    handleActive(
                      brandId,
                      row,
                      setTableLoading,
                      setTableData,
                      handleGetOpenApi
                    )
                  }
                  tooltip="啟用"
                  type="tableActive"
                  data-effect="solid"
                />
              )
              break
            default:
              break
          }
        }
        return (
          <Fragment key={`icon-feature-${rowIndex}-${state}`}>
            {triggerElement}
          </Fragment>
        )
      })}
    </span>
  )
}

// 表格的column設定
const columns = (
  setModalState,
  setTableLoading,
  setTableData,
  handleGetOpenApi,
  brandId
) => {
  const columnEvents = {
    onClick: (e, column, columnIndex, row) => {
      const { tokenId } = row
      getTokenInfo(brandId, tokenId, setModalState)
    },
  }
  return [
    {
      dataField: 'vendorId',
      text: '廠商編號',
      events: columnEvents,
      classes: 'cursor-pointer',
    },
    {
      dataField: 'vendorName',
      text: '廠商名稱',
      events: columnEvents,
      classes: 'cursor-pointer',
    },
    {
      dataField: 'validFrom',
      text: '啟用日',
      events: columnEvents,
      classes: 'cursor-pointer',
      formatter: dateFormatter,
    },
    {
      dataField: 'validUnitl',
      text: '到期日',
      events: columnEvents,
      classes: 'cursor-pointer',
      formatter: dateFormatter,
    },
    {
      dataField: 'state',
      text: '狀態',
      events: columnEvents,
      classes: 'cursor-pointer',
      formatter: (cell) => {
        let result = ''
        let statusParameter = [false, false, true]
        switch (cell) {
          case '已停用':
            result = cell
            break
          case '啟用中':
            result = cell
            statusParameter = [true, false, false]
            break
          default:
            break
        }
        return statusFormatter(result, ...statusParameter)
      },
    },
    {
      dataField: 'funcButton',
      text: '',
      formatter: buttonStatusFormatter,
      formatExtraData: {
        setModalState,
        setTableLoading,
        setTableData,
        brandId,
        handleGetOpenApi,
      },
    },
  ]
}

function CompanyToken() {
  const rootData = useContext(RootContext)
  const dispatch = useDispatch()
  const { isDataLoading: tableLoading } = useSelector(getGlobalState)
  const [modalState, setModalState] = useState({
    show: false,
    titleText: '',
    closeBtnText: '',
    closeBtnVisible: true,
    confirmBtnText: '',
    confirmBtnVisible: true,
    variant: '',
    childrenType: '',
    childrenPayload: {},
    onConfirm: null,
  })
  const [tableData, setTableData] = useState([])

  const handleGetOpenApi = useCallback(async () => {
    const brandId = rootData?.brandId
    if (brandId) {
      try {
        setTableData([])
        dispatch(updateDataLoading(true))
        const openApiData = await getOpenApi(brandId)
        setTableData(openApiData?.rows || [])
      } finally {
        dispatch(updateDataLoading(false))
      }
    }
  }, [rootData?.brandId, dispatch])

  const CompanyTokenValue = useMemo(
    () => ({
      modalState,
      setModalState,
      handleGetOpenApi,
    }),
    [modalState, setModalState, handleGetOpenApi]
  )

  useEffect(() => {
    handleGetOpenApi()
  }, [handleGetOpenApi])

  return (
    <CompanyTokenContext.Provider value={CompanyTokenValue}>
      <Basic navSection={NavSection} sideSection={SideSection}>
        <ContentSection>
          <Table
            headerClasses="table-header"
            bodyClasses="paragraph"
            className="overflow-auto mt-3"
            keyField="tokenId"
            data={tableData}
            columns={columns(
              setModalState,
              (bool) => dispatch(updateDataLoading(bool)),
              setTableData,
              handleGetOpenApi,
              rootData?.brandId
            )}
            striped
            noDataIndication={
              <NoDataIndication
                isLoading={tableLoading}
                message={tableLoading ? '載入中...' : '尚無資料'}
              />
            }
          />
          <TokenModal />
        </ContentSection>
      </Basic>
    </CompanyTokenContext.Provider>
  )
}

export default CompanyToken
