import { useEffect, useState, useContext, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getPointHistoryListApi } from 'api/ApiMain'
import { PointHistoryList } from 'components/pages/PointContent'
import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb, Mask } from 'components/units'
import { PointHistoryContext } from 'context/pageContext'
import { RootContext } from 'RootContext'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'
import { SearchSection } from 'components/widgets'
import {
  PointHistorySearchConfig,
  PointHistorySearchInitError,
  PointHistorySearchState,
} from 'helpers/searchFieldFormat'

const settingPageType = {
  new: 'new',
  edit: 'edit',
  view: 'view',
}

/**
 * @description 點數歷程進入點
 * @returns {JSX.Element}
 * @constructor
 */
export default function PointHistory() {
  const dispatch = useDispatch()
  const { isDataLoading } = useSelector(getGlobalState)
  const { brandId } = useContext(RootContext)
  const [isShowMask, setIsShowMask] = useState(false)
  const [tableData, setTableData] = useState([])
  const [totalSize, setTotalSize] = useState(0)
  // 搜尋欄位
  const [searchFields, setSearchFields] = useState({})
  // 列表條件
  const [tableFilterCondition, setTableFilterConditions] = useState({
    p: 1,
    ps: 25,
    sf: 'no',
    so: 'desc',
  })
  const searchFieldsRef = useRef(searchFields)
  const tableFilterConditionRef = useRef(tableFilterCondition)

  const getPointHistoryList = async () => {
    const apiPayload = {
      ...searchFields,
      ...tableFilterCondition,
      brandId,
    }

    setTableData([])
    try {
      dispatch(updateDataLoading(true))
      const rsp = await getPointHistoryListApi(apiPayload)
      const { rows, totalSize } = rsp
      setTableData(rows)
      setTotalSize(totalSize)
      dispatch(updateDataLoading(false))
    } catch (e) {
      console.error(e)
    }
  }

  const handleSearch = (formValues) => {
    // keyName 轉換為後端需要格式 itemName > description
    const { itemName, ...rest } = formValues
    const newFormValues = { ...rest, description: itemName }
    setTableFilterConditions({ ...tableFilterCondition, p: 1 })
    setSearchFields(newFormValues)
  }

  const handleClearAllFields = () => {
    setTableFilterConditions({
      ...tableFilterCondition,
      p: 1,
    })
    setSearchFields({})
  }

  useEffect(() => {
    if (
      searchFieldsRef.current !== searchFields ||
      tableFilterConditionRef.current !== tableFilterCondition
    ) {
      getPointHistoryList()
      searchFieldsRef.current = searchFields
      tableFilterConditionRef.current = tableFilterCondition
    }
  }, [tableFilterCondition, searchFields])

  return (
    <PointHistoryContext.Provider
      value={{
        tableFilterCondition,
        setTableFilterConditions,
        getPointHistoryList,
        setIsShowMask,
      }}
    >
      <Basic navSection={NavSection}>
        <ContentSection>
          <SearchSection
            fields={PointHistorySearchConfig}
            initErrorMsg={PointHistorySearchInitError}
            initState={PointHistorySearchState}
            onSubmit={handleSearch}
            onReset={handleClearAllFields}
          />

          <PointHistoryList
            tableData={tableData}
            totalSize={totalSize}
            isLoadingTableData={isDataLoading}
          />

          <Mask show={isShowMask} />
        </ContentSection>
      </Basic>
    </PointHistoryContext.Provider>
  )
}

function NavSection() {
  const config = {
    now: <span className="width-max-content d-inline-block">點數歷程</span>,
    pathList: [{ title: '點數內容 ', slash: true }],
  }
  return <Breadcrumb {...config} />
}

export { PointHistoryContext, settingPageType }
