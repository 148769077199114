import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import { getPoint, getGainPointRules } from 'api/ApiMain'
import { findSelectedItem } from 'helpers/common'
import * as format from 'helpers/format'
import { POINT_SETTING } from 'store/constants'

export const pointSettingInitState = {
  status: {
    // 基本設定在編輯狀態
    isBaseSettingEdit: false,

    // 基本設定區塊 Loading
    isBaseSettingLoading: false,

    // 開始進行計算的 Loading
    isCalculateLoading: false,

    // 開始計算的按鈕 disabled
    isCalculateDisabled: false,
  },
  getPointRsp: {
    data: {
      status: null,
      startCompute: {},
      baseSetting: {},
      modal: {},
    },
    isFetch: false,
  },
  getGainPointRulesRsp: {
    data: {
      sort: {},
      totalSize: 0,
      rows: [],
    },
  },
}

export const getPointThunk = createAsyncThunk(
  `${POINT_SETTING}/getPoint`,
  async (brandId, { rejectWithValue }) => {
    try {
      const response = await getPoint(brandId)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getGainPointRulesThunk = createAsyncThunk(
  `${POINT_SETTING}/getGainPointRules`,
  async ({ brandId, tableSetting }, { rejectWithValue }) => {
    try {
      const response = await getGainPointRules(brandId, tableSetting)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const pointSettingSlice = createSlice({
  name: POINT_SETTING,
  initialState: pointSettingInitState,
  reducers: {
    changeIsBaseSettingEdit(state, action) {
      state.status.isBaseSettingEdit = action.payload
    },
    changeIsBaseSettingLoading(state, action) {
      state.status.isBaseSettingLoading = action.payload
    },
    changeIsCalculateLoading(state, action) {
      state.status.isCalculateLoading = action.payload
    },
    changeIsCalculateDisabled(state, action) {
      state.status.isCalculateDisabled = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(getPointThunk.fulfilled || getPointThunk.rejected),
      (state, action) => {
        state.getPointRsp = {
          ...action.payload,
          isFetch: true,
        }
      }
    )
    builder.addMatcher(
      isAnyOf(
        getGainPointRulesThunk.fulfilled,
        getGainPointRulesThunk.rejected
      ),
      (state, action) => {
        state.getGainPointRulesRsp = action.payload
      }
    )
  },
})

/* 點數設定中各項狀態 */
export const statusSelector = createSelector(
  (state) => state[POINT_SETTING].status,
  (status) => status
)

/* 點數基本設定資料 */
export const pointSettingRspSelector = createSelector(
  (state) => state[POINT_SETTING].getPointRsp,
  (getPointRsp) => {
    const isFetch = getPointRsp?.isFetch === true
    const isStartComputeDisabled = getPointRsp?.startCompute?.disabled || false
    const startComputeDate = getPointRsp?.startCompute?.date || ''
    const baseSetting = getPointRsp?.baseSetting || {}
    const baseId = getPointRsp?.baseSetting?.baseId

    const gainPtByConsumption = baseSetting?.gainPtByConsumption === true
    const amountPerFull = format.currency(baseSetting?.howManyAmountPerFull)
    const accumulatePoints = format.number(baseSetting?.howManyPointsGive)
    const isMaxPointPerDeal = !!baseSetting?.maxPtPerDeal
    const maxPointPerDeal = format.number(baseSetting?.maxPtPerDeal)
    const startCondition =
      findSelectedItem(baseSetting?.pointStartCond)?.name || null
    const expireOption = findSelectedItem(baseSetting?.pointExpireCond) || {}

    const conditionIdMapping = {
      5: ` ${baseSetting?.pointExpireCondNum} 天`,
      6: `明年 ${baseSetting?.pointExpireCondNum} 月底`,
    }
    const expireCondition =
      conditionIdMapping[expireOption?.id] || expireOption?.name

    const modalData = getPointRsp?.modal

    return {
      isFetch,
      isStartComputeDisabled,
      startComputeDate,
      baseId,
      gainPtByConsumption,
      amountPerFull,
      accumulatePoints,
      isMaxPointPerDeal,
      maxPointPerDeal,
      startCondition,
      expireCondition,
      modalData,
    }
  }
)

/* 基本設定的 edit 使用 */
export const pointSettingEditSelector = createSelector(
  (state) => state[POINT_SETTING].getPointRsp,
  (getPointRsp) => {
    const baseSetting = getPointRsp?.baseSetting || {}
    const baseId = getPointRsp?.baseSetting?.baseId

    const gainPtByConsumption = baseSetting?.gainPtByConsumption === true
    const howManyAmountPerFull = baseSetting?.howManyAmountPerFull
    const howManyPointsGive = baseSetting?.howManyPointsGive
    const maxPtPerDeal = baseSetting?.maxPtPerDeal
    const pointExpireCondNum = baseSetting?.pointExpireCondNum

    // 之前選擇的 select 資料
    const pointStartCond = findSelectedItem(baseSetting?.pointStartCond)
    const pointExpireCond = findSelectedItem(baseSetting?.pointExpireCond)

    const pointSettingEditDefault = {
      gainPtByConsumption,
      howManyAmountPerFull,
      howManyPointsGive,
      maxPtPerDeal,
      pointStartCond,
      pointExpireCond,
      pointExpireCondNum,
    }

    // select 的option 配置
    const formatOptions = (array) => {
      if (!Array.isArray(array)) return []
      return array.map((item) => {
        return {
          text: item.name,
          value: item.id,
        }
      })
    }

    // API 完整的 select 資料
    const pointStartCondOptions = baseSetting?.pointStartCond
    const pointExpireCondOptions = baseSetting?.pointExpireCond

    // 渲染 select option 的資料
    const pointStartOptions = formatOptions(baseSetting?.pointStartCond)
    const pointExpireOptions = formatOptions(baseSetting?.pointExpireCond)

    return {
      baseId,
      pointSettingEditDefault,
      pointStartCondOptions,
      pointExpireCondOptions,
      pointStartOptions,
      pointExpireOptions,
    }
  }
)

export const pointGainRulesSelector = createSelector(
  (state) => state[POINT_SETTING].getGainPointRulesRsp,
  (getGainPointRulesRsp) => {
    const totalSize = getGainPointRulesRsp?.data?.totalSize || 0
    const rows = getGainPointRulesRsp?.data?.rows || []
    const isRowNotEmpty = rows.length > 0

    return {
      totalSize,
      rows,
      isRowNotEmpty,
    }
  }
)

export const isCalculateBtnDisabledSelector = createSelector(
  pointSettingRspSelector,
  statusSelector,
  (pointSettingRsp, status) => {
    const { baseId, isStartComputeDisabled } = pointSettingRsp
    const { isCalculateDisabled, isBaseSettingLoading } = status

    return (
      !baseId ||
      isStartComputeDisabled ||
      isCalculateDisabled ||
      isBaseSettingLoading
    )
  }
)

export const {
  changeIsBaseSettingEdit,
  changeIsBaseSettingLoading,
  changeIsCalculateLoading,
  changeIsCalculateDisabled,
} = pointSettingSlice.actions

export default pointSettingSlice.reducer
