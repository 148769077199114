import { toast } from 'react-hot-toast'
import { getRandomId, toastErrorContent } from 'helpers/common'

const handleCatch = (error, afterToastDismissFn) => {
  const { status } = error
  if (status === 400) {
    const toastId = getRandomId()
    toast.error(
      toastErrorContent(status, error?.data?.msg, toastId, afterToastDismissFn),
      {
        id: toastId,
      }
    )
  }
}

export default handleCatch
