import styled, { css } from 'styled-components'
import FormCheck from 'react-bootstrap/FormCheck'
import { spacingObj } from 'assets/styles/Variable/Spacing'
import { colorObj, method } from 'assets/styles/Variable/Color'

const { colorHandle } = method

// Radio 共幾種尺寸
const radioSize = {
  md: {
    fontSize: '15px',
    height: '24px',
  },
  sm: {
    fontSize: '13px',
    height: '20px',
  },
}
// Radio 的狀態
const radioState = {
  checked: {
    colorType: 'primary',
    color: colorObj.primary,
  },
  disabled: {
    colorType: 'dark',
    color: '#999999',
  },
}
// Input size樣式處理
const handleSizeStyles = (size) => {
  size = size || ''
  let cssStyle = ''
  for (const type in radioSize) {
    const { height } = radioSize[type]
    if (size !== type) {
      cssStyle += `
        &.form-control-${size}{
            height:${height};
        }
      `
    }
  }
  return css`
    ${cssStyle}
  `
}

// Radio 基礎樣式
const radioBaseStyles = (props) => {
  let { size, colorType, colorAlpha } = props
  size = size || 'md'
  const svgStrokeDisabledColor = encodeURIComponent(radioState.disabled.color)
  const svgFillColor = encodeURIComponent(radioState.checked.color)
  return css`
    &.form-check {
      position: relative;
      line-height: ${radioSize[size].height};
      font-size: ${radioSize[size].fontSize};

      &.form-check-inline {
        input[type='radio'] {
          margin-top: 16px;
        }
      }
      input[type='radio'] {
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
        width: 16px;
        height: 16px;
        opacity: 0;

        &:disabled {
          ~ .form-check-label {
            &::before {
              border-color: ${colorHandle(
                radioState.disabled.colorType,
                colorObj,
                0.5
              )};
            }
          }
        }
        ~ .form-check-label {
          color: ${colorType
            ? colorHandle(colorType, colorObj, colorAlpha) || colorObj.dark
            : 'inherit'};
          padding-left: ${spacingObj.micro};
          white-space: nowrap;

          &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-color: #fff;
            position: absolute;
            border-width: 1px;
            border-style: solid;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: 130%;
            background-position: center;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            border-color: ${colorObj.dark};
          }
        }

        &:checked {
          &:disabled {
            ~ .form-check-label {
              padding-left: ${spacingObj.micro};

              &::before {
                background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='${svgStrokeDisabledColor}' stroke-opacity='0.5'/></svg>");
                border-color: ${colorHandle(
                  radioState.disabled.colorType,
                  colorObj,
                  0.5
                )};
              }
            }
          }
          ~ .form-check-label {
            &::before {
              background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='${svgFillColor}'/></svg>");
              border-color: ${radioState.checked.color};
            }
          }
        }
      }
      .valid-feedback {
        text-indent: 4px;
      }
    }
    &.custom-switch {
      .custom-control-label {
        &::before {
          border-color: #adb5bd;
        }
      }
    }
    ${handleSizeStyles()};
  `
}

const StyleRadio = styled((props) => {
  const {
    colorType, // eslint-disable-line no-unused-vars
    colorAlpha, // eslint-disable-line no-unused-vars
    label,
    type = 'radio',
    isInvalid,
    isValid,
    ...prop
  } = props
  const labelElmenet = () => {
    return <>{label || ''}</>
  }

  return (
    <>
      <FormCheck
        {...prop}
        type={type}
        label={labelElmenet()}
        data-value-type={typeof prop.value}
      />
      {props?.feedback && isInvalid && (
        <div
          className="custom-invalid-feedback"
          data-testid="custom-invalid-feedback"
        >
          {props.feedback}
        </div>
      )}
      {props?.feedback && isValid && (
        <div
          className="custom-isvalid-feedback"
          data-testid="custom-isvalid-feedback"
        >
          {props.feedback}
        </div>
      )}
    </>
  )
})`
  ${(props) => radioBaseStyles(props)};
`

export { StyleRadio }
