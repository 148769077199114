import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import { getMemberPointRecord } from 'api/ApiMain'
import { getRandomId } from 'helpers/common'
import * as format from 'helpers/format'
import { POINT_RECORD } from 'store/constants'

export const pointRecordInitState = {
  pointRecordRsp: {
    data: {},
    code: null,
    msg: null,
    errors: null,
  },
}

export const getPointRecordThunk = createAsyncThunk(
  `${POINT_RECORD}/getPointRecord`,
  async ({ memberId, queryStringObj }, { rejectWithValue }) => {
    try {
      const response = await getMemberPointRecord(memberId, queryStringObj)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const pointRecordSlice = createSlice({
  name: POINT_RECORD,
  initialState: pointRecordInitState,
  reducers: {
    resetPointRecordRsp: (state) => {
      state.pointRecordRsp = pointRecordInitState.pointRecordRsp
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(getPointRecordThunk.fulfilled || getPointRecordThunk.rejected),
      (state, action) => {
        state.pointRecordRsp.data = action.payload
      }
    )
  },
})

export const pointRecordRspSelector = createSelector(
  (state) => state[POINT_RECORD].pointRecordRsp,
  (pointRecordRsp) => {
    const { data } = pointRecordRsp

    const rows = data?.rows || []
    const totalSize = data?.totalSize || 0
    const total = data?.total || null
    const isSortable = rows.length > 1
    const expiryRows = data?.expiryInfo?.rows || []

    const isPointDetailEmpty = total === null
    const pointBalance = format.number(total?.pointBalance || 0)
    const expiryPoints = format.number(total?.expiryPoints || 0)
    const expiryDate = format.date(total?.expiryDate || null)

    /* Table 需要唯一標示符，因此屬性補上 id */
    const newExpiryRows = expiryRows.map((row) => ({
      ...row,
      id: getRandomId(),
    }))

    const expiredInfo =
      expiryPoints !== '0' && expiryDate
        ? `${expiryPoints} 點將於 ${expiryDate} 到期`
        : ''

    return {
      rows,
      totalSize,
      isSortable,
      isPointDetailEmpty,
      pointBalance,
      expiredInfo,
      expiryRows: newExpiryRows,
    }
  }
)

export default pointRecordSlice.reducer
export const { resetPointRecordRsp } = pointRecordSlice.actions
