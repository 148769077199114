import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit'
import { getPointHistoryDetail } from 'api/ApiMain'
import { POINT_HISTORY } from '../constants'

export const pointHistoryInitState = {
  getPointHistoryDetailRsp: {
    data: {
      id: '',
      createDateTime: '',
      customId: '',
      name: '',
      description: '',
      transactionCategory: '',
      pointGain: 0,
      creatorEmail: '',
      additionalInstructions: '',
      shopName: '',
    },
  },
}

const getPointHistoryDetailThunk = createAsyncThunk(
  `${POINT_HISTORY}/getPointHistoryDetail`,
  async (payload, { rejectWithValue }) => {
    try {
      const rsp = await getPointHistoryDetail(payload)
      return rsp
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const pointHistorySlice = createSlice({
  name: POINT_HISTORY,
  initialState: pointHistoryInitState,
  reducers: {
    resetPointHistoryDetail: (state) => {
      state.getPointHistoryDetailRsp =
        pointHistoryInitState.getPointHistoryDetailRsp
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getPointHistoryDetailThunk.fulfilled,
        getPointHistoryDetailThunk.rejected
      ),
      (state, action) => {
        state.getPointHistoryDetailRsp = action.payload
      }
    )
  },
})

const pointHistoryDetailSelector = createSelector(
  (state) => {
    return state[POINT_HISTORY].getPointHistoryDetailRsp
  },
  (getPointHistoryDetailRsp) => {
    const { id, ...mainData } = getPointHistoryDetailRsp.data
    return {
      pointHistoryDetail: {
        ...mainData,
        shopName: mainData.shopName || '-',
        creatorEmail: mainData.creatorEmail || '-',
        additionalInstructions: mainData.additionalInstructions || '-',
      },
    }
  }
)

export default pointHistorySlice.reducer
export const { resetPointHistoryDetail } = pointHistorySlice.actions
export { getPointHistoryDetailThunk, pointHistoryDetailSelector }
