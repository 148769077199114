import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import { colorObj, method } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const { colorHandle } = method

const LocalStyle = styled(Modal)`
  .member-condition-container {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: 'Noto Sans TC', sans-serif;
    color: #333333;
    box-shadow: 2px 2px 4px 0px rgba(51, 51, 51, 0.05);
  }
  .member-condition-container-remind {
    color: ${colorHandle('dark', colorObj, 0.5)};
  }
  .btn-new-level-condition {
    min-width: auto;
    width: 96px;
    height: 32px;
  }
  .column-drag-handle {
    color: ${colorHandle('dark', colorObj, 0.25)};
    margin-left: -10px;
    font-weight: bold;
  }
  .list-title {
    min-width: 80px;
  }
  .custom-invalid-feedback {
    line-height: 1.2;
    margin-top: ${spacingObj.mini};
    margin-bottom: ${spacingObj.mini};
  }
  .member-condition-list-checked {
    .member-condition-list-checked-content {
      line-height: 1.5rem;
    }
    .list-title {
      min-width: auto;
    }
    .smooth-dnd-container {
      min-height: auto;
    }
    .smooth-dnd-active-item {
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      background-color: #ffffff;
      padding: ${spacingObj.mini};
      overflow: visible;
    }
  }
`

export default LocalStyle
