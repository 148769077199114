import Table from 'react-bootstrap/Table'
import iconTooltip from 'assets/images/icon_tooltip.svg'
import { StyleTooltip } from 'components/units/Tooltip'
import * as format from 'helpers/format'
import { combineValue } from 'helpers/common'
import LocalStyle from './MainDataStyle'

function showFulfilled(stillNeed, stillCount) {
  return (
    (stillNeed === '' && stillCount === '') ||
    (stillNeed === null && stillCount === '') ||
    (stillNeed === '' && stillCount === null)
  )
}

function MainData({ memberData }) {
  const { renewInfo, upgradeInfo } = memberData || {}

  const {
    stillNeed: renewStillNeed,
    stillCount: renewStillCount,
    currentClass: renewClass,
  } = renewInfo || {}

  const {
    stillNeed: upgradeStillNeed,
    stillCount: upgradeStillCount,
    nextClass: upgradeClass,
  } = upgradeInfo || {}

  // 當 stillNeed 或 stillCount 為 null, 代表廠商未針對該項目設定提示
  const isRenewFulfilled = showFulfilled(renewStillNeed, renewStillCount)
  const isUpgradeFulfilled = showFulfilled(upgradeStillNeed, upgradeStillCount)

  const renewTip = combineValue(renewStillNeed, renewStillCount)
  const upgradeTip = combineValue(upgradeStillNeed, upgradeStillCount)

  // 若達成升等條件，則不顯示續會提示
  const isShowRenewTip =
    renewClass &&
    (renewStillNeed !== null || renewStillCount !== null) &&
    !isUpgradeFulfilled

  const isShowUpgradeTip =
    upgradeClass && (upgradeStillNeed !== null || upgradeStillCount !== null)

  const levelTip = {
    renew: {
      type: '續會',
      isShow: isShowRenewTip,
      isFulfilled: isRenewFulfilled,
      tip: renewTip,
    },
    upgrade: {
      type: '升等',
      isShow: isShowUpgradeTip,
      isFulfilled: isUpgradeFulfilled,
      tip: upgradeTip,
    },
  }

  return (
    <LocalStyle>
      <Table>
        <tbody>
          <tr>
            <td colSpan="6" className="px-0">
              <div className="d-flex align-items-center">
                <div className="name float-left">{memberData?.name}</div>
                {memberData?.membershipName && (
                  <div className="membership float-left mr-2">
                    {memberData?.membershipName}
                  </div>
                )}
                <div className="d-flex flex-column" style={{ gap: '4px' }}>
                  <MemberLevelTip info={levelTip.renew} />
                  <MemberLevelTip info={levelTip.upgrade} />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="6" className="hr px-0">
              <hr />
            </td>
          </tr>
          <tr>
            <th>會員編號：</th>
            <td>{memberData?.customerID}</td>
            <th>
              <span className="mr-1">客單價</span>
              <StyleTooltip
                triggerElement={<img src={iconTooltip} alt="icon-tooltip" />}
                globalOption={{
                  effect: 'solid',
                }}
              >
                所有有效訂單金額加總/所有有效訂單數
              </StyleTooltip>
            </th>
            <td>{format.currency(memberData?.averageSalesPerOrder)}</td>
            <th>入會門店：</th>
            <td>{memberData?.shopName}</td>
          </tr>
          <tr>
            <th>累積消費：</th>
            <td>{format.currency(memberData?.cumulativeAmount)}</td>
            <th>
              <span className="mr-1">客件數</span>
              <StyleTooltip
                triggerElement={<img src={iconTooltip} alt="icon-tooltip" />}
                globalOption={{
                  effect: 'solid',
                }}
              >
                所有訂單內品項數
              </StyleTooltip>
            </th>
            <td>{format.number(memberData?.totalUnitsOfOrders)}</td>
            <th>入會日期：</th>
            <td>{format.date(memberData?.registeredDate)}</td>
          </tr>
          <tr>
            <th>上次消費：</th>
            <td>
              {memberData?.lastDealDate === null
                ? '-'
                : format.date(memberData?.lastDealDate)}
            </td>
            <th>
              <span className="mr-1">客次數</span>
              <StyleTooltip
                triggerElement={<img src={iconTooltip} alt="icon-tooltip" />}
                globalOption={{
                  effect: 'solid',
                }}
              >
                所有有效訂單筆數
              </StyleTooltip>
            </th>
            <td>{format.number(memberData?.totalNumberOfOrders)}</td>
            <th>會員效期：</th>
            <td>
              {memberData?.endDate
                ? `${format.date(memberData?.startDate)} - ${format.date(
                    memberData?.endDate
                  )}`
                : `${format.date(memberData?.startDate)} 起`}
            </td>
          </tr>
        </tbody>
      </Table>
    </LocalStyle>
  )
}

function MemberLevelTip({ info }) {
  const { type, isShow, isFulfilled, tip } = info
  return (
    isShow && (
      <div className="tip">
        {isFulfilled && (
          <span className="d-flex align-items-center">
            已達{type}資格
            <StyleTooltip
              className="ml-1"
              triggerElement={<img src={iconTooltip} alt="icon-tooltip" />}
            >
              將依會籍設定的規則時間更新會員等級
            </StyleTooltip>
          </span>
        )}
        {!isFulfilled && `再 ${tip} 可${type}`}
      </div>
    )
  )
}

export default MainData
