import { useState, useEffect, useCallback, useContext, useRef } from 'react'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { useDispatch, useSelector } from 'react-redux'
import { getQrcode } from 'api/ApiMain'
import { QrcodeActivityPage } from 'components/pages/ActivitiesCode/'
import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb } from 'components/units'
import { QrcodeActivityContext } from 'context/pageContext'
import { RootContext } from 'RootContext'
import {
  getGlobalState,
  updateDataLoading,
  updatePageLoading,
} from 'store/global/globalSlice'

// 頁面類型(新增、編輯、檢視)
const settingPageType = {
  new: 'new',
  edit: 'edit',
  view: 'view',
}

const paginationOptions = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

const defaultSort = {
  so: 'desc',
  sf: 'no',
}

function NavSection() {
  const BreadcrumbConfig = {
    now: <span className="width-max-content d-inline-block">QRCode 活動</span>,
    pathList: [{ title: '活動條碼 ', slash: true }],
    description: (
      <span className="width-max-content d-inline-block">
        提供 QRCode 給會員掃碼之活動內容
      </span>
    ),
  }
  return <Breadcrumb {...BreadcrumbConfig} />
}

function QrcodeActivity() {
  const dispatch = useDispatch()
  const { isDataLoading: tableLoading } = useSelector(getGlobalState)
  const rootData = useContext(RootContext)
  const { QrcodeActivityList } = QrcodeActivityPage
  const [apiPayload, setApiPayload] = useState({
    ps: 25,
    p: 1,
    ...defaultSort,
  })
  const [qrcodeListData, setQrcodeListData] = useState({})

  const handleTableLoading = (isLoading) => {
    dispatch(updateDataLoading(isLoading))
  }

  // 取得Qrcode列表資料
  const handleGetQrcode = useCallback(async () => {
    if (rootData?.brandId) {
      handleTableLoading(true)
      setQrcodeListData([])

      const apiData = await getQrcode(rootData.brandId, {
        ...apiPayload,
      })
      setQrcodeListData(apiData)
      handleTableLoading(false)
      dispatch(updatePageLoading(false))
    }
  }, [rootData?.brandId, apiPayload, dispatch])

  const apiPayloadRef = useRef(apiPayload)

  useEffect(() => {
    if (apiPayload !== apiPayloadRef.current) {
      handleGetQrcode()
    }
  }, [apiPayload, handleGetQrcode])

  return (
    <QrcodeActivityContext.Provider
      value={{
        settingPageType,
        apiPayload,
        setApiPayload,
        defaultSort,
        qrcodeListData,
        setQrcodeListData,
        handleGetQrcode,
        handleTableLoading,
        tableLoading,
      }}
    >
      <Basic navSection={NavSection}>
        <ContentSection>
          <PaginationProvider
            pagination={paginationFactory({
              ...paginationOptions,
              onPageChange: (page) =>
                setApiPayload((prev) => ({
                  ...prev,
                  p: page,
                })),
              onSizePerPageChange: (sizePerPage, page) =>
                setApiPayload((prev) => ({
                  ...prev,
                  ps: sizePerPage,
                  p: page || 1,
                })),
              totalSize: qrcodeListData?.totalSize || 0,
              page: apiPayload.p,
              sizePerPage: apiPayload?.ps,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <QrcodeActivityList
                paginationProps={paginationProps}
                paginationTableProps={paginationTableProps}
              />
            )}
          </PaginationProvider>
        </ContentSection>
      </Basic>
    </QrcodeActivityContext.Provider>
  )
}

export default QrcodeActivity
