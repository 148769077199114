import { createSelector, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPermissions } from 'api/ApiMain'

// Init Config
export const permissionsInitState = {
  permissionsRsp: {
    data: [],
  },
}

// Thunk
export const getPermissionsThunk = createAsyncThunk(
  'permissions/getPermissions',
  async (brandId) => {
    const response = await getPermissions(brandId)
    return response
  }
)

// Slice
const permissionSlice = createSlice({
  name: 'permissions',
  initialState: permissionsInitState,
  reducers: {
    resetPermissions: (state) => {
      state.permissionsRsp = permissionsInitState.permissionsRsp
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPermissionsThunk.fulfilled, (state, action) => {
      state.permissionsRsp.data = action.payload.modules
    })
  },
})

// Selector
export const getPermissionList = createSelector(
  (state) => {
    return state.permissions
  },
  (permissions) => {
    return permissions.permissionsRsp.data.reduce((acc, curr) => {
      const {
        moduleId,
        permission: { c, r, u, d },
      } = curr

      if (c) acc.push(`${moduleId}_c`)
      if (r) acc.push(`${moduleId}_r`)
      if (u) acc.push(`${moduleId}_u`)
      if (d) acc.push(`${moduleId}_d`)
      return acc
    }, [])
  }
)

export const isPermissionAllowed = (permissionKey, rule = 'or') =>
  createSelector(getPermissionList, (permissions) => {
    return Array.isArray(permissionKey) && rule === 'or'
      ? permissionKey.some((item) => permissions.includes(item))
      : permissionKey.every((item) => permissions.includes(item))
  })

export const { resetPermissions } = permissionSlice.actions
export default permissionSlice.reducer
