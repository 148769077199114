import styled from 'styled-components'
import { colorObj, method } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const { colorHandle } = method

const LocalStyle = styled.div`
  width: auto;
  margin: 0 10px;
  .point-setting-container {
    .base-setting-container {
      color: ${colorHandle('dark', colorObj)};
      line-height: 2.5rem;

      .base-setting-title {
        color: ${colorHandle('dark', colorObj, 0.66)};
      }
      .base-setting-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .base-setting-input-group {
          width: 100px;
          display: inline-block;
        }
        .invalid-feedback {
          line-height: 1.5rem;
          margin-top: 0;
        }
        .custom-invalid-feedback {
          line-height: 1;
          margin-top: 10px;
        }
      }
    }
    .ptset-custom-input {
      display: inline;
      width: 60px;
      max-width: 60px;
      min-width: 60px;
      height: 32px;
      padding: 5px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border-right: 0;

      + {
        .input-group-append {
          > .input-append-text {
            transition: 0.3s;
          }
        }
      }
      &:focus {
        + {
          .input-group-append {
            > .input-append-text {
              border-color: #4b65a6;
            }
          }
        }
      }
    }
    .point-setting-table-container {
      border-radius: 4px;
      box-sizing: border-box;
      font-family: 'Noto Sans TC', sans-serif;
      color: #333333;

      .icon-feature-container {
        .style-tooltip-container {
          margin-right: ${spacingObj.small};
          width: 16px;
          height: 16px;

          &:last-child {
            margin-right: 0;
          }
        }
        [data-id='tooltip'] {
          font-weight: 400;
        }
      }
    }
  }
  .ptset-icon-info {
    width: 12px;
    height: 12px;
    margin: auto 0 auto 8px;
  }
`

export default LocalStyle
