import { toast } from 'react-hot-toast'
import { isAfter, startOfToday } from 'date-fns'
import { toastErrorText } from 'helpers/common'
import { number } from 'helpers/format'
import {
  fieldRestrictNumberZero,
  fieldRestrictPercent,
  fieldRestrictAsciiCharacterExceptWhitespace,
} from 'helpers/validation'
import {
  contentType,
  eventType,
  fieldType,
  formConfig,
  giftType,
  imageLimitConfig,
  receiveLimit,
  resAtrr,
  validMsg,
  initValid,
  initContent,
} from 'components/pages/Gift/ExclusiveContentConfig'

// 取得好禮項目下拉選單選項
const getEventTypeOptions = (allTypes, disabledList = []) => {
  return allTypes.filter((item) => !disabledList.includes(item.value))
}
const contentReducer = (state, action) => {
  switch (action.type) {
    case contentType.init: {
      const { memberLevels } = action.payload
      const { linkList } = action.payload
      const { designatedList } = action.payload
      return {
        ...state,
        [fieldType.linkGift]: {
          ...state[fieldType.linkGift],
          linkList,
        },
        [fieldType.target]: {
          ...state[fieldType.target],
          memberLevels: memberLevels.map((i) => ({
            ...i,
            selected: false,
          })),
          designatedList,
        },
      }
    }

    case contentType.updateFromApi: {
      return {
        ...state,
        ...action.payload,
        [fieldType.linkGift]: {
          ...state[fieldType.linkGift],
          vipGiftSettingId:
            action.payload[fieldType.linkGift]?.vipGiftSettingId ??
            state[fieldType.linkGift].vipGiftSettingId,
          serialNo:
            action.payload[fieldType.linkGift]?.serialNo ??
            state[fieldType.linkGift].serialNo,
          content:
            action.payload[fieldType.linkGift]?.content ??
            state[fieldType.linkGift].content,
          isMain:
            action.payload[fieldType.linkGift]?.isMain ??
            state[fieldType.linkGift].isMain,
        },
      }
    }

    case contentType.linkGift: {
      const { selectValue } = action.payload

      // 若 selectValue 取得 -1，則將 vipGiftSettingId 帶入 null
      const linkGiftState = {
        ...state,
        [fieldType.linkGift]: {
          ...state[fieldType.linkGift],
          vipGiftSettingId: selectValue === -1 ? null : selectValue,
          isMain: selectValue === -1 ? '' : false,
        },
      }

      return linkGiftState
    }

    case contentType.eventType: {
      const initValue = formConfig[fieldType.receiveLimit].selectOption.filter(
        (i) => i.event.some((event) => event === action.payload)
      )[0].value

      let contentTypeTargetObj = {}
      // 選擇指定禮的情況
      if (action.payload === eventType.designatedList) {
        const targetContent = {
          ...initContent[fieldType.target],
          forNewMember: true, // 指定禮不能傳送null，與後端討論後傳送true
          memberLevels: state[fieldType.target].memberLevels, // 確保 memberLevels 不會被清空
        }
        contentTypeTargetObj = {
          [fieldType.target]: targetContent,
        }
      } else {
        contentTypeTargetObj = {
          ...state,
          [fieldType.target]: {
            ...state[fieldType.target],
            [contentType.designatedList]:
              initContent[fieldType.target][contentType.designatedList],
          },
        }
      }
      return {
        ...state,
        ...contentTypeTargetObj,
        [fieldType.eventType]: action.payload,
        [fieldType.receiveLimit]: initValue,
      }
    }

    case contentType.newMember: {
      const isNewMember =
        action.payload === formConfig[fieldType.target].radioOption[0]
      return {
        ...state,
        [fieldType.target]: {
          ...state[fieldType.target],
          forNewMember: isNewMember,
          memberLevels: state[fieldType.target].memberLevels.map((i) => ({
            ...i,
            selected: false,
          })),
          forInitialMember: false,
        },
      }
    }

    case contentType.allMembers:
      return {
        ...state,
        [fieldType.target]: {
          ...state[fieldType.target],
          memberLevels: state[fieldType.target].memberLevels.map((i) => ({
            ...i,
            selected: action.payload,
          })),
          forInitialMember: action.payload,
        },
      }

    case contentType.members: {
      return {
        ...state,
        [fieldType.target]: {
          ...state[fieldType.target],
          memberLevels: state[fieldType.target].memberLevels.map((i) => ({
            ...i,
            selected:
              i.id === action.payload.id ? action.payload.value : i.selected,
          })),
        },
      }
    }

    case contentType.designatedList: {
      const { selectValue, optionItems } = action.payload
      const newDesignatedList = []
      for (const optionItem of optionItems) {
        const { value, text } = optionItem
        newDesignatedList.push({
          code: value,
          name: text,
          selected: value === selectValue,
        })
      }

      return {
        ...state,
        [fieldType.target]: {
          ...state[fieldType.target],
          designatedList: newDesignatedList,
        },
      }
    }

    case contentType.basicMember:
      return {
        ...state,
        [fieldType.target]: {
          ...state[fieldType.target],
          forInitialMember: action.payload,
        },
      }

    case contentType.consumerCondition:
      return {
        ...state,
        [fieldType.consumerCondition]: {
          ...state[fieldType.consumerCondition],
          condition: action.payload,
        },
      }

    case contentType.giftSpecialOfferDiscount:
      return {
        ...state,
        [fieldType.giftSpecialOffer]: {
          ...state[fieldType.giftSpecialOffer],
          discount: action.payload,
        },
      }

    case contentType.giftSpecialOfferConsumerAmount:
      return {
        ...state,
        [fieldType.giftSpecialOffer]: {
          ...state[fieldType.giftSpecialOffer],
          consumerAmount: action.payload,
        },
      }

    case contentType.giftType:
      return {
        ...state,
        [fieldType.giftType]: action.payload,
        [fieldType.receiveMethod]:
          action.payload === giftType.point
            ? ''
            : state[fieldType.receiveMethod],
      }

    case contentType.giftSpecialOfferConsumerAmount_Check:
    case contentType.giftSpecialOfferDiscount_Check:
      return {
        ...state,
        [fieldType.giftSpecialOffer]: {
          ...state[fieldType.giftSpecialOffer],
          check: action.payload,
        },
      }

    case contentType.eventEnd_Check: {
      const eventEndIsEver =
        action.payload === formConfig[fieldType.eventEnd].radioOption[0]
      const receiveEndEverOption =
        formConfig[fieldType.receiveEnd].radioOption[0]
      return {
        ...state,
        eventEnd: { ...state.eventEnd, check: action.payload },
        [contentType.receiveEnd]: {
          ...state[contentType.receiveEnd],
          check: eventEndIsEver
            ? receiveEndEverOption
            : state[contentType.receiveEnd].check,
        },
      }
    }

    case contentType.consumerCondition_Check:
    case contentType.eventStart_Check: {
      const type = action.type.split('_Check')[0]
      return {
        ...state,
        [type]: { ...state[type], check: action.payload },
      }
    }

    case contentType.giftImage:
      return {
        ...state,
        [action.type]: action.payload || { url: '' },
        deleteExistImage:
          state[fieldType.giftImage].vipGiftSettingImgId ||
          state.deleteExistImage,
      }

    case contentType.eventStart:
    case contentType.eventEnd:
      return {
        ...state,
        [action.type]: { ...state[action.type], calendar: action.payload },
      }

    case contentType.eventName:
    case contentType.receiveLimit:
    case contentType.receiveCount:
    case contentType.giftPoint:
    case contentType.giftCode:
    case contentType.giftName:
    case contentType.giftColor:
    case contentType.giftSize:
    case contentType.giftAmount:
    case contentType.receiveMethod:
    case contentType.receiveStart:
    case contentType.autoUpload:
    case contentType.giftInfo:
      return { ...state, [action.type]: action.payload }

    case contentType.receiveEnd:
      return {
        ...state,
        [fieldType.giftCodeEndTimeType]: Number(action.payload),
      }

    case contentType.giftCodeEndTimeAfterDays:
      return {
        ...state,
        [fieldType.giftCodeEndTimeAfterDays]: Number(action.payload),
      }

    case contentType.giftCodeEndTime:
      return { ...state, [action.type]: action.payload }

    default:
      return state
  }
}

const contentTypeToFieldType = {
  [contentType.receiveLimit]: fieldType.receiveLimit,
  [contentType.eventType]: fieldType.eventType,
  [contentType.giftType]: fieldType.giftType,
  [contentType.receiveMethod]: fieldType.receiveMethod,
  [contentType.receiveEnd]: fieldType.giftCodeEndTimeType,
}

// 更新欄位設定-欄位是否為必填
const updateValidSetting = (resValue) => {
  const defaultValidStatus = { valid: null, msg: validMsg.empty }
  const validSetting = {}

  validSetting[fieldType.giftSpecialOffer] = {
    ...defaultValidStatus,
    required: resValue[fieldType.giftType] === giftType.offer,
  }
  validSetting[fieldType.giftPoint] = {
    ...defaultValidStatus,
    required: resValue[fieldType.giftType] === giftType.giftPoint,
  }
  validSetting[fieldType.giftCode] = {
    ...defaultValidStatus,
    required: resValue[fieldType.giftType] === giftType.gift,
  }
  validSetting[fieldType.giftName] = {
    ...defaultValidStatus,
    required: resValue[fieldType.giftType] === giftType.gift,
  }
  validSetting[fieldType.receiveMethod] = {
    ...defaultValidStatus,
    required:
      resValue[fieldType.giftType] === giftType.gift ||
      resValue[fieldType.giftType] === giftType.offer,
  }
  validSetting[fieldType.consumerCondition] = {
    ...defaultValidStatus,
    required:
      resValue[fieldType.eventType] === eventType.complimentaryFullAmount,
  }
  validSetting[fieldType.receiveCount] = {
    ...defaultValidStatus,
    required:
      resValue[fieldType.receiveLimit] ===
      receiveLimit.eventPeriodPickupSpecificTimes,
  }
  validSetting[fieldType.receiveStart] = {
    ...defaultValidStatus,
    required:
      resValue[fieldType.receiveMethod] ===
      formConfig[fieldType.receiveMethod].radioOption[0],
  }
  validSetting[fieldType.giftCodeEndTimeType] = {
    ...defaultValidStatus,
    required:
      resValue[fieldType.receiveMethod] ===
      formConfig[fieldType.receiveMethod].radioOption[0],
  }
  validSetting[fieldType.giftCodeEndTime] = {
    ...defaultValidStatus,
    required: resValue[fieldType.giftCodeEndTimeType] === 2,
  }

  validSetting[fieldType.giftCodeEndTimeAfterDays] = {
    ...defaultValidStatus,
    required: resValue[fieldType.giftCodeEndTimeType] === 3,
  }

  return { ...initValid, ...validSetting }
}

// 使用者勾選特定欄位後，改變相關欄位的必填設定
const setValidCallbackFn = {
  [fieldType.giftType]: {
    [giftType.gift]: (prev) => ({
      ...prev,
      [fieldType.giftSpecialOffer]: {
        ...prev[fieldType.giftSpecialOffer],
        required: false,
      },
      [fieldType.giftPoint]: {
        ...prev[fieldType.giftPoint],
        required: false,
      },
      [fieldType.giftCode]: {
        ...prev[fieldType.giftCode],
        required: true,
      },
      [fieldType.giftName]: {
        ...prev[fieldType.giftName],
        required: true,
      },
      [fieldType.receiveMethod]: {
        ...prev[fieldType.receiveMethod],
        required: true,
      },
    }),
    [giftType.point]: (prev) => ({
      ...prev,
      [fieldType.giftSpecialOffer]: {
        ...prev[fieldType.giftSpecialOffer],
        required: false,
      },
      [fieldType.giftPoint]: {
        ...prev[fieldType.giftPoint],
        required: true,
      },
      [fieldType.giftCode]: {
        ...prev[fieldType.giftCode],
        required: false,
      },
      [fieldType.giftName]: {
        ...prev[fieldType.giftName],
        required: false,
      },
      [fieldType.receiveMethod]: {
        ...prev[fieldType.receiveMethod],
        required: false,
      },
      [fieldType.receiveStart]: {
        ...prev[fieldType.receiveStart],
        required: false,
      },
    }),
    [giftType.offer]: (prev) => ({
      ...prev,
      [fieldType.giftSpecialOffer]: {
        ...prev[fieldType.giftSpecialOffer],
        required: true,
      },
      [fieldType.giftPoint]: {
        ...prev[fieldType.giftPoint],
        required: false,
      },
      [fieldType.giftCode]: {
        ...prev[fieldType.giftCode],
        required: false,
      },
      [fieldType.giftName]: {
        ...prev[fieldType.giftName],
        required: false,
      },
      [fieldType.receiveMethod]: {
        ...prev[fieldType.receiveMethod],
        required: true,
      },
    }),
  },
  [fieldType.eventType]: {
    [eventType.admissionCeremony]: (prev) => ({
      ...prev,
      [fieldType.consumerCondition]: {
        ...prev[fieldType.consumerCondition],
        required: false,
      },
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
    [eventType.bindingGift]: (prev) => ({
      ...prev,
      [fieldType.consumerCondition]: {
        ...prev[fieldType.consumerCondition],
        required: false,
      },
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
    [eventType.birthdayGift]: (prev) => ({
      ...prev,
      [fieldType.consumerCondition]: {
        ...prev[fieldType.consumerCondition],
        required: false,
      },
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
    [eventType.complimentaryFullAmount]: (prev) => ({
      ...prev,
      [fieldType.consumerCondition]: {
        ...prev[fieldType.consumerCondition],
        required: true,
      },
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
    [eventType.promotionCeremony]: (prev) => ({
      ...prev,
      [fieldType.consumerCondition]: {
        ...prev[fieldType.consumerCondition],
        required: false,
      },
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
    [eventType.renewalCeremony]: (prev) => ({
      ...prev,
      [fieldType.consumerCondition]: {
        ...prev[fieldType.consumerCondition],
        required: false,
      },
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
    [eventType.designatedList]: (prev) => ({
      ...prev,
      [fieldType.consumerCondition]: {
        ...prev[fieldType.consumerCondition],
        required: false,
      },
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
  },
  [fieldType.receiveLimit]: {
    [receiveLimit.eventPeriodPickupSpecificTimes]: (prev) => ({
      ...prev,
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: true,
      },
    }),
    [receiveLimit.eventPeriodPickupUnlimited]: (prev) => ({
      ...prev,
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
    [receiveLimit.monthlyPickupOnce]: (prev) => ({
      ...prev,
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
    [receiveLimit.onlyPickupOnce]: (prev) => ({
      ...prev,
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
    [receiveLimit.validityPeriodPickupOnce]: (prev) => ({
      ...prev,
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
    [receiveLimit.yearlyPickupOnce]: (prev) => ({
      ...prev,
      [fieldType.receiveCount]: {
        ...prev[fieldType.receiveCount],
        required: false,
      },
    }),
  },
  [fieldType.receiveMethod]: (prev) => ({
    ...prev,
    [fieldType.receiveStart]: {
      ...prev[fieldType.receiveStart],
      required: true,
    },
    [fieldType.giftCodeEndTimeType]: {
      ...prev[fieldType.giftCodeEndTimeType],
      required: true,
    },
  }),
  giftCodeEndTimeType1: {
    [fieldType.giftCodeEndTime]: (prev) => ({
      ...prev,
      [fieldType.giftCodeEndTime]: {
        ...prev[fieldType.giftCodeEndTime],
        required: false,
      },
    }),
    [fieldType.giftCodeEndTimeAfterDays]: (prev) => ({
      ...prev,
      [fieldType.giftCodeEndTimeAfterDays]: {
        ...prev[fieldType.giftCodeEndTimeAfterDays],
        required: false,
      },
    }),
  },
  giftCodeEndTimeType2: {
    [fieldType.giftCodeEndTime]: (prev) => ({
      ...prev,
      [fieldType.giftCodeEndTime]: {
        ...prev[fieldType.giftCodeEndTime],
        required: true,
      },
    }),
    [fieldType.giftCodeEndTimeAfterDays]: (prev) => ({
      ...prev,
      [fieldType.giftCodeEndTimeAfterDays]: {
        ...prev[fieldType.giftCodeEndTimeAfterDays],
        required: false,
      },
    }),
  },
  giftCodeEndTimeType3: {
    [fieldType.giftCodeEndTime]: (prev) => ({
      ...prev,
      [fieldType.giftCodeEndTime]: {
        ...prev[fieldType.giftCodeEndTime],
        required: false,
      },
    }),
    [fieldType.giftCodeEndTimeAfterDays]: (prev) => ({
      ...prev,
      [fieldType.giftCodeEndTimeAfterDays]: {
        ...prev[fieldType.giftCodeEndTimeAfterDays],
        required: true,
      },
    }),
  },
}

const handleEventValueParser =
  (dispatch, setValid) =>
  (type) =>
  (e, ...args) => {
    const [selectValue, , , optionItems] = args
    switch (type) {
      case contentType.members:
        return dispatch({
          type,
          payload: { value: e.target.checked, id: Number(e.target.id) },
        })

      case contentType.giftSpecialOfferConsumerAmount:
        return dispatch({ type, payload: fieldRestrictPercent(e) })

      case contentType.receiveCount:
      case contentType.consumerCondition:
      case contentType.giftSpecialOfferDiscount:
      case contentType.giftPoint:
      case contentType.giftAmount:
        return dispatch({ type, payload: fieldRestrictNumberZero(e) })

      case contentType.eventType:
      case contentType.receiveLimit:
      case contentType.giftType:
        setValid(setValidCallbackFn[contentTypeToFieldType[type]][selectValue])
        return dispatch({ type, payload: selectValue })

      case contentType.linkGift:
      case contentType.designatedList:
        return dispatch({ type, payload: { selectValue, optionItems } })

      case contentType.receiveMethod:
        setValid(setValidCallbackFn[contentTypeToFieldType[type]])
        return dispatch({ type, payload: e.target.id })

      case contentType.newMember:
      case contentType.giftSpecialOfferConsumerAmount_Check:
      case contentType.giftSpecialOfferDiscount_Check:
      case contentType.eventStart_Check:
      case contentType.eventEnd_Check:
      case contentType.autoUpload:
        return dispatch({ type, payload: e.target.id })

      case contentType.basicMember:
      case contentType.allMembers:
      case contentType.consumerCondition_Check:
        return dispatch({ type, payload: e.target.checked })

      case contentType.giftCode:
        return dispatch({
          type,
          payload: fieldRestrictAsciiCharacterExceptWhitespace(e),
        })

      case contentType.eventName:
      case contentType.giftName:
      case contentType.giftColor:
      case contentType.giftSize:
        return dispatch({ type, payload: e.target.value })

      case contentType.eventStart:
      case contentType.eventEnd:
      case contentType.receiveStart:
      case contentType.giftImage:
      case contentType.giftInfo:
        return dispatch({ type, payload: e })

      case contentType.receiveEnd: {
        const selectedRadio = typeof e === 'number' ? e : Number(e.target.value)

        switch (selectedRadio) {
          case 1:
            setValid(
              setValidCallbackFn.giftCodeEndTimeType1[fieldType.giftCodeEndTime]
            )
            setValid(
              setValidCallbackFn.giftCodeEndTimeType1[
                fieldType.giftCodeEndTimeAfterDays
              ]
            )
            break
          case 2:
            setValid(
              setValidCallbackFn.giftCodeEndTimeType2[fieldType.giftCodeEndTime]
            )
            setValid(
              setValidCallbackFn.giftCodeEndTimeType2[
                fieldType.giftCodeEndTimeAfterDays
              ]
            )
            break
          case 3:
            setValid(
              setValidCallbackFn.giftCodeEndTimeType3[fieldType.giftCodeEndTime]
            )
            setValid(
              setValidCallbackFn.giftCodeEndTimeType3[
                fieldType.giftCodeEndTimeAfterDays
              ]
            )
            break
          default:
            break
        }
        return dispatch({ type, payload: selectedRadio })
      }
      case contentType.giftCodeEndTimeAfterDays: {
        const [value] = args
        return dispatch({ type, payload: value })
      }
      case contentType.giftCodeEndTime: {
        return dispatch({ type, payload: e })
      }
      default:
    }
  }

const handleSizeError = () => {
  toast.error((t) =>
    toastErrorText(
      `圖檔大小超過 ${imageLimitConfig.size / 1024}MB，請重新選擇`,
      t.id
    )
  )
}

const handleImageError = (e) => {
  switch (e) {
    case 'size':
      handleSizeError()
      break
    default:
      return console.warn('onError fail')
  }
}

const conditionText = {
  earlier: '早於',
  later: '晚於',
  higher: '高於',
  lower: '低於',
}

const dateText = {
  [fieldType.eventStart]: '發送起始',
  [fieldType.eventEnd]: '發送結束',
  [fieldType.receiveStart]: '領取起始',
  [fieldType.receiveEnd]: '領取結束',
}

const genSerialNumberText = (amount) => `序號數量 ${number(amount)}`
const genSendingQuantityText = (amount) => `已發送數量 ${number(amount)}`
const genMillionGroupText = (amount) => ` ${amount / 10000} 萬組`
const genNotThanText = ({
  condition = '條件(高於)',
  detail = '細節(100萬組)',
} = {}) => `不可${condition}${detail}`

const validate = ({ type, value }) => {
  const rule = {
    [fieldType.eventName]: /^.{1,50}$/,
    [fieldType.eventType]: /^.+$/,
    [fieldType.receiveLimit]: /^.+$/,
    [fieldType.receiveCount]: /^[0-9]+$/,
    [fieldType.consumerCondition]: /^[0-9]+$/,
    [fieldType.giftType]: /^.+$/,
    [fieldType.giftPoint]: /^[0-9]{1,8}/,
    [fieldType.giftCode]: /^.{1,128}/,
    [fieldType.giftName]: /^.{1,59}/,
    [fieldType.giftAmount]: /^[1-9][0-9]{0,5}$|^1000000$/,
    [fieldType.receiveMethod]: /^.+$/,
    [fieldType.autoUpload]: /^.+$/,
    [fieldType.giftCodeEndTimeType]: /^.+$/,
    [fieldType.giftCodeEndTimeAfterDays]: /^.+$/,
  }

  return {
    valid: new RegExp(rule[type]).test(value),
    msg: validMsg.empty,
  }
}

const handleTargetValid = (target) => {
  let valid = false
  const { forNewMember, forInitialMember, memberLevels, designatedList } =
    target
  if (forNewMember) valid = true
  else if (designatedList.some((i) => i.selected)) valid = true
  else valid = forInitialMember || memberLevels.some((i) => i.selected)
  return { valid, msg: validMsg.empty }
}

const handleGiftAmountValid = ({ value, giftCodeCount, sendCount }) => {
  const limitMaxNumber = 1000000
  const rule = /^[1-9][0-9]{0,5}$|^1000000$/
  let valid = new RegExp(rule).test(value)
  let msg = validMsg.empty

  if (Number(value) > limitMaxNumber) {
    msg = genNotThanText({
      condition: conditionText.higher,
      detail: genMillionGroupText(limitMaxNumber),
    })
  } else if (Number(value) < Number(sendCount)) {
    valid = false
    msg = genNotThanText({
      condition: conditionText.lower,
      detail: genSendingQuantityText(sendCount),
    })
  } else if (Number(value) < Number(giftCodeCount)) {
    valid = false
    msg = genNotThanText({
      condition: conditionText.lower,
      detail: genSerialNumberText(giftCodeCount),
    })
  }

  return { valid, msg }
}

const handleGiftSpecialOfferValid = (payload) => {
  const { check, discount, consumerAmount } = payload
  let valid = false
  const msg = validMsg.empty
  const ruleDiscount = /^[0-9]{1,8}/
  const ruleConsumerAmount = /^[1-9][0-9]?$|^100$/

  if (check === '') return { valid, msg }

  valid =
    check === formConfig[fieldType.giftSpecialOffer].radioOption[0]
      ? new RegExp(ruleDiscount).test(discount)
      : new RegExp(ruleConsumerAmount).test(consumerAmount)

  return { valid, msg }
}

const handleEventStartvalid = (payload) => {
  const isEventStartEmpty = payload.check === ''
  if (isEventStartEmpty) return { valid: false, msg: validMsg.empty }
  return { valid: true, msg: null }
}

const handleEventEndValid = ({ payload, startData }) => {
  const startDateCheck = startData.check
  const endDatecheck = payload.check
  const isEventStartEmpty = startDateCheck === ''
  const isEventEndEmpty = endDatecheck === ''
  const isEver = endDatecheck === formConfig[fieldType.eventEnd].radioOption[0]
  const isImmediately =
    startDateCheck === formConfig[fieldType.eventStart].radioOption[0]
  const startDate = isImmediately ? startOfToday() : startData?.calendar
  const endDate = payload.calendar
  const isStartDateAfterEndDate = isAfter(startDate, endDate)

  if (isEventEndEmpty) return { valid: false, msg: validMsg.empty }
  if (isEver) return { valid: true, msg: null }

  if (!isEventStartEmpty && isStartDateAfterEndDate) {
    return {
      valid: false,
      msg: genNotThanText({
        condition: conditionText.earlier,
        detail: dateText[fieldType.eventStart],
      }),
    }
  }

  return { valid: true, msg: null }
}

const handleReceiveStartvalid = ({ payload, eventStartData }) => {
  const isEventStartEmpty = eventStartData.check === ''
  const receiveStart = payload
  const isImmediately =
    eventStartData.check === formConfig[fieldType.eventStart].radioOption[0]
  const eventStartDate = isImmediately
    ? startOfToday()
    : eventStartData.calendar
  const isEventStartDateAfterReceiveStartDate = isAfter(
    eventStartDate,
    receiveStart
  )

  if (!isEventStartEmpty && isEventStartDateAfterReceiveStartDate) {
    return {
      valid: false,
      msg: genNotThanText({
        condition: conditionText.earlier,
        detail: dateText[fieldType.eventStart],
      }),
    }
  }

  return { valid: true, msg: null }
}

const handleReceiveEndCalendarvalid = ({
  payload,
  startData,
  eventEndData,
}) => {
  const isReceiveEndEmpty = payload.giftCodeEndTime === ''
  const isEventEndEmpty = eventEndData.check === ''
  const receiveStartDate = startData
  const receiveEndDate = payload
  const eventEndDate = eventEndData.calendar
  const isEventEndEver =
    eventEndData.check === formConfig[fieldType.eventEnd].radioOption[0]
  const isEver = payload.giftCodeEndTimeType === 1
  const isReceiveStartDateAfterEndDate = isAfter(
    receiveStartDate,
    receiveEndDate
  )
  const isEventEndDateAfterReceiveEndDate = isAfter(
    eventEndDate,
    receiveEndDate
  )

  if (isReceiveEndEmpty) return { valid: false, msg: validMsg.empty }
  if (isEver) return { valid: true, msg: null }

  if (isReceiveStartDateAfterEndDate) {
    return {
      valid: false,
      msg: genNotThanText({
        condition: conditionText.earlier,
        detail: dateText[fieldType.receiveStart],
      }),
    }
  }

  if (isEventEndEmpty) return { valid: true, msg: null }
  if (isEventEndEver || isEventEndDateAfterReceiveEndDate) {
    return {
      valid: false,
      msg: genNotThanText({
        condition: conditionText.earlier,
        detail: dateText[fieldType.eventEnd],
      }),
    }
  }

  return { valid: true, msg: null }
}

const handleReceiveMethod = ({ prevData, value }) => {
  if (prevData === formConfig[fieldType.giftType].selectOption[1].value)
    return { valid: true, msg: '' }

  return {
    valid: new RegExp(/^.+$/).test(value),
    msg: validMsg.empty,
  }
}
// 在呼叫api之前，對於必填欄位進行驗證
const handleValidSetting =
  ({ data, resolve }) =>
  (prevState) => {
    const result = JSON.parse(JSON.stringify(prevState))
    const dataList = Object.entries(data)
    const requiredList = Object.entries(prevState)
      .filter(([, status]) => status.required)
      .map(([key]) => key)
    const nonRequiredList = Object.entries(prevState)
      .filter(([, status]) => status.required === false)
      .map(([key]) => key)
    const requiredData = dataList.filter(([key]) =>
      requiredList.some((i) => i === key)
    )
    const nonRequiredData = dataList.filter(([key]) =>
      nonRequiredList.some((i) => i === key)
    )

    requiredData.forEach(([type, payload]) => {
      switch (type) {
        case fieldType.target: {
          const { valid, msg } = handleTargetValid(payload)
          result[type] = { ...result[type], valid, msg }
          break
        }

        case fieldType.giftSpecialOffer: {
          const { valid, msg } = handleGiftSpecialOfferValid(payload)
          result[type] = { ...result[type], valid, msg }
          break
        }

        case fieldType.giftAmount: {
          const { valid, msg } = handleGiftAmountValid({
            value: payload,
            giftCodeCount: data[resAtrr.giftCodeCount],
            sendCount: data[resAtrr.sendCount],
          })
          result[type] = { ...result[type], valid, msg }
          break
        }

        case fieldType.eventStart: {
          const { valid, msg } = handleEventStartvalid(payload)
          result[type] = { ...result[type], valid, msg }
          break
        }

        case fieldType.eventEnd: {
          const { valid, msg } = handleEventEndValid({
            payload,
            startData: data[fieldType.eventStart],
          })
          result[type] = { ...result[type], valid, msg }
          break
        }

        case fieldType.receiveStart: {
          const { valid, msg } = handleReceiveStartvalid({
            payload,
            eventStartData: data[fieldType.eventStart],
          })
          result[type] = { ...result[type], valid, msg }
          break
        }

        case fieldType.consumerCondition: {
          const { valid, msg } = validate({ type, value: payload.condition })
          result[type] = { ...result[type], valid, msg }
          break
        }

        case fieldType.receiveMethod: {
          const { valid, msg } = handleReceiveMethod({
            prevData: data[fieldType.giftType],
            value: payload,
          })
          result[type] = { ...result[type], valid, msg }
          break
        }

        case fieldType.eventName:
        case fieldType.eventType:
        case fieldType.receiveLimit:
        case fieldType.receiveCount:
        case fieldType.giftType:
        case fieldType.giftPoint:
        case fieldType.giftCode:
        case fieldType.giftName:
        case fieldType.autoUpload:
        case fieldType.giftCodeEndTimeType:
        case fieldType.giftCodeEndTimeAfterDays: {
          const { valid, msg } = validate({ type, value: payload })
          result[type] = { ...result[type], valid, msg }
          break
        }

        case fieldType.giftCodeEndTime: {
          const { valid, msg } = handleReceiveEndCalendarvalid({
            payload,
            startData: data[fieldType.receiveStart],
            eventEndData: data[fieldType.eventEnd],
          })
          result[type] = { ...result[type], valid, msg }
          break
        }

        default:
          break
      }
    })

    nonRequiredData.forEach(
      ([type]) =>
        (result[type] = {
          ...result[type],
          valid: null,
        })
    )

    const isValid = Object.entries(result).every(
      ([, state]) => state.valid === true || state.valid === null
    )
    resolve(isValid)
    return result
  }

const handleValid = ({ data, updateValidState }) =>
  new Promise((resolve) =>
    updateValidState(handleValidSetting({ data, resolve }))
  )

const handleApiError = ({ error, updateFn }) => {
  const { errors } = error
  const config = {
    Description: [
      {
        type: '好禮介紹超過 2,000 字',
        attr: fieldType.giftInfo,
        msg: validMsg.giftInfo,
      },
    ],
  }

  for (const type in errors) {
    for (const errorMsg of errors[type]) {
      if (
        config[type] === undefined ||
        config[type].findIndex((i) => i.type === errorMsg) === -1
      )
        break

      const { msg, attr } = config[type].find((i) => i.type === errorMsg)
      updateFn((prevState) => ({
        ...prevState,
        [attr]: { ...prevState[attr], valid: false, msg },
      }))
    }
  }
}

export {
  contentReducer,
  handleEventValueParser,
  handleImageError,
  handleValid,
  handleApiError,
  updateValidSetting,
  getEventTypeOptions,
}
