import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import styled from 'styled-components'
import AccountList from 'components/pages/SystemConfiguration/account-management/containers/AccountList'
// import AccountSearch from 'components/pages/SystemConfiguration/account-management/containers/AccountSearch'
import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb, Button } from 'components/units'
import { PAGE_BREADCRUMB, PAGE_PATHS } from 'constant'

const StyledSideSection = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

function NavSection() {
  const { pathList, title } =
    PAGE_BREADCRUMB.systemConfiguration.accountManagement
  return <Breadcrumb pathList={pathList} now={title} />
}

function SideSection() {
  const history = useHistory()
  return (
    <StyledSideSection>
      <Button
        variant="outline-primary"
        onClick={() =>
          history.push(
            PAGE_PATHS.systemConfiguration.accountManagementNew.replace(
              ':pageType',
              'new'
            ),
            {}
          )
        }
      >
        新增帳號
      </Button>
    </StyledSideSection>
  )
}

export default function AccountManagement() {
  return (
    <Basic navSection={NavSection} sideSection={SideSection}>
      <ContentSection>
        {/* <AccountSearch /> */}
        <AccountList />
      </ContentSection>
    </Basic>
  )
}
