import { useEffect, useState } from 'react'
import { Col, Row, Tab, Container, Nav } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Select } from 'components/units'
import { authAccountSelector } from 'store/auth/authSelector'
import {
  getThemedDashboardThunk,
  themedDashboardSelector,
} from 'store/data-analysis/dataAnalysisSlice'

const LocalStyle = styled.div`
  margin: 24px 40px;

  .option {
    width: 277px;
  }
`

function ThemedDashboard() {
  const dispatch = useDispatch()
  const { optionItems, allDashboards } = useSelector(themedDashboardSelector)
  const { brandId } = useSelector(authAccountSelector)
  const [themeIndex, setThemeIndex] = useState(0)

  /* 獲取特定類型的所有儀表板 */
  const dashboards = allDashboards[themeIndex] || []

  /* 切換儀表板類型 */
  const handleChange = (e, value) => setThemeIndex(value)

  /* 獲取所有儀表板資料 */
  useEffect(() => {
    dispatch(getThemedDashboardThunk({ brandId }))
  }, [dispatch, brandId])

  return (
    <LocalStyle className="w-100">
      <Container className="d-flex flex-column h-100">
        <Row className="mb-4">
          <Col>
            <h1 className="mb-0">數據分析</h1>
          </Col>
        </Row>

        <div className="d-flex align-items-center mb-4">
          <div>會員成效：</div>
          <Select
            className="form-row-input-fill ml-3"
            dropdownToggleOption={{ className: 'option' }}
            optionItems={optionItems}
            selectedValue={themeIndex}
            onChange={handleChange}
          />
        </div>

        <Tab.Container defaultActiveKey={0}>
          <Nav className="nav nav-tabs">
            {dashboards.map((dashboard, index) => {
              return (
                <Nav.Item key={dashboard?.typeName}>
                  <Nav.Link eventKey={index}>{dashboard?.typeName}</Nav.Link>
                </Nav.Item>
              )
            })}
          </Nav>

          <Tab.Content className="flex-grow-1">
            {dashboards.map((dashboard, index) => {
              return (
                <Tab.Pane
                  key={dashboard?.typeName}
                  eventKey={index}
                  className="h-100"
                >
                  <iframe
                    width="100%"
                    height="100%"
                    title="ThemedTable"
                    src={dashboard?.url}
                    className="border-0"
                  />
                </Tab.Pane>
              )
            })}
          </Tab.Content>
        </Tab.Container>
      </Container>
    </LocalStyle>
  )
}

export default ThemedDashboard
