/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import { FormWrapper } from 'components/templates'
import { Modal, Select } from 'components/units'

const MethodInfo = styled.div`
  color: ${colorObj.darkGray};
  font-size: 15px;
`
const SelectWrapper = styled.div`
  width: 277px;
`

/* 核銷用 modal，用於「好禮核銷」和「贈品核銷」 */
function MethodModal({
  show,
  detailList,
  methodList,
  method,
  detail,
  onMethodChange,
  onDetailChange,
  onClose,
  onConfirm,
}) {
  const methodText =
    methodList.find((item) => item.value === method)?.text || ''
  const isConfirmDisabled = !method || !detail

  // 設定 detail 為 null 時，清空選項
  const isClearSelectedValue = !detail

  const handleClose = () => onClose()
  const handleConfirm = () => onConfirm()

  return (
    <Modal
      show={show}
      titleText="核銷方式"
      closeBtnText="取消"
      isConfirmDisabled={isConfirmDisabled}
      onConfirm={handleConfirm}
      onClose={handleClose}
    >
      <FormWrapper>
        <div>請選擇會員前往領取的門店或通路</div>
        <div className="mt-4">
          <div className="d-flex justify-content-center align-items-center">
            <MethodInfo>核銷方式：</MethodInfo>
            <SelectWrapper>
              <Select
                className="form-row-input-fill"
                selectedValue={method}
                formControlOption={{ placeholder: '請選擇' }}
                optionItems={methodList}
                onChange={onMethodChange}
              />
            </SelectWrapper>
          </div>

          {/* 由上一層的選項決定這層的顯示和標題 */}
          {methodText && (
            <div className="d-flex justify-content-center align-items-center mt-3">
              <MethodInfo>{methodText}：</MethodInfo>
              <SelectWrapper>
                <Select
                  className="form-row-input-fill"
                  formControlOption={{ placeholder: '請選擇' }}
                  selectedValue={detail}
                  optionItems={detailList}
                  onChange={onDetailChange}
                  isClearSelectedValue={isClearSelectedValue}
                />
              </SelectWrapper>
            </div>
          )}
        </div>
      </FormWrapper>
    </Modal>
  )
}

MethodModal.propTypes = {
  show: PropTypes.bool.isRequired,
  detailList: PropTypes.array.isRequired,
  methodList: PropTypes.array.isRequired,
  method: PropTypes.string, // 可能為 null, string
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 可能為 null, string, number
  onMethodChange: PropTypes.func.isRequired,
  onDetailChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default MethodModal
