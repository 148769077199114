import {
  updateAppLoading,
  updatePageLoading,
  updateDataLoading,
} from 'store/global/globalSlice'
import {
  appLoadingList,
  pageLoadingList,
  loadingSkipList,
} from 'store/loadingTypeList'

const loadingMiddleware = (store) => (next) => (action) => {
  const { type } = action
  // 確認是否為 thunk action：status 為 undefined 代表不是 thunk action
  const [sliceName, actionName, status] = type.split('/')
  const processingThunkType = status ? `${sliceName}/${actionName}` : null
  const isFetching = status === 'pending'
  const isAppLoadingAction = appLoadingList.includes(processingThunkType)
  const isPageLoadingAction = pageLoadingList.includes(processingThunkType)
  const isLoadingSkipAction = loadingSkipList.includes(processingThunkType)

  if (!processingThunkType || isLoadingSkipAction) return next(action)

  // 判斷更新 loading UI 的種類
  if (isAppLoadingAction) {
    store.dispatch(updateAppLoading(isFetching))
  } else if (isPageLoadingAction) {
    store.dispatch(updatePageLoading(isFetching))
  } else {
    store.dispatch(updateDataLoading(isFetching))
  }

  return next(action)
}

export default loadingMiddleware
