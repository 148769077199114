import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  deleteAccount,
  getAccountManagement,
  patchAccountStatus,
} from 'api/ApiMain'
import { ACCOUNT_MANAGEMENT } from 'store/constants'

export const accountManagementInitState = {
  sortConfig: {
    p: 1, // page
    ps: 25, // pageSize
    sf: 'createdTime', // sortField
    so: 'desc', // sortOrder
  },
  accountManagementRsp: {
    data: {
      sort: {
        field: 'createdTime',
        order: 'desc',
        availableFields: [],
      },
      totalSize: 0,
      rows: [
        {
          no: '',
          email: '',
          name: '',
          roleDescription: '',
          shopDescription: '',
          status: null,
          statusDescription: '',
        },
      ],
    },
    status: 200,
    msg: null,
    error: null,
  },
}

export const getAccountManagementThunk = createAsyncThunk(
  `${ACCOUNT_MANAGEMENT}/getAccountManagement`,
  async (brandId, { getState, rejectWithValue }) => {
    const { sortConfig } = getState().accountManagement
    const sortConfigParams =
      sortConfig.p === 0 ? { ...sortConfig, p: 1 } : sortConfig

    try {
      const response = await getAccountManagement(brandId, sortConfigParams)
      return response
    } catch (error) {
      // 錯誤訊息在上層已處理
      return rejectWithValue(error)
    }
  }
)

export const deleteAccountThunk = createAsyncThunk(
  `${ACCOUNT_MANAGEMENT}/deleteAccount`,
  async ({ brandId, accountId }, { rejectWithValue }) => {
    try {
      const response = await deleteAccount(brandId, accountId)
      return response
    } catch (error) {
      // 錯誤訊息在上層已處理
      return rejectWithValue(error)
    }
  }
)

export const patchAccountStatusThunk = createAsyncThunk(
  `${ACCOUNT_MANAGEMENT}/patchAccountStatus`,
  async ({ brandId, accountId, newStatus }, { rejectWithValue }) => {
    try {
      const response = await patchAccountStatus(brandId, accountId, {
        newStatus,
      })
      return response
    } catch (error) {
      // 錯誤訊息在上層已處理
      return rejectWithValue(error)
    }
  }
)

const accountManagementSlice = createSlice({
  name: [ACCOUNT_MANAGEMENT],
  initialState: accountManagementInitState,
  reducers: {
    updateSortConfig: (state, action) => {
      state.sortConfig = {
        ...state.sortConfig,
        ...action.payload,
      }
    },
    resetAccountList: (state) => {
      state.accountManagementRsp.data.rows = []
      state.accountManagementRsp.data.totalSize = 0
    },
    resetSortConfig: (state) => {
      state.sortConfig = accountManagementInitState.sortConfig
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountManagementThunk.fulfilled, (state, action) => {
      state.accountManagementRsp.data = action.payload
    })
    builder.addCase(getAccountManagementThunk.rejected, (state, action) => {
      state.accountManagementRsp.data = action.payload
    })
  },
})

export const mainListSelector = createSelector(
  (state) => state[ACCOUNT_MANAGEMENT],
  (accountManagement) => {
    return (
      accountManagement.accountManagementRsp?.data?.rows?.map(
        ({
          no,
          email,
          name,
          roleDescription,
          shopDescription,
          statusDescription,
          accountId,
          funcButton,
        }) => {
          if (funcButton === undefined) return {}
          const funcList = Object.keys(funcButton).map((key) => {
            const type = key.replace(/Btn$/, '')
            const itemId = accountId
            const { visible } = funcButton[key]
            return {
              type,
              visible,
              itemId,
            }
          })
          return {
            no,
            email,
            name,
            roleDescription,
            shopDescription,
            statusDescription,
            funcList,
          }
        }
      ) ?? []
    )
  }
)
export const mainListTotalSizeSelector = (state) =>
  state[ACCOUNT_MANAGEMENT].accountManagementRsp.data.totalSize

export const mainListSortSelector = createSelector(
  (state) => state[ACCOUNT_MANAGEMENT],
  (accountManagement) => accountManagement.sortConfig
)

export default accountManagementSlice.reducer
export const { updateSortConfig, resetAccountList, resetSortConfig } =
  accountManagementSlice.actions
