import styled, { css } from 'styled-components'
import { spacingObj } from 'assets/styles/Variable/Spacing'

// ButtonList 基礎樣式
const ButtonListBaseStyles = () => {
  return css`
    display: flex;

    &.style-buttonlist-vertical {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    > button {
      &.btn {
        &:nth-of-type(n + 2) {
          margin-left: ${spacingObj.small};
        }
      }
    }
  `
}

function StyleButtonListContainer({ className, children, ...prop }) {
  const { direction } = prop
  const directionClassName = direction ? 'style-buttonlist-vertical' : ''
  className += `${className || ''} ${directionClassName}`
  return <div className={`style-buttonlist ${className || ''}`}>{children}</div>
}
const StyleButtonList = styled(StyleButtonListContainer)`
  ${(props) => ButtonListBaseStyles(props)}
`

export { StyleButtonList }
