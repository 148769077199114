import { IconButton } from 'components/units'
import withPermission from 'components/hocs/withPermission'
import { statusFormatter } from 'helpers/tableElFormat'
import { COMPONENT_PERMISSIONS } from 'constant'
import { colorObj } from 'assets/styles/Variable/Color'

// 贈點設定表格的狀態內容呈現
const buttonStatusFormatter = (
  cell,
  row,
  rowIndex,
  {
    pointSettingTableState,
    isBaseSettingEdit,
    setLoadingPointSetting,
    handlePointSettingEdit,
    handlePointSettingRemove,
    setShowStopWarning,
    setSelectedRowID,
  }
) => {
  const { UPDATE, DELETE } = COMPONENT_PERMISSIONS.point
  const PermissionStopIcon = withPermission(IconButton, UPDATE)
  const PermissionEditIcon = withPermission(IconButton, UPDATE)
  const PermissionDeleteIcon = withPermission(IconButton, DELETE)

  return (
    <span className="icon-feature-container d-flex align-items-center">
      {Object.keys(cell).map((state, i) => {
        let key = `icon-feature-${i}-${state}`
        if (cell[state].visible) {
          switch (state) {
            case 'stopBtn':
              return (
                <PermissionStopIcon
                  key={key}
                  type="stop"
                  alt="icon-page-stop"
                  onClick={() => {
                    setShowStopWarning(true)
                    setSelectedRowID(row.gainPointRuleId)
                  }}
                  color={colorObj.lightGray}
                  tooltip={
                    <>
                      停用此贈點設定
                      <br />
                      並將於隔日生效
                    </>
                  }
                />
              )
            case 'editBtn':
              return (
                <PermissionEditIcon
                  key={key}
                  type="edit"
                  alt="icon-page-edit"
                  className="mr-2"
                  onClick={() => {
                    handlePointSettingEdit(row.gainPointRuleId)
                  }}
                  tooltip="編輯"
                />
              )
            case 'deleteBtn':
              return (
                <PermissionDeleteIcon
                  key={key}
                  type="delete"
                  alt="icon-page-delete"
                  className={`setting-img ${
                    isBaseSettingEdit ? 'custom-disabled' : ''
                  }`}
                  onClick={() => {
                    handlePointSettingRemove(
                      row.gainPointRuleId,
                      pointSettingTableState,
                      setLoadingPointSetting
                    )
                  }}
                  color={colorObj.lightGray}
                  tooltip="刪除"
                />
              )
            default:
              break
          }
        }
      })}
    </span>
  )
}

// 贈點設定表格的body內容
const tableColumns = ({
  pointSettingTableState,
  isBaseSettingEdit,
  setPointSettingTableState,
  handlePointSettingEdit,
  handlePointSettingRemove,
  setShowStopWarning,
  setSelectedRowID,
  isHidden,
}) => {
  return [
    {
      dataField: 'no',
      text: 'No',
      sort: true,
      onSort: (field, order) => {
        setPointSettingTableState((prevState) => {
          return {
            ...prevState,
            sortFieldName: field,
            sortOrder: order,
          }
        })
      },
    },
    { dataField: 'name', text: '名稱' },
    { dataField: 'pointCategory', text: '類別', sort: true },
    { dataField: 'validPeriod', text: '效期' },
    {
      dataField: 'object',
      text: '對象',
      sort: true,
    },
    {
      dataField: 'condition',
      text: '條件',
      formatter: (cell, row) => {
        const condition = row?.condition
        const limit = row?.limit
        const limitArr = [false, false, true, false]

        return (
          <>
            {condition && (
              <div
                dangerouslySetInnerHTML={{
                  __html: condition.replace(/&/g, '&<br/>'),
                }}
              />
            )}
            {limit && limit !== '無' && statusFormatter(limit, ...limitArr)}
          </>
        )
      },
    },
    { dataField: 'content', text: '內容' },
    {
      dataField: 'state',
      text: '狀態',
      sort: true,
      formatter: (cell, row) => {
        let result = ''
        let statusArr = [true, false, false]
        switch (row.state) {
          case '準備中':
            result = '準備中'
            statusArr = statusArr.fill(false)
            break
          case '進行中':
            result = '進行中'
            statusArr = [true, false, false]
            break
          case '已過期':
            result = '已過期'
            statusArr = [false, false, true]
            break
          case '已停用':
            result = '已停用'
            statusArr = [false, false, true]
            break
        }
        return statusFormatter(result, ...statusArr)
      },
      onSort: (field, order) => {
        setPointSettingTableState((prevState) => {
          return {
            ...prevState,
            sortFieldName: field,
            sortOrder: order,
          }
        })
      },
    },
    {
      dataField: 'funcButton',
      text: '',
      formatter: buttonStatusFormatter,
      formatExtraData: {
        pointSettingTableState,
        isBaseSettingEdit,
        handlePointSettingEdit,
        handlePointSettingRemove,
        setShowStopWarning,
        setSelectedRowID,
      },
      style: () => {
        return {
          minWidth: '75px',
        }
      },
      hidden: isHidden,
    },
  ]
}

export { tableColumns }
