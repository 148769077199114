import styled, { css } from 'styled-components'
import { colorObj, method } from 'assets/styles/Variable/Color'

const { colorHandle } = method

// H element 共用樣式
const hElementBaseStyles = css`
  font-size: ${(props) => props.fontSize || '16px'};
  margin: ${(props) => props.margin || '0'};
  opacity: ${(props) => props.opacity || 1};
  color: ${(props) => props.color || 'inherit'};
  font-weight: ${(props) => props.fontWeight || 500};
`
const StyleH1 = styled.h1`
  ${hElementBaseStyles};
  font-size: 26px;
  margin: 0 0 24px 0;
  font-weight: 500;
  color: ${(props) =>
    props.colorType
      ? colorHandle(props.colorType, colorObj, props.colorAlpha)
      : ''};
`
const StyleH2 = styled.h2`
  ${hElementBaseStyles};
  font-size: 24px;
  color: ${(props) =>
    props.colorType
      ? colorHandle(props.colorType, colorObj, props.colorAlpha)
      : ''};
`
const StyleH3 = styled.h3`
  ${hElementBaseStyles};
  font-size: 18px;
  color: ${(props) =>
    props.colorType
      ? colorHandle(props.colorType, colorObj, props.colorAlpha)
      : ''};
`
const StyleH4 = styled.h4`
  ${hElementBaseStyles};
  font-size: 14px;
  font-weight: 500;
  color: ${(props) =>
    props.colorType
      ? colorHandle(props.colorType, colorObj, props.colorAlpha)
      : ''};
`
const StyleH5 = styled.h5`
  ${hElementBaseStyles};
  font-size: 13px;
  color: ${(props) =>
    props.colorType
      ? colorHandle(props.colorType, colorObj, props.colorAlpha)
      : ''};
`
const StyleH6 = styled.h6`
  ${hElementBaseStyles};
  font-size: 13px;
  font-weight: normal;
  color: ${(props) =>
    props.colorType
      ? colorHandle(props.colorType, colorObj, props.colorAlpha)
      : ''};
`

export { StyleH1, StyleH2, StyleH3, StyleH4, StyleH5, StyleH6 }
