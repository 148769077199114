import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb } from 'components/units'
import { ActivityFormRecordPage } from 'components/pages/ActivitiesCode'
function NavSection() {
  const BreadcrumbConfig = {
    now: <span className="width-max-content d-inline-block">活動表單紀錄</span>,
    pathList: [{ title: '活動條碼 ', slash: true }],
  }
  return <Breadcrumb {...BreadcrumbConfig} />
}

function ActivityFormRecord() {
  const { ActivityFormRecordList } = ActivityFormRecordPage

  return (
    <Basic navSection={NavSection}>
      <ContentSection>
        <ActivityFormRecordList />
      </ContentSection>
    </Basic>
  )
}

export default ActivityFormRecord
