/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { useDispatch, useSelector } from 'react-redux'
import { Wrapper } from 'components/pages/Gift/ListExchangeGiftStyle'
import {
  Caption,
  DetailModal,
} from 'components/pages/Gift/ListExchangeGiftSubComponents'
import { Table, NoDataIndication } from 'components/units'
import PaginationInput from 'components/units/PaginationInput'
import { paginationOptions } from 'constant/common'
import {
  statusFormatter,
  formatNumberLeftStyle,
  formatTextLeftStyle,
  tableNameCell,
} from 'helpers/tableElFormat'
import { authAccountSelector } from 'store/auth/authSelector'
import {
  getGiftExchangeRecordListThunk,
  getGiftExchangeRecordDetailThunk,
} from 'store/gift-list/giftListAsync'
import {
  resetDetailModal,
  giftExchangeRecordListSelector,
  giftExchangeRecordDetailSelector,
} from 'store/gift-list/giftListSlice'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'

function List({
  tablePayload,
  sortable,
  setTablePayload,
  searchPayload,
  tabValue,
}) {
  const dispatch = useDispatch()
  const { brandId } = useSelector(authAccountSelector)
  const exchangeGiftRecordList = useSelector(giftExchangeRecordListSelector)
  const exchangeGiftRecordDetail = useSelector(giftExchangeRecordDetailSelector)

  const [isShowModal, setIsShowModal] = useState(false)
  const { isDataLoading } = useSelector(getGlobalState)

  /* 呼叫詳細資料 API 並打開 Modal */
  const onSelect = (row) => {
    const { giftRecordObjId } = row
    dispatch(getGiftExchangeRecordDetailThunk({ giftRecordObjId, brandId }))
    setIsShowModal(true)
  }

  /* 關閉 Modal 並重置資料 */
  const handleModalClose = () => {
    setIsShowModal(false)
    dispatch(resetDetailModal())
  }

  /* Table 的排序、分頁、搜尋等變動 */
  const handleTableChange = (type, data) => {
    const { page, sizePerPage, sortField, sortOrder } = data

    const updatedPayload = {
      ...tablePayload,
      p: page || tablePayload.p,
      ps: sizePerPage || tablePayload.ps,
      so: sortOrder || tablePayload.so,
      sf: sortField || tablePayload.sf,
    }

    setTablePayload(updatedPayload)
  }

  /* Table 的單行設定 */
  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    onSelect,
  }

  const columnsConfig = [
    { dataField: 'key', text: '', hidden: true },
    { dataField: 'exchangedTime', text: '兌換日期', sort: sortable },
    { dataField: 'customClientId', text: '會員編號', sort: sortable },
    { dataField: 'member', text: '會員' },
    { dataField: 'phone', text: '手機' },
    { dataField: 'exchangeAndReceive', text: '兌換&領取', sort: sortable },
    {
      dataField: 'giftInfo',
      text: '名稱',
      sort: sortable,
      formatter: tableNameCell,
    },
    {
      dataField: 'method',
      text: '兌換方式',
      style: formatNumberLeftStyle(),
      headerStyle: formatTextLeftStyle(),
    },
    {
      dataField: 'status',
      text: '狀態',
      sort: sortable,
      formatter: (cell, row) => {
        const { statusDescription } = row
        // 0 未兌換、1 已兌換、2 已領取 ; 已使用(兌換通路為簡訊發送時)、3 已出貨、90 已取消
        let statusParameter = [false, false, false]
        if (cell === 2 || cell === 3) statusParameter = [true, false, false]
        if (cell === 90) statusParameter = [false, false, true]
        return statusFormatter(statusDescription, ...statusParameter)
      },
      style: formatNumberLeftStyle({
        minWidth: '80px',
      }),
      headerStyle: formatTextLeftStyle(),
    },
  ]

  useEffect(() => {
    if (tabValue !== 'exchangeGift') return
    const updateListData = async () => {
      try {
        await dispatch(updateDataLoading(true))

        const apiPayload = {
          ...tablePayload,
          ...searchPayload,
          brandId,
        }

        await dispatch(getGiftExchangeRecordListThunk(apiPayload))
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(updateDataLoading(false))
      }
    }
    updateListData()
  }, [tablePayload, searchPayload, brandId, dispatch, tabValue])

  return (
    <Wrapper>
      <PaginationProvider
        pagination={paginationFactory({
          ...paginationOptions,
          totalSize: exchangeGiftRecordList.totalSize,
          page: tablePayload.p,
          sizePerPage: tablePayload.ps,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <Caption
              paginationProps={paginationProps}
              total={exchangeGiftRecordList.totalSize}
            />
            <Table
              headerClasses="table-header"
              bodyClasses="paragraph"
              className="overflow-auto"
              keyField="key"
              data={isDataLoading ? [] : exchangeGiftRecordList.list}
              columns={columnsConfig}
              selectRow={selectRow}
              rowStyle={{ cursor: 'pointer' }}
              striped
              remote
              onTableChange={handleTableChange}
              noDataIndication={
                <NoDataIndication
                  isLoading={isDataLoading}
                  message={isDataLoading ? '載入中...' : '尚無資料'}
                />
              }
              {...paginationTableProps}
            />
            <div className="d-flex justify-content-center">
              <PaginationListStandalone {...paginationProps} />
              <PaginationInput
                onPageChange={paginationProps.onPageChange}
                page={paginationProps.page}
                sizePerPage={paginationProps.sizePerPage}
                totalSize={paginationProps.totalSize}
              />
            </div>
          </>
        )}
      </PaginationProvider>
      <DetailModal
        show={isShowModal}
        data={exchangeGiftRecordDetail}
        isDefaultDataLoading={!exchangeGiftRecordDetail.isFetch}
        onClose={handleModalClose}
      />
    </Wrapper>
  )
}

List.propTypes = {
  tablePayload: PropTypes.shape({
    p: PropTypes.number.isRequired,
    ps: PropTypes.number.isRequired,
    sf: PropTypes.string.isRequired,
    so: PropTypes.string.isRequired,
  }).isRequired,
  sortable: PropTypes.bool.isRequired,
  searchPayload: PropTypes.shape({}).isRequired,
  setTablePayload: PropTypes.func.isRequired,
  tabValue: PropTypes.string.isRequired,
}

export default List
