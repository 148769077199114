import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import { Modal } from 'components/units'

const BatchUploadModalStyle = styled(Modal)`
  &.batch-upload-modal {
    .modal-dialog {
      width: 510px;
    }

    ol {
      padding-left: 50px;

      i {
        text-decoration: underline;
        color: ${colorObj.primary};
        cursor: pointer;
        font-style: normal;
      }
    }
  }
`

const UploadLoadingBoxStyle = styled.div`
  color: ${colorObj.dark};
  min-width: 230px;
  margin: 12px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 15px;
    &:last-child {
      font-size: 13px;
      color: ${colorObj.gray};
      cursor: pointer;
    }
  }

  .cus-spinner-sty {
    width: 17px;
    height: 17px;
    border-width: 2px;
    margin: 0 45px 0 10px;
  }
`

const ToastBoxStyle = styled.div`
  font-size: 15px;
  color: ${colorObj.dark};
  min-width: 250px;
  margin: 12px 4px;
  .toast-head {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    h3 {
      margin: 0;
    }
    margin-bottom: 26px;
    &-solo {
      margin: 0;
    }
  }
  .toast-title {
    font-size: 18px;
  }
  .toast-content {
    font-size: 15px;
    line-height: 24px;
    margin: 0;
  }

  svg {
    cursor: pointer;
  }
`

const ToastSuccessBoxStyle = styled(ToastBoxStyle)`
  padding: 9px 10px 9px 0px;
  .toast-content + span {
    display: inline-block;
    margin-top: 9px;
  }

  .toast-error-msg {
    color: #dc3c50;
  }

  .download-link {
    text-decoration: underline;
    color: ${colorObj.primary};
    cursor: pointer;
    font-style: normal;
  }
`

export default BatchUploadModalStyle

export { UploadLoadingBoxStyle, ToastBoxStyle, ToastSuccessBoxStyle }
