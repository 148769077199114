/**
 * @description 點數活動列舉
 */
const POINT_ACTIVITY = {
  POINT_TYPE: {
    DISCOUNT: {
      VALUE: 1,
      NAME: 'discountType', // 點數折抵
    },
    ADDITION: {
      VALUE: 2,
      NAME: 'additionalPoint', // 點數加贈
    },
    BARCODE_MULTIPLE: {
      VALUE: 3,
      NAME: 'giveAwayType', // 點數加倍
    },
    NOPOINT: {
      VALUE: 0,
      NAME: 'noPoint', // 不贈點
    },
  },
}

export { POINT_ACTIVITY }
