import { useState } from 'react'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import ProductActivityList from 'components/pages/ActivitiesCode/ProductActivity/Main/ProductActivityList'
import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb } from 'components/units'
import { PAGE_BREADCRUMB } from 'constant'

const settingPageType = {
  new: 'new',
  edit: 'edit',
  view: 'view',
}

const paginationOptions = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

const defaultSort = {
  so: 'desc',
  sf: 'no',
}

function ProductActivity() {
  const [event, setEvent] = useState({
    usingMaccActivity: false,
    usingOfflineShop: false,
  })
  const [totalSize, setTotalSize] = useState(0)
  const [apiPayload, setApiPayload] = useState({
    ps: 25,
    p: 1,
    ...defaultSort,
  })

  const paginationFn = {
    onPageChange: (page) => setApiPayload((prev) => ({ ...prev, p: page })),
    onSizePerPageChange: (sizePerPage, page) =>
      setApiPayload((prev) => ({ ...prev, ps: sizePerPage, p: page })),
  }

  return (
    <Basic
      navSection={() =>
        Breadcrumb(PAGE_BREADCRUMB.activitiesCode.productActivity)
      }
    >
      <ContentSection>
        <PaginationProvider
          pagination={paginationFactory({
            ...paginationOptions,
            ...paginationFn,
            totalSize,
            page: apiPayload.p,
            sizePerPage: apiPayload.ps,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ProductActivityList
              paginationProps={paginationProps}
              paginationTableProps={paginationTableProps}
              apiPayload={apiPayload}
              setApiPayload={setApiPayload}
              setTotalSize={setTotalSize}
              event={event}
              setEvent={setEvent}
            />
          )}
        </PaginationProvider>
      </ContentSection>
    </Basic>
  )
}

export default ProductActivity
export { settingPageType }
