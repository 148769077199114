import { useSelector } from 'react-redux'
import { Switch, Route, useHistory } from 'react-router-dom'
import { PAGE_PATHS } from 'constant'
import VerifyEntry from 'pages/AccountVerify/VerifyEntry'
import VerifyFailed from 'pages/AccountVerify/VerifyFailed'
import PasswordSetting from 'pages/passwordSetting/PasswordSetting'
import { tokenSelector } from 'store/account-verify/accountVerifySlice'

function VerifyRouters() {
  const { token } = useSelector(tokenSelector)
  const history = useHistory()
  return (
    <Switch>
      <Route
        exact
        path="/verify/settingPassword"
        render={() => {
          if (token) {
            return <PasswordSetting />
          }
          history.push(PAGE_PATHS.login)
          return null
        }}
      />
      <Route
        exact
        path="/verify/verifyFailed"
        render={() => {
          if (token) {
            return <VerifyFailed />
          }
          history.push(PAGE_PATHS.login)
          return null
        }}
      />
      <Route
        exact
        path="/verify/resettingPassword"
        render={() => {
          if (token) {
            return <PasswordSetting />
          }
          history.push(PAGE_PATHS.login)
          return null
        }}
      />
      <Route exact path="/verify/:token" component={VerifyEntry} />
      <Route
        path="/"
        render={() => {
          history.push(PAGE_PATHS.home)
          return null
        }}
      />
    </Switch>
  )
}

export default VerifyRouters
