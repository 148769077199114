import { getYear } from 'date-fns'

const COMMONFIELDS = {
  CUSTOM_ID: 'customId',
  NAME: 'name',
  PHONE: 'phone',
  BIRTHDAY: 'birthday',
  BIRTHDAYYEAR: 'birthdayYear',
  BIRTHDAYMONTH: 'birthdayMonth',
  BIRTHDAYDAY: 'birthdayDay',
  EMAIL: 'email',
  LAST_DEAL_DATE: 'lastDealDate',
  LINE_UID: 'lineUid',
  MEMBER_SHIPT_LEVEL_CODE: 'membershipLevelCode',
  DATE_RANGE: 'dateRange',
  ITEM_NAME: 'itemName',
}

const PLACEHOLDERS = {
  [COMMONFIELDS.DATE_RANGE]: {
    POINT_HISTORY: '發送 / 扣除時間',
    ACTIVITY_FORM: '時間',
    GIFT_LIST: '發送 / 兌換時間',
  },
  [COMMONFIELDS.ITEM_NAME]: {
    POINT_HISTORY: '消費 / 活動 / 好禮名稱',
    ACTIVITY_FORM: '活動名稱',
    GIFT_LIST: '好禮 / 贈品名稱',
  },
}

const TITLES = {
  [COMMONFIELDS.PHONE]: {
    MEMBER: '手機/電話',
    GIFT_LIST: '手機',
  },
}

const getYearOption = () => {
  const year = []
  const thisYear = getYear(new Date())
  for (let i = thisYear; i >= 1900; i -= 1) {
    year.push({
      text: i,
      value: i,
    })
  }
  return year
}
const getMonthOption = () => {
  const month = []
  for (let i = 1; i <= 12; i += 1) {
    month.push({
      text: i,
      value: i,
      testId: i,
    })
  }
  return month
}
const getDateOption = () => {
  const date = []
  for (let i = 1; i <= 31; i += 1) {
    date.push({
      text: i,
      value: i,
    })
  }
  return date
}
const lastDealDateOptions = [
  { text: '不限', value: 'none' },
  { text: '3 天內', value: 3 },
  { text: '5 天內', value: 5 },
  { text: '7 天內', value: 7 },
  { text: '14 天內', value: 14 },
  { text: '30 天內', value: 30 },
]

/**
 * 根據指定的欄位和模組生成搜索表單的欄位配置。
 * @param {Object} options - 選項對象。
 * @param {Array} options.fields - 需要包含的字段列表。
 * @param {string} options.module - 模組名稱。
 * @returns {Array} 搜索表單的欄位配置。
 */
const getSearchFieldConfig = ({ fields, module }) => {
  const searchFieldConfig = [
    {
      label: '會員編號',
      name: COMMONFIELDS.CUSTOM_ID,
      type: 'text',
      required: false,
      pattern: '',
      feedbackText: '',
      placeholder: '',
    },
    {
      label: 'LINE UID',
      name: COMMONFIELDS.LINE_UID,
      type: 'text',
      required: false,
      pattern: '',
      feedbackText: '',
      placeholder: '',
    },
    {
      label: '姓名',
      name: COMMONFIELDS.NAME,
      type: 'text',
      required: false,
      pattern: '',
      feedbackText: '',
      placeholder: '',
    },
    {
      label: TITLES[COMMONFIELDS.PHONE][module],
      name: COMMONFIELDS.PHONE,
      type: 'text',
      required: false,
      pattern: '',
      feedbackText: '格式錯誤',
      placeholder: '',
    },
    {
      label: '電子郵件',
      name: COMMONFIELDS.EMAIL,
      type: 'email',
      required: false,
      pattern: '',
      feedbackText: '',
      placeholder: '',
    },
    {
      label: '生日',
      type: 'select',
      name: COMMONFIELDS.BIRTHDAY,
      className: 'conbination',
      groupSelect: [
        {
          label: '生日',
          name: COMMONFIELDS.BIRTHDAYYEAR,
          type: 'select',
          options: getYearOption(),
          pattern: '',
          feedbackText: '',
          required: false,
          placeholder: '年',
        },
        {
          label: '生日',
          name: COMMONFIELDS.BIRTHDAYMONTH,
          type: 'select',
          options: getMonthOption(),
          pattern: '',
          feedbackText: '',
          required: false,
          placeholder: '月',
        },
        {
          label: '生日',
          name: COMMONFIELDS.BIRTHDAYDAY,
          type: 'select',
          options: getDateOption(),
          pattern: '',
          feedbackText: '',
          required: false,
          placeholder: '日',
        },
      ],
    },
    {
      label: '會員等級',
      type: 'select',
      name: COMMONFIELDS.MEMBER_SHIPT_LEVEL_CODE,
      groupSelect: [
        {
          label: '會員等級',
          name: COMMONFIELDS.MEMBER_SHIPT_LEVEL_CODE,
          type: 'select',
          required: false,
          options: [],
          pattern: '',
          feedbackText: '',
          placeholder: '請選擇',
        },
      ],
    },
    {
      label: '前次消費',
      type: 'select',
      name: COMMONFIELDS.LAST_DEAL_DATE,
      groupSelect: [
        {
          label: '前次消費',
          name: COMMONFIELDS.LAST_DEAL_DATE,
          type: 'select',
          required: false,
          options: lastDealDateOptions,
          pattern: '',
          feedbackText: '',
          placeholder: '請選擇',
        },
      ],
    },
    {
      label: '時間',
      name: COMMONFIELDS.DATE_RANGE,
      type: 'dateRange',
      required: false,
      pattern: '',
      feedbackText: '',
      placeholder: PLACEHOLDERS[COMMONFIELDS.DATE_RANGE][module],
    },
    {
      label: '名稱',
      name: COMMONFIELDS.ITEM_NAME,
      type: 'text',
      required: false,
      pattern: '',
      feedbackText: '',
      placeholder: PLACEHOLDERS[COMMONFIELDS.ITEM_NAME][module],
    },
  ]
  return searchFieldConfig.filter((singleField) =>
    fields.includes(singleField.name)
  )
}
/**
 * 產生搜尋表單使用的錯誤訊息的初始值物件。
 * @param {Array} config - 表單配置的陣列，每個元素包含 { name, required, type, groupSelect } 屬性。
 * @returns {Object} 初始化錯誤訊息物件。
 */

const getInitErrorMsg = (config) => {
  const errorMsg = {}

  config.forEach((field) => {
    const { name, required, type, groupSelect } = field
    if (type === 'select') {
      groupSelect.forEach((oneSelect) => {
        errorMsg[oneSelect.name] = oneSelect.required ? '尚未填寫' : ''
      })
    } else {
      errorMsg[name] = required ? '尚未填寫' : ''
    }
  })

  return errorMsg
}
/**
 * 產生搜尋表單使用的輸入值的初始化物件。
 * @param {Array} config - 表單配置的陣列，每個元素包含 { name, type, groupSelect } 屬性。
 * @returns {Object} 初始化狀態物件。
 */
const getInitState = (config) => {
  const state = {}

  config.forEach((field) => {
    const { name, type, groupSelect } = field
    switch (type) {
      case 'select':
        groupSelect.forEach((oneSelect) => {
          state[oneSelect.name] = ''
        })
        break
      case 'dateRange':
        state[name] = [
          {
            startDate: '',
            endDate: '',
            key: 'selection',
          },
        ]
        break
      default:
        state[name] = ''
        break
    }
  })
  return state
}

// 會員查詢欄位
const MemberSearchConfig = getSearchFieldConfig({
  fields: [
    COMMONFIELDS.CUSTOM_ID,
    COMMONFIELDS.NAME,
    COMMONFIELDS.PHONE,
    COMMONFIELDS.BIRTHDAY,
    COMMONFIELDS.EMAIL,
    COMMONFIELDS.LAST_DEAL_DATE,
    COMMONFIELDS.LINE_UID,
    COMMONFIELDS.MEMBER_SHIPT_LEVEL_CODE,
  ],
  module: 'MEMBER',
})
const MemberSearchInitError = getInitErrorMsg(MemberSearchConfig)
const MemberSearchInitState = getInitState(MemberSearchConfig)

// 點數歷程查詢欄位
const PointHistorySearchConfig = getSearchFieldConfig({
  fields: [
    COMMONFIELDS.CUSTOM_ID,
    COMMONFIELDS.DATE_RANGE,
    COMMONFIELDS.ITEM_NAME,
  ],
  module: 'POINT_HISTORY',
})

const PointHistorySearchInitError = getInitErrorMsg(PointHistorySearchConfig)
const PointHistorySearchState = getInitState(PointHistorySearchConfig)

// 活動表單紀錄查詢欄位
const ActivityFormRecordSearchConfig = getSearchFieldConfig({
  fields: [
    COMMONFIELDS.CUSTOM_ID,
    COMMONFIELDS.DATE_RANGE,
    COMMONFIELDS.ITEM_NAME,
  ],
  module: 'ACTIVITY_FORM',
})
const ActivityFormRecordSearchInitError = getInitErrorMsg(
  ActivityFormRecordSearchConfig
)
const ActivityFormRecordSearchInitState = getInitState(
  ActivityFormRecordSearchConfig
)

// 好禮贈送 - 好禮名單查詢欄位
const GiftListSearchConfig = getSearchFieldConfig({
  fields: [
    COMMONFIELDS.CUSTOM_ID,
    COMMONFIELDS.PHONE,
    COMMONFIELDS.DATE_RANGE,
    COMMONFIELDS.ITEM_NAME,
  ],
  module: 'GIFT_LIST',
})
const GiftListSearchInitError = getInitErrorMsg(GiftListSearchConfig)
const GiftListSearchInitState = getInitState(GiftListSearchConfig)

export {
  MemberSearchConfig,
  MemberSearchInitError,
  MemberSearchInitState,
  PointHistorySearchConfig,
  PointHistorySearchInitError,
  PointHistorySearchState,
  ActivityFormRecordSearchConfig,
  ActivityFormRecordSearchInitError,
  ActivityFormRecordSearchInitState,
  GiftListSearchConfig,
  GiftListSearchInitError,
  GiftListSearchInitState,
  COMMONFIELDS,
  getSearchFieldConfig,
  getInitErrorMsg,
  getInitState,
  getMonthOption,
  getDateOption,
  getYearOption,
  lastDealDateOptions,
}
