import styled from 'styled-components'
import { colorObj, method } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const { colorHandle } = method

const LocalStyle = styled.div`
  width: 100%;
  margin: 24px 40px 24px;

  .start-cal-remind {
    padding: 10px 20px;
    border-radius: 4px;
    border: 1px solid rgba(51, 51, 51, 0.66);
    opacity: 0.5;
    float: right;
  }
  .membership-setting-container {
    .base-setting-container {
      color: ${colorHandle('dark', colorObj)};
      line-height: 2.5rem;

      .base-setting-title {
        color: ${colorHandle('dark', colorObj, 0.66)};
        min-width: 95px;
      }
      .base-setting-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &.base-setting-content-downgrade-time {
          .dropdown-toggle {
            border-color: transparent;
            margin-left: -12px;

            &::after {
              visibility: hidden;
            }
          }
        }
        .base-setting-input-group {
          width: 100px;
          display: inline-block;
        }
        .invalid-feedback {
          line-height: 1.5rem;
          margin-top: 0;
        }
        .custom-invalid-feedback {
          line-height: 1;
          margin-top: 10px;
        }
      }
    }
    .table-rule-setting {
      ol,
      ul {
        padding-left: 1.2rem;
      }
      .level-lowest-notation {
        color: ${colorObj.darkerGray};
      }

      .icon-feature-container {
        .style-tooltip-container {
          margin-right: ${spacingObj.small};
          width: 16px;
          height: 16px;

          &:last-child {
            margin-right: 0;
          }
        }
        [data-id='tooltip'] {
          font-weight: 400;
        }
      }
    }
  }
`

export default LocalStyle
