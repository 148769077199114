/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { useDispatch, useSelector } from 'react-redux'
import { Wrapper } from 'components/pages/Gift/ListExclusiveGiftStyle'
import {
  Caption,
  DetailModal,
} from 'components/pages/Gift/ListExclusiveGiftSubComponents'
import { Table, NoDataIndication } from 'components/units'
import PaginationInput from 'components/units/PaginationInput'
import { paginationOptions } from 'constant/common'
import { statusFormatter } from 'helpers/tableElFormat'
import { authAccountSelector } from 'store/auth/authSelector'
import {
  getVipGiftExchangeRecordListThunk,
  getVipGiftExchangeRecordDetailThunk,
} from 'store/gift-list/giftListAsync'
import {
  resetDetailModal,
  vipGiftExchangeRecordListSelector,
  vipGiftExchangeRecordDetailSelector,
} from 'store/gift-list/giftListSlice'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'

function List({
  tablePayload,
  sortable,
  setTablePayload,
  searchPayload,
  tabValue,
}) {
  const dispatch = useDispatch()
  const { brandId } = useSelector(authAccountSelector)
  const vipGiftRecordList = useSelector(vipGiftExchangeRecordListSelector)
  const vipGiftRecordDetail = useSelector(vipGiftExchangeRecordDetailSelector)
  const [isShowModal, setIsShowModal] = useState(false)
  const { isDataLoading } = useSelector(getGlobalState)

  /* 呼叫詳細資料 API 並打開 Modal */
  const onSelect = (row) => {
    const { giftRecordObjId } = row
    setIsShowModal(true)
    dispatch(getVipGiftExchangeRecordDetailThunk({ giftRecordObjId, brandId }))
  }

  /* 關閉 Modal 並重置資料 */
  const handleModalClose = () => {
    setIsShowModal(false)
    dispatch(resetDetailModal())
  }

  /* Table 的排序、分頁、搜尋等變動 */
  const handleTableChange = (type, data) => {
    const { page, sizePerPage, sortField, sortOrder } = data

    const updatedPayload = {
      ...tablePayload,
      p: page || tablePayload.p,
      ps: sizePerPage || tablePayload.ps,
      so: sortOrder || tablePayload.so,
      sf: sortField || tablePayload.sf,
    }

    setTablePayload(updatedPayload)
  }

  /* Table 的單行設定 */
  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    onSelect,
  }

  /* Table 欄位設定 */
  const columns = [
    { dataField: 'key', text: '', hidden: true },
    { dataField: 'sendTime', text: '發送日期', sort: sortable },
    { dataField: 'customClientId', text: '會員編號', sort: sortable },
    { dataField: 'member', text: '會員' },
    { dataField: 'phone', text: '手機' },
    { dataField: 'receiveWay', text: '領取方式', sort: sortable },
    { dataField: 'name', text: '名稱' },
    { dataField: 'content', text: '內容' },
    {
      dataField: 'status',
      text: '狀態',
      sort: sortable,
      formatter: (cell, row) => {
        const { statusDescription } = row
        // 0 未發送、1 已發送、2 已領取、90 已過期
        let statusParameter = [false, false, false]
        if (cell === 2) statusParameter = [true, false, false]
        if (cell === 90) statusParameter = [false, false, true]
        return statusFormatter(statusDescription, ...statusParameter)
      },
      style: { minWidth: '80px' },
    },
  ]

  /* 獲取專屬好禮列表 */
  useEffect(() => {
    if (tabValue !== 'exclusiveGift') return
    const updateListData = async () => {
      try {
        await dispatch(updateDataLoading(true))

        const apiPayload = {
          ...tablePayload,
          ...searchPayload,
          brandId,
        }
        await dispatch(getVipGiftExchangeRecordListThunk(apiPayload))
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(updateDataLoading(false))
      }
    }
    updateListData()
  }, [tablePayload, searchPayload, brandId, dispatch, tabValue])

  return (
    <Wrapper>
      <PaginationProvider
        pagination={paginationFactory({
          ...paginationOptions,
          totalSize: vipGiftRecordList.totalSize,
          page: tablePayload.p,
          sizePerPage: tablePayload.ps,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <Caption
              paginationProps={paginationProps}
              total={vipGiftRecordList.totalSize}
            />
            <Table
              headerClasses="table-header"
              bodyClasses="paragraph"
              className="overflow-auto"
              keyField="key"
              data={isDataLoading ? [] : vipGiftRecordList.list}
              columns={columns}
              selectRow={selectRow}
              rowStyle={{ cursor: 'pointer' }}
              striped
              remote
              onTableChange={handleTableChange}
              noDataIndication={
                <NoDataIndication
                  isLoading={isDataLoading}
                  message={isDataLoading ? '載入中...' : '尚無資料'}
                />
              }
              {...paginationTableProps}
            />
            <div className="d-flex justify-content-center">
              <PaginationListStandalone {...paginationProps} />
              <PaginationInput
                onPageChange={paginationProps.onPageChange}
                page={paginationProps.page}
                sizePerPage={paginationProps.sizePerPage}
                totalSize={paginationProps.totalSize}
              />
            </div>
          </>
        )}
      </PaginationProvider>
      <DetailModal
        show={isShowModal}
        data={vipGiftRecordDetail}
        isDefaultDataLoading={!vipGiftRecordDetail.isFetch}
        onClose={handleModalClose}
      />
    </Wrapper>
  )
}

List.propTypes = {
  tablePayload: PropTypes.shape({
    p: PropTypes.number.isRequired,
    ps: PropTypes.number.isRequired,
    sf: PropTypes.string.isRequired,
    so: PropTypes.string.isRequired,
  }).isRequired,
  sortable: PropTypes.bool.isRequired,
  searchPayload: PropTypes.shape({}).isRequired,
  setTablePayload: PropTypes.func.isRequired,
  tabValue: PropTypes.string.isRequired,
}

export default List
