import styled from 'styled-components'
import { FormWrapper } from 'components/templates'
import { colorObj } from 'assets/styles/Variable/Color'

const SettingNoWrapper = styled(FormWrapper)`
  .rules-form .form-row {
    border-bottom: none;
    &:not(:first-child):not(:last-child) {
      margin-bottom: 0;
    }
  }

  .form-label {
    flex: 0 0 80px;
  }

  .form-group-row-nowarp .row {
    flex-wrap: nowrap;
  }

  .buttom-custom {
    margin-left: 8px;
    min-width: 120px;
  }

  .search-area {
    align-items: center;
    margin-bottom: 16px;
    input,
    .dropdown {
      height: 24px;
    }
    input {
      width: 144px;
      margin-right: 20px;
    }
    button {
      min-width: 100px;
      margin-right: 16px;
    }
    .dropdown button {
      min-width: 80px;
      margin-right: 4px;
    }
  }

  .caption {
    margin-bottom: 16px;
    display: flex;
    white-space: nowrap;
    &-text {
      color: ${colorObj.dark};
    }
    .form-invalid-feeback {
      padding: 0;
      margin: 0 0 0 24px;
      width: auto;
    }
    .label-title {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &:first-child {
        justify-content: flex-start;
      }
      &:last-child {
        justify-content: flex-end;
      }
      .remove-btn {
        margin-left: 24px;
      }
      .last-btn {
        margin-left: 16px;
      }
    }
  }

  .table-header {
    color: red;
  }

  svg {
    margin-left: 5px;
    path {
      fill: #fff;
      fill-opacity: 1;
    }
  }
`

const ImportModalWrapper = styled.div`
  text-align: start;
  padding: 0 40px;
`

const MemberModalWrapper = styled.div`
  text-align: start;

  .member-title {
    color: ${colorObj.dark};
    font-size: 14px;
    margin-bottom: 16px;
    &:first-child {
      margin-bottom: 25px;
    }

    .member-note {
      color: ${colorObj.gray};
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .member-info {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .member-label {
    color: ${colorObj.darkGray};
    font-size: 15px;
    width: 90px;
  }

  .member-content {
    color: ${colorObj.dark};
    font-size: 15px;
    .member-note {
      color: ${colorObj.gray};
      font-size: 13px;
      margin-left: 8px;
    }
  }

  hr {
    margin: 24px 0;
  }
`

const ToastLoadingWrapper = styled.div`
  color: ${colorObj.dark};
  min-width: 230px;
  margin: 12px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    &:last-child {
      font-size: 13px;
      color: ${colorObj.gray};
      cursor: pointer;
    }
  }
`

const ToastWrapper = styled.div`
  color: ${colorObj.dark};
  min-width: 250px;
  margin: 12px 4px;
  .toast-head {
    display: flex;
    justify-content: space-between;
    h3 {
      margin: 0;
    }
    margin-bottom: 26px;
    &-solo {
      margin: 0;
    }
  }
  .toast-title {
    font-size: 18px;
  }
  .toast-content {
    font-size: 15px;
    line-height: 24px;
    margin: 0;
  }

  svg {
    cursor: pointer;
  }
`

const ToastUploadWrapper = styled(ToastWrapper)`
  .toast-content + span {
    display: inline-block;
    margin-top: 9px;
  }
`

const FormatterStatusWrapper = styled.ul`
  list-style-type: disc;
  margin: 0;
  li {
    margin-left: 18px;
    color: ${(props) => props.color || colorObj.dark};
  }
`

const TextLink = styled.span`
  text-decoration: underline;
  color: ${colorObj.primary};
  cursor: pointer;
`

const AddSerialnumber = styled.div`
  .input-group-append {
    position: relative;

    .style-loader-container {
      background-color: transparent;
    }
  }
`

export default SettingNoWrapper
export {
  ImportModalWrapper,
  MemberModalWrapper,
  ToastLoadingWrapper,
  ToastWrapper,
  ToastUploadWrapper,
  FormatterStatusWrapper,
  TextLink,
  AddSerialnumber,
}
