import { hexToRgbA, colorObj } from 'assets/styles/Variable/Color'
import {
  formatterType,
  formatterName,
  formatterFn,
  formatterDateRange,
  formatterAmount,
  formatterTarget,
  formatterContent,
} from 'components/pages/Gift/ExclusiveMainSubComponents'
import { formatTextLeftStyle } from 'helpers/tableElFormat'
import { tabStatus } from 'pages/Gift/config'

const columnType = {
  no: 'no',
  receive: 'receiveWay',
  type: 'giftType',
  name: 'name',
  target: 'object',
  content: 'content',
  dateRange: 'validPeriod',
  amount: 'quantity',
  fn: 'fn',
}

const columnText = {
  [columnType.no]: 'No.',
  [columnType.type]: '類別',
  [columnType.receive]: '領取通路',
  [columnType.name]: '名稱',
  [columnType.target]: '對象',
  [columnType.content]: '內容',
  [columnType.dateRange]: '發送效期',
  [columnType.amount]: '數量',
  [columnType.fn]: '',
}

const typeOption = [
  { text: '入會禮', value: 1 },
  { text: '生日禮', value: 2 },
  { text: '升等禮', value: 3 },
  { text: '續會禮', value: 4 },
  { text: '滿額贈', value: 5 },
  { text: '指定禮', value: 6 },
  { text: '綁定禮', value: 7 },
]

const amountErrorCode = {
  normal: -1,
  finish: 1,
  insufficient: 2,
  fail: 3,
  linkCount: 4,
}

const amountErrorText = {
  [amountErrorCode.finish]: '已發送完畢',
  [amountErrorCode.insufficient]: '序號不足',
  [amountErrorCode.fail]: '無法生成更多序號',
  [amountErrorCode.linkCount]: '連結好禮無法發送',
}

const tableFnType = {
  view: 'viewBtn',
  edit: 'editBtn',
  upload: 'availBtn',
  takeDown: 'unavailBtn',
  remove: 'deleteBtn',
  copy: 'copyBtn',
  sealing: 'sealBtn',
}

const rowStyle = (tabType) => (row) => {
  if (tabType !== tabStatus.unavailable) return null

  const style = {}
  const [, , errorType] = row[columnType.amount]
  if (errorType === null) return style
  if (errorType >= 0) style.backgroundColor = hexToRgbA(colorObj.danger, 0.2)
  return style
}
const columns = (handleEvent, isHidden, tabType) => [
  // workaround:解官方 hidden 屬性無法動態設定的 bug
  { dataField: '', text: '', hidden: true },
  {
    dataField: columnType.no,
    text: columnText[columnType.no],
    sort: true,
    style: { width: '76px' },
  },
  {
    dataField: columnType.type,
    text: columnText[columnType.type],
    formatter: formatterType,
    sort: true,
    style: { width: '76px' },
  },
  {
    dataField: columnType.receive,
    text: columnText[columnType.receive],
    sort: true,
    style: { width: '104px' },
  },
  {
    dataField: columnType.name,
    text: columnText[columnType.name],
    formatter: formatterName,
    style: { width: '142px' },
  },
  {
    dataField: columnType.target,
    text: columnText[columnType.target],
    sort: true,
    style: { width: '68px' },
    formatter: formatterTarget,
  },
  {
    dataField: columnType.content,
    text: columnText[columnType.content],
    style: { width: '146px' },
    formatter: formatterContent,
  },
  {
    dataField: columnType.dateRange,
    text: columnText[columnType.dateRange],
    sort: true,
    formatter: formatterDateRange,
    style: { width: '106px' },
  },
  {
    dataField: columnType.amount,
    text: columnText[columnType.amount],
    sort: true,
    formatter: formatterAmount(handleEvent),
    style: { width: '127px' },
    headerStyle: formatTextLeftStyle(),
  },
  {
    dataField: columnType.fn,
    text: columnText[columnType.fn],
    formatter: formatterFn(handleEvent, tabType),
    style: { width: '0px' },
    hidden: isHidden,
  },
]

const initModalData = {
  status: false,
  payload: { brandId: null, giftSettingId: null },
}

export {
  columnType,
  columnText,
  typeOption,
  amountErrorCode,
  amountErrorText,
  tableFnType,
  rowStyle,
  columns,
  initModalData,
}
