import { differenceInDays, formatISO } from 'date-fns'
import PropTypes from 'prop-types'
import { useCallback, useState, useRef } from 'react'
import styled from 'styled-components'
import { colorCommon, colorObj } from 'assets/styles/Variable/Color'
import {
  Button,
  Select,
  Col,
  Row,
  Container,
  DateRange,
} from 'components/units'
import { showTopToast } from 'components/units/Toast'
import { StyleButtonList as ButtonList } from 'components/widgets/ButtonList'
import { getRandomId, handleKeyPressEnter } from 'helpers/common'
import { fieldRestrictNumber } from 'helpers/validation'

const SearchSectionWrapper = styled(Container)`
  margin: 0 auto;
  padding: 20px 0;

  .searchSectionRow {
    column-gap: 20px;
  }

  .my-col {
    margin-left: -8px !important;
    margin-right: -8px !important;
  }

  .my-row {
    padding-left: 10px;
  }

  .title-label {
    margin: 0;
    padding: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    .red-hint {
      color: ${colorObj.danger};
    }
  }
  .error-msg {
    color: ${colorObj.danger};
    font-size: 14px;
    display: block;
    margin: 2px 4px;
  }
`

const InputWrapper = styled(Col)`
  .input-field {
    width: 100%;
    height: 32px;
    padding: 6px 8px;
    border: 1px ${colorCommon.borderGray} solid;
    border-radius: 2px;
    box-shadow: 0px;
    color: ${colorObj.dark};

    &::placeholder {
      color: ${colorObj.lightGray};
    }
    &:focus {
      border: 1px ${colorObj.primary} solid;
      transition: border 0.1s ease-in-out;
      box-shadow: 0px;
      cursor: auto;
      outline: none;
    }
  }
`

const SelectWrapper = styled(Col)`
  &.conbination > div {
    align-items: left;
  }
  &::placeholder {
    color: ${colorObj.lightGray};
  }
  &:focus {
    border: 1px ${colorObj.primary} solid;
    transition: border 0.1s ease-in-out;
    box-shadow: 0px;
    cursor: auto;
    outline: none;
  }
`

function InputField({
  name,
  type,
  required,
  pattern,
  placeholder,
  value,
  onChange,
  onKeyPress,
  className,
}) {
  return (
    <InputWrapper className="col-9 m-0 p-0">
      <input
        className={`input-field ${className}`}
        data-testid={name}
        id={name}
        name={name}
        type={type}
        required={required}
        pattern={pattern}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </InputWrapper>
  )
}

function SelectField({
  name,
  placeholder,
  options,
  onChange,
  className,
  state,
}) {
  const handleOnBlur = (e) => {
    const originalValue = state[name]
    const valueOnBlur = e.target.value

    if (valueOnBlur !== originalValue) {
      const newValue = valueOnBlur !== '' ? originalValue : ''

      onChange({
        name,
        value: newValue,
      })
    }
  }

  const handleOnChange = (e, value) => {
    onChange({
      name,
      value,
    })
  }

  return (
    <SelectWrapper
      className={`m-0 p-0 ${
        className === 'conbination' ? `pl-2 ${className}` : ''
      }`}
    >
      <Select
        id={name}
        className="w-100"
        optionItems={options}
        dropdownToggleOption={{
          className: 'w-100',
        }}
        clearOptionItem={{
          isOpen:
            name === 'birthdayYear' ||
            name === 'birthdayMonth' ||
            name === 'birthdayDay',
          text: '未選擇',
        }}
        selectedValue={state?.[name]}
        isClearSelectedValue={!state?.[name]}
        formControlOption={{
          name,
          placeholder,
          onBlur: handleOnBlur,
        }}
        searchFeature={{
          isOpen: name === 'birthdayYear',
          data: options,
          onSelect: handleOnChange,
        }}
        onChange={handleOnChange}
        testId={name}
      />
    </SelectWrapper>
  )
}

const validateForm = (form, errorMsgs) => {
  const isFormCheck = form.checkValidity()
  const isValidation = Object.values(errorMsgs).every(
    (singleErrorMsg) => singleErrorMsg === null || singleErrorMsg === ''
  )

  return { isFormCheck, isValidation }
}

export default function SearchSection({
  fields,
  initErrorMsg,
  initState,
  onSubmit,
  onReset,
}) {
  const [formValues, setFormValues] = useState(initState)
  const [errorMsg, setErrorMsg] = useState(initErrorMsg)
  const [isShowErrorMsg, setShowErrorMsg] = useState(false)
  const formRef = useRef(null)

  const isNonEmpty = (value) => {
    return (
      (typeof value === 'string' && value.trim() !== '') ||
      typeof value === 'number'
    )
  }
  const isArrayWithValues = (value) => Array.isArray(value) && value.length > 0
  const isObject = (value) => typeof value === 'object' && value !== null

  const isSearchBtnEnabled = Object.values(formValues).some((value) => {
    if (isObject(value)) {
      return (
        isArrayWithValues(value) &&
        value[0]?.startDate !== '' &&
        value[0]?.endDate !== ''
      )
    }
    return isNonEmpty(value)
  })

  const handleClearAllField = (e) => {
    e.preventDefault()
    // 清空 state 和 errorMsg，使每個值都為''
    setFormValues(
      Object.fromEntries(
        Object.entries(formValues).map(([key]) => [
          key,
          key === 'dateRange'
            ? [{ startDate: '', endDate: '', key: 'selection' }]
            : '',
        ])
      )
    )

    setErrorMsg(
      Object.fromEntries(Object.entries(errorMsg).map(([key]) => [key, '']))
    )
    onReset()
  }

  const handleErrorMsg = useCallback(
    ({ name, value }) => {
      const targetFieldConfig = fields.find((item) => item.name === name) ?? {}
      const fieldConfig = {
        ...targetFieldConfig, // 取得 text 類型的欄位設定
        ...targetFieldConfig?.groupSelect?.find((item) => item.name === name), // 取得 select 類型的欄位設定
      }

      const { pattern, feedbackText, required } = fieldConfig

      let result = ''
      if (!value && required) {
        result = '尚未填寫'
      } else if (pattern && !new RegExp(pattern).test(value)) {
        result = feedbackText
      }

      setErrorMsg((prevErrorMsg) => ({
        ...prevErrorMsg,
        [name]: result,
      }))
    },
    [fields]
  )
  // 取得輸入 input 欄位的值
  const handleChange = (e) => {
    const { name } = e.target
    let newValue
    if (name === 'phone') {
      newValue = fieldRestrictNumber(e)
    } else {
      newValue = e.target.value
    }

    setFormValues({ ...formValues, [name]: newValue })
    handleErrorMsg({ name, value: newValue })
  }
  // 取得輸入 select 欄位的值
  const handleSelectChange = ({ name, value }) => {
    setFormValues({ ...formValues, [name]: value })
    // 驗證錯誤訊息由元件自己處理
  }

  // 取得輸入 dateRange 欄位的值
  const handleDateRangeChange = (ranges) => {
    const { startDate, endDate } = ranges[0]
    const newStartDate =
      startDate === '' ? '' : formatISO(startDate, { representation: 'date' })
    const newEndDate =
      endDate === '' ? '' : formatISO(endDate, { representation: 'date' })
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      startDate: newStartDate,
      endDate: newEndDate,
      dateRange: ranges,
    }))
    // 驗證錯誤訊息由元件自己處理
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // 更新錯誤處理
    Object.entries(formValues).forEach(([key, value]) => {
      handleErrorMsg({ name: key, value })
    })

    const form = formRef.current
    const { isValidation } = validateForm(form, errorMsg)

    if (!isValidation) {
      setShowErrorMsg(true)
      return
    }
    setShowErrorMsg(false)
    // 如果有選擇日期，要檢查日期區間是否超過 90 天

    if (formValues.startDate) {
      const distanceDayNumber = differenceInDays(
        new Date(formValues.endDate),
        new Date(formValues.startDate)
      )

      if (distanceDayNumber > 90) {
        showTopToast(
          {
            message: '日期區間不可超過 90 天',
          },
          'error',
          null,
          getRandomId()
        )
        return
      }
    }
    // key dateRanges 不需要傳到後端
    const filteredFormValues = Object.fromEntries(
      Object.entries(formValues).filter(([key]) => key !== 'dateRange')
    )

    // 如果欄位值前後有空白，要去除空白，且只傳送有值的欄位
    Object.keys(filteredFormValues).forEach((key) => {
      if (typeof filteredFormValues[key] === 'string') {
        filteredFormValues[key] = filteredFormValues[key].trim()
      }
      if (!filteredFormValues[key]) {
        delete filteredFormValues[key]
      }
    })

    // 「全部會員」要帶上 null 做為參數
    // 但 option 的 value 無法直接設為 null, 因此先使用字串, 再手動轉換
    if (filteredFormValues.membershipLevelCode === 'null')
      filteredFormValues.membershipLevelCode = null
    onSubmit(filteredFormValues)
  }

  return (
    <SearchSectionWrapper>
      <form
        noValidate
        onSubmit={handleSubmit}
        ref={formRef}
        data-testid="searchSection"
      >
        <Row className="searchSectionRow">
          {fields.map((field) => {
            const {
              label,
              name,
              type,
              required,
              pattern,
              placeholder,
              className,
              groupSelect,
            } = field
            const value = formValues[name] ?? ''
            const isConbination = className === 'conbination'
            return (
              <Col key={name} className="col-12 col-sm-6 col-md-4 mb-3 my-col">
                <Row
                  className={` ${isConbination ? 'select-group' : ''} my-row`}
                >
                  <Col className="m-0 p-0 col-3 d-flex align-items-center">
                    <label htmlFor={name} className="title-label">
                      {label}：{' '}
                      {required && <span className="red-hint">*</span>}
                    </label>
                  </Col>
                  {/* 這邊的 type 有 text、select、dateRange 三種 */}
                  {(type === 'text' || type === 'email') && (
                    <InputField
                      key={name}
                      label={label}
                      name={name}
                      type={type}
                      required={required}
                      pattern={pattern}
                      placeholder={placeholder}
                      value={value}
                      onChange={handleChange}
                      onKeyPress={(e) =>
                        handleKeyPressEnter(e, () => handleSubmit(e))
                      }
                      errorMsg={errorMsg}
                      isShowErrorMsg={isShowErrorMsg}
                      className={className}
                    />
                  )}
                  {type === 'select' && (
                    <div
                      className="d-flex col-9 m-0 p-0"
                      style={{ gap: '4px' }}
                    >
                      {groupSelect.map((singleSelect) => {
                        const {
                          name: nameGroupSelect,
                          placeholder: placeholderGroupSelect,
                          options: optionsGroupSelect,
                        } = singleSelect
                        return (
                          <SelectField
                            key={nameGroupSelect}
                            name={nameGroupSelect}
                            placeholder={placeholderGroupSelect}
                            options={optionsGroupSelect}
                            state={formValues}
                            onChange={handleSelectChange}
                            className={className}
                            value={value}
                          />
                        )
                      })}
                    </div>
                  )}
                  {type === 'dateRange' && (
                    <DateRange
                      ranges={value}
                      onChange={handleDateRangeChange}
                      placeholder={placeholder}
                      className="col-9 m-0 p-0"
                    />
                  )}
                </Row>
                <Row
                  className={`my-row select-hint ${isConbination ? 'g-3' : ''}`}
                >
                  <Col className="col-3 d-flex align-items-center" />
                  <Col className="col-9 ">
                    {isShowErrorMsg && (
                      <span className="px-2 error-msg">{errorMsg[name]}</span>
                    )}
                  </Col>
                </Row>
              </Col>
            )
          })}
        </Row>
        <ButtonList className="justify-content-end my-4">
          <Button
            type="submit"
            variant="outline-darkerGray"
            colorType="darkerGray"
            colorAlpha="1"
            size="lg"
            onClick={handleClearAllField}
          >
            清空條件
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="lg"
            disabled={!isSearchBtnEnabled}
          >
            查詢
          </Button>
        </ButtonList>
      </form>
    </SearchSectionWrapper>
  )
}

export { validateForm }

SearchSection.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      required: PropTypes.bool,
      pattern: PropTypes.string,
      feedbackText: PropTypes.string,
      placeholder: PropTypes.string,
    })
  ).isRequired,
  initErrorMsg: PropTypes.objectOf(PropTypes.string).isRequired,
  initState: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    dateRange: PropTypes.arrayOf(
      PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        key: PropTypes.string,
      })
    ),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  className: PropTypes.string,
}

InputField.defaultProps = {
  required: false,
  pattern: '',
  placeholder: '',
  value: '',
  onChange: () => {},
  onKeyPress: () => {},
  className: '',
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  state: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

SelectField.defaultProps = {
  placeholder: '',
  className: '',
}
