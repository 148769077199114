import { useCallback, useContext, useEffect, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { colorObj } from 'assets/styles/Variable/Color'
import columns from 'components/pages/SystemConfiguration/account-management/containers/AccountListConfig'
import RolePermissionModal from 'components/pages/SystemConfiguration/AccountManagement/widgets/RolePermissionModal'
import { Card, NoDataIndication, Table } from 'components/units'
import PlainButton from 'components/units/PlainButton'
import { TableToolBar } from 'components/units/Table'
import { PAGE_PATHS } from 'constant'
import { paginationOptions, FUNCTBUTTON } from 'constant/common'
import { RootContext } from 'RootContext'
import {
  mainListSelector,
  mainListSortSelector,
  getAccountManagementThunk,
  mainListTotalSizeSelector,
  updateSortConfig,
  resetAccountList,
  resetSortConfig,
  deleteAccountThunk,
  patchAccountStatusThunk,
} from 'store/account-management/accountManagementSlice'
import {
  modalStatusSelector,
  openPermissionModal,
  closePermissionModal,
} from 'store/account-setting/accountSettingSlice'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'

export default function AccountList() {
  const { brandId } = useContext(RootContext)
  const dispatch = useDispatch()
  const { isDataLoading } = useSelector(getGlobalState)
  const accountList = useSelector(mainListSelector)
  const totalSize = useSelector(mainListTotalSizeSelector)
  const sortConfig = useSelector(mainListSortSelector)
  const { isPermissionModalOpen } = useSelector(modalStatusSelector)
  const sortConfigRef = useRef()
  const brandIdRef = useRef()
  const history = useHistory()

  const handleOpenPermissionModal = () => {
    dispatch(openPermissionModal())
  }

  const handleClosePermissionModal = () => {
    dispatch(closePermissionModal())
  }

  const handleGetAccountList = useCallback(async () => {
    if (!brandId) return
    if (sortConfigRef.current === sortConfig) return
    sortConfigRef.current = sortConfig
    dispatch(updateDataLoading(true))
    dispatch(resetAccountList())
    await dispatch(getAccountManagementThunk(brandId))
    dispatch(updateDataLoading(false))
  }, [brandId, dispatch, sortConfig])

  // table有remote屬性時，需要設定 onTableChange 來處理表格變化
  const onTableChange = (changeType, updatedState) => {
    if (changeType === 'pagination') {
      dispatch(
        updateSortConfig({ p: updatedState.page, ps: updatedState.sizePerPage })
      )
    } else if (changeType === 'sort') {
      // 因顯示欄位名稱不等於要給後端的排序欄位回傳值，所以要做轉換
      const sortFieldMap = {
        roleDescription: 'roleId',
        statusDescription: 'status',
      }
      dispatch(
        updateSortConfig({
          sf: sortFieldMap[updatedState.sortField] || updatedState.sortField,
          so: updatedState.sortOrder,
        })
      )
    }
  }

  // functionButton操作
  const handleFuncButton = async ({ type, itemId: accountId }) => {
    const actions = {
      edit: () =>
        history.push(
          `${PAGE_PATHS.systemConfiguration.accountManagementEdit.replace(
            ':pageType/:accountId',
            'edit'
          )}/${accountId}`
        ),
      delete: () => dispatch(deleteAccountThunk({ brandId, accountId })),
      stop: () =>
        dispatch(
          patchAccountStatusThunk({ brandId, accountId, newStatus: 'stop' })
        ),
      activate: () =>
        dispatch(
          patchAccountStatusThunk({ brandId, accountId, newStatus: 'activate' })
        ),
    }

    if (type !== FUNCTBUTTON.EDIT) {
      if (actions[type]) {
        await actions[type]()
      }
      dispatch(updateDataLoading(true))
      dispatch(resetAccountList())
      dispatch(getAccountManagementThunk(brandId))
      dispatch(updateDataLoading(false))
    } else {
      actions[type]()
    }
  }

  useEffect(() => {
    handleGetAccountList()

    if (brandIdRef.current !== brandId) {
      brandIdRef.current = brandId
      dispatch(resetSortConfig())
    }
  }, [brandId, dispatch, handleGetAccountList])

  return (
    <>
      <Card className="mt-3">
        <PaginationProvider
          pagination={paginationFactory({
            ...paginationOptions,
            totalSize,
            page: sortConfig.p,
            sizePerPage: sortConfig.ps,
          })}
        >
          {({ paginationProps, paginationTableProps }) => {
            return (
              <>
                <Row className="mt-0">
                  <Col className="label-title text-right">
                    <PlainButton
                      onClick={handleOpenPermissionModal}
                      style={{
                        underline: true,
                        color: colorObj.primary,
                        fontWeight: '500',
                      }}
                    >
                      <span>檢視角色權限表</span>
                    </PlainButton>
                  </Col>
                </Row>
                <TableToolBar
                  totalSize={totalSize}
                  paginationProps={paginationProps}
                  sizePerPage={paginationProps.sizePerPage}
                />
                <Table
                  headerClasses="table-header"
                  bodyClasses="paragraph"
                  className="overflow-auto mt-3"
                  keyField="no"
                  data={accountList}
                  columns={columns({
                    onHandleIconButton: handleFuncButton,
                  })}
                  striped
                  noDataIndication={
                    <NoDataIndication
                      isLoading={isDataLoading}
                      message={isDataLoading ? '載入中...' : '尚無資料'}
                    />
                  }
                  remote
                  onTableChange={onTableChange}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...paginationTableProps}
                />
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <PaginationListStandalone {...paginationProps} />
              </>
            )
          }}
        </PaginationProvider>
      </Card>
      <RolePermissionModal
        show={isPermissionModalOpen}
        onClose={handleClosePermissionModal}
      />
    </>
  )
}
