import styled from 'styled-components'
import { colorCommon, colorObj, method } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const { colorHandle } = method

const Wrapper = styled.div`
  .tab-content-inner-nav {
    .nav-item {
      &:first-of-type {
        .nav-link {
          padding-left: 0;
        }
      }
      &:last-of-type {
        .nav-link {
          border-right: 0;
        }
      }
      .nav-link,
      .nav-link:hover {
        box-shadow: none;
      }
    }
    .nav-link {
      border-right: 2px solid ${colorHandle('dark', colorObj, 0.5)};
      border-radius: 0;
      line-height: 1;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-bottom: ${spacingObj.medium};
      font-weight: 500;

      &.active {
        box-shadow: none;
      }
    }
    .tab-content {
      padding: 0;
      box-shadow: none;
    }
  }
  .send-email {
    line-height: 1;
    margin-left: auto;
    font-weight: bold;
    color: ${colorObj.primary};
    text-decoration: underline;
    cursor: pointer;
  }
`

const CaptionWrapper = styled.div`
  color: ${colorObj.dark};
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 16px;

  > div {
    display: flex;
    align-items: center;
  }

  .caption-label {
    min-width: 60px;
    color: ${colorObj.darkGray};
  }

  .caption-color {
    color: ${colorObj.darkGray};
  }
`

const MemberModalWrapper = styled.div`
  text-align: start;
  min-width: 420px;

  .email-warning {
    color: ${colorObj.dark};
    line-height: ${spacingObj.medium};
  }
  .member-title {
    color: ${colorObj.dark};
    font-size: 14px;
    margin-bottom: 16px;
    &:first-child {
      margin-bottom: 25px;
    }

    .member-note {
      color: ${colorObj.gray};
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .member-info {
    display: flex;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .member-label {
    color: ${colorObj.darkGray};
    font-size: 15px;
    min-width: 90px;
  }

  .member-content {
    color: ${colorObj.dark};
    font-size: 15px;

    .member-note {
      color: ${colorObj.gray};
      font-size: 13px;
      margin-left: 8px;
    }
    .tip-container {
      padding: 0 !important;
    }
    .input-group {
      .add-email-field {
        border-color: ${colorCommon.borderGray};
        background-color: transparent;
      }
      .input-group-text {
        cursor: pointer;

        > svg {
          transform: scale(0.5);
        }
      }
    }
  }

  hr {
    margin: 24px 0;
  }
`

const ImportModalWrapper = styled.div`
  text-align: start;
  padding: 0 40px;
`

const TextLink = styled.span`
  text-decoration: underline;
  color: ${colorObj.primary};
  cursor: pointer;
`

const ToastLoadingWrapper = styled.div`
  color: ${colorObj.dark};
  min-width: 230px;
  margin: 12px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 15px;
    &:last-child {
      font-size: 13px;
      color: ${colorObj.gray};
      cursor: pointer;
    }
  }

  .cus-spinner-sty {
    width: 17px;
    height: 17px;
    border-width: 2px;
    margin: 0 45px 0 10px;
  }
`
const ToastWrapper = styled.div`
  font-size: 15px;
  color: ${colorObj.dark};
  min-width: 250px;
  margin: 12px 4px;
  .toast-head {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    h3 {
      margin: 0;
    }
    margin-bottom: 26px;
    &-solo {
      margin: 0;
    }
  }
  .toast-title {
    font-size: 18px;
  }
  .toast-content {
    font-size: 15px;
    line-height: 24px;
    margin: 0;
  }

  svg {
    cursor: pointer;
  }
`
const ToastUploadWrapper = styled(ToastWrapper)`
  .toast-content + span {
    display: inline-block;
    margin-top: 9px;
  }

  .toast-error-msg {
    color: #dc3c50;
  }
`
const IconFeatureStyle = styled.span`
  gap: ${spacingObj.mini};
`

const NotificationWrapper = styled(MemberModalWrapper)`
  width: 420px;
`
export {
  Wrapper,
  CaptionWrapper,
  MemberModalWrapper,
  ImportModalWrapper,
  TextLink,
  ToastWrapper,
  ToastLoadingWrapper,
  ToastUploadWrapper,
  IconFeatureStyle,
  NotificationWrapper,
}
