import { POINT_ACTIVITY } from './PointActivityModalConfig'

/**
 * @description 新增點數活動初始化 state
 * @type {{}}
 */
export const initState = {
  barcode: '',
  name: '',
  startDateType: '',
  startDate: new Date(),
  endDateType: '',
  endDate: new Date(),
  type: null,
  discountPoint: 0,
  discountAmount: 0,
  additionalPoint: 0,
  discountPointType: '',
  pointBarcodeMultiple: {
    id: 1,
    code: '',
    name: '',
  },
  pointMultipleMaxResult: 0,
  barcodeMultipleList: [],
}

/**
 * @description 新增點數活動 Reducer
 * @param state
 * @param action
 * @constructor
 */
export function PointActivityReducer(state, { payload, type }) {
  switch (type) {
    case 'SET_BARCODE_MULTIPLE_LIST': {
      return {
        ...state,
        pointBarcodeMultiple: {
          ...state.pointBarcodeMultiple,
        },
        barcodeMultipleList: generateSelectData(payload),
      }
    }
    case 'CLEAR_ALL_FILEDS': {
      return {
        ...state,
        ...initState,
        pointBarcodeMultiple: {
          ...initState.pointBarcodeMultiple,
        },
        barcodeMultipleList: [...state.barcodeMultipleList],
      }
    }

    case 'CHANGE_FIELD': {
      return {
        ...state,
        [payload.keyName]: payload.value,
      }
    }

    case 'CHANGE_DATE': {
      return {
        ...state,
        [payload.keyName]: payload.value,
      }
    }

    case 'CHANGE_START_DATE': {
      return {
        ...state,
        [payload.keyName]: payload.id,
        startDate: new Date(),
      }
    }

    case 'CHANGE_END_DATE': {
      let newEndDate = ''
      if (payload.id === 'datePermanen') {
        newEndDate = null
      } else {
        newEndDate = new Date()
      }
      return {
        ...state,
        [payload.keyName]: payload.id,
        endDate: newEndDate,
      }
    }

    case 'CHANGE_POINT_TYPE': {
      const initValue = {
        discountPoint: 0,
        discountAmount: 0,
        additionalPoint: 0,
        pointBarcodeMultiple: {
          id: 0,
          code: '',
          name: '',
        },
        pointMultipleMaxResult: 0,
      }

      if (payload.id === POINT_ACTIVITY.POINT_TYPE.DISCOUNT.NAME) {
        return {
          ...state,
          ...initValue,
          discountPointType: payload.id,
          pointBarcodeMultiple: {
            ...initValue.pointBarcodeMultiple,
          },
          type: POINT_ACTIVITY.POINT_TYPE.DISCOUNT.VALUE,
        }
      }
      if (payload.id === POINT_ACTIVITY.POINT_TYPE.ADDITION.NAME) {
        return {
          ...state,
          ...initValue,
          discountPointType: payload.id,
          pointBarcodeMultiple: {
            ...initValue.pointBarcodeMultiple,
          },
          type: POINT_ACTIVITY.POINT_TYPE.ADDITION.VALUE,
        }
      }
      if (payload.id === POINT_ACTIVITY.POINT_TYPE.BARCODE_MULTIPLE.NAME) {
        return {
          ...state,
          ...initValue,
          discountPointType: payload.id,
          pointBarcodeMultiple: {
            id: payload.giveAwayInitial.value,
            code: payload.giveAwayInitial.code,
            name: payload.giveAwayInitial.text,
          },
          type: POINT_ACTIVITY.POINT_TYPE.BARCODE_MULTIPLE.VALUE,
        }
      }
      if (payload.id === POINT_ACTIVITY.POINT_TYPE.NOPOINT.NAME) {
        return {
          ...state,
          ...initValue,
          discountPointType: payload.id,
          pointBarcodeMultiple: {
            ...initValue.pointBarcodeMultiple,
          },
          type: POINT_ACTIVITY.POINT_TYPE.NOPOINT.VALUE,
        }
      }
      return {
        ...state,
      }
    }

    case 'CHANGE_GIVE_AWAY': {
      return {
        ...state,
        pointBarcodeMultiple: {
          id: payload.id,
          code: payload.code,
          name: payload.name,
        },
      }
    }

    case 'CHANGE_DISCOUNT_POINT_FIELDS': {
      const newValue = payload.value.replace(/\D/g, '')
      if (state.discountPointType === payload.currentType) {
        return {
          ...state,
          [payload.name]: Number(newValue),
        }
      }
      /* falls through */
    }

    case 'UPDATE_EDIT_FIELDS': {
      const {
        barcode,
        name,
        startDate,
        endDate,
        type,
        discountPoint,
        discountAmount,
        additionalPoint,
        pointBarcodeMultiple,
        pointMultipleMaxResult,
        validPeriodForever,
      } = payload

      return {
        ...state,
        barcode,
        name,
        startDateType: 'startDate',
        startDate: startDate === null ? new Date() : new Date(startDate),
        endDateType: validPeriodForever ? 'datePermanen' : 'endDate',
        endDate: validPeriodForever ? null : new Date(endDate),
        type,
        discountPointType: getDiscountPointType(
          type,
          POINT_ACTIVITY.POINT_TYPE
        ),
        discountPoint: getDiscountPointFiledsValue(discountPoint),
        discountAmount: getDiscountPointFiledsValue(discountAmount),
        additionalPoint: getDiscountPointFiledsValue(additionalPoint),
        pointBarcodeMultiple: getPointBarcodeMultiple(pointBarcodeMultiple),
        pointMultipleMaxResult: getDiscountPointFiledsValue(
          pointMultipleMaxResult
        ),
        barcodeMultipleList: generateSelectData(pointBarcodeMultiple),
      }
    }

    default: {
      return state
    }
  }
}

/**
 * @description 產生贈送點數 Select 的 option
 * @param list
 */
function generateSelectData(list) {
  let result = []
  if (list?.length) {
    result = list.map((item, index) => {
      return {
        value: item.id,
        text: item.name,
        code: item.code,
        selected: false,
        dropdownItemoption: {
          eventKey: index,
        },
      }
    })
  }
  return result
}

/**
 * @description 取得 pointBarcodeMultiple 的資料
 * @param pointBarcodeMultiple
 * @returns {{code: string, name: string, id: number}}
 */
function getPointBarcodeMultiple(pointBarcodeMultiple) {
  let data = {
    id: 0,
    code: '',
    name: '',
  }
  const filterSelectedBarcodeMutiple = pointBarcodeMultiple.filter(
    (item) => item.selected
  )
  if (filterSelectedBarcodeMutiple.length) {
    data = {
      id: filterSelectedBarcodeMutiple[0].id,
      code: filterSelectedBarcodeMutiple[0].code,
      name: filterSelectedBarcodeMutiple[0].name,
    }
  }

  return data
}

/**
 * @description 取得 discountPointType 的字串值
 * @param type
 * @param pointType
 * @returns {string}
 */
function getDiscountPointType(type, pointType) {
  let result = ''
  if (type === pointType.DISCOUNT.VALUE) {
    result = pointType.DISCOUNT.NAME
  }
  if (type === pointType.ADDITION.VALUE) {
    result = pointType.ADDITION.NAME
  }
  if (type === pointType.BARCODE_MULTIPLE.VALUE) {
    result = pointType.BARCODE_MULTIPLE.NAME
  }
  if (type === pointType.NOPOINT.VALUE) {
    result = pointType.NOPOINT.NAME
  }
  return result
}

/**
 * @description 轉換折扣點數的值，防止 react 顯示警告
 * @param fieldName
 * @returns {number|*}
 */
function getDiscountPointFiledsValue(fieldName) {
  return fieldName === null ? 0 : fieldName
}
