import { createSelector } from '@reduxjs/toolkit'
import { AUTH } from 'store/constants'

export const authResultSelector = createSelector(
  (state) => state.auth,
  (auth) => {
    const { status, statusDescription } = auth.postAuthRsp.data
    const loginFormError = status === 0 ? '' : statusDescription
    const authResult = {
      isLoggedIn: false,
      loginFormError: '',
      accessToken: '',
    }
    const token = JSON.parse(localStorage.getItem('token'))
    return {
      ...authResult,
      accessToken: auth.postAuthRsp.data.accessToken || token,
      isLoggedIn: !!auth.postAuthRsp.data.accessToken || !!token,
      loginFormError: auth.postAuthRsp.isFetch ? loginFormError : '',
    }
  }
)

export const authAndOrgStatusSelector = createSelector(
  (state) => state[AUTH].getAuthRsp,
  (state) => state[AUTH].getAuthCompanyBrandShopsRsp,
  (state) => state[AUTH].authAccountData,
  (authRsp, shopRsp, authAccountData) => {
    const { shops, shopIndex, prevShopId } = authAccountData
    return {
      hasFetchedAuth: authRsp.isFetch,
      hasFetchedShop: shopRsp.isFetch,
      isSwitchShop: prevShopId !== shops?.[shopIndex]?.id,
    }
  }
)

export const authAccountSelector = createSelector(
  (state) => state[AUTH].authAccountData,
  (state) => state[AUTH].getAuthRsp,
  (authAccountData, authRsp) => {
    const { companies, brands, shops, companyIndex, brandIndex, shopIndex } =
      authAccountData
    return {
      ...authAccountData,
      account: {
        ...authRsp.data?.account,
        isAuth: authRsp.data?.status === 0,
      },
      companyId: companies?.[companyIndex]?.id || '',
      brandId: brands?.[brandIndex]?.id || '',
      shopId: shops?.[shopIndex]?.id || '',
    }
  }
)

export const navbarDataSelector = createSelector(
  (state) => state[AUTH].authAccountData,
  (authAccountData) => {
    const { companyIndex, companies, brandIndex, brands, shopIndex, shops } =
      authAccountData
    // 處理 navbar 的 menu 資料格式
    const handleMenuItems = (list) => {
      const newList = Array.isArray(list) ? list : []
      return newList.map((item, index) => ({
        text: item?.name || '',
        dropdownItemOption: {
          eventKey: index,
        },
      }))
    }
    return [
      {
        type: 'company',
        selectedName: companies[companyIndex]?.name || '',
        selectedIndex: companyIndex,
        menuItems: handleMenuItems(companies),
      },
      {
        type: 'brand',
        selectedName: brands[brandIndex]?.name || '',
        selectedIndex: brandIndex,
        menuItems: handleMenuItems(brands),
      },
      {
        type: 'shop',
        selectedName: shops[shopIndex]?.name || '',
        selectedIndex: shopIndex,
        menuItems: handleMenuItems(shops),
      },
    ]
  }
)
