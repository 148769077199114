import { useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Col, Container, Form, Modal, Row } from 'react-bootstrap'
import iconModalClose from '../../../assets/images/modal/icon_modalClose.svg'
import LocalStyle from './ChangePasswordStyle'
import { StyleInput } from '../../units/Input'
import { StyleButton } from '../../units/Button'
import { patchAuthChpwd } from '../../../api/ApiMain'
import { StyleLoader } from '../../units/Loader'
import { getRandomId, toastErrorContent } from '../../../helpers/common'
import { regexRuleNormal } from '../../../helpers/validation'

function ChangePassword(props) {
  const { handleLogout } = props
  // 表單ref
  const formRef = useRef(null)

  // 記錄api回傳錯誤時的status（為了在不同欄位顯示不同錯誤訊息）
  const fieldsErrorStatusRef = useRef()

  // 表單form是否驗證欄位規則
  const [validated, setValidated] = useState(false)

  const [passwordInputValues, setPasswordInputValues] = useState({})

  // 欄位errors
  const [fieldsError, setFieldsError] = useState({
    other: '', // 按下修改密碼後的驗證錯誤提醒文字(動態由api回傳)
  })
  // 是否修改密碼驗證失敗
  const [isPatchPasswordFailed, setPatchPasswordFailed] = useState(false)

  const [isLoadingPwdModal, setLoadingPwdMoal] = useState(false)

  const { showPwdModal, setShowPwdModal } = props.usePwdModal()

  const handlePwdModalClose = () => setShowPwdModal(false)

  // 表單發送處理
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = formRef.current
    // 判斷form的欄位中，有填寫name值及驗證規則(required、pattern)的項目
    const isValidated = form.checkValidity()
    setValidated(true)
    setPatchPasswordFailed(false)

    if (isValidated) {
      if (
        passwordInputValues?.newPassword &&
        passwordInputValues?.newPassword ===
          passwordInputValues?.confirmPassword
      ) {
        // eslint-disable-next-line no-unused-vars
        const { confirmPassword, ...postData } = passwordInputValues

        setLoadingPwdMoal(true)
        const patchAuthChpwdPromise = patchAuthChpwd(postData)
        patchAuthChpwdPromise
          .then((rsp) => {
            const { status, statusDescription } = rsp
            fieldsErrorStatusRef.current = status
            const errorMessage = statusDescription || ''

            if (status !== 0) {
              setLoadingPwdMoal(false)
              setPatchPasswordFailed(true)
              setFieldsError((prevState) => {
                return {
                  ...prevState,
                  other: errorMessage,
                }
              })
            } else {
              toast.success('密碼修改成功')
              setTimeout(() => {
                setLoadingPwdMoal(false)
                handleLogout()
              }, 1500)
            }
          })
          .catch((rsp) => {
            const { status } = rsp
            setLoadingPwdMoal(false)
            if (status === 400) {
              const toastId = getRandomId()
              toast.error(toastErrorContent(status, rsp?.data?.msg, toastId), {
                id: toastId,
              })
            }
          })
      }
    }
  }
  // 修改密碼的欄位處理
  const onPasswordChangeField = ({ target, ...prop }) => {
    /*
      name、value表示參數是傳入event來做處理
      finalName、finalValue表示參數是傳入欄位name跟value來做處理
      可以擇一選擇參數帶入處理方式  
    */
    const name = target?.name ?? null
    const value = target?.value ?? null
    let { fieldName, fieldValue } = prop
    fieldName = fieldName ?? null
    fieldValue = fieldValue ?? null
    const finalName = fieldName ?? name
    const finalValue = fieldValue ?? value

    if (isPatchPasswordFailed) {
      setPatchPasswordFailed(false)
    }
    setPasswordInputValues((prevInputValues) => {
      return {
        ...prevInputValues,
        [finalName]: finalValue,
      }
    })
  }

  return (
    <LocalStyle show={showPwdModal} onHide={handlePwdModalClose} centered>
      <StyleLoader isHide={!isLoadingPwdModal} />
      <Modal.Header>
        <Modal.Title>修改密碼</Modal.Title>
        <div>
          <img
            src={iconModalClose}
            className="setting-img"
            alt=""
            onClick={handlePwdModalClose}
          />
        </div>
      </Modal.Header>
      <div className="modal-subtitle fz18">確認修改後，需重新進行登入</div>
      <Modal.Body>
        <Form noValidate validated={validated} ref={formRef}>
          <Form.Group>
            <Form.Row className="mb-3">
              <Form.Label>
                <span className="form-label">舊密碼：</span>
              </Form.Label>
              <Col>
                <StyleInput
                  formControlOption={{
                    type: 'password',
                    required: true,
                    name: 'oldPassword',
                    autoComplete: 'new-password',
                    isInvalid:
                      validated &&
                      passwordInputValues?.oldPassword &&
                      fieldsErrorStatusRef.current === 5,
                    onChange: onPasswordChangeField,
                  }}
                  feedbackText={
                    fieldsErrorStatusRef.current === 5
                      ? fieldsError?.other
                      : '尚未填寫'
                  }
                />
              </Col>
            </Form.Row>
            <Form.Row className="mb-3">
              <Form.Label>
                <span className="form-label">新密碼：</span>
              </Form.Label>
              <Col>
                <StyleInput
                  formControlOption={{
                    type: 'password',
                    required: true,
                    pattern: regexRuleNormal.password,
                    name: 'newPassword',
                    autoComplete: 'new-password',
                    onChange: onPasswordChangeField,
                  }}
                  feedbackText="請輸入符合密碼格式的字元"
                  helpertext="請混合使用 10 碼以上的大小寫英文、數字或符號"
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Form.Label>
                <span className="form-label">確認密碼：</span>
              </Form.Label>
              <Col>
                <StyleInput
                  formControlOption={{
                    type: 'password',
                    required: true,
                    name: 'confirmPassword',
                    autoComplete: 'new-password',
                    isInvalid: validated
                      ? passwordInputValues?.newPassword &&
                        passwordInputValues?.newPassword !==
                          passwordInputValues?.confirmPassword
                      : false,
                    onChange: onPasswordChangeField,
                  }}
                  feedbackText={
                    validated
                      ? passwordInputValues?.newPassword &&
                        passwordInputValues?.newPassword !==
                          passwordInputValues?.confirmPassword
                        ? '兩次密碼不相符'
                        : '尚未填寫'
                      : ''
                  }
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Form.Label>
                <span className="form-label" />
              </Form.Label>
              <Col>
                {isPatchPasswordFailed && (
                  <div className="custom-invalid-feedback px-2 pt-1">
                    {fieldsErrorStatusRef.current !== 5 && fieldsError?.other}
                  </div>
                )}
              </Col>
            </Form.Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Container className="m-0">
          <Row className="m-0">
            <Col className="text-right">
              <StyleButton
                className="btn-medium"
                variant="outline-gray"
                onClick={handlePwdModalClose}
              >
                取消
              </StyleButton>
            </Col>
            <Col>
              <StyleButton
                className="btn-medium"
                variant="danger"
                onClick={handleSubmit}
              >
                確認修改
              </StyleButton>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </LocalStyle>
  )
}

export default ChangePassword
