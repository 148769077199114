import PropTypes from 'prop-types'
import { color } from 'assets/styles/Variable/Color'
import { Modal } from 'components/units'

export default function PointActivityHintsModal({
  isShow,
  onClose,
  onConfirm,
}) {
  return (
    <Modal
      show={isShow}
      backdrop="static"
      titleText="即將停用"
      variant={color.danger}
      confirmBtnText="停用"
      closeBtnText="返回"
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <p>停用後將無法復原，</p>
      <p>確定停用？</p>
    </Modal>
  )
}

PointActivityHintsModal.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

PointActivityHintsModal.defaultProps = {
  isShow: false,
  onClose: () => {},
  onConfirm: () => {},
}
