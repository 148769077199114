import { css } from 'styled-components'
import { colorObj, colorCommon } from './Variable/Color'
import { spacingObj } from './Variable/Spacing'

// 處理靜態文字顏色樣式
const handleStaticFontColor = (colorType) => {
  let style = ''
  for (const type in colorType) {
    style += `
      .font-${type}{
          color:${colorType[type]};
      }
    `
  }
  return style
}
// 處理靜態字級樣式
const handleStaticFontTypography = (fontTypography) => {
  let style = ''
  for (const type in fontTypography) {
    style += `
        .${type}-bold{
            font-size: ${fontTypography[type]};
            font-weight: 700;
        }
        .${type}{
            font-size: ${fontTypography[type]};
        }
    `
  }
  return style
}
// 處理靜態margin樣式
const handleStaticMarginTypography = (marginTypography) => {
  let style = ''
  for (const type in marginTypography) {
    const direction = type.substring(7, 8)
    switch (direction) {
      case 't':
        style += `
					.${type}{
						margin-top:${marginTypography[type]};
					}
				`
        break
      case 'r':
        style += `
					.${type}{
						margin-right:${marginTypography[type]};
					}
				`
        break
      case 'b':
        style += `
					.${type}{
							margin-bottom:${marginTypography[type]};
					}
				`
        break
      case 'l':
        style += `
					.${type}{
							margin-left:${marginTypography[type]};
					}
				`
        break
      default:
        break
    }
  }
  return style
}

// 處理靜態margin樣式
const handleStaticPaddingTypography = (paddingTypography) => {
  let style = ''
  for (const type in paddingTypography) {
    const direction = type.substring(8, 9)
    switch (direction) {
      case 't':
        style += `
					.${type}{
						padding-top:${paddingTypography[type]};
					}
				`
        break
      case 'r':
        style += `
					.${type}{
						padding-right:${paddingTypography[type]};
					}
				`
        break
      case 'b':
        style += `
					.${type}{
							padding-bottom:${paddingTypography[type]};
					}
				`
        break
      case 'l':
        style += `
					.${type}{
							padding-left:${paddingTypography[type]};
					}
				`
        break
      default:
        break
    }
  }
  return style
}

/* ------字級大小------*/
// example
// { fz12: "12px" }
const fontTypography = {}
for (const [index] of Array(51).entries()) {
  fontTypography[`fz${index}`] = `${index}px`
}

/* ------margin間距------*/
// example
// { margin-t-20: "20px" }
const marginTypography = {}
for (const [index] of Array(51).entries()) {
  marginTypography[`margin-t-${index}`] = `${index}px`
  marginTypography[`margin-t-${index}-minus`] = `-${index}px`
  marginTypography[`margin-r-${index}`] = `${index}px`
  marginTypography[`margin-r-${index}-minus`] = `-${index}px`
  marginTypography[`margin-b-${index}`] = `${index}px`
  marginTypography[`margin-b-${index}-minus`] = `-${index}px`
  marginTypography[`margin-l-${index}`] = `${index}px`
  marginTypography[`margin-l-${index}-minus`] = `-${index}px`
}

/* ------padding間距------*/
// example
// { padding-t-20: "20px" }
const paddingTypography = {}
for (const [index] of Array(51).entries()) {
  paddingTypography[`padding-t-${index}`] = `${index}px`
  paddingTypography[`padding-t-${index}-minus`] = `-${index}px`
  paddingTypography[`padding-r-${index}`] = `${index}px`
  paddingTypography[`padding-r-${index}-minus`] = `-${index}px`
  paddingTypography[`padding-b-${index}`] = `${index}px`
  paddingTypography[`padding-b-${index}-minus`] = `-${index}px`
  paddingTypography[`padding-l-${index}`] = `${index}px`
  paddingTypography[`padding-l-${index}-minus`] = `-${index}px`
}

// 全域靜態樣式(沒有帶入後端API參數)
const staticStyle = css`
  /* common */

  html {
    position: relative;
    min-height: 100vh;
  }

  body {
    font-family: 'Noto Sans TC', 'Noto Sans TC', sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  hr {
    border-top: 1px solid #d4d4d4;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  .list-title {
    color: ${colorObj.darkerGray};
    font-size: 15px;
  }
  .white-space-nowrap {
    white-space: nowrap;
  }
  .min-width-80 {
    min-width: 80px;
  }

  .custom-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .value-remind {
    color: ${colorObj.gray};
  }
  /* input */
  .style-input-container {
    .input-group-prepend {
      + .form-control {
        border-color: ${colorCommon.borderGray} !important;
      }
    }
  }
  .custom-invalid-feedback {
    color: ${colorObj.danger};
    font-size: 13px;
  }
  .custom-isvalid-feedback {
    color: ${colorObj.success};
    font-size: 13px;
  }
  //字體顏色
  .font-color-green {
    color: ${colorObj.success};
  }
  .font-color-red {
    color: ${colorObj.danger};
  }

  /* bootstrap-grid */

  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .col {
    padding-left: 10px;
    padding-right: 10px;
  }

  form .row,
  .row-inline {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  /* bootstrap-text */

  h1 {
    color: rgba(51, 51, 51, 1);
    font-size: 26px;
    margin-top: 7px;
  }

  h2 {
    color: rgba(51, 51, 51, 1);
    font-size: 24px;
  }

  h3 {
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
  }

  h4 {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
  }

  h5 {
    color: rgba(51, 51, 51, 1);
    font-size: 13px;
  }

  h6 {
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
  }

  .label-title {
    color: rgba(51, 51, 51, 0.66);
    font-size: 15px;
  }

  .label-info {
    color: rgba(51, 51, 51, 0.66);
    font-weight: normal;
    font-size: 15px;
  }

  .paragraph {
    color: rgba(51, 51, 51, 1);
    font-weight: normal;
    font-size: 15px;
    td {
      vertical-align: middle;
    }
  }

  .paragraph-info {
    color: rgba(51, 51, 51, 1);
    font-size: 15px;
  }

  .breadcrumb {
    all: unset;
    color: rgba(51, 51, 51, 0.5);
    font-size: 12px;
    text-align: left;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .tooltip-inner {
    background-color: rgba(51, 51, 51, 0.75);
  }

  .tooltip .arrow {
    display: none;
  }

  /* bootstrap-button */

  .btn-medium {
    width: 120px;
    height: 40px;
  }

  .btn-small {
    width: 84px;
    height: 40px;
  }

  // button - general
  .btn-general,
  .btn-general:focus {
    border-color: rgba(51, 51, 51, 0.66);
    box-shadow: none;
    background-color: #ffffff;
    color: rgba(51, 51, 51, 0.66);
  }

  .btn-general:hover,
  .btn-general:active {
    background-color: rgba(51, 51, 51, 0.05);
  }

  // button - week
  .btn-week,
  .btn-week:focus {
    border-color: rgba(51, 51, 51, 0.25);
    box-shadow: none;
    background-color: #ffffff;
    color: rgba(51, 51, 51, 0.25);
  }

  .btn-week:hover,
  .btn-week:active {
    background-color: rgba(51, 51, 51, 0.05);
    color: rgba(51, 51, 51, 0.5);
  }

  // button - primary-outline
  .btn-primary-outline,
  .btn-primary-outline:focus {
    border-color: ${colorObj.primary};
    box-shadow: none;
    background-color: #ffffff;
    color: ${colorObj.primary};
  }

  .btn-primary-outline:hover,
  .btn-primary-outline:active {
    background-color: rgba(75, 101, 166, 0.05);
  }

  // button - primary
  .btn-primary-cust:hover,
  .btn-primary-cust:active,
  .btn-primary-cust:focus,
  .btn-primary-cust {
    background-color: ${colorObj.primary};
    color: #ffffff;
    box-shadow: none;
  }

  // button - danger
  .btn-danger-cust:hover,
  .btn-danger-cust:active,
  .btn-danger-cust:focus,
  .btn-danger-cust {
    background-color: #dc3c50;
    color: #ffffff;
    box-shadow: none;
  }

  /* bootstrap-table */
  .pagination {
    justify-content: center !important;
    margin: 0px;
  }
  .table-footer {
    background-color: rgba(51, 51, 51, 0.25);
    color: rgba(51, 51, 51, 1);
    font-size: 15px;
    font-weight: normal;
  }
  .page-item.active .page-link,
  .page-link:hover {
    background-color: #ffffff;
    color: ${colorObj.primary};
  }
  .page-link {
    border: 0;
    color: rgba(51, 51, 51, 0.5);
  }
  .react-bs-table-sizePerPage-dropdown {
    .dropdown-toggle {
      &#pageDropDown {
        height: 24px;
        line-height: initial;
        background-color: transparent;
        color: #495057;
        border-color: #ced4da;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 2px;

        &:focus,
        &:active {
          color: #495057;
          background-color: transparent;
          border-color: #4b65a6;
          box-shadow: none;
        }
      }
    }
    .dropdown-menu {
      left: auto;
      right: 0;

      a {
        color: #495057;
        text-decoration: none;
        display: block;
      }
    }
  }

  /* bootstrap-tab */
  .nav-tabs {
    margin-left: 9px;
    margin-bottom: -5px;
  }

  .nav-link {
    color: rgba(51, 51, 51, 0.5);
    font-size: 18px;
    margin-bottom: 0px;

    &.active,
    &:hover {
      color: ${colorObj.primary}!important;
      border-color: transparent;
      background-color: #ffffff;
      box-shadow: 0px 1px 4px 0px rgba(51, 51, 51, 0.25);
    }
    &.active {
      z-index: auto;
      border-radius: 4px;
      box-shadow: 0px 1px 4px 0px rgba(51, 51, 51, 0.25);
    }
    &.has-badge {
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background-color: ${colorObj.danger};
        border-radius: 50%;
        position: absolute;
        right: 7px;
        top: 7px;
      }
    }
  }
  .tab-content {
    position: relative;
    z-index: 1;
    padding: 24px 24px;
    margin-top: -1px;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0px 1px 4px 0px rgb(51 51 51 / 25%);
  }

  /* bootstrap-form */
  .was-validated {
    .form-control {
      &:invalid,
      &:valid {
        background-image: none !important;
        padding-right: ${spacingObj.mini};
      }
      &:valid {
        border-color: ${colorCommon.borderGray};
        padding-right: ${spacingObj.mini};

        &:focus {
          box-shadow: none;
          border-color: ${colorObj.primary};
        }
      }
      &:invalid {
        + .input-group-append {
          > .input-group-text {
            border-color: ${colorObj.danger} !important;
          }
        }
        &:focus {
          box-shadow: none;
        }
      }
      &:focus {
        border-color: ${colorObj.primary};
      }
      &.is-invalid {
        padding-right: ${spacingObj.mini};
      }
    }
    .combine-fields-container {
      .form-control {
        padding-right: ${spacingObj.mini};
      }
      .invalid-feedback {
        display: none;
      }
    }
  }
  .form-control {
    font-size: 15px;
    border-radius: 2px;
    padding-left: 8px;
    padding-right: 8px;

    &.is-valid {
      background-image: none;
      border-color: ${colorObj.success};
      padding-left: 8px;
      padding-right: 8px;

      &:focus {
        box-shadow: none;
        border-color: ${colorObj.success};
      }
    }
    &.is-invalid {
      background-image: none;
      border-color: ${colorObj.danger};

      &:focus {
        box-shadow: none;
        border-color: ${colorObj.danger};
      }
    }
    &:focus {
      border-color: ${colorObj.primary};
      box-shadow: none;
    }
    ~ .valid-feedback {
      display: none !important;
    }
  }

  input[type='text']:disabled,
  input[type='email']:disabled,
  select:disabled,
  textarea:disabled {
    border-radius: 2px;
    border: 1px solid rgba(51, 51, 51, 0.05);
    background-color: #ffffff !important;
  }

  select.form-control.is-invalid {
    background-position: right 1em center;
  }

  /* bootstrap-modal */
  // 將 modal 的 backdrop 設為透明，使用自訂的灰色mask
  .modal-backdrop-custom {
    display: none;
  }
  .modal-header {
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 0;
    border: none;
    border-bottom: 0px;
  }

  .modal-title {
    width: 100%;
    color: rgba(51, 51, 51, 1);
    text-align: center;
  }

  .modal-body {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 0;
  }

  .modal-body table,
  .modal-body .form-group {
    margin-bottom: 0;
  }

  .modal-body table {
    margin-bottom: 0;
  }

  .modal-footer {
    margin-bottom: 40px;
    padding: 0;
    border: none;
    justify-content: center;

    button {
      margin: 0;

      &:first-child {
        margin-right: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .modal-confirm {
    max-width: 360px;
  }

  .modal-confirm-body {
    text-align: center;
    font-size: 18px;
  }

  /* react-datepicker */
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }
  .react-datepicker__month-container {
    .disabled-date {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  /* react-hot-toast */
  .toast-error-dismiss {
    color: ${colorObj.gray};
    cursor: pointer;
  }

  /* otehrs 未整理*/
  .no-padding {
    padding: 0;
  }

  .h-32 {
    height: 32px !important;
    line-height: 32px !important;
  }

  .migo-blue {
    color: ${colorObj.primary};
  }

  .cursor-pointer,
  .setting-img {
    &:hover,
    &::before,
    &::after {
      cursor: pointer;
    }
  }

  //載入多個文字顏色樣式
  ${handleStaticFontColor(colorObj)}
  //載入多個字級樣式
  ${handleStaticFontTypography(fontTypography)}
  //載入多個margin樣式
  ${handleStaticMarginTypography(marginTypography)}
	//載入多個padding樣式
	${handleStaticPaddingTypography(paddingTypography)}
`

export { staticStyle }
