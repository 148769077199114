import PropTypes from 'prop-types'
import { useContext, useState, useEffect, useCallback } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { PaginationListStandalone } from 'react-bootstrap-table2-paginator'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getGiftList, changeStatus } from 'api/ApiMain'
import { colorObj, color, hexToRgbA } from 'assets/styles/Variable/Color'
import { Wrapper } from 'components/pages/Gift/ExchangeMainStyle'
import {
  Caption,
  formatterType,
  formatterInfo,
  formatterObject,
  formatterDate,
  formatterAmount,
  formatterWay,
  formatterSetting,
} from 'components/pages/Gift/ExchangeMainSubComponents'
import { Table, NoDataIndication, Modal } from 'components/units'
import { COMPONENT_PERMISSIONS } from 'constant'
import { ExchangeContext } from 'context/pageContext'
import { tabStatus, tabConfig } from 'pages/Gift/config'
import { RootContext } from 'RootContext'
import { updatePageLoading } from 'store/global/globalSlice'
import { isPermissionAllowed } from 'store/permission/permissionSlice'

const rowStyle = (tabType) => (row) => {
  if (tabType !== tabStatus.unavailable) return {}
  const isGiftCodeNotEnough = row?.giftAmount?.isGiftCodeNotEnough
  const style = {}
  if (isGiftCodeNotEnough)
    style.backgroundColor = hexToRgbA(colorObj.danger, 0.2)
  return style
}

const columns = ({
  type,
  brandId,
  history,
  apiPayload,
  updateDone,
  openModal,
  isHidden,
}) => {
  return [
    // 為使 hidden column 正常作用，需加入一個空的 column（疑似套件bug）
    { dataField: '', text: '', hidden: true },
    { dataField: 'no', text: 'No.', sort: true },
    {
      dataField: 'exchangeAndReceive',
      text: '兌換&領取',
      sort: true,
      formatter: formatterType,
    },
    {
      dataField: 'giftInfo',
      text: '贈品資訊',
      sort: true,
      formatter: formatterInfo,
    },
    {
      dataField: 'object',
      text: '對象',
      sort: true,
      formatter: formatterObject,
    },
    {
      dataField: 'exchangeValidPeriod',
      text: '兌換效期',
      sort: true,
      formatter: formatterDate,
    },
    {
      dataField: 'giftAmount',
      text: '數量',
      sort: true,
      formatter: formatterAmount,
      headerAlign: 'right',
      align: 'right',
    },
    {
      dataField: 'exchangeCondition',
      text: '兌換方式',
      formatter: formatterWay,
      headerAlign: 'right',
      align: 'right',
    },
    {
      dataField: 'funcButton',
      text: '',
      formatter: formatterSetting,
      formatExtraData: {
        type,
        brandId,
        history,
        apiPayload,
        updateDone,
        openModal,
      },
      hidden: isHidden,
    },
  ]
}
const initModalData = {
  status: false,
  payload: { brandId: null, giftSettingId: null },
}

const parserRows = (rows) => {
  const result = rows.map((i) => ({
    ...i,
    exchangeValidPeriod: [
      i?.validPeriod?.exchangeStartDate,
      i?.validPeriod?.exchangeEndDate,
    ],
  }))

  return result
}

function ExchangeList({ paginationProps, paginationTableProps }) {
  const {
    apiPayload,
    setApiPayload,
    setTotalSize,
    defaultSort,
    event,
    setEvent,
    setAddBtdDisabled,
  } = useContext(ExchangeContext)
  const dispatch = useDispatch()
  const rootData = useContext(RootContext)
  const { brandId } = rootData
  const history = useHistory()

  const [modal, setModal] = useState(initModalData)
  const [rows, setRows] = useState([])
  const [research, setResearch] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const isOnUnavailableTab = apiPayload.state === tabStatus.unavailable
  const isHiddenFunctBtnCol = !useSelector(
    isPermissionAllowed([
      ...COMPONENT_PERMISSIONS.gift.UPDATE,
      ...COMPONENT_PERMISSIONS.gift.DELETE,
    ])
  )

  const openModal = (payload) => setModal({ status: true, payload })
  const closeModal = (handleModalLoading) => {
    setModal(initModalData)
    handleModalLoading(false)
  }

  const onConfirm = async (payload, { handleModalLoading }) => {
    handleModalLoading(true)
    const { giftSettingId } = payload
    try {
      await changeStatus(brandId, giftSettingId, { act: 'seal' })
      setResearch(true)
    } catch (err) {
      console.error(err)
    } finally {
      closeModal(handleModalLoading)
    }
  }

  const onTableChange = (type, value) => {
    if (type === 'sort') {
      setApiPayload((prev) => ({
        ...prev,
        sf: value.sortField,
        so: value.sortOrder,
      }))
    }
  }

  const handleTabChange = (value) =>
    setApiPayload(() => ({
      state: value,
      ps: 25,
      p: 1,
      ...defaultSort,
    }))

  const handleGetGiftList = useCallback(async () => {
    setIsLoading(true)
    setRows([])
    setTotalSize(0)
    try {
      const res = await getGiftList(brandId, apiPayload)
      setRows(parserRows(res?.data?.data?.rows) || [])
      setTotalSize(res?.data?.data?.totalSize)
      setAddBtdDisabled(res?.data?.data?.newGiftSettingBtn?.disabled || false)
      setEvent(() => ({
        usingMaacActivity: res?.data?.data?.feature?.usingMaacActivity || false,
        usingOfflineShop: res?.data?.data?.feature?.usingOfflineShop || false,
        usingLineLiff: res?.data?.data?.feature?.usingLineLiff || false,
        usingSendBySms: res?.data?.data?.feature?.usingSendBySms || false,
        usingDelivery: res?.data?.data?.feature?.usingDelivery || false,
      }))
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
      dispatch(updatePageLoading(false))
    }
  }, [setTotalSize, brandId, apiPayload, setAddBtdDisabled, setEvent, dispatch])

  useEffect(() => {
    if (brandId) {
      handleGetGiftList()
    }
  }, [handleGetGiftList, brandId])

  useEffect(() => {
    if (research) {
      handleGetGiftList()
      setResearch(false)
    }
  }, [research, handleGetGiftList])

  return (
    <Wrapper>
      <Tabs
        defaultActiveKey={apiPayload.state}
        onSelect={handleTabChange}
        activeKey={apiPayload.state}
      >
        {tabConfig.map((tab) => (
          <Tab eventKey={tab.eventKey} title={tab.title} key={tab.eventKey}>
            <Caption
              paginationProps={paginationProps}
              apiPayload={apiPayload}
              setApiPayload={setApiPayload}
            />
            <Table
              headerClasses="table-header"
              bodyClasses="paragraph"
              className="overflow-auto"
              keyField="no"
              data={rows}
              rowStyle={rowStyle(apiPayload.state)}
              columns={columns({
                type: apiPayload.state,
                brandId,
                history,
                apiPayload: { ...apiPayload, event },
                updateDone: () => setResearch(true),
                openModal,
                isHidden: isOnUnavailableTab && isHiddenFunctBtnCol,
              })}
              sort={{ dataField: apiPayload.sf, order: apiPayload.so }}
              striped
              remote
              onTableChange={onTableChange}
              noDataIndication={
                <NoDataIndication
                  isLoading={isLoading}
                  message={
                    isLoading
                      ? '載入中...'
                      : '查無資料，請調整搜尋內容後重新查詢'
                  }
                />
              }
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...paginationTableProps}
            />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <PaginationListStandalone {...paginationProps} />
          </Tab>
        ))}
      </Tabs>
      <Modal
        show={modal.status}
        onClose={closeModal}
        onConfirm={(e) => onConfirm(modal.payload, e)}
        variant={color.danger}
        titleText="即將封存"
        confirmBtnText="封存"
        closeBtnText="返回"
      >
        <p>是否繼續執行封存？</p>
        <p>封存後，該項目將無法編輯；</p>
        <p>未兌換之序號，可重複新增。</p>
      </Modal>
    </Wrapper>
  )
}

export default ExchangeList

ExchangeList.propTypes = {
  paginationProps: PropTypes.shape({
    totalSize: PropTypes.number,
    page: PropTypes.number,
    sizePerPage: PropTypes.number,
    onSizePerPageChange: PropTypes.func,
    onPageChange: PropTypes.func,
  }),

  paginationTableProps: PropTypes.shape({
    onTableChange: PropTypes.func,
  }),
}

ExchangeList.defaultProps = {
  paginationProps: {
    totalSize: 0,
    page: 1,
    sizePerPage: 25,
    onSizePerPageChange: () => {},
    onPageChange: () => {},
  },
  paginationTableProps: {
    onTableChange: () => {},
  },
}
