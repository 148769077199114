import { useContext, useEffect } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import startOfToday from 'date-fns/startOfToday'
import {
  Col,
  Calendar,
  Row,
  Tooltip,
  Input,
  Checkbox,
  Radio,
} from 'components/units'
import {
  FormWrapper,
  CalendarWrapper,
  ComplexListWrapper,
  CheckListMainWrapper,
  CheckListSubWrapper,
  InValidFeedback,
  LvZeroEl,
} from 'components/templates'
import { SettingContext } from 'pages/ActivitiesCode/ProductActivityManagementEntry'
import {
  formConfig,
  optionConfig,
  stateUpdate,
  parserPayload,
} from 'components/pages/ActivitiesCode/ProductActivity/Sub/ProductActivityManagementConfig'
import { settingPageType } from 'pages/ActivitiesCode/ProductActivity'
import iconTooltip from 'assets/images/icon_tooltip.svg'
import { StyleSelect } from 'components/units/Select'
import LocalStyle from './ProductActivityManagementStyle'

// select 的option 配置 (內容欄位)
const handleSelectOption = (array) => {
  const newArrData = []
  if (Array.isArray(array)) {
    for (const [index, item] of array.entries()) {
      const { name } = item
      newArrData.push({
        text: name,
        value: index + 1,
        dropdownItemOption: {
          eventKey: index,
        },
      })
    }
  }
  return newArrData
}

function ProductActivityManagement() {
  const {
    defaultData,
    viewType,
    infoState: [state, dispatch],
    infoValid: [validStatus],
  } = useContext(SettingContext)

  const isNew = viewType === settingPageType.new
  const isEdit = viewType !== settingPageType.view

  const handleChange = stateUpdate(dispatch)

  // 欄位onChange處理
  const onChangeField = ({ target, ...prop }) => {
    if (target?.type.includes('select')) {
      const { eventKey } = prop

      dispatch({
        type: 'pointProductMultiple',
        payload: handleSelectOption(defaultData?.pointProductMultiple)[eventKey]
          .value,
      })
    }
  }

  useEffect(() => {
    if (defaultData) {
      const payload = isNew
        ? {
            target: {
              forNewMember: '',
              forInitialMember: false,
              memberLevels: defaultData.memberLevelItemList.map((i) => ({
                ...i,
                selected: false,
              })),
              pointProductMultiple: defaultData.pointProductMultiple,
            },
            memberLevelItemList: defaultData.memberLevelItemList,
          }
        : parserPayload(defaultData)
      dispatch({ type: 'init', payload })
    }
  }, [defaultData, isEdit, dispatch, isNew])

  return (
    <LocalStyle>
      <FormWrapper>
        <Form>
          <Form.Row>
            <Col lg="6">
              <Form.Group>
                <Form.Label>{formConfig.name.label}</Form.Label>
                <InputGroup>
                  <Input
                    className="form-row-input-fill"
                    formControlOption={{
                      placeholder: formConfig.name.placeholder,
                      value: state.name,
                      disabled: !isEdit,
                      onChange: handleChange('name'),
                      maxLength: formConfig.name.maxLength,
                      required: validStatus.name.isValid === false,
                    }}
                  />
                  <InValidFeedback show={validStatus.name.isValid}>
                    {validStatus.name.msg}
                  </InValidFeedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col lg="6" />
            <Col lg="6">
              <Form.Group className="form-group-top" as={Col}>
                <Form.Label>{formConfig.startDate.label}</Form.Label>
                <ComplexListWrapper>
                  <Radio
                    label={optionConfig.startDate[0]}
                    id="immediately"
                    checked={state.startDate.check}
                    disabled={!isEdit}
                    onChange={handleChange('startDate_Checkbox')}
                  />
                  <CalendarWrapper>
                    <Radio
                      label={optionConfig.startDate[1]}
                      id="startDate"
                      checked={
                        state.startDate.check !== '' && !state.startDate.check
                      }
                      disabled={!isEdit}
                      onChange={handleChange('startDate_Checkbox')}
                    />
                    <Calendar
                      value={state.startDate.calendar}
                      disabled={!isEdit}
                      onChange={handleChange('startDate')}
                      minDate={startOfToday()}
                      isValid={validStatus.startDate.isValid}
                    />
                    <InValidFeedback show={validStatus.startDate.isValid}>
                      {validStatus.startDate.msg}
                    </InValidFeedback>
                  </CalendarWrapper>
                </ComplexListWrapper>
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group className="form-group-top" as={Col}>
                <Form.Label>{formConfig.endDate.label}</Form.Label>
                <ComplexListWrapper>
                  <Radio
                    label={optionConfig.endDate[0]}
                    id="endDatePermanent"
                    checked={state.endDate.check}
                    disabled={!isEdit}
                    onChange={handleChange('endDate_Checkbox')}
                  />
                  <CalendarWrapper>
                    <Radio
                      label={optionConfig.endDate[1]}
                      id="endDate"
                      checked={
                        state.endDate.check !== '' && !state.endDate.check
                      }
                      disabled={!isEdit}
                      onChange={handleChange('endDate_Checkbox')}
                    />
                    <Calendar
                      value={state.endDate.calendar}
                      disabled={!isEdit}
                      onChange={handleChange('endDate')}
                      minDate={startOfToday()}
                      isValid={validStatus.endDate.isValid}
                    />
                  </CalendarWrapper>
                  <InValidFeedback show={validStatus.endDate.isValid}>
                    {validStatus.endDate.msg}
                  </InValidFeedback>
                </ComplexListWrapper>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-group-top" as={Col}>
              <Form.Label>{formConfig.target.label}</Form.Label>
              <Col lg="12">
                <Row>
                  <CheckListMainWrapper className="col-sty">
                    <Radio
                      label={optionConfig.target[0]}
                      id="newMember"
                      checked={state.target.forNewMember}
                      disabled={!isEdit}
                      onChange={handleChange('forNewMember')}
                    />
                    <Radio
                      label={optionConfig.target[1]}
                      id="specified"
                      checked={
                        state.target.forNewMember !== '' &&
                        !state.target.forNewMember
                      }
                      disabled={!isEdit}
                      onChange={handleChange('forNewMember')}
                    />
                  </CheckListMainWrapper>
                  <CheckListSubWrapper>
                    {/* 要選擇指定等級才會顯示會員等級核取選項 */}
                    {state.target.forNewMember === false ? (
                      <>
                        <Checkbox
                          label={formConfig.targetAll.label}
                          id="all"
                          checked={
                            state.target.memberLevels.filter((i) => i.selected)
                              .length === state.memberLevelItemList.length &&
                            state.target.forInitialMember
                          }
                          disabled={!isEdit}
                          onChange={handleChange('memberLevelsAll')}
                        />
                        <LvZeroEl>
                          <Checkbox
                            label={formConfig.targetZero.label}
                            id="lv0"
                            checked={state.target.forInitialMember}
                            disabled={!isEdit}
                            onChange={handleChange('lv0')}
                          />
                          <Tooltip
                            triggerElement={
                              <img src={iconTooltip} alt="icon-tooltip" />
                            }
                            globalOption={{
                              effect: 'solid',
                            }}
                          >
                            {formConfig.targetZero.tip}
                          </Tooltip>
                        </LvZeroEl>
                        {state.memberLevelItemList.map((item) => {
                          return (
                            <Checkbox
                              key={`member-level-item-${item.id}`}
                              label={item.name}
                              id={item.id}
                              checked={state.target.memberLevels.some(
                                (level) =>
                                  level.id === item.id && level.selected
                              )}
                              disabled={!isEdit}
                              onChange={handleChange('memberLevels')}
                            />
                          )
                        })}
                      </>
                    ) : null}
                  </CheckListSubWrapper>
                </Row>
                <InValidFeedback show={validStatus.target.isValid}>
                  {validStatus.target.msg}
                </InValidFeedback>
              </Col>
            </Form.Group>
          </Form.Row>
          <Form.Row className="last-form-row-sty">
            <Col lg="6">
              <Form.Group className="form-group-top" as={Col}>
                <Form.Label>{formConfig.content.label}</Form.Label>
                <ComplexListWrapper>
                  <div className="input-wrap">
                    <Radio
                      label={optionConfig.content[0]}
                      id="productPoint"
                      checked={state.content.usingPointMultiple.check}
                      disabled={!isEdit}
                      onChange={handleChange('content_Checkbox')}
                    />
                    <StyleSelect
                      optionItems={handleSelectOption(
                        defaultData?.pointProductMultiple
                      )}
                      className="input-sty-in-input-wrap"
                      selectedValue={state.content.usingPointMultiple.value}
                      dropdownToggleOption={{
                        className: 'w-100',
                        disabled: !isEdit,
                      }}
                      // 這邊的onChange並非對應到formControl的onChange，因此才需要另外設計成以下格式
                      onChange={(event, value, formControlRef, eventKey) => {
                        onChangeField({
                          target: formControlRef,
                          optionItems: state.content.usingPointMultiple.value,
                          eventKey,
                        })
                      }}
                    />
                  </div>
                  <div className="input-wrap">
                    <Radio
                      label={optionConfig.content[1]}
                      id="additionalPoint"
                      checked={state.content.usingAdditionalPoint.check}
                      disabled={!isEdit}
                      onChange={handleChange('content_Checkbox')}
                    />
                    <Input
                      className="input-sty-in-input-wrap"
                      appendContent="點"
                      appendContentBgColor="#fff"
                      appendContentPadding="0 4px 0 0"
                      appendContentHaveBorder={false}
                      isFocusCocatAppend
                      formControlOption={{
                        value: state.content?.usingAdditionalPoint?.value ?? '',
                        disabled: !isEdit,
                        onChange: handleChange('usingAdditionalPoint'),
                        maxLength: formConfig.usingAdditionalPoint.maxLength,
                      }}
                    />
                  </div>
                  {state.content.usingAdditionalPoint.check ? (
                    <div className="sub-input-wrap">
                      <Checkbox
                        label={formConfig.notSplit.label}
                        id="notSplit"
                        checked={state.content.usingNotSplit}
                        disabled={!isEdit}
                        onChange={handleChange('notSplit')}
                      />
                    </div>
                  ) : null}
                  <div className="input-wrap">
                    <Radio
                      label={optionConfig.content[2]}
                      id="noPoint"
                      checked={state.content.isNoGivingPoint}
                      disabled={!isEdit}
                      onChange={handleChange('content_Checkbox')}
                    />
                  </div>
                  <InValidFeedback show={validStatus.content.isValid}>
                    {validStatus.content.msg}
                  </InValidFeedback>
                </ComplexListWrapper>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </FormWrapper>
    </LocalStyle>
  )
}

export default ProductActivityManagement
