/* eslint-disable import/prefer-default-export */
import { showToast } from 'api/ApiCommon'

const handleCatch = (error) => {
  const { status } = error
  if (status === 400) {
    showToast(error)
  }
}
export { handleCatch }
