import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import { getVipGiftList } from 'api/ApiMain'
import { columnType } from 'components/pages/Gift/ExclusiveMainConfig'
import { VIP_GIFT_EXCHANGE_LIST } from 'store/constants'

export const vipGiftExchangeListInitState = {
  vipGiftConfig: {
    pageType: null,
  },
  getVipGiftListRsp: {
    isFetch: false,
    code: 0,
    msg: '',
    errors: {},
    data: {
      sort: {
        field: '',
        order: '',
        availableFields: [''],
      },
      totalSize: 0,
      rows: [],
      feature: {
        usingVipGiftLinkedVipGift: true,
      },
      newVipGiftSettingBtn: {
        disabled: true,
      },
    },
  },
}

const getVipExchangeListThunk = createAsyncThunk(
  `${VIP_GIFT_EXCHANGE_LIST}/getVipExchangeList`,
  async ({ brandId, apiPayload }, { rejectWithValue }) => {
    try {
      const rsp = await getVipGiftList(brandId, apiPayload)
      return rsp?.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const vipGiftExchangeListSlice = createSlice({
  name: VIP_GIFT_EXCHANGE_LIST,
  initialState: vipGiftExchangeListInitState,
  reducers: {
    resetVipGiftExchangeList: () => vipGiftExchangeListInitState,
    updateVipGiftConfig: (state, action) => {
      state.vipGiftConfig = { ...state.vipGiftConfig, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getVipExchangeListThunk.fulfilled,
        getVipExchangeListThunk.rejected
      ),
      (state, action) => {
        state.getVipGiftListRsp = action.payload
        state.getVipGiftListRsp.isFetch = true
      }
    )
  },
})

const vipGiftListSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE_LIST].getVipGiftListRsp,
  (getVipGiftListRsp) => {
    const { data } = getVipGiftListRsp
    return {
      rows: data?.rows.map((i) => {
        return {
          [columnType.no]: i?.no,
          [columnType.receive]: i?.receiveWay,
          [columnType.type]: i?.giftType,
          [columnType.name]: i?.name,
          [columnType.target]: i?.object,
          [columnType.content]: i?.content,
          [columnType.dateRange]: [
            i?.validPeriod?.exchangeStartDate,
            i?.validPeriod?.exchangeEndDate,
          ],
          [columnType.amount]: [
            i?.quantity?.receivedCount ?? 0,
            i?.quantity?.sentCount ?? 0,
            i?.quantity?.errorStatus,
            i?.quantity?.errorLinkedVipGiftSettingIds[0] ?? null,
          ],
          [columnType.fn]: Object.entries(i?.funcButton ?? []).map(
            ([key, value]) => [key, value?.visible ?? false]
          ),
          vipGiftSettingId: i?.vipGiftSettingId,
          linkInfo: [i?.isLinked, i?.mainVipGiftBrandSerialNo],
        }
      }),
      totalSize: data?.totalSize ?? 0,
      addBtnDisabled: data?.newVipGiftSettingBtn?.disabled ?? true,
      usingLinkGift: data?.feature?.usingVipGiftLinkedVipGift ?? false,
      usingBindingGift: data?.feature?.usingVipGiftBinding ?? false,
    }
  }
)

const vipGiftConfigSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE_LIST].vipGiftConfig,
  (vipGiftConfig) => vipGiftConfig
)

export const { resetVipGiftExchangeList, updateVipGiftConfig } =
  vipGiftExchangeListSlice.actions
export { getVipExchangeListThunk, vipGiftListSelector, vipGiftConfigSelector }
export default vipGiftExchangeListSlice.reducer
