import styled, { css } from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { getRandomId } from 'helpers/common'
import { colorObj } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

// Tooltip 共用樣式
const tooltipCommonStyles = (props) => {
  return css`
    &.style-tooltip-container {
      display: inline-block;

      [data-for] {
        cursor: pointer;
        display: flex;
      }
      [data-id='tooltip'] {
        opacity: 1;
        font-weight: 400;

        &[class*='place-'] {
          background-color: ${(props?.specificOption?.[
            'data-background-color'
          ] ||
            props?.globalOption?.backgroundColor) ??
          colorObj.darkerGray};
          margin-top: 0;
          padding: ${spacingObj.micro} ${spacingObj.mini};
          height: auto;
          line-height: 22px;

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  `
}

// Tooltip 基礎樣式
const tooltipBaseStyles = (props) => {
  return css`
    ${tooltipCommonStyles(props)};
  `
}

const StyleTooltip = styled((props) => {
  let {
    className,
    id,
    triggerElement,
    specificOption,
    globalOption,
    children,
  } = props
  // 若id未填寫，則隨機產生英數字組合
  id = id ?? getRandomId()

  return (
    <div className={`style-tooltip-container ${className}`}>
      <span data-tip data-for={id} {...specificOption}>
        {triggerElement}
      </span>
      {children && (
        <ReactTooltip id={id} effect="solid" {...globalOption}>
          {children}
        </ReactTooltip>
      )}
    </div>
  )
})`
  ${(props) => tooltipBaseStyles(props)};
`

export { StyleTooltip }
