import styled from 'styled-components'
import bgLogin from 'assets/images/img_bg_login.png'
import { colorObj } from 'assets/styles/Variable/Color'

const LocalStyle = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: url(${bgLogin}) no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Noto Sans TC', sans-serif;
  .main {
    flex: 1;
    overflow-y: auto;
  }
  .welcome-section {
    padding-top: 100px;
    text-align: center;
    font-size: 24px;
    color: ${colorObj.secondary};
  }
  .litloyal-section {
    padding-top: 3px;
    font-family: 'NotoSerifTC-Black', 'Noto Serif TC Black', 'Noto Serif TC',
      sans-serif;
    font-size: 40px;
    color: ${colorObj.primary};
    text-align: center;
  }
  .subtitle-section {
    padding-top: 16px;
    font-size: 14px;
    color: ${colorObj.secondary};
    text-align: center;
    letter-spacing: 2px;
  }
  .line-section {
    width: 270px;
    color: ${colorObj.dark};
    margin: auto;
  }
  .form-title {
    font-size: 26px;
    font-weight: 500;
    color: ${colorObj.primary};
    text-align: center;
  }
  .form-subTitle {
    margin: 26px 0px;
    font-size: 18px;
    color: ${colorObj.dark};
    text-align: center;
  }
  form {
    width: 270px;
    margin: 26px auto 0;
    .login-btn {
      height: 40px;
      margin-top: 40px;
      border-radius: 4px;
      border-color: ${colorObj.primary};
      background-color: ${colorObj.primary};
      box-sizing: border-box;
      font-size: 14px;
      color: ${colorObj.white};
    }
    .password-forget-btn {
      background-color: transparent;
      display: flex;
      margin: 24px auto;
      padding: 0;
      border: 0;
      color: ${colorObj.primary};
      text-decoration: underline;
      text-underline-offset: 2px;
      font-size: 15px;
      height: auto;
      line-height: 1;
      min-width: fit-content;
      &:hover {
        background-color: transparent;
        border: 0;
        box-shadow: none;
      }
    }
  }
  footer {
    height: 80px;
    padding-top: 24px;
    padding-bottom: 24px;
    background-image: linear-gradient(
      -180deg,
      rgba(250, 250, 250, 0) 0%,
      rgba(161, 205, 238, 0.25) 100%
    );
    box-sizing: border-box;
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 10px;
    font-weight: normal;
    color: #4b65a6;
    text-align: center;
    width: 100%;

    a {
      color: ${colorObj.primary};
      text-decoration: underline;
      text-underline-offset: 0.1rem;
    }
  }
`

export default LocalStyle
