import styled from 'styled-components'
import bgLogin from 'assets/images/img_bg_login.png'
import { colorObj } from 'assets/styles/Variable/Color'

const LocalStyle = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${bgLogin}) no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Noto Sans TC', sans-serif;
  text-align: center;
  .welcome-section {
    padding-top: 100px;
    font-size: 24px;
    color: ${colorObj.secondary};
  }
  .litloyal-section {
    padding-top: 3px;
    font-family: 'NotoSerifTC-Black', 'Noto Serif TC Black', 'Noto Serif TC',
      sans-serif;
    font-size: 40px;
    color: ${colorObj.primary};
  }
  .subtitle-section {
    padding-top: 16px;
    font-size: 14px;
    color: ${colorObj.secondary};
    letter-spacing: 2px;
  }
  .line-section {
    width: 270px;
    color: ${colorObj.dark};
    margin: auto;
  }
  .login-title {
    font-size: 26px;
    font-weight: 500;
    color: ${colorObj.primary};
  }
  .verify-failed-title {
    font-size: 26px;
    font-weight: 500;
    color: ${colorObj.danger};
  }
  .verify-failed-description {
    font-size: 13px;
    color: ${colorObj.dark};
    margin-top: 26px;
  }
  .link-expired-btn {
    width: 270px;
    margin: 0 auto;
    height: 40px;
    margin-top: 40px;
    border-radius: 4px;
    border-color: ${colorObj.primary};
    background-color: ${colorObj.primary};
    box-sizing: border-box;
    font-size: 14px;
    color: ${colorObj.white};
  }
  footer {
    height: 80px;
    padding-top: 24px;
    padding-bottom: 40px;
    background-image: linear-gradient(
      -180deg,
      rgba(250, 250, 250, 0) 0%,
      rgba(161, 205, 238, 0.25) 100%
    );
    box-sizing: border-box;
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 10px;
    font-weight: normal;
    color: #4b65a6;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`

export default LocalStyle
