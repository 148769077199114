/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useEffect, useState, useCallback } from 'react'
import { Col, Row } from 'react-bootstrap'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import { useSelector, useDispatch } from 'react-redux'
import { showToast } from 'api/ApiCommon'
import {
  patchGainPointRuleStop,
  getPointGainPointRuleOnce,
  deleteGainPointRule,
} from 'api/ApiMain'
import withPermission from 'components/hocs/withPermission'
import { tableColumns } from 'components/pages/PointSetting/Container/GivePointSetting/GivePointSettingSubComponent'
import { handleCatch } from 'components/pages/PointSetting/PointSettingHelper'
import PointSettingModal from 'components/pages/PointSetting/Widget/PointSettingModal'
import StopWarningModal from 'components/pages/PointSetting/Widget/StopWarningModal'
import { Card, Button, Table, NoDataIndication } from 'components/units'
import { COMPONENT_PERMISSIONS } from 'constant'
import { authAccountSelector } from 'store/auth/authSelector'
import { getGlobalState, updateListLoading } from 'store/global/globalSlice'
import { isPermissionAllowed } from 'store/permission/permissionSlice'
import {
  getGainPointRulesThunk,
  pointSettingRspSelector,
  pointGainRulesSelector,
  statusSelector,
} from 'store/point-setting/pointSettingSlice'

function GivePointSetting() {
  const dispatch = useDispatch()
  const { isBaseSettingEdit } = useSelector(statusSelector)
  const { gainPtByConsumption, modalData } = useSelector(
    pointSettingRspSelector
  )
  const { rows, isRowNotEmpty, totalSize } = useSelector(pointGainRulesSelector)
  const { brandId } = useSelector(authAccountSelector)
  const { isListLoading } = useSelector(getGlobalState)
  const { CREATE, DELETE, UPDATE } = COMPONENT_PERMISSIONS.point
  const isHidden = !useSelector(
    isPermissionAllowed([...CREATE, ...DELETE, ...UPDATE])
  )
  const PermissionAddButton = withPermission(Button, CREATE)
  // 贈點設定，按鈕區塊，停用按鈕按下後，控制是否彈出modal提醒視窗
  const [isShowStopWarning, setShowStopWarning] = useState(false)
  // 贈點設定，按鈕區塊，停用按鈕按下後，儲存項目 ID
  const [selectedRowID, setSelectedRowID] = useState(null)
  // 贈點設定，表格狀態(目前第幾頁、每頁顯示多少筆、目前排序的欄位名稱、目前是升冪還是降冪)
  const [pointSettingTableState, setPointSettingTableState] = useState({
    page: 1,
    perPageSize: 25,
    sortFieldName: 'no',
    sortOrder: 'desc',
  })
  // 贈點設定的欄位值
  const [pointSettingModalInputValues, setPointSettingModalInputValues] =
    useState({})
  // 贈點設定，新增贈點modal顯示/隱藏
  const [isShowModalPointSetting, setShowModalPointSetting] = useState(false)
  // 贈點設定，新增贈點modal是否loading
  const [isLoadingModalPointSetting, setLoadingModalPointSetting] =
    useState(false)
  // 贈點設定，判斷modal打開時是要新增贈點還是編輯贈點
  const isEditModalPointSetting = useRef(false)
  // 贈點設定，編輯時所需的gainPointRuleId
  const pointSettingGainPointRuleId = useRef()

  // 新增贈點modal顯示視窗
  const handlePointSettingModalShow = (isShow) => {
    setShowModalPointSetting(isShow)
  }

  // 贈點設定的表格資料從api獲取後的處理
  const handlePointSettingTableData = useCallback(
    async (tableState) => {
      const newTableState = tableState ?? pointSettingTableState
      try {
        const tableSetting = {
          p: newTableState.page,
          ps: newTableState.perPageSize,
          sf: newTableState.sortFieldName,
          so: newTableState.sortOrder,
        }
        dispatch(updateListLoading(true))
        await dispatch(getGainPointRulesThunk({ brandId, tableSetting }))
        setPointSettingTableState(newTableState)
      } catch (error) {
        handleCatch(error)
      } finally {
        dispatch(updateListLoading(false))
      }
    },
    [pointSettingTableState, brandId, dispatch]
  )
  // 贈點設定的頁碼設定
  const paginationOptions = {
    custom: true,
    totalSize,
    sizePerPageList: [
      { text: '25筆', value: 25 },
      { text: '50筆', value: 50 },
      { text: '100筆', value: 100 },
    ],
    onSizePerPageChange: (perPageSize) => {
      handlePointSettingTableData({
        ...pointSettingTableState,
        perPageSize,
      })
    },
    onPageChange: (nowPage) => {
      handlePointSettingTableData({
        ...pointSettingTableState,
        page: nowPage,
      })
    },
  }

  // 贈點設定的編輯功能
  const handlePointSettingEdit = async (gainPointRuleId) => {
    if (gainPointRuleId) {
      isEditModalPointSetting.current = true
      handlePointSettingModalShow(true)
      setLoadingModalPointSetting(true)
      pointSettingGainPointRuleId.current = gainPointRuleId

      try {
        const getPointGainPointRuleOnceData = await getPointGainPointRuleOnce(
          brandId,
          gainPointRuleId
        )
        setPointSettingModalInputValues(getPointGainPointRuleOnceData || {})
      } catch (error) {
        handleCatch(error)
      } finally {
        dispatch(updateListLoading(false))
        setLoadingModalPointSetting(false)
      }
    }
  }
  // 贈點設定的刪除功能
  const handlePointSettingRemove = async (
    gainPointRuleId,
    pointSettingTableStateParam
  ) => {
    if (gainPointRuleId) {
      try {
        dispatch(updateListLoading(true))
        const rsp = await deleteGainPointRule(brandId, gainPointRuleId)
        showToast(rsp, '刪除贈點成功')
        handlePointSettingTableData(pointSettingTableStateParam)
      } catch (error) {
        handleCatch(error)
      } finally {
        dispatch(updateListLoading(false))
      }
    }
  }
  // 贈點設定的停用功能
  const handlePointSettingStop = async (
    gainPointRuleId,
    pointSettingTableStateParam,
    handleModalLoading
  ) => {
    if (gainPointRuleId) {
      try {
        dispatch(updateListLoading(true))
        handleModalLoading(true)
        const rsp = await patchGainPointRuleStop(brandId, gainPointRuleId)
        showToast(rsp, '停用贈點成功')
        handlePointSettingTableData(pointSettingTableState)
        setShowStopWarning(false)
      } catch (error) {
        handleCatch(error)
      } finally {
        dispatch(updateListLoading(false))
        handleModalLoading(false)
      }
    }
  }
  // 贈點設定的表格事件變動
  const handleTableChange = (type, newState) => {
    if (isRowNotEmpty && type === 'sort') {
      const postData = {
        ...pointSettingTableState,
        sortFieldName: newState.sortField,
        sortOrder: newState.sortOrder,
      }
      setPointSettingTableState(postData)
      handlePointSettingTableData(postData)
    }
  }

  //  贈點設定中，停止按鈕之 Modal 開關
  const handleStopWarningModalShow = (boolean) => {
    setShowStopWarning(boolean)
  }

  useEffect(() => {
    handlePointSettingTableData()
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId])

  return (
    <Row className="margin-t-24 mb-40">
      <Card className="w-100">
        <div className="point-setting-table-container">
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <div>
                  <Row>
                    <Col>
                      <h3 className="migo-blue">贈點設定</h3>
                    </Col>
                    <Col className="label-title text-right d-flex align-items-center justify-content-end">
                      <span className="white-space-nowrap mr-1">每頁</span>
                      <SizePerPageDropdownStandalone
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...paginationProps}
                        sizePerPage={`${paginationProps.sizePerPage}筆`}
                      />
                      <PermissionAddButton
                        className="ml-3"
                        variant="outline-primary"
                        disabled={!gainPtByConsumption || isBaseSettingEdit}
                        onClick={() => {
                          handlePointSettingModalShow(true)
                          setPointSettingModalInputValues({})
                          isEditModalPointSetting.current = false
                        }}
                        size="sm"
                      >
                        新增贈點
                      </PermissionAddButton>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="overflow-auto">
                      <Table
                        remote
                        headerClasses="table-header"
                        bodyClasses="paragraph"
                        keyField="no"
                        onTableChange={handleTableChange}
                        data={isListLoading ? [] : rows}
                        columns={tableColumns({
                          pointSettingTableState,
                          isBaseSettingEdit,
                          setPointSettingTableState,
                          handlePointSettingEdit,
                          handlePointSettingRemove,
                          setShowStopWarning,
                          setSelectedRowID,
                          isHidden,
                        })}
                        defaultSorted={[
                          {
                            dataField: pointSettingTableState.sortFieldName,
                            order: pointSettingTableState.sortOrder,
                          },
                        ]}
                        bordered={false}
                        striped
                        hover
                        noDataIndication={
                          <NoDataIndication
                            isLoading={isListLoading}
                            message={isListLoading ? '載入中...' : '無贈點資訊'}
                          />
                        }
                        {...paginationTableProps}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {isRowNotEmpty && (
                        <PaginationListStandalone
                          className="justify-content-center"
                          {...paginationProps}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              )
            }}
          </PaginationProvider>
        </div>
      </Card>
      {modalData && isShowModalPointSetting && (
        <PointSettingModal
          brandId={brandId}
          isEdit={isEditModalPointSetting.current}
          isShowModalPointSetting={isShowModalPointSetting}
          modalData={modalData}
          gainPointRuleId={pointSettingGainPointRuleId.current}
          inputValuesData={pointSettingModalInputValues}
          isLoadingModalPointSetting={isLoadingModalPointSetting}
          handlePointSettingModalShow={handlePointSettingModalShow}
          updatePointSettingRowsData={
            () => handlePointSettingTableData(pointSettingTableState) // 更新贈點設定的表格資料
          }
        />
      )}
      {isShowStopWarning && (
        <StopWarningModal
          isShowModal={isShowStopWarning}
          handleModalClose={() => handleStopWarningModalShow(false)}
          handleModalConfirm={({ handleModalLoading }) => {
            handlePointSettingStop(
              selectedRowID,
              pointSettingTableState,
              handleModalLoading
            )
          }}
        />
      )}
    </Row>
  )
}
export default GivePointSetting
