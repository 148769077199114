/* -------------------------------------- 
  可共用的參數
--------------------------------------*/

// table中頁碼的下拉選單設定
const paginationOptions = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

const commonSetting = {
  paginationOptions,
}

/* -------------------------------------- 
  modal 參數
--------------------------------------*/
const modalType = {
  addToken: 'addToken',
  tokenInfo: 'tokenInfo',
}

const modalConfig = {
  [modalType.addToken]: {
    title: '新增token',
    close: '取消',
    confirm: '儲存',
  },
  [modalType.tokenInfo]: {
    title: 'Token 詳情',
    confirm: '複製Token',
  },
}

const modalSetting = {
  modalType,
  modalConfig,
}

export { commonSetting, modalSetting }
