import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { format, startOfDay, add } from 'date-fns'
import { DateRange as DefaultDateRange } from 'react-date-range'
import { zhTW } from 'react-date-range/dist/locale'
import { StyleButton } from 'components/units/Button'
import { StyleInput } from 'components/units/Input'
import { colorObj } from 'assets/styles/Variable/Color'
import iconCalendar from '../../assets/images/icon_calendar.svg'

const Wrapper = styled.div`
  .form-calendar,
  .form-calendar input:disabled {
    cursor: pointer;
  }

  .datepicker {
    box-shadow: 0 0 10px #888;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }

  .datepicker-footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  .__react_component_tooltip {
    &.show {
      background: ${colorObj.white};
      opacity: 1;
    }
  }

  ${(props) =>
    props.isValid === false &&
    css`
      .input-group-text,
      .form-calendar input:disabled {
        border-color: ${colorObj.danger} !important;
      }
    `}
`

const getRandomIntId = (max = 1000000) => Math.floor(Math.random() * max)

const settingStartDate = (range) => {
  return range[0].startDate && range[0].endDate
    ? range
    : [
        {
          startDate: range[0].startDate || startOfDay(new Date()),
          endDate: range[0].endDate || startOfDay(new Date()),
          key: 'selection',
        },
      ]
}

const formatDateString = (date, formatRule = 'yyyy/MM/dd') =>
  date === '' ? date : format(date, formatRule)

const initRanges = [
  {
    startDate: '',
    endDate: '',
    key: 'selection',
  },
]

const initMinDate = new Date('1900/1/1')
const initMaxDate = add(startOfDay(new Date()), { years: 10 })

function DateRange({
  defaultRanges = initRanges,
  ranges = null,
  placeholder = '',
  onChange = null,
  disabled = false,
  minDate = initMinDate,
  maxDate = initMaxDate,
  isValid = null,
  className = '',
}) {
  const [dateRanges, setDateRanges] = useState(defaultRanges)
  const id = useRef(getRandomIntId()).current
  const elRef = useRef(null)

  const prevText =
    dateRanges[0].startDate && dateRanges[0].endDate
      ? `${formatDateString(dateRanges[0].startDate)} ~ ${formatDateString(
          dateRanges[0].endDate
        )}`
      : ''

  const handleSelect = (item) => {
    setDateRanges([item.selection])
    if (typeof onChange === 'function') onChange([item.selection])
  }

  const handleReset = () => {
    setDateRanges(defaultRanges)
    if (typeof onChange === 'function') onChange(defaultRanges)
  }

  const handleDatepickerClose = () => {
    elRef.current.tooltipRef = null
    ReactTooltip.hide()
  }

  const initDateRanges = () => {
    const isValueNonExist =
      ranges[0]?.startDate === undefined && ranges[0]?.endDate === undefined
    if (isValueNonExist) return
    const isRangesKeeping = dateRanges.find(
      (item) =>
        formatDateString(item.startDate) ===
          formatDateString(ranges[0].startDate) &&
        formatDateString(item.endDate) === formatDateString(ranges[0].endDate)
    )
    if (isRangesKeeping) return
    setDateRanges(ranges)
  }
  initDateRanges()

  return (
    <Wrapper isValid="isValid" className={className}>
      <div
        className="form-calendar"
        data-tip="tooltip"
        data-for={`datepicker-${id}`}
        data-event="click"
      >
        <StyleInput
          prependContent={<img src={iconCalendar} alt="icon-calendar" />}
          prependContentHaveBorder={false}
          prependContentBgColor="transparent"
          formControlOption={{
            value: prevText,
            placeholder,
            disabled: true,
          }}
        />
      </div>
      <ReactTooltip
        className="datepicker"
        id={`datepicker-${id}`}
        clickable
        type="light"
        place="bottom"
        effect="solid"
        ref={elRef}
        disable={disabled}
      >
        <DefaultDateRange
          showDateDisplay={false}
          editableDateInputs
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
          ranges={settingStartDate(dateRanges)}
          color={colorObj.primary}
          locale={zhTW}
          minDate={minDate}
          maxDate={maxDate}
          rangeColors={['#4B65A6']}
        />
        <div className="datepicker-footer">
          <StyleButton
            variant="outline-gray"
            colorType="gray"
            colorAlpha="1"
            size="md"
            onClick={handleReset}
          >
            清除
          </StyleButton>
          <StyleButton
            variant="outline-gray"
            colorType="gray"
            colorAlpha="1"
            size="md"
            onClick={handleDatepickerClose}
          >
            關閉
          </StyleButton>
        </div>
      </ReactTooltip>
    </Wrapper>
  )
}

export default DateRange
