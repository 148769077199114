import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  getExchangeCodeDetail,
  getExchangeRecordDetail,
  getEvent,
} from 'api/ApiMain'
import { settingPageType } from 'pages/Gift/config'
import { GIFT_EXCHANGE } from 'store/constants'
import { memberOutlineSelector } from 'store/member-outline/memberOutlineSlice'

export const giftExchangeInitState = {
  giftExchangeSettingRsp: {
    data: {
      feature: {
        usingMaacActivity: true,
        usingLineLiff: true,
        usingOfflineShop: false,
        usingSendBySms: true,
        usingDelivery: true,
      },
      giftSettingId: null,
      brandSerialNo: null,
      name: '',
      color: null,
      size: null,
      images: [],
      forNewMember: false,
      forInitialMember: false,
      forMemberLevel: false,
      isAllMemberLevelChecked: false,
      memberLevelList: [],
      exchangeWay: null,
      receiveWay: null,
      giftCustomId: '',
      exchangeMethod: {
        onlyPoint: {
          point: 0,
          money: null,
          barcode: null,
        },
        pointMoney: null,
        onlyMoney: null,
      },
      amount: null,
      everyMemberOnce: false,
      exchangeStartDate: '',
      exchangeEndDate: '',
      autoAvailable: false,
      description: null,
      giftCodeStartDate: '',
      giftCodeEndDate: '',
      giftCodeType: null,
      giftCodeGetType: null,
      giftCodePrefix: '',
      giftCodeLength: null,
      giftCodeCount: null,
      barcodeTypeNotice: '',
      giftRecordCount: null,
    },
    code: 0,
    msg: null,
    errors: null,
  },
  exchangeRecordDetailRsp: {
    code: null,
    msg: '',
    errors: {},
    data: {
      giftRecordObjId: '',
      memberId: '',
      memberName: '',
      memberLevel: '',
      memberCellPhone: '',
    },
    isFetch: false,
  },
  exchangeCodeDetailRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      giftExchangeCodeId: 0,
      memberId: '',
      memberName: '',
      memberLevel: '',
      memberCellPhone: '',
    },
    isFetch: false,
  },
  giftExchangeState: {
    editType: '',
  },
}

const getGiftExchangeSettingThunk = createAsyncThunk(
  `${GIFT_EXCHANGE}/getGiftExchangeInfo`,
  async ({ brandId, giftSettingId }, { rejectWithValue }) => {
    try {
      const rsp = await getEvent(brandId, giftSettingId)
      return rsp?.data // 先保持原本api的格式，日後可統一rsp
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const getExchangeRecordDetailThunk = createAsyncThunk(
  `${GIFT_EXCHANGE}/getExchangeRecordDetail`,
  async ({ brandId, giftSettingId, giftRecordObjId }, { rejectWithValue }) => {
    try {
      const rsp = await getExchangeRecordDetail({
        brandId,
        giftSettingId,
        giftRecordObjId,
      })
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

const getExchangeCodeDetailThunk = createAsyncThunk(
  `${GIFT_EXCHANGE}/getExchangeCodeDetail`,
  async (
    { brandId, giftSettingId, giftExchangeCodeId },
    { rejectWithValue }
  ) => {
    try {
      const rsp = await getExchangeCodeDetail({
        brandId,
        giftSettingId,
        giftExchangeCodeId,
      })
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

const giftExchangeSlice = createSlice({
  name: GIFT_EXCHANGE,
  initialState: giftExchangeInitState,
  reducers: {
    resetGiftExchangeDetailModal: (state) => {
      state.exchangeRecordDetailRsp =
        giftExchangeInitState.exchangeRecordDetailRsp
      state.exchangeCodeDetailRsp = giftExchangeInitState.exchangeCodeDetailRsp
    },
    updateGiftExchangeState: (state, action) => {
      state.giftExchangeState = {
        ...state.giftExchangeState,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === getGiftExchangeSettingThunk.fulfilled.type ||
        action.type === getGiftExchangeSettingThunk.rejected.type,
      (state, action) => {
        state.giftExchangeSettingRsp = action.payload
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getExchangeRecordDetailThunk.fulfilled.type ||
        action.type === getExchangeRecordDetailThunk.rejected.type,
      (state, action) => {
        state.exchangeRecordDetailRsp = action.payload
        state.exchangeRecordDetailRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getExchangeCodeDetailThunk.fulfilled.type ||
        action.type === getExchangeCodeDetailThunk.rejected.type,
      (state, action) => {
        state.exchangeCodeDetailRsp = action.payload
        state.exchangeCodeDetailRsp.isFetch = true
      }
    )
  },
})

// 兌換名單tab 中的 兌換紀錄
const exchangeRecordDetailSelector = createSelector(
  (state) => state[GIFT_EXCHANGE].exchangeRecordDetailRsp,
  memberOutlineSelector,
  (exchangeRecordDetailRsp, memberOutlineSelectorData) => {
    const { data, isFetch: exchangeRecordFetch } = exchangeRecordDetailRsp
    const { memberOutlineFetch, ...memberOutlineData } =
      memberOutlineSelectorData

    const isReady = exchangeRecordFetch && memberOutlineFetch
    return {
      isReady,
      name: data.memberName ?? '',
      phone: data.memberCellPhone ?? '',
      level: data.memberLevel ?? '',
      memberId: data.memberId ?? '',
      ...memberOutlineData,
    }
  }
)

// 贈品序號tab 中的 兌換紀錄
const exchangeCodeDetailSelector = createSelector(
  (state) => state[GIFT_EXCHANGE].exchangeCodeDetailRsp,
  memberOutlineSelector,
  (exchangeCodeDetailRsp, memberOutlineSelectorData) => {
    const { data, isFetch: exchangeCodeFetch } = exchangeCodeDetailRsp
    const { memberOutlineFetch, ...memberOutlineData } =
      memberOutlineSelectorData
    const isReady = exchangeCodeFetch && memberOutlineFetch
    return {
      isReady,
      name: data.memberName ?? '',
      phone: data.memberCellPhone ?? '',
      level: data.memberLevel ?? '',
      memberId: data.memberId ?? '',
      ...memberOutlineData,
    }
  }
)

const giftExchangeStateSelector = createSelector(
  (state) => state[GIFT_EXCHANGE].giftExchangeState,
  (state) => state[GIFT_EXCHANGE].giftExchangeSettingRsp,
  (giftExchangeState, settingRsp) => {
    const activeState = {
      isView: giftExchangeState.editType === settingPageType.view,
      isEdit:
        giftExchangeState.editType === settingPageType.edit ||
        giftExchangeState.editType === settingPageType.new,
    }
    return {
      ...settingRsp.data,
      ...giftExchangeState,
      ...activeState,
    }
  }
)

export {
  exchangeRecordDetailSelector,
  exchangeCodeDetailSelector,
  giftExchangeStateSelector,
}
export {
  getExchangeRecordDetailThunk,
  getExchangeCodeDetailThunk,
  getGiftExchangeSettingThunk,
}
export const { resetGiftExchangeDetailModal, updateGiftExchangeState } =
  giftExchangeSlice.actions

export default giftExchangeSlice.reducer
