import {
  headerFormatterInfo,
  formatterBarCode,
  formatterStatus,
  headerFormatterStatus,
  formatterName,
  formatterCategory,
} from 'components/pages/ActivitiesCode/ProductActivity/Sub/ProductListSubComponents'

const buildType = {
  user: 10,
  system: 20,
}

const searchType = {
  code: 0,
  name: 1,
  category: 2,
}

const renderBuildList = [buildType.user, buildType.system]

const searchConfig = {
  label: '查詢清單：',
  options: [
    { value: searchType.code, text: '識別碼' },
    { value: searchType.name, text: '名稱' },
    { value: searchType.category, text: '分類' },
  ],
  search: '查詢',
  clear: '清除條件',
}

const paginationOptions = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

const captionLabel = {
  total: '總項目：',
  sizeParPage: '每頁',
  delete: '批次刪除',
  multileUpload: '批次上傳',
  submitNewProductItem: '確認送出',
}

const defaultSort = {
  sf: 'no',
  so: 'desc',
}

const columns = (columnsObj, extraProps) => [
  {
    dataField: 'no',
    text: 'No.',
    sort: true,
    attrs: function callback(cell, row) {
      if (row.no === null) return { colSpan: 2 }
    },
    style: {
      paddingLeft: columnsObj?.barCode?.isEdit ? 0 : '12px',
      borderLeft: 0,
    },
    headerStyle: {
      width: '5%',
      border: 0,
      paddingLeft: columnsObj?.barCode?.isEdit ? 0 : '12px',
    },
  },
  {
    dataField: 'barCode',
    text: '',
    sort: true,
    headerStyle: {
      width: '20%',
    },
    headerFormatter: headerFormatterInfo,
    formatter: formatterBarCode,
    formatExtraData: {
      columnsObj,
      extraProps,
    },
  },
  {
    dataField: 'name',
    text: '商品名稱',
    headerStyle: {
      width: '50%',
    },
    formatter: formatterName,
    formatExtraData: {
      columnsObj,
      extraProps,
    },
  },
  {
    dataField: 'category',
    text: '商品分類',
    headerStyle: {
      width: '20%',
    },
    formatter: formatterCategory,
    formatExtraData: {
      columnsObj,
      extraProps,
    },
  },
  {
    dataField: 'status',
    text: '',
    hidden: !columnsObj?.barCode?.isEdit,
    formatter: formatterStatus,
    formatExtraData: {
      columnsObj,
      extraProps,
    },
    // (unknown, item) => formatterStatus(columnsObj, item, extraProps),
    headerFormatter: headerFormatterStatus,
    headerClasses: 'setting-no-status',
  },
]
const selectRow = ({ onSelect, onSelectAll, selected, isEdit }) => ({
  mode: 'checkbox',
  nonSelectable: [null],
  hideSelectColumn: !isEdit,
  selected,
  onSelect,
  onSelectAll,
  selectColumnStyle: ({ disabled }) => {
    return {
      display: disabled && 'none',
    }
  },
  headerColumnStyle: {
    width: '30px',
    paddingRight: 0,
    border: 0,
  },
})

export {
  buildType,
  searchType,
  renderBuildList,
  searchConfig,
  paginationOptions,
  captionLabel,
  defaultSort,
  columns,
  selectRow,
}
