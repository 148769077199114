import PropTypes from 'prop-types'
import { useEffect, useState, useCallback, useRef } from 'react'
import { Accordion } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { ReactComponent as Arrow } from 'assets/images/button/icon_arrow.svg'
import { ReactComponent as Delete } from 'assets/images/modal/icon_modalClose.svg'
import {
  ToastCustomWrapper,
  UploadToastWrapper,
  ToastHeader,
  ToastBody,
  ToastFooter,
} from 'components/units/ToastStyle'
import { TOAST } from 'constant/common'
import { MODULE_NAME } from 'constant/toast'

function CancelBtn({
  cancelIcon = false,
  cancelTxt = null,
  onCancel,
  toastId,
}) {
  const handleClose = () => {
    toast.dismiss(toastId)
    if (typeof onCancel === 'function') onCancel()
  }
  return (
    <div className="toast-cancelText" onClick={handleClose}>
      {cancelIcon ? <Delete /> : cancelTxt ?? '關閉'}
    </div>
  )
}

function ActionBtn({ linkTxt, onAction }) {
  const handleOnAction = () => {
    if (typeof onAction === 'function') onAction()
  }

  return (
    linkTxt && (
      <div className="toast-action" onClick={handleOnAction}>
        <p>{linkTxt}</p>
      </div>
    )
  )
}

const getToastMessages = ({ type, module, countdown, progress }) =>
  ({
    preUpload: {
      msg: `${MODULE_NAME[module]} ${countdown} 秒後開始上傳，`,
      warning: '請勿離開',
      actionBtn: '取消上傳',
    },
    uploading: { msg: `${MODULE_NAME[module]} 批次上傳中...` },
    importing: {
      msg: `${MODULE_NAME[module]} 檔案寫入中... ${progress}%`,
    },
    completed: {
      msg: `${MODULE_NAME[module]} 批次上傳完成`,
      actionBtn: '關閉',
    },
    cancel: {
      msg: `${MODULE_NAME[module]} 批次上傳已取消`,
      actionBtn: '關閉',
    },
    preGenerate: {
      msg: `${countdown} 秒後開始生成，`,
      warning: '請勿離開',
      actionBtn: '取消生成',
    },
    generating: { msg: `${MODULE_NAME[module]} 生成序號中...` },

    generated: {
      msg: `${MODULE_NAME[module]} 生成序號完成`,
      actionBtn: '關閉',
    },
    cancelGenerate: {
      msg: `${MODULE_NAME[module]} 生成序號已取消`,
      actionBtn: '關閉',
    },
  }[type])

export function handleCloseToast(toastId) {
  toast.remove(toastId)
}

export function showUploadToast({
  duration,
  type,
  message,
  footer,
  onClose,
  toastId,
}) {
  toast(
    () => (
      <UploadToast
        duration={duration}
        type={type}
        message={message}
        footer={footer}
        onClose={onClose}
        toastId={toastId}
      />
    ),
    {
      duration: duration ? duration * 1000 : Infinity,
      position: 'bottom-right',
      style: {
        padding: '20px 14px',
      },
      id: toastId,
    }
  )
}

export function UploadToast({
  duration,
  type,
  message,
  footer,
  toastId,
  onClose,
}) {
  const { module, title, content, progress } = message
  const [countdown, setCountdown] = useState(duration)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const collapseRef = useRef(false)
  const { msg, warning, actionBtn } = getToastMessages({
    type,
    module,
    countdown,
    progress,
  })
  const [innerTitle, setInnerTitle] = useState(msg)

  const handleClose = useCallback(() => {
    toast.remove(toastId)
    onClose()
  }, [onClose, toastId])

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed)
    // 為了 toast 在開闔後 re-position，設計的 rerender 機制
    setInnerTitle(`${msg}  `)
  }

  useEffect(() => {
    if (type) {
      setInnerTitle(msg)
    }
  }, [msg, type])

  useEffect(() => {
    // 為了 toast 在開闔後 re-position，設計的 rerender 機制
    if (collapseRef.current !== isCollapsed) {
      collapseRef.current = isCollapsed
      const interval = setInterval(() => {
        setInnerTitle(msg)
      }, 300)
      setTimeout(() => {
        clearInterval(interval)
      }, 400)
    }
  }, [innerTitle, isCollapsed, msg, type])

  useEffect(() => {
    let interval
    if (duration) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)
      setTimeout(() => {
        clearInterval(interval)
        toast.remove(toastId)
      }, duration * 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [duration, toastId])

  return (
    <UploadToastWrapper type={type}>
      <Accordion defaultActiveKey="0">
        <Accordion.Toggle as="div" eventKey="0" onClick={handleToggle}>
          <ToastHeader isOpen={isCollapsed}>
            <div>
              <span>{innerTitle}</span>
              <span className="text-danger">{warning}</span>
            </div>
            <Arrow />
          </ToastHeader>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <>
            <ToastBody>
              <div className="module">
                <span className="text-style">{MODULE_NAME[module]}</span>
                <button onClick={handleClose} type="button">
                  {actionBtn}
                </button>
              </div>
              <div className="text-style">{title}</div>
              <div className="text-style">{content}</div>
            </ToastBody>
            <ToastFooter className={`${footer && 'show'}`}>
              {footer}
            </ToastFooter>
          </>
        </Accordion.Collapse>
      </Accordion>
    </UploadToastWrapper>
  )
}

UploadToast.propTypes = {
  duration: PropTypes.number,
  type: PropTypes.string,
  message: PropTypes.shape({
    module: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.node,
    progress: PropTypes.string,
  }),
  footer: PropTypes.node,
  toastId: PropTypes.string,
  onClose: PropTypes.func,
}
UploadToast.defaultProps = {
  duration: 0,
  type: '',
  message: { module: '', title: '', content: null, progress: '' },
  footer: null,
  toastId: null,
  onClose: () => {},
}

const showTopToast = (config, status, toastOption) => {
  const toastFunction = {
    [TOAST.SUCCESS]: toast.success,
    [TOAST.ERROR]: toast.error,
    [TOAST.LOADING]: toast.loading,
  }

  toastFunction[status](
    (t) => <TopToast config={config} status={status} toastId={t.id} />,
    {
      ...toastOption,
      position: 'top-center',
    }
  )
}

export function TopToast({ config, status, toastId }) {
  const { message, cancelIcon, cancelTxt, onCancel } = config
  const messageArr = Array.isArray(message) ? message : [message]
  const messageArrWithId = messageArr.map((msg, index) => ({ msg, id: index }))
  const handleCancel = () => {
    if (typeof onCancel === 'function') onCancel()
    toast.dismiss(toastId)
  }

  return (
    <ToastCustomWrapper>
      <div className="toast-wrapper-default-flexbox">
        <div className="toast-content-flex">
          {messageArrWithId.map(({ msg, id }) => {
            return (
              <p key={id} className="toast-content">
                {msg}
              </p>
            )
          })}
        </div>
        {status === TOAST.LOADING ||
          (status === TOAST.ERROR && (
            <CancelBtn
              cancelIcon={cancelIcon}
              cancelTxt={cancelTxt}
              onCancel={handleCancel}
              toastId={toastId}
            />
          ))}
      </div>
    </ToastCustomWrapper>
  )
}

TopToast.propTypes = {
  config: PropTypes.shape({
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    cancelIcon: PropTypes.bool,
    cancelTxt: PropTypes.string,
    onCancel: PropTypes.func,
  }),
  status: PropTypes.string,
  toastId: PropTypes.string,
}
TopToast.defaultProps = {
  config: {
    message: '',
    cancelIcon: false,
    cancelTxt: '',
    onCancel: () => {},
  },
  status: '',
  toastId: null,
}

export { showTopToast, CancelBtn, ActionBtn }

CancelBtn.propTypes = {
  cancelIcon: PropTypes.bool,
  cancelTxt: PropTypes.string,
  onCancel: PropTypes.func,
  toastId: PropTypes.string,
}

CancelBtn.defaultProps = {
  cancelIcon: false,
  cancelTxt: null,
  onCancel: () => {},
  toastId: null,
}

ActionBtn.propTypes = {
  linkTxt: PropTypes.string,
  onAction: PropTypes.func,
}

ActionBtn.defaultProps = {
  linkTxt: '',
  onAction: () => {},
}
