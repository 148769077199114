import {
  Button,
  IconButton,
  Input,
  Modal,
  NoDataIndication,
  Table,
} from 'components/units'
import { FieldTypeSwitch } from 'components/widgets'
import useField from 'hooks/useField'
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import Form from 'react-bootstrap/Form'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import { ReactComponent as IconClose } from 'assets/images/modal/icon_modalClose.svg'
import { showToast } from 'api/ApiCommon'
import { toast } from 'react-hot-toast'
import { toastErrorText } from 'helpers/common'
import { regexRuleNormal } from 'helpers/validation'
import { statusFormatter } from 'helpers/tableElFormat'
import { RootContext } from 'RootContext'
import { patchGiftDelivery } from 'api/ApiMain'
import {
  getNotificationGift,
  postNotificationGift,
  getGiftDeliveryExport,
} from 'api/pages/Gift'
import { handleUploadCsv } from './ListGoodsListHelper'
import { downloadCsv } from 'helpers/common'
import {
  CaptionWrapper,
  ImportModalWrapper,
  MemberModalWrapper,
  TextLink,
  IconFeatureStyle,
  NotificationWrapper,
} from './ListGoodsListStyle'
import withPermission from 'components/hocs/withPermission'
import { COMPONENT_PERMISSIONS } from 'constant'
import { useSelector } from 'react-redux'
import { isPermissionAllowed } from 'store/permission/permissionSlice'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'
import { useDispatch } from 'react-redux'
import { updateMaskOpen } from 'store/global/globalSlice'
import PaginationInput from 'components/units/PaginationInput'
import { CSV_EXAMPLE } from 'constant/csvExample'
import { number } from 'helpers/format'
import { paginationOptions } from 'constant/common'

// 由 config 搬過來，暫時放在這裡
const goodsFormConfig = {
  fieldName: 'email',
}

/* -------------------------------- 
  出貨名單-共用 Caption 區塊
-----------------------------------*/
function Caption({ apiPayload, paginationProps, total = 0, search }) {
  const [isShowMultipleUploadModal, setShowMultipleUploadModal] =
    useState(false)
  const PermissionBatchUploadButton = withPermission(
    Button,
    COMPONENT_PERMISSIONS.giftShippingList.CREATE
  )

  const totalText = `共 ${number(total)} 筆`

  const showMultipleModal = () => {
    setShowMultipleUploadModal(true)
  }
  const handleGiftDeliveryExport = async () => {
    try {
      const { state } = apiPayload
      const isUnshipped = state === 'unshipped'
      const fileName = `出貨名單_匯出${isUnshipped ? '未出貨' : '全部'}名單`
      const res = await getGiftDeliveryExport(apiPayload)
      downloadCsv({ data: res.data, fileName })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <CaptionWrapper>
      <div>
        <div className="caption-label">總項目：</div>
        <div>{totalText}</div>
      </div>
      <div>
        <div className="caption-color mr-1">每頁</div>
        <SizePerPageDropdownStandalone
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
        <span className="d-flex align-items-center">
          <PermissionBatchUploadButton
            className="ml-4"
            variant="outline-darkerGray"
            size="sm"
            onClick={showMultipleModal}
          >
            批次上傳
          </PermissionBatchUploadButton>
          <Button
            className="ml-3"
            variant="outline-primary"
            size="sm"
            onClick={handleGiftDeliveryExport}
            disabled={total === 0}
          >
            匯出名單
          </Button>
        </span>
      </div>
      <MultipleUploadModal
        uploadModal={isShowMultipleUploadModal}
        setUploadModal={setShowMultipleUploadModal}
        search={search}
      />
    </CaptionWrapper>
  )
}
function MultipleUploadModal({ uploadModal, setUploadModal, search }) {
  const dispatch = useDispatch()
  const rootData = useContext(RootContext)
  const brandId = rootData?.brandId

  const handleGreyMask = (bool) => {
    dispatch(updateMaskOpen(bool))
  }

  const handleExampleDownload = () =>
    downloadCsv({
      data: CSV_EXAMPLE.SHIPMENT,
      fileName: '出貨名單_匯入範本',
      isAppendTime: false,
    })

  return (
    <Modal
      show={uploadModal}
      onClose={() => setUploadModal(false)}
      titleText="批次上傳"
      closeBtnText="關閉"
      customConfirm={() => (
        <UploadBtn
          onChange={handleUploadCsv({
            brandId,
            setMask: handleGreyMask,
            setUploadModal,
            search,
          })}
        />
      )}
    >
      <ImportModalWrapper>
        <p>1. 檔案格式限 csv 檔，檔案大小限 ≤ 25MB</p>
        <p>
          2. 欄位設定說明，詳見&nbsp;
          <TextLink onClick={handleExampleDownload}>
            匯入範本說明 (下載)
          </TextLink>
        </p>
      </ImportModalWrapper>
    </Modal>
  )
}

function UploadBtn({ onChange }) {
  const inputRef = useRef(null)
  return (
    <>
      <Button variant="primary" onClick={() => inputRef.current.click()}>
        上傳檔案
      </Button>
      <Input
        onClick={(e) => {
          e.target.value = ''
        }} // 情境：按取消上傳，再次重複上傳相同檔案，重置動作，才能再次上傳該檔案
        formControlOption={{
          style: { display: 'none' },
          ref: inputRef,
          type: 'file',
          accept: '.csv',
          onChange: (e) => {
            const [file] = e.currentTarget.files
            const sizeMb = file.size / 1024 ** 2
            if (sizeMb >= 25) {
              toast.error((t) =>
                toastErrorText('檔案大小超過 25MB，請重新選擇', t.id)
              )
            } else if (typeof onChange === 'function') onChange(file)
          },
        }}
      />
    </>
  )
}

/* -------------------------------- 
  寄送電子信箱 Ｍodal 區塊
-----------------------------------*/
function FormEmailModal({ show = false, brandId, onClose }) {
  // 表單form是否驗證欄位規則
  const [validated, setValidated] = useState(false)
  const [emailFieldCount, setEmailFieldCount] = useState(1)
  const fieldData = useField({}, validated)
  const { inputValues, onChangeField, setInputValues } = fieldData
  const formRef = useRef(null)
  const { isDataLoading } = useSelector(getGlobalState)
  const dispatch = useDispatch()

  const addEmail = () => {
    setEmailFieldCount(emailFieldCount + 1)
    setInputValues((prevState) => {
      return {
        ...prevState,
        [`${goodsFormConfig.fieldName}-${emailFieldCount}`]: '',
      }
    })
  }
  const handleSubmit = async ({ handleModalLoading }) => {
    const form = formRef.current
    // 判斷form的欄位中，有填寫name值及驗證規則(required、pattern)的項目
    const isValidated = form.checkValidity()

    setValidated(true)
    // form的欄位判斷都通過
    if (isValidated) {
      const inputValueDisplayCount = {} // 紀錄各email出現的次數(2次以上表示重複)
      for (const key in inputValues) {
        const value = inputValues[key]
        if (value) {
          inputValueDisplayCount[value] = inputValueDisplayCount[value]
            ? inputValueDisplayCount[value] + 1
            : 1
        }
      }
      const repeatArray = []
      for (const emailItem in inputValueDisplayCount) {
        const count = inputValueDisplayCount[emailItem]
        if (count > 1) {
          repeatArray.push(emailItem)
        }
      }
      const toastDuration = 4000
      if (repeatArray.length > 0) {
        toast.error(
          () => (
            <div>
              {repeatArray.map((item, index) => {
                return (
                  <div key={`repeat-email-${index}`}>
                    通知信箱不可重複：
                    {item}
                  </div>
                )
              })}
            </div>
          ),
          {
            duration: toastDuration,
          }
        )
        handleModalLoading(false)
      } else {
        // 都沒問題的情況(成功送出)
        handleModalLoading(true)
        try {
          const postData = {
            brandId,
            /*
                type:
                1:Email
                2:SMS
                3:Line
              */
            type: 1,
            receivers: [],
          }
          postData.receivers = Object.values(inputValues).filter(
            (email) => email !== ''
          )
          await postNotificationGift(postData)
          toast.success('通知設定成功')
        } finally {
          handleModalClose()
          handleModalLoading(false)
        }
      }
    }
  }

  const handleModalClose = () => {
    if (typeof onClose === 'function') {
      onClose()
      initModal()
    }
  }

  const initModal = () => {
    setValidated(false)
    setEmailFieldCount(1)
    setInputValues({})
  }

  const handleGetNotificationGift = useCallback(async () => {
    /*
      type:
      1:Email
      2:SMS
      3:Line
    */
    dispatch(updateDataLoading(true))
    const getNotificationGiftData = await getNotificationGift(brandId, 1)
    const data = getNotificationGiftData?.data?.data
    const { emails } = data
    const obj = []
    if (Array.isArray(emails)) {
      for (const [index, item] of emails.entries()) {
        obj[`email-${index}`] = item
      }
      setInputValues(obj)
      setEmailFieldCount(emails.length || 1)
    }
    dispatch(updateDataLoading(false))
  }, [brandId, setInputValues])

  useEffect(() => {
    if (show) {
      handleGetNotificationGift()
    }
  }, [show, handleGetNotificationGift])

  return (
    <Modal
      show={show}
      onClose={handleModalClose}
      onConfirm={handleSubmit}
      titleText="通知設定"
      customConfirm={null}
      closeBtnText="取消"
      confirmBtnText="儲存"
      isDefaultDataLoading={isDataLoading}
    >
      <Form noValidate validated={validated} ref={formRef}>
        <NotificationWrapper>
          <div className="fz15 text-center email-warning">
            每日 10:00 ，寄送未出貨名單至指定信箱
          </div>
          <div className="fz15 text-center email-warning">
            若需取消通知，請先清空通知信箱再點擊儲存
          </div>
          <>
            {/*暫用 index 作為 key*/}
            {[...Array(emailFieldCount)].map((fieldItem, fieldIndex) => {
              return (
                <div
                  className="member-info mt-3"
                  key={`${goodsFormConfig.fieldName}-field-${fieldIndex}`}
                >
                  <div className="member-label">
                    {fieldIndex === 0 ? '通知信箱：' : ''}
                  </div>
                  <div className="member-content w-100">
                    <Input
                      formControlOption={{
                        type: 'email',
                        placeholder: '電子郵件地址',
                        pattern: regexRuleNormal.email,
                        name: `${goodsFormConfig.fieldName}-${fieldIndex}`,
                        value:
                          inputValues[
                            `${goodsFormConfig.fieldName}-${fieldIndex}`
                          ] ?? '',
                      }}
                      appendContent={
                        inputValues[
                          `${goodsFormConfig.fieldName}-${fieldIndex}`
                        ] ? (
                          <IconClose />
                        ) : (
                          ' '
                        )
                      }
                      appendContentBgColor="transparent"
                      isFocusCocatAppend
                      appendContentHaveBorder={false}
                      appendContentPadding="0 8px"
                      appendContentOnClick={() => {
                        setInputValues((prevState) => {
                          return {
                            ...prevState,
                            [`${goodsFormConfig.fieldName}-${fieldIndex}`]: '',
                          }
                        })
                      }}
                      onChange={onChangeField}
                      feedbackText="請輸入正確的Email格式"
                    />
                  </div>
                </div>
              )
            })}
            <div className="member-info mt-3">
              <div className="member-label" />
              <div className="member-content w-100">
                <Input
                  formControlOption={{
                    placeholder: '新增電子郵件',
                    className: 'add-email-field cursor-pointer',
                    readOnly: true,
                  }}
                  onClick={addEmail}
                />
              </div>
            </div>
          </>
        </NotificationWrapper>
      </Form>
    </Modal>
  )
}

/* -------------------------------- 
  出貨名單-共用 出貨詳情 Ｍodal 區塊
-----------------------------------*/
function DetailModal({
  brandId,
  show = false,
  data = {},
  onClose = () => {},
  search,
  isDefaultDataLoading = false,
}) {
  // 表單form是否驗證欄位規則
  const [validated, setValidated] = useState(false)
  // 固定欄位的資料
  const fixFieldsData = [
    {
      canModify: true,
      display: '收件姓名',
      isRequired: true,
      name: 'name',
      options: [],
      placeholder: '',
      tip: '',
      toolTip: '',
      value: '',
    },
    {
      canModify: true,
      display: '收件手機',
      isRequired: true,
      name: 'cell_phone',
      options: [],
      placeholder: '',
      tip: '',
      toolTip: '',
      value: '',
    },
    {
      canModify: true,
      display: '收件地址',
      isRequired: true,
      name: 'city',
      options: [],
      placeholder: '縣市',
      tip: '',
      toolTip: '',
      value: '',
    },
    {
      canModify: true,
      display: '',
      isRequired: true,
      name: 'dist',
      options: [],
      placeholder: '鄉鎮市區',
      tip: '',
      toolTip: '',
      value: '',
    },
    {
      canModify: true,
      display: '',
      isRequired: true,
      name: 'address',
      options: [],
      placeholder: '地址',
      tip: '',
      toolTip: '',
      value: '',
    },
  ]
  // 彈性欄位的資料
  const [optionalFieldsData, setOptionalFieldsData] = useState(optionalFormData)
  // 紀錄是否做過初值的處理(避免一直render)
  const isDoingInitInputValuesRef = useRef(false)
  const formRef = useRef(null)
  const fieldData = useField({ optional: null, customized: null }, validated)
  const {
    inputValues,
    setInputValues,
    handleInitFieldValue,
    handleInitOptionalFieldValue,
  } = fieldData

  const { fieldTypeSwitchProps: fixFieldTypeSwitchProps, titles: fixTitles } =
    fieldData.getFixField(fixFieldsData)

  const {
    fieldTypeSwitchProps: optionFieldTypeSwitchProps,
    titles: optionTitles,
  } = fieldData.getOptionalField(optionalFieldsData)

  // 要將輸出的object 結構或key名稱做更換
  const postDataTranslate = (inputValues) => {
    const { optional } = inputValues
    const obj = {
      recipientName: inputValues?.name,
      recipientCellPhone: inputValues?.cell_phone,
      recipientCity: {
        optionId: inputValues?.city?.id || inputValues?.city?.optionId, // optionId為get api而來
        code: inputValues?.city?.code,
        name: inputValues?.city?.name,
      },
      recipientDist: {
        optionId: inputValues?.dist?.id || inputValues?.dist?.optionId, // optionId為get api而來
        code: inputValues?.dist?.code,
        name: inputValues?.dist?.name,
      },
      recipientAddress: inputValues?.address,
      deliveryNote: optional?.deliveryNote,
      shippingNumber: optional?.shippingNumber,
      whetherToShip:
        Array.isArray(optional?.whetherToShip) &&
        optional?.whetherToShip?.length > 0,
    }

    return obj
  }
  const handleSubmit = async () => {
    const form = formRef.current
    // 判斷form的欄位中，有填寫name值及驗證規則(required、pattern)的項目
    const isValidated = form.checkValidity()

    setValidated(true)
    // form的欄位判斷都通過後，再進行特別判斷
    if (isValidated) {
      const postData = postDataTranslate(inputValues)
      try {
        const rsp = await patchGiftDelivery(data?.giftRecordObjId, {
          brandId,
          customClientId: data?.customClientId,
          ...postData,
        })
        showToast(rsp, '收件資訊編輯成功')
        search()
      } finally {
        handleModalClose()
      }
    }
  }
  const handleModalClose = () => {
    if (typeof onClose === 'function') {
      onClose()
      initModal()
    }
  }
  const initModal = () => {
    setValidated(false)
    setInputValues({})
    isDoingInitInputValuesRef.current = false
  }

  // 設定出貨詳情彈出視窗的初值處理
  useEffect(() => {
    if (show && !isDoingInitInputValuesRef.current && data?.isFetch) {
      // 固定欄位的處理
      const fixArray = fixFieldsData.map((item) => {
        const { name } = item
        const nameMapping = {
          name: data?.recipientName,
          cell_phone: data?.recipientCellPhone,
          city: data?.recipientCity,
          dist: data?.recipientDist,
          address: data?.recipientAddress,
        }
        const value = nameMapping[name] || ''
        return { ...item, value }
      })
      // 彈性欄位的處理
      const optionalArray = optionalFieldsData.map((item) => {
        const { name } = item
        const nameMapping = {
          deliveryNote: data?.deliveryNote,
          whetherToShip: null,
          shippingNumber: data?.shippingNumber,
        }
        const value = nameMapping[name]
        const options = data?.whetherToShip
          ? [{ ...item.options[0], selected: true }]
          : [{ ...item.options[0], selected: false }]
        if (name === 'whetherToShip') return { ...item, value, options }
        return { ...item, value }
      })

      // 固定欄位
      handleInitFieldValue(fixArray)
      // 彈性欄位
      handleInitOptionalFieldValue(optionalArray)
      isDoingInitInputValuesRef.current = true
    }
  }, [show, data, fixFieldsData, optionalFieldsData])

  // 物流狀態的出貨變更時間
  useEffect(() => {
    if (!Object.keys(data).length) return
    const deepClone = JSON.parse(JSON.stringify(optionalFieldsData))
    for (const [index, item] of deepClone.entries()) {
      const { name } = item
      if (name !== 'whetherToShip') continue // 只有物流狀態的欄位才需要處理，其餘略過
      const { shippingUpdateTime } = data
      const tip = shippingUpdateTime ? `出貨變更時間 ${shippingUpdateTime}` : ''
      deepClone[index].tip = tip
    }
    setOptionalFieldsData(deepClone)
  }, [data])

  return (
    <Modal
      show={show}
      onClose={handleModalClose}
      onConfirm={handleSubmit}
      titleText="出貨詳情"
      customConfirm={null}
      closeBtnText="取消"
      confirmBtnText="儲存"
      isDefaultDataLoading={isDefaultDataLoading}
    >
      <MemberModalWrapper>
        {/* TODO 根據兌換通路 顯示/隱藏 選項 */}
        <div className="member-info">
          <div className="member-label">訂單編號：</div>
          <div className="member-content">{data?.giftRecordObjId}</div>
        </div>
        <div className="member-info">
          <div className="member-label">兌換時間：</div>
          <div className="member-content">{data?.exchangedTime}</div>
        </div>
        <div className="member-info">
          <div className="member-label">兌換通路：</div>
          <div className="member-content">{data?.exchangedWay}</div>
        </div>
        <div className="member-info">
          <div className="member-label">兌換方式：</div>
          <div className="member-content">{data?.exchangedExpense}</div>
        </div>
        <hr />
        <div className="member-info">
          <div className="member-label">會員編號：</div>
          <div className="member-content">{data?.customClientId}</div>
        </div>
        <div className="member-info">
          <div className="member-label">會員：</div>
          <div className="member-content">{data?.memberName}</div>
        </div>
        <div className="member-info">
          <div className="member-label">手機：</div>
          <div className="member-content">{data?.memberCellPhone}</div>
        </div>
        <div className="member-info">
          <div className="member-label">會員等級：</div>
          <div className="member-content">{data?.memberLevel}</div>
        </div>
        <hr />
        <div className="member-info">
          <div className="member-label">贈品識別碼：</div>
          <div className="member-content">{data?.giftCustomId}</div>
        </div>
        <div className="member-info">
          <div className="member-label">贈品名稱：</div>
          <div className="member-content">{data?.giftName}</div>
        </div>
        <div className="member-info">
          <div className="member-label">顏色：</div>
          <div className="member-content">{data?.giftColor}</div>
        </div>
        <div className="member-info">
          <div className="member-label">尺寸：</div>
          <div className="member-content">{data?.giftSize}</div>
        </div>
        <div className="member-info">
          <div className="member-label">數量：</div>
          <div className="member-content">{data?.giftQuantity}</div>
        </div>
        <hr />
        {data?.whetherToShip && (
          <div className="font-danger fz13 mb-3">
            此項目已出貨，修改收件資訊可能無法正確配送
          </div>
        )}
        <Form noValidate validated={validated} ref={formRef}>
          <>
            {fixFieldTypeSwitchProps.map((fieldTypeSwitchProp, itemIndex) => {
              const title = fixTitles[itemIndex]
              const { required, name } = fieldTypeSwitchProp.formControlOption
              const canModify = !fixFieldsData[itemIndex].canModify
              fieldTypeSwitchProp.formControlOption.disabled = canModify
              return (
                <div
                  className="member-info"
                  key={`field-type-switch-${
                    fieldTypeSwitchProp?.formControlOption?.name ?? itemIndex
                  }`}
                >
                  <div className="member-label font-danger">
                    {title && (
                      <>
                        {title}
                        {required && <span className="font-danger">* </span>} :
                      </>
                    )}
                  </div>
                  <div className="member-content w-100">
                    <FieldTypeSwitch
                      {...fieldTypeSwitchProp}
                      containerClass={`w-100 ${
                        (name === 'city' || name === 'dist') && 'pr-0'
                      }`}
                    />
                  </div>
                </div>
              )
            })}
          </>
          <>
            {optionFieldTypeSwitchProps.map(
              (fieldTypeSwitchProp, itemIndex) => {
                const title = optionTitles[itemIndex]
                const required = optionalFieldsData[itemIndex].isRequired
                return (
                  <div
                    className="member-info"
                    key={`field-type-switch-${
                      fieldTypeSwitchProp?.formControlOption?.name ?? itemIndex
                    }`}
                  >
                    <div className="member-label font-danger">
                      {title && (
                        <span>
                          {title}
                          {required && (
                            <span className="font-danger">* </span>
                          )}{' '}
                          :
                        </span>
                      )}
                    </div>
                    <div className="member-content w-100">
                      <FieldTypeSwitch
                        {...fieldTypeSwitchProp}
                        containerClass="w-100"
                      />
                    </div>
                  </div>
                )
              }
            )}
          </>
        </Form>
      </MemberModalWrapper>
    </Modal>
  )
}
const optionalFormData = [
  {
    canModify: true,
    fieldType: 'textarea',
    display: '備註',
    isRequired: false,
    name: 'deliveryNote',
    options: [],
    placeholder: '選填，上限 200 字',
    tip: '',
    toolTip: '',
    value: '',
    fieldLength: 200,
  },
  {
    canModify: true,
    fieldType: 'checkbox',
    display: '物流狀態',
    isRequired: false,
    name: 'whetherToShip',
    options: [
      {
        code: 'sent',
        name: '已出貨',
        selected: false,
      },
    ],
    tip: '',
    toolTip: '',
    value: '',
  },
  {
    canModify: true,
    fieldType: 'text',
    display: '物流單號',
    isRequired: false,
    name: 'shippingNumber',
    options: [],
    placeholder: '供追查物流狀態',
    tip: '',
    toolTip: '',
    value: '',
  },
]

/* -------------------------------- 
  出貨名單表格共用
-----------------------------------*/
// 表格的formatter
const goodsListFormatter = {
  giftNameFormatter: (cell, row) => {
    const { giftCode, giftColor, giftSize } = row
    let note = `${giftCode} ${giftColor ?? ''} ${giftColor ? '|' : ''} ${
      giftSize ?? ''
    }`
    if (!(giftCode ?? false) && !(giftColor ?? false) && !(giftSize ?? false)) {
      note = ''
    }
    return (
      <div>
        {cell}
        {note && <div>{statusFormatter(note, false, false, true, false)}</div>}
      </div>
    )
  },
  addressFormatter: (cell, row) => {
    const { recipientCity, recipientDist } = row
    return (
      <div>
        {recipientCity.name} {recipientDist.name}
        <div>{cell}</div>
      </div>
    )
  },
  buttonStatusFormatter: (cell, row, rowIndex, { onClick }) => {
    const PermissionEditIcon = withPermission(
      IconButton,
      COMPONENT_PERMISSIONS.giftShippingList.UPDATE
    )
    const handleClick = () => onClick(row)
    return (
      <IconFeatureStyle className="icon-feature-container d-flex align-items-center">
        {Object.keys(cell).map((state) => {
          let triggerElement = null
          if (cell[state].visible) {
            switch (state) {
              case 'editBtn':
                triggerElement = (
                  <PermissionEditIcon
                    onClick={handleClick}
                    tooltip="編輯"
                    type="edit"
                    data-effect="solid"
                  />
                )
                break
              default:
                break
            }
          }
          return (
            <Fragment key={`icon-feature-${rowIndex}-${state}`}>
              {triggerElement}
            </Fragment>
          )
        })}
      </IconFeatureStyle>
    )
  },
  statusFormatter: (cell, row) => {
    const { statusDescription } = row
    let statusArr = [true, false, false]
    switch (cell) {
      // 0:未兌換;
      // 10:未出貨
      // 12:即將出貨
      case 0:
      case 10:
      case 12:
        statusArr = statusArr.fill(false)
        break
      // 1:已兌換
      // 2:已領取
      // 11:已出貨
      case 1:
      case 2:
      case 11:
        statusArr = [true, false, false]
        break
      // 90:已過期
      case 90:
        statusArr = [false, false, true]
    }
    return statusFormatter(statusDescription, ...statusArr)
  },
}

/* -------------------------------- 
  出貨名單表格-未出貨
-----------------------------------*/
function GoodsListReady({
  search,
  apiPayload,
  tablePayload,
  setTablePayload,
  tableData,
  onClick,
}) {
  const { isDataLoading } = useSelector(getGlobalState)
  const isShowFuncButtonCol = useSelector(
    isPermissionAllowed(COMPONENT_PERMISSIONS.giftShippingList.UPDATE)
  )

  const handleTableChange = (type, data) => {
    const { page, sizePerPage, sortField, sortOrder } = data

    const newTablePayload = {
      ...tablePayload,
      p: page || tablePayload.p,
      ps: sizePerPage || tablePayload.ps,
      so: sortOrder || tablePayload.so,
      sf: sortField || tablePayload.sf,
    }

    setTablePayload(newTablePayload)
  }

  const goodsListReadyColumnsConfig = [
    { dataField: 'giftRecordObjId', text: '訂單編號', sort: true },
    {
      dataField: 'exchangedDate',
      text: '兌換日期',
      sort: true,
    },
    { dataField: 'customClientId', text: '會員編號', sort: true },
    {
      dataField: 'giftName',
      text: '名稱',
      sort: true,
      formatter: goodsListFormatter.giftNameFormatter,
    },
    {
      dataField: 'recipientName',
      text: '收件姓名',
    },
    { dataField: 'recipientCellPhone', text: '收件手機' },
    {
      dataField: 'recipientAddress',
      text: '收件地址',
      formatter: goodsListFormatter.addressFormatter,
      style: { minWidth: '120px' },
    },
    {
      dataField: 'funcButton',
      text: '',
      formatter: goodsListFormatter.buttonStatusFormatter,
      formatExtraData: { onClick },
      hidden: !isShowFuncButtonCol,
    },
  ]
  return (
    <>
      <PaginationProvider
        pagination={paginationFactory({
          ...paginationOptions,
          totalSize: tableData.total,
          page: apiPayload.p,
          sizePerPage: apiPayload.ps,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <Caption
              paginationProps={paginationProps}
              total={tableData.total}
              apiPayload={apiPayload}
              search={search}
            />
            <Table
              headerClasses="table-header"
              bodyClasses="paragraph"
              className="overflow-auto"
              keyField="giftRecordObjId"
              data={tableData.list}
              columns={goodsListReadyColumnsConfig}
              striped
              remote
              onTableChange={handleTableChange}
              defaultSorted={[
                {
                  dataField: 'giftRecordObjId',
                  order: 'desc',
                },
              ]}
              noDataIndication={
                <NoDataIndication
                  isLoading={isDataLoading}
                  message={isDataLoading ? '載入中...' : '尚無資料'}
                />
              }
              {...paginationTableProps}
            />
            <div className="d-flex justify-content-center">
              <PaginationListStandalone {...paginationProps} />
              <PaginationInput
                onPageChange={paginationProps.onPageChange}
                page={paginationProps.page}
                sizePerPage={paginationProps.sizePerPage}
                totalSize={paginationProps.totalSize}
              />
            </div>
          </>
        )}
      </PaginationProvider>
    </>
  )
}

/* -------------------------------- 
  出貨名單表格-全部
-----------------------------------*/
function GoodsListAll({
  search,
  apiPayload,
  tablePayload,
  setTablePayload,
  tableData,
  onClick,
}) {
  const { isDataLoading } = useSelector(getGlobalState)

  const handleTableChange = (type, data) => {
    const { page, sizePerPage, sortField, sortOrder } = data

    const newTablePayload = {
      ...tablePayload,
      p: page || tablePayload.p,
      ps: sizePerPage || tablePayload.ps,
      so: sortOrder || tablePayload.so,
      sf: sortField || tablePayload.sf,
    }

    setTablePayload(newTablePayload)
  }

  const columns = [
    { dataField: 'giftRecordObjId', text: '訂單編號', sort: true },
    { dataField: 'exchangedDate', text: '兌換日期', sort: true },
    { dataField: 'customClientId', text: '會員編號', sort: true },
    {
      dataField: 'giftName',
      text: '名稱',
      sort: true,
      formatter: goodsListFormatter.giftNameFormatter,
    },
    { dataField: 'recipientName', text: '收件姓名' },
    { dataField: 'recipientCellPhone', text: '收件手機' },
    {
      dataField: 'recipientAddress',
      text: '收件地址',
      formatter: goodsListFormatter.addressFormatter,
      style: { minWidth: '120px' },
    },
    {
      dataField: 'status',
      text: '狀態',
      formatter: goodsListFormatter.statusFormatter,
      sort: true,
      style: { minWidth: '85px' },
    },
    {
      dataField: 'funcButton',
      text: '',
      formatter: goodsListFormatter.buttonStatusFormatter,
      formatExtraData: { onClick },
    },
  ]

  return (
    <>
      <PaginationProvider
        pagination={paginationFactory({
          ...paginationOptions,
          totalSize: tableData.total,
          page: apiPayload.p,
          sizePerPage: apiPayload.ps,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <Caption
              paginationProps={paginationProps}
              total={tableData.total}
              apiPayload={apiPayload}
              search={search}
            />
            <Table
              headerClasses="table-header"
              bodyClasses="paragraph"
              className="overflow-auto"
              keyField="giftRecordObjId"
              data={tableData.list}
              columns={columns}
              striped
              remote
              onTableChange={handleTableChange}
              defaultSorted={[
                {
                  dataField: 'giftRecordObjId',
                  order: 'desc',
                },
              ]}
              noDataIndication={
                <NoDataIndication
                  isLoading={isDataLoading}
                  message={isDataLoading ? '載入中...' : '尚無資料'}
                />
              }
              {...paginationTableProps}
            />
            <div className="d-flex justify-content-center">
              <PaginationListStandalone {...paginationProps} />
              <PaginationInput
                onPageChange={paginationProps.onPageChange}
                page={paginationProps.page}
                sizePerPage={paginationProps.sizePerPage}
                totalSize={paginationProps.totalSize}
              />
            </div>
          </>
        )}
      </PaginationProvider>
    </>
  )
}

export { DetailModal, GoodsListReady, GoodsListAll, FormEmailModal }
