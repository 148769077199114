import { useState, useEffect, useCallback, useContext } from 'react'
import { toast } from 'react-hot-toast'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { Table, NoDataIndication } from 'components/units'
import { getVipExchangedRecord } from 'api/pages/Gift'
import { ExclusiveSettingContext, tabType } from 'pages/Gift/ExclusiveSetting'
import {
  tableText,
  toastText,
  initPaylaod,
  columns,
} from 'components/pages/Gift/ExclusiveSendListConfig'
import { Wrapper } from 'components/pages/Gift/ExclusiveSendListStyle'
import {
  Caption,
  MemberInfoModal,
} from 'components/pages/Gift/ExclusiveSendListSubComponents'
import { useDispatch, useSelector } from 'react-redux'
import {
  getVipExchangeRecordDetailThunk,
  resetVipGiftExchangeDetailModal,
  vipExchangeRecordDetailSelector,
} from 'store/vip-gift-exchange/vipGiftExchangeSlice'
import {
  getMemberOutlineThunk,
  resetMemberOutline,
} from 'store/member-outline/memberOutlineSlice'
import { paginationOptions } from 'constant/common'

function ExclusiveSendList() {
  const context = useContext(ExclusiveSettingContext)
  const isThisPageNow = context?.pageType === tabType.sendList
  const brandId = context?.history?.location?.state?.apiPayload?.brandId
  // apiPaylaod?.giftSettingId為編輯＆檢視進入頁面，context?.settingId為新增進入頁面
  const giftSettingId =
    context?.history?.location?.state?.apiPayload?.giftSettingId ||
    context?.settingId
  const [data, setData] = useState({ list: [], total: 0 })
  const [payload, setPayload] = useState(initPaylaod)
  const [isModalOpen, setModalOpen] = useState(false)
  const modalInfo = useSelector(vipExchangeRecordDetailSelector)
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    (value) =>
      setPayload((prev) => ({
        ...prev,
        cellPhone: value,
      })),
    []
  )

  const paginationFn = {
    onPageChange: useCallback(
      (page) => setPayload((prev) => ({ ...prev, p: page })),
      []
    ),
    onSizePerPageChange: useCallback(
      (sizePerPage, page) =>
        setPayload((prev) => ({
          ...prev,
          p: page === 0 ? 1 : page,
          ps: sizePerPage,
        })),
      []
    ),
  }

  const handleTableChnage = useCallback((type, data) => {
    if (type === 'pagination') return
    const sortConfig = {
      no: 'no',
      status: 'status',
      sendDateTime: 'senddatetime',
    }
    const { sortField, sortOrder } = data
    setPayload((prev) => ({
      ...prev,
      so: sortOrder,
      sf: sortConfig[sortField],
    }))
  }, [])

  const handleModalOpen = useCallback(
    (payload) => {
      const { memberId, vipGiftRecordObjId } = payload
      dispatch(getMemberOutlineThunk({ memberId, brandId }))
      dispatch(
        getVipExchangeRecordDetailThunk({
          brandId,
          vipGiftSettingId: giftSettingId,
          vipGiftRecordObjId,
        })
      )
      setModalOpen(true)
    },
    [brandId]
  )
  const handleModalClose = () => {
    setModalOpen(false)
    dispatch(resetMemberOutline())
    dispatch(resetVipGiftExchangeDetailModal())
  }
  const handleModalConfirm = useCallback(() => {
    if (modalInfo?.memberId) window.open(`/member/edit/${modalInfo?.memberId}`)
    handleModalClose()
  }, [modalInfo?.memberId])

  const search = useCallback(async () => {
    if (!isThisPageNow) return
    const isEditPayload = payload.cellPhone !== initPaylaod.cellPhone
    const res = await getVipExchangedRecord(brandId, giftSettingId, payload)
    const resList = res?.data?.data?.rows || []
    if (isEditPayload && resList.length === 0)
      toast.error(toastText.searchEmpty, { duration: 4000 })
    const resTotal = res?.data?.data?.totalSize || 0
    setData({ list: resList, total: resTotal })
  }, [isThisPageNow, brandId, giftSettingId, payload])

  useEffect(() => {
    search()
  }, [search])
  return (
    <Wrapper>
      <PaginationProvider
        pagination={paginationFactory({
          ...paginationOptions,
          ...paginationFn,
          totalSize: data.total,
          page: payload.p,
          sizePerPage: payload.ps,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <Caption
              paginationProps={paginationProps}
              onSubmit={onSubmit}
              total={data.total}
            />
            <Table
              headerClasses="table-header"
              bodyClasses="paragraph"
              className="overflow-auto"
              keyField="no"
              data={data.list}
              columns={columns({
                onClick: handleModalOpen,
              })}
              striped
              remote
              onTableChange={handleTableChnage}
              noDataIndication={<NoDataIndication message={tableText.empty} />}
              {...paginationTableProps}
            />
            <PaginationListStandalone {...paginationProps} />
          </>
        )}
      </PaginationProvider>
      <MemberInfoModal
        show={isModalOpen}
        info={modalInfo}
        onConfirm={handleModalConfirm}
        onClose={handleModalClose}
      />
    </Wrapper>
  )
}

export default ExclusiveSendList
