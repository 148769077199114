import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import { IconButton } from 'components/units'

const IconButtonGroupContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
`

export default function IconButtonGroup({ iconList, onHandleIconButton }) {
  function renderIconButtonGroup() {
    if (iconList.length === 0) return null
    return iconList.map((item) => {
      const { visible, type, itemId } = item
      const isLightGray = type === 'delete' || type === 'stop'
      const key = `${type}_${itemId}`
      const buttonColor = isLightGray ? colorObj.lightGray : ''
      if (visible) {
        return (
          <IconButton
            key={key}
            type={type}
            color={buttonColor}
            data-effect="solid"
            style={{ margin: '0 4px' }}
            onClick={() => onHandleIconButton({ type, itemId })}
          />
        )
      }
      return null
    })
  }

  return (
    <IconButtonGroupContainer>
      {renderIconButtonGroup()}
    </IconButtonGroupContainer>
  )
}

IconButtonGroup.propTypes = {
  iconList: PropTypes.arrayOf(
    PropTypes.shape({
      visible: PropTypes.bool,
      type: PropTypes.string,
      accountId: PropTypes.number,
    })
  ).isRequired,
  onHandleIconButton: PropTypes.func.isRequired,
}
