import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  getVipExchangeCodeDetail,
  getVipExchangeRecordDetail,
} from 'api/ApiMain'
import { VIP_GIFT_EXCHANGE } from 'store/constants'
import { memberOutlineSelector } from 'store/member-outline/memberOutlineSlice'

export const vipGiftExchangeInitState = {
  vipExchangeRecordDetailRsp: {
    data: {
      vipGiftRecordObjId: '',
      memberId: '',
      memberName: '',
      memberLevel: '',
      memberCellPhone: '',
    },
    code: null,
    msg: '',
    errors: {},
    isFetch: false,
  },
  vipExchangeCodeDetailRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      vipGiftExchangeCodeId: 0,
      memberId: '',
      memberName: '',
      memberLevel: '',
      memberCellPhone: '',
    },
    isFetch: false,
  },
}

const getVipExchangeRecordDetailThunk = createAsyncThunk(
  `${VIP_GIFT_EXCHANGE}/getVipExchangeRecordDetail`,
  async (
    { brandId, vipGiftSettingId, vipGiftRecordObjId },
    { rejectWithValue }
  ) => {
    try {
      const rsp = await getVipExchangeRecordDetail({
        brandId,
        vipGiftSettingId,
        vipGiftRecordObjId,
      })
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

const getVipExchangeCodeDetailThunk = createAsyncThunk(
  `${VIP_GIFT_EXCHANGE}/getVipExchangeCodeDetail`,

  async (
    { brandId, vipGiftSettingId, vipGiftExchangeCodeId },
    { rejectWithValue }
  ) => {
    try {
      const rsp = await getVipExchangeCodeDetail({
        brandId,
        vipGiftSettingId,
        vipGiftExchangeCodeId,
      })
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

const vipGiftExchangeSlice = createSlice({
  name: VIP_GIFT_EXCHANGE,
  initialState: vipGiftExchangeInitState,
  reducers: {
    resetVipGiftExchangeDetailModal: (state) => {
      state.vipExchangeRecordDetailRsp =
        vipGiftExchangeInitState.vipExchangeRecordDetailRsp
      state.vipExchangeCodeDetailRsp =
        vipGiftExchangeInitState.vipExchangeCodeDetailRsp
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === getVipExchangeRecordDetailThunk.fulfilled.type ||
        action.type === getVipExchangeRecordDetailThunk.rejected.type,
      (state, action) => {
        state.vipExchangeRecordDetailRsp = action.payload
        state.vipExchangeRecordDetailRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getVipExchangeCodeDetailThunk.fulfilled.type ||
        action.type === getVipExchangeCodeDetailThunk.rejected.type,
      (state, action) => {
        state.vipExchangeCodeDetailRsp = action.payload
        state.vipExchangeCodeDetailRsp.isFetch = true
      }
    )
  },
})

// 發送名單 tab 中的 發送紀錄
const vipExchangeRecordDetailSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE].vipExchangeRecordDetailRsp,
  memberOutlineSelector,
  (vipExchangeRecordDetailRsp, memberOutlineSelectorData) => {
    const {
      data: vipExchangeRecordDetailData,
      isFetch: vipExchangeRecordFetch,
    } = vipExchangeRecordDetailRsp
    const { memberOutlineFetch, ...memberOutlineData } =
      memberOutlineSelectorData

    const isReady = vipExchangeRecordFetch && memberOutlineFetch
    return {
      isReady,
      memberId: vipExchangeRecordDetailData.memberId ?? '',
      name: vipExchangeRecordDetailData.memberName ?? '',
      phone: vipExchangeRecordDetailData.memberCellPhone ?? '',
      level: vipExchangeRecordDetailData.memberLevel ?? '',
      ...memberOutlineData,
    }
  }
)

// 好禮序號 tab 中的 兌換紀錄
const vipExchangeCodeDetailSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE].vipExchangeCodeDetailRsp,
  memberOutlineSelector,
  (vipExchangeCodeDetailRsp, memberOutlineSelectorData) => {
    const { data: vipExchangeCodeDetailData, isFetch: vipExchangeCodeFetch } =
      vipExchangeCodeDetailRsp
    const { memberOutlineFetch, ...memberOutlineData } =
      memberOutlineSelectorData
    const isReady = vipExchangeCodeFetch && memberOutlineFetch
    return {
      isReady,
      memberId: vipExchangeCodeDetailData.memberId ?? '',
      name: vipExchangeCodeDetailData.memberName ?? '',
      phone: vipExchangeCodeDetailData.memberCellPhone ?? '',
      level: vipExchangeCodeDetailData.memberLevel ?? '',
      ...memberOutlineData,
    }
  }
)

export { vipExchangeRecordDetailSelector, vipExchangeCodeDetailSelector }
export { getVipExchangeRecordDetailThunk, getVipExchangeCodeDetailThunk }
export const { resetVipGiftExchangeDetailModal } = vipGiftExchangeSlice.actions

export default vipGiftExchangeSlice.reducer
