import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { Row } from 'react-bootstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { uploadFileApi } from 'api/pages/PointHistoryApi'
import { ReactComponent as Delete } from 'assets/images/modal/icon_modalClose.svg'
import withPermission from 'components/hocs/withPermission'
import { Table, NoDataIndication, Col, Button } from 'components/units'
import { StyleCard } from 'components/units/Card'
import PaginationInput from 'components/units/PaginationInput'
import {
  handleCloseToast,
  showTopToast,
  showUploadToast,
} from 'components/units/Toast'
import ToastContent from 'components/units/ToastContent'
import ToastFooter from 'components/units/ToastFooter'
import { COMPONENT_PERMISSIONS } from 'constant'
import { paginationOptions } from 'constant/common'
import { UPLOAD_STAGE } from 'constant/toast'
import { PointHistoryContext } from 'context/pageContext'
import { getRandomId } from 'helpers/common'
import {
  formatTextLeftStyle,
  formatNumberLeftStyle,
} from 'helpers/tableElFormat'
import { authAccountSelector } from 'store/auth/authSelector'
import { updateMaskOpen } from 'store/global/globalSlice'
import { resetMemberOutline } from 'store/member-outline/memberOutlineSlice'
import {
  getPointHistoryDetailThunk,
  pointHistoryDetailSelector,
  resetPointHistoryDetail,
} from 'store/point-history/pointHistorySlice'
import BatchUploadModal from './BatchUploadModal'
import {
  ToastSuccessBoxStyle,
  UploadLoadingBoxStyle,
} from './BatchUploadModalStyle'
import PointDetailModal from './PointDetailModal'
import PointHistoryListStyle from './PointHistoryListStyle'
import PointRecordModal from './PointRecordModal'
import UploadLeaveHintsModal from './UploadLeaveHintsModal'

/**
 * @description 點數歷程列表
 * @returns {JSX.Element}
 * @constructor
 */
export default function PointHistoryList({
  tableData = [],
  totalSize = 0,
  isLoadingTableData = false,
}) {
  const dispatch = useDispatch()
  const {
    getPointHistoryList,
    tableFilterCondition,
    setTableFilterConditions,
  } = useContext(PointHistoryContext)
  const { brandId } = useSelector(authAccountSelector)
  const { pointHistoryDetail } = useSelector(pointHistoryDetailSelector)
  const [isShowDetailModal, setIsShowDetailModal] = useState(false)
  const [isShowRecordModal, setIsShowRecordModal] = useState(false)
  const [isShowBatchUploadModal, setIsShowBatchUploadModal] = useState(false)
  const [isShowUploadLeaveHintsModal, setIsShowUploadLeaveHintsModal] =
    useState(false)
  const [isModalDataLoading, setIsModalDataLoading] = useState(false)

  const pointHistoryListColumns = [
    {
      dataField: 'no',
      text: 'No.',
      sort: true,
      headerStyle: {
        width: '2%',
      },
    },
    {
      dataField: 'createDateTime',
      text: '時間',
      sort: true,
      headerStyle: {
        width: '23%',
      },
    },
    {
      dataField: 'customId',
      text: '會員編號',
      sort: true,
      headerStyle: {
        width: '6%',
      },
    },
    {
      dataField: 'name',
      text: '會員',
      sort: false,
      headerStyle: {
        width: '12%',
      },
    },
    {
      dataField: 'description',
      text: '名稱',
      sort: false,
      headerStyle: {
        width: '27%',
      },
    },
    {
      dataField: 'pointGain',
      text: '點數異動',
      sort: true,
      style: (cell) => formatNumberLeftStyle({ cell }),
      headerStyle: formatTextLeftStyle({ width: '2%' }),
    },
    {
      dataField: 'creatorEmail',
      text: '補登者',
      sort: true,
      style: formatNumberLeftStyle(),
      headerStyle: formatTextLeftStyle({ width: '13%' }),
    },
  ]

  const handleGreyMask = (bool) => {
    dispatch(updateMaskOpen(bool))
  }

  const handleTableChange = (type, newState) => {
    const updateFields = {
      pagination: { p: newState.page, ps: newState.sizePerPage },
      sort: { sf: newState.sortField, so: newState.sortOrder },
    }
    setTableFilterConditions({ ...tableFilterCondition, ...updateFields[type] })
  }

  const handleClickRow = async ({ pointRecordId }) => {
    setIsShowDetailModal(true)
    setIsModalDataLoading(true)
    await dispatch(getPointHistoryDetailThunk({ brandId, pointRecordId }))
    setIsModalDataLoading(false)
  }

  const closePointDetailModal = () => {
    setIsShowDetailModal(false)
    dispatch(resetPointHistoryDetail())
    dispatch(resetMemberOutline())
  }

  const handleClickRecordPoint = () => {
    setIsShowRecordModal(true)
  }

  const handleClickBatchUpload = () => {
    setIsShowBatchUploadModal(true)
  }

  const handleConfirmLeaveUpload = () => {
    toast.dismiss()
    setIsShowUploadLeaveHintsModal(false)
    setIsShowBatchUploadModal(false)
  }

  const handleUploadCsvFile = async (file) => {
    const maxFileSize = 1024 * 1024 * 25 // 25MB
    if (file.size > maxFileSize) {
      showTopToast({
        config: { message: '檔案大小超過 25MB，請重新選擇' },
        type: 'error',
      })
    } else {
      const formData = new FormData()
      formData.append('BrandId', brandId)
      formData.append('ImportFile', file)
      const postponeTime = 5
      const toastId = getRandomId()

      const toastMsg = {
        module: 'pointHistory',
        content: file.name,
      }
      const preUploadToastConfig = {
        duration: postponeTime,
        type: UPLOAD_STAGE.PREPARE,
        message: toastMsg,
        toastId,
      }
      const uploadingToastConfig = {
        type: UPLOAD_STAGE.PROCESS,
        message: toastMsg,
        toastId,
      }
      const cancelToastConfig = {
        type: UPLOAD_STAGE.CANCEL,
        message: toastMsg,
        toastId: getRandomId(), // 因目前流程設計，cancel toastId 要獨立才能正確開啟
      }
      const uploadSetimeoutHandler = setTimeout(async () => {
        try {
          showUploadToast(uploadingToastConfig)
          const rsp = await uploadFileApi(formData)
          const { successAmount, failureAmount, failureFileUrl } =
            rsp?.data?.data || {}
          showUploadToast({
            type: UPLOAD_STAGE.COMPLETED,
            message: {
              ...toastMsg,
              content: (
                <ToastContent
                  successCount={successAmount}
                  failCount={failureAmount}
                />
              ),
            },
            footer: <ToastFooter reportLink={failureFileUrl} />,
            toastId,
          })
        } catch (e) {
          console.error('batch upload error', e)
          handleCloseToast(toastId)
        } finally {
          handleGreyMask(false)
          getPointHistoryList()
        }
      }, postponeTime * 1000)
      setIsShowBatchUploadModal(false)
      handleGreyMask(true)
      showUploadToast({
        ...preUploadToastConfig,
        onClose: () => {
          clearTimeout(uploadSetimeoutHandler)
          handleGreyMask(false)
          showUploadToast(cancelToastConfig)
        },
      })
    }
  }
  return (
    <PaginationProvider
      pagination={paginationFactory({
        ...paginationOptions,
        totalSize,
        page: tableFilterCondition.p,
        sizePerPage: tableFilterCondition.ps,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <PointHistoryListStyle>
          <StyleCard className="w-100 mt-4">
            <TableToolBar
              totalSize={paginationProps.totalSize}
              paginationProps={paginationProps}
              sizePerPage={paginationProps.sizePerPage}
              onClickBatchUpload={handleClickBatchUpload}
              onClickRecordPoint={handleClickRecordPoint}
            />
            <Table
              striped
              remote
              headerClasses="table-header"
              bodyClasses="paragraph"
              className="overflow-auto"
              keyField="no"
              data={tableData}
              columns={pointHistoryListColumns}
              sort={{
                dataField: tableFilterCondition.sf,
                order: tableFilterCondition.so,
              }}
              rowEvents={{
                onClick: (
                  ...args // args: event, row, rowIndex, columnIndex
                ) => handleClickRow({ pointRecordId: args[1].id }),
              }}
              onTableChange={handleTableChange}
              noDataIndication={
                <NoDataIndication
                  isLoading={isLoadingTableData}
                  message={isLoadingTableData ? '載入中...' : '尚無資料'}
                />
              }
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...paginationTableProps}
            />
            <div className="d-flex justify-content-center">
              <PaginationListStandalone
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...paginationProps}
              />
              <PaginationInput
                onPageChange={paginationProps.onPageChange}
                page={paginationProps.page}
                sizePerPage={paginationProps.sizePerPage}
                totalSize={paginationProps.totalSize}
              />
            </div>
          </StyleCard>
          <PointDetailModal
            isShowModal={isShowDetailModal}
            pointDetailModalInfo={pointHistoryDetail}
            closePointDetailModal={closePointDetailModal}
            isDefaultDataLoading={isModalDataLoading}
          />
          <PointRecordModal
            isShowModal={isShowRecordModal}
            setIsShowRecordModal={setIsShowRecordModal}
          />
          <BatchUploadModal
            isShowBatchUploadModal={isShowBatchUploadModal}
            setIsShowBatchUploadModal={setIsShowBatchUploadModal}
            onUploadCsvFile={handleUploadCsvFile}
          />
          <UploadLeaveHintsModal
            isShow={isShowUploadLeaveHintsModal}
            onConfirmLeave={handleConfirmLeaveUpload}
          />
        </PointHistoryListStyle>
      )}
    </PaginationProvider>
  )
}

/**
 * @description table 上方工具列 UI
 * @param totalSize
 * @param paginationProps
 * @param sizePerPage
 * @param onClick
 * @param onClickRecordPoint 補單點數事件
 * @returns {JSX.Element}
 * @constructor
 */
function TableToolBar({
  totalSize = 0,
  paginationProps = {},
  sizePerPage = 0,
  onClickBatchUpload = () => {},
  onClickRecordPoint = () => {},
}) {
  const { CREATE } = COMPONENT_PERMISSIONS.pointHistory
  const PermissionAddButton = withPermission(Button, CREATE)
  const PermissionBatchUploadButton = withPermission(Button, CREATE)

  return (
    <Row className="table-toolbar d-flex">
      <Col>
        <span>總項目：</span>
        <span className="total-size">{totalSize} 筆</span>
      </Col>
      <Col className="d-flex">
        <div className="ml-auto">
          <span className="mr-1">每頁</span>
          <SizePerPageDropdownStandalone
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...paginationProps}
            sizePerPage={`${sizePerPage} 筆`}
          />
        </div>
        <PermissionBatchUploadButton
          className="ml-3 mr-3"
          variant="outline-darkerGray"
          onClick={onClickBatchUpload}
          size="sm"
        >
          批次上傳
        </PermissionBatchUploadButton>
        <PermissionAddButton
          variant="outline-primary"
          onClick={onClickRecordPoint}
          size="sm"
        >
          補登點數
        </PermissionAddButton>
      </Col>
    </Row>
  )
}

function UploadingToast({ onCancelUpload = () => {} }) {
  return (
    <UploadLoadingBoxStyle>
      <span>點數歷程，批次上傳中</span>
      <div
        className="spinner-border text-primary cus-spinner-sty"
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
      <span onClick={onCancelUpload}>取消上傳</span>
    </UploadLoadingBoxStyle>
  )
}

function ToastUploadSuccess({
  onClose = () => {},
  successAmount = null,
  failureAmount = null,
  failureFileUrl = null,
}) {
  return (
    <ToastSuccessBoxStyle>
      <div className="toast-head">
        <h3 className="toast-title">點數歷程</h3>
        <Delete onClick={onClose} />
      </div>
      <p className="toast-content">批次上傳 完成</p>
      <p className="toast-content">
        {successAmount} 筆資料成功，{failureAmount} 筆資料
        <span className="toast-error-msg">失敗</span>
      </p>
      {failureAmount > 0 ? (
        <a
          className="ml-1 download-link"
          href={failureFileUrl}
          title="下載錯誤報告"
        >
          下載錯誤報告
        </a>
      ) : null}
    </ToastSuccessBoxStyle>
  )
}

PointHistoryList.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalSize: PropTypes.number,
  isLoadingTableData: PropTypes.bool,
  paginationProps: PropTypes.shape({
    page: PropTypes.number,
    sizePerPage: PropTypes.number,
    totalSize: PropTypes.number,
  }),
  paginationTableProps: PropTypes.shape({
    page: PropTypes.number,
  }),
  sizePerPage: PropTypes.number,
  onClickBatchUpload: PropTypes.func,
  onClickRecordPoint: PropTypes.func,
  onClickRow: PropTypes.func,
  onClickPagination: PropTypes.func,
  onClickSort: PropTypes.func,
  setIsShowMask: PropTypes.func,
  setIsShowBatchUploadModal: PropTypes.func,
  getPointHistoryList: PropTypes.func,
  onTableChange: PropTypes.func,
}

PointHistoryList.defaultProps = {
  totalSize: 0,
  isLoadingTableData: false,
  paginationProps: {},
  paginationTableProps: {},
  sizePerPage: 0,
  onClickBatchUpload: () => {},
  onClickRecordPoint: () => {},
  onClickRow: () => {},
  onClickPagination: () => {},
  onClickSort: () => {},
  setIsShowMask: () => {},
  setIsShowBatchUploadModal: () => {},
  getPointHistoryList: () => {},
  onTableChange: () => {},
}

TableToolBar.propTypes = {
  totalSize: PropTypes.number,
  paginationProps: PropTypes.shape({
    page: PropTypes.number,
    sizePerPage: PropTypes.number,
    totalSize: PropTypes.number,
  }),
  sizePerPage: PropTypes.number,
  onClickBatchUpload: PropTypes.func,
  onClickRecordPoint: PropTypes.func,
}

TableToolBar.defaultProps = {
  totalSize: 0,
  paginationProps: {},
  sizePerPage: 0,
  onClickBatchUpload: () => {},
  onClickRecordPoint: () => {},
}

UploadingToast.propTypes = {
  onCancelUpload: PropTypes.func,
}

UploadingToast.defaultProps = {
  onCancelUpload: () => {},
}

ToastUploadSuccess.propTypes = {
  onClose: PropTypes.func,
  successAmount: PropTypes.number,
  failureAmount: PropTypes.number,
  failureFileUrl: PropTypes.string,
}

ToastUploadSuccess.defaultProps = {
  onClose: () => {},
  successAmount: 0,
  failureAmount: 0,
  failureFileUrl: '',
}
