import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getEmailValidation,
  postAccount,
  getRoles,
  getShops,
  getAccount,
  patchAccount,
} from 'api/pages/AccountCreation'
import { ACCOUNT_SETTING } from 'store/constants'

export const getEmailValidationThunk = createAsyncThunk(
  `${ACCOUNT_SETTING}/getEmailValidation`,
  async ({ brandId, email, signal }, { rejectWithValue }) => {
    try {
      const response = await getEmailValidation(brandId, { email }, signal)
      return response
    } catch (error) {
      return rejectWithValue(error.data)
    }
  }
)

export const postAccountThunk = createAsyncThunk(
  `${ACCOUNT_SETTING}/postAccount`,
  async ({ brandId, payload }, { rejectWithValue }) => {
    try {
      const response = await postAccount(brandId, payload)
      return response
    } catch (error) {
      return rejectWithValue(error.data)
    }
  }
)

export const getRolesThunk = createAsyncThunk(
  `${ACCOUNT_SETTING}/getRoles`,
  async (brandId, { rejectWithValue }) => {
    try {
      const response = await getRoles(brandId)
      return response
    } catch (error) {
      return rejectWithValue(error.data)
    }
  }
)

export const getShopsThunk = createAsyncThunk(
  `${ACCOUNT_SETTING}/getShops`,
  async (brandId, { rejectWithValue }) => {
    try {
      const response = await getShops(brandId)
      return response
    } catch (error) {
      return rejectWithValue(error.data)
    }
  }
)

export const getAccountThunk = createAsyncThunk(
  `${ACCOUNT_SETTING}/getAccount`,
  async ({ brandId, accountId }, { rejectWithValue }) => {
    try {
      const response = await getAccount(brandId, accountId)
      return response
    } catch (error) {
      return rejectWithValue(error.data)
    }
  }
)

export const patchAccountThunk = createAsyncThunk(
  `${ACCOUNT_SETTING}/patchAccount`,
  async ({ brandId, accountId, payload }, { rejectWithValue }) => {
    try {
      const response = await patchAccount(brandId, accountId, payload)
      return response
    } catch (error) {
      return rejectWithValue(error.data)
    }
  }
)
