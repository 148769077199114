import * as signalR from '@microsoft/signalr'
import { getUrl } from 'api/ApiCommon'
import { getLocalStorage } from 'helpers/common'
import { SERIAL_BATCH_UPLOAD, AUTH } from 'store/constants'
import {
  updateSerialUploadProgress,
  resetSerialUploadProgress,
} from 'store/serial-batch-upload/serialBatchUploadSlice'

const createBatchUploadMiddleware = () => {
  const CONNECT_PATHS = {
    vipGift: 'setting/signalr/vip-gift-exchange-code',
    gift: 'setting/signalr/gift-exchange-code',
  }
  const instances = {
    vipGift: null,
    gift: null,
  }
  let isClientSideStop = false
  return (store) => (next) => (action) => {
    // 從 action payload 中取得要連線的 route，一次一個
    const { payload, type } = action
    const module = payload?.module || ''

    if (!module) return next(action)

    const { dispatch, getState } = store
    const { brandIndex, brands } = getState()[AUTH].authAccountData

    const route = `${getUrl(CONNECT_PATHS[module])}?brand_id=${
      brands[brandIndex].id
    }`
    const authorization = getLocalStorage('token')
      ? `${JSON.parse(getLocalStorage('token') || {})?.accessToken}`
      : ''

    const createConnect = (routeParam) => {
      const instance = new signalR.HubConnectionBuilder()
        .withUrl(routeParam, {
          accessTokenFactory: () => authorization,
        })
        .withAutomaticReconnect([0, 3000])
        .build()
      return instance
    }

    const handleConnectOn = (connectionParam) => {
      // 接收檔案處理進度
      connectionParam.on('ReceiveProgress', (message) => {
        dispatch(updateSerialUploadProgress({ module, data: message }))
      })
      // 檔案處理成功
      connectionParam.on('ReceiveSuccess', (message) => {
        dispatch(updateSerialUploadProgress({ module, data: message }))
        instances[module] = null
        isClientSideStop = true
        connectionParam.stop()
      })
      connectionParam.onclose(() => {
        if (isClientSideStop) {
          isClientSideStop = false
          return
        }
        dispatch(
          updateSerialUploadProgress({
            module,
            data: { uploadSuccess: false, isServerError: true },
          })
        )
      })
    }

    const handleConnect = (routeParam, moduleParam) => {
      try {
        const instance = createConnect(routeParam)
        instances[moduleParam] = instance
        instance.start()
        handleConnectOn(instance)
      } catch (e) {
        instances[moduleParam] = null
        dispatch(resetSerialUploadProgress({ module: moduleParam }))
      }
    }

    // 處理連線，該模組下還沒有 connection instance 就建立 connection 流程
    if (
      type === `${SERIAL_BATCH_UPLOAD}/startConnection` &&
      !instances[module]
    ) {
      handleConnect(route, module)
    }
    // 處理手動停止連線
    if (type === `${SERIAL_BATCH_UPLOAD}/stopConnection` && instances[module]) {
      isClientSideStop = true
      instances[module].stop()
      instances[module] = null
    }
    return next(action)
  }
}

export default createBatchUploadMiddleware
