import styled, { css } from 'styled-components'
import Button from 'react-bootstrap/Button'
import { StyleLoader } from './Loader'
import { colorObj, method } from 'assets/styles/Variable/Color'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/*  說明
    1.  按鈕樣式用的參數跟react-bootstrap的參數 variant 一樣
    2.  UI設計按鈕類型一共有下方btnTypes裡的項目
    3.  btnTypes裡的類型，需要參照colorObj裡的項目，有對應才會改變樣式
*/

const { colorHandle } = method

// 按鈕共幾種類型 (若與colorObj的顏色不匹配則也不會變化)
const btnTypes = Object.keys(colorObj)

// 按鈕共幾種尺寸
const btnSize = [
  {
    type: 'lg',
    minWidth: '120px',
    height: '40px',
    fontSize: '14px',
    iconWidth: '14px',
    iconHeight: '14px',
  },

  {
    type: 'md',
    minWidth: '84px',
    height: '32px',
    fontSize: '14px',
    iconWidth: '14px',
    iconHeight: '14px',
  },
  {
    type: 'sm',
    minWidth: '84px',
    height: '24px',
    fontSize: '14px',
    iconWidth: '14px',
    iconHeight: '14px',
  },
]

// Button type樣式處理
const handleTypeStyles = (typeArr, { colorType, colorAlpha }) => {
  let cssStyle = ''
  for (const type of typeArr) {
    cssStyle += `
            &.btn-${type},
            &.btn-${type}:hover{
              background-color: ${colorObj[type]};
              border:0;

              >svg{ 
                  path{ 
                      fill: #fff; 
                  }
              }
            }
            &.btn-${type}:disabled{
              opacity:1;
              background-color: ${colorHandle('dark', colorObj, 0.25)};
              border:0;
              color:${colorHandle('dark', colorObj, 0.5)};
              box-shadow:none;
              
              >svg{ 
                path{ 
                    fill: ${colorHandle('dark', colorObj, 0.5)};
                }
              }
            }
            &.btn-outline-${type},
            &.btn-outline-${type}:disabled{
                border-color: ${colorObj[type]};
                background-color:${colorObj.white};
                color:${
                  colorType
                    ? colorHandle(colorType, colorObj, colorAlpha)
                    : colorObj[type]
                };

                &:not(:disabled):not(.disabled):active {
                    background-color: ${colorObj.white};
                    color:${colorObj[type]};
                }
                >svg{ 
                    path{ 
                        fill: ${colorObj[type]}; 
                    }
                }
            }
        `
  }
  return css`
    ${cssStyle}
  `
}

// Button size樣式處理
const handleSizeStyles = (sizeArr, { size }) => {
  size = sizeArr.includes(size) ? size : 'lg'
  let cssStyle = ''
  let notSelectorCondition = ''
  let initHeight = ''
  let initfontSize = ''
  let initMinWidth = ''
  let initIconWidth = ''
  let initIconHeight = ''

  for (const sizeOption of sizeArr) {
    const { type, height, minWidth, fontSize, iconWidth, iconHeight } =
      sizeOption
    cssStyle += `
            &.btn-${type}{
                height:${height};
                line-height:${height};
                font-size:${fontSize};
                min-width:${minWidth};

                >svg,
                >img{ 
                  margin-right:4px;
                  width:${iconWidth};
                  height:${iconHeight};
                }
            }
        `
    notSelectorCondition += `.btn-${type},`
    if (type === size) {
      initHeight = height
      initMinWidth = minWidth
      initfontSize = fontSize
      initIconWidth = iconWidth
      initIconHeight = iconHeight
    }
  }
  notSelectorCondition = notSelectorCondition.substr(
    0,
    notSelectorCondition.length - 1
  )
  cssStyle += `
        :not(${notSelectorCondition}){
            height:${initHeight};
            font-size:${initfontSize};
            line-height:${initHeight};
            min-width:${initMinWidth};

            >svg,
            >img{ 
              margin-right:4px;
              width:${initIconWidth};
              height:${initIconHeight};
              overflow:visible;
            }
        }
    `
  return css`
    ${cssStyle}
  `
}
// Button 共用樣式
const buttonCommonStyles = css`
  &.btn {
    position: relative;
    padding: 0 16px;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: ${colorObj.primary};
    border-color: ${colorObj.primary};
    border-radius: 4px;

    &:disabled {
      opacity: 0.5;
      background-color: inherit;
      box-shadow: none;
    }
    &:hover {
      box-shadow: 0px 1px 2px 0px rgba(51, 51, 51, 0.25);
    }
    > svg {
      path {
        fill-opacity: 1;
      }
    }
  }
  &[class*='btn-outline'] {
    border-color: ${colorObj.primary};
    background-color: #fff;
    color: ${colorObj.primary};

    &:hover {
      background-color: ${colorObj.white};
      box-shadow: 0px 1px 2px 0px rgba(51, 51, 51, 0.25);
    }
    &:disabled {
      background-color: inherit;
      box-shadow: none;
    }
    > svg {
      path {
        fill: ${colorObj.primary};
      }
    }
  }
`
// Button 基礎樣式
const buttonBaseStyles = ({ colorType, colorAlpha, size }) => {
  return css`
    ${buttonCommonStyles};
    ${handleTypeStyles(btnTypes, { colorType, colorAlpha })}
    ${handleSizeStyles(btnSize, { size })}
  `
}

// 透明mask
const TransparentMask = styled.div`
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 23;
`

/**
 * Styled 按鈕元件，帶有自定義樣式和功能。
 *
 * @component
 *
 * @param {object} props - 元件的屬性。
 * @param {Object} props.loaderOption - 自定義加載器的選項。
 * @param {ReactNode} props.children - 按鈕內容。
 * @param {boolean} props.isLoading - 是否處於加載狀態。
 * @param {function(e: Event, helpers: { handleButtonLocalLoading: Function })} props.onClick - 點擊按鈕時觸發的函數
 *
 * @returns {ReactElement} React元素，呈現自定義樣式的按鈕。
 
 */
const StyleButton = styled((props) => {
  const {
    colorType, // eslint-disable-line no-unused-vars
    colorAlpha, // eslint-disable-line no-unused-vars
    loaderOption,
    children,
    isLoading, // button 外部傳入的 loading 狀態控制
    onClick,
    disabled = false,
    ...prop
  } = props

  const loaderColor =
    props.variant === 'primary' || !props.variant ? 'white' : colorType

  // button 內部的 loading 狀態控制
  const [isLocalLoading, setIsLocalLoading] = useState(isLoading ?? false)

  const handleButtonLocalLoading = (bool) => {
    setIsLocalLoading(bool)
  }
  const handleClick = (e) => {
    // 按下按鈕時，如需開啟按鈕loading狀態可使用 handleButtonLocalLoading(true)
    if (typeof onClick === 'function' && !isLocalLoading)
      return onClick(e, { handleButtonLocalLoading })
  }

  useEffect(() => {
    if (isLoading !== undefined && isLoading !== isLocalLoading)
      setIsLocalLoading(isLoading)
  }, [isLoading])
  return (
    <>
      {isLocalLoading && <TransparentMask />}
      <Button disabled={disabled} onClick={handleClick} {...prop}>
        <StyleLoader
          bgColor="rgba(0,0,0,0)"
          className="mr-2 position-relative"
          size="sm"
          colorType={loaderColor}
          {...loaderOption}
          isHide={!isLocalLoading}
        />
        {children}
      </Button>
    </>
  )
})`
  ${(props) => buttonBaseStyles(props)};
`

export { StyleButton }

StyleButton.propTypes = {
  loaderOption: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
}

StyleButton.defaultProps = {
  loaderOption: {},
  children: null,
  isLoading: false,
  onClick: () => {},
}
