import { color } from 'assets/styles/Variable/Color'

const modalType = {
  stop: 'stop',
}

const modalConfig = {
  [modalType.stop]: {
    title: '即將停用',
    confirm: '停用',
    close: '返回',
    content: ['停用後將無法復原，', '確定停用？'],
    variant: color.danger,
  },
}

export { modalType, modalConfig }
