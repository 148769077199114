import { importProductItems } from 'api/ApiMain'
import { getRandomId, removeToast } from 'helpers/common'
import { showUploadToast } from 'components/units/Toast'
import { UPLOAD_STAGE } from 'constant/toast'
import ToastContent from 'components/units/ToastContent'
import ToastFooter from 'components/units/ToastFooter'

const handleUploadCsv =
  ({
    brandId,
    pointProductRuleId,
    pointProductRuleName,
    handleSearch,
    setUploadModal,
    setMask,
  }) =>
  async (file) => {
    const bodyFormData = new FormData()
    const toastMsg = {
      module: 'productList',
      title: `${pointProductRuleName}`,
      content: file.name,
    }
    bodyFormData.append('ImportFile', file)
    setMask(true)

    const uploadingToastConfig = {
      type: UPLOAD_STAGE.PROCESS,
      message: toastMsg,
      toastId: getRandomId(),
    }
    // 5 秒後顯示上傳中的toast，並且發送上傳請求
    const uploadTimeoutHandler = setTimeout(async () => {
      showUploadToast(uploadingToastConfig)
      try {
        const rsp = await importProductItems(
          brandId,
          pointProductRuleId,
          bodyFormData
        )
        const { successAmount, failureAmount, failureFileUrl } = rsp?.data?.data

        const completedToastConfig = {
          type: UPLOAD_STAGE.COMPLETED,
          message: {
            ...toastMsg,
            content: (
              <ToastContent
                successCount={successAmount}
                failCount={failureAmount}
              />
            ),
          },
          footer: <ToastFooter reportLink={failureFileUrl} />,
          toastId: getRandomId(),
        }
        removeToast(uploadingToastConfig.toastId)
        showUploadToast(completedToastConfig)
        await handleSearch()
        setMask(false)
      } catch (error) {
        removeToast(uploadingToastConfig.toastId)
        setMask(false)
      }
    }, 5100)

    const cancelToastConfig = {
      type: UPLOAD_STAGE.CANCEL,
      message: toastMsg,
      toastId: getRandomId(),
    }

    const prepareToastConfig = {
      duration: 5,
      type: UPLOAD_STAGE.PREPARE,
      message: toastMsg,
      onClose: () => {
        clearTimeout(uploadTimeoutHandler)
        setMask(false)
        showUploadToast(cancelToastConfig)
      },
      toastId: getRandomId(),
    }
    setUploadModal(false)
    showUploadToast(prepareToastConfig)
  }

const handleDefaultSubmit = (event) => {
  event.preventDefault()
  event.stopPropagation()
}

export { handleDefaultSubmit, handleUploadCsv }
