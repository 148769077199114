import { createSelector, createSlice } from '@reduxjs/toolkit'
import { GIFT_LIST } from 'store/constants'
import {
  getValidateCodeSettingThunk,
  getVipGiftExchangeRecordListThunk,
  getGiftExchangeRecordListThunk,
  getVipGiftVerificationListThunk,
  getGiftExchangeRecordDetailThunk,
  getVipGiftExchangeRecordDetailThunk,
  getGiftDeliveryDetailThunk,
  getVipGiftVerificationDetailThunk,
  patchVipGiftVerificationThunk,
  getExchangeGiftVerificationListThunk,
  getExchangeGiftVerificationDetailThunk,
  patchGiftVerificationThunk,
} from './giftListAsync'

const formatString = ({ exchange, get }) => {
  if (exchange && get) return `${exchange} - ${get}`
  if (exchange) return exchange
  if (get) return get
  return null
}

export const giftListInitState = {
  validateCodeSettingRsp: {
    data: {
      liffEnabled: false,
      source: [],
      shop: [],
      consumeChannel: [],
    },
  },
  vipGiftExchangeRecordListRsp: {
    data: {
      sort: {},
      totalSize: 0,
      rows: [],
    },
  },
  giftExchangeRecordListRsp: {
    data: {
      sort: {},
      totalSize: 0,
      rows: [],
    },
  },
  vipGiftVerificationListRsp: {
    data: {
      sort: {},
      totalSize: 0,
      rows: [],
    },
  },
  vipGiftVerificationResultRsp: {
    data: { totalCount: 0, successCount: 0, failedCount: 0 },
    isFetch: false,
  },
  exchangeGiftVerificationRsp: {
    data: { totalCount: 0, successCount: 0, failedCount: 0 },
    isFetch: false,
  },
  giftExchangeRecordDetailRsp: {
    data: {
      giftRecordObjId: '',
      status: 0,
      statusDescription: '',
      exchangedDate: '',
      exchangedTime: '',
      exchangedWay: '',
      gotGiftValidPeriod: '',
      gotGiftTime: '',
      gotGiftWay: '',
      gotGiftPlace: '',
      customClientId: '',
      memberName: '',
      memberCellPhone: '',
      memberLevel: '',
      giftCode: '',
      giftCustomId: '',
      giftName: '',
      giftColor: '',
      giftSize: '',
      giftQuantity: 0,
      exchangedExpense: '',
    },
    code: null,
    msg: '',
    errors: {},
    isFetch: false,
  },
  vipGiftExchangeRecordDetailRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      giftRecordObjId: '',
      status: 0,
      statusDescription: '',
      sendTime: '',
      gotGiftValidPeriod: '',
      gotGiftDate: '',
      gotGiftTime: '',
      gotGiftWay: '',
      gotGiftPlace: '',
      customClientId: '',
      memberName: '',
      memberCellPhone: '',
      memberLevel: '',
      giftCode: '',
      type: '',
      contentType: 1,
      contentTypeText: '',
      name: '',
      content: '',
      productCustomId: '',
      productName: '',
      productColor: '',
      productSize: '',
    },
    isFetch: false,
  },
  giftDeliveryDetailRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      giftRecordObjId: '',
      status: 0,
      statusDescription: '',
      exchangedDate: '',
      exchangedTime: '',
      exchangedWay: '',
      exchangedExpense: '',
      customClientId: '',
      memberName: '',
      memberCellPhone: '',
      memberLevel: '',
      giftCode: '',
      giftCustomId: '',
      giftName: '',
      giftColor: '',
      giftSize: '',
      giftQuantity: 0,
      recipientName: '',
      recipientCellPhone: '',
      recipientCity: {
        optionId: '',
        code: '',
        name: '',
      },
      recipientDist: {
        optionId: '',
        code: '',
        name: '',
      },
      recipientAddress: '',
      deliveryNote: '',
      whetherToShip: true,
      shippingUpdateTime: '',
      shippingNumber: '',
    },
    isFetch: false,
  },
  vipGiftVerificationDetailRsp: {
    data: {
      giftRecordObjId: '',
      status: 0,
      statusDescription: '',
      exchangedDate: '',
      exchangedTime: '',
      exchangedWay: '',
      gotGiftValidPeriod: '',
      gotGiftTime: '',
      gotGiftWay: '',
      gotGiftPlace: '',
      customClientId: '',
      memberName: '',
      memberCellPhone: '',
      memberLevel: '',
      giftCode: '',
      giftCustomId: '',
      giftName: '',
      giftColor: '',
      giftSize: '',
      giftQuantity: 0,
      exchangedExpense: '',
    },
    code: null,
    msg: '',
    errors: {},
    isFetch: false,
  },
  exchangeGiftVerificationListRsp: {
    data: {},
  },
  exchangeGiftVerificationDetailRsp: {
    data: {
      giftRecordObjId: '',
      status: 0,
      statusDescription: '',
      exchangedDate: '',
      exchangedTime: '',
      exchangedWay: '',
      gotGiftValidPeriod: '',
      gotGiftTime: '',
      gotGiftWay: '',
      gotGiftPlace: '',
      customClientId: '',
      memberName: '',
      memberCellPhone: '',
      memberLevel: '',
      giftCode: '',
      giftCustomId: '',
      giftName: '',
      giftColor: '',
      giftSize: '',
      giftQuantity: 0,
      exchangedExpense: '',
    },
    isFetch: false,
  },
}

const giftListSlice = createSlice({
  name: GIFT_LIST,
  initialState: giftListInitState,
  reducers: {
    resetDetailModal: (state) => {
      return {
        ...state,
        vipGiftVerificationDetailRsp:
          giftListInitState.vipGiftVerificationDetailRsp,
        giftExchangeRecordDetailRsp:
          giftListInitState.giftExchangeRecordDetailRsp,
        vipGiftExchangeRecordDetailRsp:
          giftListInitState.vipGiftExchangeRecordDetailRsp,
        giftDeliveryDetailRsp: giftListInitState.giftDeliveryDetailRsp,
      }
    },
    resetVerificationResult: (state) => {
      return {
        ...state,
        vipGiftVerificationResultRsp:
          giftListInitState.vipGiftVerificationResultRsp,
        exchangeGiftVerificationRsp:
          giftListInitState.exchangeGiftVerificationRsp,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === getValidateCodeSettingThunk.fulfilled.type ||
        action.type === getValidateCodeSettingThunk.rejected.type,
      (state, action) => {
        state.validateCodeSettingRsp = action.payload
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getVipGiftExchangeRecordListThunk.fulfilled.type ||
        action.type === getVipGiftExchangeRecordListThunk.rejected.type,
      (state, action) => {
        state.vipGiftExchangeRecordListRsp = action.payload
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getGiftExchangeRecordListThunk.fulfilled.type ||
        action.type === getGiftExchangeRecordListThunk.rejected.type,
      (state, action) => {
        state.giftExchangeRecordListRsp = action.payload
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getVipGiftVerificationListThunk.fulfilled.type ||
        action.type === getVipGiftVerificationListThunk.rejected.type,
      (state, action) => {
        state.vipGiftVerificationListRsp = action.payload
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getExchangeGiftVerificationListThunk.fulfilled.type ||
        action.type === getExchangeGiftVerificationListThunk.rejected.type,
      (state, action) => {
        state.exchangeGiftVerificationListRsp = action.payload
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === patchVipGiftVerificationThunk.fulfilled.type ||
        action.type === patchVipGiftVerificationThunk.rejected.type,
      (state, action) => {
        state.vipGiftVerificationResultRsp = {
          ...action.payload,
          isFetch: true,
        }
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === patchGiftVerificationThunk.fulfilled.type ||
        action.type === patchGiftVerificationThunk.rejected.type,
      (state, action) => {
        state.exchangeGiftVerificationRsp = {
          ...action.payload,
          isFetch: true,
        }
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getGiftExchangeRecordDetailThunk.fulfilled.type ||
        action.type === getGiftExchangeRecordDetailThunk.rejected.type,
      (state, action) => {
        state.giftExchangeRecordDetailRsp = action.payload
        state.giftExchangeRecordDetailRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getVipGiftExchangeRecordDetailThunk.fulfilled.type ||
        action.type === getVipGiftExchangeRecordDetailThunk.rejected.type,
      (state, action) => {
        state.vipGiftExchangeRecordDetailRsp = action.payload
        state.vipGiftExchangeRecordDetailRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getGiftDeliveryDetailThunk.fulfilled.type ||
        action.type === getGiftDeliveryDetailThunk.rejected.type,
      (state, action) => {
        state.giftDeliveryDetailRsp = action.payload
        state.giftDeliveryDetailRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getVipGiftVerificationDetailThunk.fulfilled.type ||
        action.type === getVipGiftVerificationDetailThunk.rejected.type,
      (state, action) => {
        state.vipGiftVerificationDetailRsp = action.payload
        state.vipGiftVerificationDetailRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getExchangeGiftVerificationDetailThunk.fulfilled.type ||
        action.type === getExchangeGiftVerificationDetailThunk.rejected.type,
      (state, action) => {
        state.exchangeGiftVerificationDetailRsp = action.payload
        state.exchangeGiftVerificationDetailRsp.isFetch = true
      }
    )
  },
})

// 好禮核銷 核銷設定資訊
const validateCodeSettingSelector = createSelector(
  (state) => state[GIFT_LIST].validateCodeSettingRsp,
  (validateCodeSettingRsp) => {
    const { data } = validateCodeSettingRsp
    const { liffEnabled } = data || {}
    const source = data?.source || []
    const shop = data?.shop || []
    const consumeChannel = data?.consumeChannel || []

    const sourceList = source.length
      ? source.map((item) => ({
          text: item.name,
          value: item.code,
        }))
      : []

    const shopList = shop.length
      ? shop.map((item) => ({
          text: item.shopName,
          value: item.shopId,
        }))
      : []

    const consumeChannelList = consumeChannel.length
      ? consumeChannel.map((item) => ({
          text: item,
          value: item,
        }))
      : []

    return {
      isLiffEnabled: liffEnabled,
      sourceList,
      shopList,
      consumeChannelList,
    }
  }
)

// 專屬好禮 tab 總列表
const vipGiftExchangeRecordListSelector = createSelector(
  (state) => state[GIFT_LIST].vipGiftExchangeRecordListRsp,
  (vipGiftExchangeRecordListRsp) => {
    const rows = vipGiftExchangeRecordListRsp?.data?.rows || []
    const totalSize = vipGiftExchangeRecordListRsp?.data?.totalSize || 0

    const list = rows.map((item) => ({
      key: item?.giftRecordObjId,
      giftRecordObjId: item?.giftRecordObjId,
      time: item?.exchangedDate,
      sendTime: item?.sendDate,
      customClientId: item?.customClientId,
      receiveWay: item?.gotGiftWay,
      member: item?.memberName,
      phone: item?.memberCellPhone,
      name: item?.name,
      status: item?.status,
      statusDescription: item?.statusDescription,
    }))
    return {
      list,
      totalSize,
    }
  }
)

// 贈品兌換 tab 總列表
const giftExchangeRecordListSelector = createSelector(
  (state) => state[GIFT_LIST].giftExchangeRecordListRsp,
  (giftExchangeRecordListRsp) => {
    const rows = giftExchangeRecordListRsp?.data?.rows || []
    const totalSize = giftExchangeRecordListRsp?.data?.totalSize || 0

    const list = rows.map((item) => ({
      key: item?.giftRecordObjId,
      giftRecordObjId: item?.giftRecordObjId,
      exchangedTime: item?.exchangedDate,
      customClientId: item?.customClientId,
      exchangeAndReceive: formatString({
        exchange: item?.exchangedWay,
        get: item?.gotGiftWay,
      }),
      member: item?.memberName,
      phone: item?.memberCellPhone,
      giftInfo: {
        name: item?.giftName,
        color: item?.giftColor,
        size: item?.giftSize,
        customId: item?.giftCustomId,
      },
      count: item?.giftQuantity,
      method: item?.exchangedExpense,
      status: item?.status,
      statusDescription: item?.statusDescription,
    }))
    return {
      list,
      totalSize,
    }
  }
)

// 好禮核銷 tab 總列表
const vipGiftVerificationListSelector = createSelector(
  (state) => state[GIFT_LIST].vipGiftVerificationListRsp,
  (vipGiftVerificationListRsp) => {
    const rows = vipGiftVerificationListRsp?.data?.rows || []
    const totalSize = vipGiftVerificationListRsp?.data?.totalSize || 0

    const list = rows.map((item) => ({
      key: item?.giftRecordObjId,
      giftRecordObjId: item?.giftRecordObjId,
      sendTime: item?.sendDate,
      customClientId: item?.customClientId,
      receiveWay: item?.gotGiftWay,
      member: item?.memberName,
      phone: item?.memberCellPhone,
      name: item?.name,
      content: item?.content,
      status: item?.status,
      statusDescription: item?.statusDescription,
    }))
    return {
      list,
      totalSize,
    }
  }
)

// 贈品核銷 tab 總列表
const exchangeGiftVerificationListSelector = createSelector(
  (state) => state[GIFT_LIST].exchangeGiftVerificationListRsp,
  (exchangeGiftVerificationListRsp) => {
    const rows = exchangeGiftVerificationListRsp?.data?.rows || []
    const totalSize = exchangeGiftVerificationListRsp?.data?.totalSize || 0
    const list = rows.map((item) => ({
      key: item?.giftRecordObjId,
      giftRecordObjId: item?.giftRecordObjId,
      exchangedDate: item?.exchangedDate,
      customClientId: item?.customClientId,
      exchangeAndReceive: formatString({
        exchange: item?.exchangedWay,
        get: item?.gotGiftWay,
      }),
      giftInfo: {
        name: item?.giftName,
        color: item?.giftColor,
        size: item?.giftSize,
        customId: item?.giftCustomId,
      },
      memberName: item?.memberName,
      memberCellPhone: item?.memberCellPhone,
      status: item?.status,
      exchangedExpense: item?.exchangedExpense,
      statusDescription: item?.statusDescription,
    }))
    return {
      list,
      totalSize,
    }
  }
)

// 贈品兌換 tab 列表詳細頁（兌換紀錄）
const giftExchangeRecordDetailSelector = createSelector(
  (state) => state[GIFT_LIST].giftExchangeRecordDetailRsp,
  (giftExchangeRecordDetailRsp) => {
    const { data, isFetch } = giftExchangeRecordDetailRsp
    const emptyField = '-'

    return {
      isFetch,
      orderNumber: data?.giftRecordObjId || emptyField,
      status: data?.status ?? emptyField,
      statusDescription: data?.statusDescription || emptyField,
      time: data?.exchangedTime || emptyField,
      type: data?.exchangedWay || emptyField,
      gotType: data?.exchangedExpense || emptyField,
      giftType: data?.gotGiftWay || emptyField,
      gotGiftValidPeriod: data?.gotGiftValidPeriod || emptyField,
      gotTime: data?.gotGiftTime || emptyField,
      gotPlace: data?.gotGiftPlace || emptyField,
      giftCode: data?.giftCode || emptyField,
      customClientId: data?.customClientId || emptyField,
      member: data?.memberName || emptyField,
      phone: data?.memberCellPhone || emptyField,
      level: data?.memberLevel || emptyField,
      customId: data?.giftCustomId || emptyField,
      giftName: data?.giftName || emptyField,
      giftColor: data?.giftColor || emptyField,
      giftSize: data?.giftSize || emptyField,
      giftCount: data?.giftQuantity || emptyField,
    }
  }
)

// 專屬好禮 tab 列表詳細頁（發送紀錄）
const vipGiftExchangeRecordDetailSelector = createSelector(
  (state) => state[GIFT_LIST].vipGiftExchangeRecordDetailRsp,
  (vipGiftExchangeRecordDetailRsp) => {
    const { data, isFetch } = vipGiftExchangeRecordDetailRsp
    const emptyField = '-'
    return {
      isFetch,
      status: data?.status ?? emptyField,
      statusDescription: data?.statusDescription || emptyField,
      sendTime: data?.sendTime || emptyField,
      gotTime: data?.gotGiftTime || emptyField,
      receiveWay: data?.gotGiftWay || emptyField,
      gotGiftValidPeriod: data?.gotGiftValidPeriod || emptyField,
      receiveChannel: data?.gotGiftPlace || emptyField,
      customClientId: data?.customClientId || emptyField,
      member: data?.memberName || emptyField,
      phone: data?.memberCellPhone || emptyField,
      level: data?.memberLevel || emptyField,
      code: data?.giftCode || emptyField,
      type: data?.type || emptyField,
      name: data?.name || emptyField,
      content: data?.contentTypeText || emptyField,
      giftType: data?.contentType,
      giftContent: data?.content || emptyField,
      customId: data?.productCustomId || emptyField,
      giftName: data?.productName || emptyField,
      giftColor: data?.productColor || emptyField,
      giftSize: data?.productSize || emptyField,
    }
  }
)

// 出貨名單 tab 列表詳細頁(出貨詳情)
const giftDeliveryDetailSelector = createSelector(
  (state) => state[GIFT_LIST].giftDeliveryDetailRsp,
  (giftDeliveryDetailRsp) => {
    const { data, isFetch } = giftDeliveryDetailRsp
    const emptyField = '-'
    return {
      isFetch,

      // modalData
      giftRecordObjId: data?.giftRecordObjId || emptyField,
      exchangedTime: data?.exchangedDate || emptyField,
      exchangedWay: data?.exchangedWay || emptyField,
      exchangedExpense: data?.exchangedExpense || emptyField,
      customClientId: data?.customClientId || emptyField,
      memberName: data?.memberName || emptyField,
      memberCellPhone: data?.memberCellPhone || emptyField,
      memberLevel: data?.memberLevel || emptyField,
      giftCustomId: data?.giftCustomId || emptyField,
      giftName: data?.giftName || emptyField,
      giftColor: data?.giftColor || emptyField,
      giftSize: data?.giftSize || emptyField,
      giftQuantity: data?.giftQuantity || emptyField,

      // formData
      recipientName: data?.recipientName || '',
      recipientCellPhone: data?.recipientCellPhone || '',
      recipientCity: data?.recipientCity || {},
      recipientDist: data?.recipientDist || {},
      recipientAddress: data?.recipientAddress || '',
      deliveryNote: data?.deliveryNote || '',
      whetherToShip: data?.whetherToShip || false,
      shippingUpdateTime: data?.shippingUpdateTime || '',
      shippingNumber: data?.shippingNumber || '',
    }
  }
)

// 好禮核銷 tab 列表詳細頁（兌換紀錄）
const vipGiftVerificationDetailSelector = createSelector(
  (state) => state[GIFT_LIST].vipGiftVerificationDetailRsp,
  (vipGiftVerificationDetailRsp) => {
    const { isFetch } = vipGiftVerificationDetailRsp
    const emptyField = '-'

    return {
      isFetch,
      status: vipGiftVerificationDetailRsp?.status ?? emptyField,
      statusDescription:
        vipGiftVerificationDetailRsp?.statusDescription || emptyField,
      sendTime: vipGiftVerificationDetailRsp?.sendTime || emptyField,
      gotTime: vipGiftVerificationDetailRsp?.gotGiftTime || emptyField,
      receiveWay: vipGiftVerificationDetailRsp?.gotGiftWay || emptyField,
      gotGiftValidPeriod:
        vipGiftVerificationDetailRsp?.gotGiftValidPeriod || emptyField,
      receiveChannel: vipGiftVerificationDetailRsp?.gotGiftPlace || emptyField,
      customClientId:
        vipGiftVerificationDetailRsp?.customClientId || emptyField,
      member: vipGiftVerificationDetailRsp?.memberName || emptyField,
      phone: vipGiftVerificationDetailRsp?.memberCellPhone || emptyField,
      level: vipGiftVerificationDetailRsp?.memberLevel || emptyField,
      code: vipGiftVerificationDetailRsp?.giftCode || emptyField,
      type: vipGiftVerificationDetailRsp?.type || emptyField,
      name: vipGiftVerificationDetailRsp?.name || emptyField,
      content: vipGiftVerificationDetailRsp?.contentTypeText || emptyField,
      giftType: vipGiftVerificationDetailRsp?.contentType,
      giftContent: vipGiftVerificationDetailRsp?.content || emptyField,
      customId: vipGiftVerificationDetailRsp?.productCustomId || emptyField,
      giftName: vipGiftVerificationDetailRsp?.productName || emptyField,
      giftColor: vipGiftVerificationDetailRsp?.productColor || emptyField,
      giftSize: vipGiftVerificationDetailRsp?.productSize || emptyField,
    }
  }
)

// 贈品核銷 tab 列表詳細頁（兌換紀錄）
const exchangeGiftVerificationDetailSelector = createSelector(
  (state) => state[GIFT_LIST].exchangeGiftVerificationDetailRsp,
  (exchangeGiftVerificationDetailRsp) => {
    const { isFetch } = exchangeGiftVerificationDetailRsp
    const emptyField = '-'

    return {
      isFetch,
      orderNumber:
        exchangeGiftVerificationDetailRsp?.giftRecordObjId || emptyField,
      status: exchangeGiftVerificationDetailRsp?.status ?? emptyField,
      statusDescription:
        exchangeGiftVerificationDetailRsp?.statusDescription || emptyField,
      time: exchangeGiftVerificationDetailRsp?.exchangedTime || emptyField,
      type: exchangeGiftVerificationDetailRsp?.exchangedWay || emptyField,
      gotType:
        exchangeGiftVerificationDetailRsp?.exchangedExpense || emptyField,
      giftType: exchangeGiftVerificationDetailRsp?.gotGiftWay || emptyField,
      gotGiftValidPeriod:
        exchangeGiftVerificationDetailRsp?.gotGiftValidPeriod || emptyField,
      gotTime: exchangeGiftVerificationDetailRsp?.gotGiftTime || emptyField,
      gotPlace: exchangeGiftVerificationDetailRsp?.gotGiftPlace || emptyField,
      giftCode: exchangeGiftVerificationDetailRsp?.giftCode || emptyField,
      customClientId:
        exchangeGiftVerificationDetailRsp?.customClientId || emptyField,
      member: exchangeGiftVerificationDetailRsp?.memberName || emptyField,
      phone: exchangeGiftVerificationDetailRsp?.memberCellPhone || emptyField,
      level: exchangeGiftVerificationDetailRsp?.memberLevel || emptyField,
      customId: exchangeGiftVerificationDetailRsp?.giftCustomId || emptyField,
      giftName: exchangeGiftVerificationDetailRsp?.giftName || emptyField,
      giftColor: exchangeGiftVerificationDetailRsp?.giftColor || emptyField,
      giftSize: exchangeGiftVerificationDetailRsp?.giftSize || emptyField,
      giftCount: exchangeGiftVerificationDetailRsp?.giftQuantity || emptyField,
    }
  }
)

// 好禮核銷 核銷結果
const vipGiftVerificationResultSelector = createSelector(
  (state) => state[GIFT_LIST].vipGiftVerificationResultRsp,
  (vipGiftVerificationResultRsp) => {
    const { data, isFetch } = vipGiftVerificationResultRsp
    return {
      isFetch,
      totalCount: data?.totalCount || 0,
      successCount: data?.successCount || 0,
      failedCount: data?.failedCount || 0,
    }
  }
)

// 贈品核銷 核銷結果
const exchangeGiftVerificationResultSelector = createSelector(
  (state) => state[GIFT_LIST].exchangeGiftVerificationRsp,
  (exchangeGiftVerificationRsp) => {
    const { data, isFetch } = exchangeGiftVerificationRsp
    return {
      isFetch,
      totalCount: data?.totalCount || 0,
      successCount: data?.successCount || 0,
      failedCount: data?.failedCount || 0,
    }
  }
)

export {
  validateCodeSettingSelector,
  vipGiftExchangeRecordListSelector,
  giftExchangeRecordListSelector,
  vipGiftVerificationListSelector,
  vipGiftExchangeRecordDetailSelector,
  giftExchangeRecordDetailSelector,
  giftDeliveryDetailSelector,
  vipGiftVerificationDetailSelector,
  exchangeGiftVerificationListSelector,
  exchangeGiftVerificationDetailSelector,
  vipGiftVerificationResultSelector,
  exchangeGiftVerificationResultSelector,
}
export const { resetDetailModal, resetVerificationResult } =
  giftListSlice.actions

export default giftListSlice.reducer
