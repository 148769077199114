import { useCallback, useRef, useState } from 'react'
import {
  clearSessionStorage,
  getLocalStorage,
  getSessionStorage,
  isJSON,
  setSessionStorage,
} from 'helpers/common'
import addressData from 'constant/json/address.json'
import addressDataKarihome from 'constant/json/addresskarihome.json'

/*  說明
    1.  處理表單欄位的事件處理、取得欄位值
        ※ 固定欄位、彈性欄位、客制欄位也可以一併處理
*/

let addressDataInfo = addressData

const useField = (initialState = {}, formIsTriggerValidate) => {
  const homeData = getLocalStorage('homeData')
  // 卡洛塔妮專案特殊處理
  if (
    homeData &&
    JSON.parse(homeData)?.idListData[0]?.brandMenuItems?.[0]?.id === 90
  ) {
    addressDataInfo = addressDataKarihome
  }
  // 其他品牌地址
  else {
    addressDataInfo = addressData
  }
  const [inputValues, setInputValues] = useState(initialState)
  // 固定欄位中，地址的City、Dist資料(Dist欄位因與City欄位有相依關係，不能獨立存在，因此需特別處理)
  const [fixFieldsDataDistState, setFixFieldsDataDistState] = useState({
    options: [],
    selectOption: {
      isClearSelectedValue: false,
    },
  })
  const initInputValuesRef = useRef({})
  const initOptinoalInputValuesRef = useRef({})
  const initCustomizedInputValuesRef = useRef({})
  const initExpandInputValuesRef = useRef({})

  // 獲得地址鄉鎮市區的options
  const getDistOptions = useCallback((cityValue) => {
    const options = []
    if (cityValue) {
      const citySelected = addressDataInfo.filter(
        (item) => item.Code === cityValue.code
      )[0]
      if (citySelected) {
        const { Options } = citySelected
        for (const item in Options) {
          const { Id, Code, Name } = Options[item]
          options.push({
            id: Id,
            code: Code,
            name: Name,
          })
        }
      }
    }
    return options
  }, [])

  // 處理被選取 的checked值
  const handleChecked = (
    list,
    categoryPrevInputValueData,
    expandGroupIndex
  ) => {
    if (Array.isArray(list)) {
      const arr = []
      for (const item of list) {
        const { code, name, id, selected, combineFields } = item
        const obj = {
          code,
          name,
          id,
          selected,
        }
        if (combineFields) {
          obj.combineFields = []
          for (const [
            combineFieldIndex,
            combineFieldItem,
          ] of combineFields.entries()) {
            let { name: combineFieldName, value } = combineFieldItem
            combineFieldName =
              expandGroupIndex !== undefined
                ? `${combineFieldName}-expand-${expandGroupIndex}`
                : combineFieldName
            const categoryPrevCombineFields =
              categoryPrevInputValueData?.filter(
                (item) => item.combineFields && item.combineFields.length > 0
              )?.[0]?.combineFields
            const disabledCombineFields = getSessionStorage(
              'disabledCombineFields'
            )

            let combineFieldsValue =
              // undefined表示為初次進入畫面
              categoryPrevInputValueData === undefined ? value : ''
            if (categoryPrevCombineFields) {
              combineFieldsValue =
                categoryPrevCombineFields[combineFieldIndex][combineFieldName]
            }
            let disabledCombineFieldsData = null
            if (disabledCombineFields) {
              disabledCombineFieldsData = JSON.parse(disabledCombineFields)
              if (disabledCombineFieldsData?.[combineFieldName]) {
                combineFieldsValue = ''
              }
            }

            obj.combineFields.push({
              [combineFieldName]: combineFieldsValue ?? '',
            })
          }
        }
        if (selected) {
          arr.push(obj)
        }
      }
      return arr
    }
    return []
  }
  // 處理checkbox中有combineFields的欄位，在初次進入頁面時，須reset sessionStorage，因是用此作為控制欄位的disabled
  const handleCombineFieldsDisabled = () => {
    const disabledCombineFields = getSessionStorage('disabledCombineFields')
    if (disabledCombineFields) {
      clearSessionStorage('disabledCombineFields')
    }
  }
  // 統一處理彈性欄位、客制欄位中，combineFields欄位
  const handleCombineFieldsForOptionalAndCustomized = (
    dataFieldType,
    fieldType,
    fieldTypeSwitchProps,
    options,
    name,
    isRequired
  ) => {
    switch (fieldType) {
      case 'checkbox':
      case 'radio':
        fieldTypeSwitchProps = {
          ...fieldTypeSwitchProps,
          inline: true,
          onChange: (e) => {
            onChangeField(
              {
                target: e.target,
                options,
              },
              fieldTypeSwitchProps.fieldType
            )
          },
        }
        if (fieldType === 'checkbox') {
          // required的控制(因只要有一個勾選其他checkbox的required應設為false，否則會變成所有都要勾選)
          if (isRequired) {
            if (inputValues?.[dataFieldType]?.[name]?.length > 0) {
              fieldTypeSwitchProps.formControlOption.required = false
            } else {
              fieldTypeSwitchProps.formControlOption.required = true
            }
          }
          /* 若有combineFields欄位 */
          const deepCloneOptions = JSON.parse(JSON.stringify(options))

          let combinefieldCategoryInputValue = []
          if (inputValues?.[dataFieldType]?.[name]) {
            combinefieldCategoryInputValue = JSON.parse(
              JSON.stringify(inputValues?.[dataFieldType]?.[name])
            )
          }

          for (const [
            deepCloneOptionIndex,
            deepCloneOption,
          ] of deepCloneOptions.entries()) {
            const { combineFields } = deepCloneOption
            if (combineFields) {
              const categoryInputValueCombineFields =
                combinefieldCategoryInputValue.filter(
                  (item) => item.combineFields && item.combineFields.length > 0
                )[0]
              if (categoryInputValueCombineFields) {
                for (const [combineFieldsIndex] of combineFields.entries()) {
                  const categoryInputValueCombineFieldsItem =
                    categoryInputValueCombineFields.combineFields[
                      combineFieldsIndex
                    ]
                  const categoryInputValueCombineFieldsName = Object.keys(
                    categoryInputValueCombineFieldsItem
                  )[0]
                  deepCloneOptions[deepCloneOptionIndex].combineFields[
                    combineFieldsIndex
                  ].value =
                    categoryInputValueCombineFieldsItem[
                      categoryInputValueCombineFieldsName
                    ]
                }
              }
              // 有combineFeilds的checkbox 不為勾選狀態
              else {
                for (const [combineFieldsIndex] of combineFields.entries()) {
                  deepCloneOptions[deepCloneOptionIndex].combineFields[
                    combineFieldsIndex
                  ] = {
                    ...deepCloneOptions[deepCloneOptionIndex].combineFields[
                      combineFieldsIndex
                    ].canModify,
                    name: deepCloneOptions[deepCloneOptionIndex].combineFields[
                      combineFieldsIndex
                    ].name,
                    canModify: false,
                    value: '',
                  }
                }
              }
              fieldTypeSwitchProps = {
                ...fieldTypeSwitchProps,
                options: deepCloneOptions,
              }
            }
          }
        }
        if (fieldType === 'radio') {
          const radioFieldName = fieldTypeSwitchProps.formControlOption.name
          /* 若有combineFields欄位 */
          const deepCloneOptions = JSON.parse(JSON.stringify(options))

          let obj = {}
          for (const [
            deepCloneOptionIndex,
            deepCloneOption,
          ] of deepCloneOptions.entries()) {
            const { code, name, combineFields } = deepCloneOption
            obj = {
              code,
              name,
            }
            deepCloneOptions[deepCloneOptionIndex].selected =
              inputValues?.[dataFieldType]?.[radioFieldName]?.code === code

            if (combineFields) {
              obj.combineFields = []

              for (const [
                combineFieldIndex,
                combineFieldItem,
              ] of combineFields.entries()) {
                const { name: combineFieldName } = combineFieldItem
                if (
                  inputValues?.[dataFieldType]?.[radioFieldName]?.code === code
                ) {
                  obj.combineFields.push({
                    [combineFieldName]:
                      inputValues?.[dataFieldType]?.[radioFieldName]
                        ?.combineFields?.[combineFieldIndex]?.[
                        combineFieldName
                      ] ?? '',
                  })
                  deepCloneOptions[deepCloneOptionIndex].combineFields[
                    combineFieldIndex
                  ].value =
                    obj.combineFields[combineFieldIndex][combineFieldName]
                } else {
                  deepCloneOptions[deepCloneOptionIndex].combineFields[
                    combineFieldIndex
                  ].value = ''
                }
              }
            }
          }
          fieldTypeSwitchProps = {
            ...fieldTypeSwitchProps,
            options: deepCloneOptions,
          }
        }
        break
      case 'select':
        fieldTypeSwitchProps = {
          ...fieldTypeSwitchProps,
          onChange: (event, value, formControlRef, eventKey, optionItems) => {
            onChangeField(
              {
                target: formControlRef,
                options: optionItems,
                eventKey,
                event,
              },
              fieldTypeSwitchProps.fieldType
            )
          },
        }
        break
      case 'textarea':
        fieldTypeSwitchProps = {
          ...fieldTypeSwitchProps,
          formControlOption: {
            ...fieldTypeSwitchProps.formControlOption,
            rows: 3,
          },
          onChange: (e) => onChangeField(e, fieldTypeSwitchProps.fieldType),
        }
        break
      default:
        break
    }
    return fieldTypeSwitchProps
  }
  /* 
    獲得初始值的inputValues(可作為表單填寫取消後，回復初始值的功能) 
    但 
    handleInitFieldValue,
    handleInitOptionalFieldValue,
    handleInitCustomizedFieldValue,
    handleInitExpandFieldValue,
    須先執行後才能獲取
  */
  const getInitInputValues = () => {
    return {
      ...initInputValuesRef.current,
      ...initOptinoalInputValuesRef.current,
      ...initCustomizedInputValuesRef.current,
      ...initExpandInputValuesRef.current,
    }
  }

  // 處理初值(一般欄位 or 固定欄位使用)
  const handleInitFieldValue = (fieldsData, extraValues) => {
    /* 
    ------------------------------------
    參數說明
    ------------------------------------
    fieldsData:Object
    //欄位的api回傳內容

    extraValues:Object
    //欄位要客製化改變得值
  */
    if (fieldsData) {
      setInputValues((prevState) => {
        const inputValues = {}
        for (const fixfieldItem of fieldsData) {
          const { name, value } = fixfieldItem
          inputValues[name] = value
          if (name === 'city') {
            if (value || value === 0) {
              const citySelected = addressDataInfo.filter(
                (item) => item.Code === value
              )[0]
              if (citySelected) {
                const { Id, Code, Name } = citySelected
                inputValues.city = {
                  id: Id,
                  code: Code,
                  name: Name,
                }
              }
            }
          } else if (name === 'dist') {
            if (value || value === 0) {
              const citySelected = addressDataInfo.filter(
                (item) => item.Id === inputValues.city.code
              )[0]
              if (citySelected) {
                const { Options } = citySelected
                const distSelected = Options.filter(
                  (item) => item.Id === value
                )[0]
                if (distSelected) {
                  const { Id, Code, Name } = distSelected
                  if (Id || Code) {
                    inputValues.dist = {
                      id: Id,
                      code: Code,
                      name: Name,
                    }
                  }
                }
              }
            }
          }
          if (extraValues && Object.keys(extraValues).length > 0) {
            for (const itemName in extraValues) {
              if (name === itemName) {
                inputValues[name] = extraValues[itemName]
              }
            }
          }
        }
        // 初始值的 InputValues
        initInputValuesRef.current = JSON.parse(JSON.stringify(inputValues))
        return {
          ...prevState,
          ...inputValues,
        }
      })
    } else {
      setInputValues((prevState) => {
        // 初始值的 InputValues
        initInputValuesRef.current = JSON.parse(JSON.stringify(inputValues))
        return {
          ...prevState,
          ...inputValues,
        }
      })
    }
  }
  // 處理初值(彈性欄位使用)
  const handleInitOptionalFieldValue = (fieldData) => {
    handleCombineFieldsDisabled()
    if (fieldData) {
      setInputValues((prevState) => {
        const inputValues = {}
        for (const optionalFieldItem of fieldData) {
          const { options, fieldType, name, value } = optionalFieldItem
          if (fieldType === 'checkbox') {
            const checkboxList = []
            for (const optionItem of options) {
              const { selected } = optionItem
              if (selected) {
                checkboxList.push(optionItem)
              }
            }
            inputValues[name] = handleChecked(
              options,
              prevState?.optional?.[name]
            )
          } else if (fieldType === 'select') {
            for (const optionItem of options) {
              const { selected } = optionItem
              if (selected) {
                inputValues[name] = optionItem ?? null
                break
              }
            }
          } else if (fieldType === 'radio') {
            let obj = {}
            for (const optionItem of options) {
              const { code, name, selected, combineFields } = optionItem
              if (selected) {
                obj = {
                  code,
                  name,
                  selected,
                }
                if (combineFields) {
                  obj.combineFields = []
                  for (const [, combineFieldItem] of combineFields.entries()) {
                    const { name: combineFieldName, value } = combineFieldItem
                    obj.combineFields.push({
                      [combineFieldName]: value ?? '',
                    })
                  }
                }
                break
              }
            }
            inputValues[name] = obj
          } else {
            inputValues[name] = value ?? null
          }
        }
        // 初始值的 InputValues
        initOptinoalInputValuesRef.current = JSON.parse(
          JSON.stringify({
            optional: inputValues,
          })
        )
        return {
          ...prevState,
          optional: {
            ...prevState.optional,
            ...inputValues,
          },
        }
      })
    }
  }
  // 處理初值(客制欄位使用)
  const handleInitCustomizedFieldValue = (fieldData) => {
    handleCombineFieldsDisabled()
    if (fieldData) {
      setInputValues((prevState) => {
        const inputValues = {}
        for (const customizeFieldItem of fieldData) {
          const { options, fieldType, name, value } = customizeFieldItem
          if (fieldType === 'checkbox') {
            const checkboxList = []
            for (const optionItem of options) {
              const { selected } = optionItem
              if (selected) {
                checkboxList.push(optionItem)
              }
            }
            inputValues[name] = handleChecked(
              options,
              prevState?.customized?.[name]
            )
          } else if (fieldType === 'select') {
            for (const optionItem of options) {
              const { selected } = optionItem
              if (selected) {
                inputValues[name] = optionItem ?? null
                break
              }
            }
          } else if (fieldType === 'radio') {
            inputValues[name] = options.filter((item) => {
              return item.selected
            })[0]
          } else {
            inputValues[name] = value ?? null
          }
        }
        // 初始值的 InputValues
        initCustomizedInputValuesRef.current = JSON.parse(
          JSON.stringify({
            customized: inputValues,
          })
        )
        return {
          ...prevState,
          customized: {
            ...prevState.customized,
            ...inputValues,
          },
        }
      })
    }
  }
  // 處理初值(擴充欄位使用)
  const handleInitExpandFieldValue = (fieldData) => {
    handleCombineFieldsDisabled()
    if (fieldData && Array.isArray(fieldData)) {
      setInputValues((prevState) => {
        const group = []
        for (const [expandGroupIndex, fieldDataItem] of fieldData.entries()) {
          const inputValues = {}
          for (const expandFieldItem of fieldDataItem) {
            let { options, fieldType, name, value } = expandFieldItem
            name =
              expandGroupIndex !== undefined
                ? `${name}-expand-${expandGroupIndex}`
                : name

            if (fieldType === 'checkbox') {
              const checkboxList = []
              for (const optionItem of options) {
                const { selected } = optionItem
                if (selected) {
                  checkboxList.push(optionItem)
                }
              }
              inputValues[name] = handleChecked(
                options,
                prevState?.expand?.[name],
                expandGroupIndex
              )
            } else if (fieldType === 'select') {
              for (const optionItem of options) {
                const { selected } = optionItem
                if (selected) {
                  inputValues[name] = optionItem ?? null
                  break
                }
              }
            } else if (fieldType === 'radio') {
              let obj = {}
              for (const optionItem of options) {
                const { code, name, selected, combineFields } = optionItem
                if (selected) {
                  obj = {
                    code,
                    name,
                    selected,
                  }
                  if (combineFields) {
                    obj.combineFields = []
                    for (const [
                      ,
                      combineFieldItem,
                    ] of combineFields.entries()) {
                      let { name: combineFieldName, value } = combineFieldItem
                      combineFieldName =
                        expandGroupIndex !== undefined
                          ? `${combineFieldName}-expand-${expandGroupIndex}`
                          : combineFieldName
                      obj.combineFields.push({
                        [combineFieldName]: value ?? '',
                      })
                    }
                  }
                  break
                }
              }
              inputValues[name] = obj
            } else {
              inputValues[name] = value ?? null
            }
          }
          group.push(inputValues)
        }
        // 初始值的 InputValues
        initExpandInputValuesRef.current = JSON.parse(
          JSON.stringify({
            expand: group,
          })
        )
        return {
          ...prevState,
          expand: group,
        }
      })
    }
  }

  // 固定欄位
  const getFixField = (fieldsData, extraFieldTypeSwitchProps) => {
    /* 
    ------------------------------------
    參數說明
    ------------------------------------
    fieldsData:Object
    //欄位的api回傳內容

    extraFieldTypeSwitchProps:{
      custom_id:{
        disabled:Boolean //會員編號的disabled
      }
      shop:{
        options:Array //入會門店的options，須為{code:xx,name:xx}的形式 
      }
    }
    額外的fieldTypeSwitchProps設定，
    其中key為欄位的name值，value為prop設定
  */
    const groupItemFieldTypeSwitchProps = []
    const groupItemFieldTitle = []
    if (Array.isArray(fieldsData)) {
      for (const [, item] of fieldsData.entries()) {
        const {
          canModify,
          display,
          errorMessage,
          fieldRegex,
          fieldSuffix,
          fieldType,
          isRequired,
          name,
          options,
          placeholder,
          fieldLength,
        } = item

        let fieldTypeSwitchProps = {
          formIsTriggerValidate,
          type: fieldType,
          feedbackText: errorMessage || '尚未填寫',
          formControlOption: {
            required: isRequired,
            disabled: !canModify,
            name,
            placeholder,
            maxLength: fieldLength,
            value: inputValues?.[name] ?? '',
          },
          options,
          inputOption: {
            fieldSuffix,
          },
          onChange: ({ ...prop }) =>
            onChangeField(prop, fieldTypeSwitchProps.fieldType),
          fieldType: 'fixFields',
        }
        if (fieldRegex) {
          fieldTypeSwitchProps.formControlOption.pattern = fieldRegex
        }
        switch (name) {
          case 'custom_id':
            fieldTypeSwitchProps = {
              ...fieldTypeSwitchProps,
              formControlOption: {
                ...fieldTypeSwitchProps.formControlOption,
                disabled: !!extraFieldTypeSwitchProps?.[name]?.disabled,
              },
            }
            break
          // 屬於select類型的固定欄位
          case 'shop':
          case 'membership_setting_level':
          case 'city':
          case 'dist':
            fieldTypeSwitchProps = {
              ...fieldTypeSwitchProps,
              selectOption: {
                dropdownToggleOption: {
                  disabled: false,
                },
              },
              onChange: (
                event,
                value,
                formControlRef,
                eventKey,
                optionItems
              ) => {
                onChangeField(
                  {
                    target: formControlRef,
                    options: optionItems,
                    eventKey,
                    event,
                  },
                  fieldTypeSwitchProps.fieldType
                )
                if (name === 'city') {
                  const selectedIndex = parseInt(event.target.dataset.index)
                  // 地址的Dist欄位
                  const addressDist = []
                  for (const distItem of addressDataInfo[selectedIndex]
                    .Options) {
                    const {
                      Code: distCode,
                      Name: distName,
                      Id: distId,
                    } = distItem
                    addressDist.push({
                      code: distCode,
                      name: distName,
                      id: distId,
                    })
                  }
                  setFixFieldsDataDistState((prevState) => {
                    return {
                      ...prevState,
                      options: addressDist,
                      selectOption: {
                        ...prevState.selectOption,
                        isClearSelectedValue: true,
                      },
                    }
                  })
                  setInputValues((prevState) => {
                    return {
                      ...prevState,
                      dist: null,
                    }
                  })
                }
                if (name === 'dist') {
                  setFixFieldsDataDistState((prevState) => {
                    return {
                      ...prevState,
                      selectOption: {
                        ...prevState.selectOption,
                        isClearSelectedValue: false,
                      },
                    }
                  })
                }
              },
            }
            if (name === 'dist') {
              fieldTypeSwitchProps.options =
                fixFieldsDataDistState.options.length > 0
                  ? fixFieldsDataDistState.options
                  : getDistOptions(inputValues.city)

              fieldTypeSwitchProps.selectOption = {
                ...fieldTypeSwitchProps.selectOption,
                isClearSelectedValue:
                  fixFieldsDataDistState.selectOption.isClearSelectedValue,
              }

              if (!inputValues.dist || !inputValues.dist?.code) {
                fieldTypeSwitchProps.selectOption = {
                  ...fieldTypeSwitchProps.selectOption,
                  isClearSelectedValue: true,
                }
              }
            }
            if (name === 'shop') {
              fieldTypeSwitchProps.options =
                extraFieldTypeSwitchProps?.[name]?.options || []
            }
            break
          default:
            break
        }
        groupItemFieldTitle.push(display)
        groupItemFieldTypeSwitchProps.push(fieldTypeSwitchProps)
      }
    }
    return {
      fieldTypeSwitchProps: groupItemFieldTypeSwitchProps,
      titles: groupItemFieldTitle,
    }
  }
  // 彈性欄位
  const getOptionalField = (fieldsData) => {
    /* 
    ------------------------------------
    參數說明
    ------------------------------------
    fieldsData:Object
    //彈性欄位的api回傳內容
  */
    const groupItemFieldTypeSwitchProps = []
    const groupItemFieldTitle = []
    if (Array.isArray(fieldsData)) {
      for (const [, item] of fieldsData.entries()) {
        const {
          canModify,
          display,
          errorMessage,
          fieldRegex,
          fieldSuffix,
          fieldType,
          isRequired,
          name,
          options,
          placeholder,
          fieldLength,
          tip,
          tooltip,
        } = item
        let fieldTypeSwitchProps = {
          formIsTriggerValidate,
          type: fieldType,
          feedbackText: errorMessage || '尚未填寫',
          formControlOption: {
            required: isRequired,
            disabled: !canModify,
            name,
            placeholder,
            maxLength: fieldLength,
            tip,
            tooltip,
            value: inputValues?.optional?.[name] ?? '',
          },
          options,
          inputOption: {
            fieldSuffix,
          },
          onChange: ({ ...prop }) =>
            onChangeField(prop, fieldTypeSwitchProps.fieldType),
          fieldType: 'optionalFields',
        }
        if (fieldRegex) {
          fieldTypeSwitchProps.formControlOption.pattern = fieldRegex
        }

        const dataFieldType = fieldTypeSwitchProps.fieldType.replace(
          'Fields',
          ''
        )
        // 新整理的寫法
        fieldTypeSwitchProps = handleCombineFieldsForOptionalAndCustomized(
          dataFieldType,
          fieldType,
          fieldTypeSwitchProps,
          options,
          name,
          isRequired
        )

        groupItemFieldTitle.push(display)
        groupItemFieldTypeSwitchProps.push(fieldTypeSwitchProps)
      }
    }
    return {
      fieldTypeSwitchProps: groupItemFieldTypeSwitchProps,
      titles: groupItemFieldTitle,
    }
  }
  // 客制欄位
  const getCustomizedField = (fieldsData) => {
    /* 
    ------------------------------------
    參數說明
    ------------------------------------
    fieldsData:Object
    //客制欄位的api回傳內容
  */
    const groupItemFieldTypeSwitchProps = []
    const groupItemFieldTitle = []
    if (Array.isArray(fieldsData)) {
      for (const [, item] of fieldsData.entries()) {
        const {
          canModify,
          display,
          errorMessage,
          fieldRegex,
          fieldSuffix,
          fieldType,
          isRequired,
          name,
          options,
          placeholder,
          fieldLength,
          tip,
          tooltip,
        } = item
        let fieldTypeSwitchProps = {
          formIsTriggerValidate,
          type: fieldType,
          feedbackText: errorMessage,
          formControlOption: {
            required: isRequired,
            disabled: !canModify,
            name,
            placeholder,
            maxLength: fieldLength,
            tip,
            tooltip,
            value: inputValues?.customized?.[name] ?? '',
          },
          options,
          inputOption: {
            fieldSuffix,
          },
          onChange: ({ ...prop }) =>
            onChangeField(prop, fieldTypeSwitchProps.fieldType),
          fieldType: 'customizedFields',
        }
        if (fieldRegex) {
          fieldTypeSwitchProps.formControlOption.pattern = fieldRegex
        }

        const dataFieldType = fieldTypeSwitchProps.fieldType.replace(
          'Fields',
          ''
        )
        // 新整理的寫法
        fieldTypeSwitchProps = handleCombineFieldsForOptionalAndCustomized(
          dataFieldType,
          fieldType,
          fieldTypeSwitchProps,
          options,
          name,
          isRequired
        )
        groupItemFieldTitle.push(display)
        groupItemFieldTypeSwitchProps.push(fieldTypeSwitchProps)
      }
    }
    return {
      fieldTypeSwitchProps: groupItemFieldTypeSwitchProps,
      titles: groupItemFieldTitle,
    }
  }
  // 客制欄位的section對照樣板(讓CustomizedField符合相同欄位name值的會帶入相對應欄位的屬性)
  const getCustomizedFieldSection = (fieldsSectionData, fieldsData) => {
    const obj = []
    const sectionItems = []
    if (fieldsData && Array.isArray(fieldsSectionData)) {
      for (const customizedFieldsDataItem of fieldsSectionData) {
        const { sections } = customizedFieldsDataItem
        for (const sectionItem of sections) {
          const { name: listTitle, fields } = sectionItem
          const pickFieldsData = []
          for (const fieldName of fields) {
            for (const demoItem of fieldsData) {
              const { name: demoName } = demoItem
              if (demoName === fieldName) {
                pickFieldsData.push(demoItem)
              }
            }
          }
          sectionItems.push({
            listTitle,
            fields: pickFieldsData,
          })
        }
        obj.push({
          article: sectionItems,
        })
      }
    }
    return obj
  }
  // 擴充欄位
  const getExpandField = (fieldsData) => {
    /* 
      ------------------------------------
      參數說明
      ------------------------------------
      fieldsData:Object
      //擴充欄位的api回傳內容
    */
    const expandGroup = []
    if (Array.isArray(fieldsData)) {
      for (const [expandsDataIndex, expandsDataItem] of fieldsData.entries()) {
        const groupItemFieldTypeSwitchProps = []
        const groupItemFieldTitle = []
        for (const [, item] of expandsDataItem.entries()) {
          let {
            canModify,
            display,
            errorMessage,
            fieldRegex,
            fieldSuffix,
            fieldType,
            isRequired,
            name,
            options,
            placeholder,
            fieldLength,
            tip,
            tooltip,
          } = item
          // 由於同一個form裡面不能有重複的name值，因此擴充欄位這邊從第二組資料開始做處理
          name =
            expandsDataIndex !== undefined
              ? `${name}-expand-${expandsDataIndex}`
              : name

          let fieldTypeSwitchProps = {
            formIsTriggerValidate,
            type: fieldType,
            feedbackText: errorMessage || '尚未填寫',
            formControlOption: {
              required: isRequired,
              disabled: !canModify,
              name,
              placeholder,
              maxLength: fieldLength,
              tip,
              tooltip,
              value: inputValues?.expand?.[expandsDataIndex]?.[name] ?? '',
            },
            options,
            inputOption: {
              fieldSuffix,
            },
            onChange: ({ ...prop }) =>
              onChangeField(
                prop,
                fieldTypeSwitchProps.fieldType,
                expandsDataIndex
              ),
            fieldType: 'expandFields',
          }
          if (fieldRegex) {
            fieldTypeSwitchProps.formControlOption.pattern = fieldRegex
          }

          switch (fieldType) {
            case 'checkbox':
            case 'radio':
              fieldTypeSwitchProps = {
                ...fieldTypeSwitchProps,
                inline: true,
                onChange: (e) => {
                  onChangeField(
                    {
                      target: e.target,
                      options,
                    },
                    fieldTypeSwitchProps.fieldType,
                    expandsDataIndex
                  )
                },
              }
              if (fieldType === 'checkbox') {
                // required的控制(因只要有一個勾選其他checkbox的required應設為false，否則會變成所有都要勾選)
                if (isRequired) {
                  if (
                    inputValues?.expand?.[expandsDataIndex]?.[name]?.length > 0
                  ) {
                    fieldTypeSwitchProps.formControlOption.required = false
                  } else {
                    fieldTypeSwitchProps.formControlOption.required = true
                  }
                }
                /* 若有combineFields欄位 */
                const deepCloneOptions = JSON.parse(JSON.stringify(options))
                let combinefieldCategoryInputValue = []
                if (inputValues?.expand?.[expandsDataIndex]?.[name]) {
                  combinefieldCategoryInputValue = JSON.parse(
                    JSON.stringify(
                      inputValues?.expand?.[expandsDataIndex]?.[name]
                    )
                  )
                }
                for (const [
                  deepCloneOptionIndex,
                  deepCloneOption,
                ] of deepCloneOptions.entries()) {
                  const { combineFields } = deepCloneOption
                  if (combineFields) {
                    const categoryInputValueCombineFields =
                      combinefieldCategoryInputValue.filter(
                        (item) =>
                          item.combineFields && item.combineFields.length > 0
                      )[0]
                    if (categoryInputValueCombineFields) {
                      for (const [
                        combineFieldsIndex,
                      ] of combineFields.entries()) {
                        const categoryInputValueCombineFieldsItem =
                          categoryInputValueCombineFields.combineFields[
                            combineFieldsIndex
                          ]
                        const categoryInputValueCombineFieldsName = Object.keys(
                          categoryInputValueCombineFieldsItem
                        )[0]
                        deepCloneOptions[deepCloneOptionIndex].combineFields[
                          combineFieldsIndex
                        ].name =
                          expandsDataIndex !== undefined
                            ? `${deepCloneOptions[deepCloneOptionIndex].combineFields[combineFieldsIndex].name}-expand-${expandsDataIndex}`
                            : deepCloneOptions[deepCloneOptionIndex]
                                .combineFields[combineFieldsIndex].name

                        deepCloneOptions[deepCloneOptionIndex].combineFields[
                          combineFieldsIndex
                        ].value =
                          categoryInputValueCombineFieldsItem[
                            categoryInputValueCombineFieldsName
                          ]
                      }
                    }
                    // 有combineFeilds的checkbox 不為勾選狀態
                    else {
                      for (const [
                        combineFieldsIndex,
                      ] of combineFields.entries()) {
                        deepCloneOptions[deepCloneOptionIndex].combineFields[
                          combineFieldsIndex
                        ] = {
                          ...deepCloneOptions[deepCloneOptionIndex]
                            .combineFields[combineFieldsIndex],
                          name:
                            expandsDataIndex !== undefined
                              ? `${deepCloneOptions[deepCloneOptionIndex].combineFields[combineFieldsIndex].name}-expand-${expandsDataIndex}`
                              : deepCloneOptions[deepCloneOptionIndex]
                                  .combineFields[combineFieldsIndex].name,
                          canModify: false,
                          value: '',
                        }
                      }
                    }
                    fieldTypeSwitchProps = {
                      ...fieldTypeSwitchProps,
                      options: deepCloneOptions,
                    }
                  }
                }
              }
              if (fieldType === 'radio') {
                const radioFieldName =
                  fieldTypeSwitchProps.formControlOption.name
                /* 若有combineFields欄位 */
                const deepCloneOptions = JSON.parse(JSON.stringify(options))

                let obj = {}
                for (const [
                  deepCloneOptionIndex,
                  deepCloneOption,
                ] of deepCloneOptions.entries()) {
                  const { code, name, combineFields } = deepCloneOption
                  obj = {
                    code,
                    name,
                  }
                  deepCloneOptions[deepCloneOptionIndex].selected =
                    inputValues?.expand?.[expandsDataIndex]?.[radioFieldName]
                      ?.code === code

                  if (combineFields) {
                    obj.combineFields = []

                    for (const [
                      combineFieldIndex,
                      combineFieldItem,
                    ] of combineFields.entries()) {
                      const { name: combineFieldName } = combineFieldItem
                      if (
                        inputValues?.expand?.[expandsDataIndex]?.[
                          radioFieldName
                        ]?.code === code
                      ) {
                        obj.combineFields.push({
                          [combineFieldName]:
                            inputValues?.expand?.[expandsDataIndex]?.[
                              radioFieldName
                            ]?.combineFields?.[combineFieldIndex]?.[
                              combineFieldName
                            ] ?? '',
                        })
                        deepCloneOptions[deepCloneOptionIndex].combineFields[
                          combineFieldIndex
                        ].name =
                          expandsDataIndex !== undefined
                            ? `${deepCloneOptions[deepCloneOptionIndex].combineFields[combineFieldIndex].name}-expand-${expandsDataIndex}`
                            : deepCloneOptions[deepCloneOptionIndex]
                                .combineFields[combineFieldIndex].name

                        deepCloneOptions[deepCloneOptionIndex].combineFields[
                          combineFieldIndex
                        ].value =
                          obj.combineFields[combineFieldIndex][combineFieldName]
                      } else {
                        deepCloneOptions[deepCloneOptionIndex].combineFields[
                          combineFieldIndex
                        ] = {
                          ...deepCloneOptions[deepCloneOptionIndex]
                            .combineFields[combineFieldIndex],
                          name:
                            expandsDataIndex !== undefined
                              ? `${deepCloneOptions[deepCloneOptionIndex].combineFields[combineFieldIndex].name}-expand-${expandsDataIndex}`
                              : deepCloneOptions[deepCloneOptionIndex]
                                  .combineFields[combineFieldIndex].name,
                          value: '',
                        }
                      }
                    }
                  }
                }
                fieldTypeSwitchProps = {
                  ...fieldTypeSwitchProps,
                  options: deepCloneOptions,
                }
              }
              break
            case 'select':
              fieldTypeSwitchProps = {
                ...fieldTypeSwitchProps,
                onChange: (
                  event,
                  value,
                  formControlRef,
                  eventKey,
                  optionItems
                ) => {
                  onChangeField(
                    {
                      target: formControlRef,
                      options: optionItems,
                      eventKey,
                      event,
                    },
                    fieldTypeSwitchProps.fieldType,
                    expandsDataIndex
                  )
                },
              }
              break
            case 'textarea':
              fieldTypeSwitchProps = {
                ...fieldTypeSwitchProps,
                formControlOption: {
                  ...fieldTypeSwitchProps.formControlOption,
                  rows: 3,
                },
                onChange: (e) =>
                  onChangeField(
                    e,
                    fieldTypeSwitchProps.fieldType,
                    expandsDataIndex
                  ),
              }
              break
            default:
              break
          }
          groupItemFieldTitle.push(display)
          groupItemFieldTypeSwitchProps.push(fieldTypeSwitchProps)
        }
        expandGroup.push({
          fieldTypeSwitchProps: groupItemFieldTypeSwitchProps,
          titles: groupItemFieldTitle,
        })
      }
    }
    return expandGroup
  }

  // 處理欄位改變
  const onChangeField = ({ target, ...prop }, fieldType, expandGroupIndex) => {
    /*
      name、value表示參數是傳入event來做處理
      finalName、finalValue表示參數是傳入欄位name跟value來做處理
      可以擇一選擇參數帶入處理方式  
    */
    const name = target?.name ?? null
    const value = target?.value ?? null
    let { fieldName, fieldValue } = prop
    fieldName = fieldName ?? null
    fieldValue = fieldValue ?? null
    const finalName = fieldName ?? name
    const finalValue = fieldValue ?? value
    const valueType = target?.dataset?.valueType
    setInputValues((prevState) => {
      let updateData = {}

      /* 表示為combinefields欄位 */
      const combinedFieldsDataElement = target?.closest('[data-category]') // 由於lit元件有包裝過，因此data-category並非直接綁在input上，因此需要先接收到綁到的元素上
      const dataSetFieldType = combinedFieldsDataElement?.dataset?.fieldtype // 隸屬於何name(欄位名稱)底下的combinefields
      const dataSetCategory = combinedFieldsDataElement?.dataset?.category // 隸屬於何name(欄位名稱)底下的combinefields
      const dataSetCombinefieldsIndex =
        combinedFieldsDataElement?.dataset?.combinefieldsIndex // combinefields的排序為何

      if (dataSetCategory) {
        const dataFieldType = dataSetFieldType.replace('Fields', '')
        const checkedItems =
          expandGroupIndex || expandGroupIndex === 0
            ? // 擴充欄位
              prevState?.[dataFieldType]?.[expandGroupIndex]?.[dataSetCategory]
            : prevState?.[dataFieldType]?.[dataSetCategory]
        // checkbox的情況
        if (Array.isArray(checkedItems)) {
          for (const [checkedIndex, checkedItem] of checkedItems.entries()) {
            const { combineFields } = checkedItem
            if (combineFields && combineFields.length > 0) {
              const newCombineFields = JSON.parse(JSON.stringify(combineFields))
              newCombineFields[dataSetCombinefieldsIndex][finalName] =
                finalValue
              checkedItems[checkedIndex].combineFields = newCombineFields
              updateData = {
                ...updateData,
                [dataSetCategory]: checkedItems,
              }
            }
          }
        }
        // radio的情況
        else if (checkedItems?.combineFields) {
          const newCombineFields = JSON.parse(
            JSON.stringify(checkedItems.combineFields)
          )
          newCombineFields[dataSetCombinefieldsIndex][finalName] = finalValue
          checkedItems.combineFields = newCombineFields

          updateData = {
            ...updateData,
            [dataSetCategory]: checkedItems,
          }
        }
      }

      if (target?.type === 'checkbox') {
        const { checked } = target
        const checkedIndex = [
          ...target.parentElement.parentElement.children,
        ].indexOf(target.parentElement)

        const shallowCloneOptions = prop.options ? [...prop.options] : null
        if (shallowCloneOptions) {
          shallowCloneOptions[checkedIndex].selected = checked
        }

        /* 處理有combineFields的欄位，在checkbox 關閉時，須清空欄位 */
        const disabledCombineFields = getSessionStorage('disabledCombineFields')
        if (!checked && shallowCloneOptions) {
          const checkedItem = shallowCloneOptions[checkedIndex]
          const { combineFields } = checkedItem
          const disabledCombineFieldsData = disabledCombineFields
            ? JSON.parse(disabledCombineFields)
            : {}
          const obj = { ...disabledCombineFieldsData }
          if (combineFields && combineFields.length > 0) {
            for (const combineFieldsItem of combineFields) {
              const { name } = combineFieldsItem
              if (expandGroupIndex || expandGroupIndex === 0) {
                obj[`${name}-expand-${expandGroupIndex}`] = 1
              } else {
                obj[name] = 1
              }
            }
          }
          setSessionStorage('disabledCombineFields', JSON.stringify(obj))
        }

        const newValue = handleChecked(
          shallowCloneOptions,
          prevState?.[fieldType?.replace('Fields', '')]?.[finalName],
          expandGroupIndex
        )
        updateData = {
          ...updateData,
          [finalName]: newValue,
        }
      } else if (target?.type?.includes('select')) {
        const { event, options } = prop
        const selectedIndex = parseInt(event?.target?.dataset?.index)
        /* 因select元件為名稱及值為text、value，而api定義的select名稱及值為code、name 所以要進行轉換 */
        if (options && isJSON(options)) {
          const translateOptions = JSON.parse(JSON.stringify(options))
          for (const optionItem of translateOptions) {
            optionItem.code = optionItem.value
            if (fieldType === 'fixFields') {
              if (finalName === 'shop') {
                optionItem.id = optionItem.value
              }
            }
            optionItem.name = optionItem.text
            delete optionItem.value
            delete optionItem.text
          }
          // 提出 dropdownItemOption 參數
          // eslint-disable-next-line no-unused-vars
          const { dropdownItemOption, ...newValue } = {
            ...translateOptions[selectedIndex],
          }
          newValue.selected = true

          if (finalName === 'city') {
            const nextCity = addressDataInfo.find(
              (item) => item.Name === newValue.name
            )
            return {
              ...prevState,
              city: {
                code: nextCity.Code,
                name: nextCity.Name,
                id: nextCity.Id,
              },
            }
          }
          if (finalName === 'dist') {
            const nextDist = addressDataInfo
              .find((item) => item.Name === prevState.city.name)
              .Options.find((option) => option.Name === newValue.name)
            return {
              ...prevState,
              dist: {
                code: nextDist.Code,
                name: nextDist.Name,
                id: nextDist.Id,
              },
            }
          }

          updateData = {
            ...updateData,
            [finalName]: newValue,
          }
        }
      } else if (target?.type === 'radio') {
        let newValue = {}
        if (prop.options) {
          for (const optionItem of prop.options) {
            const { code, name, combineFields } = optionItem
            if (finalValue === code) {
              newValue = {
                code,
                name,
                selected: true,
              }
              if (combineFields && combineFields.length > 0) {
                newValue.combineFields = []
                for (const [, combineFieldItem] of combineFields.entries()) {
                  const { name: combineFieldName } = combineFieldItem
                  newValue.combineFields.push({
                    [combineFieldName]: '',
                  })
                }
              }
              break
            }
          }
        }

        if (fieldType === 'fixFields' || !fieldType) {
          if (valueType === 'number') {
            newValue = Number(finalValue)
          } else if (valueType === 'boolean') {
            newValue = !!(finalValue && finalValue === 'true')
          } else {
            newValue = finalValue
          }
        }

        updateData = {
          ...updateData,
          [finalName]: newValue,
        }
      } else if (!dataSetCategory) {
        updateData = {
          ...updateData,
          [finalName]: valueType === 'number' ? Number(finalValue) : finalValue,
        }
      }

      switch (fieldType) {
        // 彈性欄位
        case 'optionalFields':
          updateData = {
            optional: {
              ...prevState.optional,
              ...updateData,
            },
          }
          break
        // 客制欄位
        case 'customizedFields':
          updateData = {
            customized: {
              ...prevState.customized,
              ...updateData,
            },
          }
          break
        // 擴充欄位
        case 'expandFields':
          prevState.expand[expandGroupIndex][finalName] =
            updateData?.[finalName]
          updateData = {
            expand: [...prevState.expand],
          }
          break
        // 固定欄位 or 一般欄位
        default:
          updateData = {
            ...updateData,
          }
          break
      }
      return {
        ...prevState,
        ...updateData,
      }
    })
  }

  return {
    inputValues,
    setInputValues,
    onChangeField,
    getFixField,
    getOptionalField,
    getCustomizedField,
    getCustomizedFieldSection,
    getExpandField,
    handleInitFieldValue,
    handleInitOptionalFieldValue,
    handleInitCustomizedFieldValue,
    handleInitExpandFieldValue,
    getInitInputValues,
  }
}

export default useField
