import { useContext, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { StyleCard } from 'components/units/Card'
import { getRandomId, toastErrorContent } from 'helpers/common'
import * as format from 'helpers/format'
import { RootContext } from 'RootContext'
import {
  getMemberDashboardThunk,
  getMemberDashboardData,
  getMemberDashboardRspError,
} from 'store/dashboard/membersDashboardSlice'
import LocalStyle from './memberDashboardStyle'

function MemberDashboard() {
  const dispatch = useDispatch()
  const { statusCode, errorMsg } = useSelector(getMemberDashboardRspError)
  const { total, thisMonth } = useSelector(getMemberDashboardData)
  // global data
  const rootData = useContext(RootContext)

  useEffect(() => {
    if (rootData?.shopId || rootData?.shopId === 0) {
      dispatch(
        getMemberDashboardThunk({
          shopId: rootData?.shopId,
        })
      )
    }
  }, [dispatch, rootData?.shopId])

  useEffect(() => {
    if (statusCode !== 0 && statusCode !== 500) {
      toast.error(toastErrorContent(statusCode, errorMsg), {
        id: getRandomId(),
      })
    }
  }, [statusCode, errorMsg])

  return (
    <LocalStyle>
      <Container>
        <Row>
          <h1>首頁</h1>
        </Row>
        <Row>
          <h3>總會員數</h3>
        </Row>
        <Row className="flex-wrap">
          {total?.map((memberItem) => {
            const { display, value, id } = memberItem
            return (
              <Col key={`member-item-${id}`} className="member-num-card-col">
                <StyleCard
                  className="member-num-card text-center mb-3"
                  padding="32px 32px"
                  size="sm"
                  isLoading={false}
                  loaderOption={{
                    colorType: 'dark',
                  }}
                >
                  <h5 className="fz15 mt-2">{display}</h5>
                  <p className="fz32">{format.number(value)}</p>
                </StyleCard>
              </Col>
            )
          })}
        </Row>
      </Container>
      <hr className="margin-t-35 margin-b-40" />
      <Container>
        <Row>
          <h3>月招募會員數</h3>
        </Row>
        <Row>
          {thisMonth?.map((memberItem) => {
            const { display, value, id } = memberItem
            return (
              <Col
                key={`monthly-member-item-${id}`}
                className="member-num-card-col"
              >
                <StyleCard
                  className="member-num-card text-center mb-3"
                  padding="32px 32px"
                  size="sm"
                  isLoading={false}
                  loaderOption={{
                    colorType: 'dark',
                  }}
                >
                  <h5 className="fz15">{display}</h5>
                  <p className="fz32">{value}</p>
                </StyleCard>
              </Col>
            )
          })}
        </Row>
      </Container>
    </LocalStyle>
  )
}

export default MemberDashboard
