import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  Checkbox,
  Input,
  Select,
  Tooltip,
} from 'components/units'
import { ButtonList } from 'components/widgets'
import { Form, Col, Row } from 'react-bootstrap'
import withPermission from 'components/hocs/withPermission'
import { ReactComponent as IconCancel } from 'assets/images/button/icon_cancel.svg'
import { ReactComponent as IconSave } from 'assets/images/button/icon_save.svg'
import iconEdit from 'assets/images/button/icon_edit.svg'
import iconTooltip from 'assets/images/icon_tooltip.svg'
import { colorObj } from 'assets/styles/Variable/Color'
import { fieldRestrictNumberZero } from 'helpers/validation'
import { authAccountSelector } from 'store/auth/authSelector'
import { patchPointBase, postPointBase } from 'api/ApiMain'
import { handleCatch } from '../../PointSettingHelper'
import { COMPONENT_PERMISSIONS } from 'constant'
import { monthOptions } from 'constant/common'
import {
  getPointThunk,
  pointSettingRspSelector,
  pointSettingEditSelector,
  pointGainRulesSelector,
  statusSelector,
  changeIsBaseSettingLoading,
  changeIsBaseSettingEdit,
  changeIsCalculateDisabled,
} from 'store/point-setting/pointSettingSlice'
import { Collapse } from 'react-bootstrap'
import iconCollapse from 'assets/images/icon_collapse.svg'
import iconUncollapse from 'assets/images/icon_uncollapse.svg'
import { StyleCard } from 'components/units/Card'
import LocalStyle from './baseSettingStyle'

// 基本設定修改"依消費金額"為取消勾選，且原贈點設定有資料，控制是否彈出modal提醒視窗

function BaseSetting() {
  const dispatch = useDispatch()
  const { isBaseSettingLoading, isCalculateLoading } =
    useSelector(statusSelector)
  const {
    isStartComputeDisabled,
    amountPerFull,
    isMaxPointPerDeal,
    accumulatePoints,
    maxPointPerDeal,
    startCondition,
    expireCondition,
  } = useSelector(pointSettingRspSelector)
  const {
    baseId,
    pointSettingEditDefault,
    pointStartCondOptions,
    pointExpireCondOptions,
    pointStartOptions,
    pointExpireOptions,
  } = useSelector(pointSettingEditSelector)
  const { isRowNotEmpty } = useSelector(pointGainRulesSelector)
  const { brandId } = useSelector(authAccountSelector)

  const { UPDATE } = COMPONENT_PERMISSIONS.point
  const PermissionEditButton = withPermission(Button, UPDATE)
  const [isSubmitValidated, setSubmitValidated] = useState(false)
  const [pointSettingEdit, setPointSettingEdit] = useState(
    pointSettingEditDefault
  )

  const [isEditBtnVisible, setIsEditBtnVisible] = useState(true)
  const [isStoreBtnVisible, setIsStoreBtnVisible] = useState(false)
  const [isCancelBtnVisible, setIsCancelBtnVisible] = useState(false)
  const [isStoreLoading, setIsStoreLoading] = useState(false)
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)

  const formRef = useRef(null)

  /* 消費贈點欄位有錯誤 */
  const isConsumedPointFieldsError =
    // 勾選依消費金額
    pointSettingEdit?.gainPtByConsumption &&
    // 每滿多少錢累積一點未填寫
    (!pointSettingEdit?.howManyAmountPerFull ||
      // 累積多少點未填寫
      !pointSettingEdit?.howManyPointsGive ||
      // 並於什麼時候發送點數未填寫
      !pointSettingEdit?.pointStartCond)

  /* 自行設定天數 */
  const isCustomizedDays = pointSettingEdit?.pointExpireCond?.id === 5

  /* 指定月底 */
  const isCustomizedMonthEnd = pointSettingEdit?.pointExpireCond?.id === 6

  /* 點數效期欄位有錯誤 */
  const isPointUseFieldsError =
    // 未選擇點數效期
    !pointSettingEdit?.pointExpireCond ||
    // 選擇自行設定天數，但未填寫天數
    (isCustomizedDays && !pointSettingEdit?.pointExpireCondNum) ||
    // 選擇指定月底，但未選擇月份
    (isCustomizedMonthEnd && !pointSettingEdit?.pointExpireCondNum)

  /* 進入編輯狀態 */
  const handleEditClick = () => {
    setIsEditBtnVisible(false)
    setIsStoreBtnVisible(true)
    setIsCancelBtnVisible(true)
    dispatch(changeIsBaseSettingEdit(true))
  }

  /* 取消編輯狀態 */
  const closeEdit = () => {
    setIsEditBtnVisible(true)
    setIsStoreBtnVisible(false)
    setIsCancelBtnVisible(false)
    dispatch(changeIsBaseSettingEdit(false))
  }

  /* 欄位驗證 */
  const validateFields = () => {
    const form = formRef.current
    const isValidated = form.checkValidity()
    setSubmitValidated(true)

    // form 的欄位判斷都通過後，再進行欄位客制判斷
    if (!isValidated) return false

    // 若消費贈點欄位、點數效期欄位有誤，則不進行後續
    if (isConsumedPointFieldsError || isPointUseFieldsError) return false

    // 消費贈點中的勾選狀態，與上次儲存不同
    const isCheckStatusChanged =
      pointSettingEdit?.gainPtByConsumption !==
      pointSettingEditDefault?.gainPtByConsumption

    // 改變勾選狀態 && table 有資料
    // TODO: 這部分有問題，因此先使用 early return，可能需要彈窗處理
    if (isCheckStatusChanged && isRowNotEmpty) return false

    return true
  }

  /* 點擊儲存按鈕 */
  const handleStoreClick = async (event) => {
    event.preventDefault()

    // 驗證
    const isValidated = validateFields()
    if (!isValidated) return

    // 編輯
    let apiFunction = patchPointBase
    let parameter = [brandId, baseId, pointSettingEdit]
    // 新增
    if (baseId === 0) {
      apiFunction = postPointBase
      parameter = [brandId, pointSettingEdit]
    }

    try {
      dispatch(changeIsCalculateDisabled(true))
      setIsStoreLoading(true)
      await apiFunction.call(this, ...parameter)
      setSubmitValidated(false)
      await handleBonusSettingData()
    } catch (error) {
      handleCatch(error)
    } finally {
      dispatch(changeIsCalculateDisabled(false))
      setIsStoreLoading(false)
      closeEdit()
    }
  }

  /* 取消編輯 */
  const handleCancelClick = () => {
    // 取消錯誤提示和紅框
    setSubmitValidated(false)
    setPointSettingEdit(pointSettingEditDefault)
    closeEdit()
  }

  /* 展開和收合 */
  const handleCollapseClick = () => setIsCollapseOpen(!isCollapseOpen)

  /* checkbox 變動 */
  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target
    setPointSettingEdit({
      ...pointSettingEdit,
      [name]: checked,
    })
  }

  /* 輸入欄位變動 */
  const handleInput = (e) => {
    const { name, value } = e.target
    if (name === 'pointExpireCondNum' && value?.length > 3) return
    setPointSettingEdit({
      ...pointSettingEdit,
      [name]: value,
    })
  }

  /* 選擇發送點數時間或效期，此處回傳完整物件 */
  const handleSelect = (event, value, target) => {
    const { name } = target
    const pointCondOptionsMapping = {
      pointStartCond: pointStartCondOptions,
      pointExpireCond: pointExpireCondOptions,
    }
    const options = pointCondOptionsMapping[name]
    const valueOption = options.find((option) => option.id === value)
    const newPointSettingEdit = {
      ...pointSettingEdit,
      [name]: valueOption,
    }

    // 點數效期變動時，刪除指定天數的值
    if (name === 'pointExpireCond')
      newPointSettingEdit.pointExpireCondNum = null
    setPointSettingEdit(newPointSettingEdit)
  }

  /* 指定隔年月底，此處回傳數字 */
  const handleChangeCustomizedMonthEnd = (event, value, target) => {
    const { name } = target
    setPointSettingEdit({
      ...pointSettingEdit,
      [name]: value,
    })
  }

  /* 獲取點數基本設定資料 */
  const handleBonusSettingData = async () => {
    try {
      dispatch(changeIsBaseSettingLoading(true))
      await dispatch(getPointThunk(brandId))
    } catch (error) {
      handleCatch(error)
    } finally {
      dispatch(changeIsBaseSettingLoading(false))
    }
  }

  /* 父層按下「開始計算」時，跳出編輯狀態 */
  useEffect(() => {
    closeEdit()
  }, [isCalculateLoading])

  /* 初始化表單狀態 */
  useEffect(() => {
    setPointSettingEdit(pointSettingEditDefault)
  }, [pointSettingEditDefault])

  /* 初始化 */
  useEffect(() => {
    if (!brandId) return
    handleBonusSettingData()
  }, [brandId])

  return (
    <>
      {isStartComputeDisabled && (
        <Row>
          <LocalStyle
            className="w-100"
            data-testid="with-calculate-base-setting"
          >
            <StyleCard>
              <div className="d-flex">
                <h3 className="migo-blue">基本設定</h3>
                <div className="base-setting-collapse-container">
                  <div className="base-setting-collapse-item d-flex">
                    <span className="base-setting-collapse-title white-space-nowrap">
                      消費贈點：
                    </span>
                    <div className="base-setting-collapse-content">
                      依消費金額每滿 {amountPerFull} 元，累積 {accumulatePoints}{' '}
                      點，
                      {isMaxPointPerDeal &&
                        `單筆最高累積 ${maxPointPerDeal} 點，`}
                      並於{startCondition}發送點數。
                    </div>
                  </div>
                  <Collapse in={isCollapseOpen}>
                    <div>
                      <div className="base-setting-collapse-item d-flex">
                        <span className="base-setting-collapse-title white-space-nowrap">
                          點數效期：
                        </span>
                        <div className="base-setting-collapse-content">
                          未使用點數將於{expireCondition}到期。
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
                <span className="setting-img-container">
                  <span className="setting-img" onClick={handleCollapseClick}>
                    <img
                      src={isCollapseOpen ? iconCollapse : iconUncollapse}
                      alt=""
                    />
                  </span>
                </span>
              </div>
            </StyleCard>
          </LocalStyle>
        </Row>
      )}

      {!isStartComputeDisabled && (
        <Row data-testid="without-calculate-base-setting">
          <Card
            className="w-100"
            isLoading={isBaseSettingLoading || isStoreLoading}
          >
            <Form noValidate validated={isSubmitValidated} ref={formRef}>
              <Form.Row>
                <Col className="no-padding">
                  <h3 className="migo-blue">基本設定</h3>
                </Col>
                <Col className="no-padding">
                  <ButtonList className="justify-content-end">
                    {isCancelBtnVisible && (
                      <Button
                        variant="outline-gray"
                        onClick={handleCancelClick}
                        size="md"
                      >
                        <IconCancel />
                        取消
                      </Button>
                    )}
                    {isStoreBtnVisible && (
                      <Button
                        variant="outline-primary"
                        size="md"
                        onClick={handleStoreClick}
                      >
                        <IconSave />
                        儲存
                      </Button>
                    )}
                    {isEditBtnVisible && (
                      <PermissionEditButton
                        className="margin-l-16"
                        variant="outline-gray"
                        onClick={handleEditClick}
                        size="md"
                      >
                        <img src={iconEdit} className="setting-img" alt="" />
                        編輯
                      </PermissionEditButton>
                    )}
                  </ButtonList>
                </Col>
              </Form.Row>
              <Form.Row className="margin-t-24">
                <Col className="no-padding">
                  <div className="base-setting-container fz15 d-flex">
                    <span className="base-setting-title white-space-nowrap">
                      消費贈點：
                    </span>
                    <div className="base-setting-content">
                      <Checkbox
                        id="gainPtByConsumption"
                        className="mr-0"
                        name="gainPtByConsumption"
                        inline
                        label="依消費金額"
                        checked={pointSettingEdit?.gainPtByConsumption}
                        disabled={isEditBtnVisible}
                        onChange={handleChangeCheckbox}
                      />
                      ，每滿＄
                      <Input
                        className="base-setting-input-group mr-1"
                        colorType={colorObj.dark}
                        appendContent="元"
                        appendContentBgColor="#fff"
                        appendContentPadding="0 4px 0 0"
                        appendContentHaveBorder={false}
                        isFocusCocatAppend
                        formControlOption={{
                          required: pointSettingEdit?.gainPtByConsumption,
                          disabled:
                            !pointSettingEdit?.gainPtByConsumption ||
                            (pointSettingEdit?.gainPtByConsumption &&
                              isEditBtnVisible),
                          name: 'howManyAmountPerFull',
                          value: pointSettingEdit?.howManyAmountPerFull ?? '',
                          isInvalid:
                            isSubmitValidated &&
                            pointSettingEdit?.gainPtByConsumption &&
                            !pointSettingEdit?.howManyAmountPerFull &&
                            pointSettingEdit?.howManyAmountPerFull !== 0,
                          onInput: fieldRestrictNumberZero,
                          onChange: handleInput,
                        }}
                      />
                      <span className="mx-1">累積</span>
                      <Input
                        className="base-setting-input-group mr-1"
                        colorType={colorObj.dark}
                        appendContent="點"
                        appendContentBgColor="#fff"
                        appendContentPadding="0 4px 0 0"
                        appendContentHaveBorder={false}
                        isFocusCocatAppend
                        formControlOption={{
                          required: !!pointSettingEdit?.gainPtByConsumption,
                          disabled:
                            !pointSettingEdit?.gainPtByConsumption ||
                            (pointSettingEdit?.gainPtByConsumption &&
                              !isStoreBtnVisible),
                          name: 'howManyPointsGive',
                          value: pointSettingEdit?.howManyPointsGive ?? '',
                          isInvalid:
                            isSubmitValidated &&
                            pointSettingEdit?.gainPtByConsumption &&
                            !pointSettingEdit?.howManyPointsGive &&
                            pointSettingEdit?.howManyPointsGive !== 0,
                          onInput: fieldRestrictNumberZero,
                          onChange: handleInput,
                        }}
                      />
                      ，單筆最高累積
                      <Input
                        className="base-setting-input-group ml-1 mr-2"
                        colorType={colorObj.dark}
                        appendContent="點"
                        appendContentBgColor="#fff"
                        appendContentPadding="0 4px 0 0"
                        appendContentHaveBorder={false}
                        isFocusCocatAppend
                        formControlOption={{
                          placeholder: '選填',
                          disabled:
                            !pointSettingEdit?.gainPtByConsumption ||
                            (pointSettingEdit?.gainPtByConsumption &&
                              isEditBtnVisible),
                          name: 'maxPtPerDeal',
                          value: pointSettingEdit?.maxPtPerDeal ?? '',
                          onInput: fieldRestrictNumberZero,
                          onChange: handleInput,
                        }}
                      />
                      <Tooltip
                        triggerElement={
                          <img src={iconTooltip} alt="icon-tooltip" />
                        }
                        globalOption={{
                          effect: 'solid',
                        }}
                      >
                        未填則無累積上限
                      </Tooltip>
                      ，並於
                      <Select
                        className="d-inline-flex mx-1"
                        optionItems={pointStartOptions}
                        selectedValue={pointSettingEdit?.pointStartCond?.id}
                        isClearSelectedValue={!pointSettingEdit?.pointStartCond}
                        dropdownToggleOption={{
                          disabled:
                            !pointSettingEdit?.gainPtByConsumption ||
                            (pointSettingEdit?.gainPtByConsumption &&
                              isEditBtnVisible),
                        }}
                        formControlOption={{
                          placeholder: '請選擇',
                          name: 'pointStartCond',
                          isInvalid:
                            isSubmitValidated &&
                            pointSettingEdit?.gainPtByConsumption &&
                            !pointSettingEdit?.pointStartCond,
                        }}
                        onChange={handleSelect}
                      />
                      發送點數。
                      <InvalidFeedback
                        isShow={isConsumedPointFieldsError && isSubmitValidated}
                      />
                    </div>
                  </div>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="no-padding">
                  <hr />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="no-padding">
                  <div className="base-setting-container fz15 d-flex">
                    <span className="base-setting-title white-space-nowrap">
                      點數效期：
                    </span>
                    <div className="base-setting-content mr-5">
                      未使用點數將於
                      <Select
                        className="d-inline-flex mx-1"
                        optionItems={pointExpireOptions}
                        selectedValue={pointSettingEdit?.pointExpireCond?.id}
                        isClearSelectedValue={
                          !pointSettingEdit?.pointExpireCond
                        }
                        dropdownToggleOption={{
                          disabled: isEditBtnVisible,
                        }}
                        formControlOption={{
                          placeholder: '請選擇',
                          name: 'pointExpireCond',
                          isInvalid:
                            isSubmitValidated &&
                            !pointSettingEdit?.pointExpireCond,
                        }}
                        onChange={handleSelect}
                      />
                      到期。
                      <Tooltip
                        triggerElement={
                          <img src={iconTooltip} alt="icon-tooltip" />
                        }
                        globalOption={{
                          effect: 'solid',
                        }}
                      >
                        因退換貨而產生的負值點數
                        <br />
                        不會因效期而重置
                      </Tooltip>
                      <InvalidFeedback
                        isShow={isPointUseFieldsError && isSubmitValidated}
                      />
                    </div>

                    {/* 指定天數 */}
                    {isCustomizedDays && (
                      <div className="base-setting-container fz15 d-flex">
                        <span className="base-setting-title white-space-nowrap">
                          點數將於：
                        </span>
                        <div className="base-setting-content align-items-start">
                          <Input
                            className="base-setting-input-group mr-1"
                            colorType={colorObj.dark}
                            appendContent="天"
                            appendContentBgColor="#fff"
                            appendContentPadding="0 4px 0 0"
                            appendContentHaveBorder={false}
                            isFocusCocatAppend
                            formControlOption={{
                              required: isCustomizedDays,
                              disabled: isEditBtnVisible,
                              name: 'pointExpireCondNum',
                              value: pointSettingEdit?.pointExpireCondNum ?? '',
                              isInvalid:
                                isSubmitValidated &&
                                !pointSettingEdit?.pointExpireCondNum,
                              onInput: fieldRestrictNumberZero,
                              onChange: handleInput,
                            }}
                          />
                          後到期。
                        </div>
                      </div>
                    )}
                    {/* 指定月底 */}
                    {isCustomizedMonthEnd && (
                      <div className="base-setting-container fz15 d-flex">
                        <span className="base-setting-title white-space-nowrap">
                          點數將於：
                        </span>
                        <div>
                          <div className="base-setting-content">
                            明年
                            <Select
                              className="d-inline-flex mx-1"
                              optionItems={monthOptions}
                              selectedValue={
                                pointSettingEdit?.pointExpireCondNum
                              }
                              dropdownToggleOption={{
                                disabled: isEditBtnVisible,
                              }}
                              formControlOption={{
                                placeholder: '請選擇',
                                name: 'pointExpireCondNum',
                                isInvalid:
                                  isSubmitValidated &&
                                  !pointSettingEdit?.pointExpireCondNum,
                              }}
                              onChange={handleChangeCustomizedMonthEnd}
                            />
                            底到期。
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Form.Row>
            </Form>
          </Card>
        </Row>
      )}
    </>
  )
}

// 之後可以使用 CustomInvalidFeedback
// 調整時要一併處理 css 和檔案位置
const InvalidFeedback = ({ isShow }) => {
  return isShow && <div className="custom-invalid-feedback w-100">尚未填寫</div>
}

export default BaseSetting
