import React, { useRef, useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { format, startOfDay } from 'date-fns'
import { Calendar as DefaultCalendar } from 'react-date-range'
import { zhTW } from 'react-date-range/dist/locale'
import { StyleButton } from 'components/units/Button'
import { StyleInput } from 'components/units/Input'
import { colorObj } from 'assets/styles/Variable/Color'
import iconCalendar from '../../assets/images/icon_calendar.svg'

const Wrapper = styled.div`
  .form-calendar,
  .form-calendar input:disabled {
    cursor: pointer;
  }

  .datepicker {
    box-shadow: 0 0 10px #888;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }

  .datepicker-footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  .__react_component_tooltip {
    &.show {
      background: ${colorObj.white};
      opacity: 1;
    }
  }

  ${(props) =>
    props.isValid === false &&
    css`
      .input-group-text,
      .form-calendar input:disabled {
        border-color: ${colorObj.danger} !important;
      }
    `}
`

const getRandomIntId = (max = 1000000) => Math.floor(Math.random() * max)

function Calendar({
  defaultValue = startOfDay(new Date()),
  value = null,
  onChange = null,
  disabled = false,
  minDate = undefined,
  maxDate = undefined,
  isValid = null,
}) {
  const [dateValue, setDateValue] = useState(defaultValue)
  const id = useRef(getRandomIntId()).current
  const elRef = useRef(null)

  const handleSelect = useCallback(
    (date) => {
      setDateValue(date)
      if (typeof onChange === 'function') {
        onChange(date)
      }
    },
    [onChange]
  )

  const handleReset = useCallback(() => {
    const initDate = startOfDay(new Date())
    setDateValue(initDate)
    if (typeof onChange === 'function') {
      onChange(initDate)
    }
  }, [onChange])

  const handleDatepickerClose = useCallback(() => {
    elRef.current.tooltipRef = null
    ReactTooltip.hide()
  }, [])

  useEffect(() => {
    if (value !== null && dateValue !== value) {
      setDateValue(value)
    }
  }, [value, dateValue])

  return (
    <Wrapper isValid={isValid}>
      <div
        className="form-calendar"
        data-tip="tooltip"
        data-for={`datepicker-${id}`}
        data-event="click"
      >
        <StyleInput
          prependContent={<img src={iconCalendar} alt="icon-calendar" />}
          prependContentHaveBorder={false}
          prependContentBgColor="transparent"
          formControlOption={{
            value: dateValue ? format(dateValue, 'yyyy/MM/dd') : '',
            disabled: true,
          }}
        />
      </div>
      <ReactTooltip
        className="datepicker"
        id={`datepicker-${id}`}
        clickable
        type="light"
        place="bottom"
        effect="solid"
        ref={elRef}
        disable={disabled}
      >
        {dateValue && (
          <DefaultCalendar
            date={dateValue}
            onChange={handleSelect}
            color={colorObj.primary}
            locale={zhTW}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
        <div className="datepicker-footer">
          <StyleButton
            variant="outline-gray"
            colorType="gray"
            colorAlpha="1"
            size="md"
            onClick={handleReset}
          >
            清除
          </StyleButton>
          <StyleButton
            variant="outline-gray"
            colorType="gray"
            colorAlpha="1"
            size="md"
            onClick={handleDatepickerClose}
          >
            關閉
          </StyleButton>
        </div>
      </ReactTooltip>
    </Wrapper>
  )
}

export default Calendar
