import { useContext, useEffect, useRef, useState } from 'react'
import { Modal, Form, Col, Row, Container } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { postMemberShipLevel, patchMemberShipLevel } from 'api/ApiMain'
import { RootContext } from 'RootContext'
import { Button, Input, Loader, Radio, Select, Tooltip } from 'components/units'
import iconTooltip from 'assets/images/icon_tooltip.svg'
import ConditionForm from './RuleSettingModalSubComponent'
import LocalStyle from './RuleSettingModalStyle'
import handleCatch from '../MembershipSetting/MembershipSettingHelper'
import {
  findSelectedItem,
  isNonEmptyArray,
  convertToType,
  trimFormString,
} from 'helpers/common'

const UpdateType = {
  DATA_UPDATE: 2,
  SYSTEM_CALCULATE: 1,
}

function RuleSettingModal({
  isEdit,
  membershipLevelId,
  accumulativeWay, // 會級累積方式(由內部baseSettingUse參數決定)
  isShowModalRuleSetting,
  isLoadingModalRuleSetting,
  iconModalClose,
  modalData, // 關於select、checkbox之類的options資料
  ruleSettingRowsData, // 表格的所有資料
  inputValuesData,
  dispatchBtnsState,
  handleRuleSettingModalShow,
  updateRuleSettingRowsData,
}) {
  // global data
  const rootData = useContext(RootContext)

  // 創建副本並使用升冪排序表格資料
  const sortAscRuleSettingRowsData = ruleSettingRowsData
    .slice()
    .sort((a, b) => a.membershipLevelId - b.membershipLevelId)

  // 表單ref
  const formRef = useRef(null)

  // 表單form是否驗證欄位規則
  const [validated, setValidated] = useState(false)
  // 表單欄位值
  const [memberLevelForm, setMemberLevelForm] = useState({
    name: null,
    type: null,
    validPeriod: null,
    caculPeriod: null,
    upgradeList: [], // 升等
    renewList: [], // 續會
  })

  const [isConfirmBtnLoading, setConfirmBtnLoading] = useState(false)

  // 處理升等條件：紀錄編輯條件的上一個會級等級的單筆消費最大值、累計消費最大值
  const memberShipConditionMaxData = useRef({
    single_ge: null,
    accumulation_ge: null,
  })
  // 處理升等條件：紀錄編輯條件的下一個會級等級的單筆消費最小值、累計消費最小值
  const memberShipConditionMinData = useRef({
    single_ge: null,
    accumulation_ge: null,
  })

  // modal-會員等級設定的欄位errors，對應的驗證規則要顯示的文字
  const requiredText = '尚未填寫'
  const fieldsError = {
    name: requiredText,
    type: requiredText,
    single_ge: requiredText,
    accumulation_ge: requiredText,
    consumption_num_ge: requiredText,
    memberShipCondition: requiredText,
    validPeriod: requiredText,
    caculPeriod: requiredText,
  }

  // modal-升等、續會條件 > 依系統計算的各項欄位初始值
  const conditionDefault = {
    checkboxSingleGe: false, // 單筆消費的checkbox
    checkboxAccumulationGe: false, // 單筆消費的checkbox
    checkboxConsumptionNumGe: false, // 單筆消費的checkbox
    single_ge: null, // 單筆消費的input
    accumulation_ge: null, // 累積消費的input
    consumption_num_ge: null, // 累積次數的input
  }
  // 升等條件
  const [upgradeCondition, setUpgradeCondition] = useState(conditionDefault)
  // 續會條件
  const [renewCondition, setRenewCondition] = useState(conditionDefault)

  // 升等條件欄位說明
  const upgradeConditionInfo =
    '當會員同時符合多個升等條件，依排序優先的條件升等'
  // 續會條件欄位說明
  const renewConditionInfo = '當會員同時符合多個續會條件，依排序優先的條件續會'

  // select 的option 配置
  const handleSelectOption = (arrData) => {
    const newArrData = []
    if (Array.isArray(arrData)) {
      for (const [dataIndex, dataItem] of arrData.entries()) {
        const { name, id } = dataItem
        newArrData.push({
          text: name,
          value: id,
          dropdownItemOption: {
            eventKey: dataIndex,
          },
        })
      }
    }
    return newArrData
  }

  // 名稱欄位 onChange 處理
  const handleChangeLevelName = ({ target }) => {
    const { name, value } = target
    setMemberLevelForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  // radio onChange 處理
  const handleRadio = ({ target }) => {
    const valueType = target?.dataset?.valueType
    const { name, value } = target
    const finalValue = convertToType(valueType, value)

    setMemberLevelForm((prevInputValues) => {
      return {
        ...prevInputValues,
        [name]: finalValue,
      }
    })
  }

  // select onChange 處理
  const handleSelect = ({ target, eventKey, optionItems }) => {
    const name = target?.name
    const newInputValues = optionItems[eventKey]
    setMemberLevelForm((prevInputValues) => {
      delete newInputValues.selected
      return {
        ...prevInputValues,
        [name]: newInputValues,
      }
    })
  }

  // 客制判斷處理
  const checkExtraValidation = () => {
    const { validPeriod, type, upgradeList, renewList, caculPeriod } =
      memberLevelForm
    const { SYSTEM_CALCULATE } = UpdateType
    // 未選擇會員效期
    // 未勾選升等條件
    // 選擇依系統計算，但沒訂下任何升等條件
    // 會員效期選擇永久，但未選擇累積區間
    // 會員效期選擇非永久，但沒訂下任何續會條件
    if (
      !validPeriod ||
      !type ||
      (type === SYSTEM_CALCULATE && !isNonEmptyArray(upgradeList)) ||
      (type === SYSTEM_CALCULATE && validPeriod.id === 1 && !caculPeriod) ||
      (validPeriod.id !== 1 && !isNonEmptyArray(renewList))
    ) {
      return false
    }
    return true
  }

  // 表單發送處理
  const handleSubmit = async (e) => {
    e.preventDefault()

    // 整理表單資料 (去除空白等)
    const formattedForm = trimFormString(memberLevelForm)
    setMemberLevelForm(formattedForm)

    const form = formRef.current
    // 判斷form的欄位中，有填寫name值及驗證規則(required、pattern)的項目
    const isValidated = form.checkValidity()
    setValidated(true)
    if (!isValidated) return

    // 客制判斷
    const isExtraValidated = checkExtraValidation()
    if (!isExtraValidated) return

    setConfirmBtnLoading(true)
    try {
      if (isEdit) {
        await patchMemberShipLevel(
          rootData.brandId,
          membershipLevelId,
          formattedForm
        )
        toast.success('編輯會級成功')
      } else {
        await postMemberShipLevel(rootData.brandId, formattedForm)
        toast.success('新增會級成功')
        dispatchBtnsState({
          type: 'disabled',
          task: [{ others: { startCalculate: false } }],
        })
      }
      handleRuleSettingModalShow(false)
      updateRuleSettingRowsData()
    } catch (error) {
      handleCatch(error)
    } finally {
      setConfirmBtnLoading(false)
    }
  }

  // 會員效期選擇
  const handleValidPeriodChange = (value, formControlRef, eventKey) => {
    handleSelect({
      target: formControlRef,
      optionItems: modalData?.membershipValidPeriodList,
      eventKey,
    })
    // 會員效期永久，續會條件不用給值; 會員效期不永久，累積區間不用給值
    setMemberLevelForm((prevState) => ({
      ...prevState,
      renewList: value === 1 ? [] : prevState.renewList,
      caculPeriod: value === 1 ? prevState.caculPeriod : null,
    }))
  }

  // 升等條件選依資料更新
  const handleConditionForDataUpdate = () => {
    setMemberLevelForm((prevState) => {
      const getIdOne = (item) => item.id === 1
      // 會員效期：永久
      const validPeriod = modalData?.membershipValidPeriodList?.find(getIdOne)
      delete validPeriod.selected
      // 累積區間：無限期
      const caculPeriod = modalData?.membershipCaculPeriodList?.find(getIdOne)
      delete caculPeriod.selected
      return {
        ...prevState,
        upgradeList: [],
        renewList: [],
        validPeriod,
        caculPeriod,
      }
    })
    setUpgradeCondition(conditionDefault)
    setRenewCondition(conditionDefault)
  }

  // 升等條件選依系統計算
  const handleConditionForSystemCalculate = () => {
    setMemberLevelForm((prevState) => {
      return {
        ...prevState,
        upgradeList: [],
        renewList: [],
        validPeriod: null,
        caculPeriod: null,
      }
    })
  }

  // 返回等級字串
  const generateLevelCode = () => {
    if (isEdit) return inputValuesData?.levelCode

    const lastLevelCode =
      sortAscRuleSettingRowsData?.[sortAscRuleSettingRowsData.length - 1]?.level
        ?.code

    if (lastLevelCode) {
      const numericPart = lastLevelCode.replace(/\D/g, '')
      const newNumericPart = parseInt(numericPart, 10) + 1
      return `${lastLevelCode.replace(/\d/g, '')}${newNumericPart}`
    }

    return 'Lv1'
  }

  // 處理升等條件：單筆消費、累計消費的值小於上一級的最大值時，要顯示的提醒文字
  const handleConditionFieldRemind = (name, value) => {
    let feedbackText = ''
    const parsedValue = parseInt(value, 10)
    if (!Array.isArray(sortAscRuleSettingRowsData)) return ''

    // 最大邊界及最小邊界的判斷
    const highBoundary = (data) => parseInt(data, 10) && parsedValue >= data
    const lowBoundary = (data) => parseInt(data, 10) && parsedValue < data

    // 上一等級最大值和下一等級最小值
    const prevLevelMaxData = memberShipConditionMaxData.current[name]
    const nextLevelMinData = memberShipConditionMinData.current[name]

    const isLowerThanPrevLevel = lowBoundary(prevLevelMaxData)
    const isHigherThanNextLevel = highBoundary(nextLevelMinData)

    // 若無 index 必須變成 undefined，若維持 -1 或 null 會造成之後取到第 0 筆資料
    const sameLevelId = (item) => item.membershipLevelId === membershipLevelId
    let currentIndex = sortAscRuleSettingRowsData.findIndex(sameLevelId)
    currentIndex = currentIndex === -1 ? undefined : currentIndex

    // 獲取上一等級名稱
    const prevIndex = membershipLevelId
      ? currentIndex - 1
      : sortAscRuleSettingRowsData.length - 1
    const prevLevelName = sortAscRuleSettingRowsData[prevIndex]?.level?.code

    // 獲取下一等級名稱
    const nextIndex = currentIndex + 1
    const nextLevelName = sortAscRuleSettingRowsData[nextIndex]?.level?.code

    if (isLowerThanPrevLevel && isHigherThanNextLevel) {
      feedbackText = `*數值低於 ${prevLevelName} 之會級，且數值高於 ${nextLevelName} 之會級`
    } else if (isLowerThanPrevLevel) {
      feedbackText = `*數值低於 ${prevLevelName} 之會級`
    } else if (isHigherThanNextLevel) {
      feedbackText = `*數值高於 ${nextLevelName} 之會級`
    }

    return feedbackText
  }

  // 欄位初始化
  useEffect(() => {
    setMemberLevelForm({
      name: inputValuesData?.name ?? null,
      type: inputValuesData?.type,
      validPeriod: findSelectedItem(inputValuesData?.validPeriodList),
      caculPeriod: findSelectedItem(inputValuesData?.caculPeriodList),
      upgradeList: inputValuesData?.upgradeList ?? [], // 升等
      renewList: inputValuesData?.renewList ?? [], // 續會
    })
  }, [inputValuesData])

  // 處理升等條件：挑選出單筆消費、累計消費的上一級最大值與下一級最小值
  useEffect(() => {
    function processLevelData(levelList, targetData, sortOrder) {
      if (isNonEmptyArray(levelList)) {
        const singleGeList = levelList.map((item) => item.single_ge)
        const accumulationGeList = levelList.map((item) => item.accumulation_ge)

        // 根據規則排序
        singleGeList.sort((a, b) => sortOrder * (a - b))
        accumulationGeList.sort((a, b) => sortOrder * (a - b))

        // 目標 ref 的更新
        targetData.current = {
          single_ge: singleGeList[0],
          accumulation_ge: accumulationGeList[0],
        }
      }
    }

    // 若無 index 必須變成 undefined，若維持 -1 或 null 會造成之後取到第 0 筆資料
    const sameLevelId = (item) => item.membershipLevelId === membershipLevelId
    let currentIndex = sortAscRuleSettingRowsData.findIndex(sameLevelId)
    currentIndex = currentIndex === -1 ? undefined : currentIndex

    // 取上一等級資料：「修改」就取目前的上一個等級，「新增」就取最後一個會級
    const prevIndex = membershipLevelId
      ? currentIndex - 1
      : sortAscRuleSettingRowsData.length - 1
    const prevLevelList = sortAscRuleSettingRowsData[prevIndex]?.upgrade?.list

    // 取下一等級資料
    const nextIndex = currentIndex + 1
    const nextLevelList = sortAscRuleSettingRowsData[nextIndex]?.upgrade?.list

    processLevelData(prevLevelList, memberShipConditionMaxData, -1) // 降冪
    processLevelData(nextLevelList, memberShipConditionMinData, 1) // 升冪
  }, [membershipLevelId, sortAscRuleSettingRowsData])

  return (
    <LocalStyle
      backdrop="static"
      show={isShowModalRuleSetting}
      onHide={() => handleRuleSettingModalShow(false)}
      centered
      className="point-setting-modal-conatiner"
    >
      <Modal.Header>
        <Modal.Title>會員等級設定</Modal.Title>
        <div>
          <img
            src={iconModalClose}
            className="setting-img"
            alt=""
            onClick={() => handleRuleSettingModalShow(false)}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} ref={formRef}>
          <Form.Group>
            <Form.Row className="mb-3">
              <Form.Label>
                <div className="list-title">等級：</div>
              </Form.Label>
              <Col>{generateLevelCode()}</Col>
            </Form.Row>
            <Form.Row className="mb-3">
              <Form.Label>
                <div className="list-title">名稱：</div>
              </Form.Label>
              <Col>
                <Input
                  formControlOption={{
                    required: true,
                    name: 'name',
                    value: memberLevelForm?.name ?? '',
                    onChange: handleChangeLevelName,
                    maxlength: '50',
                    pattern: '^\\s*\\S.*$',
                  }}
                  feedbackText={fieldsError?.name}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Form.Label>
                <div className="list-title">升等條件：</div>
              </Form.Label>
              <Col>
                <Radio
                  label={
                    <>
                      依資料更新
                      <Tooltip
                        className="ml-1"
                        triggerElement={
                          <img src={iconTooltip} alt="icon-tooltip" />
                        }
                      >
                        若新增下一等級，
                        <br />
                        需同為「依資料更新」
                      </Tooltip>
                    </>
                  }
                  id={UpdateType.DATA_UPDATE}
                  name="type"
                  checked={memberLevelForm?.type === UpdateType.DATA_UPDATE}
                  value={2}
                  onChange={(e) => {
                    handleRadio(e)
                    handleConditionForDataUpdate()
                  }}
                />
                <Radio
                  className="mt-3 mb-2"
                  label="依系統計算"
                  id={UpdateType.SYSTEM_CALCULATE}
                  name="type"
                  checked={
                    memberLevelForm?.type === UpdateType.SYSTEM_CALCULATE
                  }
                  value={1}
                  onChange={(e) => {
                    handleRadio(e)
                    handleConditionForSystemCalculate()
                  }}
                />
                {validated && !memberLevelForm?.type && (
                  <div className="custom-invalid-feedback pl-2">
                    {fieldsError?.type}
                  </div>
                )}

                {memberLevelForm?.type === UpdateType.SYSTEM_CALCULATE && (
                  <ConditionForm
                    formName="upgrade"
                    memberLevelForm={memberLevelForm}
                    validated={validated}
                    accumulativeWay={accumulativeWay}
                    condition={upgradeCondition}
                    onConditionFieldRemind={handleConditionFieldRemind}
                    fieldsError={fieldsError}
                    setCondition={setUpgradeCondition}
                    setMemberLevelForm={setMemberLevelForm}
                    conditionInfo={upgradeConditionInfo}
                  />
                )}
              </Col>
            </Form.Row>
            {
              // 升等條件選依系統計算
              memberLevelForm?.type === UpdateType.SYSTEM_CALCULATE && (
                <Form.Row className="mt-3">
                  <Form.Label>
                    <div className="list-title">會員效期：</div>
                  </Form.Label>
                  <Col>
                    <Select
                      optionItems={handleSelectOption(
                        modalData?.membershipValidPeriodList
                      )}
                      selectedValue={
                        memberLevelForm && memberLevelForm?.validPeriod?.id
                      }
                      formControlOption={{
                        placeholder: '請選擇',
                        name: 'validPeriod',
                        isInvalid: validated && !memberLevelForm?.validPeriod,
                      }}
                      feedbackText={fieldsError?.validPeriod}
                      dropdownToggleOption={{
                        className: 'w-100',
                      }}
                      // 這邊的onChange並非對應到formControl的onChange，因此才需要另外設計成以下格式
                      onChange={(event, value, formControlRef, eventKey) => {
                        handleValidPeriodChange(value, formControlRef, eventKey)
                      }}
                    />
                  </Col>
                </Form.Row>
              )
            }
            {
              // 升等條件選依系統計算
              memberLevelForm?.type === UpdateType.SYSTEM_CALCULATE &&
                // 會員效期選擇永久
                memberLevelForm?.validPeriod?.id === 1 && (
                  <Form.Row className="mt-3">
                    <Form.Label>
                      <div className="list-title">累積區間：</div>
                    </Form.Label>
                    <Col>
                      <Select
                        optionItems={handleSelectOption(
                          modalData?.membershipCaculPeriodList
                        )}
                        selectedValue={
                          memberLevelForm && memberLevelForm?.caculPeriod?.id
                        }
                        formControlOption={{
                          placeholder: '請選擇',
                          name: 'caculPeriod',
                          isInvalid: validated && !memberLevelForm?.caculPeriod,
                        }}
                        feedbackText={fieldsError?.caculPeriod}
                        dropdownToggleOption={{
                          className: 'w-100',
                        }}
                        // 這邊的onChange並非對應到formControl的onChange，因此才需要另外設計成以下格式
                        onChange={(event, value, formControlRef, eventKey) => {
                          handleSelect({
                            target: formControlRef,
                            optionItems: modalData?.membershipCaculPeriodList,
                            eventKey,
                          })
                        }}
                      />
                    </Col>
                  </Form.Row>
                )
            }

            {
              // 有選擇會員效期 && 會員效期選擇非永久
              memberLevelForm?.validPeriod?.id &&
                memberLevelForm?.validPeriod?.id !== 1 && (
                  <Form.Row className="mt-3">
                    <Form.Label>
                      <div className="list-title">續會條件：</div>
                    </Form.Label>
                    <Col>
                      <ConditionForm
                        formName="renew"
                        memberLevelForm={memberLevelForm}
                        validated={validated}
                        accumulativeWay={accumulativeWay}
                        condition={renewCondition}
                        fieldsError={fieldsError}
                        setCondition={setRenewCondition}
                        setMemberLevelForm={setMemberLevelForm}
                        isShowSyncButton
                        conditionInfo={renewConditionInfo}
                      />
                    </Col>
                  </Form.Row>
                )
            }
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Container className="m-0">
          <Row className="m-0">
            <Col className="text-right">
              <Button
                variant="outline-gray"
                disabled={isLoadingModalRuleSetting}
                onClick={() => handleRuleSettingModalShow(false)}
              >
                取消
              </Button>
            </Col>
            <Col>
              <Button
                isLoading={isConfirmBtnLoading}
                disabled={isLoadingModalRuleSetting}
                onClick={handleSubmit}
              >
                儲存
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </LocalStyle>
  )
}

export default RuleSettingModal
