import PropTypes from 'prop-types'
import PlainButton from 'components/units/PlainButton'
import { downloadCsv } from 'helpers/common'

export default function ToastFooter({
  reportLink = '',
  redirectFn = undefined,
}) {
  return (
    <>
      <span className={`${reportLink ? 'margin-r-20' : 'd-none'}`}>
        <PlainButton onClick={() => downloadCsv({ url: reportLink })}>
          下載錯誤報告
        </PlainButton>
      </span>
      <PlainButton
        className={`${!redirectFn && 'd-none'}`}
        onClick={redirectFn}
      >
        前往列表
      </PlainButton>
    </>
  )
}

ToastFooter.propTypes = {
  reportLink: PropTypes.string,
  redirectFn: PropTypes.func,
}

ToastFooter.defaultProps = {
  reportLink: '',
  redirectFn: undefined,
}
