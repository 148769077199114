import {
  createAsyncThunk,
  createSlice,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit'
import { patchTokenValidity, getResetPasswordTokenValidity } from 'api/ApiMain'
import {
  ENABLE_ACCOUNT,
  accountRedirectPaths,
  RESET_PASSWORD,
  passwordRedirectPaths,
} from 'constant/verify'
import { ACCOUNT_VERIFY } from 'store/constants'

export const accountVerifyInitState = {
  token: '',
  verifyType: '',
  patchTokenValidityRsp: {
    data: {
      status: null,
    },
    code: null,
    msg: '',
    errors: [],
    isFetch: false,
  },
  getResetPasswordTokenValidityRsp: {
    data: {
      status: 1,
      statusDescreption: '',
    },
    code: null,
    msg: '',
    errors: [],
    isFetch: false,
  },
}

export const patchTokenValidityThunk = createAsyncThunk(
  `${ACCOUNT_VERIFY}/patchTokenValidity`,
  async (token, { rejectWithValue }) => {
    try {
      const response = await patchTokenValidity({ token })
      return response
    } catch (error) {
      return rejectWithValue(error.data)
    }
  }
)

export const getResetPasswordTokenValidityThunk = createAsyncThunk(
  `${ACCOUNT_VERIFY}/getResetPasswordTokenValidity`,
  async (token, { rejectWithValue }) => {
    try {
      const response = await getResetPasswordTokenValidity(token)
      return response
    } catch (error) {
      return rejectWithValue(error.data)
    }
  }
)

const accountVerifySlice = createSlice({
  name: ACCOUNT_VERIFY,
  initialState: accountVerifyInitState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload
    },
    updateVerifyType: (state, action) => {
      state.verifyType = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        patchTokenValidityThunk.fulfilled,
        patchTokenValidityThunk.rejected
      ),
      (state, action) => {
        state.patchTokenValidityRsp = action.payload
        state.patchTokenValidityRsp.isFetch = true
      }
    )
    builder.addMatcher(
      isAnyOf(
        getResetPasswordTokenValidityThunk.fulfilled,
        getResetPasswordTokenValidityThunk.rejected
      ),
      (state, action) => {
        state.getResetPasswordTokenValidityRsp = action.payload
        state.getResetPasswordTokenValidityRsp.isFetch = true
      }
    )
  },
})

const redirectDataSelector = createSelector(
  (state) => state[ACCOUNT_VERIFY].patchTokenValidityRsp,
  (patchTokenValidityRsp) => {
    return {
      redirectPath:
        accountRedirectPaths[patchTokenValidityRsp?.data?.status] || '/',
      isFetch: patchTokenValidityRsp?.isFetch || false,
      isSuccess: patchTokenValidityRsp?.data?.status === ENABLE_ACCOUNT.ENABLED,
    }
  }
)

const resetPasswordRedirectDataSelector = createSelector(
  (state) => state[ACCOUNT_VERIFY].getResetPasswordTokenValidityRsp,
  (getResetPasswordTokenValidityRsp) => {
    return {
      redirectPath:
        passwordRedirectPaths[getResetPasswordTokenValidityRsp?.data?.status] ||
        '/',
      isFetch: getResetPasswordTokenValidityRsp?.isFetch || false,
      isReset:
        getResetPasswordTokenValidityRsp?.data?.status ===
        RESET_PASSWORD.HAS_RESET,
    }
  }
)

const tokenSelector = createSelector(
  (state) => state[ACCOUNT_VERIFY].token,
  (state) => state[ACCOUNT_VERIFY].verifyType,
  (token, verifyType) => {
    return {
      token: token || '',
      type: verifyType,
    }
  }
)

export const { updateToken, updateVerifyType } = accountVerifySlice.actions
export {
  redirectDataSelector,
  tokenSelector,
  resetPasswordRedirectDataSelector,
}
export default accountVerifySlice.reducer
