import { Col, Container, Modal, Row } from 'react-bootstrap'
import LocalStyle from './DeleteWarningModalStyle'
import iconModalClose from '../../../assets/images/modal/icon_modalClose.svg'
import { StyleButton } from '../../units/Button'

function DeleteWarningModal(props) {
  const { isShowModal } = props

  // 事件
  const { handleModalClose, handleModalConfirm } = props

  return (
    <LocalStyle show={isShowModal} onHide={handleModalClose} centered>
      <Modal.Header>
        <Modal.Title>即將刪除</Modal.Title>
        <div>
          <img
            src={iconModalClose}
            className="setting-img"
            alt=""
            onClick={handleModalClose}
          />
        </div>
      </Modal.Header>
      <div className="modal-subtitle fz18">
        變更升等條件，
        <br /> 將刪除已設定之會員等級。
        <br /> 是否繼續執行？
      </div>
      <Modal.Body />
      <Modal.Footer>
        <Container className="m-0">
          <Row className="m-0">
            <Col className="text-right">
              <StyleButton
                className="btn-medium"
                variant="outline-gray"
                onClick={handleModalClose}
              >
                返回
              </StyleButton>
            </Col>
            <Col>
              <StyleButton
                className="btn-medium"
                variant="danger"
                onClick={handleModalConfirm}
              >
                繼續
              </StyleButton>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </LocalStyle>
  )
}

export default DeleteWarningModal
