import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAuth,
  getAuthCompanies,
  getAuthCompanyBrands,
  getAuthCompanyBrandShops,
  postAuth,
  postPasswordReset,
} from 'api/ApiMain'
import { authAccountSelector } from 'store/auth/authSelector'
import { AUTH } from 'store/constants'

const getAuthCompaniesThunk = createAsyncThunk(
  `${AUTH}/getAuthCompanies`,
  async (payload, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const { companyIndex, companies } = authAccountSelector(state)
      const currentCompanyName = companies[companyIndex]?.name
      const rsp = await getAuthCompanies()
      const findIndex = rsp.data.companies.findIndex(
        (company) => company.name === currentCompanyName
      )
      return { ...rsp, index: Math.max(findIndex, 0) }
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const getAuthCompanyBrandsThunk = createAsyncThunk(
  `${AUTH}/getAuthCompanyBrands`,
  async (payload, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const { companyId, brands, brandIndex } = authAccountSelector(state)
      const currentBrandName = brands[brandIndex]?.name
      const rsp = await getAuthCompanyBrands(companyId)
      const findIndex = rsp.data.brands.findIndex(
        (brand) => brand.name === currentBrandName
      )
      return { ...rsp, index: Math.max(findIndex, 0) }
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const getAuthCompanyBrandShopsThunk = createAsyncThunk(
  `${AUTH}/getAuthCompanyBrandShops`,
  async (payload, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const { companyId, brandId, shops, shopIndex } =
        authAccountSelector(state)
      const currentShopName = shops[shopIndex]?.name
      const rsp = await getAuthCompanyBrandShops(companyId, brandId)
      const findIndex = rsp.data.shops.findIndex(
        (shop) => shop.name === currentShopName
      )
      return { ...rsp, index: Math.max(findIndex, 0) }
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const getAuthThunk = createAsyncThunk(
  `${AUTH}/getAuth`,
  async (payload, { rejectWithValue }) => {
    try {
      const rsp = await getAuth()
      return rsp
    } catch (err) {
      return rejectWithValue(err.data)
    }
  }
)

const postAuthThunk = createAsyncThunk(
  `${AUTH}/postAuth`,
  async (loginFormData) => {
    try {
      const rsp = await postAuth(loginFormData)
      const { status, accessToken } = rsp
      // 需將 token 先存入 localStorage 以便後續 API 使用
      if (status === 0) {
        localStorage.setItem('token', JSON.stringify({ accessToken }))
      }
      return rsp
    } catch (err) {
      return err.data
    }
  }
)
const postPasswordResetThunk = createAsyncThunk(
  `${AUTH}/postPasswordReset`,
  async (passwordResetFormData) => {
    try {
      // 只需顯示 toast 上層已有處理
      const rsp = await postPasswordReset(passwordResetFormData)
      return rsp
    } catch (err) {
      // 上層已有處理，此處不再處理
      return err
    }
  }
)

export {
  getAuthCompaniesThunk,
  getAuthCompanyBrandsThunk,
  getAuthCompanyBrandShopsThunk,
  postAuthThunk,
  getAuthThunk,
  postPasswordResetThunk,
}
