import differenceInDays from 'date-fns/differenceInDays'

// 欄位不符規定要被replace掉的正則(可用在輸入欄位不符規定，在keyin的時候連打都不能打)
const regexRule = {
  number: /\D+/g,
  nonZeroNumber: /[^1-9]/,
  numberEnglishWordsASCII: /^([-.$/+%!"&'()*,:;<=>?@[\]^_`{|}~\w]+)$/, // 只能輸入英文、數字和ASCII特殊符號
}

// 欄位的正則(可用在欄位pattern)
const regexRuleNormal = {
  password: `(^(?![\\s\\S]*\\s)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{10,})|(^(?![\\s\\S]*\\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\p{S}\\p{P}]).{10,})|(^(?![\\s\\S]*\\s)(?=.*\\d)(?=.*[\\p{S}\\p{P}]).{10,})$`,
  email: '^[a-zA-Z0-9+._\\-]+@[a-zA-Z0-9._\\-]+\\.[a-zA-Z0-9_\\-]+$',
  numberWithNegative: '^-?(?![0])\\d+$|^[0]$',
  name: '^\\s*\\S.*\\s*$',
}

// 欄位只允許數字
const fieldRestrictNumber = (e) => {
  return (e.target.value = e.target.value.replace(regexRule.number, ''))
}

// 欄位只允許數字且不允許第一個字輸入0
const fieldRestrictNumberZero = (e) => {
  if (e.target.value.length === 1) {
    return (e.target.value = e.target.value.replace(
      regexRule.nonZeroNumber,
      ''
    ))
  }
  return (e.target.value = e.target.value.replace(regexRule.number, ''))
}

const fieldRestrictPercent = (e) => {
  const matchResult = new RegExp(/^[1-9][0-9]?$|^100$/).test(e.target.value)
  return matchResult ? e.target.value : String(e.target.value).slice(0, -1)
}

const fieldRestrictAsciiCharacterExceptWhitespace = (e) => {
  const matchResult = new RegExp(regexRule.numberEnglishWordsASCII).test(
    e.target.value
  )
  return matchResult ? e.target.value : String(e.target.value).slice(0, -1)
}

// 日期欄位查詢區間是否超過 N 天
const fieldRestrictPeriod = ({ endDate, startDate }, ndays) => {
  if (typeof endDate === 'string') endDate = new Date(endDate)
  if (typeof startDate === 'string') startDate = new Date(startDate)
  const differenceDays = differenceInDays(endDate, startDate)
  return differenceDays >= ndays
}

export {
  fieldRestrictNumber,
  fieldRestrictNumberZero,
  regexRule,
  regexRuleNormal,
  fieldRestrictPercent,
  fieldRestrictAsciiCharacterExceptWhitespace,
  fieldRestrictPeriod,
}
