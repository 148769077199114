import { useState, createContext, useEffect } from 'react'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { useDispatch } from 'react-redux'
import { PointActivityPage } from 'components/pages/ActivitiesCode'
import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb } from 'components/units'
import { updatePageLoading } from 'store/global/globalSlice'

const settingPageType = {
  new: 'new',
  edit: 'edit',
  view: 'view',
}

const paginationOption = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

const defaultSort = {
  so: 'desc',
  sf: 'no',
}

const PointActivityContext = createContext()
PointActivityContext.displayName = 'PointActivityContext'

/**
 * @description 點數活動頁面進入點
 * @returns {JSX.Element}
 * @constructor
 */
function PointActivity() {
  const dispatch = useDispatch()
  const { PointActivityList } = PointActivityPage
  const [event, setEvent] = useState({
    usingMaccActivity: false,
    usingOfflineShop: false,
  })
  const [totalSize, setTotalSize] = useState(0)
  const [apiPayload, setApiPayload] = useState({
    ps: 25,
    p: 1,
    ...defaultSort,
  })
  useEffect(() => {
    dispatch(updatePageLoading(false))
  })
  const paginationFn = {
    onPageChange: (page) => setApiPayload((prev) => ({ ...prev, p: page })),
    onSizePerPageChange: (sizePerPage, page) =>
      setApiPayload((prev) => ({ ...prev, ps: sizePerPage, p: page })),
  }
  return (
    <PointActivityContext.Provider
      value={{
        apiPayload,
        setApiPayload,
        defaultSort,
        setTotalSize,
        event,
        setEvent,
      }}
    >
      <Basic navSection={NavSection}>
        <ContentSection>
          <PaginationProvider
            pagination={paginationFactory({
              ...paginationOption,
              ...paginationFn,
              totalSize,
              page: apiPayload.p,
              sizePerPage: apiPayload.ps,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <PointActivityList
                paginationProps={paginationProps}
                paginationTableProps={paginationTableProps}
                apiPayload={apiPayload}
                setApiPayload={setApiPayload}
                setTotalSize={setTotalSize}
              />
            )}
          </PaginationProvider>
        </ContentSection>
      </Basic>
    </PointActivityContext.Provider>
  )
}

function NavSection() {
  const BreadcrumbConfig = {
    now: '點數活動',
    pathList: [{ title: '活動條碼 ', slash: true }],
    description: '針對單筆消費，做使用點數折抵金額、額外贈點之活動內容',
  }
  return <Breadcrumb {...BreadcrumbConfig} />
}

export default PointActivity
export { PointActivityContext, settingPageType }
