import { Suspense, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab'
import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb, Button, Loader, Modal } from 'components/units'
import { ReactComponent as IconCancel } from 'assets/images/button/icon_cancel.svg'
import { ReactComponent as IconSave } from 'assets/images/button/icon_save.svg'
import { QrcodeActivityPage } from 'components/pages/ActivitiesCode'
import {
  tabOption,
  settingPageType, // 頁面類型(新增、編輯、檢視)
  modalType,
  modalConfig,
} from 'components/pages/ActivitiesCode/QrcodeActivity/QrcodeActivityContentConfig'
import { PAGE_PATHS } from 'constant'
import { postQrcode, patchQrcode } from 'api/ApiMain'
import {
  allFieldTypeSwitchProp,
  formControlOutputNames,
} from 'components/pages/ActivitiesCode/QrcodeActivity/ActivityContent/ActivityContentConfig'
import * as format from 'helpers/format'
import { RootContext } from 'RootContext'
import { toast } from 'react-hot-toast'
import {
  ButtonGroupWrapper,
  TabsWarpper,
  StyledSideSection,
  TransparentMask,
  IconWrapper,
} from './QrcodeActivityContentStyle'
import { QrcodeActivityContentContext } from 'context/pageContext'
import {
  getGlobalState,
  updateDataLoading,
  updatePageLoading,
  updateListLoading,
} from 'store/global/globalSlice'
import { useDispatch, useSelector } from 'react-redux'

function QrcodeActivityContent() {
  const dispatch = useDispatch()
  const rootData = useContext(RootContext)
  const { brandId } = rootData
  const history = useHistory()
  const { brandId: braidIdHistory } = history?.location?.state
  const [tabEventKey, setTabEventKey] = useState(
    history.location.state?.tabEventKey ?? tabOption.activityContent.eventKey
  )
  const [settingId, setSettingId] = useState(null)
  const { isDataLoading, isListLoading } = useSelector(getGlobalState)
  // 切換tab頁籤時，準備要更新的tab的eventKey
  const tabEventKeyWillMountRef = useRef()
  // 為了紀錄額外贈點的列表是否有資料
  const hasQRCodeGainPointRuleRef = useRef()
  // TODO:因 QRCode render 機制尚未優化，無法直接使用 button 元件 loading 機制，故在此頁面額外加入一個透明遮罩來遮蔽畫面，用於右上角儲存按鈕儲存功能
  const [isSaving, setIsSaving] = useState(false)
  const handleSideSectionSavingMask = (bool) => {
    setIsSaving(bool)
  }

  // 為了各頁籤內容要傳遞的事件（父元件操作子元件的事件）
  const tabContentRef = {
    activityContent: useRef(),
    serialNumber: useRef(),
    extraPoint: useRef(),
    formRecord: useRef(),
  }
  const { activityContent, serialNumber, extraPoint, formRecord } = tabOption
  // tab頁籤設定
  const tabConfig = [
    // 活動內容
    {
      eventKey: activityContent.eventKey,
      title: activityContent.title,
      component: (
        <Suspense fallback={<Loader />}>
          <>
            <QrcodeActivityPage.ActivityContent
              ref={tabContentRef.activityContent}
            />
          </>
        </Suspense>
      ),
    },
    // QRCode序號
    {
      eventKey: serialNumber.eventKey,
      title: serialNumber.title,
      component: (
        <Suspense fallback={<Loader />}>
          <>
            <QrcodeActivityPage.SerialNumber ref={tabContentRef.serialNumber} />
          </>
        </Suspense>
      ),
    },
    // 額外贈點
    {
      eventKey: extraPoint.eventKey,
      title: extraPoint.title,
      component: (
        <Suspense fallback={<Loader />}>
          <>
            <QrcodeActivityPage.ExtraPoint ref={tabContentRef.extraPoint} />
          </>
        </Suspense>
      ),
    },
    // 表單紀錄
    {
      eventKey: formRecord.eventKey,
      title: formRecord.title,
      component: (
        <Suspense fallback={<Loader />}>
          <>
            <QrcodeActivityPage.FormRecord ref={tabContentRef.formRecord} />
          </>
        </Suspense>
      ),
    },
  ]
  const pageType = history.location.state?.pageType
  const qrCodeActivitySettingId =
    history.location.state?.qrCodeActivitySettingId || settingId

  // 表單form是否按下按鈕來驗證欄位規則
  const [validated, setValidated] = useState(false)
  const [modalState, setModalState] = useState({
    type: modalType.exit,
    show: false,
  })

  const handleOpenModal = (type) => {
    setModalState((prevState) => {
      return {
        ...prevState,
        show: true,
        type,
      }
    })
  }
  const handleCloseModal = () => {
    setModalState((prevState) => {
      return {
        ...prevState,
        show: false,
      }
    })
  }
  const handleModalConfirm = async ({ handleModalLoading }, type) => {
    switch (type) {
      case modalType.save:
        handleModalLoading(true)
        await btnSave()
        handleModalLoading(false)
        break
      case modalType.exit:
        history.push({
          pathname: PAGE_PATHS.activitiesCode.qrcodeActivity,
          state: {
            ...history.location.state,
            qrCodeActivitySettingId: undefined,
          },
        })
        break
      case modalType.updateSetting:
        tabContentRef.activityContent.current.clearValidate()
        handleCloseModal()
        break
    }
  }

  // (新增、編輯)時，右上角按下儲存按鈕的處理 or 切換頁籤的儲存提示
  const btnSave = async () => {
    setValidated(true)
    const formGroupErrorState =
      tabContentRef.activityContent.current.getFormGroupErrorState()
    const errorCount = detectFieldError(formGroupErrorState)
    // 欄位沒有任何驗證錯誤
    if (errorCount === 0) {
      let postData = {}
      const inputValues =
        tabContentRef.activityContent.current.getSubmitInputValues()
      postData = {
        name: inputValues[formControlOutputNames.name],
        startDate: inputValues[formControlOutputNames.validateStartDate],
        endDate: inputValues[formControlOutputNames.validateFinalDate],
        point: inputValues[formControlOutputNames.point],
        liffTitle: inputValues[formControlOutputNames.liffTitle],
        liffDescription: inputValues[formControlOutputNames.liffCaption],
      }

      if (
        inputValues[formControlOutputNames.validateStart] ===
        allFieldTypeSwitchProp.validateFromNow.options[0].code
      ) {
        postData.startDate = format.date(new Date())
      }
      if (
        inputValues[formControlOutputNames.validateFinal] ===
        allFieldTypeSwitchProp.validateEndForever.options[0].code
      ) {
        postData.endDate = null
      }
      const brandId = rootData?.brandId

      let apiFunction = () => {}
      let apiParameter = []

      // 新增
      if (pageType === settingPageType.new && !qrCodeActivitySettingId) {
        apiFunction = postQrcode
        apiParameter = [brandId, postData]
      }
      // 編輯 or 新增模式但是已經存過資料
      else if (
        pageType === settingPageType.edit ||
        (pageType === settingPageType.new && qrCodeActivitySettingId)
      ) {
        apiFunction = patchQrcode
        apiParameter = [brandId, qrCodeActivitySettingId, postData]
      }

      try {
        const apiRes = await apiFunction(...apiParameter)
        handleCloseModal()
        setValidated(false)
        const qrCodeActivitySettingId = apiRes?.qrCodeActivitySettingId
        if (qrCodeActivitySettingId) {
          setSettingId(qrCodeActivitySettingId)
        }
        // 切換頁籤的儲存
        if (
          (pageType === settingPageType.new ||
            pageType === settingPageType.edit) &&
          tabEventKey === tabOption.activityContent.eventKey &&
          tabEventKeyWillMountRef.current
        ) {
          setTabEventKey(tabEventKeyWillMountRef.current)
          tabEventKeyWillMountRef.current = undefined
        } else {
          if (pageType === settingPageType.new) {
            toast.success('新增活動內容成功')
            history.push(PAGE_PATHS.activitiesCode.qrcodeActivity)
          }
          if (pageType === settingPageType.edit) {
            toast.success('編輯活動內容成功')
            history.push(PAGE_PATHS.activitiesCode.qrcodeActivity)
          }
        }
      } catch {
        handleCloseModal()
      }
    }
    // 欄位有驗證錯誤
    else {
      handleCloseModal()
    }
  }

  const handleTabChange = (eventKey) => {
    if (
      tabEventKey === tabOption.activityContent.eventKey &&
      pageType !== settingPageType.view
    ) {
      handleOpenModal(modalType.save)
      tabEventKeyWillMountRef.current = eventKey
    } else {
      setTabEventKey(eventKey)
    }
  }

  const handleTabLoading = (isLoading) => {
    dispatch(updateDataLoading(isLoading))
  }

  const handleListLoading = (isLoading) => {
    dispatch(updateListLoading(isLoading))
  }

  // 根據history來決定切換頁籤
  useEffect(() => {
    if (history.location.search) {
      const searchParams = new URLSearchParams(history.location.search)
      const historyTabEventKey = searchParams.get('tabEventKey')
      setTabEventKey(historyTabEventKey)
      dispatch(updatePageLoading(false))
    }
  }, [history, dispatch])

  // 若是進入頁籤群組後，BrandId有異動，須退回list主頁
  useEffect(() => {
    if (brandId && braidIdHistory && brandId !== braidIdHistory) {
      history.push(PAGE_PATHS.activitiesCode.qrcodeActivity)
    }
  }, [braidIdHistory, brandId])

  return (
    <QrcodeActivityContentContext.Provider
      value={{
        tabContentRef,
        hasQRCodeGainPointRuleRef,
        tabEventKeyWillMountRef,
        settingId,
        pageType,
        settingPageType,
        validated,
        setValidated,
        handleOpenModal,
        btnSave,
        handleTabLoading,
        handleListLoading,
        isDataLoading,
        isListLoading,
      }}
    >
      <TransparentMask isShow={isSaving} />
      <Basic
        navSection={NavSection}
        sideSection={() =>
          SideSection(tabEventKey, isSaving, handleSideSectionSavingMask)
        }
      >
        <ContentSection>
          <TabsWarpper
            defaultActiveKey={tabEventKey}
            activeKey={tabEventKey}
            onSelect={(eventKey) => handleTabChange(eventKey)}
          >
            {tabConfig.map(({ eventKey, title, component: TabContent }) => {
              return (
                <Tab key={eventKey} eventKey={eventKey} title={title}>
                  <Loader isHide={!isDataLoading} />
                  {/* lazyloading */}
                  {tabEventKey === eventKey && TabContent}
                </Tab>
              )
            })}
          </TabsWarpper>
        </ContentSection>
        <Modal
          show={modalState.show}
          onClose={handleCloseModal}
          onConfirm={(handleModalLoading) =>
            handleModalConfirm(handleModalLoading, modalState.type)
          }
          variant={modalConfig[modalState.type].variant}
          titleText={modalConfig[modalState.type].title}
          confirmBtnText={modalConfig[modalState.type].confirm}
          closeBtnText={modalConfig[modalState.type].close}
        >
          {modalConfig[modalState.type].content.map((i, key) => (
            <p key={`modal-content-${key}`}>{i}</p>
          ))}
        </Modal>
      </Basic>
    </QrcodeActivityContentContext.Provider>
  )
}
// 檢測欄位規則不符合的次數
const detectFieldError = (errors) => {
  let errorCount = 0
  for (const key in errors) {
    if (!(errors[key] === undefined || errors[key] === null)) {
      errorCount++
    }
  }
  return errorCount
}

// 麵包屑及設定主標題元件
function NavSection() {
  const qrcodeActivityContent = useContext(QrcodeActivityContentContext)
  const { pageType, handleOpenModal } = qrcodeActivityContent
  const history = useHistory()
  const now = {
    [settingPageType.new]: '新增 QRCode 活動',
    [settingPageType.edit]: '編輯 QRCode 活動',
    [settingPageType.view]: '檢視 QRCode 活動',
  }
  const BreadcrumbConfig = {
    now: now[pageType] || now[[settingPageType.new]],
    pathList: [
      { title: '活動條碼 ', slash: true },
      {
        title: 'QRCode 活動 ',
        onClick: () => {
          switch (pageType) {
            case 'view':
              history.push({
                pathname: PAGE_PATHS.activitiesCode.qrcodeActivity,
                state: {
                  ...history.location.state,
                },
              })
              break
            default:
              handleOpenModal(modalType.exit)
              break
          }
        },
      },
    ],
  }
  return <Breadcrumb {...BreadcrumbConfig} />
}
// 各頁區塊右上角功能按鈕
function SideSection(tabEventKey, isSaving, handleButtonLocalLoading) {
  const history = useHistory()
  const qrcodeActivityContent = useContext(QrcodeActivityContentContext)
  const { pageType, handleOpenModal, btnSave } = qrcodeActivityContent

  const handleSubmit = async () => {
    handleButtonLocalLoading(true)
    await btnSave()
    handleButtonLocalLoading(false)
  }

  return (
    <StyledSideSection>
      {!pageType ||
      ((pageType === settingPageType.new ||
        pageType === settingPageType.edit) &&
        tabEventKey === tabOption.activityContent.eventKey) ? (
        <ButtonGroupWrapper>
          <Button
            className="btn-cancel"
            variant="outline-gray"
            size="lg"
            onClick={() => handleOpenModal(modalType.exit)}
          >
            <IconCancel />
            取消
          </Button>
          <Button
            className="ml-3 btn-store"
            variant="outline-primary"
            size="lg"
            onClick={handleSubmit}
            isLoading={isSaving}
            loaderOption={{
              bgColor: 'transparent',
              colorType: '#fff',
            }}
          >
            <IconWrapper>
              <IconSave />
            </IconWrapper>
            儲存
          </Button>
        </ButtonGroupWrapper>
      ) : (
        <Button
          variant="outline-gray"
          size="lg"
          onClick={() => {
            history.push({
              pathname: PAGE_PATHS.activitiesCode.qrcodeActivity,
              state: {
                ...history.location.state,
                qrCodeActivitySettingId: undefined,
              },
            })
          }}
        >
          返回列表
        </Button>
      )}
    </StyledSideSection>
  )
}

export default QrcodeActivityContent
