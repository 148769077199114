import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit'
import { getQrcodePointRecordDetail } from 'api/ApiMain'
import { QRCODE_ACTIVITY_FORM_RECORD } from 'store/constants'
import { memberOutlineSelector } from 'store/member-outline/memberOutlineSlice'

export const activityFormRecordInitState = {
  getQrcodePointRecordDetailRsp: {
    data: {
      recordId: '',
      memberId: '',
      memberName: '',
      cellPhone: '',
      customId: '',
      membershipName: '',
    },
  },
}

const getQrcodePointRecordDetailThunk = createAsyncThunk(
  `${QRCODE_ACTIVITY_FORM_RECORD}/getQrcodePointRecordDetail`,
  async (payload, { rejectWithValue }) => {
    try {
      const rsp = await getQrcodePointRecordDetail(payload)
      return rsp
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const qrcodeActivityFormSlice = createSlice({
  name: QRCODE_ACTIVITY_FORM_RECORD,
  initialState: activityFormRecordInitState,
  reducers: {
    resetQrcodePointRecordDetail: (state) => {
      state.getQrcodePointRecordDetailRsp =
        activityFormRecordInitState.getQrcodePointRecordDetailRsp
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getQrcodePointRecordDetailThunk.fulfilled,
        getQrcodePointRecordDetailThunk.rejected
      ),
      (state, action) => {
        state.getQrcodePointRecordDetailRsp = action.payload
      }
    )
  },
})

const pointRecordDetailSelector = createSelector(
  memberOutlineSelector,
  (state) => state[QRCODE_ACTIVITY_FORM_RECORD].getQrcodePointRecordDetailRsp,
  (memberOutlineData, getQrcodePointRecordDetailRsp) => {
    const { customId, memberName, cellPhone, membershipName } =
      getQrcodePointRecordDetailRsp.data
    const {
      age,
      yearMonth,
      gender,
      pointBalance,
      customerUnitPrice,
      lastDealDate,
      registeredDate,
      shopName,
      isMemberExist,
    } = memberOutlineData
    return {
      exchangedRecord: {
        title: '兌換紀錄',
        titleRemind: '',
        list: [
          {
            label: '會員編號',
            value: customId,
            id: 'customId',
          },
          {
            label: '姓名',
            value: memberName,
            id: 'memberName',
          },
          {
            label: '手機',
            value: cellPhone,
            id: 'cellPhone',
          },
          {
            label: '會員等級',
            value: membershipName,
            id: 'membershipName',
          },
        ],
      },
      memberContour: {
        title: '會員輪廓',
        titleRemind: '最新會員資料',
        list: [
          {
            label: '生日',
            value: age || age === 0 ? `${age} 歲` : '',
            valueRemind: yearMonth,
            id: 'birthInfo',
          },
          {
            label: '性別',
            value: gender,
            id: 'gender',
          },
          {
            label: '剩餘點數',
            value: pointBalance,
            id: 'pointBalance',
          },
          {
            label: '客單價',
            value: customerUnitPrice,
            id: 'customerUnitPrice',
          },
          {
            label: '上次消費日',
            value: lastDealDate,
            id: 'lastDealDate',
          },
          {
            label: '入會時間',
            value: registeredDate,
            id: 'registeredDate',
          },
          {
            label: '入會門店',
            value: shopName,
            id: 'shopName',
          },
        ],
      },
      isMemberExist,
    }
  }
)

export default qrcodeActivityFormSlice.reducer
export const { resetQrcodePointRecordDetail } = qrcodeActivityFormSlice.actions
export { getQrcodePointRecordDetailThunk, pointRecordDetailSelector }
