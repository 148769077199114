import styled, { css } from 'styled-components'
import Card from 'react-bootstrap/Card'
import { colorObj, method } from 'assets/styles/Variable/Color'
import { StyleLoader } from './Loader'

const { colorHandle } = method

// card共幾種尺寸
const cardSize = {
  lg: {
    padding: '60px 80px',
  },
  md: {
    padding: '24px',
  },
  sm: {
    padding: '16px',
  },
}

// Card size樣式處理
const handleSizeStyles = (size) => {
  let cssStyle = ''
  for (const type in cardSize) {
    const { padding } = cardSize[type]
    if (size === type) {
      cssStyle += `
                padding:${padding};
            `
    }
  }
  return css`
    ${cssStyle}
  `
}

// Card 共用樣式
const cardCommonStyles = (props) => {
  const { padding } = props
  return css`
    position: relative;
    padding: ${padding};
    box-shadow: 0px 1px 4px 0px ${colorHandle('dark', colorObj, 0.25)};
  `
}

// Card 基礎樣式
const cardBaseStyles = (props) => {
  let { size } = props
  size = size || 'md'
  return css`
    ${cardCommonStyles(props)};
    ${handleSizeStyles(size)};
  `
}

const StyleCard = styled((props) => {
  const { children, isLoading, loaderOption, ...prop } = props
  return (
    <Card {...prop}>
      <StyleLoader {...loaderOption} isHide={!isLoading} />
      {children}
    </Card>
  )
})`
  ${(props) => cardBaseStyles(props)};
`

export { StyleCard, cardBaseStyles, cardCommonStyles, handleSizeStyles }
