// 轉換16進制色碼 to RGBA
const hexToRgbA = (hex, aplha = 1) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ','
    )},${aplha})`
  }
}
const method = {
  colorHandle(propType, referenceTypes, alpha) {
    let result = ''
    for (const typeItem in referenceTypes) {
      if (propType === typeItem) {
        result = referenceTypes[typeItem]
        break
      }
    }
    // 若本身為rgba傳入，則不用進行轉碼
    if (result.includes('rgba')) {
      const colorStartIndex = result.indexOf('(')
      const colorEndIndex = result.indexOf(')')
      const colorRgbaArr = result
        .substring(colorStartIndex + 1, colorEndIndex)
        .trim()
        .split(',')
        .map(Number)
      return `rgba(${colorRgbaArr[0]},${colorRgbaArr[1]},${colorRgbaArr[2]},${
        alpha || colorRgbaArr[3]
      })`
    }
    if (alpha && alpha >= 1) {
      alpha = 1
    }
    return hexToRgbA(result, alpha)
  },
}

// 以顏色區分類別(若有類別需求，限定套用的顏色種類只能以下幾種)
const colorObj = {
  primary: '#4B65A6',
  secondary: '#6399D1',
  success: '#3CA078',
  danger: '#DC3C50',
  dark: '#333333',
  white: '#ffffff',
  darkerGray: 'rgba(51,51,51,0.75)',
  darkGray: 'rgba(51,51,51,0.66)',
  gray: 'rgba(51,51,51,0.5)',
  lightGray: 'rgba(51, 51, 51, 0.33)',
}

const colorCommon = {
  borderGray: '#d4d4d4',
  disabledBorder: hexToRgbA('#d4d4d4', 0.5),
}

const color = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  danger: 'danger',
  dark: 'dark',
  white: 'white',
  gray: 'gray',
}

export { colorObj, colorCommon, method, color, hexToRgbA }
