import { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as Delete } from 'assets/images/modal/icon_modalClose.svg'
import { colorObj } from 'assets/styles/Variable/Color'

const Wrapper = styled.div`
  input[type='file'],
  .hidden-img {
    display: none;
  }
  .image-uploader {
    border-radius: 4px;
    border: 1px solid ${colorObj.darkerGray};
    background-color: #fff;
    position: relative;
    width: 100px;
    height: 100px;
    padding: 0;
    box-sizing: content-box;
    color: ${colorObj.darkerGray};
    font-size: 14px;
    overflow: hidden;
    &.onload {
      cursor: default;
    }
  }
  .delete {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    background-color: #fff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  .preview {
    max-width: 100%;
    max-height: 100%;
  }
`

const initState = { data: null, view: null, show: false }
const imageErrorTypes = { size: 'size', resolution: 'resolution' }
const defaultImageTypes = ['png', 'jpg', 'jpeg']
const genAccept = (imageTypes) => imageTypes.map((i) => `image/${i}`).join()
const quiredText = (required) => (required ? '(必填)' : '(選填)')

const imageSizeLimit = (imgSize, limitSize) => {
  if (!limitSize) return true
  const byte = 1024
  const validResult = imgSize < limitSize * byte // limitSize is KB
  return validResult
}

const imageResolutionLimit = (width, height, limitWidth, limitHeight) => {
  if (!limitWidth || !limitHeight) return true
  const validResult =
    width < limitWidth &&
    height < limitHeight &&
    width / height === limitWidth / limitHeight
  return validResult
}

function ImageUploader({
  defaultValue = '',
  value = '',
  imageTypes = defaultImageTypes,
  limit = {},
  onChange = null,
  onError = null,
  disabled = false,
  required = false,
}) {
  const [status, setStatus] = useState(
    defaultValue ? { data: null, view: defaultValue, show: true } : initState
  )
  const inputEl = useRef(null)
  let file = ''
  const onButtonClick = () => inputEl.current.click()

  const handleReset = () => {
    if (typeof onChange === 'function') onChange('')
    setStatus(initState)
  }

  const onUploadChange = (e) => {
    if (e.currentTarget.files.length === 0) return
    file = e.currentTarget.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      setStatus((prev) => ({ ...prev, data: file, view: e.target.result }))
    }
  }

  const imageOnLoad = (e) => {
    const isOnError = typeof onError === 'function'
    const isOnChange = typeof onChange === 'function'
    const validSize = imageSizeLimit(status?.data?.size, limit?.size)
    const validResolution = imageResolutionLimit(
      e.target.width,
      e.target.height,
      limit?.width,
      limit?.height
    )
    if (!validSize) {
      isOnError && onError(imageErrorTypes.size)
      return handleReset()
    }
    if (!validResolution) {
      isOnError && onError(imageErrorTypes.resolution)
      return handleReset()
    }
    isOnChange && onChange(status.data)
    return setStatus((prev) => ({ ...prev, show: true }))
  }

  useEffect(() => {
    if (value) setStatus({ data: null, view: value, show: true })
  }, [value])

  return (
    <Wrapper>
      <button
        type="button"
        className={`image-uploader ${status.show ? 'onload' : ''}`}
        onClick={onButtonClick}
      >
        <input
          type="file"
          accept={genAccept(imageTypes)}
          ref={inputEl}
          onChange={onUploadChange}
          disabled={disabled || status.view !== null}
        />
        {status.data && (
          <img
            className="hidden-img"
            src={status.view}
            alt="hidden-img"
            onLoad={imageOnLoad}
          />
        )}
        {status.show ? (
          <>
            {!disabled && (
              <Delete
                type="button"
                className="delete"
                viewBox="-8 -8 32 32"
                onClick={handleReset}
              />
            )}
            <img src={status.view} className="preview" alt="preview-img" />
          </>
        ) : (
          <>
            <div>上傳圖片</div>
            <div>{quiredText(required)}</div>
          </>
        )}
      </button>
    </Wrapper>
  )
}

export default ImageUploader
