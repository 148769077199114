import styled from 'styled-components'
import { Col, Row, Container } from 'react-bootstrap'

const StyledRow = styled(Row)`
  margin: 0px !important;
`

const StyledCol = styled(Col)`
  padding: 0px !important;
`

const StyledContainer = styled(Container)`
  padding: 0px !important;
`

export { StyledCol as Col, StyledContainer as Container, StyledRow as Row }
