import differenceInDays from 'date-fns/differenceInDays'
import PropTypes from 'prop-types'
import React, {
  useState,
  Suspense,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import { Col, Row, Tabs, Tab } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import withPermission from 'components/hocs/withPermission'
import { Basic, ContentSection } from 'components/templates'
import { ButtonList } from 'components/widgets'
import { COMPONENT_PERMISSIONS, PAGE_BREADCRUMB } from 'constant'
import { RootContext } from 'RootContext'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'
import { deleteMember, getMemberPointRecord } from '../../../api/ApiMain'
import { ReactComponent as IconCancel } from '../../../assets/images/button/icon_cancel.svg'
import { ReactComponent as IconDelete } from '../../../assets/images/button/icon_delete.svg'
import { ReactComponent as IconEdit } from '../../../assets/images/button/icon_edit.svg'
import { ReactComponent as IconSave } from '../../../assets/images/button/icon_save.svg'
import { getRandomId, toastErrorContent } from '../../../helpers/common'
import { Loader, Button, Card, Modal, Breadcrumb } from '../../units'

import MainData from './MainData'

const BasicInfo = React.lazy(() => import('./BasicInfo'))
const DealInfo = React.lazy(() => import('./DealInfo'))
const PointRecord = React.lazy(() => import('./PointRecord'))
const MembershipRecord = React.lazy(() => import('./MembershipRecord'))

function NavSection(isRead) {
  const { edit, editting } = PAGE_BREADCRUMB.member
  return isRead ? (
    <Breadcrumb
      title={edit.title}
      pathList={edit.pathList}
      description={edit.description}
    />
  ) : (
    <Breadcrumb
      title={editting.title}
      pathList={editting.pathList}
      description={editting.description}
    />
  )
}

function SideSection({
  isRead,
  memberId,
  isShowFeatureBtn,
  basicInfoRef,
  setRead,
}) {
  return isRead ? (
    <DetailHeader
      memberId={memberId}
      isShowFeatureBtn={isShowFeatureBtn}
      setRead={setRead}
    />
  ) : (
    <EditHeader
      memberId={memberId}
      isShowFeatureBtn={isShowFeatureBtn}
      basicInfoRef={basicInfoRef}
      setRead={setRead}
    />
  )
}

// tab區塊無資料的component
function TabEmptyContainer(props) {
  const { content } = props
  return (
    <div className="tab-empty-container d-flex justify-content-center align-items-center">
      {content}
    </div>
  )
}

function MemberDetailAndEdit({ isShowFeatureBtn, setShowFeatureBtn }) {
  const { brandId } = useContext(RootContext)
  const { memberId } = useParams()
  const [isRead, setRead] = useState(true)
  const basicInfoRef = useRef()
  const [tabEventKey, setTabEventKey] = useState('basicInfo')

  // 控制tab切換時的loading
  const dispatch = useDispatch()
  const { isDataLoading } = useSelector(getGlobalState)
  const handleTabLoading = useCallback(
    (bool) => {
      dispatch(updateDataLoading(bool))
    },
    [dispatch]
  )
  const [basicInfoData, setBasicInfoData] = useState(null)

  // 控制點數紀錄是否顯示頁籤紅點
  // 目前先關閉此功能, css 不會啟用 has-badge 的樣式
  // eslint-disable-next-line no-unused-vars
  const [pointRecordBadge, setPointRecordBadge] = useState(false)

  const handleTab = (eventKey) => {
    if (tabEventKey !== eventKey) {
      setShowFeatureBtn(eventKey === 'basicInfo')
      setTabEventKey(eventKey)
      handleTabLoading(true)
    }
  }
  const handleGetMemberPointRecord = useCallback(
    async (memberIdParam, queryStringObj) => {
      handleTabLoading(true)

      try {
        const rsp = await getMemberPointRecord(memberIdParam, {
          ...queryStringObj,
        })

        const expiryDate = rsp?.total?.expiryDate
        const result = differenceInDays(new Date(expiryDate), new Date())
        if (result <= 90) {
          setPointRecordBadge(true)
        }
      } catch (e) {
        console.error(e)
      } finally {
        handleTabLoading(false)
      }
    },
    [handleTabLoading]
  )

  useEffect(() => {
    if (memberId && brandId) {
      handleGetMemberPointRecord(memberId, { brandId })
    }
  }, [memberId, brandId, handleGetMemberPointRecord])

  return (
    <Basic
      navSection={() => NavSection(isRead)}
      sideSection={() =>
        SideSection({
          isRead,
          memberId,
          isShowFeatureBtn,
          basicInfoRef,
          setRead,
        })
      }
    >
      <ContentSection>
        <Row>
          <Col>
            <Card
              style={{ minHeight: '260px' }}
              size="md"
              isLoading={isDataLoading}
              loaderOption={{
                colorType: 'dark',
              }}
            >
              {basicInfoData && <MainData memberData={basicInfoData} />}
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Tabs
              defaultActiveKey="basicInfo"
              id="noanim-tab-example"
              onSelect={handleTab}
            >
              <Tab eventKey="basicInfo" title="基本資料">
                {tabEventKey === 'basicInfo' && (
                  <Suspense fallback={<Loader />}>
                    <>
                      <Loader isHide={!isDataLoading} />
                      <BasicInfo
                        ref={basicInfoRef}
                        isRead={isRead}
                        memberId={memberId}
                        isLoadingTab={isDataLoading}
                        basicInfoData={basicInfoData}
                        setLoadingTab={handleTabLoading}
                        TabEmptyContainer={TabEmptyContainer}
                        setBasicInfoData={setBasicInfoData}
                      />
                    </>
                  </Suspense>
                )}
              </Tab>
              <Tab eventKey="dealInfo" title="消費紀錄" disabled={!isRead}>
                {tabEventKey === 'dealInfo' && (
                  <Suspense fallback={<Loader />}>
                    <>
                      <Loader isHide={!isDataLoading} />
                      <DealInfo
                        memberId={memberId}
                        tableNoDataIndication={
                          isDataLoading ? '載入中...' : '無符合條件的交易資料'
                        }
                      />
                    </>
                  </Suspense>
                )}
              </Tab>
              <Tab
                eventKey="pointRecord"
                title="點數紀錄"
                disabled={!isRead}
                // tabClassName={pointRecordBadge ? 'has-badge' : ''}
              >
                {tabEventKey === 'pointRecord' && (
                  <Suspense fallback={<Loader />}>
                    <>
                      <Loader isHide={!isDataLoading} />
                      <PointRecord
                        memberId={memberId}
                        setLoadingTab={handleTabLoading}
                        tableNoDataIndication={
                          isDataLoading ? '載入中...' : '無符合條件的點數紀錄'
                        }
                        TabEmptyContainer={TabEmptyContainer}
                      />
                    </>
                  </Suspense>
                )}
              </Tab>

              <Tab
                eventKey="membershipRecord"
                title="會籍紀錄"
                disabled={!isRead}
              >
                {tabEventKey === 'membershipRecord' && (
                  <Suspense fallback={<Loader />}>
                    <>
                      <Loader isHide={!isDataLoading} />
                      <MembershipRecord
                        memberId={memberId}
                        tableNoDataIndication={
                          isDataLoading ? '載入中...' : '無符合條件的會籍紀錄'
                        }
                        setLoadingTab={handleTabLoading}
                        TabEmptyContainer={TabEmptyContainer}
                        basicInfoData={basicInfoData}
                      />
                    </>
                  </Suspense>
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </ContentSection>
    </Basic>
  )
}

// 上方刪除、編輯按鈕以及modal刪除視窗
function DetailHeader({ isShowFeatureBtn, memberId, setRead }) {
  // global data
  const rootData = useContext(RootContext)
  const history = useHistory()
  const location = useLocation()
  const { brandId } = rootData
  const { UPDATE, DELETE } = COMPONENT_PERMISSIONS.member
  const PermissionEditButton = withPermission(Button, UPDATE)
  const PermissionDeleteButton = withPermission(Button, DELETE)

  // modal視窗顯示控制
  const [showModal, setShowModel] = useState(false)

  const handleModalClose = () => setShowModel(false)
  const handleModalShow = () => setShowModel(true)

  const handleDelete = ({ handleModalLoading }) => {
    const deleteMemberPromise = deleteMember(memberId, { brandId })
    handleModalLoading(true)
    deleteMemberPromise
      .then(() => {
        toast.success('會員刪除成功')
        history.push({
          pathname: '/member',
          state: location?.state,
        })
      })
      .catch((rsp) => {
        const { status } = rsp
        if (status === 400) {
          const toastId = getRandomId()
          toast.error(toastErrorContent(status, rsp?.data?.msg, toastId), {
            id: toastId,
          })
          handleModalClose()
        }
      })
      .finally(() => {
        handleModalLoading(false)
      })
  }

  const handleEdit = () => {
    setRead(false)
  }

  return (
    <>
      {isShowFeatureBtn && (
        <ButtonList className="justify-content-end">
          <PermissionDeleteButton
            className=" btn-delete"
            style={{ minWidth: 'auto' }}
            variant="outline-gray"
            onClick={handleModalShow}
          >
            <IconDelete />
            刪除
          </PermissionDeleteButton>
          <PermissionEditButton
            style={{ minWidth: 'auto' }}
            variant="outline-primary"
            onClick={handleEdit}
          >
            <IconEdit />
            編輯
          </PermissionEditButton>
        </ButtonList>
      )}
      {/* 刪除會員-modal */}
      <Modal
        show={showModal}
        onHide={handleModalClose}
        titleText="即將刪除會員個資"
        confirmBtnText="刪除"
        closeBtnText="返回"
        variant="danger"
        onClose={handleModalClose}
        onConfirm={handleDelete}
      >
        <div className="modal-confirm-body">
          是否繼續執行刪除？
          <br />
          刪除後，該會員個資將無法回復。
        </div>
      </Modal>
    </>
  )
}

// 上方取消、儲存按鈕
function EditHeader({ isShowFeatureBtn, basicInfoRef, setRead }) {
  // modal視窗顯示控制
  const [showModal, setShowModel] = useState(false)
  const { isDataLoading } = useSelector(getGlobalState)

  const handleModalClose = () => setShowModel(false)
  const handleModalShow = () => setShowModel(true)

  const handleCancelEdit = (e) => {
    setRead(true)
    basicInfoRef.current.basicInfoCancelEvent(e)
  }

  const handleSaveEdit = async (e) => {
    e.preventDefault()

    try {
      await basicInfoRef.current.basicInfoStoreEvent(e, setRead)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      {isShowFeatureBtn && (
        <ButtonList className="justify-content-end">
          <Button
            className="btn-cancel"
            style={{ minWidth: 'auto' }}
            variant="outline-gray"
            onClick={handleModalShow}
          >
            <IconCancel />
            取消
          </Button>
          <Button
            style={{ minWidth: 'auto' }}
            variant="outline-primary"
            onClick={handleSaveEdit}
            isLoading={isDataLoading}
            loaderOption={{
              colorType: '#fff',
            }}
          >
            <IconSave />
            儲存
          </Button>
        </ButtonList>
      )}
      {/* 離開編輯-modal */}
      <Modal
        show={showModal}
        onHide={handleModalClose}
        titleText="即將離開編輯"
        confirmBtnText="離開"
        closeBtnText="返回"
        variant="danger"
        onClose={handleModalClose}
        onConfirm={handleCancelEdit}
      >
        <div className="modal-confirm-body">
          已編輯的資料不會儲存，
          <br />
          確定離開編輯？
        </div>
      </Modal>
    </>
  )
}

export default MemberDetailAndEdit

SideSection.propTypes = {
  isRead: PropTypes.bool,
  memberId: PropTypes.string,
  isShowFeatureBtn: PropTypes.bool,
  basicInfoRef: PropTypes.shape({
    current: PropTypes.shape({
      basicInfoCancelEvent: PropTypes.func,
      basicInfoStoreEvent: PropTypes.func,
    }),
  }),
  setRead: PropTypes.func,
}
SideSection.defaultProps = {
  isRead: true,
  memberId: '',
  isShowFeatureBtn: true,
  basicInfoRef: {},
  setRead: () => {},
}

EditHeader.propTypes = {
  basicInfoRef: PropTypes.shape({
    current: PropTypes.shape({
      basicInfoCancelEvent: PropTypes.func,
      basicInfoStoreEvent: PropTypes.func,
    }),
  }),
  setRead: PropTypes.func,
  isShowFeatureBtn: PropTypes.bool,
}
EditHeader.defaultProps = {
  basicInfoRef: {},
  setRead: () => {},
  isShowFeatureBtn: true,
}

MemberDetailAndEdit.propTypes = {
  isShowFeatureBtn: PropTypes.bool,
  setShowFeatureBtn: PropTypes.func,
}
MemberDetailAndEdit.defaultProps = {
  isShowFeatureBtn: true,
  setShowFeatureBtn: () => {},
}
DetailHeader.propTypes = {
  memberId: PropTypes.string,
  setRead: PropTypes.func,
  isShowFeatureBtn: PropTypes.bool,
}
DetailHeader.defaultProps = {
  memberId: '',
  setRead: () => {},
  isShowFeatureBtn: true,
}

TabEmptyContainer.propTypes = {
  content: PropTypes.string,
}
TabEmptyContainer.defaultProps = {
  content: '查無資料',
}
