import styled from 'styled-components'
import { colorCommon, colorObj } from 'assets/styles/Variable/Color'

const LocalStyle = styled.div`
  width: 100%;
  .col {
    padding: 0;
  }
  .warn {
    color: #dc3c50;
  }
  .divider {
    height: 1.5px;
    width: 100%;
    background-color: ${colorCommon.borderGray};
    margin: 25px 0;
  }
  .form-group {
    display: flex;
    > *:first-child {
    }
  }
  .dropdown-toggle {
    width: 346px;
  }
  .form-label {
    min-width: 100px;
    max-width: 100px;
  }
  .form-check input[type='checkbox'] ~ .form-check-label {
    white-space: break-spaces;
    text-indent: 0px;
    padding-left: 4px;
  }
  .form-check {
    display: flex;
    align-items: baseline;
    margin-right: 0.5rem;
    width: 150px;
    margin-bottom: 5px;
  }
  .form-check input[type='checkbox'] ~ .form-check-label::before {
    top: 5px;
    transform: none;
  }
  .form-check input[type='checkbox']:checked ~ .form-check-label {
    text-indent: 0px;
  }
  .form-control {
    min-width: 346px;
    max-width: 346px;
  }
  .tab-content-inner-nav {
    .tab-content {
      padding: 0;
      box-shadow: none;
    }
  }
  .select-wrapper {
    margin-right: 20px;
  }
`

const CustomFieldWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`
const CheckboxsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
`
const CustomFeedback = styled('div')`
  margin-top: 2px;
  color: ${(props) => (props.isWarn ? '#dc3545' : colorObj.gray)};
  text-indent: 8px;
  font-size: 13px;
`

const IconInputWrapper = styled('div')`
  position: relative;
  max-width: 346px;
  svg {
    position: absolute;
    right: 10px;
    top: 8px;
    display: ${(props) => (props.isShow ? 'block' : 'none')};
  }
  .email-enabled {
    path {
      fill: #3ca078;
    }
  }
  .email-disabled {
    path {
      fill: #dc3c50;
    }
  }
`

export {
  CustomFieldWrapper,
  CheckboxsWrapper,
  CustomFeedback,
  IconInputWrapper,
}
export default LocalStyle
