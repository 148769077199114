import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as IconCancel } from 'assets/images/button/icon_cancel.svg'
import { ReactComponent as IconSave } from 'assets/images/button/icon_save.svg'
import { Button } from 'components/units'

const ButtonGroupWrapper = styled.div`
  .btn {
    min-width: auto !important;
  }
`
const StyledSideSection = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

function SideSection({ onSubmit, onCancel }) {
  const handleSubmit = (e) => {
    onSubmit(e)
  }
  const handleOpenModel = () => {
    onCancel()
  }

  return (
    <StyledSideSection>
      <ButtonGroupWrapper>
        <Button
          className="btn-cancel"
          variant="outline-gray"
          size="lg"
          onClick={handleOpenModel}
        >
          <IconCancel />
          取消
        </Button>
        <Button
          className="ml-3 btn-store"
          variant="outline-primary"
          size="lg"
          onClick={handleSubmit}
        >
          <IconSave />
          儲存
        </Button>
      </ButtonGroupWrapper>
    </StyledSideSection>
  )
}

export default SideSection

SideSection.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

SideSection.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
}
