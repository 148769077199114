import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { getMemberDealinfo } from 'api/ApiMain'
import { DEAL_INFO } from '../constants'

export const dealInfoInitState = {
  getDealInfoRsp: {
    data: {
      sort: '',
      totalSize: 0,
      rows: [],
    },
  },
}

const getMemberDealInfoThunk = createAsyncThunk(
  `${DEAL_INFO}/getDealInfo`,
  async ({ memberId, queryStringObj }, { rejectWithValue }) => {
    try {
      const rsp = await getMemberDealinfo(memberId, queryStringObj)
      return rsp
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

const dealInfoSlice = createSlice({
  name: DEAL_INFO,
  initialState: dealInfoInitState,
  reducers: {
    resetGetDealInfoStatus(state) {
      state.getDealInfoRsp = { ...dealInfoInitState.getDealInfoRsp }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === getMemberDealInfoThunk.fulfilled.type ||
        action.type === getMemberDealInfoThunk.rejected.type,
      (state, action) => {
        state.getDealInfoRsp.data = action.payload
      }
    )
  },
})

const dealInfoSelector = createSelector(
  (state) => state[DEAL_INFO].getDealInfoRsp,
  (dealInfo) => dealInfo.data
)

export default dealInfoSlice.reducer
export const { resetGetDealInfoStatus } = dealInfoSlice.actions
export { getMemberDealInfoThunk, dealInfoSelector }
