import { color } from 'assets/styles/Variable/Color'

// Tab的event key和標題參數
const tabOption = {
  activityContent: {
    eventKey: 'activity-content',
    title: '活動內容',
  },
  serialNumber: {
    eventKey: 'serial-number',
    title: 'QRCode 序號',
  },
  extraPoint: {
    eventKey: 'extra-point',
    title: '額外贈點',
  },
  formRecord: {
    eventKey: 'form-record',
    title: '表單紀錄',
  },
}

// 頁面類型(新增、編輯、檢視)
const settingPageType = {
  new: 'new',
  edit: 'edit',
  view: 'view',
}

const modalType = {
  exit: 'exit',
  updateSetting: 'updateSetting',
  save: 'save',
  stop: 'stop',
}

const modalConfig = {
  [modalType.exit]: {
    title: '即將離開',
    confirm: '離開',
    close: '返回',
    content: ['已編輯的資料將不會儲存，', '確定離開編輯？'],
    variant: color.danger,
  },
  [modalType.updateSetting]: {
    title: '變更設定',
    confirm: '繼續',
    close: '返回',
    content: [
      '變更效期設定，',
      '將清除已建立額外贈點之效期。',
      '是否繼續執行？',
    ],
    variant: color.danger,
  },
  [modalType.save]: {
    title: '儲存進度',
    confirm: '儲存',
    close: '返回',
    content: ['是否儲存目前編輯進度？'],
    variant: color.primary,
  },
  [modalType.stop]: {
    title: '即將停用',
    confirm: '停用',
    close: '返回',
    content: ['停用後將無法復原，', '確定停用？'],
    variant: color.danger,
  },
}

export { tabOption, settingPageType, modalType, modalConfig }
