import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import LoginContainer from 'components/pages/login/containers/Login'
import { PAGE_PATHS } from 'constant'
import LocalStyle from 'pages/login/LoginStyle'

export default function Login({ auth }) {
  const currentYear = new Date().getFullYear()
  return (
    <LocalStyle>
      <div className="main">
        <div className="welcome-section">Welcome to</div>
        <div className="litloyal-section">LitLoyal</div>
        <div className="subtitle-section">創造高忠誠的會員經濟</div>
        <div className="margin-t-40 margin-b-40">
          <hr className="line-section" />
        </div>
        <div className="form-title">登入</div>
        <LoginContainer auth={auth} />
      </div>
      <footer>
        <p className="mb-1">
          登入即表示您了解並同意 LitLoyal{' '}
          <NavLink
            to={PAGE_PATHS.privacyPolicy}
            target="_blank"
            alt="隱私權政策"
          >
            隱私權聲明
          </NavLink>
        </p>
        <p>Copyright © {currentYear} MIGO Corp. All rights reserved</p>
      </footer>
    </LocalStyle>
  )
}

Login.propTypes = {
  auth: PropTypes.shape({
    value: PropTypes.shape({
      loading: PropTypes.bool,
      accessToken: PropTypes.string,
      isLoggedIn: PropTypes.bool,
    }).isRequired,
    setAuth: PropTypes.func.isRequired,
  }),
}

Login.defaultProps = {
  auth: {
    value: {
      loading: true,
      accessToken: '',
      isLoggedIn: false,
    },
    setAuth: () => {},
  },
}
