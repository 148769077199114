import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PAGE_PATHS } from 'constant'
import { tokenSelector } from 'store/account-verify/accountVerifySlice'
import LocalStyle from './VerifyFailedStyle'

export default function VerifyFailed() {
  const history = useHistory()
  const { type } = useSelector(tokenSelector)
  const currentYear = new Date().getFullYear()
  const verifyFailedText = {
    resetPassword: {
      title: '連結失效',
      content: (
        <>
          請返回登入頁，操作忘記密碼
          <br />
          以重設密碼
        </>
      ),
    },
    enableAccount: {
      title: '驗證失敗',
      content: (
        <>
          請聯繫相關單位總管理者
          <br />
          以啟用帳號
        </>
      ),
    },
  }
  return (
    <LocalStyle>
      <div className="welcome-section">Welcome to</div>
      <div className="litloyal-section">LitLoyal</div>
      <div className="subtitle-section">創造高忠誠的會員經濟</div>
      <div className="margin-t-40 margin-b-40">
        <hr className="line-section" />
      </div>
      <div className="verify-failed-title">{verifyFailedText[type].title}</div>
      <div className="verify-failed-description">
        {verifyFailedText[type].content}
      </div>
      {type === 'resetPassword' && (
        <Button
          type="button"
          className="link-expired-btn"
          onClick={() => {
            history.push(PAGE_PATHS.login)
          }}
        >
          返回
        </Button>
      )}
      <footer>{`Copyright © ${currentYear} MIGO Corp. All rights reserved`}</footer>
    </LocalStyle>
  )
}
