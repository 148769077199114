import { useContext, useEffect, useRef } from 'react'
import { startOfToday } from 'date-fns'
import { Form } from 'react-bootstrap'
import {
  Button,
  Col,
  Input,
  Editor,
  Radio,
  Checkbox,
  Calendar,
  ImageUploader,
  Row,
  Tooltip,
  Select,
} from 'components/units'
import {
  FormWrapper,
  CalendarWrapper,
  ColumnListWrapper,
  CheckListMainWrapper,
  CheckListSubWrapper,
  InValidFeedback,
  LvZeroEl,
  ReceiveEndOptionsWrapper,
} from 'components/templates'
import { debounce } from 'helpers/common'
import { settingPageType, tabStatus } from 'pages/Gift/config'
import {
  ExclusiveSettingContext,
  headerEventType,
  tabType,
} from 'pages/Gift/ExclusiveSetting'
import {
  ConsumerConditionWrapper,
  GiftSpecialWrapper,
  LinkContentWrapper,
  TextLink,
} from 'components/pages/Gift/ExclusiveContentStyle'
import {
  fieldType,
  formConfig as initFormConfig,
  eventType,
  contentType,
  receiveLimit,
  giftType,
  resAtrr,
  imageLimitConfig,
} from 'components/pages/Gift/ExclusiveContentConfig'
import {
  getEventTypeOptions,
  handleEventValueParser,
  handleImageError,
} from 'components/pages/Gift/ExclusiveContentHelper'
import iconTooltip from 'assets/images/icon_tooltip.svg'
import { useSelector } from 'react-redux'
import {
  vipGiftConfigSelector,
  vipGiftListSelector,
} from 'store/vip-gift-exchange/vipGiftExchangeListSlice'

function ExclusiveContent() {
  const exclusiveSettingContext = useContext(ExclusiveSettingContext)
  const history = exclusiveSettingContext?.history
  const type = history?.location?.state?.type
  const apiPayload = history?.location?.state?.apiPayload
  const pathname = history?.location?.pathname
  const viewType = exclusiveSettingContext?.viewType
  const [infoState, dispatch] = exclusiveSettingContext?.infoState
  const [infoValid, setInfoValid] = exclusiveSettingContext?.infoValid
  const [memberLevels] = exclusiveSettingContext?.memberLevels
  const [designatedList] = exclusiveSettingContext?.designatedList
  const handleEvent = exclusiveSettingContext?.handleEvent
  const [stateFromEdit] = exclusiveSettingContext?.stateFromEdit
  const isNew = viewType === settingPageType.new
  const isEdit = viewType === settingPageType.edit
  const isView = viewType === settingPageType.view
  const hasExchanged = infoState[resAtrr.sendCount] > 0
  const isMainLinked = infoState[fieldType.linkGift].isMain === true
  const isSubLinked = infoState[fieldType.linkGift].isMain === false
  const basicDisabled = isNew ? false : isView || hasExchanged
  const isGiftAmountDisabled = isNew ? false : isView
  const isFieldDisabled = isSubLinked || basicDisabled
  const isFieldDisabledByView = isSubLinked || isView
  const giftTypeAndReceiveMethonDisabled = isMainLinked || isFieldDisabled
  const giftInfoDisabled = isSubLinked || isView
  const isDesignatedList =
    infoState[fieldType.eventType] === eventType.designatedList
  const { pageType } = useSelector(vipGiftConfigSelector)
  const { usingBindingGift, usingLinkGift } = useSelector(vipGiftListSelector)

  // 綁定禮 default 開啟
  // 關閉：品牌關閉綁定禮功能時，新增、編輯頁面不可選擇綁定禮，檢視可有綁定禮選項
  const isBindingGiftDisabled =
    !usingBindingGift && pageType !== settingPageType.view
  const eventTypeDisabledList = isBindingGiftDisabled
    ? [eventType.bindingGift]
    : []
  const formConfig = {
    ...initFormConfig,
    [fieldType.eventType]: {
      ...initFormConfig[fieldType.eventType],
      selectOption: getEventTypeOptions(
        initFormConfig[fieldType.eventType].selectOption,
        eventTypeDisabledList
      ),
    },
  }

  const handleChange = handleEventValueParser(dispatch, setInfoValid)
  const handleEditorChange = useRef(
    debounce(handleChange(contentType.giftInfo), 200)
  )

  const handleReceiveEndCalendar = (e) => {
    handleChange(contentType.giftCodeEndTime)(e)
    handleChange(contentType.receiveEnd)(2)
  }

  const handleReceiveEndAfterDays = (e, value) => {
    handleChange(contentType.giftCodeEndTimeAfterDays)(e, value)
    handleChange(contentType.receiveEnd)(3)
  }

  const handleLinkClick = () => {
    history?.push(pathname, {
      type,
      apiPayload: {
        ...apiPayload,
        giftSettingId: infoState[fieldType.linkGift].vipGiftSettingId,
      },
    })
  }

  const handleDelink = () => handleEvent(headerEventType.unlink)

  const showInValidFeedback = (isShow, item) => {
    return <InValidFeedback show={isShow}>{item.msg}</InValidFeedback>
  }

  // select元件需要text、value，而api回傳的格式是name、code
  const selectKeyNameTranslate = (list) => {
    const newList = []
    for (const item of list) {
      const { code, name, selected } = item
      newList.push({
        text: name,
        value: code,
        selected,
      })
    }
    return newList
  }

  useEffect(() => {
    return () => {
      if (
        stateFromEdit === tabStatus.available &&
        pageType === tabType.content
      ) {
        handleEvent(headerEventType.goList)
      }
    }
  }, [handleEvent, stateFromEdit, pageType])

  return (
    <FormWrapper>
      <Form>
        <Form.Row>
          <Col lg="6">
            <Form.Group>
              <Form.Label>
                {formConfig[fieldType.systemNumber].label}
              </Form.Label>
              <Row>{infoState[fieldType.systemNumber]}</Row>
            </Form.Group>
          </Col>
          <Col lg="6">
            {((isNew && usingLinkGift) ||
              infoState[fieldType.linkGift].content) && (
              <Form.Group as={Col}>
                <Form.Label>{formConfig[fieldType.linkGift].label}</Form.Label>
                <Col>
                  {isNew && (
                    <Select
                      className="form-row-input-fill"
                      selectedValue={infoState[fieldType.linkGift]}
                      optionItems={infoState[fieldType.linkGift].linkList} // 有 selector: vipExchangeLinkSelector，待替換
                      onChange={handleChange(contentType.linkGift)}
                      formControlOption={{
                        placeholder: formConfig[fieldType.linkGift].placeholder,
                      }}
                      dropdownToggleOption={{
                        disabled: basicDisabled,
                      }}
                    />
                  )}
                  {infoState[fieldType.linkGift].content && (
                    <LinkContentWrapper>
                      <TextLink onClick={handleLinkClick}>
                        {formConfig[fieldType.linkGift].linkInfoFn({
                          text: infoState[fieldType.linkGift].content,
                          id: infoState[fieldType.linkGift].serialNo,
                          isMain: isMainLinked,
                        })}
                      </TextLink>
                      {isEdit && (
                        <Button
                          size="md"
                          variant="outline-gray"
                          onClick={handleDelink}
                          disabled={basicDisabled}
                        >
                          {formConfig[fieldType.linkGift].unlink}
                        </Button>
                      )}
                    </LinkContentWrapper>
                  )}
                </Col>
              </Form.Group>
            )}
          </Col>
          <Col lg="6">
            <Form.Group>
              <Form.Label>{formConfig[fieldType.eventName].label}</Form.Label>
              <Col>
                <Input
                  className="form-row-input-fill"
                  formControlOption={{
                    value: isSubLinked
                      ? `${infoState[fieldType.eventName]}(已連結${
                          infoState[fieldType.linkGift].serialNo
                        })`
                      : infoState[fieldType.eventName],
                    placeholder: formConfig[fieldType.eventName].placeholder,
                    disabled: isNew
                      ? false
                      : isEdit
                      ? // 編輯模式，附屬好禮的名稱不可以編輯
                        infoState[fieldType.linkGift].serialNo
                      : !isEdit,
                    onChange: handleChange(contentType.eventName),
                    maxLength: formConfig[fieldType.eventName].maxlength,
                  }}
                />
                <InValidFeedback show={infoValid[fieldType.eventName].valid}>
                  {infoValid[fieldType.eventName].msg}
                </InValidFeedback>
              </Col>
            </Form.Group>
          </Col>
          <Col lg="6" />
          <Col lg="6">
            <Form.Group as={Col}>
              <Form.Label>{formConfig[fieldType.eventType].label}</Form.Label>
              <Col>
                <Select
                  className="form-row-input-fill"
                  selectedValue={infoState[fieldType.eventType]}
                  optionItems={formConfig[fieldType.eventType].selectOption}
                  onChange={handleChange(contentType.eventType)}
                  formControlOption={{
                    placeholder: formConfig[fieldType.eventType].placeholder,
                  }}
                  dropdownToggleOption={{ disabled: isFieldDisabled }}
                />
                <InValidFeedback show={infoValid[fieldType.eventType].valid}>
                  {infoValid[fieldType.eventType].msg}
                </InValidFeedback>
              </Col>
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group as={Col}>
              <Form.Label>
                {formConfig[fieldType.receiveLimit].label}
              </Form.Label>
              <Col>
                <Select
                  className="form-row-input-fill"
                  selectedValue={infoState[fieldType.receiveLimit]}
                  optionItems={formConfig[
                    fieldType.receiveLimit
                  ].selectOption.filter((i) =>
                    i.event.some(
                      (event) => event === infoState[fieldType.eventType]
                    )
                  )}
                  onChange={handleChange(contentType.receiveLimit)}
                  formControlOption={{
                    placeholder: formConfig[fieldType.receiveLimit].placeholder,
                  }}
                  dropdownToggleOption={{
                    disabled:
                      infoState[fieldType.eventType] !==
                        eventType.complimentaryFullAmount || isFieldDisabled,
                  }}
                />
                <InValidFeedback show={infoValid[fieldType.receiveLimit].valid}>
                  {infoValid[fieldType.receiveLimit].msg}
                </InValidFeedback>
              </Col>
            </Form.Group>
          </Col>
          <Col lg="6" />
          <Col lg="6">
            {infoState[fieldType.receiveLimit] ===
              receiveLimit.eventPeriodPickupSpecificTimes && (
              <Form.Group as={Col}>
                <Form.Label>
                  {formConfig[fieldType.receiveCount].label}
                </Form.Label>
                <Col>
                  <Input
                    className="form-row-input-fill"
                    appendContent="次"
                    appendContentBgColor="#fff"
                    appendContentPadding="0 4px 0 0"
                    appendContentHaveBorder={false}
                    isFocusCocatAppend
                    formControlOption={{
                      value: infoState[fieldType.receiveCount],
                      disabled: isFieldDisabled,
                      onChange: handleChange(contentType.receiveCount),
                    }}
                  />
                  <InValidFeedback
                    show={infoValid[fieldType.receiveCount].valid}
                  >
                    {infoValid[fieldType.receiveCount].msg}
                  </InValidFeedback>
                </Col>
              </Form.Group>
            )}
          </Col>
        </Form.Row>
        <Form.Row>
          <Col lg={`${isDesignatedList ? '6' : ''}`}>
            <Form.Group className="form-group-top" as={Col}>
              <Form.Label>{formConfig[fieldType.target].label}</Form.Label>
              <Col>
                {!isDesignatedList ? (
                  // 非選擇指定禮的情況
                  <Row>
                    <CheckListMainWrapper>
                      <Radio
                        label={formConfig[fieldType.target].radioOption[0]}
                        id={formConfig[fieldType.target].radioOption[0]}
                        checked={infoState[fieldType.target].forNewMember}
                        disabled={isFieldDisabled}
                        onChange={handleChange(contentType.newMember)}
                      />
                      <Radio
                        label={formConfig[fieldType.target].radioOption[1]}
                        id={formConfig[fieldType.target].radioOption[1]}
                        checked={
                          infoState[fieldType.target].forNewMember === false
                        }
                        disabled={isFieldDisabled}
                        onChange={handleChange(contentType.newMember)}
                      />
                    </CheckListMainWrapper>
                    {infoState[fieldType.target].forNewMember === false && (
                      <CheckListSubWrapper>
                        <Checkbox
                          id={formConfig[fieldType.target].checkboxOption[0]}
                          label={formConfig[fieldType.target].checkboxOption[0]}
                          checked={
                            infoState[fieldType.target].memberLevels.every(
                              (i) => i.selected
                            ) && infoState[fieldType.target].forInitialMember
                          }
                          disabled={isFieldDisabled}
                          inline
                          onChange={handleChange(contentType.allMembers)}
                        />
                        <LvZeroEl>
                          <Checkbox
                            id={formConfig[fieldType.target].checkboxOption[1]}
                            label={
                              formConfig[fieldType.target].checkboxOption[1]
                            }
                            checked={
                              infoState[fieldType.target].forInitialMember
                            }
                            disabled={isFieldDisabled}
                            inline
                            onChange={handleChange(contentType.basicMember)}
                          />
                          <Tooltip
                            triggerElement={
                              <img src={iconTooltip} alt="icon-tooltip" />
                            }
                            globalOption={{
                              effect: 'solid',
                            }}
                          >
                            {formConfig[fieldType.target].tip}
                          </Tooltip>
                        </LvZeroEl>
                        {memberLevels.map((item) => (
                          <Checkbox
                            key={item.code}
                            label={item.name}
                            id={item.id}
                            checked={infoState[
                              fieldType.target
                            ].memberLevels.some(
                              (level) => level.id === item.id && level.selected
                            )}
                            disabled={isFieldDisabled}
                            inline
                            onChange={handleChange(contentType.members)}
                          />
                        ))}
                      </CheckListSubWrapper>
                    )}
                  </Row>
                ) : (
                  // 選擇指定禮的情況
                  <Row>
                    <Select
                      className="w-100"
                      selectedValue={
                        infoState[fieldType.target].designatedList.filter(
                          (item) => item.selected
                        )[0]?.code
                      }
                      optionItems={selectKeyNameTranslate(designatedList)}
                      dropdownToggleOption={{
                        className: 'w-100',
                      }}
                      formControlOption={{
                        placeholder: formConfig[fieldType.target].placeholder,
                      }}
                      onChange={handleChange(contentType.designatedList)}
                    />
                  </Row>
                )}
                <InValidFeedback show={infoValid[fieldType.target].valid}>
                  {infoValid[fieldType.target].msg}
                </InValidFeedback>
              </Col>
            </Form.Group>
          </Col>
          <Col
            lg={`${isDesignatedList ? '6' : ''}`}
            className={`${isDesignatedList ? '' : 'd-none'}`}
          />
        </Form.Row>
        {infoState[fieldType.eventType] ===
          eventType.complimentaryFullAmount && (
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                {formConfig[fieldType.consumerCondition].label}
              </Form.Label>
              <Col>
                <ConsumerConditionWrapper>
                  <Row>{formConfig[fieldType.consumerCondition].content}</Row>
                  <Input
                    appendContent="元"
                    appendContentBgColor="#fff"
                    appendContentPadding="0 4px 0 0"
                    appendContentHaveBorder={false}
                    isFocusCocatAppend
                    formControlOption={{
                      value: infoState[fieldType.consumerCondition].condition,
                      disabled: isFieldDisabled,
                      onChange: handleChange(contentType.consumerCondition),
                    }}
                  />
                  <Checkbox
                    label={
                      formConfig[fieldType.consumerCondition].checkboxOption[0]
                    }
                    id={
                      formConfig[fieldType.consumerCondition].checkboxOption[0]
                    }
                    checked={infoState[fieldType.consumerCondition].check}
                    disabled={isFieldDisabled}
                    onChange={handleChange(contentType.consumerCondition_Check)}
                  />
                  <InValidFeedback
                    show={infoValid[fieldType.consumerCondition].valid}
                  >
                    {infoValid[fieldType.consumerCondition].msg}
                  </InValidFeedback>
                </ConsumerConditionWrapper>
              </Col>
            </Form.Group>
          </Form.Row>
        )}
        <Form.Row>
          <Col lg="6">
            <Form.Group>
              <Form.Label>{formConfig[fieldType.giftType].label}</Form.Label>
              <Tooltip
                triggerElement={
                  <Col>
                    <Select
                      className="form-row-input-fill"
                      selectedValue={infoState[fieldType.giftType]}
                      optionItems={formConfig[fieldType.giftType].selectOption}
                      onChange={handleChange(contentType.giftType)}
                      formControlOption={{
                        placeholder: formConfig[fieldType.giftType].placeholder,
                      }}
                      dropdownToggleOption={{
                        disabled: giftTypeAndReceiveMethonDisabled,
                      }}
                    />
                    <InValidFeedback show={infoValid[fieldType.giftType].valid}>
                      {infoValid[fieldType.giftType].msg}
                    </InValidFeedback>
                  </Col>
                }
                globalOption={{
                  effect: 'solid',
                  getContent: () =>
                    formConfig[fieldType.giftType].tooltipFn(isMainLinked),
                }}
              />
            </Form.Group>
            {infoState[fieldType.giftType] === giftType.offer && (
              <Form.Group>
                <Form.Label>
                  {formConfig[fieldType.giftSpecialOffer].label}
                </Form.Label>
                <ColumnListWrapper>
                  <GiftSpecialWrapper>
                    <Radio
                      id={formConfig[fieldType.giftSpecialOffer].radioOption[0]}
                      label={
                        formConfig[fieldType.giftSpecialOffer].radioOption[0]
                      }
                      checked={
                        infoState[fieldType.giftSpecialOffer].check ==
                        formConfig[fieldType.giftSpecialOffer].radioOption[0]
                      }
                      disabled={isFieldDisabled}
                      onChange={handleChange(
                        contentType.giftSpecialOfferDiscount_Check
                      )}
                    />
                    <Input
                      appendContent="元"
                      appendContentBgColor="#fff"
                      appendContentPadding="0 4px 0 0"
                      appendContentHaveBorder={false}
                      isFocusCocatAppend
                      formControlOption={{
                        value: infoState[fieldType.giftSpecialOffer].discount,
                        disabled: isFieldDisabled,
                        onChange: handleChange(
                          contentType.giftSpecialOfferDiscount
                        ),
                        maxLength:
                          formConfig[fieldType.giftSpecialOffer].maxlength,
                      }}
                    />
                  </GiftSpecialWrapper>
                  <GiftSpecialWrapper>
                    <Radio
                      id={formConfig[fieldType.giftSpecialOffer].radioOption[1]}
                      label={
                        formConfig[fieldType.giftSpecialOffer].radioOption[1]
                      }
                      checked={
                        infoState[fieldType.giftSpecialOffer].check ===
                        formConfig[fieldType.giftSpecialOffer].radioOption[1]
                      }
                      disabled={isFieldDisabled}
                      onChange={handleChange(
                        contentType.giftSpecialOfferConsumerAmount_Check
                      )}
                    />
                    <Input
                      appendContent="%"
                      appendContentBgColor="#fff"
                      appendContentPadding="0 4px 0 0"
                      appendContentHaveBorder={false}
                      isFocusCocatAppend
                      formControlOption={{
                        value:
                          infoState[fieldType.giftSpecialOffer].consumerAmount,
                        disabled: isFieldDisabled,
                        onChange: handleChange(
                          contentType.giftSpecialOfferConsumerAmount
                        ),
                      }}
                    />
                  </GiftSpecialWrapper>
                  <InValidFeedback
                    show={infoValid[fieldType.giftSpecialOffer].valid}
                  >
                    {infoValid[fieldType.giftSpecialOffer].msg}
                  </InValidFeedback>
                </ColumnListWrapper>
              </Form.Group>
            )}
            {infoState[fieldType.giftType] === giftType.point && (
              <Form.Group>
                <Form.Label>{formConfig[fieldType.giftPoint].label}</Form.Label>
                <Col>
                  <Input
                    className="form-row-input-fill"
                    appendContent="點"
                    appendContentBgColor="#fff"
                    appendContentPadding="0 4px 0 0"
                    appendContentHaveBorder={false}
                    isFocusCocatAppend
                    formControlOption={{
                      value: infoState[fieldType.giftPoint],
                      disabled: isFieldDisabled,
                      onChange: handleChange(contentType.giftPoint),
                      maxLength: formConfig[fieldType.giftPoint].maxlength,
                    }}
                  />
                  <InValidFeedback show={infoValid[fieldType.giftPoint].valid}>
                    {infoValid[fieldType.giftPoint].msg}
                  </InValidFeedback>
                </Col>
              </Form.Group>
            )}
            {infoState[fieldType.giftType] === giftType.gift && (
              <>
                <Form.Group>
                  <Col>
                    <ImageUploader
                      value={infoState[fieldType.giftImage]?.url}
                      disabled={isFieldDisabled}
                      onChange={handleChange(contentType.giftImage)}
                      onError={handleImageError}
                      limit={imageLimitConfig}
                    />
                    <Form.Text>{formConfig[fieldType.giftImage].tip}</Form.Text>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {formConfig[fieldType.giftCode].label}
                  </Form.Label>
                  <Col>
                    <Input
                      className="form-row-input-fill"
                      formControlOption={{
                        placeholder: formConfig[fieldType.giftCode].placeholder,
                        value: infoState[fieldType.giftCode],
                        disabled: isFieldDisabled,
                        onChange: handleChange(contentType.giftCode),
                        maxLength: formConfig[fieldType.giftCode].maxlength,
                      }}
                    />
                    <Form.Text>{formConfig[fieldType.giftCode].tip}</Form.Text>
                    <InValidFeedback show={infoValid[fieldType.giftCode].valid}>
                      {infoValid[fieldType.giftCode].msg}
                    </InValidFeedback>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {formConfig[fieldType.giftName].label}
                  </Form.Label>
                  <Col>
                    <Input
                      className="form-row-input-fill"
                      formControlOption={{
                        placeholder: formConfig[fieldType.giftName].placeholder,
                        value: infoState[fieldType.giftName],
                        disabled: isFieldDisabled,
                        onChange: handleChange(contentType.giftName),
                        maxLength: formConfig[fieldType.giftName].maxlength,
                      }}
                    />
                    <InValidFeedback show={infoValid[fieldType.giftName].valid}>
                      {infoValid[fieldType.giftName].msg}
                    </InValidFeedback>
                  </Col>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {formConfig[fieldType.giftColor].label}
                  </Form.Label>
                  <Input
                    className="form-row-input-fill"
                    formControlOption={{
                      placeholder: formConfig[fieldType.giftColor].placeholder,
                      value: infoState[fieldType.giftColor],
                      disabled: isFieldDisabled,
                      onChange: handleChange(contentType.giftColor),
                      maxLength: formConfig[fieldType.giftColor].maxlength,
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {formConfig[fieldType.giftSize].label}
                  </Form.Label>
                  <Input
                    className="form-row-input-fill"
                    formControlOption={{
                      placeholder: formConfig[fieldType.giftSize].placeholder,
                      value: infoState[fieldType.giftSize],
                      disabled: isFieldDisabled,
                      onChange: handleChange(contentType.giftSize),
                      maxLength: formConfig[fieldType.giftSize].maxlength,
                    }}
                  />
                </Form.Group>
              </>
            )}
          </Col>
          <Col lg="6">
            <Form.Group>
              <Form.Label>{formConfig[fieldType.giftAmount].label}</Form.Label>
              <Col>
                <Input
                  className="form-row-input-fill"
                  formControlOption={{
                    placeholder: formConfig[fieldType.giftAmount].placeholder,
                    value: infoState[fieldType.giftAmount],
                    disabled: isGiftAmountDisabled,
                    onChange: handleChange(contentType.giftAmount),
                  }}
                />
                <InValidFeedback show={infoValid[fieldType.giftAmount].valid}>
                  {infoValid[fieldType.giftAmount].msg}
                </InValidFeedback>
              </Col>
            </Form.Group>
            {(infoState[fieldType.giftType] === giftType.offer ||
              infoState[fieldType.giftType] === giftType.gift) && (
              <Form.Group>
                <Form.Label>
                  {formConfig[fieldType.receiveMethod].label}
                </Form.Label>
                <Col>
                  <Tooltip
                    triggerElement={
                      <Row>
                        <Radio
                          id={
                            formConfig[fieldType.receiveMethod].radioOption[0]
                          }
                          label={
                            formConfig[fieldType.receiveMethod].radioOption[0]
                          }
                          checked={
                            infoState[fieldType.receiveMethod] ===
                            formConfig[fieldType.receiveMethod].radioOption[0]
                          }
                          disabled={giftTypeAndReceiveMethonDisabled}
                          onChange={handleChange(contentType.receiveMethod)}
                        />
                        <Radio
                          id={
                            formConfig[fieldType.receiveMethod].radioOption[1]
                          }
                          label={
                            formConfig[fieldType.receiveMethod].radioOption[1]
                          }
                          checked={
                            infoState[fieldType.receiveMethod] ===
                            formConfig[fieldType.receiveMethod].radioOption[1]
                          }
                          disabled={giftTypeAndReceiveMethonDisabled}
                          onChange={handleChange(contentType.receiveMethod)}
                        />
                      </Row>
                    }
                    globalOption={{
                      effect: 'solid',
                      getContent: () =>
                        formConfig[fieldType.receiveMethod].tooltipFn(
                          isMainLinked
                        ),
                    }}
                  />

                  <Form.Text>
                    {formConfig[fieldType.receiveMethod].tip}
                  </Form.Text>
                  <InValidFeedback
                    show={infoValid[fieldType.receiveMethod].valid}
                  >
                    {infoValid[fieldType.receiveMethod].msg}
                  </InValidFeedback>
                </Col>
              </Form.Group>
            )}
          </Col>
        </Form.Row>
        <Form.Row>
          <Col lg="6">
            <Form.Group>
              <Form.Label>{formConfig[fieldType.eventStart].label}</Form.Label>
              <Col>
                <ColumnListWrapper>
                  <Radio
                    id={formConfig[fieldType.eventStart].radioOption[0]}
                    label={formConfig[fieldType.eventStart].radioOption[0]}
                    checked={
                      infoState[fieldType.eventStart].check ===
                      formConfig[fieldType.eventStart].radioOption[0]
                    }
                    disabled={isFieldDisabled}
                    onChange={handleChange(contentType.eventStart_Check)}
                  />
                  <CalendarWrapper>
                    <Radio
                      id={formConfig[fieldType.eventStart].radioOption[1]}
                      label={formConfig[fieldType.eventStart].radioOption[1]}
                      checked={
                        infoState[fieldType.eventStart].check ===
                        formConfig[fieldType.eventStart].radioOption[1]
                      }
                      disabled={isFieldDisabled}
                      onChange={handleChange(contentType.eventStart_Check)}
                    />
                    <Calendar
                      value={infoState[fieldType.eventStart].calendar}
                      disabled={isFieldDisabled}
                      onChange={handleChange(contentType.eventStart)}
                      minDate={startOfToday()}
                    />
                  </CalendarWrapper>
                </ColumnListWrapper>
                <InValidFeedback show={infoValid[fieldType.eventStart].valid}>
                  {infoValid[fieldType.eventStart].msg}
                </InValidFeedback>
                <Form.Text>
                  {
                    formConfig[fieldType.eventStart][
                      `tip${infoState[fieldType.eventType]}`
                    ]
                  }
                </Form.Text>
              </Col>
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group>
              <Form.Label>{formConfig[fieldType.eventEnd].label}</Form.Label>
              <Col>
                <ColumnListWrapper>
                  <Radio
                    id={formConfig[fieldType.eventEnd].radioOption[0]}
                    label={formConfig[fieldType.eventEnd].radioOption[0]}
                    checked={
                      infoState[fieldType.eventEnd].check ===
                      formConfig[fieldType.eventEnd].radioOption[0]
                    }
                    disabled={isFieldDisabledByView}
                    onChange={handleChange(contentType.eventEnd_Check)}
                  />
                  <CalendarWrapper>
                    <Radio
                      id={formConfig[fieldType.eventEnd].radioOption[1]}
                      label={formConfig[fieldType.eventEnd].radioOption[1]}
                      checked={
                        infoState[fieldType.eventEnd].check ===
                        formConfig[fieldType.eventEnd].radioOption[1]
                      }
                      disabled={isFieldDisabledByView}
                      onChange={handleChange(contentType.eventEnd_Check)}
                    />
                    <Calendar
                      value={infoState[fieldType.eventEnd].calendar}
                      disabled={isFieldDisabledByView}
                      onChange={handleChange(contentType.eventEnd)}
                      minDate={startOfToday()}
                    />
                  </CalendarWrapper>
                </ColumnListWrapper>
                <InValidFeedback show={infoValid[fieldType.eventEnd].valid}>
                  {infoValid[fieldType.eventEnd].msg}
                </InValidFeedback>
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>{formConfig[fieldType.autoUpload].label}</Form.Label>
              <Col>
                <ColumnListWrapper>
                  <Radio
                    id={formConfig[fieldType.autoUpload].radioOption[0]}
                    label={formConfig[fieldType.autoUpload].radioOption[0]}
                    checked={
                      infoState[fieldType.autoUpload] ===
                      formConfig[fieldType.autoUpload].radioOption[0]
                    }
                    disabled={isFieldDisabledByView}
                    onChange={handleChange(contentType.autoUpload)}
                  />
                  <Radio
                    id={formConfig[fieldType.autoUpload].radioOption[1]}
                    label={formConfig[fieldType.autoUpload].radioOption[1]}
                    checked={
                      infoState[fieldType.autoUpload] ===
                      formConfig[fieldType.autoUpload].radioOption[1]
                    }
                    disabled={isFieldDisabledByView}
                    onChange={handleChange(contentType.autoUpload)}
                  />
                </ColumnListWrapper>
                <InValidFeedback show={infoValid[fieldType.autoUpload].valid}>
                  {infoValid[fieldType.autoUpload].msg}
                </InValidFeedback>
              </Col>
            </Form.Group>
          </Col>
        </Form.Row>
        {infoState[fieldType.receiveMethod] && (
          <Form.Row>
            <Col lg="6">
              <Form.Group className="form-group-align-center">
                <Form.Label>
                  {formConfig[fieldType.receiveStart].label}
                </Form.Label>
                <ColumnListWrapper>
                  <Calendar
                    value={infoState[fieldType.receiveStart]}
                    disabled={isFieldDisabled}
                    onChange={handleChange(contentType.receiveStart)}
                    minDate={startOfToday()}
                  />
                  <InValidFeedback
                    show={infoValid[fieldType.receiveStart].valid}
                  >
                    {infoValid[fieldType.receiveStart].msg}
                  </InValidFeedback>
                </ColumnListWrapper>
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <Form.Label>
                  {formConfig[fieldType.receiveEnd].label}
                </Form.Label>
                <Col>
                  <ColumnListWrapper>
                    <Radio
                      id={formConfig[fieldType.receiveEnd].radioOption[0].text}
                      label={
                        formConfig[fieldType.receiveEnd].radioOption[0].text
                      }
                      value={
                        formConfig[fieldType.receiveEnd].radioOption[0].value
                      }
                      checked={
                        infoState[fieldType.giftCodeEndTimeType] ===
                        formConfig[fieldType.receiveEnd].radioOption[0].value
                      }
                      disabled={isFieldDisabledByView}
                      onChange={handleChange(contentType.receiveEnd)}
                    />
                    <CalendarWrapper>
                      <Radio
                        id={`${fieldType.receiveEnd}-${
                          formConfig[fieldType.receiveEnd].radioOption[1].text
                        }}`}
                        label={
                          formConfig[fieldType.receiveEnd].radioOption[1].text
                        }
                        value={
                          formConfig[fieldType.receiveEnd].radioOption[1].value
                        }
                        checked={
                          infoState[fieldType.giftCodeEndTimeType] ===
                          formConfig[fieldType.receiveEnd].radioOption[1].value
                        }
                        disabled={isFieldDisabledByView}
                        onChange={handleChange(contentType.receiveEnd)}
                      />

                      <Calendar
                        id="calendar"
                        value={infoState[fieldType.giftCodeEndTime]}
                        disabled={isFieldDisabledByView}
                        onChange={handleReceiveEndCalendar}
                        minDate={startOfToday()}
                      />
                    </CalendarWrapper>
                    <ReceiveEndOptionsWrapper>
                      <Radio
                        id={`${fieldType.receiveEnd}-${
                          formConfig[fieldType.receiveEnd].radioOption[2].text
                        }}`}
                        label={
                          formConfig[fieldType.receiveEnd].radioOption[2].text
                        }
                        value={
                          formConfig[fieldType.receiveEnd].radioOption[2].value
                        }
                        checked={
                          infoState[fieldType.giftCodeEndTimeType] ===
                          formConfig[fieldType.receiveEnd].radioOption[2].value
                        }
                        disabled={isFieldDisabledByView}
                        onChange={handleChange(contentType.receiveEnd)}
                      />
                      <Select
                        id="afterdays"
                        className="form-row-input-fill form-select"
                        selectedValue={
                          infoState[fieldType.giftCodeEndTimeAfterDays]
                        }
                        optionItems={
                          formConfig[fieldType.giftCodeEndTimeType].selectOption
                        }
                        onChange={handleReceiveEndAfterDays}
                        formControlOption={{
                          placeholder:
                            formConfig[fieldType.giftCodeEndTimeType]
                              .placeholder,
                        }}
                        dropdownToggleOption={{
                          disabled: isFieldDisabledByView,
                        }}
                      />
                    </ReceiveEndOptionsWrapper>
                  </ColumnListWrapper>
                  {showInValidFeedback(
                    infoValid[fieldType.giftCodeEndTimeType].valid,
                    infoValid[fieldType.giftCodeEndTimeType]
                  )}
                  {showInValidFeedback(
                    infoValid[fieldType.giftCodeEndTimeAfterDays].valid,
                    infoValid[fieldType.giftCodeEndTimeAfterDays]
                  )}
                  {showInValidFeedback(
                    infoValid[fieldType.giftCodeEndTime].valid,
                    infoValid[fieldType.giftCodeEndTime]
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Form.Row>
        )}
        <Form.Row className="form-row-lasted">
          <Form.Group as={Col}>
            <Form.Label>{formConfig[fieldType.giftInfo].label}</Form.Label>
            <Col>
              <Editor
                height="146"
                width="100%"
                placeholder={formConfig[fieldType.giftInfo].placeholder}
                defaultValue={infoState[fieldType.giftInfo]}
                disabled={giftInfoDisabled}
                onChange={handleEditorChange.current}
              />
              <InValidFeedback show={infoValid[fieldType.giftInfo].valid}>
                {infoValid[fieldType.giftInfo].msg}
              </InValidFeedback>
            </Col>
          </Form.Group>
        </Form.Row>
      </Form>
    </FormWrapper>
  )
}

export default ExclusiveContent
