import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import { Modal } from 'components/units'
import { MemberModalWrapper } from 'components/pages/Gift/ExclusiveSendListStyle'
import { CaptionWrapper } from 'components/pages/Gift/ListExchangeGiftStyle'
import { number } from 'helpers/format'

function Caption({ paginationProps, total = 0 }) {
  return (
    <CaptionWrapper>
      <div>
        <div className="caption-label">總項目：</div>
        <div>{`共 ${number(total)} 筆`}</div>
      </div>
      <div>
        <div className="caption-color mr-1">每頁</div>
        <SizePerPageDropdownStandalone
          {...paginationProps}
          sizePerPage={`${paginationProps.sizePerPage} 筆`}
        />
      </div>
    </CaptionWrapper>
  )
}

function DetailModal({
  show = false,
  data = {},
  isDefaultDataLoading = false,
  onClose = () => {},
  onConfirm = () => {},
}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      onConfirm={onConfirm}
      titleText="發送詳情"
      customConfirm={<></>}
      closeBtnText="關閉"
      isDefaultDataLoading={isDefaultDataLoading}
    >
      {/* 
        giftType:
          1:優惠
          2:點數
          3:贈品
      } */}
      <MemberModalWrapper>
        <div className="member-info">
          <div className="member-label">狀態：</div>
          <div className="member-content">{data?.statusDescription}</div>
        </div>
        <div className="member-info">
          <div className="member-label">發送時間：</div>
          <div className="member-content">{data?.sendTime}</div>
        </div>
        {data?.giftType !== 2 && (
          <>
            <div className="member-info">
              <div className="member-label">領取方式：</div>
              <div className="member-content">{data?.receiveWay}</div>
            </div>
            <div className="member-info">
              <div className="member-label">領取效期：</div>
              <div className="member-content">{data?.gotGiftValidPeriod}</div>
            </div>
            <div className="member-info">
              <div className="member-label">領取時間：</div>
              <div className="member-content">{data?.gotTime}</div>
            </div>
            <div className="member-info">
              <div className="member-label">領取通路：</div>
              <div className="member-content">{data?.receiveChannel}</div>
            </div>
            <div className="member-info">
              <div className="member-label">好禮序號：</div>
              <div className="member-content">{data?.code}</div>
            </div>
          </>
        )}
        <hr />
        <div className="member-info">
          <div className="member-label">會員編號：</div>
          <div className="member-content">{data?.customClientId}</div>
        </div>
        <div className="member-info">
          <div className="member-label">會員：</div>
          <div className="member-content">{data?.member}</div>
        </div>
        <div className="member-info">
          <div className="member-label">手機：</div>
          <div className="member-content">{data?.phone}</div>
        </div>
        <div className="member-info">
          <div className="member-label">會員等級：</div>
          <div className="member-content">{data?.level}</div>
        </div>
        <hr />
        <div className="member-info">
          <div className="member-label">類別：</div>
          <div className="member-content">{data?.type}</div>
        </div>
        <div className="member-info">
          <div className="member-label">名稱：</div>
          <div className="member-content">{data?.name}</div>
        </div>
        <div className="member-info">
          <div className="member-label">內容：</div>
          <div className="member-content">{data?.content}</div>
        </div>
        {data?.giftType === 1 && (
          <div className="member-info">
            <div className="member-label">優惠：</div>
            <div className="member-content">{data?.giftContent}</div>
          </div>
        )}
        {data?.giftType === 2 && (
          <div className="member-info">
            <div className="member-label">點數：</div>
            <div className="member-content">{data?.giftContent}</div>
          </div>
        )}
        {data?.giftType === 3 && (
          <>
            <div className="member-info">
              <div className="member-label">贈品識別碼：</div>
              <div className="member-content">{data?.customId}</div>
            </div>
            <div className="member-info">
              <div className="member-label">贈品名稱：</div>
              <div className="member-content">{data?.giftName}</div>
            </div>
            <div className="member-info">
              <div className="member-label">顏色：</div>
              <div className="member-content">{data?.giftColor}</div>
            </div>
            <div className="member-info">
              <div className="member-label">尺寸：</div>
              <div className="member-content">{data?.giftSize}</div>
            </div>
          </>
        )}
      </MemberModalWrapper>
    </Modal>
  )
}

export { Caption, DetailModal }
