import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import {
  handleCloseToast,
  showTopToast,
  showUploadToast,
} from 'components/units/Toast'
import ToastFooter from 'components/units/ToastFooter'
import { TOAST } from 'constant/common'
import { UPLOAD_STAGE } from 'constant/toast'
import { authAccountSelector } from 'store/auth/authSelector'
import {
  batchModuleSelector,
  batchToastSelector,
  getSerialBatchModuleThunk,
  resetSerialUploadProgress,
  signalrStatusSelector,
  startConnection,
  stopConnection,
} from 'store/serial-batch-upload/serialBatchUploadSlice'

function SerialBatchUpload() {
  const dispatch = useDispatch()
  const { brandId } = useSelector(authAccountSelector)
  const history = useHistory()
  const prevBrandId = useRef(null)
  const modules = useSelector(batchModuleSelector)
  const isSignalrBuilt = useSelector(signalrStatusSelector)
  const toasts = useSelector(batchToastSelector)
  const isSwitchBrand = prevBrandId.current && prevBrandId.current !== brandId

  const batchUploadToasts = toasts.map((toast) => {
    if (toast?.type !== 'completed') return toast
    const {
      redirectUrl = null,
      reportLink = null,
      redirectTab = null,
    } = toast.footer

    const handleRedirect = () => {
      history.push(redirectUrl, { apiPayload: { state: redirectTab } })
    }

    return {
      ...toast,
      footer: (
        <ToastFooter reportLink={reportLink} redirectFn={handleRedirect} />
      ),
    }
  })

  const getAuthThunk = useCallback(() => {
    if (!brandId) return
    dispatch(getSerialBatchModuleThunk(brandId))
  }, [dispatch, brandId])

  useEffect(() => {
    if (isSwitchBrand) {
      prevBrandId.current = null
      handleCloseToast() // 切換品牌時，關閉所有 Toast
      if (batchUploadToasts.length) {
        batchUploadToasts.forEach((toast) => {
          dispatch(stopConnection({ module: toast.message.module }))
        })
      }
    }
  }, [brandId, dispatch, batchUploadToasts, isSwitchBrand])

  useEffect(() => {
    if (!prevBrandId.current) {
      prevBrandId.current = brandId
      getAuthThunk()
    }
  }, [brandId, getAuthThunk])

  useEffect(() => {
    if (!isSwitchBrand && modules.length && !isSignalrBuilt) {
      modules.forEach((module) => {
        dispatch(startConnection({ module }))
      })
    }
    if (batchUploadToasts.length)
      batchUploadToasts.forEach((toast) => {
        const { module } = toast.message
        const { toastId } = toast
        const moduleNameConfig = {
          gift: '贈品序號',
          vipGift: '好禮序號',
        }
        const errorMsg = {
          uploadError: `${moduleNameConfig[module]} 批次上傳發生錯誤，請再試一次`,
          serverError: '上傳連線異常，請聯絡系統管理員',
        }
        if (toast.brandId !== brandId) return
        if (toast.type === TOAST.ERROR) {
          handleCloseToast(toastId)
          showTopToast(
            {
              message: toast.isServerError
                ? errorMsg.serverError
                : errorMsg.uploadError,
            },
            TOAST.ERROR,
            {
              id: toastId,
            }
          )
          dispatch(resetSerialUploadProgress({ module }))
        } else {
          showUploadToast(toast)
          if (toast.type === UPLOAD_STAGE.COMPLETED) {
            dispatch(resetSerialUploadProgress({ module }))
          }
        }
      })
  }, [
    modules,
    isSignalrBuilt,
    dispatch,
    batchUploadToasts,
    isSwitchBrand,
    brandId,
  ])
}

export default SerialBatchUpload
