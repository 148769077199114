import { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import ListGoodsList from 'components/pages/Gift/ListGoodsList'
import ListSearch from 'components/pages/Gift/ListSearch'
import ExchangeGiftVerificationList from 'components/pages/gift-list/containers/ExchangeGiftVerificationList'
import ListExchangeGift from 'components/pages/gift-list/containers/ListExchangeGift'
import ListExclusiveGift from 'components/pages/gift-list/containers/ListExclusiveGift'
import VipGiftVerificationList from 'components/pages/gift-list/containers/VipGiftVerificationList'
import { Basic, ContentSection } from 'components/templates'
import { Breadcrumb } from 'components/units'
import { COMPONENT_PERMISSIONS } from 'constant'
import { isPermissionAllowed } from 'store/permission/permissionSlice'

function NavSection() {
  const pathList = [{ title: '好禮贈送', slash: true }]
  return <Breadcrumb now="好禮名單" pathList={pathList} />
}

function GiftList() {
  const isExclusiveGiftShow = useSelector(
    isPermissionAllowed(COMPONENT_PERMISSIONS.giftListVipGift.READ)
  )
  const isExchangeGiftShow = useSelector(
    isPermissionAllowed(COMPONENT_PERMISSIONS.giftListVipGift.READ)
  )
  const isGoodsListShow = useSelector(
    isPermissionAllowed(COMPONENT_PERMISSIONS.giftShippingList.READ)
  )
  const isVipGiftVerificationShow = useSelector(
    isPermissionAllowed(COMPONENT_PERMISSIONS.giftListVipGift.UPDATE)
  )
  const isExchangeGiftVerificationShow = useSelector(
    isPermissionAllowed(COMPONENT_PERMISSIONS.giftListVipGift.UPDATE)
  )

  const tabOrder = [
    {
      eventKey: 'exclusiveGift',
      isVisible: isExclusiveGiftShow,
    },
    {
      eventKey: 'exchangeGift',
      isVisible: isExchangeGiftShow,
    },
    {
      eventKey: 'goodsList',
      isVisible: isGoodsListShow,
    },
    {
      eventKey: 'vipGiftVerification',
      isVisible: isVipGiftVerificationShow,
    },
    {
      eventKey: 'exchangeGiftVerification',
      isVisible: isExchangeGiftVerificationShow,
    },
  ]
  const firstTab = tabOrder.find((config) => config.isVisible)?.eventKey
  const [tabValue, setTabValue] = useState(firstTab)
  const [sortable, setSortable] = useState(false)

  const initTablePayload = {
    p: 1,
    ps: 25,
    sf: tabValue === 'exclusiveGift' ? 'sendTime' : 'exchangeTime',
    so: 'desc',
  }
  const [tablePayload, setTablePayload] = useState(initTablePayload)
  const [searchPayload, setSearchPayload] = useState({})

  /* 切換 Tab 同時先 reset tablePayload */
  const handleTabChange = (value) => {
    setTabValue(value)
    setTablePayload(initTablePayload)
  }

  const handleSearch = (formValues) => {
    if (!formValues) return
    setSearchPayload(formValues)
    setTablePayload({ ...tablePayload, p: 1 })
    setSortable(true)
  }

  /* 清除所有搜尋條件 */
  const handleClearAllField = () => {
    setTablePayload(initTablePayload)
    setSearchPayload({})
    setSortable(false)
  }

  return (
    <Basic navSection={NavSection}>
      <ContentSection>
        <ListSearch onSearch={handleSearch} onReset={handleClearAllField} />
        <Tabs defaultActiveKey={tabValue} onSelect={handleTabChange}>
          {isExclusiveGiftShow && (
            <Tab eventKey="exclusiveGift" title="專屬好禮">
              <ListExclusiveGift
                tablePayload={tablePayload}
                sortable={sortable}
                setTablePayload={setTablePayload}
                searchPayload={searchPayload}
                tabValue={tabValue}
              />
            </Tab>
          )}

          {isExchangeGiftShow && (
            <Tab eventKey="exchangeGift" title="贈品兌換">
              <ListExchangeGift
                tablePayload={tablePayload}
                sortable={sortable}
                setTablePayload={setTablePayload}
                searchPayload={searchPayload}
                tabValue={tabValue}
              />
            </Tab>
          )}

          {isGoodsListShow && (
            <Tab eventKey="goodsList" title="出貨名單">
              <ListGoodsList
                searchPayload={searchPayload}
                tabValue={tabValue}
              />
            </Tab>
          )}

          {isVipGiftVerificationShow && (
            <Tab eventKey="vipGiftVerification" title="好禮核銷">
              <VipGiftVerificationList
                tablePayload={tablePayload}
                searchPayload={searchPayload}
                sortable={sortable}
                setTablePayload={setTablePayload}
                tabValue={tabValue}
              />
            </Tab>
          )}

          {isExchangeGiftVerificationShow && (
            <Tab eventKey="exchangeGiftVerification" title="贈品核銷">
              <ExchangeGiftVerificationList
                tablePayload={tablePayload}
                searchPayload={searchPayload}
                sortable={sortable}
                setTablePayload={setTablePayload}
                tabValue={tabValue}
              />
            </Tab>
          )}
        </Tabs>
      </ContentSection>
    </Basic>
  )
}

export default GiftList
