import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import MemberDetailAndEdit from '../../components/pages/Member/DetailAndEdit'
import { RootContext } from 'RootContext'
import { useDispatch } from 'react-redux'
import { updatePageLoading } from 'store/global/globalSlice'
import { clearLocalStorage } from 'helpers/common'

function MemberEdit() {
  const dispatch = useDispatch()
  // global data
  const { brandId, shopId } = useContext(RootContext)
  const history = useHistory()
  const [isShowFeatureBtn, setShowFeatureBtn] = useState(true)
  const [data, setData] = useState({
    brandId: brandId,
    shopId: shopId,
  })

  const { brandId: browserBrandId } = useContext(RootContext)

  useEffect(() => {
    if (data.brandId === undefined && data.shopId === undefined) {
      if (brandId !== undefined && shopId !== undefined) {
        setData({ brandId: brandId, shopId: shopId })
      }
    } else if (
      // 回到會員列表頁的情境:
      // 1. data.brandId 和 context brandId 不同
      // 2. data.shopId 和 context shopId 不同
      // 3. data.brandId 和 browserBrandId 不同
      data.brandId !== brandId ||
      data.shopId !== shopId ||
      browserBrandId !== brandId
    ) {
      history.push('/member')
      clearLocalStorage('brandId')
    }
  }, [data, brandId, shopId, history, browserBrandId])

  useEffect(() => {
    if (data.brandId && data.shopId) dispatch(updatePageLoading(false))
  }, [dispatch, data])

  return (
    <MemberDetailAndEdit
      isShowFeatureBtn={isShowFeatureBtn}
      setShowFeatureBtn={setShowFeatureBtn}
    />
  )
}

export default MemberEdit
