import { fetchData } from 'api/ApiRoute'
import { commonFetchProps, parserUrl, showToast } from 'api/ApiCommon'

const productActivity = {
  default: 'setting/brand/{brandId}/point/productrule',
  info: 'setting/brand/{brandId}/point/productrule/{pointProductRuleId}',
  stop: 'setting/brand/{brandId}/point/productrule/{pointProductRuleId}/stop',
  defaultProductList:
    'setting/brand/{brandId}/point/productrule/{pointProductRuleId}/product',
  productItemBatch:
    'setting/brand/{brandId}/point/productrule/{pointProductRuleId}/product/batch',
  productItemImport:
    'setting/brand/{brandId}/point/productrule/{pointProductRuleId}/product/import',
  pointProductMultiple:
    'setting/brand/{brandId}/point/productrule/pointproductrulemultiple',
}

const handleError400 = (rsp) => {
  if (rsp.status === 400 && !rsp?.data?.errors && rsp?.data?.msg) {
    showToast(rsp)
  }
  return Promise.reject(rsp.data ?? rsp)
}

const getProductActivityList = (brandId, payload) => {
  return fetchData(
    parserUrl(productActivity.default, { brandId }, payload),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const getProductActivityEvent = (brandId, pointProductRuleId) => {
  return fetchData(
    parserUrl(productActivity.info, { brandId, pointProductRuleId }),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const deleteProductActivityEvent = (brandId, pointProductRuleId) => {
  return fetchData(
    parserUrl(productActivity.info, { brandId, pointProductRuleId }),
    'DELETE',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const stopProductActivity = (brandId, pointProductRuleId) => {
  return fetchData(
    parserUrl(productActivity.stop, { brandId, pointProductRuleId }),
    'PATCH',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const getPointproductrulemultiple = (brandId) => {
  return fetchData(
    parserUrl(productActivity.pointProductMultiple, { brandId }),
    'GET',
    null,
    commonFetchProps
  ).catch(handleError400)
}

const addProductActivityEvent = (brandId, body) => {
  return fetchData(
    parserUrl(productActivity.default, { brandId }),
    'POST',
    body
  ).catch(handleError400)
}

const updateProductActivityEvent = (brandId, pointProductRuleId, body) => {
  return fetchData(
    parserUrl(productActivity.info, { brandId, pointProductRuleId }),
    'PATCH',
    body
  ).catch(handleError400)
}

const getProductListList = async (brandId, pointProductRuleId, payload) => {
  try {
    const rsp = await fetchData(
      parserUrl(
        productActivity.defaultProductList,
        { brandId, pointProductRuleId },
        payload
      ),
      'GET',
      null,
      commonFetchProps
    )
    return rsp
  } catch (error) {
    handleError400(error)
  }
}

const addSingleProductListItem = async (brandId, pointProductRuleId, body) => {
  try {
    const response = await fetchData(
      parserUrl(productActivity.defaultProductList, {
        brandId,
        pointProductRuleId,
      }),
      'POST',
      body
    )
    showToast(response, '新增商品成功')
    return response
  } catch (error) {
    return handleError400(error)
  }
}

const batchDeleteProductItem = async (brandId, pointProductRuleId, body) => {
  try {
    const response = await fetchData(
      `setting/brand/${brandId}/point/productrule/${pointProductRuleId}/product/batch?${body}`,
      'DELETE',
      null,
      commonFetchProps
    )
    showToast(response, '批次刪除成功')
    return response
  } catch (error) {
    return handleError400(error)
  }
}

const importProductItems = async (
  brandId,
  pointProductRuleId,
  body,
  cancelToken
) => {
  try {
    const response = await fetchData(
      parserUrl(productActivity.productItemImport, {
        brandId,
        pointProductRuleId,
      }),
      'POST',
      body,
      cancelToken
    )
    return response
  } catch (res) {
    return handleError400(res)
  }
}

export {
  getProductActivityList, // 獲取對應品牌的 "商品活動" 列表
  getProductActivityEvent, // 獲取對應品牌的「指定商品活動」相關設定資料
  deleteProductActivityEvent, // 刪除對應品牌的「指定商品活動」資料
  stopProductActivity, // 停用對應品牌的「指定商品活動」資料
  getPointproductrulemultiple, // 獲取對應品牌的點數贈送倍數選項
  addProductActivityEvent, // 新增對應品牌的「商品活動」資料
  updateProductActivityEvent, // 更新對應品牌的「指定商品活動」資料
  getProductListList, // 獲取對應品牌裡指定商品活動的 "所屬商品" 列表
  addSingleProductListItem, // 新增所屬商品 - 對應品牌的商品活動
  batchDeleteProductItem, // 批次刪除所屬商品 - 對應品牌的商品活動
  importProductItems, // 上傳匯入所屬商品 - 對應品牌的商品活動
}
