import styled from 'styled-components'
import { H1, H6 } from 'components/units'
import { colorObj } from 'assets/styles/Variable/Color'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  h1 {
    margin: 0px;
  }
`

const NavList = styled.span`
  span:nth-child(odd) {
    cursor: ${({ isClick }) => (isClick ? 'pointer' : 'default')};
    &:hover {
      color: ${({ isClick }) => (isClick ? colorObj.primary : 'inherit')};
    }
  }
  span:nth-child(even) {
    cursor: default;
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  .tilte-description {
    font-size: 15px;
    color: #333;
    margin-left: 10px;
  }
`

function Breadcrumb({ now = '', description = '', pathList = [], title }) {
  return (
    <Wrapper>
      <H6 color={colorObj.gray}>
        {pathList.map((item, index) => (
          <NavList key={`breadcrumb-navlist-${index}`} isClick={item?.onClick}>
            <span onClick={item.onClick ?? item.onClick}>{item.title}</span>
            <span>{item.slash ? ' / ' : ''}</span>
          </NavList>
        ))}
      </H6>
      <TitleWrap>
        <H1>{now || title}</H1>
        <span className="tilte-description width-max-content d-inline-block">
          {description}
        </span>
      </TitleWrap>
    </Wrapper>
  )
}

export default Breadcrumb
