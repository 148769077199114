import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { colorObj, colorCommon } from 'assets/styles/Variable/Color'
import { handleKeyPressEnter } from 'helpers/common'

const PaginationInputSection = styled.div`
  span {
    color: ${colorObj.darkGray};
  }
  input {
    width: 80px;
    height: 24px;
    color: ${colorObj.dark};
    border: 1px solid ${colorCommon.borderGray};
    border-radius: 2px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 4px;
    margin-right: 4px;

    &:focus {
      border-color: ${colorObj.primary};
      outline: none;
    }
  }
`

/**
 * 配合的分頁元件，提供輸入框讓使用者可以直接輸入頁碼
 * @param {function} onPageChange - 切換分頁時的 callback
 * @param {number} page - 目前所在的分頁
 * @param {number} sizePerPage - 每頁顯示幾筆資料
 * @param {number} totalSize - 總共有幾筆資料
 */
function PaginationInput({ onPageChange, page, sizePerPage, totalSize }) {
  const [specificPage, setSpecificPage] = useState('')

  const isDataExist = totalSize > 0

  const handleInput = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '')
    const numberInput = Number(inputValue)

    const totalPage = Math.ceil(totalSize / sizePerPage)
    if (numberInput === 0) {
      setSpecificPage('')
    } else if (numberInput > totalPage) {
      setSpecificPage(totalPage)
    } else {
      setSpecificPage(numberInput)
    }
  }

  const handleChangePage = () => {
    const targetPage = Number(specificPage)
    if (!targetPage) return
    if (targetPage === page) return
    onPageChange(targetPage)
    setSpecificPage('')
  }

  // 只要外部資料有任何變化，都清空輸入框
  useEffect(() => {
    setSpecificPage('')
  }, [page, sizePerPage, totalSize])

  return (
    isDataExist && (
      <PaginationInputSection className="d-flex align-items-center ml-4">
        <span>第</span>
        <input
          type="text"
          value={specificPage}
          onInput={handleInput}
          onBlur={handleChangePage}
          onKeyPress={(e) => handleKeyPressEnter(e, handleChangePage)}
        />
        <span>頁</span>
      </PaginationInputSection>
    )
  )
}

PaginationInput.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
}

export default PaginationInput
