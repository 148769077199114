import styled, { css } from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import { getRandomId } from 'helpers/common'
import { colorObj, colorCommon, method } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const { colorHandle } = method

// Input共幾種尺寸
const inputSize = {
  lg: {
    height: '40px',
  },
  md: {
    height: '32px',
  },
  sm: {
    height: '24px',
  },
}

// Input size樣式處理
const handleSizeStyles = ({ size }) => {
  size = size || 'md'
  let cssStyle = ''
  for (const type in inputSize) {
    const { height } = inputSize[type]
    if (size === type) {
      cssStyle += `
          input{
              &.form-control{
                  height:${height};
              }
          }
          .input-group-prepend,
          .input-group-append{
              height:${height};
          }
      `
    }
  }
  return css`
    ${cssStyle}
  `
}

// Input append、prepend 樣式處理
const handlePrependAppendContentStyles = ({
  isFocusCocatAppend,
  prependContentColor,
  prependContentBgColor,
  prependContentPadding,
  appendContentColor,
  appendContentBgColor,
  appendContentPadding,
}) => {
  let cssStyle = ''
  if (isFocusCocatAppend) {
    cssStyle += `
            input{
              &:not([type=radio],[type=checkbox]){
                &:disabled{
                  border-color:${colorCommon.disabledBorder} !important;
                  
                  +.input-group-append{                            
                    >.input-group-text{
                      border-color:${colorCommon.disabledBorder} !important;
                    }
                  }
                }
                &:focus{
                  +.input-group-append{                            
                    >.input-group-text{
                        border-width:1px 1px 1px 0;
                        border-style:solid;
                        border-color:${colorObj.primary};
                    }
                  }
                }
              }
            }
            .is-valid{
                +.input-group-append{                            
                  >.input-group-text{
                      border-color:${colorObj.success};
                  }
                }
            }
            .is-invalid{
                padding-right:${spacingObj.mini};   
                
                +.input-group-append{                            
                  >.input-group-text{
                    border-color:${colorObj.danger} !important;
                  }
                }
            }
        `
  }
  cssStyle += `
        .input-group-prepend,
        .input-group-append{
            >.input-group-text{
                transition:all 0.3s;
                font-size:15px;
                border-radius:2px;
            }
        }
        .input-group-prepend{
            + .form-control {
              border-color: ${colorCommon.borderGray} !important;
            }
            >.input-group-text{
                color:${prependContentColor || 'inherit'};
                background-color:${prependContentBgColor || ''};
                padding:${prependContentPadding || ''};
            }
        }
        .input-group-append{
            >.input-group-text{
                color:${appendContentColor || 'inherit'};
                background-color:${appendContentBgColor || ''};
                padding:${appendContentPadding || ''};
            }
        }
    `
  return css`
    ${cssStyle}
  `
}
// Input 共用樣式
const inputCommonStyles = ({
  colorType,
  colorAlpha,
  prependContent,
  prependContentHaveBorder = true,
  appendContent,
  appendContentHaveBorder = true,
}) => {
  let cssStyle = ''
  cssStyle += `
        input,
        textarea{
          &:not([type=radio],[type=checkbox]){
            color:${
              colorType
                ? colorHandle(colorType, colorObj, colorAlpha) ?? '#333333'
                : '#333333'
            };
            border-color:${colorCommon.borderGray};
            border-left:${prependContentHaveBorder ? '' : 0};
            border-right:${appendContentHaveBorder ? '' : 0};
            border-top-right-radius:${appendContent ? '0' : '2px'} !important;
            border-bottom-right-radius:${
              appendContent ? '0' : '2px'
            } !important;
            border-top-left-radius:${prependContent ? '0' : '2px'} !important;
            border-bottom-left-radius:${
              prependContent ? '0' : '2px'
            } !important;

            &:focus{
              border-color:${colorObj.primary};
            }
            &.is-invalid{
              border-color: ${colorObj.danger} !important;

              ~.invalid-feedback{
                  color: ${colorObj.danger};
              }
            }
            &.is-valid{
              border-color: ${colorObj.success};

              ~.valid-feedback{
                  color: ${colorObj.success};
              }
            }
            &::placeholder{
                color:${colorHandle('dark', colorObj, 0.33)}
            }
            &:disabled{
              border-color:${colorCommon.disabledBorder};
              background-color:${colorObj.white};

              +.input-group-append{
                >.input-group-text{
                    border-color:${colorCommon.disabledBorder};
                }
              }
            }
            +.input-group-append{
              >.input-group-text{
                border-left:${appendContentHaveBorder ? '' : 0};
              }
            }
          }
        }
        .input-group-prepend,
        .input-group-append{
            height:32px;
        }
        .search-result-container{
          position:absolute;
          width: 100%;
          min-width: auto;
          border-color: #d4d4d4;
          border-radius: 8px;
          box-shadow: 1px 2px 8px 0px rgb(51 51 51 / 25%);
          max-height: 300px;
          overflow: auto;
          background-color:${colorObj.white};
          border:1px solid rgba(0,0,0,.15);
          z-index:1;

          &.has-clear-option-item{
            min-width: max-content;
            right: 0;
          }
          >li{
            min-height:36px;

            &.select-menu-item-clear{
              color:${colorCommon.borderGray};
            }
            &:hover{
              cursor:pointer;
              background-color:rgba(99,153,209,0.2);
            }
          }
        }
    `
  return css`
    ${cssStyle}
  `
}
// Input 基礎樣式
const inputBaseStyles = (props) => {
  return css`
    ${inputCommonStyles(props)};
    ${handleSizeStyles(props)};
    ${handlePrependAppendContentStyles(props)};
  `
}

function InputContainer({ className, children }) {
  return <div className={className}>{children}</div>
}

const StyleInputContainer = styled(InputContainer)`
  ${(props) => inputBaseStyles(props)};
`

function Input(props) {
  let {
    colorType, // eslint-disable-line no-unused-vars
    colorAlpha, // eslint-disable-line no-unused-vars
    feedbackText,
    hideFeedbackText,
    feedbackTooltip,
    prependContent,
    prependContentColor, // eslint-disable-line no-unused-vars
    prependContentBgcolor, // eslint-disable-line no-unused-vars
    prependContentPadding, // eslint-disable-line no-unused-vars
    appendContent,
    isFocusCocatAppend, // eslint-disable-line no-unused-vars
    appendContentColor, // eslint-disable-line no-unused-vars
    appendContentBgColor, // eslint-disable-line no-unused-vars
    appendContentPadding, // eslint-disable-line no-unused-vars
    appendContentHaveBorder, // eslint-disable-line no-unused-vars
    prependContentBgColor, // eslint-disable-line no-unused-vars
    prependContentHaveBorder, // eslint-disable-line no-unused-vars
    formControlOption,
    searchFeature,
    clearOptionItem,
    children, // eslint-disable-line no-unused-vars
    helper,
    ...prop
  } = props
  // 事件
  const {
    prependContentOnClick,
    appendContentOnClick,
    onClick,
    ...withoutEventProp
  } = prop

  prop = {
    ...withoutEventProp,
  }
  const originSearchFeatureWithIdRef = useRef()
  const [searchFeatureOption, setSearchFeatureOption] = useState(() => {
    const searchFeatureData = []
    if (Array.isArray(searchFeature?.data)) {
      for (const item of searchFeature.data) {
        searchFeatureData.push({
          ...item,
          id: getRandomId(),
        })
      }
    }
    return {
      display: false,
      data: searchFeatureData,
    }
  })

  const searchFeatureSelectedValue = useRef('')
  // 搜尋功能下拉選單的過濾
  const handleSearchFeatureFilter = (e) => {
    const { value } = e.target
    if (value || value === 0) {
      searchFeature?.setSearchValue(value)
      const filterData = originSearchFeatureWithIdRef.current.data.filter(
        (item) => String(item.text).includes(value)
      )
      setSearchFeatureOption((prevState) => {
        return {
          ...prevState,
          data: filterData,
        }
      })
    } else {
      setSearchFeatureOption((prevState) => {
        return {
          ...prevState,
          data: originSearchFeatureWithIdRef.current.data,
        }
      })
    }
  }
  // 搜尋功能的下拉選單，顯示或隱藏
  const handleSearchFeatureDisplay = (display) => {
    setSearchFeatureOption((prevState) => {
      return {
        ...prevState,
        display,
      }
    })
  }
  // 搜尋功能的下拉選單，選擇事件
  const handleSearchFeatureOnSelect = (e) => {
    // 點選"請選擇"
    if (e.target?.classList.contains('select-menu-item-clear')) {
      searchFeatureSelectedValue.current = ''
      searchFeature?.setSearchValue?.(searchFeatureSelectedValue.current)
    } else {
      const value = e.target.textContent
      if (typeof searchFeature?.onSelect === 'function') {
        searchFeature.onSelect(e, value, formControlOption.name)
        handleSearchFeatureDisplay(false)
        searchFeatureSelectedValue.current = value
      }
    }
  }
  // 欄位onChange
  const handleOnChange = (e) => {
    if (typeof formControlOption?.onChange === 'function') {
      formControlOption?.onChange(e)
    }
    if (searchFeature?.isOpen) {
      handleSearchFeatureFilter(e)
    }
  }
  // 欄位onFocus
  const handleOnFocus = (e) => {
    if (typeof formControlOption?.onFocus === 'function') {
      formControlOption?.onFocus(e)
    }
    if (searchFeature?.constructor === Object && !searchFeatureOption.display) {
      // 清空輸入框的值
      e.target.value = ''
      // 重置下拉選單的選項
      setSearchFeatureOption((prevState) => {
        return {
          ...prevState,
          data: originSearchFeatureWithIdRef.current.data,
        }
      })
      handleSearchFeatureDisplay(true)
      handleSearchFeatureFilter(e)
    }
  }
  // 欄位onBlur
  const handleOnBlur = (e) => {
    if (typeof formControlOption?.onBlur === 'function') {
      formControlOption?.onBlur(e)
    }
    if (searchFeature?.constructor === Object) {
      handleSearchFeatureDisplay(false)

      const { value } = e.target
      if (value === '') {
        searchFeatureSelectedValue.current = ''
      } else if (
        (Array.isArray(searchFeatureOption.data) &&
          searchFeatureOption.data.length < 1) ||
        searchFeatureSelectedValue.current === ''
      ) {
        searchFeature?.setSearchValue?.('')
      }
      if (searchFeatureSelectedValue.current) {
        searchFeature?.setSearchValue?.(searchFeatureSelectedValue.current)
      }
    }
  }
  useEffect(() => {
    if (!originSearchFeatureWithIdRef.current) {
      originSearchFeatureWithIdRef.current = { ...searchFeatureOption }
    }
  }, [searchFeatureOption])

  return (
    <div className="style-input-container position-relative" {...prop}>
      <InputGroup>
        {prependContent && (
          <InputGroup.Prepend onClick={prependContentOnClick}>
            <InputGroup.Text
              style={
                formControlOption?.isInvalid
                  ? { borderColor: colorObj.danger }
                  : {}
              }
            >
              {prependContent}
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <FormControl
          {...formControlOption}
          autoComplete={
            searchFeature?.isOpen ? 'nope' : formControlOption?.autoComplete
          }
          onClick={onClick}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          tabIndex="-1"
        />
        {appendContent && (
          <InputGroup.Append onClick={appendContentOnClick}>
            <InputGroup.Text>{appendContent}</InputGroup.Text>
          </InputGroup.Append>
        )}
        {!hideFeedbackText && (
          <FormControl.Feedback
            type="invalid"
            className="px-2"
            tooltip={feedbackTooltip || false}
          >
            {feedbackText}
          </FormControl.Feedback>
        )}
        {!hideFeedbackText && (
          <FormControl.Feedback
            type="valid"
            className="px-2"
            tooltip={feedbackTooltip || false}
          >
            {feedbackText}
          </FormControl.Feedback>
        )}
      </InputGroup>
      {/* 搜尋功能 */}
      {searchFeature?.isOpen &&
        searchFeatureOption?.data &&
        searchFeatureOption?.display && (
          <ul
            className={`search-result-container py-2 ${
              clearOptionItem?.isOpen ? 'has-clear-option-item' : ''
            }`}
          >
            {clearOptionItem?.isOpen && (
              <li
                className="select-menu-item-clear px-3 d-flex align-items-center"
                onMouseDown={handleSearchFeatureOnSelect}
              >
                {clearOptionItem?.text ?? ' 請選擇 '}
              </li>
            )}
            {searchFeatureOption.data.map((item, index) => {
              const { text, value, id } = item
              return (
                <li
                  className="px-3 d-flex align-items-center"
                  key={`search-data-item-${id ?? index}`}
                  data-value={value}
                  data-value-type={typeof value}
                  // 這邊不用onclick的原因是因為，onclick會晚於onblur，造成觸發不到
                  onMouseDown={handleSearchFeatureOnSelect}
                >
                  {text}
                </li>
              )
            })}
          </ul>
        )}
    </div>
  )
}

// 灰字提示
const HelperText = styled.div`
  margin-top: 3px;
  padding: 0 8px;
  font-size: 13px;
  color: ${colorObj.gray};
  white-space: nowrap;
`

function StyleInput(props) {
  const { helpertext } = props
  return (
    <StyleInputContainer {...props}>
      <Input {...props} />
      {helpertext && <HelperText>{helpertext}</HelperText>}
    </StyleInputContainer>
  )
}

export { StyleInput }
