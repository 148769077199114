import { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import iconModalClose from 'assets/images/modal/icon_modalClose.svg'
import { color, colorObj } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'
import { Button } from 'components/units'
import { Loader } from 'components/units'

const Wrapper = styled(Modal)`
  font-size: 20px;
  .modal-dialog {
    max-width: ${(props) => (props.maxwidth ? props.maxwidth : '600px')};
    min-width: 320px;
    width: max-content;

    .modal-content {
      padding: ${spacingObj.large};

      .modal-header {
        display: flex;
        margin: 0;
        .modal-title-group {
          display: flex;
          align-items: center;
          flex-direction: column;
          flex-grow: 1;
          .subtitle {
            margin: 27px 0 0 0;
            font-size: 18px;
          }
        }

        img {
          cursor: pointer;
        }
        .modal-title {
          color: ${colorObj.dark};
          font-size: 24px;
        }
      }

      .modal-body {
        margin: ${spacingObj.medium} 0 0 0;

        p {
          color: ${colorObj.dark};
          font-size: 18px;
          margin: 0;
        }
      }

      .modal-footer {
        margin: ${spacingObj.medium} 0 0 0;

        button {
          font-size: 14px;
        }
      }
      .transparentMask {
        background: rgba(0, 0, 0, 0);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 21;
      }
    }
  }
`

const renderCustomEl = (customEl) => {
  if (typeof customEl === 'function') return customEl()
  return customEl
}

function BasicModal({
  show = true,
  defaultShow = false,
  variant = color.primary,
  customConfirm = null,
  onCancel,
  onClose,
  onConfirm,
  children,
  titleText = '',
  subTitleText = '',
  closeBtnText = '取消',
  closeBtnVisible = true,
  confirmBtnText = '確認',
  confirmBtnVisible = true,
  isHideCloseButton = false,
  isConfirmDisabled = false,
  modalOption = {}, // react-bootstrap Modal元件的屬性設定
  className = '',
  isShowDefaultMask = true, // 如需用自訂mask，可傳入false
  isLoading: isBtnLoading, // 用於控制確認按鈕 loading 及全螢幕完全透明防呆 mask 的狀態
  isDefaultDataLoading, // 用於覆蓋整個 modal 的半透明 loading 狀態，並會顯示轉圈 loader
}) {
  const [status, setStatus] = useState(defaultShow)
  const [isModalLoading, setIsModalLoading] = useState(isBtnLoading ?? false)

  const handleModalLoading = (bool) => {
    setIsModalLoading(bool)
  }
  const handleClose = () => {
    if (typeof onClose === 'function') return onClose(handleModalLoading)
    return setStatus(false)
  }

  /**
   * 關閉按鈕
   * 有 onCancel 則執行 onCancel
   * 否則有 onClose 則執行 onClose
   * 否則就單純關閉 Modal
   */
  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      return onCancel()
    } else if (typeof onClose === 'function') {
      return onClose(handleModalLoading)
    } else {
      return setStatus(false)
    }
  }
  const handleConfirm = () => {
    if (typeof onConfirm === 'function')
      return onConfirm({ handleModalLoading })
    return setStatus(false)
  }
  useEffect(() => {
    setStatus(show)
    if (!show) setIsModalLoading(false)
  }, [show])

  useEffect(() => {
    setIsModalLoading(isBtnLoading)
  }, [isBtnLoading])
  return (
    <Wrapper
      centered
      show={status}
      onHide={handleClose}
      className={className}
      {...modalOption}
      backdropClassName={isShowDefaultMask ? '' : 'modal-backdrop-custom'}
    >
      <Loader isHide={!isDefaultDataLoading} />
      {isModalLoading && <div className="transparentMask" />}
      <Modal.Header>
        <div className="modal-title-group">
          <Modal.Title>{titleText}</Modal.Title>
          {subTitleText && <p className="subtitle">{subTitleText}</p>}
        </div>
        {!isHideCloseButton && (
          <div>
            <img src={iconModalClose} alt="close" onClick={handleClose} />
          </div>
        )}
      </Modal.Header>
      <Modal.Body className="modal-confirm-body">{children}</Modal.Body>
      <Modal.Footer>
        {closeBtnVisible && (
          <Button variant="outline-darkerGray" onClick={handleCancel}>
            {closeBtnText}
          </Button>
        )}
        {confirmBtnVisible &&
          (renderCustomEl(customConfirm) || (
            <Button
              variant={variant}
              onClick={handleConfirm}
              isLoading={isModalLoading}
              disabled={isConfirmDisabled}
              loaderOption={{
                bgColor: 'transparent',
                colorType: '#fff',
              }}
            >
              {confirmBtnText}
            </Button>
          ))}
      </Modal.Footer>
    </Wrapper>
  )
}

export default BasicModal
