import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { getQrcodeGeneratedCodeDetail } from 'api/ApiMain'
import { QRCODE_SERIAL_NUMBER_DETAIL } from 'store/constants'
import { memberOutlineSelector } from 'store/member-outline/memberOutlineSlice'

export const qrcodeSerialNumberDetailInitState = {
  generatedCodeRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      qrCodeGeneratedCodeId: 0,
      exchangedMemberId: '',
      exchangedMemberName: '',
      exchangedMemberCellPhone: '',
      exchangedMemberLevel: '',
    },
  },
}

const getQrcodeGeneratedCodeDetailThunk = createAsyncThunk(
  `${QRCODE_SERIAL_NUMBER_DETAIL}/getQrcodeGeneratedCodeDetail`,
  async (
    { brandId, qrcodeActivitySettingId, qrCodeGeneratedCodeId },
    { rejectWithValue }
  ) => {
    try {
      const rsp = await getQrcodeGeneratedCodeDetail({
        brandId,
        qrcodeActivitySettingId,
        qrCodeGeneratedCodeId,
      })
      return rsp
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)
const qrcodeSerialNumberDetailSlice = createSlice({
  name: QRCODE_SERIAL_NUMBER_DETAIL,
  initialState: qrcodeSerialNumberDetailInitState,
  reducers: {
    resetDetailModal: () => qrcodeSerialNumberDetailInitState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === getQrcodeGeneratedCodeDetailThunk.fulfilled.type ||
        action.type === getQrcodeGeneratedCodeDetailThunk.rejected.type,
      (state, action) => {
        state.generatedCodeRsp = { ...action.payload, isFetch: true }
      }
    )
  },
})

const qrcodeSerialNumberDetailSelector = createSelector(
  (state) => state[QRCODE_SERIAL_NUMBER_DETAIL].generatedCodeRsp,
  memberOutlineSelector,
  (generatedCodeRsp, memberOutline) => {
    const { data, isFetch: generatedCodeFetch } = generatedCodeRsp
    const {
      memberOutlineFetch,
      age,
      yearMonth,
      gender,
      pointBalance,
      customerUnitPrice,
      lastDealDate,
      registeredDate,
      shopName,
      customerId,
      isMemberExist,
    } = memberOutline

    const {
      exchangedMemberId,
      exchangedMemberName,
      exchangedMemberCellPhone,
      exchangedMemberLevel,
    } = data
    const isReady = generatedCodeFetch && memberOutlineFetch
    return {
      isReady,
      exchangedMemberId,
      exchangedMemberName,
      exchangedMemberCellPhone,
      exchangedMemberLevel,
      customerId,
      age,
      yearMonth,
      gender,
      pointBalance,
      customerUnitPrice,
      lastDealDate,
      registeredDate,
      shopName,
      isMemberExist,
    }
  }
)

export { getQrcodeGeneratedCodeDetailThunk, qrcodeSerialNumberDetailSelector }

export const { resetDetailModal } = qrcodeSerialNumberDetailSlice.actions

export default qrcodeSerialNumberDetailSlice.reducer
