import isDate from 'date-fns/isDate'
import isValid from 'date-fns/isValid'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

// 值為null時，要顯示的替換文字 (日期功能暫不替換)
const nullDisplayString = '-'

const date = (dateTime) => {
  if (dateTime) {
    if (isValid(parseISO(dateTime))) {
      return format(new Date(parseISO(dateTime)), 'yyyy/MM/dd')
    }
    if (isDate(dateTime)) {
      return format(new Date(dateTime), 'yyyy/MM/dd')
    }
  }
}

const dateTime = (dateTime) => {
  if (dateTime) {
    if (
      isValid(parseISO(dateTime)) ||
      isValid(parseISO(dateTime) !== 'Invalid Date')
    ) {
      return format(new Date(parseISO(dateTime)), 'yyyy/MM/dd HH:mm')
    }
    if (isDate(dateTime)) {
      return format(new Date(dateTime), 'yyyy/MM/dd HH:mm')
    }
  }
}

const number = (number, ...props) => {
  return number === null
    ? nullDisplayString
    : new Intl.NumberFormat('zh-TW', ...props).format(number)
}

const currency = (number) => {
  return number === null
    ? nullDisplayString
    : new Intl.NumberFormat('zh-TW', {
        style: 'currency',
        currency: 'TWD',
        minimumFractionDigits: 0,
      }).format(number)
}

export { date, dateTime, number, currency }
