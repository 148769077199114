import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'

const PointHistoryListStyle = styled.div`
  .table-toolbar {
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
    color: ${colorObj.darkGray};
  }

  .total-size {
    color: ${colorObj.darkerGray};
  }

  .table .paragraph {
    font-size: 13px;

    tr {
      cursor: pointer;
    }
  }
`

export default PointHistoryListStyle
