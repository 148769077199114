import { Suspense, useContext, useRef, useState } from 'react'
import { Loader } from 'components/units'
import Tab from 'react-bootstrap/Tab'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { ContentSection } from 'components/templates'
import { RootContext } from 'RootContext'
import {
  searchSectionSetting,
  tabSectionSetting,
} from './ActivityFormRecordListConfig'
import { TabSectionSub } from './Sub'
import { commonSetting } from '../ActivityFormRecordConfig'
import { TabsWrapper } from './ActivityFormRecordListStyle'
import { ActivityFormRecordListContext } from 'context/pageContext'
import { TabSectionContext } from 'context/pageContext'
import { SearchSection as SearchSectionWidget } from 'components/widgets'
import {
  ActivityFormRecordSearchConfig,
  ActivityFormRecordSearchInitError,
  ActivityFormRecordSearchInitState,
} from 'helpers/searchFieldFormat'

/* -------------------------------- 
  SearchSection 區塊
-----------------------------------*/
const { defaultSort } = searchSectionSetting

function SearchSection() {
  const { brandId } = useContext(RootContext)
  const { setApiPayload, apiPayload } = useContext(
    ActivityFormRecordListContext
  )

  // 清空表單欄位
  const handleResetRorm = () => {
    const { ps, so, sf } = apiPayload
    setApiPayload({
      ...defaultSort,
      brandId,
      p: 1,
      ps: ps || 25,
      so: so || defaultSort.so,
      sf: sf || defaultSort.sf,
    })
  }
  // 查詢表單
  const handleSearch = (formValues) => {
    // 轉換為後端格式
    const { itemName, ...rest } = formValues
    const formValuesApi = {
      ...rest,
      name: itemName,
    }
    setApiPayload({
      ...formValuesApi,
      brandId,
      p: 1,
      ps: apiPayload.ps || 25,
      so: apiPayload.so || defaultSort.so,
      sf: apiPayload.sf || defaultSort.sf,
    })
  }

  return (
    <SearchSectionWidget
      fields={ActivityFormRecordSearchConfig}
      initErrorMsg={ActivityFormRecordSearchInitError}
      initState={ActivityFormRecordSearchInitState}
      onSubmit={handleSearch}
      onReset={handleResetRorm}
    />
  )
}

/* -------------------------------- 
  TabSection 區塊
-----------------------------------*/

const { tabOption } = tabSectionSetting
// tab頁籤設定
const tabConfig = [
  {
    eventKey: tabOption.getPointFail.eventKey,
    title: tabOption.getPointFail.title,
    component: (
      <Suspense fallback={<Loader />}>
        <TabSectionSub.GetPointFail />
      </Suspense>
    ),
  },
  {
    eventKey: tabOption.getPointSuccess.eventKey,
    title: tabOption.getPointSuccess.title,
    component: (
      <Suspense fallback={<Loader />}>
        <TabSectionSub.GetPointSuccess />
      </Suspense>
    ),
  },
]

function TabSection() {
  const { paginationOptions } = commonSetting
  const activityFormRecordListContext = useContext(
    ActivityFormRecordListContext
  )
  const { totalSize, apiPayload, setApiPayload, setTableRow } =
    activityFormRecordListContext

  const [tabEventKey, setTabEventKey] = useState(
    tabOption.getPointFail.eventKey
  )

  const handleTabChange = (eventKey) => {
    if (tabEventKey !== eventKey) {
      setTabEventKey(eventKey)
      setApiPayload({
        ps: 25,
        p: 1,
        ...defaultSort,
        customId: apiPayload.customId,
        startDate: apiPayload.startDate,
        endDate: apiPayload.endDate,
        name: apiPayload.name,
      })
      setTableRow([])
    }
  }

  return (
    <PaginationProvider
      pagination={paginationFactory({
        ...paginationOptions,
        onPageChange: (page) =>
          setApiPayload((prev) => ({
            ...prev,
            p: page,
          })),
        onSizePerPageChange: (sizePerPage, page) =>
          setApiPayload((prev) => ({
            ...prev,
            ps: sizePerPage,
            p: page || 1,
          })),
        totalSize,
        page: apiPayload.p,
        sizePerPage: apiPayload.ps,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <TabSectionContext.Provider
          value={{
            paginationProps,
            paginationTableProps,
          }}
        >
          <ContentSection>
            <TabsWrapper
              defaultActiveKey={tabOption.getPointFail.eventKey}
              onSelect={handleTabChange}
            >
              {tabConfig.map(({ eventKey, title, component: TabContent }) => {
                return (
                  <Tab key={eventKey} eventKey={eventKey} title={title}>
                    {tabEventKey === eventKey && TabContent}
                  </Tab>
                )
              })}
            </TabsWrapper>
          </ContentSection>
        </TabSectionContext.Provider>
      )}
    </PaginationProvider>
  )
}

export { SearchSection, TabSection }
export { TabSectionContext }
