import ExchangeContent from 'components/pages/Gift/ExchangeContent'
import ExchangeMain from 'components/pages/Gift/ExchangeMain'
import ExchangeSendList from 'components/pages/Gift/ExchangeSendList'
import SerialNumberTab from 'components/pages/Gift/ExchangeSerialNumber'
import ExclusiveContent from 'components/pages/Gift/ExclusiveContent'
import ExclusiveMain from 'components/pages/Gift/ExclusiveMain'
import ExclusiveSendList from 'components/pages/Gift/ExclusiveSendList'
import ExclusiveSerialNumber from 'components/pages/Gift/ExclusiveSerialNumber'

const ExclusivePage = {
  ExclusiveMain,
  ExclusiveContent,
  ExclusiveSerialNumber,
  ExclusiveSendList,
}
const ExchangePage = {
  ExchangeMain,
  ExchangeContent,
  SerialNumberTab,
  ExchangeSendList,
}

export { ExclusivePage, ExchangePage }
