import { useState } from 'react'

/**
 * @param {Object[]} list - 資料列表
 * @param {number} selectedLimit - 選取上限
 * @returns {Object} - 回傳選取相關的資訊
 * @returns {string[]} selectedKeys - 選取的 key
 * @returns {boolean} isSelectedFull - 是否選取達上限
 * @returns {boolean} isSelectedEmpty - 是否選取為空
 * @returns {string[]} nonSelectableKeys - 選取達上限後，剩餘不能選取的 key
 * @returns {Function} handleSelectSingle - checkbox 單一選取
 * @returns {Function} handleSelectAll - checkbox 總選取
 */
const useSelectedRows = ({ list, selectedLimit }) => {
  /**
   * checkbox 中的 key 使用 id 或 giftRecordObjId 等唯一值
   * 確保不重複的同時，可直接使用
   */
  const [selectedKeys, setSelectedKeys] = useState([])
  const isSelectedFull = selectedKeys.length >= selectedLimit
  const isSelectedEmpty = selectedKeys.length === 0

  /* table 中，選取達到上限後，剩餘不能選取的 key */
  const nonSelectedKeys = list
    .filter((item) => !selectedKeys.includes(item.giftRecordObjId))
    .map((item) => item.key)
  const nonSelectableKeys = isSelectedFull ? nonSelectedKeys : []

  /* checkbox 單一選取，使用在 table 元件中 */
  const handleSelectSingle = (row) => {
    const { giftRecordObjId } = row
    const isIdExisted = selectedKeys.some((item) => item === giftRecordObjId)
    const updateRows = isIdExisted
      ? selectedKeys.filter((item) => item !== giftRecordObjId)
      : [...selectedKeys, giftRecordObjId]
    setSelectedKeys(updateRows)
  }

  /**
   * checkbox 總選取，使用在 table 元件中，上限自訂
   * 在沒選滿的情況下跳頁，再點選全選時，會依剩餘空間納入第二頁的選取
   */
  const handleSelectAll = (isSelected) => {
    const selectIds = list.map((item) => item.giftRecordObjId)
    const filterIds = [...selectedKeys, ...selectIds].filter((_, index) => {
      return index < selectedLimit
    })

    // 如果「未達上限」且「點擊全選」，將 filterIds 加入選取，否則清空
    let updateRows = []
    if (!isSelectedFull && isSelected) updateRows = filterIds
    setSelectedKeys(updateRows)
  }

  /* 清除所有選取項目 */
  const clearSelectedKeys = () => setSelectedKeys([])

  return {
    selectedKeys,
    isSelectedFull,
    isSelectedEmpty,
    nonSelectableKeys,
    handleSelectSingle,
    handleSelectAll,
    clearSelectedKeys,
  }
}

export default useSelectedRows
