import styled from 'styled-components'
import BootstrapTable from 'react-bootstrap-table-next'
import { spacingObj } from 'assets/styles/Variable/Spacing'
import { colorObj, method } from 'assets/styles/Variable/Color'
import { Col, Row, Spinner } from 'react-bootstrap'
import { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import { Checkbox } from 'components/units'

const { colorHandle } = method

const svgStrokeColor = encodeURIComponent(colorObj.primary)

function TableContainer({ className, children }) {
  return <div className={className}>{children}</div>
}
const StyleBootstrapTable = styled(TableContainer)`
  .sortable {
    .caret {
      transform: translateY(-25%);
    }
    > .order {
      position: relative;
      display: inline-block;

      > .dropup {
        position: absolute;
        left: 0;
        top: -3px;
      }
      > .dropdown {
        bottom: -3px;
      }
    }
  }
  .table {
    &.table-striped {
      th,
      td {
        /* border reset */
        border: none;
      }
      tbody {
        tr {
          :nth-of-type(odd) {
            background-color: #ffffff;
          }
          :nth-of-type(even) {
            background-color: rgba(51, 51, 51, 0.05);
          }
          :hover {
            background-color: rgba(99, 153, 209, 0.1) !important;
          }
        }
        td {
          border-left: 1px solid rgba(222, 226, 230, 1);
          border-bottom: 1px solid rgba(222, 226, 230, 1);
        }
        td:last-child {
          border-right: 1px solid rgba(222, 226, 230, 1);
        }
      }
      .dropup .caret {
        border-bottom: 6px dashed;
        border-top: 0px;
      }

      .order .dropdown .caret,
      .order .dropup .caret {
        color: #cccccc;
      }
    }
    .selection-cell-header {
      input[type='checkbox'] {
        &:indeterminate {
          width: 16px;
          height: 16px;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -8.5px;
          margin-left: -9px;

          &::before {
            z-index: -1;
          }
        }
      }
    }
    .selection-cell-header,
    .selection-cell {
      position: relative;
      text-align: center;

      input[type='checkbox'] {
        position: relative;
        top: 1px;

        &::before {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          background-color: #fff;
          position: absolute;
          border-width: 1px;
          border-style: solid;
          border-radius: 2px;
          background-repeat: no-repeat;
          background-size: 130%;
          background-position: center;
          left: calc(50% - 1px);
          top: 50%;
          transform: translate(-50%, -50%);
          pointer-events: none;
          border-color: ${colorObj.dark};
        }

        &:checked {
          &::before {
            background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='${svgStrokeColor}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
            border-color: ${colorObj.primary};
          }
        }
        &:disabled {
          opacity: 0.5;

          &::before {
            border-color: ${colorHandle('dark', colorObj, 0.5)};
          }
        }
      }
    }
    .table-header {
      background-color: ${colorObj.secondary};
      color: #ffffff;
      font-size: 15px;
      font-weight: normal;
      white-space: nowrap;

      th {
        border-left: 1px solid ${colorObj.secondary};
        border-right: 1px solid ${colorObj.secondary};
        &.sortable {
          cursor: pointer;

          .caret {
            margin-left: 8px;
            border-width: 4px;
          }
        }
      }
    }
    th {
      .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        border-top: 6px dashed;
      }
    }
    .icon-feature-container {
      gap: ${spacingObj.small};
    }
    .link-container {
      color: ${colorObj.primary};
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .table-bordered {
    border-top: none;
  }
`
function TableFormat(props) {
  return (
    <div
      className={`style-table-container ${props.className || ''}`}
      data-testid="style-table-container"
    >
      <BootstrapTable {...props} />
    </div>
  )
}
function StyleTable(props) {
  return (
    <StyleBootstrapTable>
      <TableFormat {...props} />
    </StyleBootstrapTable>
  )
}

// 過渡期，新增isLoading，但為了不改動目前的呼叫方式，所以寫法較複雜。
// 未來可調整方向：
// isLoading 為true時，顯示預設loading動畫，false時預設'尚無資料'
// message 若有傳入loading的文字取代預設loading動畫
// 待刪除props.children，一律改用 message 傳入 loading的文字

function NoDataIndication({ message, isLoading = false, ...props }) {
  return (
    <div className="text-secondray text-center py-5">
      {isLoading
        ? message && <Spinner animation="border" />
        : message || props.children}
    </div>
  )
}

function TableToolBar({
  totalSize = 0,
  paginationProps = {},
  sizePerPage = 0,
  sortByCkeckBoxSetting = {
    name: 'sortByCkeckBox',
    id: 'sortByCkeckBox',
    label: '',
    isShow: false,
    onChange: null,
    isChecked: false,
  },
}) {
  const { name, id, label, isShow, isChecked, onChange } = sortByCkeckBoxSetting
  return (
    <Row className="caption pb-3 my-2">
      <Col className="label-title d-flex">
        <div className="margin-t-1">
          總項目：<span className="paragraph-info">{totalSize || 0} 筆</span>
        </div>
        {isShow && (
          <Checkbox
            className="m-0 ml-3 font-dark"
            name={name}
            id={id}
            label={label}
            checked={isChecked}
            disabled={isChecked}
            inline
            onChange={onChange}
          />
        )}
      </Col>
      <Col className="label-title text-right">
        <span className="white-space-nowrap mr-1">每頁</span>
        <SizePerPageDropdownStandalone
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...paginationProps}
          sizePerPage={`${sizePerPage} 筆`}
        />
      </Col>
    </Row>
  )
}
export { StyleTable, NoDataIndication, TableToolBar }
