import { Fragment, useEffect, useState } from 'react'
import { FormCheck } from 'react-bootstrap'
import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

const monthlyDayOption = {
  list: Array.from({ length: 32 }, (item, index) => {
    let text = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`
    let value = parseInt(text)
    const checked = false
    if (index === 31) {
      text = '最後一日'
      value = 'lastDay'
    }
    return {
      text,
      value,
      checked,
    }
  }),
}

// css樣式
const MonthlyDayStyle = styled.div`
  width: 337px;

  .form-check-inline {
    color: ${colorObj.darkerGray};
    justify-content: center;
    align-items: center;
    margin: 0;
    border-color: ${colorObj.darkerGray};
    border-width: 0 1px 1px 0;
    border-style: solid;
    width: calc(100% / 7);

    :first-of-type {
      border-radius: ${spacingObj.micro} 0 0 0;
    }
    :nth-of-type(7) {
      border-radius: 0 ${spacingObj.micro} 0 0;
    }
    :nth-of-type(29) {
      border-left-width: 1px;
      border-radius: 0 0 0 ${spacingObj.micro};
    }
    :nth-of-type(-n + 7) {
      border-top-width: 1px;
    }
    :nth-of-type(7n + 1) {
      border-left-width: 1px;
    }
    :last-of-type {
      width: calc(100% / 7 * 4);
      border-radius: 0 0 ${spacingObj.micro} 0;
    }
    > input {
      display: none;

      :checked {
        + label {
          color: ${colorObj.white} !important;
          background-color: ${colorObj.primary};
        }
      }
    }
    > label {
      width: 100%;
      cursor: pointer;
      text-align: center;
      padding: ${spacingObj.mini} 0;
      white-space: nowrap;
      color: ${colorObj.darkerGray} !important;
    }
  }
`

function MonthlyDay({ onChange, className, fieldName, value, isInit }) {
  const [list, setList] = useState(monthlyDayOption.list)
  const [isFirstLoad, setFirstLoad] = useState(true)

  const handleChange = (e, index) => {
    let obj = {}

    const newState = JSON.parse(JSON.stringify(list))
    const checkedItem = JSON.parse(JSON.stringify(list[index]))
    checkedItem.checked = e.target.checked
    newState[index] = checkedItem
    obj = {
      [fieldName]: {
        days: [],
        lastDay: false,
      },
    }
    if (fieldName) {
      for (const [index, item] of newState.entries()) {
        const { value, checked } = item
        if (checked) {
          if (index === newState.length - 1) {
            obj[fieldName].lastDay = checked
          } else {
            obj[fieldName].days.push(value)
          }
        }
      }
    }

    setList(newState)
    setFirstLoad(false)
    if (typeof onChange === 'function') {
      onChange(e, index, obj)
    }
  }

  // 處理是否reset
  useEffect(() => {
    if (isInit) {
      setList(monthlyDayOption.list)
    }
  }, [isInit])

  // 處理初次載入時，有值的情況(編輯狀態)
  useEffect(() => {
    if (isFirstLoad && value) {
      const { days, lastDay } = value
      const deepCloneList = JSON.parse(JSON.stringify(monthlyDayOption.list))
      for (const day of days) {
        for (const [index, item] of deepCloneList.entries()) {
          const { value } = item
          if (day === value) {
            deepCloneList[index].checked = true
            break
          }
        }
      }
      if (lastDay) {
        deepCloneList[deepCloneList.length - 1].checked = true
      }
      setList(deepCloneList)
    }
  }, [isFirstLoad, value])

  return (
    <MonthlyDayStyle>
      {list.map((item, index) => {
        const { name, value, text, checked, ...prop } = item
        return (
          <Fragment key={`weekly-day-${index}`}>
            <FormCheck
              {...prop}
              type="checkbox"
              className={className}
              name={name}
              value={value}
              inline
              label={text}
              // id取隨機值(主要是讓點選label也能觸發checkbox)
              id={prop?.id || Math.random().toString(36).substring(2)}
              checked={checked}
              onChange={(e) => handleChange(e, index)}
            />
          </Fragment>
        )
      })}
    </MonthlyDayStyle>
  )
}

export default MonthlyDay
