import styled, { css } from 'styled-components'
import { useEffect, useRef, useState, Fragment, useCallback } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import FormControl from 'react-bootstrap/FormControl'
import { getRandomId } from 'helpers/common'
import { colorObj, colorCommon, method } from 'assets/styles/Variable/Color'
import { spacingObj } from 'assets/styles/Variable/Spacing'

import { StyleInput } from './Input'

const { colorHandle } = method

// Select共幾種尺寸
const selectSize = {
  lg: {
    fontSize: '15px',
    height: '32px',
  },
  sm: {
    fontSize: '13px',
    height: '24px',
  },
}

// Select type樣式處理
const handleTypeStyles = ({ colorType, colorAlpha, variant }) => {
  let cssStyle = ''
  colorType = colorType ?? variant
  cssStyle += `
      .style-select-container{
        .dropdown{
          .dropdown-toggle{
            color:${
              colorType
                ? colorHandle(colorType, colorObj, colorAlpha)
                : colorObj.dark
            };
            border-color:${
              variant ? colorObj[variant] : colorCommon.borderGray
            };
            &:disabled{
              border-color:${colorCommon.disabledBorder};
            }
          }
        }
      }
    `
  return css`
    ${cssStyle}
  `
}

// Select size樣式處理
const handleSizeStyles = ({ size }) => {
  size = size || 'lg'
  let cssStyle = ''
  for (const type in selectSize) {
    const { height, fontSize } = selectSize[type]
    if (size !== type) {
      cssStyle += `
                &.form-control-${size}{
                    height:${height};
                    font-size:${fontSize};
                }
            `
    }
  }
  return css`
    ${cssStyle}
  `
}

// Select 共用樣式
const selectCommonStyles = ({ colorType, colorAlpha, size, variant }) => {
  let cssStyle = ''
  size = size || 'lg'
  cssStyle += `
      .dropdown{
        position:relative;
        display: flex;
        height:${selectSize[size].height};
        
        &.invalid{
          button{
            &.dropdown-toggle{
                border-color:${colorObj.danger};
            }
          }
        }
        
        .dropdown-toggle{
          color:${
            colorType
              ? colorHandle(colorType, colorObj, colorAlpha)
              : colorObj.dark
          };
          background-color:#fff;
          border-radius:2px;
          border-color:${colorCommon.borderGray};
          box-shadow:none !important;
          font-size:inherit;
          min-width:auto;
          position:relative;
          padding-top:0;
          padding-bottom:0;
          text-align:left;
          opacity:1;
          
          &[aria-expanded="true"]{
            border-color:${colorObj.primary || ''};
          }
          &::after{
              display: inline-block;
              position: absolute;
              top:calc(50% - 3px);
              right: 4px;
              content: "";
              border: solid ${variant ? colorObj[variant] : colorObj.dark};
              border-width: 0 2px 2px 0;
              transform: rotate(45deg) translate(-50%);
              display: inline-block;
              padding: 2.5px;
          }
          &:focus,
          &:active{
              color:${
                colorType
                  ? colorHandle(colorType, colorObj, colorAlpha)
                  : colorObj.dark
              };
              background-color:#fff;
              border-radius:2px;
          }
          &:disabled{
            border-color:${colorCommon.disabledBorder};
            background-color:${colorObj.white};
          }
          .is-placeholder{
            color:${
              variant ? colorObj[variant] : colorHandle('dark', colorObj, 0.33)
            }
          }
          >span{
            padding-right:12px;
          }
        }
        .dropdown-menu{
          width:100%;
          min-width:min-content;
          border-color:${colorCommon.borderGray};
          border-radius: 8px;
          box-shadow: 1px 2px 8px 0px rgba(51, 51, 51, 0.25);
          max-height:285px;
          overflow:auto;
          inset:2px auto auto 0px !important;

          .dropdown-header{
            padding:0 ${spacingObj.small};
          }
          .dropdown-item{
            color:${colorObj.darkerGray};
            padding:0 ${spacingObj.small};
            min-height:36px;
            display:flex;
            align-items:center;

            &:hover{
              background-color:rgba(99,153,209,0.2);
            }
            &.select-menu-item-clear{
              color:${colorCommon.borderGray};
            }
          }
          .dropdown-divider{
            width:calc(100% - (${spacingObj.small}*2));
            margin-left:auto;
            margin-right:auto;
          }
        }
        ~select{
          display:none;
        }
      }
      .style-input-container{
        .input-group{
          input{
            padding-left:12px;
            padding-right:12px;
          }
          &::after{
            display: inline-block;
            position: absolute;
            top: calc(50% - 3px);
            right: 4px;
            content: "";
            border: solid ${colorObj.dark};
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) translate(-50%);
            display: inline-block;
            padding: 2.5px;
            z-index:9;
          }
        }
      }
    `
  return css`
    ${cssStyle}
  `
}

// Select 基礎樣式
const selectBaseStyles = ({ colorType, colorAlpha, ...props }) => {
  return css`
    ${selectCommonStyles(props)};
    ${handleTypeStyles({ colorType, colorAlpha, ...props })}
    ${handleSizeStyles(props)};
  `
}

function SelectContainer({ className, children }) {
  return <div className={className}>{children}</div>
}
const StyleSelectContainer = styled(SelectContainer)`
  ${(props) => selectBaseStyles(props)};
`

function CustomSelect(props) {
  const {
    children,
    colorType, // eslint-disable-line no-unused-vars
    colorAlpha, // eslint-disable-line no-unused-vars
    selectedValue,
    isClearSelectedValue,
    clearOptionItem,
    optionItems,
    dropdownOption,
    dropdownToggleOption,
    dropdownMenuOption,
    formControlOption = {},
    feedbackText,
    hideFeedbackText,
    searchFeature,
    ...prop
  } = props

  const { value, ...inputSearchFormControlOption } = formControlOption

  // 事件
  const { onChange } = prop
  const [isPlaceholder, setIsPlaceholder] = useState(
    !!formControlOption?.placeholder
  )
  const [selectText, setSelectText] = useState(
    formControlOption?.placeholder || ''
  )
  const [selectValue, setSelectValue] = useState(selectedValue ?? '')
  const [searchValue, setSearchValue] = useState(formControlOption.value ?? '')
  const formControlRef = useRef(null)
  const dropdownOptionItemsWithIdRef = useRef()
  const selectOptionItemsWithIdRef = useRef()

  const onDropdownSelect = (eventKey, event) => {
    // 點選"請選擇"
    if (event.target?.classList.contains('select-menu-item-clear')) {
      setSelectText(formControlOption?.placeholder ?? '')
      setIsPlaceholder(true)
      setSelectValue('')

      if (typeof onChange === 'function') {
        const isTriggerClearOptionItem = true
        onChange(
          event,
          value,
          formControlRef.current,
          eventKey,
          optionItems,
          isTriggerClearOptionItem
        )
      }
    } else {
      // 由於dataset取得的值，型別一定是string，因此這邊需要區分number或string
      const dataSetValueType = event.target.dataset.valueType
      let dataSetValue = event.target.dataset.value

      if (dataSetValue) {
        dataSetValue =
          dataSetValueType === 'number' ? Number(dataSetValue) : dataSetValue
      }

      const value = dataSetValue ?? eventKey ?? event.target.textContent

      setSelectText(event.target.textContent)
      setIsPlaceholder(false)
      setSelectValue(value)

      if (typeof onChange === 'function') {
        onChange(event, value, formControlRef.current, eventKey, optionItems)
      }
    }
  }

  const handleSelectValue = useCallback(() => {
    // ※ selectedValue是prop，selectValue是state

    // 外部有設定selected的初值
    if (selectedValue || selectedValue === 0) {
      if (Array.isArray(optionItems)) {
        for (const item of optionItems) {
          const { text, value } = item
          if (value === selectedValue) {
            setSelectText(text)
            setIsPlaceholder(false)
            setSelectValue(selectedValue)
            break
          }
        }
      }
    }

    if (isClearSelectedValue) {
      setSelectText(formControlOption?.placeholder ?? '')
      setIsPlaceholder(true)
      setSelectValue('')
      setSearchValue('')
    }
  }, [
    formControlOption.placeholder,
    isClearSelectedValue,
    optionItems,
    selectedValue,
  ])

  // 搜尋功能框的onChange
  const handleSearchFeatureOnChange = (e) => {
    const { value } = e.target
    setSearchValue(value)
  }
  // 搜尋功能的下拉選單，選擇事件
  const handleSearchFeatureOnSelect = (e) => {
    // 由於dataset取得的值，型別一定是string，因此這邊需要區分number或string
    const dataSetValueType = e.target.dataset.valueType
    let dataSetValue = e.target.dataset.value

    if (dataSetValue) {
      dataSetValue =
        dataSetValueType === 'number' ? Number(dataSetValue) : dataSetValue
    }
    const value = dataSetValue ?? e.target.textContent

    if (typeof searchFeature?.onSelect === 'function') {
      searchFeature.onSelect(e, value, formControlOption.name)
      setSearchValue(value)
    }
  }

  const handleSelectKeyId = (optionItems) => {
    const arr = []
    for (const optionItem of optionItems) {
      arr.push({
        ...optionItem,
        id: getRandomId(),
      })
    }
    return arr
  }

  useEffect(() => {
    handleSelectValue()
  }, [optionItems, selectedValue, isClearSelectedValue, handleSelectValue])

  return (
    <div className="style-select-container">
      {
        // 開啟搜尋功能
        searchFeature?.isOpen ? (
          <StyleInput
            formControlOption={{
              ...inputSearchFormControlOption,
              value: searchValue,
              onChange(e) {
                handleSearchFeatureOnChange(e)
              },
            }}
            clearOptionItem={clearOptionItem}
            searchFeature={{
              ...searchFeature,
              setSearchValue,
              onSelect(e) {
                handleSearchFeatureOnSelect(e)
              },
            }}
          />
        ) : (
          // 未開啟搜尋功能(一般select)
          <>
            <Dropdown
              className={`${formControlOption?.isInvalid ? 'invalid' : ''} ${
                dropdownOption?.drop ? 'dropdown' : ''
              }`}
              onSelect={onDropdownSelect}
              {...dropdownOption}
            >
              {children || (
                <>
                  <Dropdown.Toggle {...dropdownToggleOption}>
                    <span
                      className={`${isPlaceholder ? 'is-placeholder' : ''}`}
                    >
                      {selectText}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu {...dropdownMenuOption} align="right">
                    {clearOptionItem?.isOpen && (
                      <Dropdown.Item className="select-menu-item-clear">
                        {clearOptionItem?.text ?? ' 請選擇 '}
                      </Dropdown.Item>
                    )}
                    {Array.isArray(optionItems) &&
                      optionItems.length > 0 &&
                      optionItems.map((item, i) => {
                        const {
                          text,
                          value,
                          title,
                          divider,
                          dropdownItemOption,
                        } = item

                        if (dropdownOptionItemsWithIdRef.current) {
                          if (
                            dropdownOptionItemsWithIdRef.current.length !==
                            optionItems.length
                          ) {
                            dropdownOptionItemsWithIdRef.current =
                              handleSelectKeyId(optionItems)
                          }
                        } else {
                          dropdownOptionItemsWithIdRef.current =
                            handleSelectKeyId(optionItems)
                        }

                        return (
                          <Fragment
                            key={`dropdown-items-${dropdownOptionItemsWithIdRef.current[i].id}`}
                          >
                            {title && (
                              <Dropdown.Header>{title}</Dropdown.Header>
                            )}
                            <Dropdown.Item
                              data-index={i}
                              data-value={value}
                              data-value-type={typeof value}
                              eventKey={
                                dropdownItemOption?.evenKey || `select-${i}`
                              }
                              {...dropdownItemOption}
                            >
                              {text}
                            </Dropdown.Item>
                            {divider && <Dropdown.Divider />}
                          </Fragment>
                        )
                      })}
                  </Dropdown.Menu>
                </>
              )}
            </Dropdown>
            <FormControl
              ref={formControlRef}
              as="select"
              value={selectValue}
              {...formControlOption}
              onChange={() => {}}
              data-testid={props?.testId ?? ''}
            >
              {Array.isArray(optionItems) &&
                optionItems.length > 0 &&
                optionItems.map((item, i) => {
                  const { text, value, testId } = item
                  if (selectOptionItemsWithIdRef.current) {
                    if (
                      selectOptionItemsWithIdRef.current.length !==
                      optionItems.length
                    ) {
                      selectOptionItemsWithIdRef.current =
                        handleSelectKeyId(optionItems)
                    }
                  } else {
                    selectOptionItemsWithIdRef.current =
                      handleSelectKeyId(optionItems)
                  }
                  return (
                    <option
                      value={value}
                      key={`select-items-${selectOptionItemsWithIdRef.current[i].id}`}
                      data-testid={testId}
                    >
                      {text}
                    </option>
                  )
                })}
            </FormControl>
            {!hideFeedbackText && (
              <FormControl.Feedback type="invalid" className="px-2">
                {feedbackText}
              </FormControl.Feedback>
            )}
            {!hideFeedbackText && (
              <FormControl.Feedback type="valid" className="px-2">
                {feedbackText}
              </FormControl.Feedback>
            )}
          </>
        )
      }
    </div>
  )
}

function StyleSelect(props) {
  return (
    <StyleSelectContainer {...props}>
      <CustomSelect {...props} />
    </StyleSelectContainer>
  )
}

export { StyleSelect }
