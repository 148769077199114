import styled, { css } from 'styled-components'
import { Checkbox } from 'components/units'
import { colorObj } from 'assets/styles/Variable/Color'

const Wrapper = styled.div`
  .tab-content {
    padding: 24px;
  }

  .caption {
    caption-side: top;
    padding: 0px 0px 16px 0px;
  }

  .label-title {
    display: flex;
    align-items: center;
  }

  .label-title + .text-right {
    justify-content: flex-end;
  }
  .paragraph {
    td {
      height: 100px;
    }
  }
`

const StyledCheckbox = styled(Checkbox)`
  margin-left: 16px;
  color: ${colorObj.dark};
`

const infoMixin = css`
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    background-color: ${colorObj.white};
    border-radius: 5px;
  }
  .info-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  .info-content {
    display: flex;
    flex-direction: column;
  }
  .info-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .info-default {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    color: ${colorObj.darkGray};
  }
`

const amountMixin = css`
  flex-wrap: nowrap;
  white-space: nowrap;
  .amount-warn {
    color: ${colorObj.danger};
  }
  .amount-once {
    color: ${colorObj.darkGray};
  }
`

const wayMixin = css`
  max-width: 140px;
  > div {
    display: flex;
    gap: 2px;
    justify-content: flex-end;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .way-currency {
    font-weight: 500;
  }
`

const settingMixin = css`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 0 8px;
`

const dateMixin = css`
  .dateRange-expired {
    color: ${colorObj.danger};
  }
`

const StyledFormatter = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: ${(props) => props.color || colorObj.dark};
  font-weight: normal;
  width: ${(props) => props.width || 'auto'};

  ${(props) => props.date && dateMixin}
`
const baseStyle = css`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: ${(props) => props.color || colorObj.dark};
  font-weight: normal;
  width: ${(props) => props.width || 'auto'};
`
const StyledFormatterSetting = styled.div`
  ${baseStyle}
  ${settingMixin}
`
const StyledFormatterAmount = styled.div`
  ${baseStyle}
  ${amountMixin}
`
const StyledFormatterInfo = styled.div`
  ${baseStyle}
  ${infoMixin}
`
const StyledFormatterWay = styled.div`
  ${baseStyle}
  ${wayMixin}
`

const FormatterFnWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.flexEnd ? 'flex-end' : 'flex-start')};
  align-items: center;
  gap: 10px;
  .formatterFnBtn .dropdown-toggle {
    padding: 0 8px;
  }

  .unavailableIcon {
    font-size: 14px;
    margin-right: 5px;
    path {
      fill-opacity: 1;
      fill: ${colorObj.darkerGray};
    }
    + span {
      font-size: 14px;
    }
  }
`

export {
  Wrapper,
  StyledCheckbox,
  StyledFormatter,
  StyledFormatterSetting,
  StyledFormatterAmount,
  StyledFormatterInfo,
  StyledFormatterWay,
  FormatterFnWrapper,
}
