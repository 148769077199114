import styled from 'styled-components'
import { colorObj } from 'assets/styles/Variable/Color'

const LocalStyle = styled.div`
  .icon-feature-container {
    > button {
      &:last-of-type {
        margin-right: 0;
      }
    }
    [data-id='tooltip'] {
      font-weight: 400;
    }
  }
  .icon-extra-point {
    min-width: 16px;

    path {
      fill: ${colorObj.danger};
    }
  }
  .font-danger {
    color: ${colorObj.danger};
  }
`

export default LocalStyle
