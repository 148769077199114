/* -------------------------------------- 
  可共用的參數
--------------------------------------*/

// table中頁碼的下拉選單設定
const paginationOptions = {
  custom: true,
  sizePerPageList: [
    { text: '25 筆', value: 25 },
    { text: '50 筆', value: 50 },
    { text: '100 筆', value: 100 },
  ],
}

const commonSetting = {
  paginationOptions,
}

export { commonSetting }
