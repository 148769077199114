import { useEffect, useState } from 'react'
import styled from 'styled-components'
import SidebarDropdownItem from 'components/layouts/SidebarDropdownItem'
import SidebarStruct from 'components/layouts/SidebarStruct'
import { clearSessionStorage, setSessionStorage } from 'helpers/common'
import iconMenuToggle from '../../assets/images/menubar/icon_menuToggle.svg'

const SidebarContainer = styled.div`
  background-color: #fafafa;
`

const MenuIsOpened = styled.div`
  transform: rotate(45deg);
`

const MenuCloseBtnContainer = styled.div`
  width: 215px;
  background: white;
  box-shadow: 4px 0px 4px -4px rgba(51, 51, 51, 0.25);
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 48px;
  left: 2px;
  margin-left: ${({ sidebar }) => (sidebar ? '0' : '-200px')};
  transition: 350ms;
`

const MenuToggleClose = styled.div`
  width: 12px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
  box-shadow: 0 4px 4px -4px rgba(51, 51, 51, 0.25);
  padding: 1px;
`

const SidebarNav = styled.nav`
  background: white;
  width: 200px;
  height: calc(100vh - 48px);
  display: flex;
  justify-content: center;
  position: sticky;
  top: 48px;
  margin-left: ${({ sidebar }) => (sidebar ? '0' : '-200px')};
  transition: 350ms;
  z-index: 21;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(51, 51, 51, 0.25);
  overflow: auto;

  a:hover {
    text-decoration: none;
  }
`

const SidebarWrap = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 21;
`

function Sidebar() {
  const [isSidebarOpen, setSidebarOpen] = useState(true)
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)

  // 全局監聽整個頁面的keyboard事件(為了讓使用者按鍵盤command(ctrl)+滑鼠左鍵可以另開新分頁的需求)
  useEffect(() => {
    const handleKeyboardEvent = (e) => {
      setSessionStorage('keyboardEventKey', e.key)
    }
    const handleKeyUp = (e) => {
      clearSessionStorage('keyboardEventKey', e.key)
    }
    document.addEventListener('keydown', handleKeyboardEvent)
    document.addEventListener('keyup', handleKeyUp)
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvent)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return (
    <SidebarContainer
      style={{
        flexBasis: isSidebarOpen ? '200px' : '0',
        transition: '350ms',
        zIndex: isSidebarOpen ? 11 : 1,
      }}
    >
      <SidebarNav sidebar={isSidebarOpen}>
        <SidebarWrap>
          {SidebarStruct.map((item) => (
            <SidebarDropdownItem navItem={item} key={`submenu-${item.id}`} />
          ))}
        </SidebarWrap>
      </SidebarNav>
      <MenuCloseBtnContainer to="#" sidebar={isSidebarOpen}>
        <MenuToggleClose to="#" onClick={toggleSidebar}>
          <MenuIsOpened>
            <img
              src={iconMenuToggle}
              alt=""
              style={isSidebarOpen ? {} : { transform: 'scale(-1)' }}
            />
          </MenuIsOpened>
        </MenuToggleClose>
      </MenuCloseBtnContainer>
    </SidebarContainer>
  )
}

export default Sidebar
