/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { Row, Col } from 'react-bootstrap'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { useSelector } from 'react-redux'
import { exportExchangeCode } from 'api/ApiMain'
import { color } from 'assets/styles/Variable/Color'
import {
  paginationOptions,
  columns,
  selectRow,
  codeGetType,
  captionLabel,
  dropdownOptions,
  eventAction,
} from 'components/pages/Gift/ExchangeSerialNumberConfig'
import {
  ImportModalWrapper,
  TextLink,
} from 'components/pages/Gift/ExchangeSerialNumberStyle'
import {
  Caption,
  UploadBtn,
} from 'components/pages/Gift/ExchangeSerialNumberSubComponents'
import {
  Table,
  NoDataIndication,
  Modal,
  Button,
  Dropdown,
} from 'components/units'
import { PAGE_PATHS } from 'constant'
import { CSV_EXAMPLE } from 'constant/csvExample'
import { downloadCsv } from 'helpers/common'
import { authAccountSelector } from 'store/auth/authSelector'
import { getGlobalState } from 'store/global/globalSlice'
import SearchBar from '../widgets/SearchBar'

function SerialNumberTable({
  defaultRules,
  isSettingTypeExisted,
  listTotal,
  payload,
  onListQuery,
  isSystemType,
  isUserType,
  isEdit,
  isSelected,
  isSameCode,
  columnsObj,
  selectObj,
  uploadCount,
  data,
  setMask,
  onBatchRemove,
  giftSettingId,
  context,
  isImportModal,
  onUploadChange,
  isUploadModal,
  setUploadModal,
}) {
  // 可能被 isExportDisabled 取代，確定前站時先保留
  const unexchangedCountIsZero = listTotal.totalUnexchangedCount === 0

  // 「完全沒有序號」或「序號全部已兌換」，則匯出按鈕 disabled
  const isExportDisabled =
    listTotal.totalCount === 0 || listTotal.totalUnexchangedCount === 0
  const isUploadDone = listTotal.totalCount === uploadCount

  // 設定為「相同序號」，或上傳已經結束時，批次上傳按鈕 disabled
  const isUploadDisabled = isUploadDone || isSameCode
  const { brandId } = useSelector(authAccountSelector)
  const { isDataLoading, isListLoading } = useSelector(getGlobalState)

  const handleExampleDownload = () => {
    downloadCsv({
      data: CSV_EXAMPLE.EXCHANGE,
      fileName: '贈品序號_匯入範本',
    })
  }

  const handleTableChange = (type, state) => {
    if (type === 'pagination') return // 每頁筆數
    const { sortField, sortOrder } = state
    onListQuery({ so: sortOrder, sf: sortField })
  }

  const handleCsvDownload = useCallback(async () => {
    try {
      const res = await exportExchangeCode(brandId, giftSettingId, { type: 0 })
      const fileName = `${context?.defaultData?.name}_匯出未兌換序號`
      downloadCsv({ data: res.data, fileName })
    } catch (error) {
      console.log(
        '🚀 ~ file: SettingNo.js ~ line 226 ~ handleCsvDownload ~ error',
        error
      )
    }
  }, [brandId, context?.defaultData?.name, giftSettingId])

  const handleUpload = () => {
    setUploadModal(true)
    setMask(true)
  }
  const dispatchAction = (e) => {
    switch (e) {
      case eventAction.addMany:
        handleUpload()
        break
      case eventAction.export:
        handleCsvDownload()
        break
      default:
        break
    }
  }

  const handleImportModalConfirm = () => {
    setMask(false)
    context?.history?.push(PAGE_PATHS.gift.exchange, {
      apiPayload: context.history.location.state.apiPayload,
    })
  }

  const paginationFn = {
    onPageChange: (page) => onListQuery({ p: page }),
    onSizePerPageChange: (sizePerPage, page) =>
      onListQuery({ p: page === 0 ? 1 : page, ps: sizePerPage }),
  }

  return (
    <>
      {/* 列表 */}
      {isSettingTypeExisted && (
        <PaginationProvider
          pagination={paginationFactory({
            ...paginationOptions,
            ...paginationFn,
            totalSize: listTotal.conditionalCount,
            page: payload.p,
            sizePerPage: payload.ps,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <>
              <Caption
                paginationProps={paginationProps}
                isShowCaption={
                  defaultRules.codeGetType === codeGetType.sameCode
                }
                type={defaultRules.type}
                listTotal={listTotal}
                uploadCount={uploadCount}
                isEdit={isEdit}
                isSelected={isSelected}
              />
              <Row>
                <Col>
                  <SearchBar
                    isSettingTypeExisted={isSettingTypeExisted}
                    onListQuery={onListQuery}
                  />
                </Col>
                <Col className="d-flex justify-content-end">
                  {isEdit && (
                    <Button
                      className="remove-btn"
                      size="sm"
                      disabled={!isSelected}
                      variant="outline-darkerGray"
                      onClick={onBatchRemove}
                    >
                      {captionLabel.delete}
                    </Button>
                  )}
                  {isSystemType && (
                    <Button
                      className="ml-3"
                      size="sm"
                      disabled={unexchangedCountIsZero}
                      variant="outline-primary"
                      onClick={handleCsvDownload}
                    >
                      {captionLabel.export}
                    </Button>
                  )}
                  {isUserType && (
                    <Dropdown
                      className="ml-3"
                      colorType="primary"
                      framed
                      size="sm"
                      html={captionLabel.dropdown}
                      menuItems={dropdownOptions({
                        fn: dispatchAction,
                        uploadIsHide: !isEdit,
                        uploadDisabled: isUploadDisabled,
                        exportDisabled: isExportDisabled,
                      }).filter((i) => !i.hide && i)}
                    />
                  )}
                </Col>
              </Row>
              <Table
                headerClasses="table-header"
                bodyClasses="paragraph"
                className="overflow-auto"
                keyField="no"
                data={data}
                columns={columns(columnsObj)}
                selectRow={selectRow(selectObj)}
                striped
                remote
                onTableChange={handleTableChange}
                noDataIndication={
                  <NoDataIndication
                    isLoading={isDataLoading ? !isDataLoading : isListLoading}
                    message={
                      isDataLoading
                        ? '載入中'
                        : '查無資料，請調整查詢內容後重新查詢'
                    }
                  />
                }
                {...paginationTableProps}
              />
              <PaginationListStandalone {...paginationProps} />
            </>
          )}
        </PaginationProvider>
      )}
      <Modal
        show={isUploadModal}
        onClose={() => {
          setUploadModal(false)
          setMask(false)
        }}
        titleText="批次上傳"
        closeBtnText="返回"
        customConfirm={() => <UploadBtn onChange={onUploadChange} />}
        isShowDefaultMask={false}
      >
        <ImportModalWrapper>
          <p>1. 序號總數不可高於 10 萬組</p>
          <p>2. 檔案格式限 csv 檔，檔案大小限 ≤ 25MB</p>
          <p>
            3. 欄位設定說明，詳見
            <TextLink onClick={handleExampleDownload}>
              匯入範本說明 (下載)
            </TextLink>
          </p>
          <p>4. 檔案上傳結果將發送至帳號 E-mail 信箱</p>
        </ImportModalWrapper>
      </Modal>
      <Modal
        show={isImportModal}
        variant={color.primary}
        titleText="返回列表"
        confirmBtnText="確認"
        onConfirm={handleImportModalConfirm}
        onClose={handleImportModalConfirm}
        isHideCloseButton
        closeBtnVisible={false}
        isShowDefaultMask={false}
      >
        <ul
          style={{
            textAlign: 'left',
            listStyleType: 'decimal',
            padding: '0 40px',
          }}
        >
          <li
            style={{
              marginBottom: '10px',
              lineHeight: '1.5',
            }}
          >
            贈品序號已上傳資料庫寫入中，結果將發送至
            <br />
            帳號 E-mail 信箱
          </li>
          <li>寫入期間無法對此贈品項目進行編輯與刪除</li>
        </ul>
      </Modal>
    </>
  )
}

export default SerialNumberTable

SerialNumberTable.propTypes = {
  defaultRules: PropTypes.shape({
    type: PropTypes.number,
    codeGetType: PropTypes.number,
    prefix: PropTypes.string,
    length: PropTypes.number,
  }).isRequired,
  isSettingTypeExisted: PropTypes.bool.isRequired,
  listTotal: PropTypes.shape({
    conditionUnexchangedCodeCount: PropTypes.number,
    conditionalCount: PropTypes.number,
    totalUnexchangedCount: PropTypes.number,
    totalCount: PropTypes.number,
  }).isRequired,
  payload: PropTypes.shape({
    sf: PropTypes.string,
    so: PropTypes.string,
    searchType: PropTypes.string,
    keyword: PropTypes.string,
    p: PropTypes.number,
    ps: PropTypes.number,
  }).isRequired,
  onListQuery: PropTypes.func.isRequired,
  isSystemType: PropTypes.bool.isRequired,
  isUserType: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isSameCode: PropTypes.bool.isRequired,
  columnsObj: PropTypes.shape({
    giftCode: PropTypes.shape({
      title: PropTypes.string,
      width: PropTypes.string,
    }),
    member: PropTypes.shape({
      title: PropTypes.string,
      width: PropTypes.string,
    }),
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      no: PropTypes.number,
      giftCode: PropTypes.string,
      member: PropTypes.string,
      exchangeTime: PropTypes.string,
      exchangeStatus: PropTypes.string,
      exchangeStatusColor: PropTypes.string,
      exchangeStatusText: PropTypes.string,
      exchangeStatusTooltip: PropTypes.string,
      exchangeStatusTooltipPlacement: PropTypes.string,
      exchangeStatusTooltipColor: PropTypes.string,
      exchangeStatusTooltipText: PropTypes.string,
      exchangeStatusTooltipIcon: PropTypes.string,
      exchangeStatusTooltipIconColor: PropTypes.string,
      exchangeStatusTooltipIconSize: PropTypes.string,
    })
  ).isRequired,
  selectObj: PropTypes.shape({
    onSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
    selected: PropTypes.arrayOf(PropTypes.number),
    nonSelectable: PropTypes.arrayOf(PropTypes.number),
    hideSelectColumn: PropTypes.bool,
  }).isRequired,
  uploadCount: PropTypes.number.isRequired,
  setMask: PropTypes.func.isRequired,
  onBatchRemove: PropTypes.func.isRequired,
  giftSettingId: PropTypes.number.isRequired,
  context: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func,
      location: PropTypes.shape({
        state: PropTypes.shape({
          apiPayload: PropTypes.shape({
            sf: PropTypes.string,
            so: PropTypes.string,
            searchType: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
            ]),
            keyword: PropTypes.string,
            p: PropTypes.number,
            ps: PropTypes.number,
          }),
        }),
      }),
    }),
    defaultData: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  isImportModal: PropTypes.bool.isRequired,
  onUploadChange: PropTypes.func.isRequired,
  isUploadModal: PropTypes.bool.isRequired,
  setUploadModal: PropTypes.func.isRequired,
}
