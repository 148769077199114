/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { colorObj } from 'assets/styles/Variable/Color'
import withPermission from 'components/hocs/withPermission'
import { Basic, ContentSection } from 'components/templates'
import {
  Breadcrumb,
  Button,
  Card,
  NoDataIndication,
  Table,
} from 'components/units'
import PaginationInput from 'components/units/PaginationInput'
import { SearchSection } from 'components/widgets'
import { COMPONENT_PERMISSIONS, PAGE_BREADCRUMB } from 'constant'
import {
  MemberSearchConfig,
  MemberSearchInitError,
  MemberSearchInitState,
} from 'helpers/searchFieldFormat'
import { RootContext } from 'RootContext'
import { getGlobalState, updateDataLoading } from 'store/global/globalSlice'
import { getMember, getMemberLevel } from '../../api/ApiMain'
import {
  setLocalStorage,
  setHomeDataStorage,
  getLocalStorage,
  isJSON,
} from '../../helpers/common'

function SideSection() {
  const history = useHistory()
  const { CREATE } = COMPONENT_PERMISSIONS.member
  const PermissionAddMemberButton = withPermission(Button, CREATE)
  return (
    <PermissionAddMemberButton
      variant="outline-primary"
      onClick={() => history.push('/member/new')}
      style={{ position: 'absolute', right: 0, bottom: 0 }}
    >
      新增會員
    </PermissionAddMemberButton>
  )
}
// 每頁顯示會員資料筆數，為使用者個人化預設值，故從local拿
const getLocalStorageSizePerPage = (string, rootData) => {
  if (isJSON(string)) {
    const data = JSON.parse(string)
    if (Array.isArray(data?.idListData)) {
      const idItem = data.idListData.find(
        (item) => item.name === rootData?.account?.name
      )
      if (idItem) {
        return idItem.sizePerPage
      }
    }
  }
  return null
}

function MemberSearch() {
  const dispatch = useDispatch()
  const { isDataLoading } = useSelector(getGlobalState)
  // global data
  const rootData = useContext(RootContext)
  const { shopId, brandId } = rootData
  const [memberData, setMemberData] = useState({})
  const [memberSearchConfigData, setMemberSearchConfigData] = useState([])
  // 是否查詢條件要包含shopId(按下查詢按鈕時必須不包含shopId)
  const isFilterShopIdRef = useRef(true)
  const initTableFilterCondition = {
    p: 1,
    ps:
      getLocalStorageSizePerPage(getLocalStorage('memberData'), rootData) ?? 25,
    sf: 'customId',
    so: 'desc',
  }
  const [tableFilterCondition, setTableFilterCondition] = useState(
    initTableFilterCondition
  )
  const [memberSearchCondition, setMemberSearchCondition] = useState({})

  const filterShopId = useCallback(() => {
    return isFilterShopIdRef.current
      ? tableFilterCondition?.shopId ?? shopId
      : undefined
  }, [shopId, tableFilterCondition?.shopId])

  // 獲得會員查詢的表格資料
  const getMemberData = useCallback(async () => {
    dispatch(updateDataLoading(true))
    setMemberData({})
    const cleanMemberSearchParameter = Object.fromEntries(
      Object.entries(memberSearchCondition).filter(([key, value]) => {
        if (key === 'lastDealDate') {
          const lastDealDateValue = value === 'none' ? '' : value
          return lastDealDateValue !== ''
        }
        return value !== ''
      })
    )
    try {
      const rsp = await getMember({
        ...cleanMemberSearchParameter,
        ...tableFilterCondition,
        shopId: filterShopId(),
        brandId,
      })
      setMemberData(rsp)
    } catch (e) {
      // 上層已處理
      console.error(e)
    } finally {
      dispatch(updateDataLoading(false))
    }
  }, [
    brandId,
    dispatch,
    filterShopId,
    memberSearchCondition,
    tableFilterCondition,
  ])

  // Handle Event

  const handleTableChange = (type, newState) => {
    const { sortField, sortOrder } = newState
    if (
      Array.isArray(memberData?.rows) &&
      memberData?.rows?.length > 0 &&
      type === 'sort'
    ) {
      setTableFilterCondition((prevState) => ({
        ...prevState,
        sf: sortField,
        so: sortOrder,
      }))
    }
  }

  const handleReset = () => {
    // 重置搜尋表單
    setTableFilterCondition({
      ...tableFilterCondition,
      p: 1,
    })
    setMemberSearchCondition({})
    // 重置門店過濾
    isFilterShopIdRef.current = true
  }

  const handleSubmit = (formValues) => {
    // 查詢按鈕按下後，排除門店過濾的限制
    isFilterShopIdRef.current = false

    setMemberSearchCondition(formValues)

    setTableFilterCondition({
      ...tableFilterCondition,
      p: 1,
    })
  }

  const paginationOptions = {
    custom: true,
    sizePerPage: tableFilterCondition?.ps,
    sizePerPageList: [
      { text: '25筆', value: 25 },
      { text: '50筆', value: 50 },
      { text: '100筆', value: 100 },
    ],
    totalSize: memberData?.totalSize || 0,
    onPageChange: (nowPage) => {
      setTableFilterCondition((prevState) => ({
        ...prevState,
        p: nowPage,
      }))
    },
    onSizePerPageChange: (sizePerPage) => {
      setTableFilterCondition((prevState) => ({
        ...prevState,
        ps: sizePerPage,
      }))

      setLocalStorage(
        'memberData',
        JSON.stringify({
          idListData: [
            {
              name: rootData?.account?.name,
              sizePerPage,
            },
          ],
        })
      )
    },
  }

  // react-bootstrap-table-next所需的column參數
  const memberSearchColumns = () => {
    return [
      {
        dataField: 'customId',
        text: '會員編號',
        sort: true,
      },
      {
        dataField: 'name',
        text: '姓名',
        formatter: (cell) => cell || '-',
        events: {
          onClick: (e, column, columnIndex, row) => {
            setHomeDataStorage(e)
            setLocalStorage('brandId', brandId)
            const url = `/member/edit/${row?.id}`
            window.open(url, '_blank')
          },
        },
        style: (cell) => {
          if (!cell) return {}
          return {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: colorObj.primary,
          }
        },
      },
      { dataField: 'cellPhone', text: '手機' },
      { dataField: 'phone', text: '電話' },
      { dataField: 'email', text: '電子郵件' },
      {
        dataField: 'birthday',
        text: '生日',
        sort: true,
      },
      {
        dataField: 'membershipName',
        text: '會員等級',
        sort: true,
      },
      {
        dataField: 'shopName',
        text: '入會門店',
        sort: true,
      },
    ]
  }

  useEffect(() => {
    if (brandId || brandId === 0) {
      getMemberData({
        ...tableFilterCondition,
      })
    }
  }, [brandId, getMemberData, tableFilterCondition])

  useEffect(() => {
    const getMemberLevelData = async () => {
      try {
        const rsp = await getMemberLevel(brandId)
        const brandLevel = rsp?.memberLevelItemList.map((item) => ({
          text: item.name,
          value: item.code,
        }))

        // 加入預設的全部和註冊會員
        brandLevel.unshift(
          { text: '全部', value: 'null' },
          { text: '註冊會員', value: 'Lv0' }
        )

        const memberLevelIndex = MemberSearchConfig.findIndex(
          (item) => item.label === '會員等級'
        )
        const memberField = [...MemberSearchConfig]
        memberField[memberLevelIndex].groupSelect[0].options = brandLevel

        setMemberSearchConfigData(memberField)
      } catch (e) {
        console.error(e)
      }
    }
    getMemberLevelData()
  }, [brandId])

  return (
    <Basic
      navSection={() => Breadcrumb(PAGE_BREADCRUMB.member.search)}
      sideSection={SideSection}
    >
      <ContentSection>
        <SearchSection
          fields={memberSearchConfigData}
          initErrorMsg={MemberSearchInitError}
          initState={MemberSearchInitState}
          onSubmit={handleSubmit}
          onReset={handleReset}
        />
        <Row>
          <Col>
            <Card>
              <PaginationProvider
                pagination={paginationFactory({
                  ...paginationOptions,
                  page: tableFilterCondition?.p,
                })}
              >
                {({ paginationProps, paginationTableProps }) => {
                  return (
                    <div>
                      <Row className="mt-4 mb-3">
                        <Col className="label-title d-flex align-items-center">
                          總項目：
                          <span className="paragraph-info">
                            {paginationOptions.totalSize} 筆
                          </span>
                        </Col>
                        <Col className="label-title text-right">
                          <span className="white-space-nowrap mr-1">每頁</span>
                          <SizePerPageDropdownStandalone
                            {...paginationProps}
                            sizePerPage={`${paginationProps.sizePerPage}筆`}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Table
                            remote
                            className="overflow-auto "
                            headerClasses="table-header"
                            bodyClasses="paragraph"
                            keyField="id"
                            data={memberData?.rows ?? []}
                            columns={memberSearchColumns()}
                            rowStyle={{ cursor: 'default' }}
                            bordered={false}
                            onTableChange={handleTableChange}
                            striped
                            hover
                            noDataIndication={
                              <NoDataIndication
                                isLoading={isDataLoading}
                                message={
                                  isDataLoading
                                    ? '載入中...'
                                    : '查無資料，請調整搜尋內容後重新查詢'
                                }
                              />
                            }
                            {...paginationTableProps}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-center align-items-center">
                          <PaginationListStandalone
                            className="justify-content-center"
                            {...paginationProps}
                          />
                          <PaginationInput
                            onPageChange={paginationProps.onPageChange}
                            page={paginationProps.page}
                            sizePerPage={paginationProps.sizePerPage}
                            totalSize={paginationProps.totalSize}
                          />
                        </Col>
                      </Row>
                    </div>
                  )
                }}
              </PaginationProvider>
            </Card>
          </Col>
        </Row>
      </ContentSection>
    </Basic>
  )
}

export default MemberSearch
