import { useState, useEffect, useCallback, useContext } from 'react'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import { Table, NoDataIndication } from 'components/units'
import { getExchangedRecord, getMemberOutline } from 'api/pages/Gift'
import { SettingContext, tabStatus } from 'pages/Gift/ExchangeSetting'
import {
  paginationOptions,
  tableText,
  defaultSort,
  columns,
} from 'components/pages/Gift/ExchangeSendListConfig'
import { Wrapper } from 'components/pages/Gift/ExchangeSendListStyle'
import {
  Caption,
  MemberInfoModal,
} from 'components/pages/Gift/ExchangeSendListSubComponents'
import { useDispatch, useSelector } from 'react-redux'
import { updateDataLoading } from 'store/global/globalSlice'
import {
  exchangeRecordDetailSelector,
  getExchangeRecordDetailThunk,
  resetGiftExchangeDetailModal,
} from 'store/gift-exchange/giftExchangeSlice'
import {
  getMemberOutlineThunk,
  resetMemberOutline,
} from 'store/member-outline/memberOutlineSlice'

const initPaylaod = {
  cellPhone: '',
  p: 1,
  ps: 25,
  sf: defaultSort.sf,
  so: defaultSort.so,
}

function SettingList() {
  const dispatch = useDispatch()
  const context = useContext(SettingContext)
  const isThisPageNow = context?.pageType === tabStatus.exchangeList
  const brandId = context?.history?.location?.state?.apiPayload?.brandId
  // apiPaylaod?.giftSettingId為編輯＆檢視進入頁面，context?.settingId為新增進入頁面
  const giftSettingId =
    context?.history?.location?.state?.apiPayload?.giftSettingId ||
    context?.settingId

  const [data, setData] = useState({ list: [], total: 0 })
  const [payload, setPayload] = useState(initPaylaod)
  const [isModalOpen, setModalOpen] = useState(false)
  const modalContent = useSelector(exchangeRecordDetailSelector)
  const onSubmit = useCallback(
    (value) =>
      setPayload((prev) => ({
        ...prev,
        cellPhone: value,
      })),
    []
  )

  const paginationFn = {
    onPageChange: useCallback(
      (page) => setPayload((prev) => ({ ...prev, p: page })),
      []
    ),
    onSizePerPageChange: useCallback(
      (sizePerPage, page) =>
        setPayload((prev) => ({
          ...prev,
          p: page === 0 ? 1 : page,
          ps: sizePerPage,
        })),
      []
    ),
  }

  const handleTableChnage = useCallback((type, data) => {
    if (type === 'pagination') return
    const { sortField, sortOrder } = data
    setPayload((prev) => ({ ...prev, so: sortOrder, sf: sortField }))
  }, [])

  const handleModalOpen = useCallback(
    (payload) => {
      const { memberId, giftRecordObjId } = payload
      setModalOpen(true)
      dispatch(getMemberOutlineThunk({ memberId, brandId }))
      dispatch(
        getExchangeRecordDetailThunk({
          brandId,
          giftSettingId,
          giftRecordObjId,
        })
      )
    },
    [brandId]
  )

  const handleModalConfirm = useCallback(() => {
    if (modalContent.memberId)
      window.open(`/member/edit/${modalContent.memberId}`)
    handleModalClose()
  }, [modalContent.memberId])

  const handleModalClose = () => {
    setModalOpen(false)
    dispatch(resetGiftExchangeDetailModal())
    dispatch(resetMemberOutline())
  }

  const search = useCallback(async () => {
    if (!isThisPageNow) return
    try {
      const res = await getExchangedRecord(brandId, giftSettingId, payload)
      const resList = res?.data?.data?.rows || []
      const resTotal = res?.data?.data?.totalSize || 0
      setData({ list: resList, total: resTotal })
    } catch (err) {
      console.error(err)
    } finally {
      dispatch(updateDataLoading(false))
    }
  }, [isThisPageNow, brandId, giftSettingId, payload, dispatch])

  useEffect(() => {
    search()
  }, [search])

  return (
    <Wrapper>
      <PaginationProvider
        pagination={paginationFactory({
          ...paginationOptions,
          ...paginationFn,
          totalSize: data.total,
          page: payload.p,
          sizePerPage: payload.ps,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <Caption
              paginationProps={paginationProps}
              onSubmit={onSubmit}
              total={data.total}
            />
            <Table
              headerClasses="table-header"
              bodyClasses="paragraph"
              className="overflow-auto"
              keyField="no"
              data={data.list}
              columns={columns({
                onClick: handleModalOpen,
              })}
              striped
              remote
              onTableChange={handleTableChnage}
              noDataIndication={<NoDataIndication message={tableText.empty} />}
              {...paginationTableProps}
            />
            <PaginationListStandalone {...paginationProps} />
          </>
        )}
      </PaginationProvider>
      <MemberInfoModal
        show={isModalOpen}
        info={modalContent}
        onConfirm={handleModalConfirm}
        onClose={handleModalClose}
      />
    </Wrapper>
  )
}

export default SettingList
