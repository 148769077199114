import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { startOfDay } from 'date-fns'
import {
  addVipGift,
  getMemberList,
  getDesignatedList,
  getVipGiftInfo,
  updateVipGiftInfo,
  getVipExchangeLink,
  vipExchangeUnlink,
} from 'api/ApiMain'
import {
  discountType,
  fieldType,
  formConfig,
  receiveMethodType,
} from 'components/pages/Gift/ExclusiveContentConfig'
import { VIP_GIFT_EXCHANGE_SETTING } from 'store/constants'

const parserReceiveMethod = (value) => {
  if (value === undefined || value === null) return ''
  if (value === receiveMethodType.online)
    return formConfig[fieldType.receiveMethod].radioOption[0]
  return formConfig[fieldType.receiveMethod].radioOption[1]
}

const parserMemberLevels = ({ memberLevels, list }) => {
  return memberLevels.map((member) => ({
    ...member,
    selected: list.some((item) => item.id === member.id && item.selected),
  }))
}
// TODO 需測試
const parserDesignatedList = ({
  designatedList,
  designatedListSelectedItem,
}) => {
  return designatedList.map((designatedItem) => ({
    ...designatedItem,
    selected: designatedItem.code === designatedListSelectedItem.code,
  }))
}

const parserAutoUpload = (value) => {
  if (value === undefined || value === null) return ''
  if (value) return formConfig[fieldType.autoUpload].radioOption[0]
  return formConfig[fieldType.autoUpload].radioOption[1]
}
const parserDiscountType = (value) => {
  if (value === undefined || value === null) return ''
  if (value === discountType.discount)
    return formConfig[fieldType.giftSpecialOffer].radioOption[0]
  return formConfig[fieldType.giftSpecialOffer].radioOption[1]
}
export const vipGiftExchangeSettingInitState = {
  getMemberListRsp: {
    code: null,
    msg: '',
    errors: {},
    data: {
      memberLevelItemList: [],
    },
    isFetch: false,
  },
  getDesignatedListRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      designatedList: [],
    },
    isFetch: false,
  },
  getVipGiftInfoRsp: {
    data: {
      code: 0,
      msg: '',
      errors: {},
      data: {
        vipGiftSettingId: null,
        brandSerialNo: null,
        linkInfo: {
          linkedVipGiftSettingId: null,
          linkedBrandSerialNo: null,
          linkedName: '',
          isMain: true,
        },
        name: '',
        giftType: null,
        restrictions: null,
        designatedCount: null,
        designatedList: {
          code: '',
          name: '',
        },
        forNewMember: true,
        forInitialMember: true,
        forMemberLevel: true,
        isAllMemberLevelChecked: true,
        memberLevelList: [
          {
            id: 0,
            code: '',
            name: '',
            selected: true,
          },
        ],
        overSpentPerDealCond: null,
        notSplit: true,
        contentType: 1,
        discountType: null,
        discountAmount: null,
        discountPercentage: null,
        point: null,
        amount: null,
        receiveWay: null,
        images: [
          {
            vipGiftSettingImgId: null,
            name: '',
            url: '',
          },
        ],
        productId: '',
        productName: '',
        productColor: '',
        productSize: '',
        exchangeStartTime: '',
        exchangeEndTime: '',
        autoAvailable: true,
        personAvailable: true,
        giftCodeStartTime: '',
        giftCodeEndTime: '',
        giftCodeEndTimeType: null,
        giftCodeEndTimeAfterDays: null,
        description: '',
        giftCodeType: null,
        giftCodeGetType: null,
        giftCodePrefix: '',
        giftCodeLength: null,
        giftCodeCount: null,
        sentCount: null,
        barcodeTypeNotice: '',
      },
    },
    isFetch: false,
  },
  getVipExchangeLinkRsp: {
    code: 0,
    msg: '',
    errors: {},
    data: {
      canBeLinkedList: [],
    },
    isFetch: false,
  },
  postVipGiftRsp: {
    code: null,
    msg: '',
    errors: {},
    data: {
      vipGiftSettingStatus: '',
      vipGiftSettingId: 0,
    },
    isFetch: false,
  },
  patchVipGiftInfoRsp: {
    code: null,
    msg: '',
    errors: {},
    data: {
      vipGiftSettingStatus: '',
    },
    isFetch: false,
  },
  patchVipExchangeUnlinkRsp: {
    data: {},
    isFetch: false,
  },
}

const getMemberListThunk = createAsyncThunk(
  `${VIP_GIFT_EXCHANGE_SETTING}/getMemberList`,
  async ({ brandId }, { rejectWithValue }) => {
    try {
      const rsp = await getMemberList(brandId)
      return rsp?.data
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)
const getDesignatedListThunk = createAsyncThunk(
  `${VIP_GIFT_EXCHANGE_SETTING}/getDesignatedList`,
  async ({ brandId, payload }, { rejectWithValue }) => {
    try {
      const rsp = await getDesignatedList(brandId, payload)
      return rsp?.data
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)
const getVipGiftInfoThunk = createAsyncThunk(
  `${VIP_GIFT_EXCHANGE_SETTING}/getVipGiftInfo`,
  async ({ brandId, giftSettingId }, { rejectWithValue }) => {
    try {
      const rsp = await getVipGiftInfo(brandId, giftSettingId)
      return rsp?.data
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)
const getVipExchangeLinkThunk = createAsyncThunk(
  `${VIP_GIFT_EXCHANGE_SETTING}/getVipExchangeLink`,
  async ({ brandId }, { rejectWithValue }) => {
    try {
      const rsp = await getVipExchangeLink(brandId)
      return rsp?.data
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

const postVipGiftThunk = createAsyncThunk(
  `${VIP_GIFT_EXCHANGE_SETTING}/addVipGift`,
  async ({ brandId, body }, { rejectWithValue }) => {
    try {
      const rsp = await addVipGift(brandId, body)
      return rsp?.data
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

const patchVipGiftInfoThunk = createAsyncThunk(
  `${VIP_GIFT_EXCHANGE_SETTING}/updateVipGiftInfo`,
  async ({ brandId, giftSettingId, body }, { rejectWithValue }) => {
    try {
      const rsp = await updateVipGiftInfo(brandId, giftSettingId, body)
      return rsp?.data
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

const patchVipExchangeUnlinkThunk = createAsyncThunk(
  `${VIP_GIFT_EXCHANGE_SETTING}/patchVipExchangeUnlinkThunk`,
  async ({ brandId, giftSettingId }, { rejectWithValue }) => {
    try {
      const rsp = await vipExchangeUnlink(brandId, giftSettingId)
      return rsp?.data
    } catch (err) {
      // 上層已處理
      return rejectWithValue(err)
    }
  }
)

const vipGiftExchangeSettingSlice = createSlice({
  name: VIP_GIFT_EXCHANGE_SETTING,
  initialState: vipGiftExchangeSettingInitState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === getMemberListThunk.fulfilled.type ||
        action.type === getMemberListThunk.rejected.type,
      (state, action) => {
        state.getMemberListRsp = action.payload
        state.getMemberListRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getDesignatedListThunk.fulfilled.type ||
        action.type === getDesignatedListThunk.rejected.type,

      (state, action) => {
        state.getDesignatedListRsp = action.payload
        state.getDesignatedListRsp.isFetch = true
      }
    )

    builder.addMatcher(
      (action) =>
        action.type === getVipGiftInfoThunk.fulfilled.type ||
        action.type === getVipGiftInfoThunk.rejected.type,
      (state, action) => {
        state.getVipGiftInfoRsp = action.payload
        state.getVipGiftInfoRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === getVipExchangeLinkThunk.fulfilled.type ||
        action.type === getVipExchangeLinkThunk.rejected.type,
      (state, action) => {
        state.getVipExchangeLinkRsp = action.payload
        state.getVipExchangeLinkRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === postVipGiftThunk.fulfilled.type ||
        action.type === postVipGiftThunk.rejected.type,
      (state, action) => {
        state.postVipGiftRsp = action.payload
        state.postVipGiftRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === patchVipGiftInfoThunk.fulfilled.type ||
        action.type === patchVipGiftInfoThunk.rejected.type,
      (state, action) => {
        state.patchVipGiftInfoRsp = action.payload
        state.patchVipGiftInfoRsp.isFetch = true
      }
    )
    builder.addMatcher(
      (action) =>
        action.type === patchVipExchangeUnlinkThunk.fulfilled.type ||
        action.type === patchVipExchangeUnlinkThunk.rejected.type,
      (state, action) => {
        state.patchVipExchangeUnlinkRsp = action.payload
        state.patchVipExchangeUnlinkRsp.isFetch = true
      }
    )
  },
})

const levelItemsSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE_SETTING].getMemberListRsp,
  (getMemberListRsp) => {
    const { memberLevelItemList } = getMemberListRsp?.data ?? {}
    return {
      memberLevelItemList: memberLevelItemList ?? [],
    }
  }
)
const designatedListSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE_SETTING].getDesignatedListRsp,
  (getDesignatedListRsp) => {
    const { isFetch, data } = getDesignatedListRsp
    if (!isFetch) return { designatedList: [] }
    const result = data?.designatedList.map(({ code, name }) => ({
      code,
      name: `ID:${code} - ${name}`,
    }))

    return {
      designatedList: result ?? [1, 2],
    }
  }
)

const vipGiftInfoSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE_SETTING].getVipGiftInfoRsp,
  designatedListSelector,
  levelItemsSelector,
  (getVipGiftInfoRsp, { designatedList }, { memberLevelItemList }) => {
    const { data } = getVipGiftInfoRsp
    const resAtrr = {
      giftCodeCount: 'giftCodeCount',
      sendCount: 'sendCount',
    }
    return {
      [fieldType.systemNumber]: data?.brandSerialNo ?? '',
      [fieldType.linkGift]: {
        vipGiftSettingId: data?.linkInfo?.linkedVipGiftSettingId ?? '',
        serialNo: data?.linkInfo?.linkedBrandSerialNo ?? '',
        content: data?.linkInfo?.linkedName ?? '',
        isMain: data?.linkInfo?.isMain ?? '',
      },
      [fieldType.eventName]: data?.name ?? '',
      [fieldType.eventType]: data?.giftType ?? '',
      [fieldType.eventStart]: {
        check: formConfig[fieldType.eventStart].radioOption[1],
        calendar: data?.exchangeStartTime
          ? new Date(data?.exchangeStartTime)
          : startOfDay(new Date()),
      },
      [fieldType.eventEnd]: {
        check:
          data?.exchangeEndTime === null
            ? formConfig[fieldType.eventEnd].radioOption[0]
            : formConfig[fieldType.eventEnd].radioOption[1],
        calendar: data?.exchangeEndTime
          ? new Date(data?.exchangeEndTime)
          : startOfDay(new Date()),
      },
      [fieldType.receiveLimit]: data?.restrictions ?? '',
      [fieldType.receiveCount]: data?.designatedCount ?? '',
      [fieldType.receiveMethod]: parserReceiveMethod(data?.receiveWay),
      [fieldType.receiveStart]: data?.giftCodeStartTime
        ? new Date(data?.giftCodeStartTime)
        : startOfDay(new Date()),
      [fieldType.target]: {
        forNewMember: data?.forNewMember ?? '',
        forInitialMember: data?.forInitialMember ?? '',
        memberLevels: parserMemberLevels({
          memberLevels: memberLevelItemList,
          list: data?.memberLevelList ?? [],
        }),
        designatedList: parserDesignatedList({
          designatedList,
          designatedListSelectedItem: data?.designatedList ?? [],
        }),
      },
      [fieldType.consumerCondition]: {
        check: data?.notSplit ?? '',
        condition: data?.overSpentPerDealCond ?? '',
      },
      [fieldType.giftType]: data?.contentType ?? '',
      [fieldType.giftSpecialOffer]: {
        check: parserDiscountType(data?.discountType),
        discount: data?.discountAmount ?? '',
        consumerAmount: data?.discountPercentage ?? '',
      },
      [fieldType.giftPoint]: data?.point ?? '',
      [fieldType.giftImage]: { ...data?.images?.[0] },
      [fieldType.giftCode]: data?.productId ?? '',
      [fieldType.giftName]: data?.productName ?? '',
      [fieldType.giftColor]: data?.productColor ?? '',
      [fieldType.giftSize]: data?.productSize ?? '',
      [fieldType.giftAmount]: data?.amount ?? '',
      [fieldType.giftInfo]: data?.description ?? '',
      [fieldType.autoUpload]: parserAutoUpload(data?.autoAvailable),

      [resAtrr.giftCodeCount]: data?.giftCodeCount ?? 0,
      [resAtrr.sendCount]: data?.sentCount ?? 0,
      vipGiftSettingId: data?.vipGiftSettingId ?? -1,
      barcodeTypeNotice: data?.barcodeTypeNotice ?? '',
      giftCodeGetType: data?.giftCodeGetType ?? '',
      giftCodeLength: data?.giftCodeLength ?? '',
      giftCodePrefix: data?.giftCodePrefix ?? '',
      giftCodeType: data?.giftCodeType ?? '',

      [fieldType.giftCodeEndTimeType]: data?.giftCodeEndTimeType ?? '',
      [fieldType.giftCodeEndTimeAfterDays]:
        data?.giftCodeEndTimeAfterDays ?? '',
      [fieldType.giftCodeEndTime]: data?.giftCodeEndTime
        ? new Date(data?.giftCodeEndTime)
        : startOfDay(new Date()),
    }
  }
)
const vipExchangeLinkSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE_SETTING].getVipExchangeLinkRsp,
  (getVipExchangeLinkRsp) => {
    const { data, isFetch } = getVipExchangeLinkRsp
    if (!isFetch) return { linkabaleList: [] }
    const { canBeLinkedList } = data
    return {
      linkabaleList: [{ text: '請選擇', value: -1 }].concat(
        canBeLinkedList.map((i) => ({
          text: i.brandSerialNoWithName,
          value: i.vipGiftSettingId,
        }))
      ),
    }
  }
)

const postVipGiftSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE_SETTING].postVipGiftRsp,
  (postVipGiftRsp) => {
    return { isPostSuccess: postVipGiftRsp.code === 0 }
  }
)
const patchVipGiftInfoSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE_SETTING].patchVipGiftInfoRsp,
  (patchVipGiftInfoRsp) => {
    return {
      isPatchSuccess: patchVipGiftInfoRsp.code === 0,
    }
  }
)
const patchVipExchangeUnlinkSelector = createSelector(
  (state) => state[VIP_GIFT_EXCHANGE_SETTING].patchVipExchangeUnlinkRsp,
  (patchVipExchangeUnlinkRsp) => {
    return {
      isPatchSuccess: patchVipExchangeUnlinkRsp.code === 0,
    }
  }
)

export {
  levelItemsSelector,
  designatedListSelector,
  vipGiftInfoSelector,
  vipExchangeLinkSelector,
  postVipGiftSelector,
  patchVipGiftInfoSelector,
  patchVipExchangeUnlinkSelector,
  getMemberListThunk,
  getDesignatedListThunk,
  getVipGiftInfoThunk,
  getVipExchangeLinkThunk,
  postVipGiftThunk,
  patchVipGiftInfoThunk,
  patchVipExchangeUnlinkThunk,
}

export default vipGiftExchangeSettingSlice.reducer
