import PropTypes from 'prop-types'
import { MemberModalWrapper } from 'components/pages/Gift/ExchangeSerialNumberStyle'
import { Modal } from 'components/units'

function MemberModal({
  modalContent,
  isMemberModal,
  handleMemberModalClose,
  handleMemberModalConfirm,
}) {
  return (
    <Modal
      show={isMemberModal}
      onClose={handleMemberModalClose}
      onConfirm={handleMemberModalConfirm}
      titleText="會員資訊"
      confirmBtnText="詳情"
      closeBtnText="關閉"
      confirmBtnVisible={modalContent.isMemberExist}
      isDefaultDataLoading={!modalContent.isReady}
    >
      <MemberModalWrapper>
        <h5 className="member-title">兌換紀錄</h5>
        <div className="member-info">
          <div className="member-label">會員編號：</div>
          <div className="member-content">{modalContent.customerId}</div>
        </div>
        <div className="member-info">
          <div className="member-label">姓名：</div>
          <div className="member-content">{modalContent.name}</div>
        </div>
        <div className="member-info">
          <div className="member-label">手機：</div>
          <div className="member-content">{modalContent.phone}</div>
        </div>
        <div className="member-info">
          <div className="member-label">會員等級：</div>
          <div className="member-content">{modalContent.level}</div>
        </div>
        {modalContent.isMemberExist && (
          <>
            <hr />
            <h5 className="member-title">
              會員輪廓<span className="member-note">最新會員資料</span>
            </h5>
            <div className="member-info">
              <div className="member-label">生日：</div>
              <div className="member-content">
                {modalContent.age} 歲
                <span className="member-note">{modalContent.yearMonth}</span>
              </div>
            </div>
            <div className="member-info">
              <div className="member-label">性別：</div>
              <div className="member-content">{modalContent.gender}</div>
            </div>
            <div className="member-info">
              <div className="member-label">剩餘點數：</div>
              <div className="member-content">{modalContent.pointBalance}</div>
            </div>
            <div className="member-info">
              <div className="member-label">客單價：</div>
              <div className="member-content">
                {modalContent.customerUnitPrice}
              </div>
            </div>
            <div className="member-info">
              <div className="member-label">上次消費日：</div>
              <div className="member-content">{modalContent.lastDealDate}</div>
            </div>
            <div className="member-info">
              <div className="member-label">入會時間：</div>
              <div className="member-content">
                {modalContent.registeredDate}
              </div>
            </div>
            <div className="member-info">
              <div className="member-label">入會門店：</div>
              <div className="member-content">{modalContent.shopName}</div>
            </div>
          </>
        )}
      </MemberModalWrapper>
    </Modal>
  )
}

export default MemberModal

MemberModal.propTypes = {
  modalContent: PropTypes.shape({
    memberId: PropTypes.string,
    isMemberExist: PropTypes.bool,
    isReady: PropTypes.bool,
    customerId: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    level: PropTypes.string,
    age: PropTypes.number,
    yearMonth: PropTypes.string,
    gender: PropTypes.string,
    pointBalance: PropTypes.string,
    customerUnitPrice: PropTypes.string,
    lastDealDate: PropTypes.string,
    registeredDate: PropTypes.string,
    shopName: PropTypes.string,
  }).isRequired,
  isMemberModal: PropTypes.bool.isRequired,
  handleMemberModalClose: PropTypes.func.isRequired,
  handleMemberModalConfirm: PropTypes.func.isRequired,
}
