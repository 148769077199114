import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { getThemedDashboard } from 'api/ApiMain'
import { DATA_ANALYSIS } from '../constants'

export const dataAnalysisInitState = {
  getThemedDashboardRsp: {
    data: {
      themedDashboards: [],
    },
  },
}

const getThemedDashboardThunk = createAsyncThunk(
  `${DATA_ANALYSIS}/getThemedDashboard`,
  async ({ brandId }, { rejectWithValue }) => {
    try {
      const rsp = await getThemedDashboard({ brandId })
      return rsp
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

const dataAnalysisSlice = createSlice({
  name: DATA_ANALYSIS,
  initialState: dataAnalysisInitState,
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === getThemedDashboardThunk.fulfilled.type ||
        action.type === getThemedDashboardThunk.rejected.type,
      (state, action) => {
        state.getThemedDashboardRsp = action.payload
      }
    )
  },
})

const themedDashboardSelector = createSelector(
  (state) => state[DATA_ANALYSIS].getThemedDashboardRsp,
  (getThemedDashboardRsp) => {
    const themedDashboard = getThemedDashboardRsp?.data?.themedDashboards || []
    const optionItems = themedDashboard.map((item, index) => {
      return {
        value: index,
        text: item?.dashboardName,
      }
    })
    const allDashboards = themedDashboard.map((item) => item?.types)
    return {
      optionItems,
      allDashboards,
    }
  }
)

export default dataAnalysisSlice.reducer
export { getThemedDashboardThunk, themedDashboardSelector }
